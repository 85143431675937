import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { answer } from 'src/modules/ListenAndSpeak/reducer'
import ListenAndSpeakScene from 'src/modules/ListenAndSpeak/ListenAndSpeakScene'

export default connect(
  ({ listenAndSpeak: { entitiesById, phrase, question, correctAnswer, completed } }) => ({
    phrase: entitiesById[phrase],
    question: entitiesById[question],
    answer: entitiesById[correctAnswer],
    completed,
  }),
  dispatch => bindActionCreators({ onAnswer: answer }, dispatch),
)(props => (props.phrase ? <ListenAndSpeakScene {...props} /> : null))
