import map from 'lodash/map'
import keyBy from 'lodash/keyBy'

const INIT = 'familyTreeSlideshow/INIT'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  entitiesById: {},
  placeLocations: [],
  places: {},
}

const createCard = (a, entity) => ({
  ...a,
  [entity.id]: {
    correct: undefined,
    id: entity.id,
    placeId: entity.id,
  },
})

const createPlace = (a, entity) => {
  const { places } = a
  const newPlace = {
    [entity.id]: {
      cardId: entity.id,
      id: entity.id,
    },
  }
  return {
    ...a,
    places: {
      ...places,
      ...newPlace,
    },
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities } = payload
      const cards = entities.reduce(createCard, {})
      const { places } = entities.reduce(createPlace, {})
      const placeIds = map(places, 'id')
      const placeLocations = [
        placeIds.slice(0, 4),
        placeIds.slice(4, 6),
        placeIds.slice(6, 10),
      ]
      return {
        ...initialState,
        cards,
        completed: null,
        entitiesById: keyBy(entities, 'id'),
        placeLocations,
        places,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

export {
  init,
}

export default reducer
