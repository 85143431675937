const QUERY_START = 'QUERY_START'
const QUERY_STOP = 'QUERY_STOP'
const QUERY_RESET = 'QUERY_RESET'

const initialState = {
  queriesCount: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_START:
      return {
        queriesCount: state.queriesCount + 1,
      }
    case QUERY_STOP:
      return {
        queriesCount: state.queriesCount - 1,
      }
    case QUERY_RESET:
      return initialState
    default:
      return state
  }
}

const queryStart = () => ({ type: QUERY_START })
const queryStop = () => ({ type: QUERY_STOP })
const queryReset = () => ({
  meta: {
    debounce: {
      time: 1000,
    },
  },
  type: QUERY_RESET,
})

export default reducer

export {
  queryStart,
  queryStop,
  queryReset,
}
