import 'babel-polyfill'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import store from 'src/store'
import { networkConnected, networkDisconnected } from 'src/pages/Layout/reducer'
import 'src/i18n'
import App from 'src/App'
import queue from 'src/lib/queue'
// import 'src/vendor/smartbanner.js/smartbanner'

if (!global.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js',
    'intl/locale-data/jsonp/th.js',
  ], (require) => {
    require('intl')
    require('intl/locale-data/jsonp/en.js')
    require('intl/locale-data/jsonp/th.js')
  })
}

const networkStatusHandler = (status) => {
  if (status.connected) {
    store.dispatch(networkConnected())
  } else {
    store.dispatch(networkDisconnected())
  }
}

queue(async () => {
  const {
    Capacitor,
    Capacitor: {
      Plugins: {
        Keyboard,
        Network,
        SplashScreen,
        StatusBar,
      } } } = window
  if (Capacitor.isNative) {
    const status = await Network.getStatus()
    networkStatusHandler(status)
    Network.addListener('networkStatusChange', networkStatusHandler)
    if (Capacitor.isIOS) Keyboard.setAccessoryBarVisible({ isVisible: true })
    SplashScreen.hide()
    StatusBar.hide()
  }
})

// eslint-disable-next-line no-redeclare
ReactDOM.render(<StrictMode><App /></StrictMode>, document.getElementById('app'))

// setTimeout(
//   () => (window.Capacitor && window.Capacitor.isNative) ||
//         (window.smartbanner && window.smartbanner.publish()),
//   10000,
// )
