import React from 'react'
import { t } from 'i18next'
import { StickyTableHeader } from 'src/components/StickyTable'

const TableHeader = props => (
  <StickyTableHeader {...props}>
    <th>
      {t('grade.system.percent')}
    </th>
    <th>
      {t('grade.system.color')}
    </th>
    <th>
      {t('grade.system.grade')}
    </th>
    <th>
      {t('grade.system.number')}
    </th>
    <th>
      {t('grade.system.titleEn')}
    </th>
    <th>
      {t('grade.system.titleTh')}
    </th>
  </StickyTableHeader>
)

export default TableHeader
