const INIT = 'phonics/INIT'
const PLAY = 'phonics/PLAY'
const NEXT = 'phonics/NEXT'
const STOP = 'phonics/STOP'

const initialState = {
  playLists: {},
  player: null,
  playing: null,
  reducerPlaying: 0,
}

const createPlayList = (a, phonic) => {
  if (phonic.src) return a.concat(a.length)
  return a
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { phonics = [], playId } = payload
      const playList = phonics.reduce(createPlayList, [])
      const playLists = {
        ...state.playLists,
        [playId]: playList,
      }
      return { ...initialState, playLists, reducerPlaying: 0 }
    }
    case PLAY: {
      const playing = 0
      const { player } = payload
      return { ...state, player, playing, reducerPlaying: 0 }
    }
    case NEXT: {
      const { playLists, reducerPlaying } = state
      const { player } = payload
      if (player === null) return { ...state, playing: null, reducerPlaying: 0 }

      const { length } = playLists[player]
      const next = reducerPlaying + 1 >= length ? 0 : reducerPlaying + 1
      const toPlay = playLists[player][next]
      const playing = reducerPlaying + 1 >= length ? null : toPlay
      return { ...state, playing, reducerPlaying: next }
    }
    case STOP: {
      return { ...state, playing: null }
    }
    default: {
      return state
    }
  }
}

export const initPhonics = (phonics, playId) => ({
  payload: {
    phonics,
    playId,
  },
  type: INIT,
})

export const playPhonics = (phonics, player) => ({
  payload: {
    phonics,
    player,
  },
  type: PLAY,
})

export const playNext = player => ({
  payload: {
    player,
  },
  type: NEXT,
})

export const stopPlay = () => ({
  type: STOP,
})
