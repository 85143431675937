import alphabetEn from 'src/queries/collections/alphabetEn.gql'
import alphabetCursiveEn from 'src/queries/collections/alphabetCursiveEn.gql'
import familyFaces from 'src/queries/collections/familyFaces.gql'
import letterFormationVideos from 'src/queries/collections/letterFormationVideos.gql'
import phonics from 'src/queries/collections/phonics.gql'
import phonicsAlphabet from 'src/queries/collections/phonicsAlphabet.gql'
import parentsQuery from 'src/pages/User/shared/UserSelector/parentsQuery.gql'
import studentsQuery from 'src/pages/User/shared/UserSelector/studentsQuery.gql'

export {
  alphabetEn,
  alphabetCursiveEn,
  familyFaces,
  letterFormationVideos,
  phonics,
  phonicsAlphabet,
  parentsQuery,
  studentsQuery,
}
