import icons from 'src/lib/menu/icons'
import { LESSONS } from 'src/constants/menu'

export const path = '/lessons'

export const scene = {
  icon: icons[LESSONS],
  id: LESSONS,
  path,
}

export default {
  scene,
}
