import React from 'react'
import { bool, oneOfType, string, number } from 'prop-types'
import classnames from 'classnames'
import Img from 'src/components/Img'
import { translate } from 'src/components/Translate'
import round from 'lodash/round'

const propTypes = {
  inline: bool,
  title: oneOfType([string, number]),
  type: oneOfType([string, bool]),
}
const defaultProps = {
  inline: false,
  title: '',
  type: null,
}

const RenderCore = ({ inline, title, type }) => {
  switch (type) {
    case 'image': {
      return (
        <Img src={title} />
      )
    }
    case 'm': {
      return (
        <div>
          {title} {translate('m')}
        </div>
      )
    }
    case 'cm': {
      return (
        <div>
          {round(title * 100)} {translate('cm')}
        </div>
      )
    }
    case 'mm': {
      return (
        <div>
          {round(title * 1000)} {translate('mm')}
        </div>
      )
    }
    case 'word':
    default: {
      return (
        <div className={classnames({ inline })}>
          {title}
        </div>
      )
    }
  }
}

RenderCore.propTypes = propTypes
RenderCore.defaultProps = defaultProps

export default RenderCore
