import BaseSpeechRecognition from 'src/lib/SpeechRecognition/BaseSpeechRecognition'

const { log } = console
const getOptions = () => {
  if (window.Capacitor && window.Capacitor.isAndroid) {
    return {
      options: { language: 'en-GB', showPartial: true, showPopup: false },
      timeout: 1000,
    }
  }

  return {
    options: { language: 'en-GB', showPopup: false },
    timeout: 0,
  }
}

class NativeSpeechRecognition extends BaseSpeechRecognition {
  constructor() {
    super()
    this.recognition = window.plugins.speechRecognition
    this.recognition.isRecognitionAvailable(
      () => {
        log('recognition available')
        this.recognition.hasPermission(
          () => { log('permissions available') },
          (e) => {
            log(e)
            this.recognition.requestPermission(() => { log('permissions granted') }, log)
          },
        )
      },
      () => { log('recognition is not available') },
    )
  }

  abort() {
    this.stop()
  }

  start() {
    const { options } = getOptions()
    this.recognition.startListening(
      (words) => {
        log(words)
        this.onResultCallback(words)
      },
      log,
      options,
    )
  }

  stop() {
    const { timeout } = getOptions()
    setTimeout(
      () => this.recognition.stopListening(() => { log('listening stopped') }, log),
      timeout,
    )
  }

  onResult(onResultCallback) {
    this.onResultCallback = onResultCallback
  }
}

export default NativeSpeechRecognition
