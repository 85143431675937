import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SpellingInLine from 'src/modules/Word/Spelling/SpellingInLine/SpellingInLine'
import * as spellingActions from 'src/modules/Word/Spelling/reducer'

const { array, object, func, number } = PropTypes
const propTypes = {
  alphabetEn: array.isRequired,
  entity: object,
  initSpelling: func.isRequired,
  letters: array.isRequired,
  playing: number,
  playNext: func.isRequired,
  sizes: array.isRequired,
}

const defaultProps = {
  playing: null,
  word: true,
}

class SpellingInLineContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { alphabetEn, initSpelling, entity, word } = this.props
    initSpelling({ alphabetEn, phonics: [entity], word })
  }

  render() {
    const {
      letters,
      playing,
      playNext,
      sizes,
    } = this.props
    return (
      <SpellingInLine
        playNext={playNext}
        playing={playing}
        letters={letters}
        sizes={sizes}
      />
    )
  }
}

SpellingInLineContainer.propTypes = propTypes
SpellingInLineContainer.defaultProps = defaultProps

const select = state => state.spelling
const actions = dispatch => bindActionCreators(spellingActions, dispatch)

export { SpellingInLineContainer }
export default connect(select, actions)(SpellingInLineContainer)
