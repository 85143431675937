import React from 'react'
import { shape, string } from 'prop-types'
import Img from 'src/components/Img'

const propTypes = {
  entity: shape({
    image: shape({
      src: string,
    }),
  }).isRequired,
}

const EntityImage = ({ entity, ...rest }) => {
  const { image } = { ...entity }
  const { src } = { ...image }

  return (
    <Img src={src} {...rest} />
  )
}

EntityImage.propTypes = propTypes
export default EntityImage
