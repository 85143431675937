import React from 'react'
import {
  any,
  bool,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types'
import Div from 'src/components/Div'
import Divider from 'src/components/Divider'
import icons from 'src/lib/menu/icons'
import WidgetHeader from 'src/components/Widget/WidgetHeader'

const propTypes = {
  badge: any,
  children: any,
  color: string,
  icon: string,
  noPrint: bool,
  scroll: bool,
  title: oneOfType([object, string]),
  titleRight: node,
  token: string,
}

const defaultProps = {
  badge: null,
  children: null,
  color: 'blue',
  icon: 'circle',
  noPrint: true,
  scroll: false,
  title: '',
  titleRight: null,
  token: undefined,
}

const scrollStyle = enable => (
  enable
    ? {
      height: 0,
      marginRight: '-1rem',
      overflowY: 'scroll',
      paddingRight: '1rem',
    }
    : {}
)

const Widget = ({
  badge,
  children,
  color,
  icon: optionalIcon,
  noPrint,
  scroll,
  title,
  titleRight,
  token,
}) => {
  const icon = icons[token] || optionalIcon
  return (
    <Div flex auto column px={2} py={1} className="bg-white rounded">
      <WidgetHeader
        {...{
          badge,
          color,
          icon,
          noPrint,
          title,
          titleRight,
        }}
      />
      {((title || icon) && children) && <Divider mxn2 my1 noPrint={noPrint} />}
      <Div flex auto column style={scrollStyle(scroll)}>
        {children}
      </Div>
    </Div>
  )
}

Widget.propTypes = propTypes
Widget.defaultProps = defaultProps
export default Widget
