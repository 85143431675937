import React from 'react'
import { shape } from 'prop-types'
import ParentLoginReminder from 'src/components/ParentLoginReminder/ParentLoginReminder'
import { CurrentUser } from 'src/pages/CurrentUser'
import userShape from 'src/shapes/user'

const propTypes = {
  user: shape(userShape),
}

const defaultProps = {
  user: {},
}

const ParentLoginReminderContainer = ({ user }) => {
  const { parents } = user || {}
  const hasParents = !!(parents || []).length
  const displayReminder = hasParents && parents.find(({ lastLoginAt }) => !lastLoginAt)
  return displayReminder ? <ParentLoginReminder /> : null
}

ParentLoginReminderContainer.propTypes = propTypes
ParentLoginReminderContainer.defaultProps = defaultProps

export {
  ParentLoginReminderContainer,
}

export default () => (<CurrentUser><ParentLoginReminderContainer /></CurrentUser>)
