import React from 'react'
import { number, shape, bool, string, object } from 'prop-types'
import Div from 'src/components/Div'
import Img from 'src/components/Img'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EffectImage from 'src/components/ObjectTarget/EffectImage'

const propTypes = {
  correct: bool,
  cursive: bool,
  decorateTargetImage: string,
  effectImage: string,
  entity: shape(),
  entityImageStyle: object,
  height: number,
  justify: string,
  secondEffectImage: string,
  selected: bool,
  style: object,
  targetImage: string,
  text: bool,
  width: number,
}

const defaultProps = {
  correct: null,
  cursive: null,
  decorateTargetImage: null,
  effectImage: null,
  entity: null,
  entityImageStyle: { padding: '2rem' },
  height: 15,
  justify: 'center',
  secondEffectImage: null,
  selected: null,
  style: {},
  targetImage: null,
  text: null,
  width: 18,
}

const Target = ({
  correct,
  cursive,
  decorateTargetImage,
  effectImage,
  entity,
  entityImageStyle,
  height,
  justify,
  secondEffectImage,
  selected,
  style,
  targetImage,
  text,
  width,
}) => (
  <Div flex auto>
    <Div absolute z1 flex height={height} width={width} style={{ ...style, ...entityImageStyle }}>
      {
        text
          ? (
            <Div flex auto justify-center>
              <AdaptiveCard
                cursive={cursive}
                english
                entity={entity}
                justify={justify}
                focusOnWidth
                style={{ ...entityImageStyle }}
                transparent
              />
            </Div>
          ) : (
            <Img
              src={{ ...entity.image }.src}
              rounder
            />
          )}
    </Div>
    <Div absolute z0 height={height} width={width} style={{ ...style }}>
      <Img rounder src={targetImage} />
    </Div>
    {
      decorateTargetImage && (
        <Div absolute z2 height={height} width={width} style={{ ...style }}>
          <Img rounder src={decorateTargetImage} />
        </Div>
      )
    }
    {
      (selected || correct !== null) && (
        <Div
          absolute
          z3
          height={height}
          width={width}
          style={style}
        >
          <EffectImage {...{ correct, effectImage, secondEffectImage }} />
        </Div>
      )
    }
  </Div>
)

Target.propTypes = propTypes
Target.defaultProps = defaultProps

export default Target
