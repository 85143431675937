import { string } from 'prop-types'

const videoShape = {
  id: string,
  slug: string,
  title: string,
  src: string.isRequired,
  previewSrc: string,
}

export default videoShape
