import React from 'react'
import { array, string, func } from 'prop-types'
import { t } from 'i18next'

const propTypes = {
  onYearSelect: func.isRequired,
  value: string,
  years: array.isRequired,
}

const defaultProps = {
  value: '',
}

const YearSelector = ({
  value,
  onYearSelect,
  years,
}) => {
  const selectYear = e => onYearSelect(e.target.value)
  return (
    <div className="inline-block">
      <div className="fields-group">
        <select onChange={selectYear} value={value}>
          <option value="">{t('school.settings.selectAcademicYear')}</option>
          {years.map(year => (
            <option key={year.id} value={year.id}>{year.title}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

YearSelector.propTypes = propTypes
YearSelector.defaultProps = defaultProps

export default YearSelector
