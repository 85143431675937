import React from 'react'
import { arrayOf, element, number } from 'prop-types'

const propTypes = {
  children: arrayOf(element).isRequired,
  widths: arrayOf(number),
}

const defaultProps = {
  widths: [],
}

const StickyTableHeader = ({ children, widths }) => (
  <thead>
    <tr>
      {
        React.Children.map(
          children,
          (el, i) => React.cloneElement(el, {
            className: el.props.className ? el.props.className.replace('col-', 'xcol-') : undefined,
            // eslint-disable-next-line react/no-array-index-key
            key: i,
            width: widths[i],
          }),
        )
      }
    </tr>
  </thead>
)

StickyTableHeader.propTypes = propTypes
StickyTableHeader.defaultProps = defaultProps
export default StickyTableHeader
