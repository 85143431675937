import React, { Fragment } from 'react'
import { arrayOf, bool, shape } from 'prop-types'
import useSequence from 'src/lib/useSequence'
import Slide from 'src/components/Layout/Slide'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import Card from 'src/components/Card/Card'
import wait from 'src/lib/wait'
import Bubble from 'src/components/Bubble'
import entityShape from 'src/shapes/entity'
import classnames from 'classnames'

const Indicator = ({ active }) => (
  <Div
    transition-all
    className={`bg-${active ? 'green' : 'gray'}`}
    shadow={active}
    width={2}
    height={2}
    style={{ borderRadius: '50%' }}
  />
)

Indicator.propTypes = {
  active: bool.isRequired,
}

const propTypes = {
  phonics: arrayOf(shape(entityShape).isRequired).isRequired,
  word: shape(entityShape).isRequired,
}

const WordPhonics = ({ word, phonics }) => {
  const steps = [
    () => new Promise(),
    ...phonics,
    () => wait(300),
    word,
    () => wait(800),
    word,
  ]
  const [step, nextStep] = useSequence(steps)
  return (
    <Slide>
      <Div className="WordPhonics">
        <Bubble label=" " onKeyPress={() => nextStep()}>
          <Div p4>
            {steps[step] === word ? (
              <Div animated fadeIn key={`${word.slug}-${step}`}>
                <EntityCard
                  entity={word}
                  audio
                  english
                  autoPlay
                  playNext={nextStep}
                  minHeight={20}
                  style={{ backgroundColor: 'white', fontSize: '8rem' }}
                  bold
                />
              </Div>
            ) : (
              <Div flex>
                {phonics.map((p, i) => {
                  const currentStep = steps[step].slug === p.slug
                  return (
                    <Fragment key={p.slug}>
                      <Div
                        relative
                        className={classnames('qa-phonics', { 'qa-current': currentStep })}
                      >
                        <EntityCard
                          audio
                          autoPlay={currentStep}
                          bold
                          english
                          entity={p}
                          minHeight={20}
                          playNext={nextStep}
                          style={{ backgroundColor: 'white', fontSize: '8rem', border: 'none' }}
                        />
                        <Div absolute col={12} flex justify="center" style={{ bottom: '1rem' }}>
                          <Indicator active={currentStep} />
                        </Div>
                      </Div>
                      {
                        i + 1 < phonics.length && (
                        <Card bold style={{ fontSize: '8rem', border: 'none' }}>·</Card>
                        )
                      }
                    </Fragment>
                  )
                })}
              </Div>
            )}
          </Div>
        </Bubble>
      </Div>
    </Slide>
  )
}

WordPhonics.propTypes = propTypes

export default WordPhonics
