import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as phonicsActions from 'src/modules/Word/Phonics/reducer'
import * as cardActions from 'src/modules/ComicStripFillInTheBlank/reducer'
import ComicStripFillInTheBlank from 'src/modules/ComicStripFillInTheBlank/ComicStripFillInTheBlank'

const propTypes = {
  ...gamePropTypes,
}

class ComicStripFillInTheBlankContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  render() {
    const { mark, retry, completed, entitiesById, places, moveCard,
      availableCardsId, linesList, cards, lines, playPhonics, actualEntities } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <ComicStripFillInTheBlank
          {
            ...{
              availableCardsId,
              cards,
              entitiesById,
              lines,
              linesList,
              moveCard,
              places,
              playPhonics,
            }
          }
        />
      </Game>
    )
  }
}

const select = ({ comicStripFillInTheBlank }) => comicStripFillInTheBlank
const actions = dispatch => bindActionCreators({ ...phonicsActions, ...cardActions }, dispatch)

ComicStripFillInTheBlankContainer.propTypes = propTypes
ComicStripFillInTheBlankContainer.demoData = {
  entities: [
    'phrase-last-monday-i-ate-mangoes',
    'monday',
    'phrase-last-sunday-i-ate-lettuces',
    'sunday',
    'phrase-father-bought-coupon-for-po-and-por',
    'father',
    'phrase-she-is-my-younger-sister',
    'sister',
    'phrase-he-is-watering-the-plants-in-the-garden',
    'garden',
    'phrase-it-is-a-banana',
    'banana',
    'phrase-she-is-my-elder-sister',
    'elder-sister',
    'phrase-thank-you-goodbye-sara',
    'thank-you',
  ],
}

export default connect(select, actions)(ComicStripFillInTheBlankContainer)
