import BarChartDefineCountWord from 'src/modules/BarChart/BarChartDefineCountWord'
import BarChartWordCount from 'src/modules/BarChart/BarChartWordCountContainer'
import reducer from 'src/modules/BarChart/reducer'

export {
  reducer,
  BarChartWordCount,
}

export default BarChartDefineCountWord
