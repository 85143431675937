import React from 'react'
import { array, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import ToneMarkerContainer from 'src/modules/ToneMarker/ToneMarkerContainer'
import * as gameActions from 'src/modules/ToneMarker/reducer'

const propTypes = {
  entities: array.isRequired,
  initWord: func.isRequired,
}

const ToneMarkerWordContainer = props => (
  <ToneMarkerContainer init={() => props.initWord({ entities: props.entities })} {...props} />
)

ToneMarkerWordContainer.demoData = {
  entities: [
    'bathroom',
    'brother',
    'dining-room',
  ],
}

const select = state => state.toneMarker
const actions = dispatch => bindActionCreators(gameActions, dispatch)

ToneMarkerWordContainer.propTypes = propTypes

export { ToneMarkerWordContainer }
export default connect(select, actions)(ToneMarkerWordContainer)
