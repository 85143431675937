import { useEffect, useState } from 'react'
import groupAndSort from 'src/pages/Users/SelectionActions/groupAndSort'

const useYearGroups = (sections, years) => {
  const [defYearByTag, defYearsSorted] = groupAndSort(sections, years)

  const [yearByTag, setYearByTag] = useState(defYearByTag)
  const [yearsSorted, setYearsSorted] = useState(defYearsSorted)

  useEffect(() => {
    const [ybt, ys] = groupAndSort(sections, years)
    setYearByTag(ybt)
    setYearsSorted(ys)
  }, [sections, years])

  return ({ yearByTag, yearsSorted })
}

export default useYearGroups
