import React, { Component } from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import indexEntitiesById from 'src/lib/indexEntititesById'
import * as allActions from 'src/modules/MatchTranslation/reducer'
import MatchTranslation from 'src/modules/MatchTranslation/MatchTranslation'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
}

class MatchTranslationContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { init, entities } = this.props
    init({ entities })
  }

  render() {
    const { mark, retry, completed, actualEntities,
      cards, moveCard, places, selectedEntities, placeIdsChunk } = this.props
    const entitiesById = indexEntitiesById(selectedEntities)
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        { entitiesById && (
        <MatchTranslation {...{
          cards,
          entitiesById,
          moveCard,
          placeIdsChunk,
          places,
          selectedEntities,
        }}
        />
        )}
      </Game>
    )
  }
}

MatchTranslationContainer.propTypes = propTypes
MatchTranslationContainer.defaultProps = defaultProps
MatchTranslationContainer.demoData = {
  entities: [
    'banana',
    'bicycle',
    'brother',
    'family',
    'father',
    'flower',
    'grandfather',
    'mother',
    'sister',
  ],
}

const selector = state => state.matchTranslation
const actions = dispatch => bindActionCreators(allActions, dispatch)

export { MatchTranslationContainer }
export default connect(selector, actions)(MatchTranslationContainer)
