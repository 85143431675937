import every from 'lodash/every'
import keyBy from 'lodash/keyBy'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'SelectType/INIT'
const CLICK = 'SelectType/CLICK'
const MARK = 'SelectType/MARK'
const RETRY = 'SelectType/RETRY'

const initialState = {
  actualEntities: [],
  choicesType: [],
  entitiesById: {},
  image: '',
  lines: [],
  paragraphIds: null,
}

const MAXIMUM_ROW = 5

const checkGameCompleteness = lines => every(lines, 'correct')

const lineCreator = cols => (a, entity) => {
  const prefix = entity.slug.split('-')[0]
  if (prefix === 'paragraph') {
    const { src } = { ...entity.image }

    const update = {
      paragraphIds: [...a.paragraphIds, entity.id],
      src: a.src || src,
    }

    return {
      ...a,
      ...update,
    }
  }
  if (prefix === 'collection') {
    const entityType = entity.titleEn
    return {
      ...a,
      choicesType: a.choicesType.concat(entityType),
      type: entityType,
    }
  }
  const { lines } = a
  const prevLine = lines.length >= 0 && lines[lines.length - 1]
  if (prevLine && prevLine.ids.length < cols) {
    const line = {
      ...prevLine,
      ids: [...prevLine.ids, entity.id],
    }
    return {
      ...a,
      lines: [...lines.slice(0, -1), line],
    }
  }
  const line = {
    correct: null,
    id: entity.id,
    ids: [entity.id],
    select: null,
    type: a.type,
    uuid: a.uuid,
  }
  return {
    ...a,
    lines: lines.concat(line),
  }
}

const clickLine = (id, type) => (a, line) => {
  if (id === line.id) {
    return a.concat({
      ...line,
      correct: null,
      select: type,
    })
  }
  return a.concat({ ...line })
}

const checkLines = (a, line) => a.concat({
  ...line,
  correct: line.type === line.select,
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities, maximumRow, cols } = payload
      const createLine = lineCreator(cols)
      const { lines, choicesType, paragraphIds } = entities.reduce(
        createLine,
        {
          choicesType: [],
          lines: [],
          paragraphIds: [],
          type: null,
        },
      )
      const entitiesById = keyBy(entities, 'id')
      const first = paragraphIds.length > 0 && entitiesById[paragraphIds[0]]
      const image = { ...{ ...first }.image }.src
      return {
        ...initialState,
        actualEntities: entities,
        choicesType,
        entitiesById,
        image,
        lines: uniqShuffle(lines).slice(0, maximumRow || MAXIMUM_ROW),
        paragraphIds,
      }
    }

    case CLICK: {
      const { id, type } = payload
      const lines = state.lines.reduce(clickLine(id, type), [])
      return {
        ...state,
        lines,
      }
    }

    case MARK: {
      const lines = state.lines.reduce(checkLines, [])
      const completed = checkGameCompleteness(lines)
      return {
        ...state,
        completed,
        lines,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, maximumRow, cols }) => ({
  payload: {
    cols,
    entities,
    maximumRow,
  },
  type: INIT,
})

const onClick = ({ id, type }) => ({
  payload: { id, type },
  type: CLICK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export { init, onClick, mark, retry }

export default reducer
