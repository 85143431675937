import React from 'react'
import { Redirect } from 'react-router-dom'
import { bool } from 'prop-types'
import { loginPath, userHomePagePath } from 'src/lib/routes'
import CurrentUser from 'src/pages/CurrentUser'

const HomePageRedirection = ({ user }) => (
  user ? <Redirect to={userHomePagePath(user)} /> : <Redirect to={loginPath()} />
)

const RedirectToHomePage = ({ includingGuest }) => (
  <CurrentUser includingGuest={includingGuest}>
    <HomePageRedirection />
  </CurrentUser>
)

RedirectToHomePage.propTypes = {
  includingGuest: bool,
}

export default RedirectToHomePage
