import React from 'react'
import startsWith from 'lodash/startsWith'
import splitIntoChunks from 'src/lib/splitIntoChunks'
import splitWhile from 'src/lib/splitWhile'
import Slides from 'src/components/Layout/Slides'
import WordPhonics from 'src/modules/PhonicsWords/WordPhonics'

const chunks = splitIntoChunks((entities) => {
  const [word, ...withoutWord] = entities
  const [phonics, rest] = splitWhile(withoutWord, ({ slug }) => startsWith(slug, 'phonics-'))
  return [[word, ...phonics], rest]
})

const PhonicsWords = ({ entities }) => (
  <Slides>
    {chunks(entities).map(([word, ...phonics]) => (
      <WordPhonics
        key={word.slug}
        label={word.titleEn}
        phonics={phonics}
        word={word}
      />
    ))}
  </Slides>
)

PhonicsWords.demoData = {
  entities: [
    'bright',
    'phonics-b',
    'phonics-r',
    'phonics-igh',
    'phonics-t',
    'flight',
    'phonics-f',
    'phonics-l',
    'phonics-igh',
    'phonics-t',
  ],
}

export default PhonicsWords
