import { useState } from 'react'
import isFunction from 'lodash/isFunction'

const useSequence = (steps) => {
  const [step, setStep] = useState(0)

  const nextStep = async () => {
    let next = step + 1
    while (isFunction(steps[next])) {
      // eslint-disable-next-line no-await-in-loop
      await steps[next]()
      next += 1
    }
    setStep(steps[next] ? next : 0)
  }

  return [step, nextStep]
}

export default useSequence
