import React, { Component } from 'react'
import { object, string, array, shape, arrayOf, func, bool } from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router-dom'
import map from 'lodash/map'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { t } from 'i18next'
import linkUp from 'src/lib/linkUp'

import Module, { getComponent } from 'src/components/Module'
import * as components from 'src/modules'
import * as layoutActions from 'src/components/Layout/reducer'
import { GameContext } from 'src/lib/contexts'
import * as homeworkActions from 'src/pages/Homework/reducer'
import * as homeworkIterations from 'src/lib/homework'
import normalizeEntity from 'src/lib/normalizeEntity'
import Deck from 'src/components/Layout/Deck'
import DragDropContext from 'src/lib/dragAndDrop'
import EntitiesList from 'src/components/ModuleDemo/EntitiesList'
import ErrorMessage from 'src/components/ErrorMessage'
import gqlLoader from 'src/lib/gqlLoader'
import Header from 'src/components/ModuleDemo/Header'
import instructionShape from 'src/shapes/instruction'
import KeyPress from 'src/components/KeyPress'
import query from 'src/queries/lib/Entities.gql'
import ScalableModule from 'src/components/ScalableModule'
import Div from 'src/components/Div'

const propTypes = {
  capture: bool.isRequired,
  disableMarkButton: func.isRequired,
  enableMarkButton: func.isRequired,
  entities: array.isRequired,
  hideInstruction: func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  instructions: arrayOf(shape(instructionShape)).isRequired,
  isShowInstruction: bool.isRequired,
  locale: string,
  match: object.isRequired,
  moduleId: string.isRequired,
  nextSlide: func,
  prevSlide: func,
  showInstruction: func.isRequired,
  showMarkButton: func.isRequired,
  started: bool.isRequired,
  startGame: func.isRequired,
  variables: object.isRequired,
}

const defaultProps = {
  capture: false,
  locale: 'en',
  nextSlide: undefined,
  prevSlide: undefined,
}

const firstModuleId = Object.keys(components)[0]
const options = ({ moduleId = firstModuleId }) => {
  const {
    Component: { demoData: { entities, ...props } = {} } = {},
  } = getComponent(moduleId)
  return {
    variables: {
      entities,
      ...props,
    },
  }
}

class ModuleDemo extends Component {
  state = { hasError: false }

  componentDidCatch() {
    this.setState({
      hasError: true,
    })
  }

  handleChange({ value }) {
    const { history, match, showInstruction } = this.props
    this.setState({
      hasError: false,
    })
    showInstruction()
    if (value) history.push(linkUp(match.path, value))
  }

  render() {
    const {
      capture,
      disableMarkButton,
      enableMarkButton,
      entities: entitiesFromGQL,
      hideInstruction,
      instructions,
      isShowInstruction,
      match,
      moduleId = firstModuleId,
      nextSlide,
      prevSlide,
      showInstruction,
      showMarkButton,
      started,
      startGame,
      variables,
      variables: { entities },
      ...props
    } = this.props
    const help = () => {
      if (isShowInstruction) {
        hideInstruction()
      } else {
        showInstruction()
      }
    }
    const { hasError } = this.state
    const entitiesOrdered = map(entities, slug => (
      entitiesFromGQL.find(item => item.slug === slug)
    )).map(normalizeEntity)
    const {
      Component: {
        demoData: { entities: entitySlugs, ...restDemoData } = {},
      } = {},
      error: notFound,
    } = getComponent(moduleId)
    const errorMessage =
      notFound ||
      <ErrorMessage message={t('error.inModule')} />
    const context = {
      disableMarkButton,
      enableMarkButton,
      instructions,
      moduleId,
      showMarkButton,
      startGame,
      started,
    }
    const { defaultIterations, iterations } = homeworkIterations
    const iteration = iterations[`${moduleId}`] ? iterations[`${moduleId}`] : defaultIterations
    return (
      <GameContext.Provider value={context}>
        <Deck className="overflow-hidden moduleDemoHeight" justify="start">
          <KeyPress
            keys={{ ArrowLeft: prevSlide, ArrowRight: nextSlide, F1: help }}
          />
          {
            !capture && (
              <Header
                iteration={iteration}
                moduleId={moduleId}
                onChange={e => this.handleChange(e)}
                linkToHome={linkUp(match.path)}
                key={`header-${moduleId}`}
              />
            )
          }
          <ScalableModule>
            {hasError
              ? errorMessage
              : (
                <Module
                  key={moduleId}
                  moduleId={moduleId}
                  homework={false}
                  entities={entitiesOrdered}
                  {...restDemoData}
                  {...props}
                  variables={variables}
                  isDemo
                  started={started}
                />
              )}
          </ScalableModule>
          {!capture && (
            <Div mx1>
              <EntitiesList
                key={`${moduleId}-entities`}
                entities={entitiesOrdered}
              />
            </Div>
          )}
        </Deck>
      </GameContext.Provider>
    )
  }
}

ModuleDemo.defaultProps = defaultProps
ModuleDemo.propTypes = propTypes

const select = state => ({ ...state.layout, ...state.homework })
const actions = dispatch => bindActionCreators({ ...layoutActions, ...homeworkActions }, dispatch)

export default compose(
  gqlLoader(query, { options }),
  connect(select, actions),
  DragDropContext,
)(withRouter(ModuleDemo))
