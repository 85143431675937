import React from 'react'
import { useHistory } from 'react-router'
import { shape } from 'prop-types'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'

import userShape from 'src/shapes/user'
import gqlLoader from 'src/lib/gqlLoader'
import parseErrors from 'src/lib/form/parseErrors'
import { successMessage } from 'src/lib/toaster'
import UserEdit from 'src/pages/User/Edit/Scene'
import userQuery from 'src/pages/User/Edit/user.gql'
import userProfileQuery from 'src/pages/User/shared/userProfile.gql'
import userUpdateMutation from 'src/pages/User/Edit/userUpdateMutation.gql'
import userPath from 'src/lib/routes/user'
import usePath from 'src/lib/routes/usePath'

const propTypes = {
  user: shape(userShape).isRequired,
}

const showSuccessMessage = () => {
  const title = t('user.user')
  const message = t('save.success')
  successMessage({ message, title })
}

export const UserEditContainer = ({ user }) => {
  const pathToUser = usePath(userPath)({ userId: user.slug })
  const history = useHistory()
  return (
    <Mutation
      mutation={userUpdateMutation}
      refetchQueries={() => [
        { query: userProfileQuery, variables: { userId: user.id } },
        { query: userQuery, variables: { userId: user.id } },
      ]}
      awaitRefetchQueries
      onCompleted={() => {
        showSuccessMessage()
        history.push(pathToUser)
      }}
    >
      {(userUpdate) => (
        <UserEdit
          user={{ ...user, yearId: (user.year || {}).id }}
          cancelPath={pathToUser}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            userUpdate({ variables: values }).catch((errors) =>
              setErrors(parseErrors(errors))
            )
            setSubmitting(false)
          }}
        />
      )}
    </Mutation>
  )
}

UserEditContainer.propTypes = propTypes

export default gqlLoader(userQuery)(UserEditContainer)
