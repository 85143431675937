import React from 'react'
import IdleTimer from 'react-idle-timer'

import { logout } from 'src/components/Logout'
import { TENANT } from 'src/constants/serverRole'
import { localStorage } from 'src/lib/window'

const SchoolIdleTimer = () => {
  if (!TENANT || !localStorage.getItem('token')) return null

  return (
    <IdleTimer
      element={document}
      onIdle={logout}
      timeout={1000 * 60 * 5}
    />
  )
}

export default SchoolIdleTimer
