import React, { useContext } from 'react'
import { useSvgDrawing } from 'react-hooks-svgdrawing'
import { bool, func, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Bubble from 'src/components/Bubble'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import Card from 'src/components/Card'
import { Unscaled, ScaleContext } from 'src/components/ScalableModule'
import EntityBubble from 'src/components/EntityBubble'

const propTypes = {
  entity: shape(entityShape).isRequired,
  needsCleanup: bool.isRequired,
  onCleanup: func.isRequired,
}
/* eslint-disable sort-keys */

const zIndex = z => ({
  zIndex: z,
})

const stretch = {
  height: '100%',
  width: '100%',
}
const absolute = {
  position: 'absolute',
  top: '0',
  left: '0',
}
// eslint-disable-next-line react/prop-types
const Ident = ({ children }) => (
  <Div mb3 mr2>
    {children}
  </Div>
)

// eslint-disable-next-line react/prop-types
const DrawControl = ({ title, label, action }) => (
  <Ident>
    <Bubble label={label} onKeyPress={action}>
      <Card title={title} onClick={action} width={5} />
    </Bubble>
  </Ident>
)
// eslint-disable-next-line react/prop-types
const EntityControl = ({ entity, label }) => (
  <Ident>
    <EntityBubble audio autoPlay english entity={entity} play width={5} label={label} />
  </Ident>
)

const DrawLetter = ({ entity, needsCleanup, onCleanup }) => {
  const ctx = useContext(ScaleContext)
  const { scale } = ctx || { scale: 1 }
  const [renderRef, action] = useSvgDrawing({
    penWidth: 10 * scale, // pen width
    penColor: '#e00', // pen color
    height: '100%', // drawing area height
    width: '100%', // drawing area width
  })
  const cleanup = () => {
    action.clear()
    onCleanup()
  }
  if (needsCleanup) {
    cleanup()
  }


  return (
    <Div flex flex-row auto>
      <Div flex-0 style={{ ...zIndex(3) }}>
        <DrawControl title="⎌" label="z" action={action.undo} />
        <DrawControl title="⎚" label="x" action={cleanup} />
        <EntityControl entity={entity} label=" " />
      </Div>
      <Div flex-1 auto>
        <EntityCard audio entity={entity} image style={{ ...zIndex(1), ...stretch }} />
        <Unscaled style={{ ...zIndex(2), ...stretch, ...absolute }}>
          <div ref={renderRef} style={stretch} />
        </Unscaled>
      </Div>
    </Div>
  )
}

DrawLetter.propTypes = propTypes

export default DrawLetter
