import React from 'react'
import { Link } from 'react-router-dom'
import userPath from 'src/lib/routes/user'
import usePath from 'src/lib/routes/usePath'

const UserLinkCell = ({
  original: { slug: userId },
  value,
}) => (
  <Link className="qa-user-table-row" to={usePath(userPath)({ userId })}>
    <span>{value}</span>
  </Link>
)

export default UserLinkCell
