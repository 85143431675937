import React from 'react'
import { bool, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import EntityBubble from 'src/components/EntityBubble'
import EntityImage from 'src/components/EntityImage'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  imageEntity: shape(entityShape),
  showImage: bool.isRequired,
  showPlayImage: bool.isRequired,
}

const withWrapper = child => <Div flex auto justify="center" p>{child}</Div>

const MultipleChoiceImageCard = ({ imageEntity, showImage, showPlayImage }) => {
  if (showImage) return withWrapper(<EntityImage entity={imageEntity} />)
  if (showPlayImage) {
    return (
      withWrapper(
        <EntityBubble
          key={imageEntity.id}
          entity={imageEntity}
          english
          audio
          label=" "
          play={showPlayImage}
          className="MultipleChoiceImageCard"
        />,
      )
    )
  }
  return (
    withWrapper(
      <Icon
        className="MultipleChoiceImageCard"
        style={{ alignSelf: 'center' }}
        icon="question"
        color="blue"
      />,
    )
  )
}

MultipleChoiceImageCard.propTypes = propTypes

export default MultipleChoiceImageCard
