import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import chunk from 'lodash/chunk'
import size from 'lodash/size'
import shuffle from 'lodash/shuffle'
import slice from 'lodash/slice'
import uniqShuffle from 'src/lib/uniqShuffle'
import uuid from 'uuid'

const INIT = 'multipleChoice/INIT'
const INIT_AUDIO = 'multipleChoice/INIT_AUDIO'
const INIT_FIXED = 'multipleChoice/INIT_FIXED'
const INIT_QUESTIONS = 'multipleChoice/INIT_QUESTIONS'
const INIT_PARAGRAPH = 'multipleChoice/INIT_PARAGRAPH'
const MARK = 'multipleChoice/MARK'
const RETRY = 'multipleChoice/RETRY'
const SELECT = 'multipleChoice/SELECT'

const initialState = {
  actualEntities: [],
  answerId: null,
  cards: {},
  completed: null,
  entitiesById: {},
  isMark: false,
  questionId: null,
}

const selectCards = ({ answerId, choices, cardsSize = 3 }) => {
  const newChoices = []
  const expectChoice = choices.find(choice => choice === answerId)
  if (expectChoice) newChoices.push(expectChoice)
  choices.forEach((choice) => {
    if (choice !== answerId && newChoices.length < cardsSize) {
      newChoices.push(choice)
    }
  })
  return newChoices
}

const createChoices = (a, [, answer]) => {
  if (!a.includes(answer.id)) {
    return a.concat(answer.id)
  }
  return a
}

const createCards = (a, cardId) => (
  {
    ...a,
    [cardId]: {
      correct: null,
      id: cardId,
      key: uuid(),
      label: (size(a) + 1),
      selected: null,
    },
  }
)

const createCardsForMultipleChoiceFixed = (a, entity) => (
  {
    ...a,
    [entity.id]: {
      correct: null,
      id: entity.id,
      key: uuid(),
      label: (size(a) + 1),
      selected: null,
    },
  }
)

const findSelectedCard = ({ cards, target }) => (a, cardId) => (
  {
    ...a,
    [cardId]: {
      ...cards[cardId],
      correct: null,
      selected: cardId === target ? true : null,
    },
  }
)

const checkCardCorrected = ({ cards, answerId }) => (a, cardId) => {
  if (cards[cardId].selected) {
    return {
      ...a,
      [cardId]: {
        ...cards[cardId],
        correct: cardId === answerId,
      },
    }
  }
  return {
    ...a,
    [cardId]: {
      ...cards[cardId],
    },
  }
}

const groupEntitiesByQuestion = entitiesById => (a, item) => {
  if (item[1] || a.length === 0) {
    a.push([entitiesById[item[0]]])
  } else {
    a[a.length - 1].push(entitiesById[item[0]])
  }
  return a
}

const checkEntityTypePhrase = (a, entity) => {
  const { slug } = entity
  const REGEX = /^phrase/
  const question = REGEX.test(slug)
  return [
    ...a,
    [
      entity.id,
      question,
    ],
  ]
}

const createGroupOfParagraph = (a, entity) => {
  const { slug } = entity
  const REGEX = /^paragraph-|^audio-/
  const isParagraph = REGEX.test(slug)
  if (isParagraph) {
    return a.concat([[entity]])
  }
  a[a.length - 1].push(entity)
  return a
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities, cardsSize } = payload
      const choices = shuffle(chunk(entities, 2).reduce(createChoices, []))
      const [[question, answer]] = uniqShuffle(chunk(entities, 2))
      const { id: questionId } = question
      const { id: answerId } = answer
      const cards = shuffle(selectCards({ answerId, cardsSize, choices })).reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [question, answer],
        answerId,
        cards,
        entitiesById: keyBy(entities, 'id'),
        questionId,
      }
    }
    case INIT_FIXED: {
      const { entities, cardsSize = 3 } = payload
      const firstEntity = entities[0]
      const groupOfEntities = slice(entities, 1)
      const groupOfTripletEntities = chunk(groupOfEntities, cardsSize)
      const selectedTriplet = uniqShuffle(groupOfTripletEntities)[0]
      const cards = shuffle(selectedTriplet).reduce(createCardsForMultipleChoiceFixed, {})
      return {
        ...initialState,
        actualEntities: [firstEntity, selectedTriplet[0]],
        answerId: selectedTriplet[0].id,
        cards,
        entitiesById: keyBy(entities, 'id'),
        questionId: firstEntity.id,
      }
    }
    case INIT_PARAGRAPH: {
      const { entities, cardsSize = 3 } = payload
      const groupOfParagraphs = entities.reduce(createGroupOfParagraph, [])
      const selectedGroupOfParagraph = uniqShuffle(groupOfParagraphs)[0]
      const [paragraph, ...theEntities] = selectedGroupOfParagraph
      const shuffleEntities = uniqShuffle(chunk(theEntities, cardsSize + 1))[0]
      const question = shuffleEntities[0]
      const groupOfEntities = slice(shuffleEntities, 1)
      const cards = shuffle(groupOfEntities).reduce(createCardsForMultipleChoiceFixed, {})
      return {
        ...initialState,
        actualEntities: [paragraph, question, groupOfEntities[0]],
        answerId: groupOfEntities[0].id,
        cards,
        entitiesById: keyBy(entities, 'id'),
        paragraphEntity: {
          id: paragraph.id,
          image: { ...paragraph.image }.src,
          paragraph: { ...paragraph.meaning }.titleEn || { ...paragraph }.titleEn,
          sound: { ...paragraph.meaning }.sound,
        },
        questionId: question.id,
      }
    }
    case INIT_AUDIO: {
      const { entities, cardsSize = 3 } = payload
      const [audio, question, ...theEntities] = uniqShuffle(chunk(entities, cardsSize + 2))[0]
      const cards = shuffle(theEntities).reduce(createCardsForMultipleChoiceFixed, {})
      return {
        ...initialState,
        actualEntities: [audio, question, theEntities[0]],
        answerId: theEntities[0].id,
        cards,
        entitiesById: keyBy(entities, 'id'),
        paragraphEntity: {
          id: audio.id,
          image: { ...audio.image }.src,
          paragraph: { ...audio.meaning }.titleEn || { ...audio }.titleEn,
          sound: { ...audio.meaning }.sound,
        },
        questionId: question.id,
      }
    }
    case INIT_QUESTIONS: {
      const { entities } = payload
      const entitiesById = keyBy(entities, 'id')
      const entitiesByType = entities.reduce(checkEntityTypePhrase, [])
      const groupOfEntitiesByQuestion = entitiesByType.reduce(groupEntitiesByQuestion(entitiesById), [])
      const selectedGroup = uniqShuffle(groupOfEntitiesByQuestion)[0]
      const firstEntity = selectedGroup[0]
      const theAnswer = slice(selectedGroup, 1)[0]
      const groupOfWrongAnswers = chunk(shuffle(slice(selectedGroup, 2)), 2)[0] || {}
      const selectedTriplet = [theAnswer, ...groupOfWrongAnswers]
      const cards = shuffle(selectedTriplet).reduce(createCardsForMultipleChoiceFixed, {})
      return {
        ...initialState,
        actualEntities: [firstEntity, theAnswer],
        answerId: theAnswer.id,
        cards,
        entitiesById,
        questionId: firstEntity.id,
      }
    }
    case SELECT: {
      const { cardId } = payload
      const cards = Object.keys(state.cards).reduce(findSelectedCard({
        cards: state.cards, target: cardId,
      }), {})
      return {
        ...state,
        cards,
        selectedId: cardId,
        isMark: true,
      }
    }
    case MARK: {
      const { answerId } = state
      const selectedCard = find(state.cards, { selected: true })
      const cards = Object.keys(state.cards).reduce(checkCardCorrected({ answerId, cards: state.cards }), {})
      return {
        ...state,
        cards,
        completed: selectedCard ? answerId === selectedCard.id : null,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const initDefault = ({ entities, cardsSize }) => ({
  payload: {
    cardsSize,
    entities,
  },
  type: INIT,
})

const initFixed = ({ entities, cardsSize }) => ({
  payload: {
    cardsSize,
    entities,
  },
  type: INIT_FIXED,
})

const initParagraph = ({ entities, cardsSize }) => ({
  payload: {
    cardsSize,
    entities,
  },
  type: INIT_PARAGRAPH,
})

const initAudio = ({ entities, cardsSize }) => ({
  payload: {
    cardsSize,
    entities,
  },
  type: INIT_AUDIO,
})

const initQuestions = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT_QUESTIONS,
})

const mark = () => ({
  type: MARK,
})

const selectCard = ({ cardId }) => ({
  payload: {
    cardId,
  },
  type: SELECT,
})

const retry = () => ({
  type: RETRY,
})

export {
  initAudio,
  initDefault,
  initFixed,
  initParagraph,
  initQuestions,
  mark,
  retry,
  selectCard,
}
export default reducer
