import React from 'react'
import { t } from 'i18next'
import Icon from 'src/components/Icon'
import AnnouncementBar from 'src/components/AnnouncementBar'
import Div from 'src/components/Div'

const ParentLoginReminder = () => (
  <AnnouncementBar>
    <Div relative>
      <Div
        absolute
        inline-block
        right-0
        style={{ lineHeight: '48px', paddingRight: '3rem' }}
      >
        <span className="bold">
          {t('login.parentLogin')} <Icon icon="arrow-up" />
        </span>
        <span style={{ visibility: 'hidden' }}>{t('logout.button')}</span>
      </Div>
    </Div>
  </AnnouncementBar>
)

export default ParentLoginReminder
