import React from 'react'
import { arrayOf, string, shape, func } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import EntityBubble from 'src/components/EntityBubble'
import mapKey from 'src/lib/mapKey'

const propTypes = {
  answers: arrayOf(shape(entityShape).isRequired).isRequired,
  onSelectAnswer: func.isRequired,
  selected: string,
  word: shape(entityShape).isRequired,
}

const defaultProps = {
  selected: null,
}

const OddOneOutScene = ({ answers, onSelectAnswer, selected, word }) => (
  <Deck>
    <Div flex auto mb3>
      <EntityBubble className="qa-word" image audio english entity={word} label=" " />
    </Div>
    <Div flex justify="center" gutter mb3>
      {
        answers.map((answer, i) => (
          <EntityBubble
            key={mapKey(i)}
            className="qa-answer"
            selected={selected === answer.id}
            entity={answer}
            audio
            english
            label={i + 1}
            onKeyPress={() => onSelectAnswer(answer.id)}
            onClick={() => onSelectAnswer(answer.id)}
          />
        ))
      }
    </Div>
  </Deck>
)

OddOneOutScene.propTypes = propTypes
OddOneOutScene.defaultProps = defaultProps

export default OddOneOutScene
