const variableValidation = (variable, type = 'string') => {
  switch (type) {
    case 'string':
      if (typeof variable === 'string') return variable
      return ''
    case 'number':
      if (typeof variable === 'number') return variable
      return -1
    case 'boolean':
      if (typeof variable === 'boolean') return variable
      return false
    default:
      return ''
  }
}

export default variableValidation
