import React from 'react'
import noop from 'lodash/noop'
import { arrayOf, bool, shape } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import entityShape from 'src/shapes/entity'
import { init, retry } from 'src/modules/ListenAndSpeak/reducer'
import ListenAndSpeakScene from 'src/modules/ListenAndSpeak/ListenAndSpeakSceneContainer'

const propTypes = {
  ...gamePropTypes,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
  fixed: bool,
}

const defaultProps = {
  fixed: false,
}

class ListenAndSpeakGame extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, init, fixed } = this.props
    init({ entities, fixed })
  }

  render() {
    const { completed, retry } = this.props
    const { init } = this
    return (
      <Game {
        ...{
          completed,
          init,
          retry,
          mark: noop,
        }}
      >
        <ListenAndSpeakScene />
      </Game>
    )
  }
}

ListenAndSpeakGame.propTypes = propTypes
ListenAndSpeakGame.defaultProps = defaultProps

export default connect(
  (({ listenAndSpeak }) => listenAndSpeak),
  dispatch => bindActionCreators({ init, retry }, dispatch),
)(ListenAndSpeakGame)
