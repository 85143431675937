import React from 'react'
import { array, arrayOf, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Word from 'src/modules/Word'

const propTypes = {
  alphabetEn: array.isRequired,
  entities: arrayOf(
    shape(entityShape),
  ),
  entity: shape(entityShape),
}
const defaultProps = {
  entities: [],
  entity: undefined,
}

const WordSpelling = ({ alphabetEn, entities, entity }) => {
  const theEntity = entity || entities[0]
  const { id, image, phonicsEn, sound: { srcEn, srcTh }, slug, titleEn, titleTh } = theEntity
  return (
    <Word
      alphabetEn={alphabetEn}
      image={{ image }}
      firstLine={{ id, slug, sound: { src: srcEn }, title: titleEn }}
      phonics={phonicsEn}
      lastLine={{ id, slug, sound: { src: srcTh }, title: titleTh }}
      spelling
    />
  )
}

WordSpelling.propTypes = propTypes
WordSpelling.defaultProps = defaultProps

export default WordSpelling
