import fill from 'lodash/fill'
import isEqual from 'lodash/isEqual'
import findIndex from 'lodash/findIndex'
import uniqShuffle from 'src/lib/uniqShuffle'
import replaceAt from 'src/lib/replaceAt'
import alphabetEn from 'src/constants/alphabetEn'
import { randomizedKeyboard, KEY_STATE, keyLetter, updateKeyboard } from 'src/modules/FillInMissingLetters/keyboard'
import withPhonics from 'src/modules/FillInMissingLetters/withPhonics'

const PREFIX = 'FillInMissingLetters'

const INIT = `${PREFIX}/INIT`
const NEXT = `${PREFIX}/NEXT`
const KEYPRESS = `${PREFIX}/KEYPRESS`
const AUDIO_DONE = `${PREFIX}/AUDIO_DONE`
const DISPLAY_RESULT = `${PREFIX}/DISPLAY_RESULT`
const MARK = `${PREFIX}/MARK`
const BACKSPACE = `${PREFIX}/BACKSPACE`

const initialRoundState = {
  completed: null,
  entity: null,
  keyboard: [],
  lastKey: null,
  letterIndex: 0,
  letters: [],
  wordCompleted: null,
}
const initialState = {
  ...initialRoundState,
  actualEntities: [],
  nextEntityIndex: 0,
  phonicsAlphabet: [],
  word: [],
}

const HIDDEN_LETTER = '_'

const init = ({ entities, phonicsAlphabet }) => ({
  payload: { entities, phonicsAlphabet },
  type: INIT,
})
const nextRound = () => ({ type: NEXT })
const onLetterAudioDone = () => ({ type: AUDIO_DONE })
const displayResult = () => ({ type: DISPLAY_RESULT })
const mark = () => ({ type: MARK })
const backspace = () => ({ type: BACKSPACE })
const onKey = keyIndex => ({ payload: { keyIndex }, type: KEYPRESS })

const doNextRound = ({ actualEntities, nextEntityIndex, phonicsAlphabet }) => {
  const entity = actualEntities[nextEntityIndex]
  const word = entity.titleEn
  const keyboard = withPhonics(phonicsAlphabet, randomizedKeyboard(word, alphabetEn))
  const letters = fill([...word], HIDDEN_LETTER, 0)

  return {
    ...initialRoundState,
    entity,
    keyboard,
    letters,
    nextEntityIndex: (nextEntityIndex + 1) % actualEntities.length,
    word: [...word],
  }
}

const doInit = ({ entities, phonicsAlphabet }) => {
  const actualEntities = uniqShuffle(entities).slice(0, 5)
  const nextEntityIndex = 0
  const round = doNextRound({ actualEntities, nextEntityIndex, phonicsAlphabet })

  return {
    actualEntities,
    phonicsAlphabet,
    ...round,
  }
}

const checkWord = ({ letters, word }) => isEqual(letters, word)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT:
      return { ...initialState, ...doInit(payload) }

    case NEXT:
      return { ...state, ...doNextRound(state) }

    case KEYPRESS: {
      const { keyIndex } = payload
      const { word, keyboard, letterIndex, letters } = state

      if (letterIndex >= word.length) return { ...state }

      const letter = keyLetter(keyboard[keyIndex])
      const expectedLetter = word[letterIndex]
      const keyIsCorrect = letter === expectedLetter
      const newLetters = replaceAt(letters, letterIndex, letter)
      const keyState = keyIsCorrect ? KEY_STATE.CORRECT : KEY_STATE.ERROR

      return {
        ...state,
        keyboard: updateKeyboard(keyboard, keyIndex, keyState),
        lastKey: keyboard[keyIndex],
        letterIndex: letterIndex + 1,
        letters: newLetters,
      }
    }

    case AUDIO_DONE:
      return { ...state, wordCompleted: checkWord(state) || state.wordCompleted }

    case DISPLAY_RESULT:
      return { ...state, completed: state.wordCompleted }

    case BACKSPACE: {
      const { keyboard, letterIndex, letters } = state

      if (letterIndex === 0) return { ...state }

      const letter = letters[letterIndex - 1]
      const keyIndex = findIndex(keyboard, key => key.letter === letter && key.state !== null)
      const newLetters = replaceAt(letters, letterIndex - 1, HIDDEN_LETTER)

      return {
        ...state,
        keyboard: updateKeyboard(keyboard, keyIndex, KEY_STATE.UNUSED),
        lastKey: null,
        letterIndex: letterIndex - 1,
        letters: newLetters,
      }
    }

    case MARK:
      return { ...state, wordCompleted: checkWord(state) }

    default:
      return state
  }
}

const ActionCreators = {
  backspace,
  displayResult,
  init,
  mark,
  nextRound,
  onKey,
  onLetterAudioDone,
}

export { reducer as default, ActionCreators }
