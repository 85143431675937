import React from 'react'
import { string, shape } from 'prop-types'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'

const propTypes = {
  student: shape({
    fullName: string.isRequired,
    year: shape({
      title: string.isRequired,
    }),
  }).isRequired,
}

const SelectedLabel = ({ student, ...props }) => {
  if (!student) return null
  return (
    <Div p1 auto rounded bg-white {...props}>
      <div className="h4 bold flex px1">
        <Div>
          <Icon before icon="child" />
          {student.fullName}
        </Div>
        {student.year && (
          <Div ml2>
            <Icon before icon="users" />
            {student.year.title}
          </Div>
        )}
      </div>
    </Div>
  )
}

SelectedLabel.propTypes = propTypes
export default SelectedLabel
