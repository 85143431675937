import { number, string } from 'prop-types'

const entityShape = {
  answer: string,
  cardId: string,
  correct: string,
  entityId: string,
  id: string,
  index: number,
  question: string,
  title: string,
}

export default entityShape
