import React from 'react'
import { array, func, object } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as allActions from 'src/modules/MatchImageWithText/reducer'
import MatchImageWithText from 'src/modules/MatchImageWithText/MatchImageWithText'

const propTypes = {
  ...gamePropTypes,
  cards: object.isRequired,
  entities: array.isRequired,
  moveCard: func.isRequired,
  places: object.isRequired,
}

const defaultProps = {
  completed: null,
  variants: 3,
}

class MatchImageWithTextContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      mark,
      retry,
      completed,
      cards,
      places,
      moveCard,
      actualEntities,
    } = this.props
    return (
      <Game {
        ...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <MatchImageWithText {...{ cards, moveCard, places }} />
      </Game>
    )
  }
}

MatchImageWithTextContainer.propTypes = propTypes
MatchImageWithTextContainer.defaultProps = defaultProps
MatchImageWithTextContainer.demoData = {
  entities: [
    'mother',
    'father',
    'family',
  ],
}

const selector = state => state.matchImageWithText
const actions = dispatch => bindActionCreators(allActions, dispatch)

export { MatchImageWithTextContainer }
export default connect(selector, actions)(MatchImageWithTextContainer)
