import React from 'react'
import { useParams } from 'react-router'
import { curriculumFullPath } from 'src/lib/routes'
import Curriculum from 'src/pages/Curriculum/Curriculum'

const CurriculumPage = () => {
  const {
    gradeOrSubjectFirst,
    gradeOrSubjectSecond,
    firstId,
    secondId,
  } = useParams()
  const { grade: gradeSlug, subject: subjectSlug } = {
    [gradeOrSubjectFirst]: firstId,
    [gradeOrSubjectSecond]: secondId,
  }
  return (
    <Curriculum
      {...{
        gradeSlug,
        subjectSlug,
      }}
      curriculumPath={curriculumFullPath}
    />
  )
}

export default CurriculumPage
