import React from 'react'
import classnames from 'classnames'
import { string, shape, bool, func } from 'prop-types'
import { Link } from 'react-router-dom'
import { dayModifierShape } from 'src/shapes/calendar'
import { calendarPath } from 'src/lib/routes'
import Tooltip from 'src/components/Tooltip'
import { dateToday, formatDayShortNumeric } from 'src/lib/dates'
import usePath from 'src/lib/routes/usePath'

const classNamePrefix = 'rc-Day'

const propTypes = {
  date: string,
  datePath: func,
  modifier: shape(dayModifierShape),
  outside: bool.isRequired,
}

const defaultProps = {
  date: dateToday(),
  modifier: {},
}

const Day = ({
  date,
  modifier,
  outside,
  datePath: propsDatePath,
}) => {
  const defaultDatePath = usePath(calendarPath)
  const datePath = propsDatePath || (date => defaultDatePath({ date }))
  const { tooltips = [], className } = modifier
  const disabledTooltip = outside || tooltips.length < 1
  const classNames = classnames(
    classNamePrefix,
    'cell',
    { outside },
    className,
    `date-${date}`,
  )
  return (
    <Tooltip
      tips={tooltips}
      disabled={disabledTooltip}
      cursor="pointer"
    >
      <Link
        to={datePath(date) || '#'}
        style={{ color: 'black', textDecoration: 'none' }}
      >
        <div className={classNames}>
          <span>
            {formatDayShortNumeric(date)}
          </span>
        </div>
      </Link>
    </Tooltip>
  )
}

Day.propTypes = propTypes
Day.defaultProps = defaultProps

export {
  classNamePrefix,
}
export default Day
