import { shuffle } from 'lodash'
import { bool, arrayOf, shape, string } from 'prop-types'

const KEY_STATE = { CORRECT: true, ERROR: false, UNUSED: null }
Object.freeze(KEY_STATE)

const createKey = letter => ({ letter, state: KEY_STATE.UNUSED })

const buildKeyboard = (chars, alphabet) => shuffle([
  ...chars,
  ...(shuffle(
    alphabet.filter(l => !chars.includes(l)),
  ).slice(0, 2)),
]).map(createKey)

const randomizedKeyboard = (word, alphabet) => buildKeyboard(word.toLowerCase(), alphabet)

const updateKeyboard = (keyb, index, state) => (
  keyb.map((key, keyIndex) => (
    keyIndex === index ? { ...key, state } : { ...key, state: key.state || null }
  ))
)

const keyLetter = key => key.letter
const keyAvailable = key => key.state === KEY_STATE.UNUSED

const unusedKeyIndexMap = (keyboard) => {
  const notNullOr = (current, provided) => (current == null ? provided : current)
  return keyboard
    .map(({ letter, state }, index) => ({ index, letter, used: !!state }))
    .filter(item => !item.used)
    .reduce((acc, { letter, index }) => ({ ...acc, [letter]: notNullOr(acc[letter], index) }), {})
}


const keyShape = {
  letter: string.isRequired,
  state: bool,
}
const keyboardShape = arrayOf(shape(keyShape).isRequired)

export {
  createKey,
  keyboardShape,
  randomizedKeyboard,
  keyLetter,
  keyAvailable,
  unusedKeyIndexMap,
  updateKeyboard,
  KEY_STATE,
}
