import every from 'lodash/every'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import moveCards from 'src/reducers/lib/moveCards'
import transformValues from 'src/lib/transformValues'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'familyTree/INIT'
const MARK = 'familyTree/MARK'
const MOVE = 'familyTree/MOVE'
const RETRY = 'familyTree/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  entitiesById: {},
  placeLocations: [],
  places: {},
}

const createCard = (a, entity) => ({
  ...a,
  [entity.id]: {
    correct: undefined,
    id: entity.id,
    placeId: null,
    title: entity.titleEn,
  },
})

const createPlace = (a, entity) => {
  const { places } = a
  const newPlace = {
    [entity.id]: {
      cardId: null,
      id: entity.id,
      title: entity.titleEn,
    },
  }
  return {
    ...a,
    places: {
      ...places,
      ...newPlace,
    },
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities } = payload
      const cards = uniqShuffle(entities).reduce(createCard, {})
      const { places } = entities.reduce(createPlace, {})
      const placeIds = map(places, 'id')
      const placeLocations = [
        placeIds.slice(0, 4),
        placeIds.slice(4, 6),
        placeIds.slice(6, 10),
      ]
      return {
        ...initialState,
        actualEntities: entities,
        cards,
        completed: null,
        entitiesById: keyBy(entities, 'id'),
        placeLocations,
        places,
      }
    }

    case MARK: {
      const { cards, places } = state
      const checkedCards = transformValues(cards, card => ({
        ...card,
        correct: card.placeId && card.title === places[card.placeId].title,
      }))
      const completed = every(checkedCards, 'correct')
      return {
        ...state,
        cards: {
          ...checkedCards,
        },
        completed,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      return moveCards(state, sourceId, targetId)
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ familyFaces, entities }) => ({
  payload: {
    entities: familyFaces || entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  moveCard,
  retry,
}

export default reducer
