/* eslint max-len: 0 */

import AgeComparison from 'src/modules/AgeComparison'
import AlphabetFlashCard, { AlphabetCursiveFlashCard } from 'src/modules/AlphabetFlashCard'
import BarChart, { BarChartWordCount } from 'src/modules/BarChart'
import BlendingSounds from 'src/modules/BlendingSounds'
import ComicStripDragAndDrop, {
  ComicStripDragAndDropCursiveText,
} from 'src/modules/ComicStripDragAndDrop'
import ComicStripFillInTheBlank from 'src/modules/ComicStripFillInTheBlank'
import CurriculumMapping from 'src/modules/CurriculumMapping'
import DrawLetters from 'src/modules/DrawLetters'
import FamilyTreeShuffled from 'src/modules/FamilyTreeShuffled'
import FamilyTreeSlideshow from 'src/modules/FamilyTreeSlideshow'
import FillInMissingLetters from 'src/modules/FillInMissingLetters'
import {
  FillInMissingWords,
  FillInMissingWordsNoImage,
  FillInMissingWordsNoImageFixed,
} from 'src/modules/FillInMissingWords'
import FillInMissingWordType from 'src/modules/FillInMissingWordType'
import FindTheMissingImage from 'src/modules/FindTheMissingImage'
import FollowTheInstructions from 'src/modules/FollowTheInstructions'
import HomeworkDone from 'src/modules/HomeworkDone'
import Hangman from 'src/modules/Hangman'
import Image from 'src/modules/Image'
import ImageTimerWords from 'src/modules/ImageTimerWords'
import LengthUnits from 'src/modules/math/LengthUnits'
import LetterFormation from 'src/modules/LetterFormation'
import {
  ListenAndSpeak,
  ListenAndSpeakFixed,
} from 'src/modules/ListenAndSpeak'
import ListenAndOrderSentences from 'src/modules/ListenAndOrderSentences'
import ListenAndSelect, { ListenAndSelectFixed } from 'src/modules/ListenAndSelect'
import {
  ListenAndShootImages,
  ListenAndShootCursiveText,
  ListenAndShootPrintedText,
  ListenAndShootPrintedAndCursiveText,
} from 'src/modules/ListenAndShoot'
import ListenAndTypeSentences from 'src/modules/ListenAndTypeSentences'
import ListenAndTypeWords from 'src/modules/ListenAndTypeWords'
import {
  ListeningPhonemes2,
  ListeningPhonemes3,
} from 'src/modules/ListeningPhonemes'
import ListeningWords, { ListeningWordsPaired } from 'src/modules/ListeningWords'
import {
  ListeningSounds2,
  ListeningSounds3,
} from 'src/modules/ListeningSounds'
import MatchCardsFlip from 'src/modules/MatchCardsFlip'
import MatchTextWithText from 'src/modules/MatchTextWithText'
import MatchImageWithText from 'src/modules/MatchImageWithText'
import MatchTextWithImage from 'src/modules/MatchTextWithImage'
import MatchTranslation from 'src/modules/MatchTranslation'
import MultipleChoiceMissingWordsAudio3, { MultipleChoiceMissingWordsAudio2 } from 'src/modules/MultipleChoiceMissingWordsAudio'
import Objectives from 'src/modules/Objectives'
import OddOneOut from 'src/modules/OddOneOut'
import { ParagraphMissingWords, SentencePunctuationMarker, ParagraphMissingPunctuation } from 'src/modules/ParagraphMissingWords'
import OrderingSentences from 'src/modules/OrderingSentences'
import PartsOfSpeech from 'src/modules/PartsOfSpeech'
import { PhonicBlends1, PhonicBlends2 } from 'src/modules/PhonicBlends'
import PhonicsLetters from 'src/modules/PhonicsLetters'
import PhonicsSample from 'src/modules/PhonicsSample'
import PhonicsSounds from 'src/modules/PhonicsSounds'
import PhonicsWords from 'src/modules/PhonicsWords'
import Placeholder from 'src/modules/Placeholder'
import ProvideAnswer, {
  ProvideQuestion,
  ProvideAnswerWithSpeaker,
  ProvideQuestionWithSpeaker,
} from 'src/modules/ProvidePhrase'
import SelectParagraphTitle from 'src/modules/SelectParagraphTitle'
import SortingWordsLesson, { SortingImagesLesson, SortingImagesHomework, SortingWordsHomework } from 'src/modules/Sorting'
import SpeechBubblesComic from 'src/modules/SpeechBubblesComic'
import SpeechBubblesConversation, { SpeechBubblesConversationWithThai } from 'src/modules/SpeechBubblesConversation'
import SpeechBubblesQuestionAndAnswer, { SpeechBubblesQuestionAndAnswerWithThai } from 'src/modules/SpeechBubblesQuestionAndAnswer'
import {
  SpeechRecognition,
  SpeechRecognitionProvideAnswers,
  SpeechRecognitionProvideQuestions,
  SpeechRecognitionHideAnswer,
  SpeechRecognitionHideQuestion,
  SpeechRecognitionImage,
  SpeechRecognitionPrintedAndCursiveText,
} from 'src/modules/SpeechRecognition'
import { StoryEnglish, StoryThai } from 'src/modules/Story'
import TitlePage from 'src/modules/TitlePage'
import { SentenceMarker, ToneMarkerWord } from 'src/modules/ToneMarker'
import {
  CorrectOrIncorrect,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
  TrueOrFalseImage,
  TrueOrFalseParagraphs,
} from 'src/modules/SelectType'
import TypeAndSave from 'src/modules/TypeAndSave'
import UnscrambleWords from 'src/modules/UnscrambleWords'
import Video from 'src/modules/Video'
import Dictionary from 'src/modules/Dictionary'
import VocabularyEnglishAndThai, { VocabularyPhrasesEnglishAndThai } from 'src/modules/VocabularyEnglishAndThai'
import VocabularyReview from 'src/modules/VocabularyFlashCards'
import VocabularyAndSentenceExamples from 'src/modules/VocabularyAndSentenceExamples'
import WellDone from 'src/modules/WellDone'
import WordBoard from 'src/modules/WordBoard'
import { LabelImageDiagram, LabelImageGrid } from 'src/modules/LabelImage'
import { MatchCards } from 'src/modules/MatchCards'
import {
  MultipleChoice,
  MultipleChoiceFixed,
  MultipleChoiceFixedMuted,
  MultipleChoiceImage,
  MultipleChoiceFixedQuestions,
  MultipleChoiceThai,
  MultipleChoiceParagraph2,
  MultipleChoiceParagraph3,
  MultipleChoiceParagraph4,
  MultipleChoiceAudio2,
  MultipleChoiceAudio3,
  MultipleChoiceAudio4,
} from 'src/modules/MultipleChoice'
import UnitTestMultipleChoice,
{ UnitTestMultipleChoiceImage } from 'src/modules/UnitTestMultipleChoice'
import { MultipleChoiceMultipleParagraphs } from 'src/modules/MultipleChoiceMultipleParagraphs'
import Lyrics from 'src/modules/Lyrics'

export {
  AgeComparison, // Splits entities in 2 groups, drag title to the correct image
  AlphabetCursiveFlashCard, // No entities required
  AlphabetFlashCard, // No entities required
  BarChart, // paragraph entity, and following with group of word and number entity
  BarChartWordCount, // paragraph entity, and following with word entities
  BlendingSounds, // `phonics-` entities, that make a word, correct answer, incorrect answers. `PPPCIIIPPCII`
  ComicStripDragAndDrop, // Add entities that make up the story
  ComicStripDragAndDropCursiveText, // Add entities that make up the story
  ComicStripFillInTheBlank, // Add Pair of entities that make up the story
  CorrectOrIncorrect, // collection-correct entity, following with correct entities, collection-incorrect entity, following with incorrect entities
  CurriculumMapping, // No params required. Displays indicators and other curriculum information for the current lesson.
  Dictionary,
  DrawLetters, // `phonics-` entities, [`phonics-abc`, `phonics-xyz`, `phonics-car`]
  FamilyTreeShuffled, // Family Tree with shuffled family members
  FamilyTreeSlideshow,
  FillInMissingLetters, // Entities: Fill missing letters with letters underneath
  FillInMissingWords, // `phrase-` and one or more words entities, `PW+PW+PW+`
  FillInMissingWordsNoImage, // `phrase-` and one or more words entities, `PW+PW+PW+`
  FillInMissingWordsNoImageFixed, // `phrase-`, correct and one or more incorrect, `PCI+PCI+PCI+`
  FillInMissingWordType, // `phrase-` and word entities, `PWPWPW`
  FindTheMissingImage, // Entities
  FollowTheInstructions, // collection entity, following with entities of the collection.
  Hangman,
  HomeworkDone, // No params required
  Image, // Displays an image from the entity
  ImageTimerWords, // Entities
  LabelImageDiagram, // First entity is used as a background image, the rest are spread from top to bottom
  LabelImageGrid, // First entity is used as a background image, the rest are spread from left to right for 4 entities, than start to new line
  LengthUnits,
  LetterFormation, // `letter-en-` or `phonics-` entities corresponding to letters that should be displayed. Will display all letters if no entities provided.
  ListenAndSpeakFixed, // Phrase to listen, then pairs of phrase to fill answer in and correct answer. `P PC PC PC`
  ListenAndSpeak, // Phrase to listen, phrase to fill answer into, correct answer `PPC PPC PPC`
  ListenAndOrderSentences, // Select one phrase from the list and break it down to words. Click the image to head the sound, rebuild the sentence.
  ListenAndSelect, // Entities
  ListenAndSelectFixed, // Correct, following with incorrect, and incorrect, `CIICII`
  ListenAndShootCursiveText, // Entities
  ListenAndShootImages, // Entities
  ListenAndShootPrintedAndCursiveText, // Entities
  ListenAndShootPrintedText, // Entities
  ListenAndTypeSentences, // Entities
  ListenAndTypeWords, // Entities
  ListeningPhonemes2, // Words separated by `collection-`, `letter-` or `phonics-`: `CWW LWW PWW`. Two choices at a time
  ListeningPhonemes3, // Words separated by `collection-`, `letter-` or `phonics-`: `CWW LWW PWW`. No more than three choices at a time
  ListeningSounds2, // `phonics-*`, `phonics-*`,... ``. Two of the list randomly selected per round
  ListeningSounds3, // `phonics-*`, `phonics-*`,... ``. Three of the list randomly selected per round
  ListeningWords, // Words separated by `collection-`, `letter-` or `phonics-`: `CWW LWW PWW`
  ListeningWordsPaired, // Pairs of entities that sound similar
  Lyrics, // Type lyrics below:
  MatchCards,
  MatchCardsFlip,
  MatchImageWithText,
  MatchTextWithImage,
  MatchTextWithText, // Two or more collections: `collection-1`, `collection-2`, `collection-3`, then groups of words in the same order: `word1`, `word2`, `word3`, `w1`, `w2`, `w3` ...
  MatchTranslation,
  MultipleChoice, // Pair of question and answer entities, `QAQAQA`.
  MultipleChoiceFixed, // Question, following with correct, incorrect, and incorrect, `QCIICII`
  MultipleChoiceFixedMuted, // Question, following with correct, incorrect, and incorrect, `QCIICII`
  MultipleChoiceFixedQuestions, // Phrase, following with correct entity, and any incorrect entity `PEEEPEEPEEEE`
  MultipleChoiceImage, // entities.
  MultipleChoiceMissingWordsAudio2, // groups of exactly 4 [Audio, Question, Correct, Incorrects]. [PQAA][PQAA]
  MultipleChoiceMissingWordsAudio3, // groups of exactly 5 [Audio, Question, Correct, 2x Incorrects]. [PQAAA][PQAAA]
  MultipleChoiceMultipleParagraphs, // many paragraph entities following with Qustion, Correct, Incorrect, Incorrect entities, `PPQCIIQCIICII`
  MultipleChoiceParagraph2, // Paragraph Entity, followed by groups of exactly 3 [Question, Correct, Incorrect]. [[PQAAQAA][PQAA]]
  MultipleChoiceParagraph3, // Paragraph Entity, followed by groups of exactly 4 [Question, Correct, 2x Incorrects]. [[PQAAAQAAA][PQAAA]]
  MultipleChoiceParagraph4, // Paragraph Entity, followed by groups of exactly 5 [Question, Correct, 3x Incorrects]. [[PQAAAAQAAAA][PQAAAA]]
  MultipleChoiceAudio2, // groups of exactly 4 [Audio, Question, Correct, Incorrects]. [PQAA][PQAA]
  MultipleChoiceAudio3, // groups of exactly 5 [Audio, Question, Correct, 2x Incorrects]. [PQAAA][PQAAA]
  MultipleChoiceAudio4, // groups of exactly 6 [Audio, Question, Correct, 3x Incorrects]. [PQAAAA][PQAAAA]
  MultipleChoiceThai, // Pair of question and answer entities.
  Objectives,
  OddOneOut, // Word, incorrect answer (`phonics-*`), any number of correct answers (`phonics-*`), next word, next incorrect answer... `WICCCWICWICC`
  OrderingSentences, // Order of Sentences
  ParagraphMissingWords, // First entity is paragraph, then choice entities.
  ParagraphMissingPunctuation, // Add phrase- or paragraph- entities. The meaning will be used if it's present. Title otherwise.
  PartsOfSpeech, // First entity is instruction (I), following with group of paragraph or phrase (P) and word (W) entities. `IPWPWWPWWW`.
  PhonicBlends1, // Word, its `phonics-`. Each word is a different game. `WPP WPPP WP`
  PhonicBlends2, // Word, its `phonics-`. Each consecutive pair of words is a different game. `WPPWPPP WPWPP`
  PhonicsLetters, // `letter-en-` letters that should be displayed. Will display all letters if no entities provided.
  PhonicsSample, // `letter-en-` or `phonics-` entities corresponding to phonics that should be displayed. Will display phonics for all letters if no entities provided.
  PhonicsSounds, // slides: `phonics-` phonics and an optional entity as an example. `PWPPPWP`
  PhonicsWords, // slides: word followed by its `phonics-`. `WPPPWPPWPPPP`
  Placeholder,
  ProvideAnswer, // Pair of question and answer entities
  ProvideAnswerWithSpeaker, // First entity is the person speaking, then pair of question and answer entities
  ProvideQuestion, // Pair of question and answer entities
  ProvideQuestionWithSpeaker, // First entity is the person speaking, then pair of question and answer entities
  SelectParagraphTitle, // 3+ paragraph entities
  SentenceMarker, // First incorrect phrase entity, phrase entity, and word entity
  SentencePunctuationMarker, // Add phrase- or paragraph- entities. The meaning will be used if it's present. Title otherwise.
  SortingImagesHomework, // `collection-`, followed by the list of words, then another `collection-` and its words `CWWWCWWW`
  SortingImagesLesson, // `collection-`, followed by the list of words, then another `collection-` and its words `CWWWCWWW`
  SortingWordsHomework, // `collection-`, followed by the list of words, then another `collection-` and its words `CWWWCWWW`
  SortingWordsLesson, // `collection-`, followed by the list of words, then another `collection-` and its words `CWWWCWWW`
  SpeechBubblesComic, // Pairs of entities. First entity of each pair is used for an image.
  SpeechBubblesConversation, // First entity should be left speaker image, Second entity should be right speaker image, followed by the list of conversation entities.
  SpeechBubblesConversationWithThai, // First entity should be left speaker image, Second entity should be right speaker image, followed by the list of conversation entities.
  SpeechBubblesQuestionAndAnswer, // Pair of question and answer entities.
  SpeechBubblesQuestionAndAnswerWithThai, // Pair of question and answer entities.
  SpeechRecognition, // Entities
  SpeechRecognitionHideAnswer, // Pair of question and answer entities
  SpeechRecognitionHideQuestion, // Pair of question and answer entities
  SpeechRecognitionImage, // Entities
  SpeechRecognitionPrintedAndCursiveText, // Entities
  SpeechRecognitionProvideAnswers, // Pair of question and answer entities
  SpeechRecognitionProvideQuestions, // Pair of question and answer entities
  StoryEnglish, // One or more (will be displayed in columns) entities. The meaning will be used if it's present. Title otherwise. Supports markdown.
  StoryThai, // One or more (will be displayed in columns) entities. The meaning will be used if it's present. Title otherwise. Supports markdown.
  TitlePage, // First entity is displayed as a cover image
  ToneMarkerWord, // Entities
  TrueOrFalseAudio3, // First paragraph entity, collection-true entity, following with true entities, collection-false entity, following with false entities
  TrueOrFalseAudio4, // First paragraph entity, collection-true entity, following with true entities, collection-false entity, following with false entities
  TrueOrFalseImage, // First paragraph entity, collection-true entity, following with true entities, collection-false entity, following with false entities
  TrueOrFalseParagraphs, // First paragraph entities, collection-true entity, following with true entities, collection-false entity, following with false entities
  TypeAndSave, // Entity, that will use as question
  UnitTestMultipleChoice, // groups of exactly 5 [Question, Correct, 3x Incorrects]. [Q[AAAA][AAAA]]
  UnitTestMultipleChoiceImage, // groups of exactly 5 [Question, Correct, 3x Incorrects]. [Q[AAAA][AAAA]]
  UnscrambleWords,
  Video,
  VocabularyAndSentenceExamples, // Pairs of: Word (or phrase), Example phrase are needed, examples: father, phrase-he-is-father or phrase-he-is, phrase-he-is-tom, the image is taken from the phrase. if image of phrase is empty, the image will be taken from the word.
  VocabularyEnglishAndThai, // Entities
  VocabularyPhrasesEnglishAndThai, // Entities
  VocabularyReview,
  WellDone,
  WordBoard,
}
