/* eslint-disable react/prop-types */

import React from 'react'
import { any, bool, func, number, object, oneOfType, string } from 'prop-types'
import normalizeImage from 'src/lib/normalizeImage.js'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import Bubble from 'src/components/Bubble'
import EntityBubble from 'src/components/EntityBubble'

const propTypes = {
  audio: bool,
  autoPlay: bool,
  bounce: bool,
  children: any,
  col: number,
  correct: bool,
  english: bool,
  entity: object,
  gutter: number,
  hasTip: bool,
  image: bool,
  justify: string,
  label: oneOfType([string, number]),
  mbn: oneOfType([bool, number]),
  mln: oneOfType([bool, number]),
  mrn: oneOfType([bool, number]),
  onClick: func,
  onKeyPress: func,
  pair: bool,
  play: bool,
  right: bool,
  selected: bool,
  silent: bool,
  speakerEntity: object,
  speakerHeight: number,
  thai: bool,
  tip: string,
}

const defaultProps = {
  autoPlay: false,
  bounce: null,
  entity: null,
  gutter: null,
  hasTip: false,
  label: null,
  mln: null,
  mrn: null,
  onKeyPress: null,
  pair: false,
  right: null,
  speakerCol: 3,
}

const renderLeftSpeaker = ({
  autoPlay,
  bounce,
  children,
  english,
  entity,
  gutter,
  hasTip,
  image,
  justify,
  label,
  mln,
  mrn,
  onKeyPress,
  pair,
  speakerCol,
  speakerEntity,
  speakerHeight,
  thai,
}) => {
  const speakerImage = { ...speakerEntity }.image
  const speakerSrc = { ...speakerImage }.src
  return (
    <Div flex auto>
      {
        speakerEntity && normalizeImage(speakerSrc) && (
          <Div flex col={speakerCol} height={speakerHeight}>
            <EntityImage entity={speakerEntity} />
          </Div>
        )
      }
      <Div flex auto flex-1>
        {
          entity ? (
            <EntityBubble
              autoPlay={autoPlay}
              audio
              bounce={bounce}
              english={english}
              entity={entity}
              gutter={gutter}
              image={image}
              label={label}
              mln={mln}
              mrn={mrn}
              onKeyPress={onKeyPress}
              pair={pair}
              thai={thai}
              tip={hasTip && normalizeImage(speakerSrc) ? 'left' : null}
            />
          ) : (
            <Bubble
              bounce={bounce}
              gutter={gutter}
              justify={justify}
              label={label}
              mln={mln}
              mrn={mrn}
              onKeyPress={onKeyPress}
              tip={hasTip && normalizeImage(speakerSrc) ? 'left' : null}
            >
              {children}
            </Bubble>
          )}
      </Div>
    </Div>
  )
}

const renderRightSpeaker = ({
  autoPlay,
  bounce,
  children,
  english,
  entity,
  gutter,
  hasTip,
  image,
  justify,
  label,
  mln,
  mrn,
  onKeyPress,
  pair,
  speakerCol,
  speakerEntity,
  speakerHeight,
  thai,
}) => {
  const speakerImage = { ...speakerEntity }.image
  const speakerSrc = { ...speakerImage }.src
  return (
    <Div flex auto>
      <Div flex auto flex-1>
        {
          entity ? (
            <EntityBubble
              autoPlay={autoPlay}
              audio
              bounce={bounce}
              english={english}
              entity={entity}
              gutter={gutter}
              image={image}
              label={label}
              mln={mln}
              mrn={mrn}
              onKeyPress={onKeyPress}
              pair={pair}
              thai={thai}
              tip={hasTip && normalizeImage(speakerSrc) ? 'right' : null}
            />
          ) : (
            <Bubble
              bounce={bounce}
              gutter={gutter}
              justify={justify}
              label={label}
              mln={mln}
              mrn={mrn}
              onKeyPress={onKeyPress}
              tip={hasTip && normalizeImage(speakerSrc) ? 'right' : null}
            >
              {children}
            </Bubble>
          )}
      </Div>
      {
        speakerEntity && normalizeImage(speakerSrc) && (
          <Div flex col={speakerCol} height={speakerHeight}>
            <EntityImage entity={speakerEntity} />
          </Div>
        )
      }
    </Div>
  )
}

const SpeakerBubble = ({
  autoPlay,
  bounce,
  children,
  english,
  entity,
  gutter,
  hasTip,
  image,
  justify,
  label,
  mln,
  mrn,
  onKeyPress,
  pair,
  right,
  speakerCol,
  speakerEntity,
  speakerHeight,
  thai,
}) => (
  right
    ? renderRightSpeaker({
      autoPlay,
      bounce,
      children,
      english,
      entity,
      gutter,
      hasTip,
      image,
      justify,
      label,
      mln,
      mrn,
      onKeyPress,
      pair,
      speakerCol,
      speakerEntity,
      speakerHeight,
      thai,
    })
    : renderLeftSpeaker({
      autoPlay,
      bounce,
      children,
      english,
      entity,
      gutter,
      hasTip,
      image,
      justify,
      label,
      mln,
      mrn,
      onKeyPress,
      pair,
      speakerCol,
      speakerEntity,
      speakerHeight,
      thai,
    })
)

SpeakerBubble.defaultProps = defaultProps
SpeakerBubble.propTypes = propTypes
renderLeftSpeaker.defaultProps = defaultProps
renderLeftSpeaker.propTypes = {
  autoPlay: bool,
  bounce: bool,
  children: any,
  english: bool,
  entity: object,
  gutter: number,
  hasTip: bool,
  image: bool,
  justify: string,
  label: oneOfType([string, number]),
  mln: number,
  mrn: number,
  onKeyPress: func,
  pair: bool,
  speakerCol: number,
  speakerEntity: object,
  speakerHeight: number,
  thai: bool,
}
renderRightSpeaker.defaultProps = defaultProps
renderRightSpeaker.propTypes = renderLeftSpeaker.propTypes

export default SpeakerBubble
