import React from 'react'
import { array, func, number } from 'prop-types'
import chunk from 'lodash/chunk'
import Card from 'src/components/Card'
import Div from 'src/components/Div'
import max from 'lodash/max'
import sum from 'lodash/sum'

const propTypes = {
  letters: array.isRequired,
  playing: number,
  playNext: func.isRequired,
  sizes: array.isRequired,
}

const SpellingInLine = ({ letters, playing, sizes, playNext }) => (
  <Div px={1} flex align="center" justify="center" width={50}>
    {chunk(letters, 2).map((pair) => {
      const letters = pair[0]
      const { src, title } = pair[1]
      const phonicsPlayable = sum(sizes) - 1
      return (
        <Div flex key={letters} wrap justify-center>
          {
        Object.keys(letters).map((letter, j) => {
          const { sound: { srcEn }, titleEn } = letters[letter]
          const lettersPlayable = j
          return (
            titleEn === ' '
              ? <Div col={12} />
              : (
                <Div
                  flex
                  align="center"
                  key={`${letter}-${lettersPlayable}`}
                  hide={playing >= phonicsPlayable || playing === null}
                >
                  <Card
                    audio
                    autoPlay={playing === lettersPlayable}
                    bold
                    className="h1"
                    title={titleEn}
                    src={srcEn}
                    fit
                    key={`${letter}-${lettersPlayable}`}
                    phonic
                    playNext={playNext}
                  />
                </Div>
              )
          )
        })
        }
          <Div
            flex
            align="center"
            style={{ flex: `${max(sizes)}` }}
            key={`${title}-${phonicsPlayable}`}
            hide={playing < phonicsPlayable && playing !== null}
          >
            <Card
              audio
              autoPlay={playing === phonicsPlayable}
              bold
              className="h1"
              title={title}
              src={src}
              fit
              key={`${title}-${phonicsPlayable}`}
              phonic
              playNext={playNext}
            />
          </Div>
        </Div>
      )
    })}
  </Div>
)

SpellingInLine.propTypes = propTypes

export default SpellingInLine
