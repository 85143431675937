import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'i18next'
import { withApollo } from '@apollo/react-hoc'

import { initialState } from 'src/components/TenantSettings/reducer'

const propTypes = {
  client: object.isRequired,
  title: object.isRequired,
  updateSchoolTitle: func.isRequired,
}
const defaultProps = initialState.schoolTitle

export const SchoolTitle = ({ title, updateSchoolTitle, client }) => {
  const inputs = {}

  const saveTitle = () => {
    const data = {}
    Object.keys(inputs).forEach((lang) => {
      data[lang] = inputs[lang].value
    })
    updateSchoolTitle(data, client)
  }

  const fields = Object.keys(title).map((lang) => {
    const id = `schoolTitle-${lang}`
    return (
      <div className="form-group" key={lang}>
        <label htmlFor={id}>
          {lang}
        </label>
        <input
          id={id}
          ref={(input) => {
            inputs[lang] = input
          }}
          type="text"
          className="input"
          defaultValue={title[lang]}
        />
      </div>
    )
  })

  return (
    <div className="setting-box">
      <h6>
        {t('school.settings.schoolTitle')}
      </h6>
      <div className="fields-group">
        {fields}
      </div>
      <button className="btn btn-primary" onClick={saveTitle}>
        {t('shared.save')}
      </button>
    </div>
  )
}

SchoolTitle.propTypes = propTypes
SchoolTitle.defaultProps = defaultProps

export default withApollo(SchoolTitle)
