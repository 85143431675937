import ParagraphMissingWords from 'src/modules/ParagraphMissingWords/ParagraphMissingWordsContainer'
import SentencePunctuationMarker from 'src/modules/ParagraphMissingWords/SentencePunctuationMarker'
import ParagraphMissingPunctuation from 'src/modules/ParagraphMissingWords/ParagraphMissingPunctuation'
import reducer from 'src/modules/ParagraphMissingWords/reducer'

export {
  reducer,
  SentencePunctuationMarker,
  ParagraphMissingWords,
  ParagraphMissingPunctuation,
}

export default ParagraphMissingWords
