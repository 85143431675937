import React from 'react'
import { bool, number, object, string } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'

const propTypes = {
  align: string,
  entity: object.isRequired,
  height: number,
  justify: string,
  keyToPlay: number.isRequired,
  pxLabel: number,
  thai: bool,
}

const defaultProps = {
  align: 'center',
  entity: {},
  height: null,
  justify: 'center',
  keyToPlay: null,
  pxLabel: 2,
  thai: false,
}

const SentenceLine = ({ align, entity, height, keyToPlay, justify, pxLabel, thai }) => (
  <Div flex column justify-center auto>
    <Div auto flex justify-center column>
      <AdaptiveCard
        align={align}
        audio
        auto
        className="centerPrint"
        english
        entity={entity}
        height={height}
        justify={justify}
        keyToPlay={keyToPlay}
        label={keyToPlay}
        labelPx={pxLabel}
      />
    </Div>
    {
      thai && entity.titleTh && (
        <Div auto flex>
          <Div auto flex justify-center column>
            <AdaptiveCard
              align={align}
              audio
              auto
              className="centerPrint"
              entity={entity}
              height={height}
              justify={justify}
              keyToPlay={keyToPlay + 1}
              label={keyToPlay + 1}
              labelPx={pxLabel}
              thai
            />
          </Div>
        </Div>
      )
    }
  </Div>
)

SentenceLine.propTypes = propTypes
SentenceLine.defaultProps = defaultProps
export default SentenceLine
