import DomainContext from 'src/contexts/DomainContext'
import { TEACHER_SCOPE } from 'src/constants/routeScopes'
import MenuSection from 'src/pages/TheLayout/Menu/MenuSection'
import { REPORTS_SECTION, TEACHER_SECTION } from 'src/pages/TheLayout/Menu/locale/constants'
import { teacherSection } from 'src/lib/menu'
import { teacherReportsSection } from 'src/lib/menu/reportsSection'
import React from 'react'

const TeacherMenu = ({ user }) => (
  <DomainContext.Provider value={TEACHER_SCOPE}>
    <div className="qa-menu-teacher-section">
      <MenuSection
        sectionKey={TEACHER_SECTION}
        menuItems={teacherSection(user.roleId)}
      />
    </div>
    <div className="qa-menu-teacher-reports-section">
      <MenuSection
        sectionKey={REPORTS_SECTION}
        menuItems={teacherReportsSection}
      />
    </div>
  </DomainContext.Provider>
)


export default TeacherMenu
