import React from 'react'
import { useHistory } from 'react-router'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { bool } from 'prop-types'

const propTypes = {
  button: bool,
}

const defaultProps = {
  button: false,
}

const BackButton = ({ button }) => {
  const history = useHistory()
  if (button) {
    return (
      <Div
        flex
        button-hover
        align-center
        cursor-pointer
        white
        width={6}
        rounded
        bg="blue"
        onClick={() => history.goBack()}
        mx1
        style={{ fontSize: 18, padding: '13px 10px 9px 8px' }}
      >
        <Icon icon="chevron-left" /> Back
      </Div>
    )
  }
  return (
    <Div
      flex
      button-hover
      align-center
      cursor-pointer
      white
      onClick={() => history.goBack()}
      style={{ fontSize: 18, padding: '13px 10px 9px 8px' }}
    >
      <Icon icon="chevron-left" />
    </Div>
  )
}

BackButton.propTypes = propTypes
BackButton.defaultProps = defaultProps

export default BackButton
