import React, { Component, Fragment } from 'react'
import { array, func } from 'prop-types'
import { t } from 'i18next'
import AcademicTerms from 'src/pages/SchoolSettings/AcademicYears/AcademicTerms'
import Button from 'src/components/Button'
import Div from 'src/components/Div'
import withToast from 'src/lib/withToast'
import YearSummary from 'src/pages/CalendarPage/YearSummary'
import YearCalendar from 'src/pages/CalendarPage/YearCalendarContainer'
import YearSelector from 'src/pages/SchoolSettings/AcademicYears/YearSelector'
import AcademicYearForm from 'src/pages/SchoolSettings/AcademicYears/AcademicYearForm'

const propTypes = {
  academicYears: array.isRequired,
  createYear: func.isRequired,
  deleteYear: func.isRequired,
}

class AcademicYears extends Component {
  state = {
    creatingNew: false,
    selectedYearId: null,
  }

  showForm = () => this.setState({ creatingNew: true })

  cancelCreate = () => this.setState({ creatingNew: false })

  selectYear = id => this.setState({ creatingNew: false, selectedYearId: id })

  handleCreate = async (year) => {
    const { createYear } = this.props
    const { data: { createAcademicYear: { academicYear } } } = await createYear({ variables: year })
    this.selectYear(academicYear.id)
  }

  handleDelete = async () => {
    const { deleteYear } = this.props
    const { selectedYearId: id } = this.state
    this.selectYear(null)
    await deleteYear({ variables: { id } })
  }

  handleUpdate = ({ startedOn, finishedOn }) => {
    const { updateYear } = this.props
    const { selectedYearId: id } = this.state
    withToast()(() => updateYear({ variables: { finishedOn, id, startedOn } }))
  }

  render() {
    const { creatingNew, selectedYearId } = this.state
    const { academicYears } = this.props
    const selectedYear = academicYears.find(year => year.id === selectedYearId)
    const displaySelectedYear = !!selectedYear && !creatingNew
    return (
      <Div flex auto>
        <Div col={8} className="setting-box qa-calendar">
          <h6>
            {t('school.settings.academicYears')}
          </h6>
          <YearSelector
            value={selectedYearId}
            years={academicYears}
            onYearSelect={this.selectYear}
          />
          {!creatingNew && (
            <Button className="ml2" onClick={this.showForm}>{t('shared.new')}</Button>
          )}
          {displaySelectedYear && (
            <Button className="ml2" bg="red" onClick={this.handleDelete}>
              <i className="fas fa-times" />
            </Button>
          )}
          {creatingNew && (
            <AcademicYearForm onSubmit={this.handleCreate}>
              <Button className="align-bottom" flat onClick={this.cancelCreate}>
                {t('shared.cancel')}
              </Button>
            </AcademicYearForm>
          )}
          {displaySelectedYear && (
            <Fragment>
              <AcademicYearForm
                key={selectedYearId}
                value={selectedYear}
                onSubmit={this.handleUpdate}
              />
              <AcademicTerms academicYear={selectedYear} />
              <Div pr3>
                <Div my3><hr /></Div>
                <YearCalendar yearId={selectedYear.id} />
              </Div>
            </Fragment>
          )}
        </Div>
        <Div flex col={4}>
          <YearSummary yearId={(selectedYear || {}).id} />
        </Div>
      </Div>
    )
  }
}

AcademicYears.propTypes = propTypes

export default AcademicYears
