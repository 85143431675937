import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { any, string } from 'prop-types'

import usePath from 'src/lib/routes/usePath'
import userPath from 'src/lib/routes/user'
import Access from 'src/components/Access'

const propTypes = {
  children: any,
  userId: string,
}

const defaultProps = {
  children: undefined,
  userId: undefined,
}

const UserProfileLink = ({ children, userId }) => (
  <Fragment>
    <Access staff teacher parent>
      <Link to={usePath(userPath)({ userId })}>
        {children}
      </Link>
    </Access>
    <Access student>
      {children}
    </Access>
  </Fragment>
)

UserProfileLink.propTypes = propTypes
UserProfileLink.defaultProps = defaultProps

export default UserProfileLink
