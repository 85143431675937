import React, { Component } from 'react'
import { element, number, string, bool, shape } from 'prop-types'
import KawaiiSpeechBubble from 'src/components/Human/KawaiiSpeechBubble'

class KawaiiElementWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { hover: false }
    this.mouseOver = this.mouseOver.bind(this)
  }

  mouseOver() {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    const { style, children, showTextOnHover, text, color, width, height } = this.props
    return (
      <div
        style={style}
        className="kawaiiElementWrapper"
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOver}
      >
        {children}
        {!showTextOnHover && text && (
        <KawaiiSpeechBubble
          color={color}
          width={width}
          height={height}
          text={text}
          rectangular={false}
        />
        )}
        {showTextOnHover && text && this.state.hover && (
        <KawaiiSpeechBubble
          classNames="planetSpeech"
          color={color}
          width={width}
          height={height}
          text={text}
          rectangular={false}
        />
        )}
      </div>
    )
  }
}

KawaiiElementWrapper.propTypes = {
  children: element.isRequired,
  color: string,
  height: number.isRequired,
  showTextOnHover: bool,
  /**
  * Hex color
  */
  style: shape().isRequired,
  /**
   * Set the text to show on the speech bubble
   */
  text: string,
  /**
   * Set as true to show the speech bubble on hover, as false to show text by default
   */
  width: number.isRequired,
}

KawaiiElementWrapper.defaultProps = {
  color: '#FDA7DC',
  mood: 'blissful',
  showTextOnHover: true,
  size: 16,
  text: null,
}

export default KawaiiElementWrapper
