import {
  SpeechRecognitionProvideAnswers,
  SpeechRecognitionHideAnswer,
  SpeechRecognitionProvideQuestions,
  SpeechRecognitionHideQuestion,
} from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer/speechRecognitionList'

export {
  SpeechRecognitionProvideAnswers,
  SpeechRecognitionHideAnswer,
  SpeechRecognitionProvideQuestions,
  SpeechRecognitionHideQuestion,
}
