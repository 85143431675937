import React from 'react'
import LabelImage from 'src/modules/LabelImage/LabelImageContainer'

const defaultProps = {
  direction: 'grid',
}

const LabelImageGrid = (props) => {
  const { mark, init, retry, completed, entities, direction } = props
  return (
    <LabelImage
      {...{
        completed,
        direction,
        entities,
        init,
        mark,
        retry,
      }}
      identifier
    />
  )
}

LabelImageGrid.defaultProps = defaultProps
LabelImageGrid.demoData = {
  entities: [
    'home-with-rooms',
    'bedroom',
    'bathroom',
    'bedroom',
    'garden',
    'living-room',
    'dining-room',
    'kitchen',
    'garage',
  ],
}

export default LabelImageGrid
