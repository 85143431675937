import SpeechRecognition, {
  SpeechRecognitionPrintedAndCursiveTextContainer as SpeechRecognitionPrintedAndCursiveText,
  SpeechRecognitionImageContainer as SpeechRecognitionImage,
} from 'src/modules/SpeechRecognition/SpeechRecognitionContainer'
import {
  SpeechRecognitionProvideAnswers,
  SpeechRecognitionHideAnswer,
  SpeechRecognitionProvideQuestions,
  SpeechRecognitionHideQuestion,
} from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer'

import reducer from 'src/modules/SpeechRecognition/reducer'

export {
  reducer,
  SpeechRecognition,
  SpeechRecognitionProvideAnswers,
  SpeechRecognitionProvideQuestions,
  SpeechRecognitionHideAnswer,
  SpeechRecognitionHideQuestion,
  SpeechRecognitionImage,
  SpeechRecognitionPrintedAndCursiveText,
}
export default SpeechRecognition
