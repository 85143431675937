import keyBy from 'lodash/keyBy'
import chunk from 'lodash/chunk'
import every from 'lodash/every'
import size from 'lodash/size'
import reduce from 'lodash/reduce'
import stripPunctuation from 'src/lib/stripPunctuation'

const INIT_DEFINE_COUNT_WORD = 'barChart/INIT_DEFINE_COUNT_WORD'
const INIT_WORD_COUNT = 'barChart/INIT_WORD_COUNT'
const MARK = 'barChart/MARK'
const RETRY = 'barChart/RETRY'
const CLICK_PLUS = 'barChart/CLICK_PLUS'
const CLICK_MINUS = 'barChart/CLICK_MINUS'

const MAXIMUN_BAR_SIZE = 10

const initialState = {
  actualEntities: [],
  barSize: 0,
  bars: {},
  completed: null,
  entitiesById: {},
}

const countWord = target => (a, word) => (
  {
    count: target === stripPunctuation(word).toLowerCase() ? (a.count + 1) : a.count,
  }
)

const createBars = (a, [word, number]) => (
  {
    ...a,
    [word.id]: {
      check: Number(number.titleEn),
      id: word.id,
      index: size(a),
      size: 0,
    },
  }
)

const createBarsWordCount = paragraph => (a, word) => {
  const { count } = paragraph.split(' ').reduce(
    countWord(word.titleEn.toLowerCase()),
    { count: 0 },
  )
  return (
    {
      ...a,
      [word.id]: {
        check: Number(count),
        id: word.id,
        index: size(a),
        size: 0,
        title: word.titleEn,
      },
    }
  )
}

const increaseBarSize = id => (a, bar) => (
  {
    ...a,
    [bar.id]: {
      ...bar,
      correct: null,
      size: ((id === bar.id) && (bar.size < MAXIMUN_BAR_SIZE)) ? (bar.size + 1) : bar.size,
    },
  }
)

const decreaseBarSize = id => (a, bar) => (
  {
    ...a,
    [bar.id]: {
      ...bar,
      correct: null,
      size: ((id === bar.id) && (bar.size > 0)) ? (bar.size - 1) : bar.size,
    },
  }
)

const getMaximumBarSize = (a, bar) => (
  {
    barSize: a.barSize > bar.size ? a.barSize : bar.size,
  }
)

const checkBars = (a, bar) => (
  {
    ...a,
    [bar.id]: {
      ...bar,
      correct: bar.size === bar.check,
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_DEFINE_COUNT_WORD: {
      const paragraph = { ...payload.entities[0].meaning }.titleEn
      const image = { ...payload.entities[0].image }.src
      const entities = payload.entities.slice(1)
      const bars = chunk(entities, 2).reduce(createBars, {})
      return {
        ...initialState,
        actualEntities: payload.entities,
        bars,
        entitiesById: keyBy(entities, 'id'),
        image,
        paragraph,
      }
    }
    case INIT_WORD_COUNT: {
      const paragraph = { ...payload.entities[0].meaning }.titleEn
      const image = { ...payload.entities[0].image }.src
      const entities = payload.entities.slice(1)
      const bars = entities.reduce(createBarsWordCount(paragraph), {})
      return {
        ...initialState,
        actualEntities: payload.entities,
        bars,
        entitiesById: keyBy(entities, 'id'),
        image,
        paragraph,
      }
    }
    case CLICK_PLUS: {
      const { id } = payload
      const bars = reduce(state.bars, increaseBarSize(id), {})
      const { barSize } = reduce(bars, getMaximumBarSize, { barSize: 0 })
      return {
        ...state,
        barSize,
        bars,
      }
    }
    case CLICK_MINUS: {
      const { id } = payload
      const bars = reduce(state.bars, decreaseBarSize(id), {})
      const { barSize } = reduce(bars, getMaximumBarSize, { barSize: 0 })
      return {
        ...state,
        barSize,
        bars,
      }
    }
    case MARK: {
      const bars = reduce(state.bars, checkBars, {})
      const completed = every(bars, ['correct', true])
      return {
        ...state,
        bars,
        completed,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const initDefineCountWord = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT_DEFINE_COUNT_WORD,
})

const initWordCount = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT_WORD_COUNT,
})

const mark = () => ({
  type: MARK,
})

const clickPlus = ({ id }) => ({
  payload: {
    id,
  },
  type: CLICK_PLUS,
})

const clickMinus = ({ id }) => ({
  payload: {
    id,
  },
  type: CLICK_MINUS,
})

const retry = () => ({
  type: RETRY,
})

export {
  initDefineCountWord,
  initWordCount,
  mark,
  retry,
  clickPlus,
  clickMinus,
}
export default reducer
