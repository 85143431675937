import React from 'react'
import { array, number, string } from 'prop-types'
import Badge from 'src/components/Badge'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import AttendanceGrade from 'src/pages/AttendancePage/AttendanceToday/AttendanceGrade'

const propTypes = {
  grades: array,
  present: number.isRequired,
  selectedYear: string,
  title: string.isRequired,
  total: number.isRequired,
}

const defaultProps = {
  selectedYear: undefined,
}

const AttendanceSection = ({ grades, present, selectedYear, title, total }) => (
  <div className="mb1 mt1">
    <Div flex>
      <Div auto>
        <div className="h3 bold mb1 flex align-center">
          <span className="mr1">
            {title}
          </span>
          {
            total - present === 0 ?
              <Icon after className="green" icon="check" />
              : (
                <Badge pill>
                  {total - present}
                </Badge>
              )}
        </div>
      </Div>
      <Div>
        <div className="h3">
          {present}
          <small className="muted">
            {' / '}
            {total}
          </small>
        </div>
      </Div>
    </Div>
    {
      grades.map(grade => (
        <AttendanceGrade key={grade.id} selectedYear={selectedYear} {...grade} />
      ))
    }
  </div>
)

AttendanceSection.propTypes = propTypes
AttendanceSection.defaultProps = defaultProps

export default AttendanceSection
