/* eslint no-useless-concat: 1 */
import React from 'react'
import { string } from 'prop-types'
import Markdown from 'react-markdown'
import Div from 'src/components/Div'

const propTypes = {
  lyrics: string.isRequired,
}

const fixMarkdown = lyrics => (
  lyrics.replace(/\n/, '  \n')
)

const Lyrics = ({ lyrics }) => (
  <Div h3 center bold className="mx-auto" preWrap>
    <Markdown source={fixMarkdown(lyrics)} />
    <Div h1> 🎶 </Div>
  </Div>
)

Lyrics.propTypes = propTypes
Lyrics.demoData = {
  lyrics: `Old McDonald had a farm
And everywhere McDonald went

The sheep was sure to go`,
}
export default Lyrics
