import React from 'react'
import { arrayOf, bool, shape, string } from 'prop-types'
import { t } from 'i18next'
import groupBy from 'lodash/groupBy'
import { Link } from 'react-router-dom'
import toPairs from 'lodash/toPairs'
import AssessmentWrapper from 'src/pages/AssessmentWrapper'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import {
  assessmentStudentPath,
  lessonFullPath,
  assessmentHomeworkPath,
} from 'src/lib/routes'
import UserPassport from 'src/components/UserPassport'
import Widget from 'src/components/Widget'

const propTypes = {
  chapterId: string,
  gradableChapters: arrayOf(shape()).isRequired,
  marked: bool,
  userId: string.isRequired,
  yearId: string,
}

const unitRows = ({ chapterId, chapters, marked, userId, yearId }) => toPairs(
  groupBy(chapters, c => c.lesson.unit.identifier),
).map(([unitIdentifier, chapters]) => (
  <div key={unitIdentifier} className="my2 ml2">
    <h4 className="qa-assessment-unit" key={unitIdentifier}>
      {unitIdentifier} {chapters[0].lesson.unit.title}
    </h4>

    {chapters.map(({ id, lesson, title }) => {
      const isSelected = id === chapterId
      const studentPath = assessmentStudentPath({ marked, userId, yearId })
      const chapterIndex = lesson.chapters.map(c => c.id).indexOf(id)
      const lessonPath = lessonFullPath({
        chapterIndex,
        lessonSlug: lesson.slug,
      })
      return (
        <Div key={id} flex wrap>
          <Div bold col={10}>
            <Link to={lessonPath}>
              <Div flex my={1} p={1} button-hover rounded>
                <span className="qa-assessment-chapter">
                  {lesson.identifier} {lesson.title} - {title}
                </span>
              </Div>
            </Link>
          </Div>
          <Div className="qa-assessment-link" col={2} pt={1}>
            {
              isSelected ? (
                <Link className="p1 bold right" to={studentPath}>
                  {t('shared.cancel')}
                </Link>
              ) : (
                <Link
                  className="btn h8 btn-outline right"
                  to={assessmentHomeworkPath({
                    chapterId: id,
                    marked,
                    userId,
                    yearId,
                  })}
                >
                  {t('assessment.mark')}
                </Link>
              )}
          </Div>
          <Div col={12}>
            {isSelected && (
            <AssessmentWrapper
              chapterId={id}
              lessonSlug={lesson.slug}
              params={{ chapterId: id }}
              userId={userId}
            />
            )}
          </Div>
        </Div>
      )
    })}
  </div>
))

const column = ({ chapterId, chapters, marked, title, userId, yearId }) => {
  const rows = unitRows({ chapterId, chapters, marked, userId, yearId })
  return (
    <Widget icon="check" title={t(title)}>
      <Div className="qa-assessment-homework" mbn={1}>
        {rows.length > 0 && rows}
      </Div>
      {rows.length === 0 && (
      <Div flex auto justify="center" align="center" p={4}>
        {t('assessment.homeworkEmpty')}
      </Div>
      )}
    </Widget>
  )
}

const Student = ({ chapterId, gradableChapters, marked, userId, yearId }) => (
  <div>
    <UserPassport userId={userId} />
    <Deck>
      <Div mt2>
        {column({
          chapterId,
          chapters: gradableChapters,
          marked,
          title: 'student.done',
          userId,
          yearId,
        })}
      </Div>
    </Deck>
  </div>
)

Student.propTypes = propTypes
export default Student
