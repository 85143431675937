import React from 'react'
import { string, arrayOf, shape, func } from 'prop-types'
import { dateToday, daysOfWeek } from 'src/lib/dates'
import Day from 'src/components/YearCalendar/Day'

const classNamePrefix = 'rc-Week'

const propTypes = {
  date: string,
  datePath: func,
  dayModifiers: shape(),
  edges: arrayOf(string),
}

const defaultProps = {
  date: dateToday(),
  dayModifiers: {},
  edges: [],
}

const isOutside = (edges) => {
  const hash = edges.reduce((acc, next) => ({ ...acc, [next]: true }), {})
  return date => hash[date] || false
}

const Week = ({ date, datePath, edges, dayModifiers }) => {
  const days = daysOfWeek(date)
  const isOutsideEdges = isOutside(edges)
  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}-days`}>
        {days.map(day => (
          <Day
            datePath={datePath}
            outside={isOutsideEdges(day)}
            key={`day-${day}`}
            date={day}
            modifier={dayModifiers[day]}
          />
        ))
        }
      </div>
    </div>
  )
}

Week.propTypes = propTypes
Week.defaultProps = defaultProps

export default Week
