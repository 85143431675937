const initialState = {
  networkStatusConnected: true,
}

const NETWORK_CONNECTED = 'NETWORK_CONNECTED'
const NETWORK_DISCONNECTED = 'NETWORK_DISCONNECTED'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_CONNECTED:
      return initialState
    case NETWORK_DISCONNECTED:
      return {
        networkStatusConnected: false,
      }
    default:
      return state
  }
}

const networkConnected = () => ({ type: NETWORK_CONNECTED })
const networkDisconnected = () => ({ type: NETWORK_DISCONNECTED })

export default reducer
export {
  networkConnected,
  networkDisconnected,
}
