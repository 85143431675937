import { isMobile } from 'src/lib/window'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { DragDropContext, DragSource, DropTarget } from 'react-dnd'

const DragDropContextWithBackend = DragDropContext(isMobile() ? TouchBackend : HTML5Backend)

export { DragDropContext, DragSource, DropTarget, isMobile }

export default DragDropContextWithBackend
