import React from 'react'
import { t } from 'i18next'

import Check from 'src/components/Check'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Donut from 'src/components/Donut'
import BackButton from 'src/components/BackButton'

const WellDone = ({ subscribedGames, rate }) => {
  const completedGames = subscribedGames.filter(game => game.props.completed)
  return (
    <Deck>
      <BackButton to="/" button />
      <div className="h1 bold mb2 flex align-center justify-center">
        <Check className="mr3" />
        {t('wellDone.wellDone')}
      </div>
      {
        rate ? (
          <Div flex height={12} justify-center>
            <Donut value={rate} />
          </Div>
        ) : (
          <h3>Result {completedGames.length} / { subscribedGames.length}</h3>
        )
      }
    </Deck>
  )
}

export default WellDone
