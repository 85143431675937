import React, { Fragment, useContext } from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import { t } from 'i18next'
import map from 'lodash/map'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'

import {
  // PARENT_ROLE,
  STAFF_ROLE,
  STUDENT_ROLE,
  TEACHER_ROLE,
} from 'src/constants/roles'
import { schoolDomainLink, schoolDomainHref } from 'src/lib/window'
import Access from 'src/components/Access'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import roleShape from 'src/shapes/role'
import yearShape from 'src/shapes/year'
import userShape from 'src/shapes/user'
import Select from 'src/components/forms/Select'
import Input from 'src/components/forms/Input'
import TextArea from 'src/components/forms/TextArea'
import ResetStudentPassword from 'src/components/ResetStudentPassword'
import { LocaleContext } from 'src/lib/contexts'
import UserSelector from 'src/pages/User/shared/UserSelector/UserSelector'
import collectionProvider from 'src/pages/User/shared/UserSelector/collectionProvider'

const propTypes = {
  cancelPath: string.isRequired,
  initialValues: shape(userShape),
  onSubmit: func.isRequired,
  roles: arrayOf(shape(roleShape)).isRequired,
  years: arrayOf(shape(yearShape)).isRequired,
}

const validate = (values) => {
  const errors = {}
  if (values.password && values.password !== values.confirmPassword) {
    errors.password = 'Passwords must match'
    errors.confirmPassword = 'Passwords must match'
  }
  return errors
}

const canEditPassword = () => false

// const canEditPassword = ({ L21Role, values, currentUser }) => {
// Staff can edit password everyone and everywhere
// if (currentUser.roleId === STAFF_ROLE) return false
// // at Tenant Everyone can edit password
// if (L21Role === 'tenant') return false
// // at Cloud only teacher and parent can edit only parent user
// if (L21Role === 'cloud') {
// if (
// values.roleId === PARENT_ROLE &&
// (currentUser.roleId === TEACHER_ROLE ||
// currentUser.roleId === PARENT_ROLE)
// ) {
// return false
// }
// }
// return true
// }

const ParentsEditSelector = collectionProvider({
  collections: ['parentsQuery'],
})(UserSelector)

const StudentsEditSelector = collectionProvider({
  collections: ['studentsQuery'],
})(UserSelector)

const InnerForm = ({
  cancelPath,
  initialValues,
  roles,
  years,
  isSubmitting,
  values,
  L21Role,
  currentUser,
  errors = {},
}) => {
  const { academicYearTeachingYearsTitle } = { ...initialValues }
  const { locale } = useContext(LocaleContext)
  const yearTitle = ({ en, th }) => (locale === 'en' ? en : th)
  return (
    <Form>
      <Div flex>
        <Input
          name="firstNameEn"
          labelToken="user.firstNameEn"
          className="mb pr1 col-6 qa-user-firstNameEn"
          required
        />
        <Input
          name="lastNameEn"
          labelToken="user.lastNameEn"
          className="mb col-6 qa-user-lastNameEn"
        />
      </Div>
      <Div flex>
        <Input
          name="firstNameTh"
          labelToken="user.firstNameTh"
          className="mb pr1 col-6 qa-user-firstNameTh"
        />
        <Input
          name="lastNameTh"
          labelToken="user.lastNameTh"
          className="mb col-6 qa-user-lastNameTh"
        />
      </Div>
      <Input
        name="birthdate"
        labelToken="user.birthdate"
        className="mb qa-user-birthdate"
        type="date"
      />
      {values.roleId !== STUDENT_ROLE && (
        <Input
          name="email"
          labelToken="user.email"
          className="mb qa-user-email"
          type="email"
          required={values.roleId !== STUDENT_ROLE}
        />
      )}
      <Div relative flex column>
        {canEditPassword({ currentUser, L21Role, values }) && (
          <Div
            absolute
            auto
            bg-orange
            black
            flex
            h7
            height={2.5}
            justify-center
            mt3
            px2
            py1
            rounded
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              zIndex: 2,
            }}
          >
            {t('user.pleaseChangePasswordAt')}
            <a className="bold black ml" href={schoolDomainHref()}>
              {`${schoolDomainLink()}`}
            </a>
          </Div>
        )}
        <Div
          flex
          column
          disabled={canEditPassword({ currentUser, L21Role, values })}
          qa-password-div
        >
          <Div flex>
            <Input
              name="password"
              labelToken="user.password"
              className="mb qa-user-password flex-1"
              type="password"
            />
            {values.roleId === STUDENT_ROLE && (
              <Access staff teacher>
                <Div ml mb flex flex-column>
                  <label>&nbsp;</label>
                  <ResetStudentPassword userId={values.id} />
                </Div>
              </Access>
            )}
          </Div>
          <Input
            name="confirmPassword"
            labelToken="user.confirmPassword"
            className="mb qa-user-confirmPassword"
            type="password"
          />
        </Div>
      </Div>
      <Access staff>
        <Select
          className="mb qa-user-roleId"
          name="roleId"
          labelToken="user.role"
          options={roles}
        />
      </Access>
      {values.roleId === STUDENT_ROLE && (
        <React.Fragment>
          <Access staff teacher>
            <Input
              name="identifier"
              labelToken="user.identifier"
              className="mb qa-user-identifier"
              required
            />
          </Access>
          <Access staff>
            <Select
              className="mb qa-user-yearId"
              name="yearId"
              labelToken="user.year"
              options={years}
            />
            <ParentsEditSelector errors={errors} formtype={values.roleId} />
            <TextArea
              name="medicalNote"
              className="qa-user-medicalNote"
              labelToken="user.medicalNote"
            />
          </Access>
        </React.Fragment>
      )}
      <Access staff>
        <Fragment>
          {[STAFF_ROLE, TEACHER_ROLE].includes(values.roleId) && (
            <Fragment>
              <Select
                isMulti
                isSearchable
                className="mb qa-user-teachingYearIds"
                name="teachingYearIds"
                labelToken="user.teachingYears"
                options={years}
              />
              <Div flex auto>
                <table name="qa-user-academicYearTeachingYears">
                  <thead>
                    <tr>
                      <th className="nowrap">{t('user.academicTerms')}</th>
                      <th>{t('user.teachingYears')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      academicYearTeachingYearsTitle,
                      (teachingYears, key) => (
                        <tr className={`qa-user-${key}`} key={key}>
                          <td className="nowrap">
                            {`${t('user.academicTerm')} ${key
                              .split('-')
                              .pop()}`}
                          </td>
                          <td>
                            {teachingYears
                              .map((year) => yearTitle(year))
                              .join(', ')}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Div>
            </Fragment>
          )}
          {values.roleId !== STUDENT_ROLE && (
            <StudentsEditSelector errors={errors} formtype={values.roleId} />
          )}
        </Fragment>
      </Access>
      {Object.keys(errors).length > 0 && (
        <div className="mb center rounded white red py1 qa-users-error">
          <Icon icon="exclamation-triangle" /> {t('errors.recordInvalid')}
        </div>
      )}
      <Div mt2 flex gutter={1} align="center">
        <Div px={1}>
          <button
            className="btn rounded bg-blue white"
            type="submit"
            disabled={isSubmitting}
          >
            {t('shared.save')}
          </button>
        </Div>
        <Div px={2}>
          <Link className="gray qa-users-cancel" to={cancelPath}>
            {t('shared.cancel')}
          </Link>
        </Div>
      </Div>
    </Form>
  )
}

const UserForm = ({
  cancelPath,
  roles,
  years,
  onSubmit,
  initialValues,
  isParent = false,
  L21Role,
  currentUser,
}) => (
  <Formik validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
    {({ isSubmitting, values, errors }) => (
      <InnerForm
        cancelPath={cancelPath}
        roles={roles}
        years={years}
        onSubmit={onSubmit}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        isParent={isParent}
        values={values}
        L21Role={L21Role}
        errors={errors}
        currentUser={currentUser}
      />
    )}
  </Formik>
)

UserForm.propTypes = propTypes

export { InnerForm }
export default UserForm
