import React from 'react'
import { arrayOf, shape, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import entityShape from 'src/shapes/entity'
import * as gameActions from 'src/modules/MultipleChoiceMissingWordsAudio/reducer'
import MultipleChoiceMissingWordsAudioScene from 'src/modules/MultipleChoiceMissingWordsAudio/MultipleChoiceMissingWordsAudioScene'

const propTypes = {
  ...gamePropTypes,
  cardsSize: number,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
}

const defaultProps = {
  cardsSize: 3,
}

class MultipleChoiceMissingWordsAudioGame extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { cardsSize, entities, init } = this.props
    init({ cardsSize, entities })
  }

  render() {
    const { completed, mark, retry, audio, answers,
      correct, entitiesById, phrase, selected, onSelectAnswer } = this.props
    if (answers.length === 0) return null
    return (
      <Game {
              ...{
                completed,
                init: () => this.init(),
                mark,
                retry,
              }}
      >
        <MultipleChoiceMissingWordsAudioScene
          audio={entitiesById[audio]}
          completed={completed}
          correct={entitiesById[correct]}
          phrase={entitiesById[phrase]}
          answers={answers.map(id => entitiesById[id])}
          selected={selected}
          onSelectAnswer={onSelectAnswer}
        />
      </Game>
    )
  }
}

const select = state => state.multipleChoiceMissingWordsAudio
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const MultipleChoiceMissingWordsAudio2 = props => (
  <MultipleChoiceMissingWordsAudioGame {...props} cardsSize={2} />
)

export const MultipleChoiceMissingWordsAudio2Container = (
  connect(select, actions)(MultipleChoiceMissingWordsAudio2)
)

MultipleChoiceMissingWordsAudio2Container.propTypes = propTypes
MultipleChoiceMissingWordsAudio2Container.defaultProps = defaultProps
MultipleChoiceMissingWordsAudio2Container.demoData = {
  entities: [
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-his-name-is-john',
    'john',
    'jane',
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-he-is-seven',
    'seven',
    'eight',
  ],
}

MultipleChoiceMissingWordsAudioGame.propTypes = propTypes
MultipleChoiceMissingWordsAudioGame.defaultProps = defaultProps
MultipleChoiceMissingWordsAudioGame.demoData = {
  entities: [
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-his-name-is-john',
    'john',
    'jane',
    'seven',
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-he-is-seven',
    'seven',
    'eight',
    'jane',
  ],
}

export default connect(select, actions)(MultipleChoiceMissingWordsAudioGame)
