import React from 'react'
import { any, bool, func, number, oneOfType, string } from 'prop-types'
import Div from 'src/components/Div'
import KeyHandler from 'src/components/KeyHandler'

const propTypes = {
  bounce: bool,
  children: any,
  col: number,
  gutter: number,
  justify: string,
  label: oneOfType([string, number]),
  mbn: number,
  mln: number,
  mrn: number,
  onKeyPress: func,
  tip: string,
}

const defaultProps = {
  bounce: null,
  children: null,
  col: null,
  gutter: null,
  justify: 'center',
  label: null,
  mbn: null,
  mln: null,
  mrn: null,
  onKeyPress: null,
  tip: null,
}

const Bubble = ({
  bounce,
  children,
  col,
  gutter,
  justify,
  label,
  mbn,
  mln,
  mrn,
  onKeyPress,
  tip,
}) => {
  const classNames = tip ? `pip-${tip}` : ''
  return (
    <Div
      flex
      flex-1
      justify={justify}
    >
      <Div
        col={col}
        flex
        flex-1={col ? null : true}
        justify={justify}
      >
        <Div
          animated
          bg="white"
          bounce={bounce}
          className={classNames}
          flex
          flex-1
          gutter={gutter}
          justify="center"
          mbn={mbn}
          mln={mln}
          mrn={mrn}
          p={1}
          relative
          round
          shadow
          z0
        >
          <Div flex auto relative z1 justify={justify}>
            {children}
          </Div>
          {
            (label || label === 0) && (
              <Div absolute z0 bubble-circle-position circle p={2} bg="white">
                <KeyHandler label={label} isBubbleLabel onKeyPress={onKeyPress} size={4} />
              </Div>
            )
          }
        </Div>
      </Div>
    </Div>
  )
}

Bubble.defaultProps = defaultProps
Bubble.propTypes = propTypes

export default Bubble
