import React from 'react'
import gqlLoader from 'src/lib/gqlLoader'
import { compose } from 'src/lib/redux'
import Div from 'src/components/Div'
import ErrorMessage from 'src/components/ErrorMessage'
import {
  alphabetCursiveEn,
  alphabetEn,
  familyFaces,
  letterFormationVideos,
  phonics,
  phonicsAlphabet,
} from 'src/queries/collections'

const ChildrenWithProps = (Component, errorMessage) => (props) => [
  <Component key={Component.name} {...props} />,
  errorMessage ? (
    <Div absolute col={12}>
      <ErrorMessage message={errorMessage} />
    </Div>
  ) : null,
]

const collectionQueries = {
  alphabetCursiveEn,
  alphabetEn,
  familyFaces,
  letterFormationVideos,
  phonics,
  phonicsAlphabet,
}

const collectionProvider = ({ collections }) => (Component) => {
  const collectionsNotFound = collections.filter(
    (collection) => !collectionQueries[collection]
  )
  const errorMessage = collectionsNotFound.length
    ? `Collections not found: ${collectionsNotFound.join(',')}.`
    : null
  const queries = collections
    .filter((collection) => collectionQueries[collection])
    .map((collection) => gqlLoader(collectionQueries[collection]))

  return compose(...queries)(ChildrenWithProps(Component, errorMessage))
}

export default collectionProvider
