import React, { Fragment, useState } from 'react'
import Access from 'src/components/Access'
import { number, string } from 'prop-types'
import { Query, Mutation } from '@apollo/react-components'

import Icon from 'src/components/Icon'
import qaChapterQuery from 'src/queries/qa/qaChapter.gql'
import createChapterManualTestMutation from 'src/queries/qa/createChapterManualTestMutation.gql'
import qaRejectionReasonsQuery from 'src/queries/qa/qaRejectionReasonsQuery.gql'
import qaChaptersQuery from 'src/queries/qa/qaChaptersQuery.gql'

const propTypes = {
  chapterId: string.isRequired,
  chapterIndex: number.isRequired,
  lessonSlug: string.isRequired,
}

const QABar = ({ chapterId }) => {
  const [rejecting, setRejecting] = useState(false)

  return (
    <Query query={qaChapterQuery} variables={{ id: chapterId }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return null
        if (error) return null
        const { chapterQa } = data

        const qa = chapterQa.length ? chapterQa[0] : null

        const qaExists = qa !== null
        const qaStatus = qa && qa.successful

        const approved = qaExists && qaStatus
        const rejected = qaExists && !qaStatus
        const unknown = !qaExists
        const iconColour = `${approved ? 'green' : ''}${rejected ? 'red' : ''}${unknown ? 'yellow' : ''}`

        if (rejecting) {
          return (
            <Query query={qaRejectionReasonsQuery}>
              {({ loading, error, data }) => {
                if (loading) return null
                if (error) return null

                const { reasons } = data

                return (
                  <Mutation mutation={createChapterManualTestMutation}>
                    {createChapterManualTest => (
                      <Fragment>
                        {reasons.map(reason => (
                          <button
                            type="button"
                            id={`qa-${reason.reason.replace(/\s+/g, '-').toLowerCase()}`}
                            key={`qa-${reason.reason.replace(/\s+/g, '-').toLowerCase()}`}
                            className="btn white bg-red not-rounded"
                            onClick={() => {
                              createChapterManualTest({
                                variables: {
                                  chapterId,
                                  qaRejectionReasonId: reason.id,
                                  successful: false,
                                },
                              }).then(() => {
                                setRejecting(false)
                                refetch()
                              })
                              return true
                            }}
                          >
                            {reason.reason}
                          </button>
                        ))}
                        <button
                          type="button"
                          className="btn blue bg-white not-rounded"
                          id="qa-back"
                          onClick={() => setRejecting(false)}
                        >
                          Back
                          <Icon icon="level-down-alt fa-rotate-90" />
                        </button>
                      </Fragment>
                    )}
                  </Mutation>
                )
              }}
            </Query>
          )
        }

        return (
          <Mutation mutation={createChapterManualTestMutation}>
            {createChapterManualTest => (
              <Fragment>
                <span
                  className={iconColour}
                  style={{ verticalAlign: 'middle', fontSize: '4em' }}
                >
                  {approved ? <Icon icon="check" /> : null}
                  {rejected ? <Icon icon="times" /> : null}
                  {unknown ? '?' : null}
                </span>
                &nbsp;
                <button
                  type="button"
                  className={`btn ${approved ? 'gray' : 'white'} bg-green not-rounded`}
                  disabled={approved}
                  id="qa-approve"
                  onClick={() => {
                    createChapterManualTest({
                      variables: {
                        chapterId,
                        successful: true,
                      },
                    }).then(() => refetch())
                  }}
                >
                  {approved ? 'Accepted' : 'Accept'}
                </button>
                <button
                  type="button"
                  id="qa-reject"
                  className={`btn ${rejected ? 'gray' : 'white'} bg-red not-rounded`}
                  disabled={rejected}
                  onClick={() => setRejecting(true)}
                >
                  {rejected ? 'Rejected' : 'Reject'}
                </button>
                <Query query={qaChaptersQuery}>
                  {({ loading, error, data }) => {
                    if (loading) return null
                    if (error) return null

                    const { chapter: chapters } = data
                    const chapter = chapters[0]
                    const chIndexLength = chapter.lesson.chapters.length - 1
                    const chIndex = (chapter.lesson.chapters.findIndex(c => c.id === chapter.id) + 1) % chIndexLength
                    const url = `/lesson/${chapter.lesson.slug}/chapter/${chIndex}`
                    return (
                      <a
                        className="btn blue bg-white not-rounded"
                        href={url}
                        rel="noopener noreferrer"
                      >
                        Next
                      </a>
                    )
                  }}
                </Query>
              </Fragment>
            )}
          </Mutation>
        )
      }}
    </Query>
  )
}

const AdminBar = ({ chapterIndex, lessonSlug, chapterId }) => (
  <Access staff hq>
    <div
      style={{
        position: 'absolute',
        right: '1em',
        top: '-2em',
        zIndex: 1,
      }}
    >
      <QABar
        chapterId={chapterId}
      />
      <a
        className="btn btn-primary not-rounded"
        href={`/admin/lessons/${lessonSlug}/edit/#${chapterIndex}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit
      </a>
    </div>
  </Access>
)

AdminBar.propTypes = propTypes

export default AdminBar
