import escapeRegExp from 'lodash/escapeRegExp'

// "word" should not be inside another word, but itself may contain non-word characters
// e. g. 'he is' should not match 'he issues'
const splitByWord = (str, word) => {
  // word, that is not inside other words,
  // i. e. surrounded by non-word characters or start/end of a line
  const rx = new RegExp(`([\\W]+|^)(${escapeRegExp(word)})([\\W]+|$)`, 'ig')
  let match = null
  let lastStart = 0
  const ret = []
  /* eslint-disable-next-line no-cond-assign */
  while (match = rx.exec(str)) {
    const { index, 1: firstGroup, 2: wordGroup } = match
    const sliceTo = index + firstGroup.length
    ret.push(str.slice(lastStart, sliceTo))
    lastStart = sliceTo + wordGroup.length
  }
  ret.push(str.slice(lastStart))
  return ret
}

export default splitByWord
