import React, { Fragment } from 'react'
import { bool, string, number, shape, func, oneOfType, element, arrayOf } from 'prop-types'
import Div from 'src/components/Div'
import EntityBubble from 'src/components/EntityBubble'
import entityShape from 'src/shapes/entity'
import videoShape from 'src/shapes/video'

const propTypes = {
  autoPlay: bool,
  children: oneOfType([element, arrayOf(element)]),
  entity: shape(entityShape).isRequired,
  key_: oneOfType([string, number]),
  playButtonFirst: bool,
  playNext: func,
  video: shape(videoShape),
}

const defaultProps = {
  autoPlay: false,
  children: undefined,
  key_: undefined,
  playNext: () => {},
  playButtonFirst: false,
  video: undefined,
}

const WithPlayButton = ({ children, playButtonFirst, playNext, entity, autoPlay, key_, video }) => {
  const colChildren = (
    <Div flex column auto col={8}>
      <Div flex column auto>
        {children}
      </Div>
    </Div>
  )

  const colPlayButton = (
    <Div flex column auto justify="center" align="center" col={4}>
      <Div flex col={10}>
        <EntityBubble
          key={key_}
          autoPlay={autoPlay}
          image={video}
          play={!video}
          english
          audio
          minHeight={video ? 29 : 12}
          entity={entity}
          label=" "
          playNext={playNext}
        />
      </Div>
    </Div>
  )

  return (
    <Div flex auto>
      {
        playButtonFirst
          ? <Fragment>{colPlayButton}{colChildren}</Fragment>
          : <Fragment>{colChildren}{colPlayButton}</Fragment>
      }
    </Div>
  )
}

WithPlayButton.propTypes = propTypes
WithPlayButton.defaultProps = defaultProps

export default WithPlayButton
