import chunk from 'lodash/chunk'
import every from 'lodash/every'
import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import uniqShuffle from 'src/lib/uniqShuffle'
import normalizeString from 'src/lib/normalizeString'
import speechRecognitionCorrection from 'src/lib/speechRecognitionCorrection'
import { getRandomInt } from 'src/lib/getRandomNumber'

const INIT = 'SpeechRecognition/INIT'
const INIT_QUESTION_AND_ANSWER = 'SpeechRecognition/INIT_QUESTION_AND_ANSWER'
const DECREASE_HEART = 'SpeechRecognition/DECREASE_HEART'
const MARK = 'SpeechRecognition/MARK'
const TRANSCRIPTS = 'SpeechRecognition/TRANSCRIPTS'
const MAXIMUM_TRIES = 3
const MICROPHONE_OFF = 'SpeechRecognition/MICROPHONE_OFF'
const MICROPHONE_ON = 'SpeechRecognition/MICROPHONE_ON'
const SHOW_ORIGINAL_TEXT = 'SpeechRecognition/SHOW_ORIGINAL_TEXT'
const RETRY = 'SpeechRecognition/RETRY'
const RESET = 'SpeechRecognition/RESET'

export const initialState = {
  actualEntities: [],
  completed: null,
  correct: null,
  entity: {},
  loading: false,
  mark: null,
  selected: false,
  storeTranscripts: [],
  transcripts: [],
  tries: 0,
  triesIterations: Array(MAXIMUM_TRIES).fill(undefined),
}


const createTriesIterations = (tries = 0) => (
  Array(MAXIMUM_TRIES).fill(undefined).fill(true, 0, tries)
)

const createSubWordPool = wordPool => (a, word) => {
  if (!wordPool.includes(word)) {
    return a.concat(word)
  }
  return a
}

const createWordPool = (a, string) => {
  const subWordPool = string.split(' ').reduce(createSubWordPool(a), [])
  return a.concat(...subWordPool)
}

const setWordStatus = wordPool => (a, word) => (
  a.concat({
    ...word,
    correct: word.correct ? true : wordPool.includes(word.title),
  })
)

const isTranscriptsCorrect = words => every(words, 'correct')

const createStoreTranscripts = titleArray => (a, word, index) => (
  a.concat({ id: `${size(a)}-${word}`, title: word, titleEn: titleArray[index] })
)

const createHintTranscripts = (a, word, index) => {
  const REGEX = /[.,"'/#!$%^&*;:{}=\-_\][`~“()?]/
  if ((index % 3) === 1) {
    return a.concat({ id: `${size(a)}-${word}`, titleEn: word })
  }
  const wordArray = word.split('')
  const lastLetter = wordArray[wordArray.length - 1]
  const hintTitle = REGEX.test(lastLetter) ? `... ${lastLetter}` : '...'
  return a.concat({ id: `${size(a)}-${word}`, titleEn: hintTitle })
}

const checkCompleted = (correct, tries) => {
  if (correct) return true
  if (!correct && tries === MAXIMUM_TRIES) return false
  return null
}

const isHideAnswer = (complete, hideText) => {
  if (complete === null) return true && hideText
  if (complete) return false && hideText
  return false && hideText
}

const removePunctuationArray = (a, string) => {
  if (string.trim().replace(/[.,"'/#!$%^&*;:{}=\-_\][`~“()?]/g, '') === '') return a
  return a.concat(string)
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const [entity] = uniqShuffle(payload.entities)
      const actualEntities = [entity]
      const { hide } = payload
      const storeTranscripts = normalizeString(entity.titleEn)
        .split(' ')
        .reduce(createStoreTranscripts(
          speechRecognitionCorrection(entity.titleEn).split(' ')
            .reduce(removePunctuationArray, []),
        ), [])
      return {
        ...initialState,
        actualEntities,
        correct: null,
        entity,
        hideAnswer: hide,
        hideText: hide,
        isCursive: getRandomInt(0, 1) === 1,
        storeTranscripts,
        transcripts: [{ id: entity.id, titleEn: entity.titleEn }],
      }
    }
    case INIT_QUESTION_AND_ANSWER: {
      const { asking, hide } = payload
      const entitiesById = keyBy(payload.entities, 'id')
      const { entities } = payload
      const [question, answer] = uniqShuffle(chunk(entities, 2))[0]
      const questionId = question.id
      const answerId = answer.id
      const entity = asking ? question : answer
      const hintTranscripts = entity.titleEn.split(' ').reduce(createHintTranscripts, [])
      const storeTranscripts = normalizeString(entity.titleEn)
        .split(' ')
        .reduce(createStoreTranscripts(
          speechRecognitionCorrection(entity.titleEn).split(' ')
            .reduce(removePunctuationArray, []),
        ), [])
      return {
        ...initialState,
        answerId,
        correct: null,
        entitiesById,
        entity,
        hideAnswer: hide,
        hideText: hide,
        hintTranscripts,
        questionId,
        storeTranscripts,
        transcripts: [{ id: entity.id, titleEn: entity.titleEn }],
      }
    }
    case TRANSCRIPTS: {
      const { transcripts } = payload
      const wordPool = [].concat(transcripts).reduce(createWordPool, [])
      const storeTranscripts = state.storeTranscripts.reduce(setWordStatus(wordPool), [])
      return {
        ...state,
        storeTranscripts,
      }
    }
    case MARK: {
      const { entity, storeTranscripts } = state
      const isCorrect = isTranscriptsCorrect(storeTranscripts)
      const completed = checkCompleted(isCorrect, state.tries)
      return {
        ...state,
        completed,
        correct: isCorrect,
        hideAnswer: false,
        loading: isCorrect ? false : state.loading,
        transcripts: isCorrect
          ? [{ id: entity.id, titleEn: entity.titleEn }]
          : storeTranscripts,
      }
    }
    case DECREASE_HEART: {
      if (state.completed !== null) return state
      const tries = state.tries + 1
      const triesIterations = createTriesIterations(tries)
      const completed = checkCompleted(state.correct, tries)
      return {
        ...state,
        completed,
        loading: false,
        tries,
        triesIterations,
      }
    }
    case MICROPHONE_OFF: {
      return {
        ...state,
        correct: state.completed,
        loading: true,
        selected: false,
      }
    }
    case MICROPHONE_ON: {
      return {
        ...state,
        selected: true,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
        correct: null,
        hideAnswer: state.hideText,
        selected: false,
        tries: 1,
      }
    }
    case SHOW_ORIGINAL_TEXT: {
      return {
        ...state,
        correct: state.completed,
        hideAnswer: isHideAnswer(state.completed, state.hideText),
        selected: false,
      }
    }
    case RESET: {
      const { tries, triesIterations } = state
      const newTriesIterations =
        tries > MAXIMUM_TRIES
          ? createTriesIterations({ typesLength: MAXIMUM_TRIES })
          : triesIterations
      return {
        ...state,
        triesIterations: newTriesIterations,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities, hide }) => ({
  payload: {
    entities,
    hide,
  },
  type: INIT,
})

const initQuestionAndAnswer = ({ entities, asking, hide }) => ({
  payload: {
    asking,
    entities,
    hide,
  },
  type: INIT_QUESTION_AND_ANSWER,
})

const showOriginalText = () => ({
  meta: {
    debounce: {
      time: 1000,
    },
  },
  type: SHOW_ORIGINAL_TEXT,
})

const checkForReset = () => ({
  meta: {
    debounce: {
      time: 750,
    },
  },
  type: RESET,
})

const decreaseHeart = () => ({
  meta: {
    debounce: {
      time: 1000,
    },
  },
  type: DECREASE_HEART,
})

const mark = () => (dispatch, getState) => {
  const action = dispatch({
    meta: {
      debounce: {
        time: 500,
      },
    },
    type: MARK,
  })
  dispatch(decreaseHeart())
  if (!getState().correct) {
    dispatch(checkForReset())
    dispatch(showOriginalText())
  }
  return action
}

const processTranscripts = transcript => (dispatch) => {
  const action = dispatch({
    payload: {
      transcripts: transcript,
    },
    type: TRANSCRIPTS,
  })
  dispatch(mark())
  return action
}

const microphoneOff = () => ({
  type: MICROPHONE_OFF,
})


const microphoneOn = () => ({
  meta: {
    debounce: {
      time: 100,
    },
  },
  type: MICROPHONE_ON,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  initQuestionAndAnswer,
  showOriginalText,
  checkForReset,
  decreaseHeart,
  mark,
  microphoneOff,
  microphoneOn,
  retry,
  processTranscripts,
}

export default reducer
