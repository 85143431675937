
import React from 'react'
import ListeningWordsCore from 'src/modules/ListeningWords/ListeningWordsCore'
import { func, number } from 'prop-types'

const ListeningSounds = ({ initAsIs, size, ...rest }) => (
  <ListeningWordsCore
    audioRepeat={3}
    init={() => initAsIs({ entities: rest.entities, size })}
    {...{ ...rest, showImage: false }}
  />
)
ListeningSounds.propTypes = {
  initAsIs: func.isRequired,
  size: number.isRequired,
}

export default ListeningSounds
