import React from 'react'
import reducer from 'src/modules/ListeningPhonemes/reducer'
import ListeningPhonemesContainer from 'src/modules/ListeningPhonemes/ListeningPhonemesContainer'

const ListeningPhonemes2 = props => <ListeningPhonemesContainer maxChoices={2} {...props} />
const ListeningPhonemes3 = props => <ListeningPhonemesContainer maxChoices={3} {...props} />
ListeningPhonemes2.demoData = ListeningPhonemesContainer.demoData
ListeningPhonemes3.demoData = {
  entities: [
    'collection-fl',
    'flight',
    'flower',
    'collection-pl',
    'play',
    'plus',
    'collection-cl',
    'clock',
    'clap',
  ],
}

export {
  reducer,
  ListeningPhonemes2,
  ListeningPhonemes3,
}
export default ListeningPhonemesContainer
