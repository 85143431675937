import React, { useContext } from 'react'
import { arrayOf, bool, shape } from 'prop-types'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import DomainContext from 'src/contexts/DomainContext'
import userShape from 'src/shapes/user'
import academicYearShape from 'src/shapes/academicYear'
import { STUDENT_ROLE } from 'src/constants/roles'
import { ATTENDANCE, SCHOOL } from 'src/constants/translation'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'
import { progressReportPath } from 'src/lib/routes'
import usePath from 'src/lib/routes/usePath'

import DonutAndGrade from 'src/components/DonutAndGrade'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import UserProfileLink from 'src/components/UserProfileLink'
import Attribute from 'src/components/UserPassport/Attribute'
import ProgressBar from 'src/components/UserPassport/ProgressBar'

import Access from 'src/components/Access/AccessContainer'
import ResetStudentPassword from 'src/components/ResetStudentPassword/ResetStudentPassword'
import { UserEditLink } from 'src/pages/UserInfo/UserInfo'
import Button from 'src/components/Button'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  editable: bool,
  user: shape(userShape).isRequired,
}

const defaultProps = {
  editable: false,
}

const UserDetail = ({
  academicYears,
  user,
  editable,
  user: {
    id,
    birthdate,
    children,
    role,
    fullName,
    medicalNote,
    email,
    identifier,
    year,
    attendanceProgress,
    homeworkProgress,
    parents,
    teachingYears,
    averageGrade,
  },
}) => {
  const domain = useContext(DomainContext) || SCHOOL_SCOPE
  const path = usePath(progressReportPath)
  const academicYearId = maxBy(academicYears, 'startedOn').id
  const teachingYearsByGrade = groupBy(
    sortBy(teachingYears, 'slug'),
    ({ grade: { title } }) => title
  )
  return (
    <Div px1>
      <Div relative auto>
        <Div mxn1 print-hide>
          <Div flex gutter>
            <div>
              <Div className="qa-role" py1 px2 rounded border>
                {t(`role:${role}`)}
              </Div>
            </div>
            <Div auto />
            {role === STUDENT_ROLE && (
              <Access staff teacher>
                <div>
                  <ResetStudentPassword userId={id} />
                </div>
              </Access>
            )}
            {(role === STUDENT_ROLE || editable) && (
              <Access staff parent>
                <div>
                  <UserEditLink user={user}>
                    <Button bg-blue rounded white p1>
                      <Icon icon="edit" />
                      {t('shared.edit')}
                    </Button>
                  </UserEditLink>
                </div>
              </Access>
            )}
          </Div>
        </Div>
        <Attribute
          qaClassName="qa-full-name"
          icon="user"
          value={fullName}
        />
        <Attribute value={email} icon="at" qaClassName="qa-email" />
        {role === STUDENT_ROLE && (
          <Div flex mt1>
            <Attribute
              qaClassName="qa-identifier"
              value={identifier === id ? '' : identifier}
              icon="address-card"
              divProps={{ mr3: true }}
            />
            <Attribute
              qaClassName="qa-year-title"
              value={year ? year.title : ''}
              icon="users"
            />
          </Div>
        )}
        <Attribute
          value={birthdate}
          label={t('user.birthdate')}
          qaClassName="qa-birthdate"
        />
        <Div table container>
          <ProgressBar
            label={t('student.progress.title')}
            progress={homeworkProgress}
            type={SCHOOL}
            tableRow
          />
          <ProgressBar
            label={t('student.attendance')}
            progress={attendanceProgress}
            type={ATTENDANCE}
            tableRow
          />
        </Div>
        {parents && parents.length > 0 && (
          <Attribute
            label={t('user.parents')}
            value={(
              <ul className="mb0">
                {parents.map(({ slug, fullName }) => (
                  <li key={slug}>
                    <UserProfileLink userId={slug}>{fullName}</UserProfileLink>
                  </li>
                ))}
              </ul>
            )}
            qaClassName="qa-parents"
          />
        )}
        {teachingYears && teachingYears.length > 0 && (
          <Attribute
            value={(
              <Div flex column>
                {map(teachingYearsByGrade, (grade, gradeTitle) => (
                  <Div
                    flex
                    key={gradeTitle}
                    className={`qa-grade-${gradeTitle.slice(-1)}`}
                  >
                    <Div mr1>
                      <Icon before icon="users" />
                      {`${gradeTitle}:`}
                    </Div>
                    {grade.map(({ slug, title }) => (
                      <Div key={slug} mr1>
                        <Link
                          to={path({
                            academicYearId,
                            domain,
                            tab: 'attainment',
                            yearId: slug,
                          })}
                        >
                          {title}
                        </Link>
                      </Div>
                    ))}
                  </Div>
                ))}
              </Div>
            )}
            qaClassName="qa-teaching-years"
          />
        )}
        {children && children.length > 0 && (
          <Attribute
            label={t('user.children')}
            qaClassName="qa-children"
            value={(
              <ul className="mb0">
                {children.map(({ slug, fullName, year: { title } }) => (
                  <li key={slug}>
                    <UserProfileLink userId={slug}>
                      {title} {fullName}
                    </UserProfileLink>
                  </li>
                ))}
              </ul>
            )}
          />
        )}
        {medicalNote && (
          <Attribute
            label={t('user.medicalNote')}
            qaClassName="qa-medical-note"
            value={<small>{medicalNote}</small>}
          />
        )}
      </Div>
      {averageGrade && (
        <Div flex col-3>
          <DonutAndGrade value={parseFloat(averageGrade)} showPercent={false} />
        </Div>
      )}
    </Div>
  )
}

UserDetail.propTypes = propTypes
UserDetail.defaultProps = defaultProps

export default UserDetail
