import React from 'react'
import { func, object, bool, shape, string } from 'prop-types'
import map from 'lodash/map'
import Deck from 'src/components/Layout/Deck'
import Line from 'src/modules/ListenAndSelect/Line'

const propTypes = {
  autoPlay: bool,
  image: shape({
    id: string,
    src: string,
  }),
  lines: object.isRequired,
  onClick: func.isRequired,
  silent: bool,
}

const defaultProps = {
  autoPlay: null,
  image: null,
  silent: null,
}

const ListenAndSelect = ({ lines, onClick, autoPlay, image, silent }) => (
  <Deck>
    {
      map(lines, (line, id) => (
        <Line
          autoPlay={autoPlay}
          image={image}
          key={id}
          line={line}
          onClick={col => onClick({ col, row: id })}
          silent={silent}
        />
      ))
    }
  </Deck>
)

ListenAndSelect.defaultProps = defaultProps
ListenAndSelect.propTypes = propTypes
export default ListenAndSelect
