import React, { Fragment } from 'react'
import { func, element, shape, string } from 'prop-types'
import { t } from 'i18next'
import { dateToday, add } from 'src/lib/dates'
import Button from 'src/components/Button'
import AcademicYearTermForm from 'src/pages/SchoolSettings/AcademicYears/AcademicYearTermForm'

const propTypes = {
  children: element,
  onSubmit: func.isRequired,
  value: shape({
    finishedOn: string,
    startedOn: string,
  }),
}

const defaultProps = {
  value: {
    finishedOn: add(dateToday(), 1, 'year'),
    startedOn: dateToday(),
  },
}

class AcademicYearForm extends React.Component {
  constructor({ value: { startedOn, finishedOn } }) {
    super()
    this.state = { finishedOn, startedOn }
  }

  handleChange = field => date => this.setState({ [field]: date })

  handleSubmit = () => {
    const { startedOn, finishedOn } = this.state
    const { onSubmit } = this.props
    if (!startedOn || !finishedOn) return
    onSubmit({ finishedOn, startedOn })
  }

  render() {
    const { startedOn, finishedOn } = this.state
    const { children } = this.props
    return (
      <AcademicYearTermForm
        startDate={startedOn}
        endDate={finishedOn}
        onStartDateChange={this.handleChange('startedOn')}
        onEndDateChange={this.handleChange('finishedOn')}
        startDateFieldId="year-startedOn"
        endDateFieldId="year-finishedOn"
      >
        <Fragment>
          <Button submit className="align-bottom" onClick={this.handleSubmit}>
            {t('shared.save')}
          </Button>
          {children}
        </Fragment>
      </AcademicYearTermForm>
    )
  }
}

AcademicYearForm.propTypes = propTypes
AcademicYearForm.defaultProps = defaultProps

export default AcademicYearForm
