import React from 'react'
import { array, arrayOf, func, object, oneOfType, shape, string } from 'prop-types'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Access from 'src/components/Access'
import Div from 'src/components/Div'
import gradeShape from 'src/shapes/grade'
import Input from 'src/components/forms/Input'
import Select from 'src/components/forms/Select'
import sortBy from 'lodash/sortBy'

const propTypes = {
  cancelPath: string.isRequired,
  errors: oneOfType([array, object]),
  grades: arrayOf(shape(gradeShape)).isRequired,
  onSubmit: func.isRequired,
}

const defaultProps = {
  errors: [],
}

const validate = (values) => {
  const errors = {}
  if (!values.code) { errors.code = 'Required' }
  if (!values.gradeId) { errors.gradeId = 'Required' }
  return errors
}

const YearForm = ({
  cancelPath,
  errors,
  grades,
  initialValues,
  onSubmit,
}) => (
  <Formik
    validate={validate}
    initialValues={initialValues}
    onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
  >
    {({ isSubmitting }) => (
      <Form>
        <Access staff>
          <Input
            name="code"
            labelToken="year.code"
            className="mb pr1 col-6 qa-year-code"
            errors={errors.code}
            required
          />
          <Select
            className="mb pr1 col-6 qa-year-gradeId"
            name="gradeId"
            labelToken="year.grade"
            options={sortBy(grades, 'title')}
            errors={errors.gradeId}
          />
        </Access>
        <Div mt2 flex gutter={1} align="center">
          <Div px={1}>
            <button className="btn rounded bg-blue white" type="submit" disabled={isSubmitting}>
              {t('shared.save')}
            </button>
          </Div>
          <Div px={2}>
            <Link
              className="gray qa-years-cancel"
              to={cancelPath}
            >
              {t('shared.cancel')}
            </Link>
          </Div>
        </Div>
      </Form>
    )}
  </Formik>
)

YearForm.propTypes = propTypes
YearForm.defaultProps = defaultProps

export default YearForm
