import React from 'react'
import {
  any,
  bool,
  func,
  string,
  arrayOf,
  shape,
} from 'prop-types'

import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import instructionShape from 'src/shapes/instruction'
import KeyPress from 'src/components/KeyPress'
import Icon from 'src/components/Icon'
import camelToKebab from 'src/lib/camelToKebab'
import Instruction from 'src/components/Game/Instruction'

const propTypes = {
  children: any.isRequired,
  handleInstruction: func.isRequired,
  instructions: arrayOf(shape(instructionShape)).isRequired,
  isShowInstruction: bool.isRequired,
  moduleId: string.isRequired,
}
const defaultProps = {
  instructions: [],
}

const matchInstructionByName = moduleId => (
  ({ slug }) => (`instructions-${camelToKebab(moduleId)}` === slug)
)

class Instructions extends React.Component {
  constructor(props) {
    super(props)
    const { instructions, moduleId } = this.props
    const instruction = instructions.find(matchInstructionByName(moduleId))
    const title = instruction && instruction.title
    const meaning = instruction && instruction.meaning && instruction.meaning.title
    const image = instruction && instruction.image && instruction.image.src
    this.state = {
      hasInstruction: title && meaning && image,
      image,
      meaning,
      title,
    }
  }

  componentDidMount() {
    const { hasInstruction } = this.state
    const { handleInstruction } = this.props
    if (!hasInstruction) { handleInstruction() }
  }

  render() {
    const { children, isShowInstruction, handleInstruction } = this.props
    const { hasInstruction, title, meaning, image } = this.state
    const show = hasInstruction && isShowInstruction
    return (
      <Deck>
        {isShowInstruction && <KeyPress keys={{ Enter: () => handleInstruction() }} />}
        <Deck muted={show}>
          {children}
        </Deck>
        {
          show && (
          <Div
            id="qa-instructions"
            flex
            column
            justify="center"
            absolute
            cursor-pointer
            stretch
            z1
            noPrint
            animated
            slideInDown
            qa-instructions
            onClick={() => handleInstruction()}
          >
            <Div
              relative
              flex
              auto
              round
              p={2}
              m={4}
              size={2}
              style={{
                background: '#b98706',
                boxShadow: 'rgba(62, 62, 62, 0.27) 0px -3px 0px 4px inset, 0 0 3rem 0 rgba(0,0,0,.25)',
              }}
            >
              <Div absolute top-0 right-0 p={2}>
                <Icon icon="times" className="white" top-0 right-0 p={2} size={2} absolute />
              </Div>
              <Div
                auto
                rounded
                flex
                p={3}
                px={4}
                style={{
                  background: 'rgb(21, 50, 8)',
                  boxShadow: 'rgba(35, 35, 35, 0.83) 0px 3px 0px 4px inset',
                  color: 'rgb(237, 237, 237)',
                  lineHeight: '4rem',
                }}
              >
                <Instruction {...{ image, meaning, title }} />
              </Div>
            </Div>
          </Div>
          )}
      </Deck>
    )
  }
}

Instructions.propTypes = propTypes
Instructions.defaultProps = defaultProps
export default Instructions
