import React from 'react'
import { useParams } from 'react-router'
import ModuleDemo from 'src/components/ModuleDemo'

const PreviewCaptureModulePage = () => {
  const { moduleId } = useParams()
  return (
    <ModuleDemo moduleId={moduleId} isDemo capture />
  )
}

export default PreviewCaptureModulePage
