import React, { Component } from 'react'
import { object, func, string, bool } from 'prop-types'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import ToneMarker from 'src/modules/ToneMarker/ToneMarker'

const propTypes = {
  ...gamePropTypes,
  cards: object,
  correctEntityId: string,
  entitiesById: object,
  hearEntityId: string,
  incorrectEntityId: string,
  init: func.isRequired,
  onClick: func.isRequired,
  started: bool,
}

const defaultProps = {
  cards: {},
  correctEntityId: '',
  entitiesById: {},
  hearEntityId: '',
  incorrectEntityId: '',
  started: true,
}

class ToneMarkerContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init()
  }

  render() {
    const {
      actualEntities,
      cards,
      completed,
      entitiesById,
      hearEntityId,
      mark,
      onClick,
      retry,
      started,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ToneMarker
          autoPlay={started}
          cards={cards}
          completed={completed}
          entitiesById={entitiesById}
          hearEntityId={hearEntityId}
          onClick={onClick}
        />
      </Game>
    )
  }
}

ToneMarkerContainer.defaultProps = defaultProps
ToneMarkerContainer.propTypes = propTypes
ToneMarkerContainer.demoData = {
  entities: [
    'phrase-last-monday-i-ate-mangoes',
    'phrase-he-is-watering-the-plants-in-the-garden',
    'garden',
    'phrase-last-sunday-i-ate-lettuces',
    'phrase-we-are-parents',
    'parents',
  ],
}

export default ToneMarkerContainer
