import React from 'react'
import { useHistory } from 'react-router'
import { arrayOf, shape } from 'prop-types'
import { t } from 'i18next'

import Breadcrumbs from 'src/components/Breadcrumbs'
import Widget from 'src/components/Widget'
import { schoolOverviewReportPath } from 'src/lib/routes'
import OverviewChart from 'src/pages/SchoolOverview/shared/OverviewChart'
import academicYearShape from 'src/shapes/academicYear'
import sectionShape from 'src/shapes/section'
import { icon } from 'src/pages/SchoolOverview/config'
import HomeworkPageTableRow from 'src/pages/SchoolOverview/shared/TableRow'
import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'
import usePath from 'src/lib/routes/usePath'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  section: shape(sectionShape).isRequired,
}

const defaultProps = {
  dataset: [],
}

const SchoolOverviewSectionScene = ({ academicYears, section, grades }) => {
  const history = useHistory()
  const schoolOverview = usePath(schoolOverviewReportPath)
  return (
    <Widget
      icon={icon}
      title={(
        <Breadcrumbs
          crumbs={[
            {
              path: schoolOverview({ gradeId: null, sectionId: null }),
              title: t('school.overview'),
            },
          ]}
          currentTitle={section.title}
        />
      )}
    >
      <OverviewChart
        academicYears={academicYears}
        dataset={grades}
        onClick={grade => history.push(schoolOverview({ gradeId: grade.slug }))}
      />
      <StickyTable>
        <StickyTableHeader>
          <th>{t('school.grade')}</th>
          <th>{t('homework.students')}</th>
          <th>{t('homework.grade')}</th>
          <th>{t('homework.completedRate')}</th>
          <th>{t('homework.assignedRate')}</th>
        </StickyTableHeader>
        <tbody>
          {
            grades.map(grade => (
              <HomeworkPageTableRow
                key={grade.id}
                resource={grade}
                linkTo={schoolOverview({ gradeId: grade.slug })}
              />
            ))
          }
        </tbody>
      </StickyTable>
    </Widget>
  )
}

SchoolOverviewSectionScene.propTypes = propTypes
SchoolOverviewSectionScene.defaultProps = defaultProps

export default SchoolOverviewSectionScene
