import React from 'react'
import ReactMarkdown from 'react-markdown'
import { string, element } from 'prop-types'

const propTypes = {
  source: string,
  children: element,
}

const Markdown = ({ source, children }) => (
  <div style={{ display: 'inline-block', padding: '1rem', textAlign: 'left' }}>
    <ReactMarkdown source={source || children} />
  </div>
)

Markdown.propTypes = propTypes
export default Markdown
