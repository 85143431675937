import React, { useEffect, useState } from 'react'
import { string, object, oneOfType, bool, instanceOf } from 'prop-types'
import { Rollbar } from 'src/lib/window'
import Icon from 'src/components/Icon'
import pluck from 'src/lib/pluck'

const propTypes = {
  bgColor: string,
  error: instanceOf(Error),
  extraInfo: string,
  message: oneOfType([string, object]).isRequired,
  report: bool,
}

const defaultProps = {
  bgColor: 'orange',
  error: null,
  report: false,
}

const ErrorMessage = ({ bgColor, error, message, report, extraInfo }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (report && Rollbar) Rollbar.error(error || new Error(message))
  })
  const msg = message || pluck(error, 'message')
  const extra = extraInfo || pluck(error, 'networkError.result.error.message')
  return msg || extra ? (
    <div
      className={`
      qa-error
      ${report ? 'qa-error-report' : ''}
      align-center
      animated
      bg-${bgColor}
      flex
      justify-center
      center
      fadeIn
      p3
      rounded
      slowly
      white
      print-hide
    `}
    >
      <span className="animated delayed shake quickly">
        <Icon before size={3} icon="frown" />
      </span>
      <div>
        {msg}
        {extra && (
          <div
            className="muted col-12 left-align pointer"
            onClick={() => setShow(!show)}
          >
            {show && <div>[-] {extra}</div>}
            {!show && <div>[+] More</div>}
          </div>
        )}
      </div>
    </div>
  ) : null
}

ErrorMessage.propTypes = propTypes
ErrorMessage.defaultProps = defaultProps

export default ErrorMessage
