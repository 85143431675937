import React from 'react'
import { bool, func, object, shape, string, objectOf, oneOfType, number } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import EntityBubble from 'src/components/EntityBubble'
import KeyPress from 'src/components/KeyPress'
import MultipleChoiceImageCard from 'src/modules/UnitTestMultipleChoice/MultipleChoiceImageCard'
import { ENGLISH } from 'src/constants/language'
import Bubble from 'src/components/Bubble'

const propTypes = {
  answerId: string,
  audioOnly: bool,
  cards: objectOf(
    shape({
      id: string.isRequired,
      label: number.isRequired,
      src: string,
      status: oneOfType([bool, string]),
    }).isRequired,
  ).isRequired,
  entitiesById: object.isRequired,
  language: string,
  listen: bool,
  muted: bool,
  paragraph: bool,
  paragraphEntity: shape({
    image: string,
    paragraph: string,
  }),
  questionId: string,
  selectCard: func.isRequired,
  silent: bool,
}

const defaultProps = {
  answerId: '',
  audioOnly: false,
  language: ENGLISH,
  listen: false,
  muted: false,
  paragraph: false,
  paragraphEntity: {},
  questionId: '',
  silent: false,
  started: false,
}

const entityHasImage = entity => !!({ ...entity }.image && entity.image.src)
const entityHasSound = entity => !!({ ...entity }.sound && entity.sound.srcEn)

const MultipleChoice = ({
  audioOnly,
  answerId,
  cards,
  entitiesById,
  language,
  listen,
  muted,
  paragraph,
  paragraphEntity,
  questionId,
  selectCard,
  silent,
}) => {
  const paragraphImageSrc = { ...paragraphEntity }.image
  const paragraphTitle = { ...paragraphEntity }.paragraph
  const answerEntity = entitiesById[answerId]
  const showImage = entityHasImage(answerEntity)
  const showPlayImage = entityHasSound(answerEntity) && !showImage
  return (
    <Deck>
      <Div flex auto>
        <Div flex column col={6} auto>
          {
            (paragraphTitle || paragraphImageSrc || audioOnly) && (
              <Div flex justify-center column auto px={1}>
                {
                  (paragraphImageSrc || listen) && (
                    <Div
                      flex
                      col={12}
                      mb={(audioOnly || listen) ? 0 : 3}
                      height={(audioOnly || listen) ? null : 13.5}
                      auto={(audioOnly || listen)}
                    >
                      <EntityBubble
                        play={!paragraphImageSrc}
                        audio={listen}
                        english
                        entity={entitiesById[paragraphEntity.id]}
                        key={paragraphEntity}
                        image={!!paragraphImageSrc}
                        label={listen && ' '}
                      />
                    </Div>
                  )
                }
                {
                  paragraph && !audioOnly && paragraphTitle && !listen && (
                    <Div flex col={12} auto>
                      <AdaptiveCard
                        align="left"
                        minHeight={false}
                        p={2}
                        title={paragraphTitle}
                      />
                    </Div>
                  )
                }
                {
                  audioOnly && (
                    <Div
                      flex
                      col={12}
                      mt2
                      center
                      height={audioOnly && !paragraphImageSrc ? 22 : 14.5}
                    >
                      <EntityBubble
                        audio
                        english
                        entity={entitiesById[paragraphEntity.id]}
                        label=" "
                        meaning
                        minHeight={false}
                        play
                        keyToPlay=" "
                      />
                    </Div>
                  )
                }
              </Div>
            )
          }
          {
            answerId && !paragraph && (
              <Div flex column auto>
                <MultipleChoiceImageCard
                  imageEntity={answerEntity}
                  showImage={showImage}
                  showPlayImage={showPlayImage}
                />
              </Div>
            )
          }
        </Div>
        <Div flex justify="center" col={6} column px={1} auto>
          {
            questionId && (
              <Div flex pb={3} justify="center">
                <Bubble label="␣">
                  <AdaptiveCard
                    audio
                    bold
                    english={language === ENGLISH}
                    thai={language !== ENGLISH}
                    entity={entitiesById[questionId]}
                    key={entitiesById[questionId].id}
                    silent={silent}
                    height={12}
                    minHeight={false}
                    showPlayMark
                    textAlign="center"
                    p={1}
                    style={{ paddingLeft: '2rem' }}
                    {...(!audioOnly && ({ keyToPlay: ' ', play: true }))}
                  />
                </Bubble>
              </Div>
            )
          }
          {
            map(cards, card => (
              <Div key={card.key} flex auto flex-1>
                <AdaptiveCard
                  absolutelyPositionedLabel
                  audio
                  auto
                  bold
                  correct={card.correct}
                  english
                  entity={entitiesById[card.id]}
                  keyToPlay={card.label}
                  onClick={() => selectCard({ cardId: card.id })}
                  label={card.label}
                  selected={card.selected}
                  silent={muted || silent}
                  minHeight={false}
                />
                <KeyPress keys={{ [card.label]: () => selectCard({ cardId: card.id }) }} />
              </Div>
            ))
          }
        </Div>
      </Div>
    </Deck>
  )
}

MultipleChoice.propTypes = propTypes
MultipleChoice.defaultProps = defaultProps

export default MultipleChoice
