import React, { useContext } from 'react'
import { shape } from 'prop-types'
import Div from 'src/components/Div'
import UserImageLink from 'src/components/UserImageLink'
import Access from 'src/components/Access'
import UpdateAvatar from 'src/pages/User/shared/UpdateAvatar'
import userShape from 'src/shapes/user'
import UserForm from 'src/pages/User/shared/Form'
import { UserContext } from 'src/components/SetUser/SetUser'

const propTypes = {
  user: shape(userShape).isRequired,
}

const UserEdit = ({ errors, user, onSubmit, cancelPath }) => {
  const { isParent } = useContext(UserContext)
  const { slug } = user
  return (
    <Div p2 bg-white rounded>
      <h2>{user.fullName}</h2>
      <Div flex gutter={2}>
        <Div auto>
          <UserForm
            isParent={isParent}
            initialValues={user}
            errors={errors}
            onSubmit={onSubmit}
            cancelPath={cancelPath}
          />
        </Div>
        <Div ml2>
          <UserImageLink user={user} />
          <Access teacher staff parent student>
            <UpdateAvatar userId={slug} />
          </Access>
        </Div>
      </Div>
    </Div>
  )
}

UserEdit.propTypes = propTypes
export default UserEdit
