import React, { Fragment } from 'react'
import classnames from 'classnames'
import { element, string, number, func, bool, oneOfType, arrayOf } from 'prop-types'
import { t } from 'i18next'
import MicrophoneButton from 'src/components/MicrophoneButton'
import Div from 'src/components/Div'
import AudioMeter from 'src/components/AudioMeter'
import { isMobile } from 'src/lib/window'
import wait from 'src/lib/wait'
import KeyPress from 'src/components/KeyPress/KeyPress'

const Action = ({ children, className, ...rest }) => (
  <a
    className={classnames('cursor-pointer underline mx', className)}
    {...rest}
  >
    {children}
  </a>
)

Action.propTypes = {
  children: element.isRequired,
  className: string.isRequired,
}

const noop = () => {}

export default class MicrophoneCheck extends React.Component {
  constructor(props) {
    super(props)
    const { disabled } = props
    this.state = {
      done: disabled || !!isMobile(),
      success: false,
    }
  }

  skip = async () => {
    this.setState({ done: true })
  }

  handleAudioData = async (volume) => {
    const { threshold, wait: waitTime } = this.props
    // It gets 0-volume events during initialization. Just ignore them.
    if (volume === 0) return
    const gotSound = volume > threshold
    if (gotSound) {
      this.setState({ success: true })
      if (this.noSoundInitially) await wait(waitTime)
      this.setState({ done: true })
    } else {
      this.noSoundInitially = true
    }
  }

  render() {
    const { success, done } = this.state
    const { children, Wrapper } = this.props
    if (done) return children
    return (
      <Wrapper>
        <Div flex justify="center">
          <div>
            <MicrophoneButton
              onMouseDown={noop}
              onKeyPress={noop}
              onMouseUp={noop}
              hideKeyHandler
              opaque
              selected={success}
            >
              <AudioMeter onAudioData={this.handleAudioData} />
            </MicrophoneButton>
            <Div my2 style={{ textAlign: 'center' }}>
              <div>{t('shared.microphoneCheck')}...</div>
              <Action
                style={{ fontSize: '1.6em' }}
                className="qa-skip-mic-check"
                onClick={this.skip}
              >
                <Fragment>
                  {t('shared.skip')}
                  <KeyPress keys={{ Enter: this.skip }} />
                  <i
                    className="fas fa-level-down-alt fa-rotate-90"
                    style={{ fontSize: '.5em', marginLeft: '.5em', verticalAlign: 'middle' }}
                  />
                </Fragment>
              </Action>
            </Div>
          </div>
        </Div>
      </Wrapper>
    )
  }
}

MicrophoneCheck.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  disabled: bool,
  threshold: number,
  wait: number,
  Wrapper: func,
}

MicrophoneCheck.defaultProps = {
  Wrapper: Div,
  children: null,
  disabled: false,
  threshold: 0.001,
  wait: 2000,
}
