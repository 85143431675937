import React from 'react'
import { shape, func } from 'prop-types'
import Card from 'src/components/Card'
import Place from 'src/components/Place'
import EntityCard from 'src/components/EntityCard'
import entityShape from 'src/shapes/entity'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'

const propTypes = {
  cards: shape().isRequired,
  entity: shape(entityShape),
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderPlace = ({ placeId, titleEn, ...rest }, moveCard, i) => (
  <Place
    key={i}
    id={placeId}
    onDrop={moveCard}
  >
    <Card
      locale="en"
      size={0}
      fit
      draggable
      {...rest}
      title={titleEn}
    />
  </Place>
)

const UnscrambleWords = ({ entity, cards, moveCard, places }) => (
  <Deck>
    <Div flex auto>
      {entity && <EntityCard key={entity.id} image audio autoPlayOnce english entity={entity} />}
    </Div>
    <Div flex justify="center" gutter>
      {Object.keys(places).map((id, i) => renderPlace(cards[places[id].cardId], moveCard, i))}
    </Div>
  </Deck>
)

UnscrambleWords.propTypes = propTypes
export default UnscrambleWords
