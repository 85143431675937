import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { bool, string } from 'prop-types'
import { assessmentStudentPath } from 'src/lib/routes'
import StudentList from 'src/components/StudentList/StudentListContainer'
import DomainContext from 'src/contexts/DomainContext'

const StudentsContainer = (props) => {
  const history = useHistory()
  const domain = useContext(DomainContext)
  const { yearId, marked } = props
  return (
    <StudentList
      {...props}
      domain={domain}
      studentPath={(id, userId) => assessmentStudentPath({ marked, userId, yearId })}
      onYearChange={yearId => history.push(assessmentStudentPath({ yearId }))}
    />
  )
}

StudentsContainer.propTypes = {
  marked: bool,
  yearId: string,
}

StudentsContainer.defaultProps = {
  marked: false,
}

export default StudentsContainer
