import { array } from 'prop-types'
import AlphabetFlashCard from 'src/modules/AlphabetFlashCard/AlphabetFlashCard'

const propTypes = {
  alphabetCursiveEn: array.isRequired,
}


const AlphabetCursiveFlashCard =
  ({ alphabetCursiveEn: alphabet }) => AlphabetFlashCard({ alphabetEn: alphabet })

AlphabetCursiveFlashCard.propTypes = propTypes

export default AlphabetCursiveFlashCard
