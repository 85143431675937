import React from 'react'
import {
  any,
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types'
import classnames from 'classnames'

const WHITESPACE = oneOf([true, 0, 1, 2, 3, 4])

const propTypes = {
  align: string,
  auto: bool,
  backgroundImage: string,
  backgroundSize: string,
  bg: string,
  children: any,
  className: string,
  col: number,
  color: string,
  column: bool,
  fontSize: number,
  fx: number,
  gutter: WHITESPACE,
  height: number,
  id: string,
  justify: string,
  lessTransparent: bool,
  lineHeight: number,
  m: WHITESPACE,
  mb: WHITESPACE,
  mbn: WHITESPACE,
  ml: WHITESPACE,
  mln: WHITESPACE,
  mr: WHITESPACE,
  mrn: WHITESPACE,
  mt: WHITESPACE,
  mtn: WHITESPACE,
  mx: WHITESPACE,
  mxn: WHITESPACE,
  my: WHITESPACE,
  myn: WHITESPACE,
  noPrint: bool,
  onClick: func,
  p: WHITESPACE,
  pb: WHITESPACE,
  pl: WHITESPACE,
  pr: WHITESPACE,
  preWrap: bool,
  px: WHITESPACE,
  py: WHITESPACE,
  semiTransparent: bool,
  size: number,
  style: shape(),
  tabIndex: number,
  title: string,
  width: number,
  wrap: bool,
  z: number,
}

const defaultProps = {
  align: undefined,
  auto: undefined,
  backgroundImage: undefined,
  backgroundSize: undefined,
  bg: undefined,
  children: undefined,
  className: '',
  col: undefined,
  color: undefined,
  column: undefined,
  fontSize: undefined,
  fx: undefined,
  gutter: undefined,
  height: undefined,
  id: undefined,
  justify: undefined,
  lessTransparent: undefined,
  lineHeight: undefined,
  m: undefined,
  mb: undefined,
  mbn: undefined,
  ml: undefined,
  mln: undefined,
  mr: undefined,
  mrn: undefined,
  mt: undefined,
  mtn: undefined,
  mx: undefined,
  mxn: undefined,
  my: undefined,
  myn: undefined,
  noPrint: undefined,
  onClick: undefined,
  p: undefined,
  pb: undefined,
  pl: undefined,
  pr: undefined,
  px: undefined,
  py: undefined,
  preWrap: undefined,
  semiTransparent: undefined,
  size: undefined,
  style: undefined,
  tabIndex: undefined,
  title: undefined,
  width: undefined,
  wrap: undefined,
  z: undefined,
}

const Div = ({
  align,
  auto,
  backgroundImage,
  backgroundSize,
  bg,
  children,
  className,
  col,
  color,
  column,
  fontSize,
  fx,
  gutter,
  height,
  id,
  justify,
  lineHeight,
  lessTransparent,
  m,
  mb,
  mbn,
  ml,
  mln,
  mr,
  mrn,
  mt,
  mtn,
  mx,
  mxn,
  my,
  myn,
  noPrint,
  onClick,
  p,
  pb,
  pl,
  pr,
  px,
  py,
  preWrap,
  semiTransparent,
  size,
  style,
  tabIndex,
  title,
  width,
  wrap,
  z,
  ...props
}) => {
  const theClassName = classnames({
    [className]: className,
    [color]: color,
    'flex-auto': auto,
    'flex-column': column,
    'flex-wrap': wrap,
    'less-transparent': lessTransparent,
    'pre-wrap': preWrap,
    'print-hide': noPrint,
    'semi-transparent': semiTransparent,
    [`align-${align}`]: align,
    [`bg-${bg}`]: bg,
    [`col-${col}`]: col,
    [`flex-${fx}`]: fx,
    [`h${size}`]: size !== undefined,
    [`justify-${justify}`]: justify,
    [`m${m === true ? '' : m}`]: m,
    [`mb${mb === true ? '' : mb}`]: mb,
    [`mbn${mbn === true ? '' : mbn}`]: mbn,
    [`ml${ml === true ? '' : ml}`]: ml,
    [`mln${mln === true ? '' : mln}`]: mln,
    [`mr${mr === true ? '' : mr}`]: mr,
    [`mrn${mrn === true ? '' : mrn}`]: mrn,
    [`mt${mt === true ? '' : mt}`]: mt,
    [`mtn${mtn === true ? '' : mtn}`]: mtn,
    [`mx${mx === true ? '' : mx}`]: mx,
    [`gutter${gutter === true ? '' : `-${gutter}`}`]: gutter,
    [`mxn${mxn === true ? '' : mxn}`]: mxn,
    [`my${my === true ? '' : my}`]: my,
    [`myn${myn === true ? '' : my}`]: my,
    [`p${p === true ? '' : p}`]: p,
    [`pb${pb === true ? '' : pb}`]: pb,
    [`pl${pl === true ? '' : pl}`]: pl,
    [`pr${pr === true ? '' : pr}`]: pr,
    [`px${px === true ? '' : px}`]: px,
    [`py${py === true ? '' : py}`]: py,
    [`z${z}`]: z,
    ...props,
  })
  const validProps = {
    id,
    onClick,
    title,
  }
  const bgiStyle = backgroundImage && {
    backgroundImage: `url("${backgroundImage}")`,
    backgroundSize: backgroundSize || 'cover',
  }
  const customHeight = height && {
    height: `${height}rem`,
  }
  const customWidth = width && {
    width: `${width}rem`,
  }
  const customFontSize = fontSize && {
    fontSize: `${fontSize}rem`,
  }

  const customLineHeight = lineHeight && {
    lineHeight: `${lineHeight}rem`,
  }

  return (
    <div
      className={theClassName}
      style={{
        ...bgiStyle,
        ...customHeight,
        ...customWidth,
        ...customFontSize,
        ...customLineHeight,
        ...style,
      }}
      tabIndex={tabIndex}
      {...validProps}
    >
      {children}
    </div>
  )
}

Div.propTypes = propTypes
Div.defaultProps = defaultProps

export default Div
