import { compose } from 'src/lib/redux'

import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import SchoolOverviewGradeScene from 'src/pages/SchoolOverview/Grade/Scene'
import academicYearsQuery from 'src/pages/SchoolOverview/shared/academicYears.gql'
import gradeQuery from 'src/pages/SchoolOverview/Grade/grade.gql'
import yearsHomeworkReportQuery from 'src/pages/SchoolOverview/Grade/yearsHomeworkReport.gql'

export default compose(
  gqlLoader(academicYearsQuery),
  gqlLoader(gradeQuery),
  gqlLoader(yearsHomeworkReportQuery, networkOnlyConfig),
)(SchoolOverviewGradeScene)
