import React from 'react'
import { array, bool, object, shape } from 'prop-types'
import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import KeyLabel from 'src/components/KeyLabel'
import normalizeImage from 'src/lib/normalizeImage.js'
import Phonics from 'src/modules/Word/Phonics'
import Spelling from 'src/modules/Word/Spelling'

const propTypes = {
  alphabetEn: array.isRequired,
  entity: shape(entityShape),
  firstLine: object.isRequired,
  image: object.isRequired,
  lastLine: object.isRequired,
  meaning: object,
  phonics: array,
  sentenceEntity: shape(entityShape),
  sentenceExample: bool,
  spelling: bool,
  ThaiWord: bool,
  withPartOfSpeech: bool,
}
const defaultProps = {
  withPartOfSpeech: false,
}

const Word = ({
  alphabetEn,
  entity,
  firstLine,
  image: imageEntity,
  lastLine,
  meaning,
  phonics,
  sentenceEntity,
  sentenceExample,
  spelling,
  ThaiWord,
  withPartOfSpeech,
}) => {
  const lastKeyLabel = (sentenceExample && entity.titleTh) || (phonics && phonics.length > 1)
    ? '3' : '2'
  const imageEntityImage = { ...imageEntity }.image
  const imageEntitySrc = { ...imageEntityImage }.src
  return (
    <Div flex auto gutter={2}>
      {
        normalizeImage(imageEntitySrc) && (
          <Div flex col={4} px={2}>
            <EntityImage entity={imageEntity} />
          </Div>
        )
      }
      <Div px={2} flex auto>
        <Div flex column auto justify="center">
          <Div flex mb={2} justify="center">
            <KeyLabel name="qa-label-1" label="1" />
            <Div flex align="end" auto>
              <AdaptiveCard
                autoPlayOnce
                bold
                className="wordPrint"
                entity={entity}
                height={10}
                keyToPlay="1"
                minHeight={false}
                p={2}
                withPartOfSpeech={withPartOfSpeech}
                {...firstLine}
              />
            </Div>
          </Div>
          { (sentenceExample && entity.titleTh)
              || (phonics && phonics.length > 1)
            ? (
              <Div flex justify="center" mb={2}>
                <KeyLabel name="qa-label-2" label="2" />
                <Div flex align="end" auto>
                  {!sentenceExample &&
                    (spelling
                      ? <Spelling alphabetEn={alphabetEn} phonics={phonics} />
                      : <Phonics phonics={phonics} />)
                  }
                  {sentenceExample && (
                  <AdaptiveCard
                    audio
                    autoPlayOnce
                    className="wordPrint"
                    entity={entity}
                    height={10}
                    keyToPlay="2"
                    minHeight={false}
                    name="qa-adaptive-card-2-sentence"
                    p={2}
                    thai
                  />
                  )}
                </Div>
              </Div>
            ) : null
          }
          {
            sentenceExample
              || (!ThaiWord && lastLine.thai && entity.titleTh)
              || (ThaiWord && meaning)
              ? (
                <Div flex justify="center">
                  <KeyLabel name="qa-label-3" label={lastKeyLabel} />
                  <Div flex align="end" auto>
                    {sentenceExample
                      ? (
                        <AdaptiveCard
                          audio
                          className="wordPrint"
                          entity={sentenceEntity}
                          highlight={entity}
                          height={10}
                          keyToPlay={lastKeyLabel}
                          minHeight={false}
                          name="qa-adaptive-card-3-sentence"
                          p={2}
                          {...lastLine}
                        />
                      ) : (
                        <AdaptiveCard
                          audio
                          className="wordPrint"
                          entity={ThaiWord ? meaning : entity}
                          height={10}
                          keyToPlay={lastKeyLabel}
                          minHeight={false}
                          name="qa-adaptive-card-3-thai"
                          p={2}
                          {...lastLine}
                        />
                      )}
                  </Div>
                </Div>
              ) :
              null}
        </Div>
      </Div>
    </Div>
  )
}

Word.propTypes = propTypes
Word.defaultProps = defaultProps

export default Word
