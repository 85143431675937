import React from 'react'
import { bindActionCreators } from 'redux'
import { bool, number, shape, string } from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'

import Card from 'src/components/Card'
import Check from 'src/components/Check'
import Div from 'src/components/Div'
import Donut from 'src/components/Donut'
import trackEvent from 'src/components/analytics'
import * as homeworkActions from 'src/pages/Homework/reducer'
import KeyPress from 'src/components/KeyPress'
import { userHomePagePath } from 'src/lib/routes'
import userShape from 'src/shapes/user'
import sadFace from 'src/modules/HomeworkDone/FeelingFacesSad.svg'
import smileyFace from 'src/modules/HomeworkDone/FeelingFacesSmiley.svg'

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  homework: bool.isRequired,
  homeworkWrapUp: shape({
    completed: number,
    total: number,
  }),
  lessonSlug: string.isRequired,
  user: shape(userShape),
}

const defaultProps = {
  homeworkWrapUp: {},
  user: {},
}
class HomeworkDone extends React.Component {
  componentDidMount() {
    const { homework, lessonSlug } = this.props
    if (homework) {
      trackEvent({
        action: 'done',
        category: 'homework',
        label: lessonSlug,
      })
    }
  }

  rate = (action) => {
    const { homework, user, lessonSlug, history } = this.props
    trackEvent({
      action,
      category: homework ? 'homework' : 'lesson',
      label: lessonSlug,
    })
    history.push(userHomePagePath(user))
  }

  like = () => this.rate('like')

  dislike = () => this.rate('dislike')

  render() {
    const { homework, homeworkWrapUp: { completed, total } = {}, user } = this.props
    return (
      <Div flex auto column px={4}>
        <KeyPress keys={{ 1: this.like, 2: this.dislike }} />
        <Div flex align="center" size={0} bold mb={2} mt={2}>
          <Check className="mr3 mb2" />
          {t('wellDone.wellDone')}
        </Div>
        <Div flex auto align="center" mb={4} gutter={2}>
          {user && user.roleId === 'student' && (
          <Div auto mx={2}>
            <Div flex size={2} bold mt={3} mb={3}>
              {t('wellDone.howDoYouFeel')}
            </Div>
            <Div flex auto justify="center" mb={1} gutter={2}>
              <Div flex auto mx={2}>
                <Card
                  image
                  title={smileyFace}
                  onClick={this.like}
                  className="p1"
                />
              </Div>
              <Div flex auto mx={2}>
                <Card
                  image
                  title={sadFace}
                  onClick={this.dislike}
                  className="p1"
                />
              </Div>
            </Div>
          </Div>
          )}
          {
            homework && (
              <Div flex col={4} justify="center" py={4} px={1} mb={4} mx={2}>
                <Donut value={completed / total} />
              </Div>
            )
          }
        </Div>
      </Div>
    )
  }
}

HomeworkDone.propTypes = propTypes
HomeworkDone.defaultProps = defaultProps

const selector = state => state.homework
const actions = dispatch => bindActionCreators({ ...homeworkActions }, dispatch)
export default connect(selector, actions)(withRouter(HomeworkDone))
