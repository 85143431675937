import AdaptiveCard from 'src/components/AdaptiveCard'
import React from 'react'
import Div from 'src/components/Div'
import { bool, func, string, number, shape, arrayOf } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Card from 'src/components/Card/Card'
import KeyPress from 'src/components/KeyPress/KeyPress'
import Place from 'src/components/Place'
import EntityBubble from 'src/components/EntityBubble'
import { keyAvailable, keyLetter, keyboardShape, unusedKeyIndexMap } from 'src/modules/FillInMissingLetters/keyboard'

const idForKey = l => `l_${l}`
const keyOfId = id => parseInt(id.substring(2), 10)

const moveCard = onKey => ({ sourceId }) => {
  onKey(keyOfId(sourceId))
}

const propTypes = {
  completed: bool,
  displayResult: func,
  entity: shape(entityShape).isRequired,
  keyboard: keyboardShape.isRequired,
  lastKey: shape(entityShape),
  letterIndex: number,
  letters: arrayOf(string.isRequired).isRequired,
  onKey: func,
  onLetterAudioDone: func,
  wordCompleted: bool,
}
const defaultProps = {
  completed: null,
  displayResult: () => {},
  lastKey: null,
  letterIndex: 0,
  onKey: () => {},
  onLetterAudioDone: () => {},
  wordCompleted: null,
}

// eslint-disable-next-line react/prop-types
const PlaceOrDiv = ({ place, onKey, children, ...rest }) => (place ? (
  <Place {...rest} onDrop={moveCard(onKey)} mx={false}>
    {children}
  </Place>
) : (
  <Div {...rest}>{children}</Div>
))

const renderWord = (entity, onAudioDone) => (
  <AdaptiveCard
    audio
    autoPlay
    bold
    english
    entity={entity}
    className="shadow"
    height={12}
    playNext={() => onAudioDone()}
  />
)

const renderWordLetters = ({ onKey, letters, letterIndex }) => (
  letters.map((l, index) => {
    const current = letterIndex === index
    return (
      <PlaceOrDiv
        key={`wordLetters-${index}`} // eslint-disable-line react/no-array-index-key
        {...{ onKey, place: current }}
        flex-1
      >
        <Card
          fit
          bold
          rounded
          title={l}
          size={0}
          height={12}
          selected={current}
          muted={letterIndex < index}
        />
      </PlaceOrDiv>
    )
  })
)

const enableKeypress = (keyboard, onKey) => (
  Object.entries(unusedKeyIndexMap(keyboard)).map(([key, index]) => (
    <KeyPress
      key={`KeyPress_${key}`}
      keys={{
        [key]: () => {
          onKey(index)
        },
      }}
    />
  ))
)

const renderKeyboard = keyboard => (
  keyboard.map((key, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Div key={`key_${index}`} flex-1>
      <Card
        bold
        fit
        src={key.phonicSrc}
        rounded
        flex-1
        id={idForKey(index)}
        height={8}
        {...{
          ...(!keyAvailable(key) && { correct: key.state }),
          ...(keyAvailable(key) && { draggable: true }),
        }}
      >
        {keyLetter(key)}
      </Card>
    </Div>
  ))
)

const isImage = image => image && image.src
const hasImage = ({ image }) => isImage(image)

const FillInMissingLetters = ({
  completed,
  displayResult,
  entity,
  keyboard,
  lastKey,
  letters,
  letterIndex,
  onKey,
  onLetterAudioDone,
  wordCompleted,
}) => (
  <Div flex auto gutter={2}>
    <Div col={4} flex>
      <EntityBubble
        image
        audio
        english
        entity={entity}
        {...(hasImage(entity) && { label: ' ' })}
      />
    </Div>
    <Div col={8} flex flex-column justify="center">
      <Div flex justify="center" mb2 flex-row>
        {wordCompleted !== null
          ? renderWord(entity, displayResult)
          : renderWordLetters({ letterIndex, letters, onKey })
        }
      </Div>
      <Div flex flex-row style={{ ...(completed && { height: '0px', opacity: '0' }) }}>
        {
          wordCompleted === null && enableKeypress(keyboard, onKey)
        }
        {
          wordCompleted === null && renderKeyboard(keyboard)
        }
        {
          lastKey && (
            <Div hide>
              <Card
                audio
                autoPlay
                src={lastKey.phonicSrc}
                playNext={() => onLetterAudioDone()}
              />
            </Div>
          )
        }
      </Div>
    </Div>
  </Div>
)

FillInMissingLetters.propTypes = propTypes
FillInMissingLetters.defaultProps = defaultProps

export default FillInMissingLetters
