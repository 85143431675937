import React from 'react'
import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router'
import usePath from 'src/lib/routes/usePath'
import calendarPath from 'src/pages/CalendarPage/paths'
import academicYearsQuery from 'src/queries/academicYearsQuery.gql'
import gqlLoader from 'src/lib/gqlLoader'
import CalendarPageContainer from 'src/pages/CalendarPage/CalendarPageContainer'
import YearSummary from 'src/pages/CalendarPage/YearSummary'
import DateSummary from 'src/pages/CalendarPage/DateSummary'

const CalendarPage = gqlLoader(academicYearsQuery)(({
  academicYears,
  ...props
}) => {
  const { yearId, date } = useParams()
  const path = usePath(calendarPath)
  if (!yearId) {
    const academicYear = (academicYears.find(({ current }) => current) || academicYears[0] || {})
    const redirectTo = academicYear.slug || academicYear.id
    if (redirectTo) return <Redirect to={path({ date, yearId: redirectTo })} />
  }
  return (
    <CalendarPageContainer date={date} yearId={yearId} {...props}>
      {date
        ? <DateSummary yearId={yearId} date={date} {...props} />
        : <YearSummary hideTitle yearId={yearId} />
      }
    </CalendarPageContainer>
  )
})

export default CalendarPage
