import collectionProvider from 'src/lib/collectionProvider'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from 'src/modules/DrawLetters/reducer'
import DrawLettersGame from 'src/modules/DrawLetters/DrawLettersGame'

const select = state => state.drawLetters

const actions = dispatch => bindActionCreators(ActionCreators, dispatch)

const defaultExport = connect(
  select,
  actions,
)(
  collectionProvider({
    collections: ['phonicsAlphabet'],
  })(DrawLettersGame),
)

defaultExport.demoData = DrawLettersGame.demoData
export default defaultExport
