import React from 'react'
import { bool, arrayOf } from 'prop-types'

import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  triesIterations: arrayOf(bool).isRequired,
}

const iconNoBg = [true, undefined, 'completed', 'uncompleted']
const iconHide = ['completed', 'uncompleted']

const icons = {
  completed: 'check-circle',
  true: 'heart',
  uncompleted: 'times-circle',
  undefined: 'heart',
}

const iconColors = {
  completed: 'green',
  true: 'gray',
  uncompleted: 'red',
  undefined: 'red',
}

const iconAnimations = {
  completed: 'bounceIn',
  true: 'bounceIn',
  uncompleted: 'bounceIn',
  undefined: 'bounceInUp',
}

const animationDelays = {
  completed: 0,
  true: 0,
  uncompleted: 0,
  undefined: 1,
}

const renderIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const color = iconColors[iteration]
  const icon = icons[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    icon && (
      <Div
        {...{ [animation]: true }}
        animated
        color={color}
        hide={iconHide.indexOf(iteration) !== -1}
        key={`${i}-${iteration}`}
        style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
      >
        <Icon
          className={`qa-homework-iteration-${iteration}`}
          icon={icon}
          inline
          style={{ marginLeft: '1px' }}
        />
      </Div>
    )
  )
}

const renderBackgroundIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    iconNoBg.indexOf(iteration) === -1 && (
      <Div
        {...{ [animation]: true }}
        animated
        key={`${i}-${iteration}`}
        style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
        white
      >
        <Icon inline icon="circle" style={{ marginLeft: '1px' }} />
      </Div>
    )
  )
}

const TriesIteration = ({ triesIterations }) => (
  <div>
    <Div flex>
      <Div absolute amr1 bottom-0 right-0 flex align="center">
        {triesIterations.map(renderBackgroundIcon)}
      </Div>
      <Div absolute amr1 bottom-0 right-0 id="card-select-iterations" flex align="center">
        {triesIterations.map(renderIcon)}
      </Div>
    </Div>
  </div>
)

TriesIteration.propTypes = propTypes

export default TriesIteration
