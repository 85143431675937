import every from 'lodash/every'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import shuffle from 'lodash/shuffle'
import reduce from 'lodash/reduce'
import uniqShuffle from 'src/lib/uniqShuffle'

import moveCards from 'src/reducers/lib/moveCards'
import transformValues from 'src/lib/transformValues'

const INIT = 'ageComparison/INIT'
const MOVE = 'ageComparison/MOVE'
const MARK = 'ageComparison/MARK'
const RETRY = 'ageComparison/RETRY'

const initialState = {
  actualEntities: [],
  availableCardsId: [],
  cards: {},
  completed: null,
  entitiesById: {},
  places: {},
}

const createCards = (a, entity) => ({
  ...a,
  [entity.id]: {
    correct: undefined,
    id: entity.id,
    placeId: null,
    titleEn: entity.titleEn,
  },
})

const createPlaces = (a, entity) => ({
  ...a,
  [entity.id]: {
    cardId: null,
    id: entity.id,
  },
})

const checkCardState = cards => (a, place) => {
  const card = cards[place.cardId]
  if (place.cardId) {
    return {
      ...a,
      [place.cardId]: {
        ...card,
        correct: card.id === place.id,
      },
    }
  }
  return a
}

const findAvailableCards = cards => (
  map(
    map(cards).filter(({ placeId }) => !placeId),
    'id',
  )
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const { length } = entities
      const youngerEntity = uniqShuffle(entities.slice(0, length / 2))[0]
      const elderEntity = uniqShuffle(entities.slice(length / 2, length))[0]
      const selectedEntities = shuffle([youngerEntity, elderEntity])
      const cards = entities.reduce(createCards, {})
      const places = selectedEntities.reduce(createPlaces, {})
      return {
        ...initialState,
        actualEntities: selectedEntities,
        availableCardsId: map(cards, 'id'),
        cards,
        entitiesById: keyBy(entities, 'id'),
        places,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      return {
        ...state,
        availableCardsId: findAvailableCards(cards),
        cards,
        places,
      }
    }

    case MARK: {
      const { cards, places } = state
      const checkedCards = reduce(places, checkCardState(cards), {})
      const completed = Object.keys(checkedCards).length > 1 && every(checkedCards, 'correct')
      return {
        ...state,
        cards: {
          ...cards,
          ...checkedCards,
        },
        completed,
      }
    }

    case RETRY: {
      const { cards } = state
      return {
        ...state,
        cards: transformValues(cards, card => ({ ...card, correct: null })),
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  initialState,
  init,
  mark,
  moveCard,
  retry,
}

export default reducer
