import React from 'react'
import { bool, number } from 'prop-types'
import { t } from 'i18next'
import Div from 'src/components/Div'
import TextFit from 'src/components/TextFit'
import Donut from 'src/components/Donut'
import calculatePercentage from 'src/lib/calculatePercentage'
import Grade from 'src/components/Grade'
import { percentToGrade } from 'src/lib/rateToColor'

const propTypes = {
  actual: number,
  grade: number,
  showPercent: bool,
  total: number,
  value: number,
}

const defaultProps = {
  actual: 0,
  grade: 0,
  total: 0,
  value: 0,
  showPercent: true,
}

const getPercentage = (value, total, actual) => {
  if (value) return calculatePercentage(value)
  if (!actual) return 0
  return calculatePercentage(actual / total)
}

export const DonutAndGrade = ({
  actual,
  grade,
  total,
  value,
  showPercent,
}) => {
  const percentage = getPercentage(value, total, actual)
  const theValue = value || percentage / 100
  const theGrade = grade || percentToGrade(theValue)
  return (
    <Div auto flex column justify-start p1>
      <Div flex justify-center auto>
        <Div flex mx={1} className="center">
          <Donut value={theValue} size={2} fixed={4} />
        </Div>
      </Div>
      <Div flex flex-col-3>
        <TextFit focusOnWidth>
          <Grade
            grade={theGrade}
            percentage={theValue}
            tips={[`${t('grade.system.grade')} / ${t('grade.system.percent')}`]}
            flex
            auto
            column
            style={{ alignItems: 'center' }}
            showPercent={showPercent}
          />
        </TextFit>
      </Div>
    </Div>
  )
}

DonutAndGrade.propTypes = propTypes
DonutAndGrade.defaultProps = defaultProps

export default DonutAndGrade
