import React from 'react'
import DomainContext from 'src/contexts/DomainContext'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'
import MenuSection from 'src/pages/TheLayout/Menu/MenuSection'
import {
  REPORTS_SECTION,
  SCHOOL_SECTION,
} from 'src/pages/TheLayout/Menu/locale/constants'
import { schoolSection } from 'src/lib/menu'
import { staffReportsSection } from 'src/lib/menu/reportsSection'


const StaffMenu = () => (
  <DomainContext.Provider value={SCHOOL_SCOPE}>
    <div className="qa-menu-school-section">
      <MenuSection
        sectionKey={SCHOOL_SECTION}
        menuItems={schoolSection}
      />
    </div>
    <div className="qa-menu-school-reports-section">
      <MenuSection
        sectionKey={REPORTS_SECTION}
        menuItems={staffReportsSection}
      />
    </div>
  </DomainContext.Provider>
)

export default StaffMenu
