import React, { useState, useLayoutEffect } from 'react'
import Game from 'src/components/Game'
import KeyPress from 'src/components/KeyPress'
import phonicShape from 'src/shapes/phonic'
import { bool, arrayOf, shape, func } from 'prop-types'
import entityShape from 'src/shapes/entity'
import FillInMissingLetters from 'src/modules/FillInMissingLetters/FillInMissingLetters'

const propTypes = {
  completed: bool,
  entities: arrayOf(shape(entityShape)).isRequired,
  init: func.isRequired,
  phonicsAlphabet: arrayOf(shape(phonicShape).isRequired).isRequired,
}

const defaultProps = {
  completed: undefined,
}

const FillInMissingLettersContainer = ({
  actualEntities,
  phonicsAlphabet,
  init,
  completed,
  entities,
  nextRound,
  mark,
  backspace,
  ...rest
}) => {
  const [initialized, setInitialized] = useState(false)
  useLayoutEffect(() => {
    if (!initialized && entities) {
      init({ entities, phonicsAlphabet })
      setInitialized(true)
    }
  })

  const retry = () => init({ entities, phonicsAlphabet })

  return initialized && (
    <Game {...{ actualEntities, completed, init: nextRound, mark, retry }}>
      <KeyPress keys={{ Backspace: backspace }} />
      <FillInMissingLetters {...{ completed, ...rest }} />
    </Game>
  )
}

FillInMissingLettersContainer.demoData = {
  entities: ['mum', 'dad'],
}
FillInMissingLettersContainer.propTypes = propTypes
FillInMissingLettersContainer.defaultProps = defaultProps

export default FillInMissingLettersContainer
