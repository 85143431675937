import { ATTENDANCE, SCHOOL_ATTENDANCE } from 'src/constants/menu'
import icons from 'src/lib/menu/icons'

const path = '/:domain/reports/attendance'

const scene = {
  icon: icons[ATTENDANCE],
  id: SCHOOL_ATTENDANCE,
  path,
  matchPaths: [path, '/:domain/reports/:academicYearId/:academicTermId/attendance'],
}

export {
  path,
  scene,
}
