import React from 'react'
import { number, shape, bool, func } from 'prop-types'
import ObjectTarget from 'src/components/ObjectTarget'
import moleTargetHide from 'src/components/ObjectTarget/images/targets/MoleTargetHide.svg'
import moleTargetShow from 'src/components/ObjectTarget/images/targets/MoleTargetShow.svg'
import pop from 'src/components/ObjectTarget/images/effects/Pop.svg'
import miss from 'src/components/ObjectTarget/images/effects/Miss.svg'

const propTypes = {
  animation: bool,
  cursive: bool,
  entity: shape(),
  height: number,
  mixFont: bool,
  onClick: func,
  selected: bool,
  text: bool,
  width: number,
}

const defaultProps = {
  cursive: null,
  mixFont: null,
  text: null,
}

const entityImageStyle = {
  paddingBottom: '5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  paddingTop: '1rem',
}


const MoleTarget = props => (
  <ObjectTarget
    targetImage={moleTargetShow}
    effectImage={pop}
    secondEffectImage={miss}
    disappearTargetImage={moleTargetHide}
    entityImageStyle={entityImageStyle}
    {...props}
  />
)

MoleTarget.defaultProps = defaultProps
MoleTarget.propTypes = propTypes

export default MoleTarget
