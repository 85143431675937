import size from 'lodash/size'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import last from 'lodash/last'
import partition from 'lodash/partition'
import shuffle from 'lodash/shuffle'
import take from 'lodash/take'
import reduce from 'lodash/reduce'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'ListeningPhonemes/INIT'
const CLICK = 'ListeningPhonemes/CLICK'
const MARK = 'ListeningPhonemes/MARK'
const RETRY = 'ListeningPhonemes/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
}

const createCollections = (a, entity) => {
  const slugArray = entity.slug.split('-')
  const slug = slugArray.length > 1 && slugArray[0]
  if (['collection', 'phonics', 'letter'].includes(slug)) {
    return {
      ...a,
      types: a.types.concat(entity),
    }
  }
  return {
    ...a,
    collections: a.collections.concat({ ...entity, type: last(a.types).id }),
  }
}

const createCards = (a, entity) => ({
  ...a,
  [entity.id]: {
    id: entity.id,
    label: size(a) + 1,
    selected: null,
  },
})

const clickCard = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    selected: id === card.id ? true : null,
  },
})

const checkCards = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: card.selected ? id === card.id : null,
  },
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities, maxChoices } = payload
      const {
        collections,
        types,
      } = entities.reduce(createCollections, { collections: [], types: [] })
      const answer = uniqShuffle(collections)[0]
      const { id: answerId, type: answerType } = answer
      const [[correctType], incorrectTypes] = partition(types, ({ id }) => id === answerType)
      const choices = shuffle([correctType, ...take(shuffle(incorrectTypes), maxChoices - 1)])
      const cards = choices.reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [answer],
        answerId,
        answerType,
        cards,
        entitiesById: keyBy(entities, 'id'),
      }
    }

    case (CLICK): {
      const { id } = payload
      const cards = reduce(state.cards, clickCard(id), {})
      return {
        ...state,
        cards,
      }
    }

    case (MARK): {
      const cards = reduce(state.cards, checkCards(state.answerType), {})
      const selectedCard = find(cards, { selected: true })
      const completed = selectedCard ? selectedCard.correct : false
      return {
        ...state,
        cards,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, maxChoices }) => ({
  type: INIT,
  payload: { entities, maxChoices },
})

const onClick = ({ id }) => ({
  payload: { id },
  type: CLICK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  onClick,
  mark,
  retry,
}

export default reducer
