import React from 'react'
import { useParams } from 'react-router'
import { shape } from 'prop-types'
import { withApollo } from '@apollo/react-hoc'
import gqlLoader from 'src/lib/gqlLoader'
import { compose } from 'src/lib/redux'
import Years from 'src/pages/Years/Years'
import yearsSearchQuery from 'src/pages/Years/yearsSearch.gql'

const propTypes = {
  client: shape().isRequired,
}

const YearsContainer = (props) => {
  const handleFetchData = ({ options }) => {
    const { client } = props
    return client.query({ query: yearsSearchQuery, ...options })
  }

  const { client, ...rest } = props
  const params = useParams()
  return <Years {...rest} {...params} onFetchData={handleFetchData} />
}

YearsContainer.propTypes = propTypes

export default compose(
  gqlLoader(yearsSearchQuery),
)(withApollo(YearsContainer))
