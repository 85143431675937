import React from 'react'
import { bool, number, shape, object } from 'prop-types'
import userShape from 'src/shapes/user'
import Div from 'src/components/Div'
import PersonImg from 'src/components/PersonImg'
import avatarImage from 'src/components/UserImage/avatar.svg'

const propTypes = {
  avatar: bool,
  height: number,
  style: object,
  user: shape(userShape),
  width: number,
}

const defaultProps = {
  avatar: false,
  height: 15,
  style: {},
  user: {},
  width: 11.25,
}

const validationSrc = (src) => {
  if (src === null) return undefined
  return src
}

const UserImage = ({
  user,
  avatar,
  height,
  width,
  style,
}) => (
  <Div relative rounded overflow-hidden border height={height} width={width}>
    <Div absolute z1 height={height} width={width} style={{ ...style }}>
      <PersonImg rounded src={validationSrc({ ...user }.imageUrl)} />
    </Div>
    {
      avatar && (
        <Div absolute z2 height={height} width={width} style={{ ...style }}>
          <PersonImg rounded src={avatarImage} />
        </Div>
      )
    }
  </Div>
)

UserImage.propTypes = propTypes
UserImage.defaultProps = defaultProps

export default UserImage
