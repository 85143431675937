import { PORTAL_SCOPE } from 'src/constants/routeScopes'
import buildPath from 'src/lib/routes/buildPath'
import pathWithPageParams from 'src/lib/routes/pathWithPageParams'

const tabPath = (params) => {
  const { tab = 'view', subjectId, strandSlug, standardSlug } = params
  switch (tab) {
    case 'progress': return buildPath([tab, subjectId, strandSlug, standardSlug])
    case 'skills': return buildPath([tab, subjectId])
    case 'attendance': return buildPath([tab, subjectId])
    default: return buildPath([tab])
  }
}

const portalPath = (params = {}) => {
  const { parentId, studentId, userId, tab } = params
  if (!tab) {
    return buildPath(['/portal', parentId || userId])
  }

  return buildPath([
    '/portal',
    parentId || userId,
    tab,
    userId || studentId,
  ])
}

const userPath = pathWithPageParams((pageParams) => {
  const { tab, userTab, componentTab } = pageParams
  const params = { ...pageParams, tab: (componentTab || userTab || tab) }
  const { domain, userId } = params
  return domain === PORTAL_SCOPE
    ? portalPath(params)
    : buildPath([`/${domain}/user`, userId, tabPath(params)])
})

export default userPath
