import React from 'react'
import { bool, number } from 'prop-types'

const propTypes = {
  noAnimation: bool,
  size: number,
}

const defaultProps = {
  noAnimation: false,
  size: 5,
}

const Cross = ({ noAnimation, size }) => {
  const check = !noAnimation ? 'check' : ''
  return (
    <svg version="1.1" x="0" y="0" width={`${size}rem`} viewBox="0 0 100 100">
      <polyline
        className={check}
        fill="none"
        stroke="#660000"
        strokeWidth="10"
        points="15,15 85,85"
        strokeLinecap="round"
      />
      <polyline
        className={check}
        fill="none"
        stroke="#660000"
        strokeWidth="10"
        points="85,15 15,85"
        strokeLinecap="round"
      />
    </svg>
  )
}

Cross.propTypes = propTypes
Cross.defaultProps = defaultProps

export default Cross
