import keyBy from 'lodash/keyBy'
import find from 'lodash/find'
import size from 'lodash/size'
import chunk from 'lodash/chunk'
import shuffle from 'lodash/shuffle'
import uuid from 'uuid'

const INIT = 'MultipleChoiceMultipleParagraphs/INIT'
const MARK = 'MultipleChoiceMultipleParagraphs/MARK'
const RETRY = 'MultipleChoiceMultipleParagraphs/RETRY'
const SELECT = 'MultipleChoiceMultipleParagraphs/SELECT'

const initialState = {
  actualEntities: [],
  cards: {},
  entitiesById: {},
  paragraphEntities: [],
}

const checkCardCorrected = ({ cards, answerId }) => (a, cardId) => {
  if (cards[cardId].selected) {
    return {
      ...a,
      [cardId]: {
        ...cards[cardId],
        correct: cardId === answerId,
      },
    }
  }
  return {
    ...a,
    [cardId]: {
      ...cards[cardId],
    },
  }
}

const findSelectedCard = ({ cards, target }) => (a, cardId) => (
  {
    ...a,
    [cardId]: {
      ...cards[cardId],
      correct: null,
      selected: cardId === target ? true : null,
    },
  }
)

const splitParagraphAndEntities = (a, entity) => {
  const prefix = entity.slug.split('-')[0]
  if (prefix === 'paragraph') {
    return a.concat(entity.id)
  }
  return a
}

const createCards = (a, entity) => (
  {
    ...a,
    [entity.id]: {
      correct: null,
      id: entity.id,
      key: uuid(),
      label: (size(a) + 1),
      selected: null,
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities: theEntites } = payload
      const entitiesById = keyBy(theEntites, 'id')
      const paragraphEntities = theEntites.reduce(splitParagraphAndEntities, [])
      const entities = theEntites.splice(paragraphEntities.length)
      const questionSet = shuffle(chunk(entities, 4))[0]
      const [question, answer] = questionSet
      const { id: questionId } = question
      const { id: answerId } = answer
      const cards = shuffle(questionSet.splice(1)).reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [question, answer],
        answerId,
        cards,
        entitiesById,
        paragraphEntities,
        questionId,
      }
    }

    case SELECT: {
      const { cardId } = payload
      const cards = Object.keys(state.cards).reduce(findSelectedCard({
        cards: state.cards, target: cardId,
      }), {})
      return {
        ...state,
        cards,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    case MARK: {
      const { answerId } = state
      const selectedCard = find(state.cards, { selected: true })
      const cards = Object.keys(state.cards).reduce(checkCardCorrected({ answerId, cards: state.cards }), {})
      return {
        ...state,
        cards,
        completed: selectedCard ? answerId === selectedCard.id : null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, variants }) => ({
  payload: {
    entities,
    variants,
  },
  type: INIT,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

const selectCard = ({ cardId }) => ({
  payload: {
    cardId,
  },
  type: SELECT,
})

export {
  init,
  retry,
  mark,
  selectCard,
}

export default reducer
