import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import AudioMeter from 'src/components/AudioMeter'

export const path = '/test'

const TestRoute = () => (
  <Switch>
    <Route
      exact
      path="/test"
      component={AudioMeter}
    />
  </Switch>
)

export default TestRoute
