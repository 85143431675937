import every from 'lodash/every'
import find from 'lodash/find'
import map from 'lodash/map'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'

import moveCards from 'src/reducers/lib/moveCards'
import { storeHistory } from 'src/modules/UnscrambleWords/lib'

const INIT = 'UnscrambleWords/INIT'
const BACKSPACE = 'UnscrambleWords/DELETE'
const LETTER = 'UnscrambleWords/LETTER'
const MOVE = 'UnscrambleWords/MOVE'
const MARK = 'UnscrambleWords/MARK'
const RETRY = 'UnscrambleWords/RETRY'

const initialState = {
  actualEntities: [],
  history: [],
  now: {
    cards: {},
    completed: null,
    entity: null,
    places: {},
    position: 0,
  },
}

const createCard = (a, letter, index) => {
  const theIndex = `c-${letter}-${index}`
  return {
    ...a,
    [theIndex]: {
      correct: undefined,
      id: theIndex,
      titleEn: letter,
    },
  }
}

const createPlace = (a, letter, index) => {
  const { places, cards } = a
  const theIndex = `s-${letter}-${index}`
  const card = cards[Object.keys(cards)[index]]
  const newPlace = {
    [theIndex]: {
      cardId: card.id,
      id: theIndex,
    },
  }
  const newCard = {
    [card.id]: {
      ...card,
      placeId: theIndex,
    },
  }

  return {
    ...a,
    cards: {
      ...cards,
      ...newCard,
    },
    places: {
      ...places,
      ...newPlace,
    },
  }
}

const clearCardSelectedStatus = (a, card) => (
  {
    ...a,
    [card.id]: {
      ...card,
      selected: null,
    },
  }
)

const handleType = ({ targetId, sourceId, state }) => {
  const { cards: oldCard, places, position } = moveCards(state.now, sourceId, targetId)
  const { now, history } = storeHistory(state, { cards: oldCard, places })

  const cards = (position === 0) && every(oldCard, 'selected')
    ? reduce(oldCard, clearCardSelectedStatus, {})
    : oldCard

  return {
    ...state,
    history,
    now: {
      ...now,
      cards: {
        ...cards,
        [sourceId]: {
          ...cards[sourceId],
          selected: true,
        },
      },
      position: (position + 1) === size(cards) ? 0 : position + 1,
    },
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { now } = initialState
      const entity = uniqShuffle(payload.entities)[0]
      const splitWord = shuffle(entity.titleEn.split(''))
      const shuffledCards = splitWord.reduce(createCard, {})
      const { cards, places } = splitWord.reduce(createPlace, { cards: shuffledCards, places: {} })
      const newState = {
        cards,
        entity,
        places,
      }

      return {
        ...initialState,
        actualEntities: [entity],
        now: {
          ...now,
          ...newState,
        },
      }
    }
    case LETTER: {
      const { key } = payload
      const { cards, position, places } = state.now
      const available = pick(cards, map(places, 'cardId').slice(position))
      const card = key ? find(available, c => c.titleEn.toUpperCase() === key.toUpperCase()) : null

      if (!card) {
        return state
      }

      const sourceId = card.id
      const targetId = places[Object.keys(places)[position]].id
      return handleType({ sourceId, state, targetId })
    }

    case BACKSPACE: {
      const { history: oldHistory } = state
      if (!oldHistory.length) { return state }

      const now = oldHistory.slice(-1)[0]
      const history = oldHistory.slice(0, -1)

      return {
        ...state,
        history,
        now: {
          ...state.now,
          ...now,
        },
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state.now, sourceId, targetId)
      const { now, history } = storeHistory(state, { cards, places })

      return {
        ...state,
        history,
        now,
      }
    }
    case MARK: {
      const { now, now: { entity, cards, places } } = state
      const checkCardState = (a, placeId, i) => {
        const place = places[placeId]
        const card = cards[place.cardId]
        return {
          ...a,
          [place.cardId]: {
            ...card,
            correct: card.titleEn === entity.titleEn[i],
          },
        }
      }
      const checkedCards = Object.keys(places).reduce(checkCardState, {})
      const completed = every(checkedCards, ['correct', true])
      return {
        ...state,
        now: {
          ...now,
          cards: {
            ...cards,
            ...checkedCards,
          },
          completed,
        },
      }
    }
    case RETRY: {
      const { now } = state
      const cards = reduce(
        now.cards,
        (a, card, id) => ({ ...a, [id]: { ...card, correct: null } }),
        {},
      )
      return {
        ...state,
        now: {
          ...now,
          cards,
          completed: null,
        },
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const backspace = () => ({
  type: BACKSPACE,
})

const letter = key => ({
  payload: {
    key,
  },
  type: LETTER,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  initialState,
  mark,
  moveCard,
  letter,
  backspace,
  retry,
}

export default reducer
