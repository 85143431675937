import React from 'react'
import { bool, string } from 'prop-types'
import {
  PaperTarget,
  TvTarget,
  RiverTarget,
  CircleTarget,
  MoleTarget,
} from 'src/components/ObjectTarget'

const propTypes = {
  cursive: bool,
  mixFont: bool,
  text: bool,
  theme: string.isRequired,
}

const defineDelay = (objectId, objectList, multiply) => {
  if (objectId && objectList) {
    return Object.keys(objectList).indexOf(objectId) * multiply
  }
  return null
}

const TARGETS = {
  backyard: CircleTarget,
  fence: PaperTarget,
  mole: MoleTarget,
  river: RiverTarget,
  space: TvTarget,
}

const ObjectTargetSelector = ({
  cursive,
  mixFont,
  objectId,
  objectList,
  text,
  theme,
  ...rest
}) => {
  const TARGETPARAMETERS = {
    backyard: {
      cursive,
      mixFont,
      text,
      ...rest,
    },
    fence: {
      cursive,
      mixFont,
      rotate: true,
      text,
      ...rest,
    },
    mole: {
      cursive,
      disappear: true,
      mixFont,
      text,
      ...rest,
    },
    river: {
      cursive,
      delay: defineDelay(objectId, objectList, 2),
      duration: 8,
      justify: (cursive || text || mixFont) && 'start',
      mixFont,
      text,
      ...rest,
    },
    space: {
      cursive,
      mixFont,
      text,
      ...rest,
    },
  }
  const Target = TARGETS[theme]

  return (
    <Target {...TARGETPARAMETERS[theme]} />
  )
}

ObjectTargetSelector.propTypes = propTypes
export default ObjectTargetSelector
