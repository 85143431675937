import React from 'react'

import Access from 'src/components/Access'
import Div from 'src/components/Div'
import Menu from 'src/pages/TheLayout/Menu'
import UserPassport from 'src/components/UserPassport'
import UserBackgroundStyle from 'src/pages/StudentLayout/UserBackgroundStyle'
import DomainContext from 'src/contexts/DomainContext'
import { STUDENT_SCOPE } from 'src/constants/routeScopes'
import WithUser, { WithUserId } from 'src/components/SetUser/WithUser'

const StudentLayout = ({ children }) => (
  <DomainContext.Provider value={STUDENT_SCOPE}>
    <Access redirect>
      <Div auto mt1 pb4>
        <WithUser>
          <UserBackgroundStyle />
        </WithUser>
        <div className="container">
          <Menu />
          <Div flex column mbn3 z1 relative>
            <Div mb1>
              <WithUserId><UserPassport /></WithUserId>
            </Div>
            <Div><WithUserId>{children}</WithUserId></Div>
          </Div>
        </div>
      </Div>
    </Access>
  </DomainContext.Provider>
)

export default StudentLayout
