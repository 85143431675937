import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import PreviewDictionaryPage from 'src/pages/preview/Dictionary/PreviewDictionaryPage'
import ModuleScreenShots from 'src/components/ModuleScreenShots'
import UserCards from 'src/pages/preview/UserCards'
import PreviewModulePage from 'src/pages/preview/Modules/PreviewModulePage'
import PreviewCaptureModulePage from 'src/pages/preview/Modules/PreviewCaptureModulePage'
import RedirectToDefaultYear from 'src/pages/TeacherLayout/AssessmentPage/RedirectToDefaultYear'
import { previewStudentsPath } from 'src/lib/routes'

export const path = '/preview'

const PreviewRoute = () => (
  <Switch>
    <Route
      exact
      path="/preview"
      render={() => <Redirect from="/preview" to="/preview/modules" />}
    />

    <Route exact path="/preview/captures" component={ModuleScreenShots} />
    <Route path="/preview/captures/:moduleId" component={PreviewCaptureModulePage} />

    <Route
      exact
      path="/preview/dictionary"
      render={() => <Redirect from="/preview/dictionary" to="/preview" />}
    />
    <Route path="/preview/dictionary/:entitySlug" component={PreviewDictionaryPage} />

    <Route exact path="/preview/students/:yearId" component={UserCards} />
    <Route
      path="/preview/students"
      component={() => (
        <RedirectToDefaultYear
          path={({ params, yearId }) => previewStudentsPath({ ...params, yearId })}
        />
      )}
    />

    <Route exact path="/preview/modules" component={ModuleScreenShots} />
    <Route path="/preview/modules/:moduleId" component={PreviewModulePage} />
  </Switch>
)

export default PreviewRoute
