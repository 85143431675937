import React from 'react'
import { useParams } from 'react-router'
import YearEditContainer from 'src/pages/Year/Edit/Container'

const YearEdit = () => {
  const { yearId } = useParams()
  return <YearEditContainer yearId={yearId} />
}

export default YearEdit
