import React from 'react'
import { Link } from 'react-router-dom'
import { bool, string } from 'prop-types'
import classnames from 'classnames'
import Div from 'src/components/Div'
import Tooltip from 'src/components/Tooltip'
import pathShape from 'src/shapes/path'

const propTypes = {
  identifier: string.isRequired,
  selected: bool,
  title: string.isRequired,
  to: pathShape,
}

const defaultProps = {
  selected: false,
}

const LessonLink = ({
  identifier,
  selected,
  title,
  to,
}) => (
  <Link className={`qa-lesson-selector-${identifier}`} to={to}>
    <Tooltip
      tips={[title]}
      vertical
    >
      <Div
        bold
        border
        button-hover
        className={classnames(selected ? 'bg-blue white' : 'blue', 'qa-button')}
        flex
        justify-center
        p1
        rounded
        style={{
          marginLeft: '0.25rem',
          padding: '0.25rem',
        }}
        width={5.5}
      >
        {identifier}
      </Div>
    </Tooltip>
  </Link>
)

LessonLink.propTypes = propTypes
LessonLink.defaultProps = defaultProps

export default LessonLink
