import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import Attendance from 'src/pages/AttendancePage/AttendanceWeekly/YearAttendance'

const propTypes = {
  days: arrayOf(string).isRequired,
  year: shape({
    id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
}

const YearTitleRow = ({
  days,
  year: {
    id,
    title,
  },
}) => (
  <tr>
    <td className="bold">
      <div className="pl1">
        {title}
      </div>
    </td>
    {
      days.map(day => (
        <Attendance
          key={`${id}-${day}`}
          day={day}
          yearId={id}
        />
      ))
    }
  </tr>
)

YearTitleRow.propTypes = propTypes

export default YearTitleRow
