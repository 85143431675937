import React from 'react'
import SelectTypeContainer from 'src/modules/SelectType'

const CorrectOrIncorrectContainer = props => (
  <SelectTypeContainer
    {...props}
  />
)

CorrectOrIncorrectContainer.demoData = {
  entities: [
    'collection-correct',
    'phrase-she-is-washing-the-dishes',
    'phrase-take-these-pills',
    'collection-incorrect',
    'phrase-he-is-watering-the-plants-in-the-garden',
    'phrase-he-is-my-elder-brother',
  ],
}

export default CorrectOrIncorrectContainer
