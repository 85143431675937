import React, { Component, createRef } from 'react'
import { any, bool, func, shape, number } from 'prop-types'
import classnames from 'classnames'
import { DragSource } from 'src/lib/dragAndDrop'
import { isMobile } from 'src/lib/window'
import { CARD } from 'src/constants/itemTypes'

const propTypes = {
  bold: bool,
  children: any.isRequired,
  connectDragSource: func.isRequired,
  currentOffset: shape({ x: number, y: number }),
  inline: bool,
  isDragging: bool.isRequired,
}

const defaultProps = {
  bold: false,
  currentOffset: ({ x: 0, y: 0 }),
  inline: false,
}

const draggableStyles = ({ x, y }) => {
  if (isMobile()) {
    return {
      left: x,
      pointerEvents: 'none',
      position: 'fixed',
      top: y,
    }
  }
  return { opacity: 0.5 }
}

const cardSource = {
  beginDrag({ title, id, ...props }) {
    return { id, title, ...props }
  },
}

function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

class DraggableCard extends Component {
  constructor(props) {
    super(props)
    this.ref = createRef()
  }

  render() {
    const {
      children,
      currentOffset,
      connectDragSource,
      inline,
      isDragging,
      bold,
    } = this.props

    const scaleOffset = ({ x, y }, e) => {
      const b = e.getBoundingClientRect()
      const scaleOf = () => {
        const sX = b.width / e.offsetWidth
        const sY = b.height / e.offsetHeight
        return [sX, sY]
      }
      const [scaleX, scaleY] = scaleOf()
      const [initX, initY] = [0, b.top / scaleY]

      return {
        x: x / scaleX - initX,
        y: y / scaleY - initY,
      }
    }

    const scaledOffset = isMobile() && this.ref.current && currentOffset
      ? scaleOffset(currentOffset, this.ref.current.parentNode.parentNode)
      : currentOffset

    const style = isDragging && currentOffset ? draggableStyles(scaledOffset) : {}
    return connectDragSource(
      <div
        className={classnames(
          'animated bounced delayed flex-1 relative',
          { bold, flex: !inline, inline },
        )}
        style={{ ...style, maxHeight: '100%' }}
      >
        <div ref={this.ref} style={{ position: 'absolute', top: 0, left: 0 }} />
        {children}
      </div>,
    )
  }
}

DraggableCard.propTypes = propTypes
DraggableCard.defaultProps = defaultProps

export default DragSource(CARD, cardSource, collect)(DraggableCard)
