import React from 'react'
import { bool, number, string } from 'prop-types'
import logo from 'src/images/learn21-transparent.svg'
import logoColor from 'src/images/learn21.svg'

const propTypes = {
  className: string,
  color: bool,
  height: number,
  mb: number,
}

const defaultProps = {
  className: '',
  color: false,
  height: 4,
  mb: 2,
}

const Logo = ({ className, color, height, mb }) => (
  <div className={`mb${mb} center ${className}`}>
    <img style={{ height: `${height}rem` }} alt="Learn21" src={color ? logoColor : logo} />
  </div>
)


Logo.propTypes = propTypes
Logo.defaultProps = defaultProps

export default Logo
