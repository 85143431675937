import { string, shape } from 'prop-types'
import image from 'src/shapes/image'

const instructionShape = {
  image: shape(image),
  meaning: shape({
    titleEn: string,
    titleTh: string,
  }),
  slug: string.isRequired,
  sound: shape({
    srcEn: string,
    srcTh: string,
  }),
  title: string,
  titleEn: string,
  titleTh: string,
}

export default instructionShape
