import React from 'react'
import { t } from 'i18next'
import { arrayOf, shape } from 'prop-types'

import HomeworkPageTableRow from 'src/pages/HomeworkPage/TableRow'
import sectionShape from 'src/shapes/section'
import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'
import subjectShape from 'src/shapes/subject'
import Widget from 'src/components/Widget'
import yearShape from 'src/shapes/year'
import { HOMEWORK } from 'src/constants/menu'

const propTypes = {
  sections: arrayOf(shape(sectionShape)),
  subject: shape(subjectShape),
  years: arrayOf(shape(yearShape)),
}

const defaultProps = {
  sections: [],
  subject: undefined,
  years: [],
}

const HomeworkReport = ({ sections, years, subject }) => (
  <Widget token={HOMEWORK} title={`${subject ? subject.title : ''} ${t(`${HOMEWORK}.title`)}`}>
    <StickyTable>
      <StickyTableHeader>
        <th>{t('user.section')} / {t('user.year')}</th>
        <th>{t('homework.students')}</th>
        <th>{t('homework.grade')}</th>
        <th>{t('homework.completedRate')}</th>
        <th>{t('homework.assignedRate')}</th>
      </StickyTableHeader>
      <tbody>
        {
          sections.map(section => (
            [
              <HomeworkPageTableRow key={section.id} resource={section} isSection />,
              ...years
                .filter(({ sectionId }) => sectionId === section.id)
                .map(year => (
                  <HomeworkPageTableRow key={year.id} resource={year} />
                )),
            ]
          ))
        }
      </tbody>
    </StickyTable>
  </Widget>
)

HomeworkReport.propTypes = propTypes
HomeworkReport.defaultProps = defaultProps

export default HomeworkReport
