import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { select } from 'src/modules/OddOneOut/reducer'
import OddOneOutScene from 'src/modules/OddOneOut/OddOneOutScene'

export default connect(
  ({ oddOneOut: { entitiesById, word, answers, selected } }) => ({
    word: entitiesById[word],
    answers: answers.map(id => entitiesById[id]),
    selected,
  }),
  dispatch => bindActionCreators({ onSelectAnswer: select }, dispatch),
)(props => (props.word ? <OddOneOutScene {...props} /> : null))
