import React from 'react'
import { string, func } from 'prop-types'
import { t } from 'i18next'
import { Formik, Form } from 'formik'
import { location, localStorage } from 'src/lib/window'
import { loginPath } from 'src/lib/routes'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import Logo from 'src/components/Logo'
import Input from 'src/components/forms/Input'

const propTypes = {
  onSubmit: func.isRequired,
  token: string.isRequired,
}

const cancel = () => {
  localStorage.setItem('token', null)
  location.replace(loginPath())
}

const validate = (values) => {
  const errors = {}

  if (!values.password) {
    errors.password = 'Required'
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required'
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password and password confirmation must match'
  }

  return errors
}

const ResetPasswordForm = ({ onSubmit, token }) => {
  const initialValues = {
    confirmPassword: '',
    password: '',
    token,
  }

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Div flex justify="center">
            <Div mb={2}>
              <Div
                bg-blue
                white
                rounded
                px3
                py3
                shadow
                my4
                style={{ width: '22rem' }}
              >
                <Logo />

                <div className="mb2 qa-user-edit-password">
                  <Input
                    name="password"
                    type="password"
                    placeholder={t('user.password')}
                    className="col-12"
                    errors={errors.password}
                  />
                </div>
                <div className="mb2 qa-user-edit-confirm-password">
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder={t('user.confirmPassword')}
                    className="col-12"
                    errors={errors.confirmPassword}
                  />
                </div>

                <Div flex gutter={1} align="center" justify-between>
                  <Div px={1}>
                    <button
                      className="btn rounded bg-white blue white shadow"
                      disabled={isSubmitting}
                    >
                      {t('shared.save')}
                      <Icon icon="arrow-right" after />
                    </button>
                  </Div>
                  <Div px={1}>
                    <Div
                      className="btn rounded bg-white blue white shadow qa-cancel-button"
                      onClick={cancel}
                    >
                      {t('shared.cancel')}
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  )
}

ResetPasswordForm.propTypes = propTypes
export default ResetPasswordForm
