import React from 'react'
import { arrayOf, string } from 'prop-types'
import {
  formatDayMedium,
  formatDayShortNumeric,
} from 'src/lib/dates'
import { StickyTableHeader } from 'src/components/StickyTable'

const propTypes = {
  days: arrayOf(string).isRequired,
}

const TableHead = ({ days, ...props }) => (
  <StickyTableHeader {...props}>
    <th className="col-7" />
    {
      days.map(day => (
        <th key={day} className="col-1">
          {`${formatDayShortNumeric(day)} ${formatDayMedium(day)}`}
        </th>
      ))
    }
  </StickyTableHeader>
)

TableHead.propTypes = propTypes

export default TableHead
