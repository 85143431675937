import React, { useContext } from 'react'
import { t } from 'i18next'
import Icon from 'src/components/Icon'
import { PROGRESS } from 'src/constants/menu'
import Tabs from 'src/components/Tabs'
import NavTab from 'src/components/NavTab'
import DomainContext from 'src/contexts/DomainContext'
import { PORTAL_SCOPE } from 'src/constants/routeScopes'


const Nav = ({ path }) => {
  const outline = [PORTAL_SCOPE].includes(useContext(DomainContext))
  return (
    <Tabs className="qa-student-progress-nav">
      <NavTab
        to={path({ userTab: 'progress' })}
        match={[path({ userTab: 'progress' }), path({ userTab: '' })]}
        outline={outline}
        exact
      >
        <Icon icon="chart-bar" className="mr1" />
        {t(`${PROGRESS}.title`)}
      </NavTab>
      <NavTab to={path({ userTab: 'skills' })} outline={outline}>
        <Icon icon="chart-pie" className="mr1" />
        {t('skills.title')}
      </NavTab>
      <NavTab to={path({ userTab: 'attendance' })} outline={outline}>
        <Icon icon="battery-full" className="mr1" />
        {t('attendance.report.attendance')}
      </NavTab>
    </Tabs>
  )
}

export default Nav
