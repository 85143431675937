import React, { Component } from 'react'
import {
  array,
  func,
  object,
  string,
} from 'prop-types'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Game from 'src/components/Game'
import {
  updateCardIterationIcon,
  clearCardIterationIcon,
} from 'src/components/IterationIcon/reducer'
import gamePropTypes from 'src/shapes/Game'
import * as allActions from 'src/modules/MatchCards/reducer'
import MatchCards from 'src/modules/MatchCards/MatchCards'

const propTypes = {
  ...gamePropTypes,
  cards: object.isRequired,
  click: func.isRequired,
  type: string,
  types: array.isRequired,
}

const defaultProps = {
  completed: null,
  entities: [],
  initWith: '',
  type: '',
  types: ['image', 'english', 'thai'],
  variants: 0,
}

class MatchCardsContainer extends Component {
  componentDidMount() {
    this.init()
    const { cardIterations, updateCardIterationIcon } = this.props
    updateCardIterationIcon({ cardIterations })
  }

  componentDidUpdate(prevProps) {
    if (isEqual(this.props, prevProps)) return
    const {
      cardIterations,
      updateCardIterationIcon,
    } = this.props
    if (cardIterations !== prevProps.cardIterations) updateCardIterationIcon({ cardIterations })
  }

  componentWillUnmount() {
    const { clearCardIterationIcon } = this.props
    clearCardIterationIcon()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const { mark, retry, completed, lastCardClickedId, cards,
      click, type, types, actualEntities } = this.props
    if (!cards) return null
    return (
      <Game
        {...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <MatchCards
          type={type}
          types={types}
          cards={cards}
          lastCardClickedId={lastCardClickedId}
          onClick={id => click(id)}
        />
      </Game>
    )
  }
}

MatchCardsContainer.propTypes = propTypes
MatchCardsContainer.defaultProps = defaultProps
MatchCardsContainer.demoData = {
  entities: ['family', 'father', 'mother', 'together'],
  types: ['image', 'english', 'thai'],
}

const select = state => state.matchCards
const actions = dispatch => bindActionCreators({
  ...allActions,
  clearCardIterationIcon,
  updateCardIterationIcon,
}, dispatch)
export default connect(select, actions)(MatchCardsContainer)
