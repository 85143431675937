import _moment from 'moment'
import { extendMoment } from 'moment-range'
import i18next from 'i18next'

const moment = extendMoment(_moment)

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DATE_PICKER_FORMAT = 'YYYY MMM DD'

const isBetween = (target, start, end, inclusivity = '[]') => {
  const date = moment(target)
  return date.isBetween(start, end, null, null, inclusivity)
  || (inclusivity.includes('[') && date.isSame(start))
  || (inclusivity.includes(']') && date.isSame(end))
}

const date = dateString => moment(dateString).locale(i18next.language)

const shiftYear = (momentDate) => {
  if (i18next.language === 'th') {
    momentDate.add(543, 'years')
  }
  return momentDate
}

const localizedYear = (dateString, format = 'YYYY') => shiftYear(date(dateString)).format(format)

const formatWithYear = formatStr => (dateString) => {
  const withBakedYear = formatStr.replace('YYYY', `[${localizedYear(dateString)}]`)
  return date(dateString).format(withBakedYear)
}

const formatDateTimeLong = dateString => formatWithYear(
  moment.localeData(i18next.language).longDateFormat('lll'),
)(dateString) // Jan 1, 2019 1:55 PM
const formatDateLong = formatWithYear('DD MMM YYYY') // 01 Jan 2019
const formatDayLong = dateString => date(dateString).format('dddd') // Monday
const formatDateShort = formatWithYear('DD/MM/YYYY') // 10/01/2019
const formatMonthMedium = dateString => date(dateString).format('MMM') // Jan
const formatMonthMediumNumeric = dateString => date(dateString).format('MM') // 01
const formatDateMedium = dateString => date(dateString).format('D ddd MMM') // 1 Fri Jan
const formatDayMedium = dateString => date(dateString).format('ddd') // Mon
const formatDayShort = dateString => date(dateString).format('dd') // Mo
const formatDayMediumNumeric = dateString => date(dateString).format('DD') // 01
const formatDayShortNumeric = dateString => date(dateString).format('D') // 1
const formatMonthLong = dateString => date(dateString).format('MMMM') // January
const formatYearLong = dateString => localizedYear(dateString) // 2019
const formatDayOfWeek = dateString => date(dateString).format('d') // 1

const formatRange = (startDate, endDate, {
  day = date => formatDayShortNumeric(date),
  dayAndMonth = date => `${formatDayShortNumeric(date)} ${formatMonthMedium(date)}`,
} = {}) => {
  if (!endDate || startDate === endDate) return dayAndMonth(startDate)

  if (moment(startDate).month() === moment(endDate).month()) {
    return `${day(startDate)}-${dayAndMonth(endDate)}`
  }

  return `${dayAndMonth(startDate)}-${dayAndMonth(endDate)}`
}

const dateToday = () => moment().format(DEFAULT_DATE_FORMAT)
const dateSevenDayAgo = () => moment().subtract(7, 'd').format(DEFAULT_DATE_FORMAT)

const add = (dateString, howMany, what) => (
  moment(new Date(dateString)).add(howMany, what).format(DEFAULT_DATE_FORMAT)
)

const subtract = (dateString, howMany, what) => (
  moment(new Date(dateString)).subtract(howMany, what).format(DEFAULT_DATE_FORMAT)
)

const fromMoment = momentObject => momentObject.format(DEFAULT_DATE_FORMAT)

const toMoment = dateString => moment(new Date(dateString))

const startOf = (dateString, what) => moment(dateString).startOf(what).format(DEFAULT_DATE_FORMAT)

const endOf = (dateString, what) => moment(dateString).endOf(what).format(DEFAULT_DATE_FORMAT)

const rangeBy = (startDate, endDate, what) => (
  Array.from(moment.range(date(startDate), date(endDate))
    .by(what))
    .map(i => i.format(DEFAULT_DATE_FORMAT))
)

const isSame = (first, second, ...comparators) => date(first).isSame(date(second), ...comparators)

const isAfter = (before, after) => date(before).isAfter(after)

const diff = (first, second, unit) => date(first).diff(date(second), unit)

const weeksOfMonth = (dateString) => {
  const start = date(dateString).startOf('month').startOf('week')
  const end = date(dateString).endOf('month').endOf('week')
  const weeks = Array.from(moment.range(start, end).by('week')).map(i => i.format(DEFAULT_DATE_FORMAT))

  return weeks
}

const monthEdges = (dateString) => {
  const targetMonth = date(dateString).month()
  const start = date(dateString).startOf('month').startOf('week')
  const end = date(dateString).endOf('month').endOf('week')
  const days = Array.from(moment.range(start, end).by('day'))

  const edges = days.filter(d => d.month() !== targetMonth).map(i => i.format(DEFAULT_DATE_FORMAT))

  return edges
}

const daysOfWeek = (dateString) => {
  const start = date(dateString).startOf('week')
  const end = date(dateString).endOf('week')
  const days = Array.from(moment.range(start, end).by('day')).map(i => i.format(DEFAULT_DATE_FORMAT))

  return days
}

const week = dateString => date(dateString).isoWeek()
const weekYear = dateString => date(dateString).isoWeekYear()

const weekDaysFromRange = (startDate, endDate) => {
  const isoWeekDays = [1, 2, 3, 4, 5]
  return Array.from(moment.range(date(startDate), date(endDate))
    .by('day'))
    .filter(d => isoWeekDays.includes(d.isoWeekday()))
    .map(i => i.format(DEFAULT_DATE_FORMAT))
}

const weekendDaysFromRange = (startDate, endDate) => {
  const isoWeekendDays = [6, 7]
  return Array.from(moment.range(date(startDate), date(endDate))
    .by('day'))
    .filter(d => isoWeekendDays.includes(d.isoWeekday()))
    .map(i => i.format(DEFAULT_DATE_FORMAT))
}

export {
  add,
  dateToday,
  dateSevenDayAgo,
  DEFAULT_DATE_FORMAT,
  DATE_PICKER_FORMAT,
  diff,
  isAfter,
  isBetween,
  isSame,
  endOf,
  fromMoment,
  toMoment,
  formatDateTimeLong,
  formatDateLong,
  formatDateMedium,
  formatDayLong,
  formatDayShortNumeric,
  formatDateShort,
  formatMonthMedium,
  formatMonthMediumNumeric,
  formatMonthLong,
  formatYearLong,
  formatDayMedium,
  formatDayShort,
  formatDayMediumNumeric,
  formatRange,
  localizedYear,
  startOf,
  subtract,
  rangeBy,
  weeksOfMonth,
  monthEdges,
  daysOfWeek,
  formatDayOfWeek,
  week,
  weekDaysFromRange,
  weekendDaysFromRange,
  weekYear,
}
