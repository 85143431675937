import React from 'react'

import { unittestWithChapterPath } from 'src/lib/routes'
import Div from 'src/components/Div'

const ConfirmMessage = ({ history, lessonSlug, unmarkChaptersOrder }) => (
  <Div flex column>
    <h4>Are you sure</h4>
    {
      unmarkChaptersOrder.length > 0 && (
        <Div flex column>
          <h5 key="description">These questions are not answer yet</h5>
          <Div flex wrap>
            {
              unmarkChaptersOrder.map(chapter => (
                <Div key={chapter.id} col-4 nowrap style={{ textAlign: 'center' }}>

                  <Div
                    cursor-pointer
                    button-hover
                    blue
                    onClick={() => history.push(unittestWithChapterPath({
                      chapterId: chapter.id,
                      lessonSlug,
                    }))}
                  >
                    {chapter.title}
                  </Div>
                </Div>
              ))
            }
          </Div>
        </Div>
      )
    }
  </Div>
)

export default ConfirmMessage
