import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import propsMaybeWithoutMeaning from 'src/lib/propsMaybeWithoutMeaning'
import mapValues from 'lodash/mapValues'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as allActions from 'src/modules/MultipleChoiceMultipleParagraphs/reducer'
import MultipleChoiceMultipleParagraphs from 'src/modules/MultipleChoiceMultipleParagraphs/MultipleChoiceMultipleParagraphs'

const propTypes = {
  ...gamePropTypes,
}

class MultipleChoiceMultipleParagraphsContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { init } = this.props
    init(this.props)
  }

  render() {
    const {
      mark,
      retry,
      completed,
      entitiesById: entitiesByIdRaw,
      actualEntities,
      paragraphEntities,
      answerId,
      selectCard,
      selected,
      questionId,
      cards,
    } = this.props

    const entitiesById = mapValues(entitiesByIdRaw, propsMaybeWithoutMeaning)

    return (
      <Game {
        ...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <MultipleChoiceMultipleParagraphs {...{
          answerId,
          cards,
          entitiesById,
          paragraphEntities,
          questionId,
          selectCard,
          selected,
        }}
        />
      </Game>
    )
  }
}

MultipleChoiceMultipleParagraphsContainer.propTypes = propTypes
MultipleChoiceMultipleParagraphsContainer.demoData = {
  entities: [
    'paragraph-all-about-me',
    'paragraph-christmas-tree-story-1',
    'paragraph-cinderella-part-2',
    'phrase-what-does-your-father-do',
    'father',
    'phrase-i-exercise-more-than-five-hours-a-week',
    'mother',
    'phrase-what-is-the-matter',
    'phrase-she-is-mary',
    'sister',
    'brother',
  ],
}

const selector = state => state.multipleChoiceMultipleParagraphs
const actions = dispatch => bindActionCreators(allActions, dispatch)
export { MultipleChoiceMultipleParagraphsContainer }
export default connect(selector, actions)(MultipleChoiceMultipleParagraphsContainer)
