import { scene as lessonsScene } from 'src/pages/LessonsLayout/config'
import { scene as gradingScene } from 'src/pages/Grading/config'
import { scene as calendarScene } from 'src/pages/CalendarPage/config'
import { scene as attendanceScene } from 'src/pages/AttendancePage/config'
import { scene as leaderBoardScene } from 'src/pages/DashboardPage/StudentsLeaderboard/config'
import { scene as teacherGuidesScene } from 'src/pages/TeacherGuides/config'
import { schoolScene as usersScene } from 'src/pages/Users/config'
import { scene as yearsScene } from 'src/pages/Years/config'
import { scene as settingScene } from 'src/pages/SchoolSettings/config'
import { scene as previewStudentsScene } from 'src/pages/preview/UserCards/config'
import { scene as previewModulesScene } from 'src/pages/preview/Modules/config'
import { schoolScene as curriculumScene } from 'src/pages/Curriculum/config'

const schoolSection = [
  lessonsScene,
  gradingScene,
  leaderBoardScene,
  previewModulesScene,
  calendarScene,
  curriculumScene,
  teacherGuidesScene,
  attendanceScene,
  usersScene,
  previewStudentsScene,
  yearsScene,
  settingScene,
]

export default schoolSection
