const {
  addEventListener,
  clearTimeout,
  innerHeight,
  innerWidth,
  localStorage,
  location,
  mozAudioContext,
  msAudioContext,
  navigator,
  print,
  removeEventListener,
  setTimeout,
  webkitAudioContext,
  sessionStorage,
  Notification,
  Rollbar,
} = window

const AudioContext = window.AudioContext
  || webkitAudioContext
  || mozAudioContext
  || msAudioContext

const { Capacitor } = window

const isNative = () => Capacitor && Capacitor.isNative

const mobileLanscape = () => {
  if (isNative()) Capacitor.Plugins.ScreenOrientation.landscape()
}
const mobilePortrait = () => {
  if (isNative()) Capacitor.Plugins.ScreenOrientation.portrait()
}

const bindMobileLandscapeWhenActive = () => {
  if (isNative()) {
    Capacitor.Plugins.App.addListener('appStateChange', (state = Capacitor.AppState) => {
      if (state.isActive && location.pathname !== '/login') mobileLanscape()
    })
  }
}

const enterFullScreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}

const isFullScreen = () => Boolean(
  document.fullscreenElement ||
  document.webkitFullscreenElement ||
  document.mozFullScreenElement ||
  document.msFullscreenElement,
)

const exitFullScreen = () => {
  if (!isFullScreen()) return

  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }

  if (isNative()) { Capacitor.Plugins.StatusBar.hide() }
}

const toggleFullscreen = (element = document.documentElement) => {
  if (isFullScreen()) {
    exitFullScreen()
  } else {
    enterFullScreen(element)
  }
}

const isIpad = () => window.ontouchstart !== undefined;

const isMobile = () => (
  navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || isIpad()
)

const isMobileOrNative = () => isMobile() || isNative()

const isChrome = () => (
  navigator.userAgent.toLowerCase().indexOf('chrome') > -1
  && navigator.vendor.toLowerCase().indexOf('google') > -1
)

const schoolDomainHref = () => window.location.href.replace('learn21.school', 'l21.sc')
const schoolDomainLink = () => window.location.hostname.replace('learn21.school', 'l21.sc')

export {
  addEventListener,
  AudioContext,
  bindMobileLandscapeWhenActive,
  clearTimeout,
  enterFullScreen,
  exitFullScreen,
  isFullScreen,
  toggleFullscreen,
  innerHeight,
  innerWidth,
  isNative,
  isMobile,
  isMobileOrNative,
  isChrome,
  localStorage,
  location,
  mobileLanscape,
  mobilePortrait,
  navigator,
  print,
  removeEventListener,
  sessionStorage,
  setTimeout,
  Notification,
  Rollbar,
  schoolDomainLink,
  schoolDomainHref,
}

export default window
