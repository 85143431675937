import React from 'react'
import { shape } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import KeyLabel from 'src/components/KeyLabel'
import normalizeImage from 'src/lib/normalizeImage.js'


const propTypes = {
  entity: shape(entityShape).isRequired,
}
const defaultProps = {
  entity: {},
}

const VocabularyEnglishAndThai = ({
  entity,
}) => {
  const entityImage = { ...entity }.image
  const entitySrc = { ...entityImage }.src
  return (
    <Div flex auto gutter={2}>
      {
        normalizeImage(entitySrc) && (
          <Div flex col={4} px={2}>
            <EntityImage entity={entity} />
          </Div>
        )
      }
      <Div px={2} flex auto>
        <Div flex column auto justify-center>
          <Div flex mb={2} justify-center>
            <KeyLabel label="1" />
            <Div flex align-end auto>
              <AdaptiveCard
                audio
                autoPlayOnce
                bold
                english
                entity={entity}
                height={11}
                keyToPlay="1"
                minHeight={false}
                p={1}
              />
            </Div>
          </Div>
          {
            entity.titleTh && (
              <Div flex justify-center>
                <KeyLabel label="2" />
                <Div flex align-end auto>
                  <AdaptiveCard
                    audio
                    entity={entity}
                    height={11}
                    keyToPlay="2"
                    minHeight={false}
                    p={1}
                    thai
                  />
                </Div>
              </Div>
            )
          }
        </Div>
      </Div>
    </Div>
  )
}

VocabularyEnglishAndThai.propTypes = propTypes
VocabularyEnglishAndThai.defaultProps = defaultProps

export default VocabularyEnglishAndThai
