import React, { useContext } from 'react'
import Div from 'src/components/Div'
import PersonImg from 'src/components/PersonImg'
import Icon from 'src/components/Icon'
import {
  imageUrl,
  isTBA,
  TBAStyle,
} from 'src/pages/DashboardPage/StudentsLeaderboard/StudentsLeaderboard'
import 'src/pages/DashboardPage/BestStudentSlider/custom.css'
import { t } from 'i18next'
import { LocaleContext } from 'src/lib/contexts'

const STUDENT_DETAIL_WIDTH = 100
const DEFAULT_IMAGE_WIDTH = 115
const IMAGE_HEIGHT_RATIO = 4 / 3

const imageWidth = DEFAULT_IMAGE_WIDTH
const imageHeight = imageWidth * IMAGE_HEIGHT_RATIO

const studentName = ({ studentNameTh, studentNameEn }, locale) => {
  const studentTitle = locale === 'en' ? studentNameEn : studentNameTh
  return studentTitle.split(' ')[0]
}

const dotRender = (length) => {
  const dot = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    dot.push(<div className="nav-dot" />)
  }
  return dot
}
// eslint-disable-next-line max-len
const combine = (students, children, locale) => students.map(({ id: studentId, studentPhotoPath, studentNameTh, studentNameEn, avgStudentGrade, classEn }, index) => (
  <Div key={studentId} className={`sliderL ${index}`}>
    <Div flex key={studentId} studentBox>
      <Div
        flex
        relative
      >
        <PersonImg
          src={imageUrl(studentPhotoPath)}
          position="center"
          className="clear-image"
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      </Div>
      <Div flex column p style={{ transform: 'scale(1.005) perspective(1px)' }}>
        <Div flex justify-center bold style={{ width: STUDENT_DETAIL_WIDTH }}>{classEn}
        </Div>
        <Div flex justify-center style={TBAStyle({ studentNameEn })}>
          {
            isTBA({ studentNameEn }) ? t('leaderboard.tryHarder')
              : studentName({
                studentNameEn,
                studentNameTh,
              }, locale)
          }
        </Div>
        <Div flex justify-center>
          {avgStudentGrade && parseFloat(avgStudentGrade)
            .toFixed(2)}
        </Div>
        <Div flex auto justify-center style={{ alignItems: 'center' }}>
          {
            avgStudentGrade === '4.0' && (
              <Icon
                icon="certificate"
                className="beststudent-icon blue"
                style={{
                  fontSize: '50px',
                }}
              />
            )
          }
        </Div>
        <Div flex justify-center style={{ marginBottom: '2px' }}>
          {dotRender(students.length)}
        </Div>
      </Div>
    </Div>
  </Div>
))

const BestStudentSlider = ({ children, students }) => {
  const { locale } = useContext(LocaleContext)
  return (
    <div
      className="slider"
    >
      {combine(students, children, locale)}
    </div>

  )
}

export default BestStudentSlider
