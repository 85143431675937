import { string, shape } from 'prop-types'

const entity = {
  id: string,
  slug: string,
  sound: shape({
    id: string,
    urlEn: string,
  }),
  titleEn: string,
}

export default entity
