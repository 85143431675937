import shuffle from 'lodash/shuffle'
import startsWith from 'lodash/startsWith'
import keyBy from 'lodash/keyBy'
import uniqShuffle from 'src/lib/uniqShuffle'
import splitWhile from 'src/lib/splitWhile'
import splitIntoChunks from 'src/lib/splitIntoChunks'

const chunks = splitIntoChunks((entities) => {
  const [phonics, withoutPhonics] = splitWhile(entities, ({ slug }) => startsWith(slug, 'phonics-'))
  const [answers, tail] = splitWhile(withoutPhonics, ({ slug }) => !startsWith(slug, 'phonics-'))
  return [[phonics, answers], tail]
})

const initialState = {
  answers: [],
  phonics: [],
  entitiesById: {},
  selected: null,
  completed: null,
}

const INIT = 'blendingSounds/INIT'
const SELECT = 'blendingSounds/SELECT'
const RETRY = 'blendingSounds/RETRY'
const MARK = 'blendingSounds/MARK'

const init = ({ entities }) => ({ type: INIT, payload: { entities } })
const select = answerId => ({ type: SELECT, payload: { answerId } })
const retry = () => ({ type: RETRY })
const mark = () => ({ type: MARK })

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const [phonics, [correct, ...incorrect]] = uniqShuffle(chunks(entities))[0]
      return {
        ...initialState,
        entitiesById: keyBy(entities, 'id'),
        answers: shuffle([...incorrect, correct]).map(({ id }) => id),
        correct: correct.id,
        phonics: phonics.map(({ id }) => id),
      }
    }

    case SELECT: {
      const { answerId } = payload
      return {
        ...state,
        selected: answerId,
      }
    }

    case RETRY: {
      return {
        ...state,
        selected: null,
        completed: null,
      }
    }

    case MARK: {
      const { selected, correct } = state
      return {
        ...state,
        completed: selected === correct,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  select,
  retry,
  mark,
}

export default reducer
