import React from 'react'
import { useSelector } from 'react-redux'

import CardIteration from 'src/components/IterationIcon/CardIteration'
import TriesIteration from 'src/components/IterationIcon/TriesIteration'

const IterationIcon = () => {
  const { cardIterations = [], triesIterations = [] } = useSelector(state => state.iterationIcon)
  if (cardIterations.length) {
    return <CardIteration cardIterations={cardIterations} />
  }
  if (triesIterations.length) {
    return <TriesIteration triesIterations={triesIterations} />
  }
  return null
}

export default IterationIcon
