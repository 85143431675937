import React from 'react'
import TheLayout from 'src/pages/TheLayout'
import SetUser from 'src/components/SetUser'
import LessonsLayout from 'src/pages/LessonsLayout/LessonsLayout'

const LessonsPage = ({ children }) => (
  <TheLayout>
    <SetUser>
      <LessonsLayout>
        { children }
      </LessonsLayout>
    </SetUser>
  </TheLayout>
)

export default LessonsPage
