import React from 'react'
import { useHistory } from 'react-router'
import omit from 'lodash/omit'
import CurriculumProgress from 'src/pages/ProgressPage/CurriculumProgress'
import userPath from 'src/lib/routes/user'
import userReport from 'src/lib/routes/userReport'
import usePath from 'src/lib/routes/usePath'
import usePageParams from 'src/lib/usePageParams'

const updateParams = (params, update) => {
  const updatedParams = { ...params, ...update }
  if (params.subjectId && params.subjectId !== updatedParams.subjectId) {
    return omit(updatedParams, ['standardSlug', 'strandSlug'])
  }
  if (params.strandSlug && params.strandSlug !== updatedParams.strandSlug) {
    return omit(updatedParams, ['standardSlug'])
  }
  return updatedParams
}

const UserShowProgressContainer = ({ userId, user, report }) => {
  const history = useHistory()
  const pagePath = report ? userReport : userPath
  const path = usePath(pagePath)
  const params = usePageParams()
  const onPathChange = (update) =>
    history.push(
      path({
        ...updateParams(params, update),
        tab: 'progress',
      })
    )
  return (
    <CurriculumProgress
      {...params}
      onStrandChange={(update) => onPathChange(update)}
      onSubjectChange={(subjectId) => onPathChange({ subjectId })}
      userId={userId}
      yearId={user.year.id}
    />
  )
}

export default UserShowProgressContainer
