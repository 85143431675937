import React from 'react'
import { Switch } from 'react-router'
import { arrayOf, node, oneOfType } from 'prop-types'
import NoMatch from 'src/routes/NoMatch/NoMatch'

const propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
}

const SwitchNoMatch = ({ children }) => (
  <Switch>
    {children}
    <NoMatch />
  </Switch>
)

SwitchNoMatch.propTypes = propTypes

export default SwitchNoMatch
