import { compose } from 'src/lib/redux'
import { string } from 'prop-types'
import gqlLoader from 'src/lib/gqlLoader'
import StudentSkills from 'src/pages/StudentSkillsPage/StudentSkills'
import subjectQuery from 'src/queries/subject.gql'
import studentSkillsQuery from 'src/pages/StudentSkillsPage/studentSkills.gql'

const StudentSkillsContainer = compose(
  gqlLoader(subjectQuery),
  gqlLoader(studentSkillsQuery),
)(StudentSkills)

StudentSkillsContainer.propTypes = {
  subjectId: string,
  userId: string.isRequired,
}

export default StudentSkillsContainer
