import { Component } from 'react'
import { arrayOf, object, func } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { soundFinishedPlaying as soundFinishedPlayingAction } from 'src/lib/sounds/reducer'
import audioPlayer from 'src/lib/audioPlayer'

const propTypes = {
  soundFinishedPlaying: func.isRequired,
  soundsPlaying: arrayOf(object),
}

const defaultProps = {
  soundsPlaying: [],
}

export class GlobalAudioPlayer extends Component {
  players = []

  componentDidUpdate({ soundsPlaying: prevSounds }) {
    const { soundsPlaying: nextSounds } = this.props
    if (prevSounds !== nextSounds) {
      if (prevSounds.length < nextSounds.length) {
        this.handleAddSound(prevSounds, nextSounds)
      }
    }
  }

  componentWillUnmount() {
    this.players.forEach(player => player.off())
  }

  handleRemoveSound(index) {
    const { soundFinishedPlaying } = this.props
    this.players = this.players.filter((p, i) => (i + 1) !== index)
    soundFinishedPlaying(index)
  }

  handleAddSound(prevSounds, nextSounds) {
    const newSound = nextSounds.filter(s => !prevSounds.includes(s))[0]
    const player = audioPlayer({
      ...newSound,
      onEnd: () => this.handleRemoveSound(this.players.length),
    })
    player.play()
    this.players = [...this.players, player]
  }

  render() {
    return null
  }
}

GlobalAudioPlayer.propTypes = propTypes
GlobalAudioPlayer.defaultProps = defaultProps

const mapStateToProps = state => ({ soundsPlaying: state.sounds.soundsPlaying })
const mapDispatchToProps = dispatch => bindActionCreators({
  soundFinishedPlaying: soundFinishedPlayingAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAudioPlayer)
