/* eslint-disable max-len */
/* eslint-disable sort-keys */
import {
  ACTIVITIES,
  ADMISSIONS,
  ALL_STUDENTS,
  ATTENDANCE,
  ATTENDANCE_REPORT,
  BP_REPORT,
  CALENDAR,
  CHILDREN,
  DOCUMENT_MANAGEMENT,
  EXAMINATIONS,
  GRADING,
  HOMEWORK,
  LEADERBOARD,
  LESSON_MODULES,
  LESSONS,
  LIBRARY,
  MY_FAMILY,
  MY_STUDENTS,
  MY_STUDENTS_ASSESSMENT,
  MY_STUDENTS_ATTENDANCE,
  MY_STUDENTS_ATTENDANCE_REPORT,
  MY_STUDENTS_CURRICULUM,
  MY_STUDENTS_PROGRESS,
  PARENT_DATA,
  PARENTS,
  PEOPLE,
  PROGRESS,
  RESOURCE_MANAGEMENT,
  SCHOOL,
  SCHOOL_ATTENDANCE,
  SCHOOL_ATTENDANCE_REPORT,
  SCHOOL_CURRICULUM,
  SCHOOL_PROGRESS,
  SCHOOL_SETTINGS,
  SKILLS,
  SPECIAL_NEEDS,
  STAFF,
  STAFF_DATA,
  STUDENT_CARDS,
  STUDENT_DATA,
  TEACHER,
  TEACHER_GUIDES,
  TEACHERS,
  TIMETABLE,
  USERS,
  YEARS,
} from 'src/constants/menu'

export default {
  'locale.locale': 'th',
  'locale.name': 'ไทย',

  'component.titlePage': 'หัวข้อ',

  'shared.all': 'ทั้งหมด',
  'units.mm': 'มม.',
  'units.cm': 'ซม.',
  'units.m': 'ม.',
  'units.C': '°C',

  'all.units': 'หน่วยการเรียนรู้ ทั้งหมด',

  'answer.yes': 'ใช่',
  'answer.no': 'ไม่ใช่',
  'answer.true': 'ใช่',
  'answer.false': 'ไม่ใช่',

  'shared.academicYear': 'ปีการศึกษา',
  'shared.announcements': 'ประกาศ',
  'shared.dictionary': 'พจนานุกรม',
  'shared.done': 'สำเร็จ',
  'shared.error': 'เกิดข้อผิดพลาด',
  'shared.lessons': 'บทเรียน',
  'shared.parent': 'ผู้ปกครอง',
  'shared.school': 'โรงเรียน',
  'shared.student': 'นักเรียน',
  'shared.teacher': 'ครู',
  'shared.term': 'ภาคเรียน',
  'shared.english': 'English',
  'shared.en': 'En',
  'shared.thai': 'ไทย',
  'shared.th': 'ไทย',
  'shared.today': 'วันนี้',
  'shared.week': 'สัปดาห์',
  'shared.weekend': 'วันสุดสัปดาห์',
  'shared.areYouSure': 'ต้องการยืนยัน?',
  'shared.typeHere': 'พิมพ์ที่นี้',
  'shared.ListenAndTypeSentence': 'ฟังและพิมพ์ประโยคให้ถูกต้อง',

  'leaderboard.tryHarder': 'ไม่มีนักเรียนที่ได้เกรด 3.0',
  'leaderboard.StarsOfTheWeek': 'ดาวเด่นประจำสัปดาห์',

  'direction.from': 'จาก',
  'direction.to': 'ถึง',
  'direction.next': 'ถัดไป',
  'direction.previous': 'กลับหลัง',

  'home.homework': 'การบ้าน',
  'home.users': 'ผู้ใช้งาน',

  'shared.add': 'เพิ่ม',
  'shared.edit': 'แก้ไข',
  'shared.new': 'ใหม่',
  'shared.notFound.user': 'ไม่มีผู้ใช้งานนี้ในระบบ',
  'shared.save': 'บันทึก',
  'shared.delete': 'ลบ',
  'save.success': 'บันทึกเรียบร้อยแล้ว',
  'shared.cancel': 'ยกเลิก',
  'shared.select': 'เลือก',
  'shared.skip': 'ข้าม',
  'shared.microphoneCheck': 'กำลังตรวจสอบการเชื่อมต่อไมโครโฟน',
  'send.email.success': 'ส่งอีเมล์เรียบร้อยแล้ว',

  'errors.recordInvalid': 'ไม่ผ่านการตรวจสอบ',
  'errors.missingUsername': 'กรุณาใส่ซื่อผู้ใช้',
  'errors.passwordNotSame': 'กรุณาใส่รหัสผ่านให้เหมือนกัน',
  'errors.passwordIsBlank': 'ห้ามเว้นว่างในช่องนี้',
  'errors.actionNotAllowed.title': 'การกระทำนี้ไม่ได้รับอนุญาต',
  'errors.actionNotAllowed.message':
    'การกระทำนี้สามารถทำได้ภายในโรงเรียนเท่านั้น',
  'errors.noRecordsFound': 'ไม่พบข้อมูล',

  'overview.title': 'ภาพรวม',
  [`${HOMEWORK}.title`]: 'การบ้าน',
  [`${SCHOOL}.title`]: 'โรงเรียน',
  [`${TEACHER}.title`]: 'ครู',
  [`${USERS}.title`]: 'ผู้ใช้งาน',
  [`${STUDENT_CARDS}.title`]: 'บัตรนักเรียน',
  [`${YEARS}.title`]: 'ห้องเรียน',
  [`${CHILDREN}.title`]: 'บุตร',
  [`${PROGRESS}.title`]: 'ความก้าวหน้า',
  [`${BP_REPORT}.title`]: 'ปพ. รายงาน',
  [`${SKILLS}.title`]: 'ทักษะ',
  [`${ATTENDANCE}.title`]: 'การเข้าเรียน',
  [`${ATTENDANCE_REPORT}.title`]: 'รายงาน การเข้าเรียน',
  [`${ACTIVITIES}.title`]: 'กิจกรรม',
  [`${ACTIVITIES}.description`]: 'กิจกรรมเสริมหลักสูตร, ชมรม, กีฬาและกิจกรรม',
  [`${ADMISSIONS}.title`]: 'รับสมัครเรียน',
  [`${ADMISSIONS}.description`]: 'สนับสนุนขั้นตอนการลงทะเบียน และการจัดการการรายงานต่าง ๆ',
  [`${DOCUMENT_MANAGEMENT}.title`]: 'การจัดการเอกสาร',
  [`${DOCUMENT_MANAGEMENT}.description`]: 'การจัดการเอกสาร ใบแจ้งหนี้และรายงานต่าง ๆ ไปยังผู้ปกครอง ติดตามการติดต่อสื่อสารกับนักเรียน ผู้ปกครองและพนักงาน',
  [`${EXAMINATIONS}.title`]: 'ผลสอบ',
  [`${EXAMINATIONS}.description`]: 'คะแนนการวัดผลแบบเป็นทางการ: ดูและแก้ไขคะแนนต่าง ๆ พิมพ์สมุดรายงานผลการเรียน ใบรับรอง รบ. และเอกสารต่าง ๆ',
  [`${GRADING}.title`]: 'การตัดสินผลการเรียน',
  [`${GRADING}.description`]: 'การตัดสินผลการเรียน',
  [`${LESSONS}.title`]: 'บทเรียน',
  [`${TEACHER_GUIDES}.title`]: 'คู่มือการใช้บทเรียน',
  [`${LEADERBOARD}.title`]: 'กระดานนักเรียนยอดเยียม',
  [`${LEADERBOARD}.description`]: 'แสดงรายชื่อนักเรียนยอดเยียม',
  [`${LESSON_MODULES}.title`]: 'ตัวอย่างกิจกรรมในบทเรียน',
  [`${LESSON_MODULES}.description`]: 'ตัวอย่างกิจกรรมในบทเรียน',
  [`${LIBRARY}.title`]: 'ห้องสมุด',
  [`${LIBRARY}.description`]: 'การค้นหาหนังสือ ควบคุมคงคลังหนังสือ ติดตามและกำหนดหนังสือให้นักเรียนอ่าน บริการยืมคืนหนังสือออนไลน์',
  [`${RESOURCE_MANAGEMENT}.title`]: 'การจัดการทรัพยากร',
  [`${RESOURCE_MANAGEMENT}.description`]: 'คงคลังพัสดุ วัสดุและอุปกรณ์ต่าง ๆ',
  [`${SCHOOL_PROGRESS}.title`]: 'ความคืบหน้าของการบ้าน',
  [`${SCHOOL_PROGRESS}.description`]: 'ติดตามการเรียนรู้ และสถานะการสอนจากทั้งโรงเรียน ไปยังเด็กและครู เป็นรายบุคคล',
  [`${SCHOOL_ATTENDANCE}.title`]: 'การเข้าเรียน',
  [`${SCHOOL_ATTENDANCE}.description`]: 'จัดการการบันทึกการเข้าเรียนของนักเรียน เป็น คาบ วัน เทอม และป',
  [`${SCHOOL_ATTENDANCE_REPORT}.title`]: 'รายงาน การเข้าเรียน',
  [`${SCHOOL_ATTENDANCE_REPORT}.description`]: 'จัดการการบันทึกการเข้าเรียนของนักเรียน เป็น คาบ วัน เทอม และปี',
  [`${SCHOOL_CURRICULUM}.title`]: 'มาตรฐานการเรียนรู้',
  [`${SCHOOL_CURRICULUM}.description`]: 'จัดการตารางสอน ตามรายวิชา กำหนดห้องเรียนและครูสำหรับรายวิชาต่าง ๆ',
  [`${SCHOOL_SETTINGS}.title`]: 'เพิ่มชื่อโรงเรียน',
  [`${ALL_STUDENTS}.title`]: 'นักเรียนทั้งหมด',
  [`${SPECIAL_NEEDS}.title`]: 'เด็กพิเศษ',
  [`${SPECIAL_NEEDS}.description`]: 'ติดตามข้อมูลที่เกี่ยวข้องกับนักเรียนที่มีปัญหาการเรียนรู้/เด็กพิเศษ',
  [`${STAFF_DATA}.title`]: 'ข้อมูลพนักงาน',
  [`${STAFF_DATA}.description`]: 'ข้อมูลพนักงานและรายละเอียดต่าง ๆ การเข้าไปกรอกข้อมูลของโรงเรียนและนักเรียน บนเวปไซต์ของโรงเรียนหรือบนมือถือ',
  [`${STUDENT_DATA}.title`]: 'ข้อมูลนักเรียน',
  [`${STUDENT_DATA}.description`]: 'จัดการข้อมูลของนักเรียนที่ตรวจสอบให้มั่นใจว่าเป็นข้อมูลที่ครบถ้วน รายงานไปอย่างถูกต้องและเข้าถึงข้อมูลได้อย่างรวดเร็ว',
  [`${PARENT_DATA}.title`]: 'ข้อมูลผู้ปกครอง',
  [`${PARENT_DATA}.description`]: 'รายละเอียดการติดต่อของผู้ปกครอง, ผู้ปกครองตรวจสอบรายงานผลการเรียน เวลาเรียน และเหตุการณ์สำคัญ ค่าเล่าเรียนและค่าเรียนพิเศษและกิจกรรมเสริมหลักสูตรต่าง ๆ',
  [`${PEOPLE}.title`]: 'ผู้คน',
  [`${TIMETABLE}.title`]: 'ตารางเวลา',
  [`${TIMETABLE}.description`]: 'แสดงตารางเวลาของโรงเรียนทั้งหมด แต่ละห้อง และช่วงชั้น',
  [`${CALENDAR}.title`]: 'ปฏิทิน',
  [`${CALENDAR}.description`]: 'การวางแผนงานกิจกรรมต่าง ๆ ของทุกแผนกในโรงเรียน',
  [`${TEACHERS}.title`]: 'รายชื่อครู',
  [`${PARENTS}.title`]: 'รายชื่อผู้ปกครอง',
  [`${STAFF}.title`]: 'รายชื่อบุคลากร',
  [`${MY_FAMILY}.title`]: 'ครอบครัวของฉัน',
  [`${MY_STUDENTS}.title`]: 'นักเรียน',
  [`${MY_STUDENTS_ASSESSMENT}.title`]: 'การวัดผลของนักเรียน',
  [`${MY_STUDENTS_ASSESSMENT}.description`]: 'ติดตามคะแนนเก็บต่าง ๆ ของนักเรียนซึ่งแสดงผลตามสาระและตัวชี้วัดตามหลักสูตร',
  [`${MY_STUDENTS_ATTENDANCE}.title`]: 'การเข้าเรียน',
  [`${MY_STUDENTS_ATTENDANCE}.description`]: 'จัดการการเข้าเรียนของนักเรียนราย คาบ, วัน, ภาคการศึกษา, ปีการศึกษา สำหรับนักเรียนของฉัน',
  [`${MY_STUDENTS_ATTENDANCE_REPORT}.title`]: 'รายงานการเข้าเรียน',
  [`${MY_STUDENTS_ATTENDANCE_REPORT}.description`]: 'รายงานการเข้าเรียนราย คาบ, วัน, ภาคการศึกษา, ปีการศึกษา สำหรับนักเรียนของฉัน',
  [`${MY_STUDENTS_CURRICULUM}.title`]: 'มาตรฐานการเรียนรู้',
  [`${MY_STUDENTS_CURRICULUM}.description`]: 'จัดการตารางสอน ตามรายวิชา กำหนดห้องเรียนสำหรับนักเรียนของฉัน',
  [`${MY_STUDENTS_PROGRESS}.title`]: 'ความคืบหน้าของการบ้าน',
  [`${MY_STUDENTS_PROGRESS}.description`]: 'ติดตามสถานะการเรียนการสอนของนักเรียน',

  'academicYearFilter.yearSelector': 'เลือกปีการศึกษา',
  'academicYearFilter.wholeYear': 'ทั่งปีการศึกษา',

  'assessment.toMark': 'รอการให้คะแนน',
  'assessment.marked': 'ให้คะแนนแล้ว',
  'assessment.homeworkEmpty': 'การบ้านยังไม่เสร็จ',
  'assessment.mark': 'ให้คะแนน',
  'assessment.grade': 'คะแนน',

  'dashboard.homeworkAssignments.title': 'การมอบหมายงานตามชั้นปี',
  'dashboard.homeworkAssignments.createdAt': 'วันที่มอบหมาย',
  'dashboard.homeworkAssignments.lesson': 'บทเรียน',
  'dashboard.homeworkAssignments.year': 'ชั้นปี',
  'dashboard.homeworkAssignments.due': 'กำหนดส่ง',
  'dashboard.homeworkAssignments.teacher': 'ครูผู้สอน',

  'grade.system.color': 'สี',
  'grade.system.grade': 'ระดับ',
  'grade.system.number': 'ระดับ (ตัวเลข)',
  'grade.system.percent': 'เปอร์เซ็น',
  'grade.system.titleEn': 'คำอธิบายภาษาอังกฤษ',
  'grade.system.titleTh': 'คำอธิบายภาษาไทย',

  'homework.grade': 'คะแนน',
  'homework.students': 'จำนวนนักเรียน',
  'homework.completedRate': 'การบ้าน เสร็จสิ้น / มอบหมาย',
  'homework.assignedRate': 'การบ้าน มอบหมาย / ทั้งหมด',

  'objectives.objectives': 'จุดประสงค์การเรียนรู้',
  'objectives.byTheEndOfThisLesson': 'เมื่อจบบทเรียนนี้แล้วนักเรียนสามารถ:',

  'wellDone.howDoYouFeel': 'นักเรียนรู้สึกอย่างไรกับการบ้านวันนี้',
  'wellDone.wellDone': 'เยี่ยมมาก!',
  'wellDone.youHaveLearned': 'วันนี้นักเรียนสามารถ:',

  'results.results': 'ผลการค้นหาการบ้าน',

  'skills.reading': 'การอ่าน',
  'skills.writing': 'การเขียน',
  'skills.speaking': 'การพูด',
  'skills.listening': 'การฟัง',
  'skills.title': 'ทักษะ',

  'subjects.math': 'คณิตศาสตร์',
  'subjects.english': 'อังกฤษ',
  'subjects.science': 'วิทยาศาสตร์',
  'subjects.thai': 'ไทย',

  'curriculum.subject': 'วิชา',
  'curriculum.strand': 'สาระการเรียนรู้',
  'curriculum.standard': 'มาตรฐาน',
  'curriculum.grade': 'ชั้น',
  'curriculum.indicator': 'ตัวชี้วัด',

  'attendance.allPresent': 'ทุกคนมาเรียน',
  'attendance.nav.report': 'รายงาน',
  'attendance.nav.weekly': 'รายสัปดาห์',
  'attendance.report.days': 'จำนวนวัน',
  'attendance.scanCard': 'สแกนบัตรผู้ใช้งาน',
  'attendance.shared.present': 'มาเรียน',
  'attendance.shared.absent': 'ขาดเรียน',
  'attendance.status.present': 'มา',
  'attendance.status.late': 'สาย',
  'attendance.status.absent': 'ขาด',
  'attendance.status.sick': 'ป่วย',
  'attendance.status.permitted': 'ลากิจ',
  'attendance.report.attendance': 'การเข้าเรียน',
  'attendance.report.attendanceSections': 'การเข้าเรียนรายกลุ่ม',
  'attendance.report.grade': 'คะแนน',
  'attendance.report.students': 'จำนวนนักเรียน',
  'attendance.report.progress': 'สถิติการเข้าเรียน',

  'attendance.progress.percentDescription': 'รางาน การมาเรียน',
  'attendance.progress.bodyDescription': 'การเข้าเรียน',
  'attendance.progress.actual': 'มาเรียน',
  'attendance.progress.total': 'จำนวนวัน',
  'attendance.weekly': 'การเข้าเรียนรายสัปดาห์',

  'navigation.beginning': 'เริ่มต้น',
  'navigation.end': 'สิ้นสุด',
  'navigation.help': 'ช่วยเหลือ',
  'navigation.mark': 'ตรวจสอบ',
  'navigation.next': 'ถัดไป',
  'navigation.previous': 'กลับ',
  'navigation.lesson': 'บทเรียน',

  'lessons.unrelease.warning':
    'คำเตือน: การบ้านเหล่านี้มีนักเรียนส่งงานแล้ว ดำเนินการต่อ?',

  'error.inChapter': 'บทเรียนนี้มีข้อผิดพลาดและไม่สามารถแสดงได้ในตอนนี้',
  'error.noChapter': 'บทเรียนนี้อยู่ในช่วงพัฒนาและจะเปิดให้ใช้งานเร็วๆนี้',

  'user.user': 'ผู้ใช้งาน',
  'user.name': 'ชื่อ',
  'user.firstName': 'ชื่อ',
  'user.firstNameEn': 'ชื่อ (En)',
  'user.firstNameTh': 'ชื่อ (Th)',
  'user.lastName': 'นามสกุล',
  'user.lastNameEn': 'นามสกุล (En)',
  'user.lastNameTh': 'นามสกุล (Th)',
  'user.email': 'อีเมล์',
  'user.password': 'รหัสผ่าน',
  'user.confirmPassword': 'ยืนยันรหัสผ่าน',
  'user.relationship': 'ความสัมพันธ์',
  'user.identifier': 'รหัสนักเรียน',
  'user.role': 'ประเภท',
  'user.section': 'ช่วงชั้น',
  'user.year': 'ปี',
  'user.teachingYears': 'ชั้นปี',
  'user.children': 'บุตร',
  'user.parents': 'ผู้ปกครอง',
  'user.birthdate': 'วันเดือนปีเกิด',
  'user.medicalNote': 'หมายเหตุทางการแพทย์',
  'user.updatePhoto': 'อัพโหลดรูปภาพ',
  'user.pleaseChangePasswordAt': 'กรุณาเปลียนรหัสได้ที่ ',
  'user.academicTerms': 'ภาคเรียน',
  'user.academicTerm': 'ภาคเรียนที่',

  'student.for': 'สำหรับการบ้านบทนี้',
  'student.progress.title': 'การบ้าน',
  'student.progress.bodyDescription': 'การบ้าน',
  'student.progress.percentDescription': 'การบ้านที่เสร็จแล้ว',
  'student.todo': 'สิ่งที่ต้องทำ',
  'student.done': 'เสร็จสิ้น',
  // 'student.todoEmpty': 'เก่งมาก! ลองไปขอการบ้านจากคุณครูเพิ่มสิจ๊ะ',
  'student.todoEmpty': 'เก่งมาก! ลองอีกระดับ!',
  'student.doneEmpty': 'นักเรียนพร้อมจะเริ่มทำการบ้าน ?',
  'student.name': 'ชื่อ',
  'student.studentId': 'รหัสนักเรียน',
  'student.attendance': 'การเข้าเรียน',
  'student.class': 'ห้อง',
  'student.personaldetails': 'ข้อมูลส่วนตัว',
  'student.schedule': 'ตารางเรียน',
  'student.calendar': 'ปฏิทิน',
  'student.points': 'คะแนน',
  'student.menu': 'เมนู',

  'login.button': 'เข้าสู่ระบบ',
  'login.login': 'เลขประจำตัวหรืออีเมล์',
  'login.error': 'ผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  'login.errors.invalidPassword': 'รหัสผ่านไม่ถูกต้อง',
  'login.errors.invalidUser': 'ชื่อผู้ใช้ไม่ถูกต้อง',
  'login.errors.loginRequired': 'กรุณาเข้าสู่ระบบ',
  'login.forgot': 'ลืมรหัสผ่าน?',
  'login.google': 'เข้าสู่ระบบด้วย Google',
  'login.facebook': 'เข้าสู่ระบบด้วย Facebook',
  'login.loginAs': 'เข้าสู่ระบบในฐานะ',
  'login.password': 'รหัสผ่าน',
  'login.resetPassword': 'เปลี่ยนรหัสผ่าน',
  'login.backToLogin': 'กลับไปหน้าเข้าสู่ระบบ',
  'login.parentLogin': 'ผู้ปกครอง เข้าสู่ระบบ',

  'logout.button': 'ออกจากระบบ',

  'demo.button': 'โรงเรียนสำหรับทดสอบ',
  'demo.login1': 'กรุณาเลือกโรงเรียนเพื่อเข้าสู่ระบบ',
  'demo.login2': 'หรือเลือก',

  'guide.subject': 'วิชา',
  'guide.strands': 'สาระการเรียนรู้',
  'guide.standards': 'มาตรฐาน',
  'guide.indicators': 'ตัวชี้วัด',
  'guide.suggestion': 'คำแนะนํา:',
  'guide.unit': 'หน่วยการเรียนรู้ที่',
  'guide.lesson': 'บทเรียนที่',
  'guide.level': 'ชั้น',
  'guide.time': 'เวลา',
  'guide.hour': 'ชั่วโมง',
  'guide.learningObjectives': 'จุดประสงค์การเรียนรู้',
  'guide.teachingMaterials.title': 'สื่อการเรียนรู้',
  'guide.teachingMaterials.info': 'โปรแกรม Learn21',
  'guide.measurementAndEvaluation.title': 'การวัดและประเมินผล',
  'guide.measurementAndEvaluation.info1': 'การบ้านในโปรแกรม Learn21',
  'guide.measurementAndEvaluation.info2':
    'การประเมินในชั้นเรียนโดยใช้โปรแกรม Learn21',
  'guide.measurementAndEvaluation.info3':
    'การประเมินตนเองโดยใช้โปรแกรม Learn21',
  'guide.subjectCode': 'รหัสวิชา',
  'guide.indexEntities': 'ดรรชนีคำศัพท์เพิ่มเติม',
  'guide.printButton': 'สั่งพิมพ์ / นำข้อมูลออก',
  'guide.presentation': 'การนำเสนอเนื้อหา',
  'guide.practice': 'ขั้นการฝึกปฏิบัติ',
  'guide.production': 'ประมวลผลการเรียนรู้',
  'guide.printInstructionImage1': '/images/print-settings-th-1.png',
  'guide.printInstructionImage2': '/images/print-settings-th-2.png',
  'guide.printInstructionImage3': '/images/print-settings-th-3.png',

  'school.progress.percentDescription': 'การบ้านที่เสร็จแล้ว',
  'school.progress.bodyDescription': 'การบ้านที่ต้องทำ',
  'progress.actual': 'เสร็จแล้ว',
  'progress.total': 'ทั้งหมด',
  'progress.averageAttainment': 'ค่าเฉลี่ยความก้าวหน้า',
  'progress.averageTries': 'จำนวนการบ้านที่ทำโดยประมาณ',
  'progress.totalTries': 'จำนวนการบ้านที่ทำทั้งหมด',

  'school.overview': 'ภาพรวม',
  'school.grade': 'เกรด',
  'school.averageProgress': 'คะแนนเฉลีย',
  'school.homeworkReleased': 'เปิดใช้การบ้าน',
  'school.attainmentProgress': 'ความก้าวหน้าของนักเรียน',
  'school.curriculumProgress': 'ความก้าวหน้าตามมาตรฐานการเรียนรู้',
  'school.studentProgress': 'ความก้าวหน้าของนักเรียนรายบุคคล',
  'school.for': 'ของการบ้านที่เสร็จแล้ว',
  'school.unit': 'หน่วยการเรียนรู้',
  'school.lesson': 'บทเรียน',
  'school.selectUnit': 'เลือกหน่วยการเรียนรู้',
  'school.calendar.createEvent': 'สร้างกิจกรรม',
  'school.calendar.schoolOpen': 'โรงเรียนเปิด',
  'school.calendar.schoolClosed': 'โรงเรียนปิด',
  'school.calendar.schoolHolidays': 'ปิดเทอม',
  'school.calendar.schoolEvent': 'กิจกรรมโรงเรียน',

  'school.settings.academicTerms': 'เงื่อนไขทางวิชาการ',
  'school.settings.academicYears': 'ปีการศึกษา',
  'school.settings.academicTerm': 'ภาคเรียนที่',
  'school.settings.holidays': 'ปิดภาคเรียน',
  'school.settings.holiday': 'วันหยุด',
  'school.settings.calendarDays': 'วัน',
  'school.settings.academicDays': 'วัน',
  'school.settings.academicDay': 'วัน',
  'school.settings.calendar': 'ปฏิทิน',
  'school.settings.currentYear': 'ปีนี้',
  'school.settings.schoolEvents': 'กิจกรรมของโรงเรียน',
  'school.settings.school': 'โรงเรียน',
  'school.settings.schoolTitle': 'เพิ่มชื่อโรงเรียน',
  'school.settings.selectAcademicYear': 'เลือกปีการศึกษา',
  'school.settings.selectEvent': 'เลือกกิจกรรม',
  'school.users.assign': 'สมัคร {{count}} ใน {{-year}}',
  'school.users.resign': 'ลาออก {{count}} จากห้อง',
  'shortcut.instructions.title': 'ทางลัด',
  'shortcut.instructions.leftArrow': 'สไลด์ก่อนหน้า',
  'shortcut.instructions.rightArrow': 'สไลด์ถัดไป',
  'shortcut.instructions.space': 'เล่น / หยุด วีดิโอ',
  'shortcut.instructions.numbers': 'เล่นเสียง',
  'released.progress.percentDescription': 'การบ้าน',
  'released.progress.bodyDescription': 'การบ้าน',
  'released.progress.actual': 'มอบหมาย',
  'released.progress.total': 'ทั้งหมด',

  'browser.warning.title': 'ไม่รองรับเบราเซอร์นี้',
  'browser.warning.message':
    'กรุณาใช้ Chrome หรือดาวน์โหลดแอพลิเคชั่นสำหรับมือถือ',

  'year.year': 'ปี',
  'year.code': 'ห้อง',
  'year.empty': 'ไม่มีนักเรียนของปีนี้',
  'year.grade': 'ระดับชั้น',
  'year.id': 'รหัส',
  'year.title': 'หัวข้อ',
  'year.studentCount': 'จำนวน นักเรียน',
  'year.teachersName': 'ชื่อครู',

  'selection.clear': 'ยกเลิกข้อมูลที่เลือก',
  'selection.toggle-on': 'เลือกหน้านี้',
  'selection.toggle-off': 'ยกเลิกการเลือกหน้านี้',

  'bp.bp': 'ปพ',
  'bp.title': 'แบบบันทักผลการพัฒนาคุณภาพผู้เรียนรายวิชา',

  'registration.registration': 'การลงทะเบียน',
  'registration.parent': 'ผู้ปกครอง',
  'registration.children': 'เด็ก',
  'registration.registrationNow': 'ลงทะเบียนตอนนี้',
  'registration.toImproveEnglish': 'เพื่อพัฒนาทักษะภาษาอังกฤษ',
  'registration.aboutUs': 'ลองวีธีการเรียนรู้ภาษาอังกฤษระดับประถมที่ได้รับการพิสูจน์แล้วว่ามีคุณภาพพร้อมกับบทเรียนต่างๆที่สอดคล้องกับหลักสูตรแกนกลางของกระทรวงศึกษาธิการ สามารสมัครและทดลองใช้ได้ฟรี',
  'registration.addStudent': 'เพิ่มนักเรียน',
  'registration.thankYou': 'ขอบคุณ!',
  'registration.done': '**ขอบคุณที่ลงทะเบียน**\n\nกรุณาคลิกลิงค์ที่เราส่งให้คุณเพื่อยืนยันอีเมลของคุณ',
  'registration.complete': '**รอสักครู่**\n\nกำลังสร้างบัญชีของคุณ.',
  'student.loginAndStart': 'เข้าสู่ระบบและเริ่มใช้งาน',
  'student.loginAndDoHomework': 'เข้าสู่ระบบและทำการบ้าน',
  'student.switchLevel': 'เปลี่ยนชั้นเรียน',
  'student.seeSkills': 'ดูความก้าวหน้าของทักษะ',
  'student.seeAttendance': 'ดูการเข้าร่วม',

  'welcome.tagline': 'โรงเรียนแห่งอนาคต... ในวันนี้',
}
