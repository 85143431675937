import React from 'react'
import TheLayout from 'src/pages/TheLayout'
import TeacherLayout from 'src/pages/TeacherLayout/TeacherLayout'

const TeacherLayoutPage = ({ children }) => (
  <TheLayout>
    <TeacherLayout>
      {children}
    </TeacherLayout>
  </TheLayout>
)

export const path = '/teacher'

export default TeacherLayoutPage
