import React from 'react'
import { useHistory } from 'react-router'
import { shape } from 'prop-types'
import { withApollo } from '@apollo/react-hoc'
import usePageParams from 'src/lib/usePageParams'
import gqlLoader from 'src/lib/gqlLoader'
import { compose } from 'src/lib/redux'
import Users from 'src/pages/Users/Users'
import usersSearchQuery from 'src/pages/Users/usersSearch.gql'
import yearsSectionsQuery from 'src/pages/Users/yearsSections.gql'
import rolesQuery from 'src/pages/Users/roles.gql'

const propTypes = {
  client: shape().isRequired,
}

const UsersContainer = (props) => {
  const handleFetchData = ({ options }) => {
    const { client } = props
    return client.query({ query: usersSearchQuery, ...options })
  }

  const { client, ...rest } = props
  const params = usePageParams()
  const history = useHistory()
  return <Users {...params} {...rest} history={history} onFetchData={handleFetchData} />
}

UsersContainer.propTypes = propTypes

export { UsersContainer }

export default compose(
  gqlLoader(yearsSectionsQuery),
  gqlLoader(rolesQuery),
)(withApollo(UsersContainer))
