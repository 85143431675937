import React from 'react'
import classnames from 'classnames'
import { any, bool, string } from 'prop-types'

const propTypes = {
  bg: string,
  children: any.isRequired,
  className: string,
  disabled: bool,
  flat: bool,
  submit: bool,

}

const defaultProps = {
  bg: 'blue',
  className: undefined,
  disabled: false,
  flat: false,
  submit: false,
}

const Button = ({
  bg,
  children,
  className,
  disabled,
  flat,
  submit,
  ...rest
}) => (
  <button
    type={submit ? 'submit' : 'button'}
    className={classnames(
      'btn inline-block',
      {
        'btn-hover': flat,
        'btn-primary': !flat,
        [`bg-${bg}`]: bg,
        muted: disabled,
      },
      className
    )}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
)

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
