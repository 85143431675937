import React from 'react'
import { array } from 'prop-types'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import VocabularyEnglishAndThai from 'src/modules/VocabularyEnglishAndThai/VocabularyEnglishAndThai'

const propTypes = {
  entities: array.isRequired,
}

const createSlide = phrases => entity => (
  <Slide key={entity.id} label={phrases ? undefined : entity.titleEn}>
    <VocabularyEnglishAndThai entity={entity} />
  </Slide>
)

const VocabularyEnglishAndThaiContainer = ({ entities, phrases }) => (
  <Slides>
    {entities.map(createSlide(phrases))}
  </Slides>
)

const VocabularyPhrasesEnglishAndThai = ({ entities }) => (
  <VocabularyEnglishAndThaiContainer entities={entities} phrases />
)

const demoData = {
  entities: [
    'mother',
    'father',
    'family',
    'phrase-who-is-he',
  ],
}


VocabularyEnglishAndThaiContainer.propTypes = propTypes
VocabularyEnglishAndThaiContainer.demoData = demoData

VocabularyPhrasesEnglishAndThai.demoData = demoData

export { VocabularyPhrasesEnglishAndThai }
export default VocabularyEnglishAndThaiContainer
