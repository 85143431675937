import React from 'react'
import { arrayOf, shape } from 'prop-types'
import Slide from 'src/components/Layout/Slide'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import Bubble from 'src/components/Bubble'
import SentenceLine from 'src/components/SentenceLine'
import entityShape from 'src/shapes/entity'
import normalizeImage from 'src/lib/normalizeImage.js'

const propTypes = {
  images: arrayOf(
    shape(entityShape).isRequired,
  ).isRequired,
  phrases: arrayOf(
    shape(entityShape).isRequired,
  ).isRequired,
}

const isComicHasImage = (a, entity) => {
  const entityImage = { ...entity }.image
  const entitySrc = { ...entityImage }.src
  return a || entitySrc
}

const hasValidImage = ({ image }) => ({ ...image }.src && true)


const ComicSlide = ({ images, phrases }) => (
  <Slide>
    <Div flex column auto>
      {
        normalizeImage(images.reduce(isComicHasImage, undefined)) && (
          <Div flex flex-1>
            {images.filter(hasValidImage)
              .map(image => <EntityImage key={image.id} entity={image} />)}
          </Div>
        )
      }
      <Div flex column flex-1>
        {phrases.map((phrase, i) => (
          <Div key={phrase.id} flex flex-1 mb1>
            <Bubble tip={(i % 2) ? 'right' : 'left'}>
              <SentenceLine
                align="left"
                entity={phrase}
                justify="start"
                height={1}
                keyToPlay={i + 1}
              />
            </Bubble>
          </Div>
        ))}
      </Div>
    </Div>
  </Slide>
)

ComicSlide.propTypes = propTypes

export default ComicSlide
