import React from 'react'
import { t } from 'i18next'
import { func, string } from 'prop-types'

import Div from 'src/components/Div'

const propTypes = {
  updateAvatar: func.isRequired,
  userId: string.isRequired,
}

class UpdateAvatar extends React.Component {
  componentDidMount() {
    const { userId, updateAvatar } = this.props

    const fileSelector = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    fileSelector.onchange = ({
      target: {
        validity,
        files: [file],
      } }) => validity.valid && updateAvatar({ variables: { file, userId } })

    this.fileSelector = fileSelector
  }

  handleFileSelect = (e) => {
    e.preventDefault()
    this.fileSelector.click()
  }

  render() {
    return (
      <Div btn btn-primary mt1 center onClick={this.handleFileSelect}>
        {t('user.updatePhoto')}
      </Div>
    )
  }
}

UpdateAvatar.propTypes = propTypes

export default UpdateAvatar
