import React from 'react'
import { Redirect } from 'react-router-dom'
import { compose } from 'src/lib/redux'
import pick from 'lodash/pick'
import gqlLoader from 'src/lib/gqlLoader'
import subjectQuery from 'src/queries/subject.gql'
import initialStudent from 'src/queries/initialStudent.gql'
import academicYear from 'src/queries/academicYear.gql'
import usePath from 'src/lib/routes/usePath'
import userReport from 'src/lib/routes/userReport'
import { STUDENT_TAB } from 'src/constants/progressTabs'
import ProgressPage from 'src/pages/ProgressPage/ProgressPage'
import yearQuery from 'src/pages/Year/shared/year.gql'

const ProgressPageContainer = ({
  initialStudent,
  pageState,
  sections,
  subject,
  year,
  ...rest
}) => {
  const { yearId, subjectId, tab, userId } = pageState
  const { id, slug } = { ...initialStudent }
  const studentId = slug || id
  const stateWithDefaults = {
    ...pageState,
    subjectId: subjectId || subject.slug,
    yearId: yearId || year.slug,
  }
  const studentPath = usePath(userReport)
  if (tab === STUDENT_TAB && !userId && studentId) {
    return (
      <Redirect
        to={studentPath({
          userId: studentId,
          subjectId: stateWithDefaults.subjectId,
        })}
      />
    )
  }
  return <ProgressPage pageState={stateWithDefaults} {...rest} />
}

export default props => compose(
  gqlLoader(academicYear),
  gqlLoader(yearQuery),
  gqlLoader(initialStudent),
  gqlLoader(subjectQuery),
)(ProgressPageContainer)({
  ...pick(props.pageState, 'academicYearId', 'yearId', 'subjectId', 'domain', 'year'),
  ...props,
})
