import React from 'react'
import TheLayout from 'src/pages/TheLayout'
import SchoolLayout from 'src/pages/SchoolLayout/SchoolLayout'

const SchoolLayoutPage = ({ children }) => (
  <TheLayout>
    <SchoolLayout>
      {children}
    </SchoolLayout>
  </TheLayout>
)

export default SchoolLayoutPage
