import React from 'react'
import { t } from 'i18next'
import { Formik, Form } from 'formik'
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import SelectGrade from 'src/pages/Registration/SelectGrade'
import Input from 'src/components/forms/Input'
import Button from 'src/components/Button'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  gradeId: yup.string().required(),
  lastName: yup.string().required(),
}).required()

const StudentCreateForm = ({ onSubmit }) => (
  <Formik
    validationSchema={schema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form>
        <Div h3 bold mxn1 mb2>
          <Icon icon="child" blue />
          {t('registration.addStudent')}
        </Div>
        <Div flex mxn1 mb2>
          <Div col-4 px1>
            <Input
              name="firstName"
              label={t('user.firstName')}
              placeholder={t('user.firstName')}
              className="qa-user-firstName"
              required
            />
          </Div>
          <Div col-4 px1>
            <Input
              name="lastName"
              label={t('user.lastName')}
              placeholder={t('user.lastName')}
              className="qa-user-lastName"
              required
            />
          </Div>
          <Div px1 col-4>
            <SelectGrade
              required
              name="gradeId"
            />
          </Div>
        </Div>
        <Button type="submit" disabled={isSubmitting} className="p2 col-12">
          {isSubmitting ? (
            <Icon spin icon="circle-notch" />
          ) : (
            <Icon icon="check" />
          )}
        </Button>
      </Form>
    )}
  </Formik>
)

export default StudentCreateForm
