import React from 'react'
import { array, bool, object, string, func } from 'prop-types'
import Bubble from 'src/components/Bubble'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import ParagraphCard from 'src/components/ParagraphCard'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import TextFit from 'src/components/TextFit'

const propTypes = {
  availableCardsId: array.isRequired,
  entitiesById: object.isRequired,
  image: string,
  moveCard: func.isRequired,
  places: object.isRequired,
  punctuationMode: bool,
  words: array.isRequired,
}

const defaultProps = {
  image: '',
  punctuationMode: null,
}

const renderPlace = ({ moveCard, place, cards, punctuationMode }) => {
  const { id, cardId, correct, beforeWord, afterWord } = place
  return (
    <Div inline mr2 key={id} style={{ textAlign: 'center' }}>
      <span className={punctuationMode ? 'bold' : ''}>
        {beforeWord}
      </span>
      <span className="flex-auto">
        <Place
          id={id}
          onDrop={moveCard}
          mx={false}
          inline
        >
          <span>
            {
              cardId
                ? (
                  <ParagraphCard
                    adaptiveSize
                    bg="silver"
                    correct={correct}
                    draggable
                    id={cardId}
                    inline
                    key={cardId}
                    title={cards[cardId].title}
                  />
                )
                : (
                  <ParagraphCard
                    adaptiveSize
                    bg="silver"
                    correct={correct}
                    inline
                    title="..."
                  />
                )
            }
          </span>
        </Place>
      </span>
      <span className={punctuationMode
        ? 'bold'
        : ''}
      >
        {afterWord}
      </span>
    </Div>
  )
}

const ParagraphMissingWords = ({
  availableCardsId,
  cards,
  entitiesById,
  image,
  moveCard,
  places,
  punctuationMode,
  words,
}) => (
  <Deck>
    <Div flex flex-1>
      <Div flex flex-3 mr2>
        <Bubble>
          <TextFit reduceFontSize={0.92} focusOnWidth>
            <Div
              flex-1
              wrap
              justify="right"
              px={2}
              mt={punctuationMode ? 3 : 0}
              mb={punctuationMode ? 3 : 0}
              p={1}
              style={{ textAlign: 'left', whiteSpace: 'pre-line' }}
            >
              {
                words.map(({ title, placeId }, i) => {
                  if (placeId) {
                    return renderPlace({
                      cards,
                      moveCard,
                      place: places[placeId],
                      punctuationMode,
                    })
                  }

                  if (title === '\n') {
                    /* eslint-disable-next-line react/no-array-index-key */
                    return <Div key={`newline-${i}`} col={12} />
                  }

                  if ((title || '').trim()) {
                    return (
                      <span
                        className="bold pr1"
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`word-${i}-${title}`}
                      >
                        {title}
                      </span>
                    )
                  }

                  return null
                })
              }
            </Div>
          </TextFit>
        </Bubble>
      </Div>
      <Div flex column flex-2 justify="center">
        {
          image && (
            <Div flex auto justify="center">
              <EntityCard entity={entitiesById[image]} image english transparent />
            </Div>
          )
        }
        <Div>
          <Div flex justify="center" wrap>
            {
              availableCardsId.map(({ id, entityId, title }) => (
                <Div key={id}>
                  {
                    punctuationMode ? (
                      <Card
                        draggable
                        english
                        title={title}
                        fit
                        id={id}
                        minWidth={5}
                      />
                    ) : (
                      <EntityCard
                        draggable
                        english
                        fit
                        entity={entitiesById[entityId]}
                        id={id}
                        size={2}
                      />
                    )}
                </Div>
              ))
            }
          </Div>
        </Div>
      </Div>
    </Div>
  </Deck>
)

ParagraphMissingWords.defaultProps = defaultProps
ParagraphMissingWords.propTypes = propTypes
export default ParagraphMissingWords
