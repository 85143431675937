import React from 'react'
import Div from 'src/components/Div'
import Progress from 'src/components/Progress'
import { string, shape, number, bool } from 'prop-types'

const propTypes = {
  label: string.isRequired,
  progress: shape({
    actual: number,
    total: number,
  }),
  tableRow: bool,
  type: string.isRequired,
}

const defaultProps = {
  progress: null,
  tableRow: false,
}

const ProgressBar = ({ label, type, progress, tableRow }) => {
  if (!progress) return null
  const { actual, total } = progress
  return (
    <Div table-row={tableRow} className="xs-flex-column py1">
      <Div
        table-cell={tableRow}
        style={tableRow && { width: '1px' }}
        className={tableRow && 'py1'}
        h4
        bold
      >
        {label}
      </Div>
      <Div table-cell={tableRow} fx={1} px={2}>
        <Progress actual={actual} total={total} showRatio type={type} />
      </Div>
    </Div>
  )
}

ProgressBar.propTypes = propTypes
ProgressBar.defaultProps = defaultProps

export default ProgressBar
