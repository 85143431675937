const path = '/school/reports/overview'
const fullPath = '/school/reports/:academicYearId/:academicTermId/overview'
const sectionPath = '/school/reports/:academicYearId/:academicTermId/overview/section/:sectionId'
const gradePath = '/school/reports/:academicYearId/:academicTermId/overview/grade/:gradeId'
const matchPaths = [path, fullPath]
const icon = 'poll'
const id = 'overview'

export {
  fullPath,
  icon,
  id,
  matchPaths,
  sectionPath,
  gradePath,
  path,
}
