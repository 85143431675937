import React from 'react'
import { bool, number, string, arrayOf, shape, objectOf } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Div from 'src/components/Div'
import Markdown from 'src/components/Markdown'
import Card from 'src/components/Card'
import AdaptiveCard from 'src/components/AdaptiveCard'

const propTypes = {
  audioOnly: bool.isRequired,
  entitiesById: objectOf(shape(entityShape)).isRequired,
  headerHeight: number.isRequired,
  image: string,
  paragraphIds: arrayOf(string).isRequired,
}
const defaultProps = {
  image: null,
}

const entityText = entity => ({ ...entity.meaning }.titleEn || entity.titleEn)

const StandardHeader = ({
  audioOnly,
  entitiesById,
  headerHeight,
  image,
  paragraphIds,
}) => {
  const renderEntity = entity => (
    <AdaptiveCard
      audio
      english
      align="left"
      key={entity.id}
      play={audioOnly}
      height={headerHeight}
      minHeight={false}
      entity={entity}
    >
      <Markdown source={entityText(entity)} />
    </AdaptiveCard>
  )
  const paragraphs = Math.max(1, paragraphIds ? (paragraphIds.length) : 0)
  const totalCols = 12
  const imgCols = audioOnly ? 8 : 4 / paragraphs
  const restCols = totalCols - (image ? imgCols : 0)
  return (
    <Div flex justify-center>
      {image && (
        <Div flex col={imgCols}>
          <Card image title={image} minHeight={false} />
        </Div>
      )}
      <Div flex col={restCols} flex-1 pre-wrap>
        {paragraphIds && paragraphIds.map(id => entitiesById[id]).map(renderEntity)}
      </Div>
    </Div>
  )
}

StandardHeader.propTypes = propTypes
StandardHeader.defaultProps = defaultProps

export default StandardHeader
