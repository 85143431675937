import React from 'react'
import { string } from 'prop-types'
import { t } from 'i18next'
import { authPath } from 'src/lib/routes'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  icon: string.isRequired,
  provider: string.isRequired,
}

const OAuthButton = ({ provider, icon }) => (
  <div className="mt1">
    <a
      href={authPath(provider)}
      className={`
        btn button-hover rounded bg-white flex white shadow container center bg-${provider}
      `}
    >
      <Icon before icon={icon} className="fab" />
      <Div auto border-left>
        {t(`login.${provider}`)}
      </Div>
    </a>
  </div>
)

OAuthButton.propTypes = propTypes

export default OAuthButton
