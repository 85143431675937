import React, { Component } from 'react'
import { any, bool, func, object, string } from 'prop-types'
import classnames from 'classnames'
import { DropTarget } from 'react-dnd'
import { CARD } from 'src/constants/itemTypes'

const propTypes = {
  children: any,
  className: string,
  connectDropTarget: func.isRequired,
  flex: bool,
  inline: bool,
  isOver: bool.isRequired,
  mx: bool,
  style: object,
}

const defaultProps = {
  children: undefined,
  className: null,
  flex: true,
  inline: false,
  mx: true,
  onDrop: null,
  style: {},
}

const placeTarget = {
  drop(props, monitor) {
    const { id: sourceId } = monitor.getItem()
    const { id: targetId } = props
    props.onDrop({ sourceId, targetId })
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

const renderOverlay = color => (
  <div
    style={{
      backgroundColor: color,
      height: '100%',
      left: 0,
      opacity: 0.5,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    }}
  />
)

const inlineOverStyle = {
  filter: 'invert(1)',
}

class Place extends Component {
  render() {
    const { children, connectDropTarget, inline, isOver, style, className, flex, mx } = this.props
    return connectDropTarget(
      <div
        className={classnames(
          'flex-column flex-1 relative',
          { flex: flex && !inline, mx, inline },
          className,
        )}
        style={{
          transition: 'filter .15s',
          ...((isOver && inline) ? inlineOverStyle : {}),
          ...style,
        }}
      >
        {children}
        {(isOver && !inline) && renderOverlay('yellow')}
      </div>,
    )
  }
}

Place.propTypes = propTypes
Place.defaultProps = defaultProps
export default DropTarget(CARD, placeTarget, collect)(Place)
