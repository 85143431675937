import React from 'react'
import { array, arrayOf, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import { SpellingInLine } from 'src/modules/Word/Spelling'

const propTypes = {
  alphabetEn: array.isRequired,
  entities: arrayOf(
    shape(entityShape),
  ),
  entity: shape(entityShape),
}
const defaultProps = {
  entities: [],
  entity: null,
}

const WordInLineSpelling = ({ alphabetEn, entities, entity }) => {
  const theEntity = entity || entities[0]
  const { id, slug, titleEn } = theEntity
  const src = { ...theEntity.sound }.srcEn
  return (
    <SpellingInLine
      alphabetEn={alphabetEn}
      entity={{ id, slug, src, title: titleEn }}
    />
  )
}

WordInLineSpelling.propTypes = propTypes
WordInLineSpelling.defaultProps = defaultProps

export default WordInLineSpelling
