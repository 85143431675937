import React from 'react'
import { string, func } from 'prop-types'
import AcademicYearTermForm from 'src/pages/SchoolSettings/AcademicYears/AcademicYearTermForm'
import Button from 'src/components/Button'

const propTypes = {
  finishedOn: string,
  id: string.isRequired,
  onTermChange: func.isRequired,
  onTermRemove: func.isRequired,
  startedOn: string,
}

const defaultProps = {
  finishedOn: '',
  startedOn: '',
}

const AcademicTerm = ({
  onTermChange,
  onTermRemove,
  startedOn,
  finishedOn,
  id,
}) => {
  const changeTerm = field => date => onTermChange({ finishedOn, id, startedOn, [field]: date })

  return (
    <div className="academic-term">
      <AcademicYearTermForm
        startDate={startedOn}
        endDate={finishedOn}
        onStartDateChange={changeTerm('startedOn')}
        onEndDateChange={changeTerm('finishedOn')}
        startDateFieldId={`termFrom-${id}`}
        endDateFieldId={`termTo-${id}`}
      >
        <Button submit bg="red" className="align-bottom" onClick={onTermRemove}>
          <i className="fas fa-times" />
        </Button>
      </AcademicYearTermForm>
    </div>
  )
}

AcademicTerm.propTypes = propTypes
AcademicTerm.defaultProps = defaultProps

export default AcademicTerm
