/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { shape, array, func, string, bool } from 'prop-types'
import { Redirect } from 'react-router-dom'

import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'

import DragDropContext from 'src/lib/dragAndDrop'
import { confirmMessage } from 'src/lib/toaster'
import { bindActionCreators, connect } from 'src/lib/redux'

import { subscribeGame, finish, initial, clearSubscribedGames } from 'src/pages/UnitTest/reducer'
import Access from 'src/components/Access'
import Chapter from 'src/pages/preview/Chapter'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import Isolated from 'src/pages/preview/Isolated'
import { LessonBackground } from 'src/components/Layout/BodyBackground'
import Logo from 'src/components/Logo'
import UserPassport from 'src/components/UserPassport'
import { NoCurrentUser } from 'src/pages/CurrentUser'
import ConfirmMessage from 'src/pages/UnitTest/ConfirmMessage'
import WellDone from 'src/pages/UnitTest/WellDone'
import SubmitButton from 'src/pages/UnitTest/SubmitButton'

const propTypes = {
  backgroundComponentLayout: shape(),
  clearSubscribedGames: func.isRequired,
  finish: func.isRequired,
  finished: bool,
  initial: func.isRequired,
  lesson: shape().isRequired,
  lessonSlug: string.isRequired,
  subscribedGames: array,
  subscribeGame: func.isRequired,
}

const defaultProps = {
  backgroundComponentLayout: {},
  finished: false,
  subscribedGames: [],
}

const printPageControl = (index) => {
  if (index % 2 === 1) return 'printBreak'
  return ''
}

const UnitTest = (props) => {
  const history = useHistory()
  const location = useLocation()
  const {
    initial,
    clearSubscribedGames,
    lessonSlug,
    subscribedGames,
    backgroundComponentLayout,
    client,
    finish,
    finished,
    lesson,
    subscribeGame,
    studentUnitTestResult: { rate },
  } = props
  const { chapters } = lesson
  const [allQuestions, setAllQuestions] = useState([])

  const updateAllQuestions = (subscribedGames) => {
    const allQuestions = subscribedGames.map(({ props: { chapterId, isMark } }) => ({ chapterId, isMark }))
    setAllQuestions(allQuestions)
  }

  useEffect(() => {
    initial()

    return () => {
      clearSubscribedGames()
    }
  }, [clearSubscribedGames, initial])

  useEffect(() => {
    updateAllQuestions(subscribedGames)
  }, [subscribedGames])


  useEffect(() => {
    const el = document.getElementById(location.hash.replace('#', ''))
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [location])


  const withConfirm = callback => () => {
    const unmarkQuestiona = filter(allQuestions, { isMark: false })
    const chapters = keyBy(lesson.chapters, 'id')
    const unmarkChapters = unmarkQuestiona.map(({ chapterId }) => ({
      ...chapters[chapterId],
      index: Number(chapters[chapterId].title.split(' ')[1]),
    }))
    const unmarkChaptersOrder = orderBy(unmarkChapters, 'index')
    const options = isEmpty(unmarkChapters) ? { onOk: callback }
      : { okText: 'Close', disableCancel: true }
    confirmMessage({
      message: <ConfirmMessage
        lessonSlug={lessonSlug}
        unmarkChaptersOrder={unmarkChaptersOrder}
        history={history}
      />,
      options,
    })
  }

  const handleSubmit = () => {
    subscribedGames.map(({ props: { mark } }) => mark && mark())
    finish(client)
  }

  return (
    <Div my2 mx-auto col-12 container print-col-12 unit-test>
      <NoCurrentUser>
        <Redirect to="/login" />
      </NoCurrentUser>
      <Access>
        {
          currentUser => (
            <Div flex column px1 printBreak mb3>
              <Div flex justify-start>
                <Logo color height={10} mb={3} className="mt2 print-hide" />
              </Div>
              <UserPassport userId={currentUser.id} />
            </Div>
          )
        }
      </Access>
      <LessonBackground entity={backgroundComponentLayout} noPrint />
      {!rate && chapters.map((chapter, index) => {
        const chapterId = chapter.id
        const { isMark } = { ...keyBy(allQuestions, 'chapterId')[chapterId] }
        return (
          <div
            key={chapterId}
            className={`px1 center clearfix mb2 ${printPageControl(index)}`}
          >
            <Div
              chapter-title
              flex
              justify-start
              border-bottom
              style={{ alignItems: 'center' }}
            >
              {
                isMark ? (
                  <Icon icon="check-square" color="blue" />
                )
                  : (
                    <Icon icon="square" />
                  )
              }
              {chapter.title}
            </Div>
            <Div py2 rounded shadow style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}>
              <div
                className="relative rounded"
                style={{
                  paddingBottom: '3rem',
                  paddingTop: '50%',
                }}
              >
                <Div absolute stretch flex column justify="center">
                  <Isolated chapterId={chapterId}>
                    <Chapter
                      onSubscribe={subscribeGame}
                      lesson={lesson}
                      chapterId={chapterId}
                      navigation={false}
                    />
                  </Isolated>
                </Div>
              </div>
            </Div>
          </div>
        )
      })}
      <Div center my3 noPrint>
        {
          finished || rate
            ? <WellDone subscribedGames={subscribedGames} rate={rate} />
            : <SubmitButton withConfirm={withConfirm(handleSubmit)} />
          }
      </Div>
    </Div>
  )
}

UnitTest.propTypes = propTypes
UnitTest.defaultProps = defaultProps

const actions = dispatch => bindActionCreators(
  { subscribeGame, finish, initial, clearSubscribedGames },
  dispatch,
)
const select = state => state.unitTest

export default DragDropContext(connect(select, actions)(UnitTest))
