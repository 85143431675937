import collectionProvider from 'src/lib/collectionProvider'
import WordInLineSpelling from 'src/modules/Word/WordInLineSpelling/WordInLineSpelling'

const WordInLineSpellingContainer = collectionProvider({
  collections: ['alphabetEn'],
})(WordInLineSpelling)

WordInLineSpellingContainer.demoData = {
  entities: ['family'],
}

export default WordInLineSpellingContainer
