import React from 'react'
import { shape, string, bool } from 'prop-types'
import { QRCode } from 'react-qr-svg'
import Div from 'src/components/Div'
import PersonImg from 'src/components/PersonImg'
import Icon from 'src/components/Icon'
import userShape from 'src/pages/preview/UserCards/userShape'

const Attribute = ({ icon, value, ...rest }) => (value ? (
  <Div {...rest}>
    { icon && <Icon before icon={icon} color="blue" /> }
    <span className="bold">{value}</span>
  </Div>
) : null)

// CR80 card 3.375″ x 2.125″
const WIDTH = 85.6
const HEIGHT = 53.98
const PADDING = 4
const PHOTO_HEIGHT = HEIGHT - (PADDING * 2)
const QR_HEIGHT = 20

const propTypes = {
  teacher: bool,
  user: shape({
    ...userShape,
    year: string,
  }).isRequired,
}

const defaultProps = {
  teacher: false,
}

const UserCard = ({
  user: {
    fullName,
    identifier,
    imageUrl,
    slug: userId,
    year,
  },
  teacher,
}) => (
  <Div
    relative
    flex
    printNoBreak
    bg="white"
    style={{
      width: `${WIDTH}mm`,
      height: `${HEIGHT}mm`,
      padding: `${PADDING}mm`,
      fontSize: '12pt',
    }}
  >
    <Div column auto>
      <Attribute icon={teacher ? 'graduation-cap' : 'user'} value={fullName} className="qa-name" />
      <Div style={{ marginTop: '.2em' }}>
        <Attribute
          className="qa-identifier"
          value={identifier}
          icon="address-card"
          inline-block
          style={{ marginRight: '.4em' }}
        />
        <Attribute
          className="qa-year"
          value={year}
          icon="users"
          inline-block
        />
      </Div>
      <QRCode
        level="Q"
        value={userId}
        style={{ height: `${QR_HEIGHT}mm`, bottom: `${PADDING}mm`, left: `${PADDING}mm` }}
        className="absolute"
      />
    </Div>
    <Div
      style={{
        flex: `0 0 ${PHOTO_HEIGHT * (3 / 4)}mm`,
        height: `${PHOTO_HEIGHT}mm`,
        marginLeft: '1mm',
      }}
    >
      <PersonImg src={imageUrl || undefined} />
    </Div>
  </Div>
)


UserCard.propTypes = propTypes
UserCard.defaultProps = defaultProps

export default UserCard
