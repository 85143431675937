import React from 'react'
import { arrayOf, number, shape } from 'prop-types'
import { t } from 'i18next'

import Grade from 'src/components/Grade'
import Div from 'src/components/Div'
import { percentToGrade } from 'src/lib/rateToColor'

const propTypes = {
  students: arrayOf(
    shape({
      days: number.isRequired,
      present: number.isRequired,
    }).isRequired
  ).isRequired,
}

const YearSummary = ({ students }) => {
  const { totalDays, totalPresent } = students.reduce(
    ({ totalDays, totalPresent }, { days, present }) => ({
      totalDays: totalDays + days,
      totalPresent: totalPresent + present,
    }),
    { totalDays: 0, totalPresent: 0 }
  )
  const attendanceRate = totalDays > 0 ? totalPresent / totalDays : 0

  return (
    <td colSpan="100%">
      <Div flex align-center>
        <span className="mr1 qa-students-count">
          {t('attendance.report.students')}:
          <span className="bold">&nbsp;{students.length}</span>
        </span>
        <Grade
          grade={percentToGrade(attendanceRate)}
          percentage={attendanceRate}
        />
      </Div>
    </td>
  )
}

YearSummary.propTypes = propTypes

export default YearSummary
