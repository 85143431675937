import React from 'react'
import sortBy from 'lodash/sortBy'
import { compose } from 'src/lib/redux'
import gqlLoader from 'src/lib/gqlLoader'
import { add, subtract, endOf, startOf, dateToday } from 'src/lib/dates'
import academicYearsQuery from 'src/queries/academicYearsQuery.gql'
import calendarEventsQuery from 'src/pages/CalendarPage/calendarEvents.gql'

const yearDataToProps = ({
  yearId,
  academicYears,
  ...rest
}) => {
  const currentYear = !yearId
  const year = (
    // TODO redirect
    academicYears.find(
      currentYear
        ? ({ current }) => current
        : ({ id, slug }) => [id, slug].includes(yearId),
    ) || sortBy(academicYears, 'finishedOn').reverse()[0]
  )
  const { academicTerms = [] } = year || {}
  const [firstTerm] = sortBy(academicTerms, 'startedOn')
  const [lastTerm] = sortBy(academicTerms, 'finishedOn').reverse()
  const { startedOn } = firstTerm || year || {}
  const { finishedOn } = lastTerm || year || {}
  const startDate = startOf(startedOn || startOf(dateToday(), 'year'), 'month')
  // display at least one year from the first month
  const yearFromStart = subtract(add(startDate, 1, 'year'), 1, 'day')
  const endDateUnclamped = finishedOn > yearFromStart ? finishedOn : yearFromStart
  // include full last month
  const endDate = endOf(endDateUnclamped, 'month')
  return {
    academicYears,
    endDate,
    startDate,
    year,
    ...rest,
  }
}

const loadYear = Component => gqlLoader(academicYearsQuery)(
  yearData => <Component {...yearDataToProps(yearData)} />,
)

export {
  yearDataToProps,
}

export default compose(
  loadYear,
  gqlLoader(calendarEventsQuery),
)
