import React from 'react'
import classnames from 'classnames'
import { bool, shape, string } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  className: string,
  cover: bool,
  position: string,
  src: string,
  stretch: bool,
  style: shape(),
}

const defaultProps = {
  className: '',
  cover: false,
  position: 'center',
  src: undefined,
  style: {},
}

const backgroundImage = ({ src, position, cover }) => ({
  backgroundImage: `url("${src}")`,
  backgroundPosition: position,
  backgroundSize: `${cover && 'cover'}`,
})

const Img = ({
  className,
  cover,
  position,
  src,
  style,
  ...rest
}) => {
  const theStyle = {
    ...backgroundImage({ cover, position, src }),
    ...style,
  }
  return (
    <Div
      style={theStyle}
      className={classnames('image', 'flex-1', 'stretch', className)}
      {...rest}
    />
  )
}

Img.propTypes = propTypes
Img.defaultProps = defaultProps

export default Img
