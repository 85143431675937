import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Lesson from 'src/pages/Lesson'
import LessonsRedirect from 'src/pages/LessonsLayout/LessonsRedirect'

export const path = '/lesson'

const LessonRoute = () => (
  <Switch>
    <Route exact path="/lesson">
      <LessonsRedirect />
    </Route>
    <Route path="/lesson/:lessonSlug/:chapters?/:chapterIndex?/:slides?/:slideIndex?">
      <Lesson />
    </Route>
  </Switch>
)

export default LessonRoute
