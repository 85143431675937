import React from 'react'
import { array, string } from 'prop-types'
import AttendanceYear from 'src/pages/AttendancePage/AttendanceToday/AttendanceYear'
import Div from 'src/components/Div'

const propTypes = {
  selectedYear: string,
  title: string.isRequired,
  years: array.isRequired,
}

const defaultProps = {
  selectedYear: undefined,
}

const Grade = ({ selectedYear, title, years }) => (
  <div>
    <div className="h3">
      {title}
    </div>
    <Div flex flex-wrap gutter>
      {years.map(year => (
        <div key={year.id}>
          <AttendanceYear selected={year.id === selectedYear} {...year} />
        </div>
      ))}
    </Div>
  </div>
)

Grade.propTypes = propTypes
Grade.defaultProps = defaultProps

export default Grade
