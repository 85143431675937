import { arrayOf, shape, string } from 'prop-types'
import localizedField from 'src/shapes/localizedField'
import standardShape from 'src/shapes/standard'
import unitShape from 'src/shapes/unit'

const subjectShape = {
  id: string,
  identifier: string,
  slug: string,
  strands: arrayOf(shape(standardShape)),
  title: string,
  titleRaw: shape(localizedField),
  units: arrayOf(shape(unitShape)),
}

export default subjectShape
