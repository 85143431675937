import React, { Fragment } from 'react'
import { useParams, useHistory } from 'react-router'
import { linkTo } from 'src/lib/linkTo'
import Div from 'src/components/Div'
import SelectedLabel from 'src/pages/PortalLayout/SelectedLabel'
import SelectChildren from 'src/pages/PortalLayout/SelectChildren'
import UserPassport from 'src/components/UserPassport'
import RedirectToFirstChild from 'src/pages/PortalLayout/RedirectToFirstChild'
import StudentBar from 'src/pages/Students/StudentBar'

const StudentPage = ({ children, path }) => {
  const history = useHistory()
  const { domain, studentId, parentId, page, subjectId } = useParams()
  if (!studentId) {
    return (
      <RedirectToFirstChild path={path} domain={domain} parentId={parentId} />
    )
  }
  return (
    <Fragment>
      <Div flex relative>
        <SelectedLabel studentId={studentId} rounded-top />
        <SelectChildren
          domain={domain}
          parentId={parentId}
          value={studentId}
          onChange={(studentId) =>
            history.push(
              linkTo(path)({
                domain,
                studentId,
                parentId,
                page,
                subjectId,
              })
            )
          }
        />
      </Div>
      <Div bg-white rounded-bottom border-top rounded pt1 px1 mb1>
        <StudentBar studentId={studentId} />
      </Div>
      <Div mb1>
        <UserPassport userId={studentId} />
      </Div>
      {React.cloneElement(children, { userId: studentId })}
    </Fragment>
  )
}

export default StudentPage
