const attendancePath = () => '/attendance'
const attendanceWeeklyPath = ({
  week,
  year,
} = {}) => [
  '/attendance/weekly',
  year && `${year}`,
  week && `${week}`,
].filter(x => x).join('/')

export {
  attendancePath,
  attendanceWeeklyPath,
}
