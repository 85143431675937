import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ProvidePhrase/reducer'
import ProvideAnswer from 'src/modules/ProvidePhrase/ProvideAnswer/ProvideAnswer'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
  speaker: false,
}

class ProvideAnswerContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      mark, retry, completed, actualEntities,
      availableCardsId, cards, entitiesById, moveCard, places,
      answerEntity, choiceEntities, questionEntity, speakerEntity, started, speaker,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ProvideAnswer {...{
          answerEntity,
          availableCardsId,
          cards,
          choiceEntities,
          entitiesById,
          moveCard,
          places,
          questionEntity,
          speaker,
          speakerEntity,
          started,
        }}
        />
      </Game>
    )
  }
}

const selector = state => state.providePhrase
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const ProvideAnswerWithSpeaker = props => <ProvideAnswerContainer speaker {...props} />

export const ProvideAnswerWithSpeakerContainer =
  connect(selector, actions)(ProvideAnswerWithSpeaker)

ProvideAnswerWithSpeakerContainer.demoData = {
  entities: [
    'younger-sister',
    'phrase-who-is-he',
    'phrase-he-is-my-elder-brother',
    'phrase-who-is-she',
    'phrase-she-is-my-elder-sister',
    'phrase-who-are-they',
    'phrase-they-are-my-parents',
  ],
}

ProvideAnswerContainer.propTypes = propTypes
ProvideAnswerContainer.defaultProps = defaultProps
ProvideAnswerContainer.demoData = {
  entities: [
    'phrase-who-is-he',
    'phrase-he-is-my-elder-brother',
    'phrase-who-is-she',
    'phrase-she-is-my-elder-sister',
    'phrase-who-are-they',
    'phrase-they-are-my-parents',
  ],
}
export default connect(selector, actions)(ProvideAnswerContainer)
