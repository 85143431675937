import React from 'react'
import { bool, number, object } from 'prop-types'

import Card from 'src/components/Card'
import EntityCard from 'src/components/EntityCard'

const propTypes = {
  adaptiveSize: bool,
  entity: object,
  minWidth: number,
  paddingLeft: number,
  paddingRight: number,
}

const defaultProps = {
  adaptiveSize: false,
  entity: null,
  minWidth: 6,
  paddingLeft: 0.5,
  paddingRight: 0.5,
}

const ParagraphCard = ({
  adaptiveSize,
  entity,
  minWidth,
  paddingLeft,
  paddingRight,
  ...rest
}) => (
  entity ? (
    <EntityCard
      adaptiveSize={adaptiveSize}
      className="mb"
      entity={entity}
      m={false}
      minHeight={false}
      minWidth={minWidth}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      {...rest}
    />
  )
    : (
      <Card
        adaptiveSize={adaptiveSize}
        className="mb"
        fit
        m={false}
        minHeight={false}
        minWidth={minWidth}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        {...rest}
      />
    )
)

ParagraphCard.defaultProps = defaultProps
ParagraphCard.propTypes = propTypes
export default ParagraphCard
