import React from 'react'
import { number, oneOfType, string } from 'prop-types'
import { t } from 'i18next'
import round from 'lodash/round'

import Icon from 'src/components/Icon'
import Tooltip from 'src/components/Tooltip'
import Div from 'src/components/Div'

const propTypes = {
  tries: oneOfType([string, number]).isRequired,
}

const TimesTries = ({ tries }) => (
  <Tooltip
    tips={[
      t('progress.totalTries'),
    ]}
    vertical
  >
    <Div bold align-center flex style={{ width: '4em' }}>
      <Icon icon="times" />
      <span className="h4">
        {round(tries, 1)}
      </span>
    </Div>
  </Tooltip>
)

TimesTries.propTypes = propTypes
export default TimesTries
