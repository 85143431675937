import debounce from 'redux-debounced'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from 'src/reducers'

const initialState = {}

const middlewares = [debounce(), thunk]

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require
  const { logger } = require('redux-logger')

  middlewares.push(logger)
}

const configureStore = () => {
  const store = createStore(rootReducer, initialState, composeWithDevTools(
    applyMiddleware(...middlewares),
  ))

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('src/reducers', () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('src/reducers')
      store.replaceReducer(nextReducer)
    })
  }
  return store
}

export default configureStore()
