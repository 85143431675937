import every from 'lodash/every'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import slice from 'lodash/slice'
import size from 'lodash/size'
import reduce from 'lodash/reduce'

import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'

const INIT = 'LabelImage/INIT'
const MOVE = 'LabelImage/MOVE'
const MARK = 'LabelImage/MARK'
const RETRY = 'LabelImage/RETRY'

const initialState = {
  actualEntities: [],
  availableCardsId: [],
  cards: {},
  completed: null,
  entitiesById: {},
  numberOfPlaces: 0,
  places: {},
}

const createCards = (a, entity) => {
  const id = `card-${size(a)}`
  return {
    ...a,
    [id]: {
      correct: undefined,
      entityId: entity.id,
      id,
      placeId: null,
    },
  }
}

const createPlaces = (a, entity) => {
  const id = `place-${size(a)}`
  const label = size(a) + 1
  return {
    ...a,
    [id]: {
      cardId: null,
      entityId: entity.id,
      id,
      label,
    },
  }
}

const checkPlaceState = cards => (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.cardId
        ? cards[place.cardId].entityId === place.entityId
        : false,
    },
  }
)

const findAvailableCards = cards => (
  map(
    map(cards).filter(({ placeId }) => !placeId),
    'id',
  )
)

const setPlaceStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.correct === false ? null : place.correct },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { src } = { ...payload.entities[0].image }
      const entities = slice(payload.entities, 1)
      const numberOfPlaces = entities.length
      const cards = uniqShuffle(entities).reduce(createCards, {})
      const places = entities.reduce(createPlaces, {})
      return {
        ...initialState,
        actualEntities: entities,
        availableCardsId: map(cards, 'id'),
        cards,
        cover: src,
        entitiesById: keyBy(entities, 'id'),
        numberOfPlaces,
        places,
      }
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      const newPlaces = reduce(places, setPlaceStatus, {})
      const newState = {
        availableCardsId: findAvailableCards(cards),
        cards,
        places: newPlaces,
      }
      return {
        ...state,
        ...newState,
      }
    }
    case MARK: {
      const { cards, places } = state
      const checkedPlaces = reduce(places, checkPlaceState(cards), {})
      const completed = every(checkedPlaces, 'correct')
      const newState = {
        availableCardsId: findAvailableCards(cards),
        completed,
        places: checkedPlaces,
      }
      return {
        ...state,
        ...newState,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

export {
  init,
  moveCard,
  mark,
  retry,
}

export default reducer
