import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import { ENGLISH } from 'src/constants/language'
import * as gameAction from 'src/modules/MultipleChoice/reducer'
import MultipleChoice from 'src/modules/MultipleChoice/MultipleChoice'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  audioOnly: false,
  language: ENGLISH,
  silent: false,
}

class MultipleChoiceCore extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init()
  }

  render() {
    const { mark, retry, completed, entitiesById, actualEntities, silent,
      answerId, muted, selectCard, selected, questionId, cards, language,
      paragraph, paragraphEntity, audioOnly, listen } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <MultipleChoice {...{
          answerId,
          audioOnly,
          cards,
          entitiesById,
          language,
          listen,
          muted,
          paragraph,
          paragraphEntity,
          questionId,
          selectCard,
          selected,
          silent,
        }}
        />
      </Game>
    )
  }
}
MultipleChoiceCore.propTypes = propTypes
MultipleChoiceCore.defaultProps = defaultProps
MultipleChoiceCore.demoData = {
  entities: [
    'phrase-who-is-she',
    'phrase-she-is-mary',
    'phrase-who-is-he',
    'phrase-he-is-tom',
    'phrase-what-is-it',
    'phrase-it-is-a-banana',
    'phrase-what-is-it',
    'phrase-it-is-a-mango',
  ],
}
const selector = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameAction, dispatch)

export { MultipleChoiceCore }
export default connect(selector, actions)(MultipleChoiceCore)
