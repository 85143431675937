import React from 'react'
import { number, shape, bool, func } from 'prop-types'
import ObjectTarget from 'src/components/ObjectTarget'
import circleTarget from 'src/components/ObjectTarget/images/targets/CircleTarget.svg'
import True from 'src/components/ObjectTarget/images/effects/True.svg'
import False from 'src/components/ObjectTarget/images/effects/False.svg'

const propTypes = {
  animation: bool,
  cursive: bool,
  entity: shape(),
  height: number,
  mixFont: bool,
  onClick: func,
  selected: bool,
  text: bool,
  width: number,
}

const defaultProps = {
  cursive: null,
  mixFont: null,
  text: null,
}

const entityImageStyle = {
  paddingBottom: '3rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  paddingTop: '3rem',
}

const CircleTarget = props => (
  <ObjectTarget
    effectImage={True}
    entityImageStyle={entityImageStyle}
    secondEffectImage={False}
    targetImage={circleTarget}
    {...props}
  />
)

CircleTarget.defaultProps = defaultProps
CircleTarget.propTypes = propTypes

export default CircleTarget
