import reject from 'lodash/reject'
import * as roles from 'src/constants/roles'
import { scene as lessonsScene } from 'src/pages/LessonsLayout/config'
import { scene as gradingScene } from 'src/pages/Grading/config'
import { scene as calendarScene } from 'src/pages/CalendarPage/config'
import { scene as attendanceScene } from 'src/pages/AttendancePage/config'
import { scene as leaderBoardScene } from 'src/pages/DashboardPage/StudentsLeaderboard/config'
import { scene as teacherGuidesScene } from 'src/pages/TeacherGuides/config'
import { teacherScene as usersScene } from 'src/pages/Users/config'
import { scene as previewModulesScene } from 'src/pages/preview/Modules/config'
import { teacherScene as curriculumScene } from 'src/pages/Curriculum/config'
import { scene as assessmentScene } from 'src/pages/TeacherLayout/AssessmentPage/config'

const teacherItems = [
  lessonsScene,
  gradingScene,
  leaderBoardScene,
  previewModulesScene,
  calendarScene,
  curriculumScene,
  usersScene,
  assessmentScene,
  teacherGuidesScene,
  attendanceScene,
]

const teacherSection = role => (
  (role === roles.TEACHER_ROLE) ? teacherItems : reject(teacherItems, 'common')
)

export default teacherSection
