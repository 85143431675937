import React from 'react'
import { func, shape, bool } from 'prop-types'
import map from 'lodash/map'
import Card from 'src/components/Card'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import EntityCard from 'src/components/EntityCard'
import Line from 'src/modules/ListenAndOrderSentences/Line'

const propTypes = {
  autoPlay: bool.isRequired,
  cards: shape().isRequired,
  completed: bool,
  lines: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const defaultProps = {
  completed: null,
}

const ListenAndOrderSentences = ({
  autoPlay,
  cards,
  lines,
  moveCard,
  places,
  completed,
}) => (
  <Deck>
    { completed
      ? map(lines, ({ entity, id, title }) => [
        <Div key={id} flex flex-auto>
          <EntityCard image audio english entity={entity} />
        </Div>,
        <div key={title}>
          <Card title={title} bold />
        </div>,
      ])
      : map(lines, ({ entity, line }, key) => (
        <Line {...{ autoPlay, cards, entity, key, line, moveCard, places }} />
      ))
    }
  </Deck>
)

ListenAndOrderSentences.propTypes = propTypes
ListenAndOrderSentences.defaultProps = defaultProps

export default ListenAndOrderSentences
