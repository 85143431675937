import React, { Component } from 'react'
import { bool, element, object, func, string, shape, arrayOf, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import { withApollo } from '@apollo/react-hoc'

import { isMobileOrNative } from 'src/lib/window'
import * as homeworkActions from 'src/pages/Homework/reducer'
import * as layoutActions from 'src/components/Layout/reducer'
import CompletableGame from 'src/components/Game/CompletableGame'
import gamePropsShape from 'src/shapes/Game'
import KeyPress from 'src/components/KeyPress'

const propTypes = {
  chapterId: string,
  children: element.isRequired,
  client: object.isRequired,
  completed: bool,
  disableMarkButton: func.isRequired,
  enableMarkButton: func.isRequired,
  gameProps: shape(gamePropsShape).isRequired,
  hideAndClearSoftKeyboardInputs: func.isRequired,
  homework: bool,
  homeworkIterations: arrayOf(bool).isRequired,
  isShowInstruction: bool.isRequired,
  iterationDone: func.isRequired,
  mark: func,
  moduleId: string.isRequired,
  nextChapter: func.isRequired,
  setHomework: func.isRequired,
  storeChapterAnswer: func.isRequired,
  storeHomeworkResults: func.isRequired,
  totalIterations: number.isRequired,
  userId: string,
}

const defaultProps = {
  chapterId: null,
  completed: null,
  homework: false,
  mark: null,
  userId: null,
}

class GameWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animation: false,
    }
  }

  componentDidMount() {
    const { setHomework, moduleId } = this.props
    setTimeout(() => setHomework(moduleId))
  }

  componentDidUpdate(prevProps) {
    const {
      chapterId,
      client,
      completed,
      gameProps: { answer, entities, actualEntities },
      hideAndClearSoftKeyboardInputs,
      homework,
      iterationDone,
      storeHomeworkResults,
      userId,
    } = this.props
    if (completed !== prevProps.completed) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ animation: completed === true || completed === false })
    }
    if (
      (completed === true || completed === false)
      && completed !== prevProps.completed
    ) {
      const iteration = {
        entities: (actualEntities || []).map(({ slug }) => slug),
        correct: completed,
        answer,
      }
      iterationDone(completed, iteration)
      if (isMobileOrNative()) hideAndClearSoftKeyboardInputs()
      if (homework && userId) {
        storeHomeworkResults({ actualEntities, answer, chapterId, completed, entities }, client)
      }
    }
  }

  isAllIterationsDone() {
    const { homeworkIterations, totalIterations } = this.props
    const iterationsDone = homeworkIterations.filter(iteration => (iteration === true || iteration === false))
    return totalIterations === iterationsDone.length
  }

  handleAfterMark() {
    const {
      chapterId,
      client,
      homework,
      setHomework,
      moduleId,
      nextChapter,
      storeChapterAnswer,
      gameProps: {
        init,
        entities,
        answer,
        ...restProps
      },
      enableMarkButton,
    } = this.props
    enableMarkButton()
    if (this.isAllIterationsDone()) {
      if (homework) {
        // TODO move logic to actions/reducer
        storeChapterAnswer({ answer, chapterId }, client)
        nextChapter()
      } else {
        setHomework(moduleId)
        init(restProps)
      }
    } else {
      init(restProps)
    }
  }

  handleEnterKeyPress() {
    const { completed, mark, disableMarkButton, isShowInstruction } = this.props
    if (!isShowInstruction) {
      if (completed === null && mark) {
        mark()
        disableMarkButton()
      }
    }
  }

  render() {
    const { children } = this.props
    const { animation } = this.state
    return React.Children.map(children, (child) => {
      if (child.type === CompletableGame) {
        const clonedChildren = React.Children.map(child.props.children, (CompletableGameChild) => {
          if (CompletableGameChild.type === KeyPress) {
            return React.cloneElement(CompletableGameChild, {
              keys: { Enter: () => this.handleEnterKeyPress() },
            })
          }
          return CompletableGameChild
        })
        return React.cloneElement(child, {
          animation,
          children: clonedChildren,
          onClick: () => this.handleAfterMark(),
        })
      }
      return child
    })
  }
}

GameWrapper.propTypes = propTypes
GameWrapper.defaultProps = defaultProps

const actions = dispatch => bindActionCreators({ ...homeworkActions, ...layoutActions }, dispatch)
const select = state => ({ ...state.homework, ...state.layout })
export default connect(select, actions)(withApollo(GameWrapper))
