import React, { useContext } from 'react'
import { string, number } from 'prop-types'
import { Link } from 'react-router-dom'
import { attendanceStudentReportPath } from 'src/lib/routes/paths'
import DomainContext from 'src/contexts/DomainContext'

const propTypes = {
  absent: number.isRequired,
  attendance: string.isRequired,
  days: number.isRequired,
  description: string.isRequired,
  grade: number.isRequired,
  identifier: string.isRequired,
  name: string.isRequired,
  present: number.isRequired,
  slug: string.isRequired,
}

const StudentReport = ({
  absent,
  attendance,
  days,
  description,
  grade,
  identifier,
  name,
  present,
  slug,
}) => {
  const domain = useContext(DomainContext)
  return (
    <tr>
      <td>
        <div className="pl2 qa-attendance-report-student-name">
          <Link to={attendanceStudentReportPath(domain, slug)}>
            {name}
          </Link>
        </div>
      </td>
      <td>
        {identifier}
      </td>
      <td>{days}</td>
      <td>{present}</td>
      <td>{absent}</td>
      <td>{attendance}</td>
      <td>{grade}</td>
      <td>{description}</td>
    </tr>
  )
}

StudentReport.propTypes = propTypes

export default StudentReport
