import { ATTENDANCE, SCHOOL_ATTENDANCE } from 'src/constants/menu'
import icons from 'src/lib/menu/icons'
import { path } from 'src/pages/AttendancePage/paths'

export { path }

export const scene = {
  icon: icons[ATTENDANCE],
  id: SCHOOL_ATTENDANCE,
  path,
}

export default {
  scene,
}
