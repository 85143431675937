import React from 'react'
import { shape } from 'prop-types'
import Markdown from 'react-markdown'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import Div from 'src/components/Div'
import TextFit from 'src/components/TextFit'

const propTypes = {
  lesson: shape().isRequired,
}

const uniqById = array => uniqBy(array, 'id')
const mapUniqById = (array, selector) => orderBy(uniqBy(map(array, selector), 'id'), 'identifier')
const titles = array => array.map(i => `\n* **${i.identifier}** ${i.title}`).join('')

const destructure = (lesson) => {
  const indicators = uniqById(lesson.indicators)
  const standards = mapUniqById(indicators, 'standard')
  const strands = mapUniqById(standards, 'strand')
  const subjects = mapUniqById(strands, 'subject')
  return ({
    indicators,
    lesson,
    standards,
    strands,
    subjects,
  })
}

const MarkdownDownBlock = ({ source, mb, title, className }) => (
  <Div className={className} flex auto column bg="white" p={2} rounded left mb={mb}>
    {
      title && (
        <h3>{title}</h3>
      )
    }
    <AdaptiveMarkDown source={source} />
  </Div>
)

const AdaptiveMarkDown = ({ source }) => (
  <TextFit align="left" justify="left" reduceFontSize={0.95}>
    <Markdown source={source} />
  </TextFit>
)
const CurriculumMapping = ({
  lesson,
}) => {
  const {
    indicators,
    standards,
    strands,
    subjects,
  } = destructure(lesson)
  const title = subjects[0] ? subjects[0].title : lesson.title
  const standardsText = titles(standards) ? titles(standards) : 'No standards found'
  const indicatorsText = titles(indicators) ? titles(indicators) : 'No standards found'
  return (
    <Div flex auto>
      <Div flex auto column col-6 px>
        <MarkdownDownBlock source={`${titles(strands)}`} title={title} mb className="flex-col-4" />
        <MarkdownDownBlock source={standardsText} className="flex-col-8" />
      </Div>
      <Div flex auto column col-6>
        <MarkdownDownBlock source={indicatorsText} />
      </Div>
    </Div>
  )
}

CurriculumMapping.propTypes = propTypes

export default CurriculumMapping
