import React from 'react'
import Div from 'src/components/Div'
import { print } from 'src/lib/window'
import { t } from 'i18next'

const handleClick = (e) => {
  e.preventDefault()
  print()
}

const PrintInstructions = () => (
  <Div print-hide py={3} container>
    <Div flex wrap gutter-1 justify-center mb2>
      <Div col-6 md-col-4>
        <img
          className="rounded"
          alt={`${t('guide.printInstructionImage1')} 1`}
          src={t('guide.printInstructionImage1')}
        />
      </Div>
      <Div col-6 md-col-4>
        <img
          className="rounded"
          alt={`${t('guide.printInstructionImage2')} 2`}
          src={t('guide.printInstructionImage2')}
        />
      </Div>
      <Div col-6 md-col-4>
        <img
          className="rounded"
          alt={`${t('guide.printInstructionImage3')} 3`}
          src={t('guide.printInstructionImage3')}
        />
      </Div>
    </Div>
    <Div center>
      <button
        className="btn btn-block btn-primary p3 h4"
        onClick={e => handleClick(e)}
      >
        {t('guide.printButton')}
      </button>
    </Div>
  </Div>
)

export default PrintInstructions
