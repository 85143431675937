import { combineReducers } from 'redux'
import { reducer as toastr } from 'react-redux-toastr'
import apolloClient from 'src/lib/apolloClient/reducer'
import homework from 'src/pages/Homework/reducer'
import layout from 'src/components/Layout/reducer'
import layoutNetwork from 'src/pages/Layout/reducer'
import unitTest from 'src/pages/UnitTest/reducer'
import matchCards from 'src/modules/MatchCards'
import modal from 'src/pages/CalendarPage/reducer'
import { phonicsReducer as phonics, spellingReducer as spelling } from 'src/modules/Word'
import ageComparison from 'src/modules/AgeComparison/reducer'
import barChart from 'src/modules/BarChart/reducer'
import blendingSounds from 'src/modules/BlendingSounds/reducer'
import comicStripDragAndDrop from 'src/modules/ComicStripDragAndDrop/reducer'
import comicStripFillInTheBlank from 'src/modules/ComicStripFillInTheBlank/reducer'
import drawLetters from 'src/modules/DrawLetters/reducer'
import familyTreeShuffled from 'src/modules/FamilyTreeShuffled/reducer'
import familyTreeSlideshow from 'src/modules/FamilyTreeSlideshow/reducer'
import fillInMissingLetters from 'src/modules/FillInMissingLetters/reducer'
import fillInMissingWords from 'src/modules/FillInMissingWords/reducer'
import fillInMissingWordType from 'src/modules/FillInMissingWordType/reducer'
import followTheInstructions from 'src/modules/FollowTheInstructions/reducer'
import hangman from 'src/modules/Hangman/reducer'
import iterationIcon from 'src/components/IterationIcon/reducer'
import labelImage from 'src/modules/LabelImage/reducer'
import listenAndSpeak from 'src/modules/ListenAndSpeak/reducer'
import listenAndOrderSentences from 'src/modules/ListenAndOrderSentences/reducer'
import listenAndSelect from 'src/modules/ListenAndSelect/reducer'
import listeningPhonemes from 'src/modules/ListeningPhonemes/reducer'
import listeningWords from 'src/modules/ListeningWords/reducer'
import listenAndTypeSentences from 'src/modules/ListenAndTypeSentences/reducer'
import listenAndTypeWords from 'src/modules/ListenAndTypeWords/reducer'
import matchImageWithText from 'src/modules/MatchImageWithText/reducer'
import matchTextWithText from 'src/modules/MatchTextWithText/reducer'
import matchTextWithImage from 'src/modules/MatchTextWithImage/reducer'
import matchTranslation from 'src/modules/MatchTranslation/reducer'
import multipleChoice from 'src/modules/MultipleChoice/reducer'
import multipleChoiceMissingWordsAudio from 'src/modules/MultipleChoiceMissingWordsAudio/reducer'
import multipleChoiceMultipleParagraphs from 'src/modules/MultipleChoiceMultipleParagraphs/reducer'
import oddOneOut from 'src/modules/OddOneOut/reducer'
import orderingSentences from 'src/modules/OrderingSentences/reducer'
import paragraphMissingWords from 'src/modules/ParagraphMissingWords/reducer'
import partsOfSpeech from 'src/modules/PartsOfSpeech/reducer'
import phonicBlends from 'src/modules/PhonicBlends/reducer'
import providePhrase from 'src/modules/ProvidePhrase/reducer'
import selectParagraphTitle from 'src/modules/SelectParagraphTitle/reducer'
import softKeyboard from 'src/components/SoftKeyboard/reducer'
import sorting from 'src/modules/Sorting/reducer'
import sounds from 'src/lib/sounds/reducer'
import speechBubblesQuestionAnswer from 'src/modules/SpeechBubblesQuestionAndAnswer/reducer'
import speechRecognition from 'src/modules/SpeechRecognition/reducer'
import selectType from 'src/modules/SelectType/reducer'
import toneMarker from 'src/modules/ToneMarker/reducer'
import typeAndSave from 'src/modules/TypeAndSave/reducer'
import unscrambleWords from 'src/modules/UnscrambleWords/reducer'
import tenantSettings from 'src/components/TenantSettings/reducer'

export default combineReducers({
  ageComparison,
  apolloClient,
  barChart,
  blendingSounds,
  comicStripDragAndDrop,
  comicStripFillInTheBlank,
  drawLetters,
  familyTreeShuffled,
  familyTreeSlideshow,
  fillInMissingLetters,
  fillInMissingWords,
  fillInMissingWordType,
  followTheInstructions,
  hangman,
  homework,
  iterationIcon,
  labelImage,
  layout,
  layoutNetwork,
  listenAndOrderSentences,
  listenAndSelect,
  listenAndSpeak,
  listenAndTypeSentences,
  listenAndTypeWords,
  listeningPhonemes,
  listeningWords,
  matchCards,
  matchImageWithText,
  matchTextWithImage,
  matchTextWithText,
  matchTranslation,
  modal,
  multipleChoice,
  multipleChoiceMissingWordsAudio,
  multipleChoiceMultipleParagraphs,
  oddOneOut,
  orderingSentences,
  paragraphMissingWords,
  partsOfSpeech,
  phonicBlends,
  phonics,
  providePhrase,
  selectParagraphTitle,
  selectType,
  softKeyboard,
  sorting,
  sounds,
  speechBubblesQuestionAnswer,
  speechRecognition,
  spelling,
  tenantSettings,
  toastr,
  toneMarker,
  typeAndSave,
  unitTest,
  unscrambleWords,
})
