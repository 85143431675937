import { arrayOf, shape, string } from 'prop-types'
import localizedField from 'src/shapes/localizedField'
import gradeShape from 'src/shapes/grade'
import standardShape from 'src/shapes/standard'
import subjectShape from 'src/shapes/subject'

const strandShape = {
  code: string,
  grade: shape(gradeShape),
  id: string,
  identifier: string,
  standards: arrayOf(shape(standardShape)),
  subject: shape(subjectShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default strandShape
