import React from 'react'
import { array, func, object } from 'prop-types'
import Div from 'src/components/Div'

import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import EntityCard from 'src/components/EntityCard'
import Grandparents from 'src/modules/FamilyTreeShuffled/Grandparents'
import Children from 'src/modules/FamilyTreeShuffled/Children'
import FamilyPlace from 'src/modules/FamilyTreeShuffled/FamilyPlace'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  placeLocations: array.isRequired,
  places: object.isRequired,
}

const SEPARATORS = [
  Grandparents,
  Children,
]

const FamilyTreeShuffled = ({
  cards,
  entitiesById,
  placeLocations,
  places,
  moveCard,
}) => {
  const availableCards = map(cards).filter(({ placeId }) => !placeId)
  const { length } = availableCards
  return (
    <Div flex>
      <Div col={12} justify="start">
        {
          !!length && (
          <Div flex wrap justify="center">
            {
                map(cards, ({ id, placeId }) => !placeId && (
                  <Div col={1} key={id}>
                    <EntityCard
                      audio
                      draggable
                      english
                      entity={entitiesById[id]}
                      fit
                      id={id}
                      image
                      key={id}
                      style={{ height: '6rem' }}
                    />
                  </Div>
                ))
              }
          </Div>
          )}
        <Div wrap justify="center">
          {
            flatMap(placeLocations, (row, i) => {
              const Separator = SEPARATORS[i]
              return [
                <Div flex key={i} justify="center">
                  {row.map(id => (
                    <FamilyPlace
                      key={id}
                      {...{
                        cards,
                        entitiesById,
                        moveCard,
                        place: places[id],
                        places,
                      }}
                    />
                  ))}
                </Div>,
                Separator ? (
                  <div
                    key={`separator-${i}`}
                    className="mtn1 mbn1"
                    style={{ height: '3rem' }}
                  >
                    <Separator />
                  </div>
                ) : null,
              ]
            })
          }
        </Div>
      </Div>
    </Div>
  )
}

FamilyTreeShuffled.propTypes = propTypes

export default FamilyTreeShuffled
