import pluck from 'src/lib/pluck'

const parseErrors = (errors) => {
  // eslint-disable-next-line no-console
  console.trace(); console.log(errors)
  const message = pluck(errors, 'graphQLErrors.0.message')
  return message ? JSON.parse(message) : {}
}

export default parseErrors
