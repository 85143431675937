import React from 'react'
import { string } from 'prop-types'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import Access from 'src/components/Access'
import { homeworkPath } from 'src/lib/routes'

const propTypes = {
  lessonSlug: string.isRequired,
}

const LessonsPageLessonsHomeworkLink = ({ lessonSlug }) => (
  <Access>
    <Link
      className="btn h6 btn-outline right"
      id={`qa-homework-${lessonSlug}`}
      to={homeworkPath(lessonSlug)}
    >
      {t('home.homework')}
    </Link>
  </Access>
)

LessonsPageLessonsHomeworkLink.propTypes = propTypes

export default LessonsPageLessonsHomeworkLink
