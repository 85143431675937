import TrueOrFalseParagraph, {
  TrueOrFalseParagraphs,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
} from 'src/modules/SelectType/TrueOrFalse/TrueOrFalseParagraphContainer'
import TrueOrFalseImage, {
} from 'src/modules/SelectType/TrueOrFalse/TrueOrFalseImageContainer'

export {
  TrueOrFalseParagraphs,
  TrueOrFalseImage,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
}
export default TrueOrFalseParagraph
