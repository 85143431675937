import React, { Component } from 'react'
import { func, number } from 'prop-types'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import ListeningWords from 'src/modules/ListeningWords/ListeningWords'

const propTypes = {
  ...gamePropTypes,
  audioRepeat: number,
  init: func.isRequired,
  onClick: func.isRequired,
}

const defaultProps = {
  audioRepeat: 1,
}

class ListeningWordsCore extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init()
  }

  render() {
    const {
      audioRepeat,
      actualEntities,
      completed,
      entitiesById,
      answerId,
      cards,
      mark,
      onClick,
      retry,
      started,
      showImage,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ListeningWords
          audioRepeat={audioRepeat}
          autoPlay={started}
          entitiesById={entitiesById}
          answerId={answerId}
          onClick={onClick}
          cards={cards}
          showImage={showImage}
        />
      </Game>
    )
  }
}

ListeningWordsCore.propTypes = propTypes
ListeningWordsCore.defaultProps = defaultProps
ListeningWordsCore.demoData = {
  entities: [
    'collection-igh',
    'fight',
    'night',
    'fright',
    'eight',
    'collection-y',
    'fry',
    'why',
    'cry',
    'try',
  ],
}

export default ListeningWordsCore
