import React from 'react'
import { arrayOf, bool } from 'prop-types'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  homeworkIterations: arrayOf(bool).isRequired,
}

const icons = {
  false: 'times-circle',
  null: 'question-circle',
  true: 'check-circle',
}

const iconColors = {
  false: 'red',
  null: 'gray',
  true: 'green',
}

const iconAnimations = {
  false: 'bounceIn',
  null: 'bounceInUp',
  true: 'bounceIn',
}

const animationDelays = {
  false: 0,
  null: 1,
  true: 0,
}

const renderIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const color = iconColors[iteration]
  const icon = icons[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    icon && (
    <Div
      {...{ [animation]: true }}
      animated
      color={color}
      key={`${i}-${iteration}`}
      style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
    >
      <Icon
        className={`qa-homework-iteration-${iteration}`}
        icon={icon}
        inline
        style={{ marginLeft: '1px' }}
      />
    </Div>
    ))
}

const renderBackgroundIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    <Div
      {...{ [animation]: true }}
      animated
      key={`${i}-${iteration}`}
      style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
      white
    >
      <Icon inline icon="circle" style={{ marginLeft: '1px' }} />
    </Div>
  )
}

const HomeworkIteration = ({
  homeworkIterations,
}) => (
  <div>
    {
      homeworkIterations && homeworkIterations.length > 0 && (
        <Div flex>
          <Div absolute bottom-0 left-0 flex align="center">
            {homeworkIterations.map(renderBackgroundIcon)}
          </Div>
          <Div id="qa-homework-iterations" absolute bottom-0 left-0 flex align="center">
            {homeworkIterations.map(renderIcon)}
          </Div>
        </Div>
      )
    }
  </div>
)

HomeworkIteration.propTypes = propTypes
export default HomeworkIteration
