import React from 'react'
import { string, bool, number } from 'prop-types'

const propTypes = {
  className: string,
  noAnimation: bool,
  size: number,
}

const defaultProps = {
  className: null,
  noAnimation: false,
  size: 5,
}

const Check = ({ className, noAnimation, size }) => {
  const check = !noAnimation ? 'check' : ''
  return (
    <svg {...{ className }} version="1.1" x="0" y="0" width={`${size}rem`} viewBox="0 0 100 100">
      <polyline
        className={check}
        fill="none"
        stroke="#006600"
        strokeWidth="10"
        points="10,52 35,77 90,22"
        strokeLinecap="round"
      />
    </svg>
  )
}

Check.propTypes = propTypes
Check.defaultProps = defaultProps
export default Check
