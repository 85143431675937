import React, { useEffect } from 'react'
import { bool, shape, arrayOf, string, func } from 'prop-types'
import { withSelectStyle } from 'src/components/SelectStyle'
import pluck from 'src/lib/pluck'

const propTypes = {
  allowAll: bool,
  onChange: func.isRequired,
  subjectId: string.isRequired,
  subjects: arrayOf(shape()).isRequired,
}

const defaultProps = {
  allowAll: false,
}

const SubjectSelector = ({ allowAll, subjectId, subjects, onChange }) => {
  useEffect(() => {
    if (!subjectId) {
      const firstSubjectId = pluck(subjects, '0.id')
      if (firstSubjectId) {
        onChange(firstSubjectId)
      }
    }
  }, [subjectId, onChange, subjects])
  if (!subjectId) {
    return null
  }
  return (
    <select
      name="qa-subject-selector"
      value={subjectId}
      onChange={(ev) => onChange(ev.target.value)}
    >
      {allowAll && <option value="all">All</option>}
      {subjects.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </select>
  )
}

SubjectSelector.defaultProps = defaultProps
SubjectSelector.propTypes = propTypes
const exportSubjectSelectorWithStyle = withSelectStyle(SubjectSelector)

export { exportSubjectSelectorWithStyle as SubjectSelectorWithStyle }
export default SubjectSelector
