import React from 'react'
import { bool, arrayOf } from 'prop-types'

import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  cardIterations: arrayOf(bool).isRequired,
}

const icons = {
  completed: 'check-circle',
  true: 'plus-circle',
  uncompleted: 'times-circle',
  undefined: 'stop-circle',
}

const iconColors = {
  completed: 'green',
  true: 'aqua',
  uncompleted: 'red',
  undefined: 'yellow',
}

const iconAnimations = {
  completed: 'bounceIn',
  true: 'bounceIn',
  uncompleted: 'bounceIn',
  undefined: 'bounceInUp',
}

const animationDelays = {
  completed: 0,
  true: 0,
  uncompleted: 0,
  undefined: 1,
}

const renderIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const color = iconColors[iteration]
  const icon = icons[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    icon && (
      <Div
        {...{ [animation]: true }}
        animated
        color={color}
        key={`${i}-${iteration}`}
        style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
      >
        {
          (icon === 'plus-circle') ? (
            <Div flex iterationIconText column justify-center>
              <Div style={{ textAlign: 'center' }}>
                {i + 1}
              </Div>
            </Div>
          )
            : (
              <Icon
                className={`qa-homework-iteration-${iteration}`}
                icon={icon}
                inline
                style={{ marginLeft: '1px' }}
              />
            )
        }
      </Div>
    )
  )
}

const renderBackgroundIcon = (iteration, i) => {
  const animation = iconAnimations[iteration]
  const animationDelay = animationDelays[iteration]
  return (
    <Div
      {...{ [animation]: true }}
      animated
      key={`${i}-${iteration}`}
      style={{ animationDelay: `${i * animationDelay * 0.1}s` }}
      white
    >
      <Icon inline style={{ marginLeft: '1px' }} icon="circle" />
    </Div>
  )
}

const CardIteration = ({ cardIterations }) => (
  <div>
    <Div flex>
      <Div absolute amr1 bottom-0 right-0 z2 flex align="center">
        {cardIterations.map(renderBackgroundIcon)}
      </Div>
      <Div absolute amr1 bottom-0 right-0 z2 id="card-select-iterations" flex align="center">
        {cardIterations.map(renderIcon)}
      </Div>
    </Div>
  </div>
)

CardIteration.propTypes = propTypes

export default CardIteration
