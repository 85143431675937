import React from 'react'
import classnames from 'classnames'
import { string, shape, oneOfType, element } from 'prop-types'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'

const propTypes = {
  divProps: shape(),
  icon: string,
  id: string,
  label: string,
  qaClassName: string,
  value: oneOfType([element, string]),
}

const defaultProps = {
  divProps: {},
  icon: null,
  id: null,
  label: null,
  qaClassName: null,
  value: undefined,
}

const Attribute = ({ icon, label, value, qaClassName, divProps }) => {
  if (!value) return null
  return (
    <Div Attribute {...divProps}>
      { icon && <Icon before color="blue" icon={icon} /> }
      { label && <span className="bold mr1">{label}:</span> }
      <span className={classnames(qaClassName, { bold: !label })}>
        {value}
      </span>
    </Div>
  )
}

Attribute.propTypes = propTypes
Attribute.defaultProps = defaultProps

export default Attribute
