import React from 'react'
import { number, shape } from 'prop-types'
import Card from 'src/components/Card'
import EntityCard from 'src/components/EntityCard'
import Bubble from 'src/components/Bubble'
import Div from 'src/components/Div'
import EntityBubble from 'src/components/EntityBubble'
import Hover from 'src/utilities/Hover'
import entityShape from 'src/shapes/entity'

const propTypes = {
  action: number.isRequired,
  entity: shape(entityShape).isRequired,
  target: shape(entityShape).isRequired,
}

const ActionCard = ({ action, entity, target }) => {
  if (action === 1) {
    return (
      <Div flex col-3 key={entity.id} p1 auto>
        <Bubble auto>
          <Card button className="Flipper-back-bg" />
        </Bubble>
      </Div>
    )
  }
  if (action === 2 && entity.id === target.id) {
    return (
      <Div flex col-3 key={entity.id} p1 auto>
        <Bubble auto>
          <Card button className="Flipper-back-bg" />
        </Bubble>
      </Div>
    )
  }
  return (
    <Div flex col-3 key={entity.id} p1 auto>
      <Hover
        className="flex flex-auto"
        showClassName="flex flex-auto"
        hideClassName="flipInY animated flex-auto"
        hoverHiddenFlex
        hoverHiddenShow
        hoverStyle={{ left: '0', top: '0' }}
        onHover={
          (
            <Bubble auto>
              <Div flex auto>
                <EntityCard entity={entity} english audio size={2} />
              </Div>
            </Bubble>
          )
        }
      >
        <EntityBubble entity={entity} image />
      </Hover>
    </Div>
  )
}

ActionCard.propTypes = propTypes

export default ActionCard
