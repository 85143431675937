import React from 'react'
import { bool, object } from 'prop-types'
import EntityCard from 'src/components/EntityCard'
import Div from 'src/components/Div'
import { WordInLineSpelling } from 'src/modules/Word'
import Bubble from 'src/components/Bubble'

const propTypes = {
  entity: object,
  showWord: bool,
}

const defaultProps = {
  entity: {},
  showWord: false,
}

const ImageAndWord = ({ entity, showWord }) => (
  <Div auto flex column p2 justify-center>
    <Bubble label="␣">
      <EntityCard
        key={entity.id}
        keyToPlay=" "
        entity={entity}
        english
        image
        audio
        transparent
      />
    </Bubble>
    {
      showWord && (
        <Div
          absolute
          z1
          auto
          flex
          animated
          fadeInUp
          slowly
          mt2
          justify-center
          style={{ top: '50%' }}
        >
          <WordInLineSpelling entity={entity} />
        </Div>
      )
    }
  </Div>
)
ImageAndWord.defaultProps = defaultProps
ImageAndWord.propTypes = propTypes

export default ImageAndWord
