import shuffle from 'lodash/shuffle'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'

const derange = (array) => {
  const uniqArray = uniq(array)
  if (uniqArray.length <= 1) return uniqArray

  const shuffledArray = shuffle(uniqArray)
  const hasFrozenElement = shuffledArray.some((element, i) => isEqual(uniqArray[i], element))
  return (hasFrozenElement ? derange(array) : shuffledArray)
}

export default derange
