import React from 'react'
import { func } from 'prop-types'
import { AudioContext, isMobile, navigator } from 'src/lib/window'

const { warn } = console
const { mediaDevices } = navigator

const DAMPNESS = 0.98

class AudioMeter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processor: null,
      stream: null,
      volume: 0,
    }
    if (!isMobile()) {
      this.initProcessor()
    }
  }

  componentWillUnmount() {
    this.cancelledCallbacks = true
    if (this.state.stream) {
      const track = this.state.stream.getAudioTracks()[0]
      if (track) track.stop()
    }
    if (this.state.processor) {
      this.state.processor.onaudioprocess = null
      this.state.processor.context.close()
    }
  }

  onAudioProcess(event) {
    const { onAudioData } = this.props
    const buf = event.inputBuffer.getChannelData(0)
    const sum = buf.reduce((a, x) => a + (x * x), 0)
    const rms = Math.sqrt(sum / buf.length)
    if (onAudioData) onAudioData(rms, event)
    this.setState({
      volume: Math.max(rms, this.state.volume * DAMPNESS),
    })
  }

  onStreamReady(stream) {
    if (this.cancelledCallbacks) return
    const audioCtx = new AudioContext()
    const source = audioCtx.createMediaStreamSource(stream)
    const processor = audioCtx.createScriptProcessor(256)
    processor.onaudioprocess = event => this.onAudioProcess(event)
    processor.connect(audioCtx.destination)
    source.connect(processor)
    this.setState({ processor, stream })
  }

  initProcessor() {
    if (!mediaDevices) warn('mediaDevices() not supported.')
    if (mediaDevices) {
      mediaDevices.getUserMedia({ audio: true })
        .then(stream => this.onStreamReady(stream))
        .catch((err) => {
          warn(`Error occurred while initalizing audio input: ${err.toString()}`)
        })
    }
  }

  render() {
    const top = (1 - this.state.volume) * 100
    const style = { top: `${top}%` }
    return (
      <div className="bg-blue absolute left-0 bottom-0 right-0" style={style} />
    )
  }
}

AudioMeter.propTypes = {
  onAudioData: func,
}

AudioMeter.defaultProps = {
  onAudioData: null,
}

export default AudioMeter
