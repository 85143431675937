import { compose } from 'src/lib/redux'

import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import Scene from 'src/pages/SchoolOverview/Scene'
import academicYearsQuery from 'src/pages/SchoolOverview/shared/academicYears.gql'
import sectionsHomeworkReportQuery from 'src/pages/SchoolOverview/sectionsHomeworkReport.gql'

export default compose(
  gqlLoader(academicYearsQuery),
  gqlLoader(sectionsHomeworkReportQuery, networkOnlyConfig),
)(Scene)
