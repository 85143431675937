import trackEvent from 'src/components/analytics'
import client from 'src/lib/apolloClient'

export default (token, { resetStore = true } = {}) => {
  localStorage.setItem('token', token)
  trackEvent({
    action: 'login',
    category: 'user',
  })

  if (resetStore) client.resetStore()
}
