import shuffle from 'lodash/shuffle'
import md5 from 'md5'

const digest = params => md5(JSON.stringify(params))

const storage = {}

const sameAsInitial = (result, list) => {
  if (list.length > 2) {
    return digest(list) === digest(result)
  }
  return false
}

const init = (list) => {
  let result
  if (list.length === 1) return list
  do {
    result = shuffle(list)
  } while (sameAsInitial(result, list))
  return result
}

const uniqShuffle = (list) => {
  const key = digest(list)
  if ((!storage[key] || !storage[key].length)) {
    storage[key] = init(list)
  } else {
    storage[key].unshift(storage[key].pop())
  }
  return storage[key]
}

export default uniqShuffle
