import { bool, shape, string } from 'prop-types'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import entityShape from 'src/shapes/entity'
import renderCard from 'src/components/AdaptiveCard/renderCard'

/*
  Adaptive Mode
    single: for headlines, text align for one line
    multi: for paragraphs, text align for multiple line
    forceSingleModeWidth: When mode is single and forceSingleModeWidth is true, the element's height will be ignored. Default is true.
*/

const propTypes = {
  entity: shape(entityShape),
  markdown: bool,
  type: string,
}

const defaultProps = {
  audio: false,
  autoPlay: false,
  autoPlayOnce: false,
  bold: undefined,
  english: false,
  entity: null,
  image: false,
  imageSrc: null,
  keyToPlay: null,
  label: null,
  lablePx: null,
  markdown: false,
  meaning: null,
  onClick: null,
  onKeyPress: null,
  play: false,
  playNext: null,
  selected: false,
  srcEn: null,
  thai: false,
  type: null,
}
const AdaptiveCard = ({
  audio,
  autoPlay,
  autoPlayOnce,
  bold,
  english,
  entity,
  image,
  meaning,
  srcEn,
  thai,
  type,
  ...rest
}) => {
  const stringTypes = mapValues(keyBy(`${type}`.split(' ')), () => true)
  const boolTypes = {
    audio,
    autoPlay,
    autoPlayOnce,
    english,
    image,
    thai,
  }
  const {
    audio: theAudio,
    autoPlay: theAutoPlay,
    autoPlayOnce: theAutoPlayOnce,
    english: theEnglish,
    thai: theThai,
    image: theImage,
    m: unitM,
    cm: unitCM,
    mm: unitMM,
  } = {
    ...boolTypes,
    ...stringTypes,
  }
  const { sound } = { ...entity }
  const meaningPart = { ...entity }.meaning
  const meaningSound = { ...meaningPart }.sound
  const isAudio = (theAudio || theAutoPlay || theAutoPlayOnce)
  const soundForEntityOrMeaning = () => {
    if (meaning) {
      // eslint-disable-next-line
      return isAudio && (theEnglish && { ...meaningSound }.src_en) || (theThai && { ...meaningSound }.src_th)
    }
    // eslint-disable-next-line
    return isAudio && ({ ...sound }.src) || (theEnglish && { ...sound }.srcEn) || srcEn || (theThai && { ...sound }.srcTh) || (theImage && { ...entity.image }.src)
  }
  const src = soundForEntityOrMeaning()
  return renderCard({
    audio: theAudio,
    autoPlay: theAutoPlay,
    bold,
    cm: unitCM,
    english: theEnglish,
    entity,
    image: theImage,
    m: unitM,
    meaning,
    mm: unitMM,
    src,
    thai: theThai,
    ...rest,
  })
}

AdaptiveCard.propTypes = propTypes
AdaptiveCard.defaultProps = defaultProps

export default AdaptiveCard
