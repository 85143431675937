import React from 'react'
import { any, bool, func, number, string } from 'prop-types'
import classNames from 'classnames'
import Dropdown, { DropdownMenu } from 'src/components/ui/Dropdown'

const SequenceMapStep = ({
  active,
  children,
  title,
  i,
  width,
  homework,
  published,
  onClick,
}) => {
  const isActive = () => i.toString() === active || (children && children.find(({ i: index }) => index.toString() === active))
  const sx = {
    ddLink: {
      paddingBottom: 2,
      paddingLeft: 14,
      paddingRight: 14,
      paddingTop: 6,
    },
    label: {
      flex: '1 1 auto',
      paddingLeft: '1px',
      paddingRight: '1px',
    },
    line: {
      height: 4,
    },
    link: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 2,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 6,
      position: 'relative',
      textAlign: 'center',
    },
    verticalLine: {
      width: 4,
    },
    width: {
      flex: `1 1 ${width}`,
    },
  }
  const getWidth = () => {
    if (children && children.length) {
      const stepsDone = children.filter(({ i: index }) => index <= active).length
      return `${(stepsDone / children.length) * 100}%`
    }
    return active >= i ? '100%' : 0
  }
  const hasUnpublished = children ? children.find(({ published }) => !published) : !published
  return (
    <Dropdown disabled={homework} className={`${isActive() && 'selected'}`} style={sx.width}>
      <div
        className={classNames({
          blue: active >= i && !homework,
          'flex-auto': true,
          z1: true,
        })}
        style={{
          ...sx.link,
        }}
        onClick={onClick}
      >
        <div style={sx.label} className={classNames('ellipsis-2-line', { red: hasUnpublished })}>
          {title}
        </div>
      </div>
      <div
        className={classNames({
          'bg-blue': true,
          blue: true,
          muted: !isActive(),
        })}
        style={{
          ...sx.line,
          width: getWidth(),
        }}
      />
      {
      children && (
      <DropdownMenu>
        <div className="bg-white shadow rounded-bottom relative overflow-hidden">
          {
              children.map(({ title, published, i, ...rest }) => (
                title && (
                <a
                  key={i}
                  {...rest}
                  style={sx.ddLink}
                  className={
                      classNames('block py px2 nowrap relative',
                        {
                          blue: published && active >= i,
                          gray: published && active < i,
                          red: !published,
                        })
                    }
                >
                  <div
                    className={classNames({
                      absolute: true,
                      'bg-blue': active >= i,
                      'bottom-0': true,
                      'left-0': true,
                      'top-0': true,
                    })}
                    style={{ ...sx.verticalLine }}
                  />
                  {title}
                </a>
                )))
            }
        </div>
      </DropdownMenu>
      )}
    </Dropdown>
  )
}

SequenceMapStep.propTypes = {
  active: string,
  children: any,
  homework: bool,
  i: number,
  onClick: func.isRequired,
  published: bool,
  title: string,
  width: string,
}
SequenceMapStep.defaultProps = {
  children: undefined,
}

export default SequenceMapStep
