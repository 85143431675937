import React from 'react'
import {
  arrayOf,
  shape,
  string,
} from 'prop-types'
import { t } from 'i18next'

import Widget from 'src/components/Widget'
import AttendanceSection from 'src/pages/AttendancePage/AttendanceToday/AttendanceSection'

const propTypes = {
  sections: arrayOf(
    shape({ id: string.isRequired }).isRequired,
  ).isRequired,
  selectedYear: string,
}

const defaultProps = {
  selectedYear: undefined,
}

const AttendanceSections = ({ sections, selectedYear }) => (
  <Widget
    icon="th-list"
    title={t('attendance.report.attendanceSections')}
  >
    {sections.map((section, i) => (
      <AttendanceSection
        index={i}
        key={section.id}
        selectedYear={selectedYear}
        {...section}
      />
    ))}
  </Widget>
)

AttendanceSections.propTypes = propTypes
AttendanceSections.defaultProps = defaultProps

export default AttendanceSections
