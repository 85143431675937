import every from 'lodash/every'
import lowerCase from 'lodash/lowerCase'
import reduce from 'lodash/reduce'
import uniqShuffle from 'src/lib/uniqShuffle'

const CHANGE = 'Hangman/CHANGE'
const INIT = 'Hangman/INIT'
const MARK = 'Hangman/MARK'
const LETTER = 'Hangman/LETTER'

const MAXIMUM_TRIES = 3

const initialState = {
  actualEntities: [],
  attemptArray: [],
  chop: false,
  completed: null,
  entity: {},
  mood: 'happy',
  places: {},
  responseText: 'Hello there!',
  tries: 0,
  triesIterations: Array(MAXIMUM_TRIES).fill(undefined),
  usedAplhabet: [],
}

const createTriesIterations = (tries = 0) => (
  Array(MAXIMUM_TRIES).fill(undefined).fill(true, 0, tries)
)

const checkPlaces = validatedKey => (a, place) => ({
  ...a,
  newPlaces: {
    ...a.newPlaces,
    [place.id]: {
      ...place,
      correct: !place.correct ? place.question === validatedKey : true,
    },
  },
})

const createPlaces = (a, alphabet, index) => {
  const theIndex = `p-${index}-${alphabet}`
  return {
    ...a,
    [theIndex]: {
      answer: undefined,
      correct: alphabet === ' ' ? true : undefined,
      id: theIndex,
      index,
      question: alphabet,
    },
  }
}

const checkInputFormat = ({ key }) => {
  const REGEX = /^[a-zA-Z]+$/
  if (key && REGEX.test(key) && key.length === 1) {
    return key
  }
  return ''
}

const checkInputAndUpdateUsedAlphabet = ({ entity, validatedKey, usedAplhabet }) => {
  const wrongAnswer = usedAplhabet.slice()
  if (!lowerCase(entity.titleEn).includes(validatedKey)
    && !usedAplhabet.includes(validatedKey)) {
    wrongAnswer.push(validatedKey)
  }
  return wrongAnswer
}

const checkComplete = ({ triesIterations, newPlaces }) => {
  if (every(triesIterations)) {
    return false
  } if (every(newPlaces, 'correct')) {
    return true
  } else { // eslint-disable-line no-else-return
    return null
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const entity = uniqShuffle(entities)[0]
      const question = lowerCase(entity.titleEn)
      const splitWord = question.split('')
      const places = splitWord.reduce(createPlaces, {})
      return {
        ...initialState,
        actualEntities: [entity],
        entity,
        places,
        usedAplhabet: [],
      }
    }

    case LETTER: {
      const { key } = payload
      const {
        entity,
        places,
        tries,
        usedAplhabet,
      } = state
      const validatedKey = lowerCase(checkInputFormat({ key }))
      const newusedAplhabet = usedAplhabet.length < MAXIMUM_TRIES ? checkInputAndUpdateUsedAlphabet({ entity, usedAplhabet, validatedKey }) : usedAplhabet
      const newTries = (newusedAplhabet.length > usedAplhabet.length && tries < 3) ? tries + 1 : tries
      const triesIterations = createTriesIterations(newTries)
      const { newPlaces } = reduce(places, checkPlaces(validatedKey), { places: {} })
      const checkMood = lowerCase(entity.titleEn).includes(validatedKey)
      return {
        ...state,
        answer: key,
        chop: !checkMood,
        completed: checkComplete({ triesIterations, newPlaces }),
        mood: checkMood ? 'blissful' : 'sad',
        places: newPlaces,
        responseText: checkMood ? 'Good job!' : 'Try again!',
        tries: newTries,
        triesIterations,
        usedAplhabet: newusedAplhabet,
      }
    }

    case CHANGE: {
      const newChop = false
      return {
        ...state,
        chop: newChop,
      }
    }

    case MARK: {
      const { places, entity } = state
      const { newPlaces } = reduce(places, checkPlaces(), { entity, places: {} })
      const completed = every(newPlaces, 'correct')
      return {
        ...state,
        completed,
        places: newPlaces,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const handleChange = () => ({
  type: CHANGE,
})

const mark = () => ({
  type: MARK,
})

const letter = key => ({
  payload: {
    key,
  },
  type: LETTER,
})

export {
  init,
  handleChange,
  mark,
  letter,
}

export default reducer
