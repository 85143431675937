import { useContext } from 'react'
import DomainContext from 'src/contexts/DomainContext'
import { UserContext } from 'src/components/SetUser/SetUser'

const usePath = (path) => {
  const domainContext = useContext(DomainContext)
  const userContext = useContext(UserContext)

  return path({ domainContext, userContext })
}

export default usePath
