import React from 'react'
import { arrayOf, shape } from 'prop-types'
import compact from 'lodash/compact'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import Slides from 'src/components/Layout/Slides'
import entityShape from 'src/shapes/entity'
import LetterSlide from 'src/modules/PhonicsLetters/LetterSlide'

const propTypes = {
  alphabetEn: arrayOf(shape(entityShape)).isRequired,
  entities: arrayOf(shape(entityShape)),
  letterFormationVideos: arrayOf(shape(entityShape)).isRequired,
}

const defaultProps = {
  entities: [],
}

const slideKey = slug => slug.replace('letter-formation-', '').replace('letter-en-', '')

const PhonicsLetters = ({ alphabetEn, entities, letterFormationVideos }) => {
  const alphabet = orderBy(alphabetEn, 'titleEn')
  const videosHash = keyBy(letterFormationVideos, ({ slug }) => slideKey(slug))
  const lettersHash = keyBy(alphabetEn, ({ slug }) => slideKey(slug))
  const slides = uniq(entities.length
    ? compact(entities
      .filter(({ slug }) => lettersHash[slideKey(slug)])
      .map(({ slug }) => slideKey(slug)))
    : orderBy(compact(alphabet.map(({ slug }) => slideKey(slug)))))
  return (
    <Slides>
      {slides.map(slide => (
        <LetterSlide
          key={slide}
          letter={lettersHash[slide]}
          video={videosHash[slide]}
          label={lettersHash[slide].titleEn}
        />
      ))}
    </Slides>
  )
}

PhonicsLetters.propTypes = propTypes
PhonicsLetters.defaultProps = defaultProps

export default PhonicsLetters
