import React from 'react'
import { bool, func, arrayOf, string, objectOf, shape } from 'prop-types'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Card from 'src/components/Card'
import Icon from 'src/components/Icon'

const TYPECOLUMNSIZE = 2

const propTypes = {
  choicesType: arrayOf(string).isRequired,
  correct: bool,
  entitiesById: objectOf(shape(entityShape)).isRequired,
  id: string.isRequired,
  ids: arrayOf(string),
  onClick: func,
  select: string,
  silent: bool,
}
const defaultProps = {
  correct: null,
  ids: [],
  onClick: null,
  select: null,
  silent: undefined,
}
const COLOR = {
  0: 'green',
  1: 'darkred',
  2: 'yellow',
  3: 'brown',
}

const ICONS = {
  false: { className: 'darkred', icon: 'times' },
  true: { className: 'green', icon: 'check' },
}

const buttonSelector = (type, index) => {
  if (ICONS[type]) return <Icon size={1} {...ICONS[type]} />
  return (
    <Div bold color={COLOR[index]}>
      {type}
    </Div>
  )
}

const sentenceColumnSize = choicesType => 12 - choicesType.length * TYPECOLUMNSIZE

const cardStatus = ({ select, correct, type }) => {
  if (select === type) {
    if (correct === null) return { selected: true }
    if (correct) {
      return { correct: true }
    }
    return { correct: false }
  }
  return null
}

// eslint-disable-next-line react/display-name
const cellRenderer = silent => entity => (
  <AdaptiveCard
    key={entity.id}
    audio
    english
    entity={entity}
    minHeight={6}
    p={1}
    className="py2"
    silent={silent}
  />
)

const Line = ({ correct, choicesType, entitiesById, id, ids, select, onClick, silent }) => (
  <Div flex flex-1>
    <Div flex col={sentenceColumnSize(choicesType)}>
      {
        ids.map(cellId => entitiesById[cellId]).map(cellRenderer(silent))
      }
    </Div>
    {
      choicesType.map((type, index) => (
        <Div key={`${id}-${type}`} flex col={TYPECOLUMNSIZE} justify-center>
          <Card
            minHeight={false}
            size={3}
            onClick={() => onClick({ id, type })}
            {...cardStatus({ correct, select, type })}
          >
            {buttonSelector(type, index)}
          </Card>
        </Div>
      ))
    }
  </Div>
)

Line.propTypes = propTypes
Line.defaultProps = defaultProps

export default Line
