import parentSection from 'src/lib/menu/parentSection'
import schoolSection from 'src/lib/menu/schoolSection'
import studentSection from 'src/lib/menu/studentSection'
import teacherSection from 'src/lib/menu/teacherSection'

export {
  parentSection,
  schoolSection,
  studentSection,
  teacherSection,
}
