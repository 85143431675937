import React from 'react'
import { func, shape } from 'prop-types'
import { Formik, FieldArray, Form } from 'formik'
import { t } from 'i18next'
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup'

import Icon from 'src/components/Icon'
import Input from 'src/components/forms/Input'
import SelectGrade from 'src/pages/Registration/SelectGrade'
import Button from 'src/components/Button'
import Div from 'src/components/Div'
import { isDevelopment } from 'config'

const schema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
  email: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  password: yup.string().required(),
  students: yup
    .array()
    .of(
      yup.object().shape({
        firstName: yup.string().required(),
        gradeId: yup.string().required(),
        lastName: yup.string().required(),
      })
    )
    .required(),
})

const testValues = {
  confirmPassword: '123',
  email: 'nick@firedev.com',
  firstName: 'firstname',
  lastName: 'lastname',
  password: '123',
  students: [
    {
      firstName: 'student',
      lastName: 'studov',
    },
    {
      firstName: 'student2',
      lastName: 'studov3',
    },
  ],
}

const initialValues = isDevelopment ? testValues : { students: [{}] }

const Spacer = ({ children }) =>
  React.Children.map(children, (child) => <Div my1>{child}</Div>)

const AddStudent = ({ onClick }) => (
  <Div
    flex
    align-center
    rounded
    center
    green
    pointer
    button-hover
    py1
    onClick={() => { if (onClick) { onClick() } return false }}
  >
    <Div p1>
      <Icon icon="plus-circle" />
    </Div>
    <Div p1>{t('registration.addStudent')}</Div>
  </Div>
)
AddStudent.propTypes = {
  onClick: func.isRequired,
}

const RegistrationForm = ({ onSubmit, errors }) => (
  <Formik
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={isDevelopment ? testValues : initialValues}
    initialErrors={errors}
  >
    {({ values, isSubmitting }) => (
      <Form>
        <Div mb2 bg-white rounded shadow>
          <Div bg-blue p2 rounded-top>
            <Div h2 white bold mxn1>
              <Icon icon="passport" />
              {t('registration.registration')}
            </Div>
          </Div>
          <Div bg-white px3 pb2>
            <Div my2 h3 bold mxn2>
              <Icon icon="male" />
              {t('registration.parent')}
            </Div>
            <Div>
              <Spacer>
                <Div flex>
                  <Input
                    name="firstName"
                    label={t('user.firstName')}
                    placeholder={t('user.firstName')}
                    className="mb pr1 col-6 qa-user-firstName"
                    required
                  />
                  <Input
                    name="lastName"
                    label={t('user.lastName')}
                    placeholder={t('user.firstName')}
                    className="mb col-6 qa-user-lastName"
                    required
                  />
                </Div>
                <Input
                  name="email"
                  label={t('login.email')}
                  placeholder={t('login.email')}
                  required
                />
                <Input
                  name="password"
                  type="password"
                  label={t('login.password')}
                  placeholder={t('login.password')}
                  required
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  label={t('user.confirmPassword')}
                  placeholder={t('user.confirmPassword')}
                  required
                />
              </Spacer>
            </Div>
          </Div>
          <Div bg-body border-top mt1 py3 px3 rounded-bottom>
            <Div h3 bold mxn1>
              <Icon icon="child" />
              {t('registration.children')}
            </Div>
            <FieldArray
              name="students"
              render={(arrayHelpers) => (
                <Spacer>
                  {values.students.map((_student, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Div key={index} flex mxn1>
                      <Div px1 flex flex-column justify-start>
                        <label>&nbsp;</label>
                        <Div
                          center
                          red
                          pointer
                          rounded
                          p1
                          button-hover
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        >
                          <Icon icon="minus-circle" />
                        </Div>
                      </Div>
                      <Div auto flex>
                        <Div col-4 px1>
                          <Input
                            name={`students[${index}].firstName`}
                            label={t('user.firstName')}
                            placeholder={t('user.firstName')}
                            className="qa-user-firstName"
                            required
                          />
                        </Div>
                        <Div col-4 px1>
                          <Input
                            name={`students[${index}].lastName`}
                            label={t('user.lastName')}
                            placeholder={t('user.lastName')}
                            className="qa-user-lastName"
                            required
                          />
                        </Div>
                        <Div px1 col-4>
                          <SelectGrade
                            required
                            name={`students[${index}].gradeId`}
                          />
                        </Div>
                      </Div>
                    </Div>
                  ))}
                  <AddStudent onClick={() => { arrayHelpers.push({}) }} />
                </Spacer>
              )}
            />
            <Button type="submit" disabled={isSubmitting} className="p2 col-12">
              {isSubmitting ? (
                <Icon spin icon="circle-notch" />
              ) : (
                <Icon icon="check" />
              )}
            </Button>
          </Div>
        </Div>
      </Form>
    )}
  </Formik>
)
RegistrationForm.propTypes = {
  errors: shape(),
  onSubmit: func.isRequired,
}
export { AddStudent }
export default RegistrationForm
