import React from 'react'
import { arrayOf, number, shape } from 'prop-types'
import { gradeToColor } from 'src/lib/rateToColor'
import Div from 'src/components/Div'

const propTypes = {
  distribution: arrayOf(shape({
    grade: number.isRequired,
    value: number.isRequired,
  })).isRequired,
  height: number,
}

const defaultProps = {
  height: 15,
}

const getSumTotal = distribution => (distribution.reduce((total, { value }) => total + value, 0))

const GradeDistribution = ({ distribution, height }) => (
  <Div col={8} flex column style={{ height: `${height}rem` }}>
    <Div flex auto pt3 border-bottom>
      {distribution.map(({ grade, value }) => (
        <Div key={`${grade}-${value}`} flex flex-1 align="end" justify="center">
          <Div
            flex
            image
            justify="center"
            flex-1
            bg-gray
            style={{
              height: `${(value / getSumTotal(distribution)) * (height - 4)}rem`,
            }}
            className={`bg-${gradeToColor(grade)}`}
          >
            <Div mtn3>
              {value > 0 && value}
            </Div>
          </Div>
        </Div>
      ))}
    </Div>
    <Div flex>
      {distribution.map(({ grade }) => (
        <Div key={grade} flex flex-1 justify="center" bold py1>
          {grade}
        </Div>
      ))}
    </Div>
  </Div>
)

GradeDistribution.propTypes = propTypes
GradeDistribution.defaultProps = defaultProps
export default GradeDistribution
