import React from 'react'
import { string, bool } from 'prop-types'
import GenericField from 'src/components/forms/GenericField'

const propTypes = {
  className: string,
  labelToken: string,
  name: string,
  required: bool,
}

const TextArea = ({ name, className = '', labelToken, required = false }) => (
  <GenericField
    name={name}
    className={className}
    labelToken={labelToken}
  >
    <textarea style={{ width: '100%' }} required={required} />
  </GenericField>
)

TextArea.propTypes = propTypes

export default TextArea
