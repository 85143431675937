import React from 'react'
import { any } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  children: any,
}

const defaultProps = {
  children: null,
}

const Deck = ({ children, ...rest }) => (
  <Div justify="center" flex auto column {...rest}>
    {children}
  </Div>
)

Deck.defaultProps = defaultProps
Deck.propTypes = propTypes

export default Deck
