import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'src/lib/redux'
import { arrayOf, bool, string, shape } from 'prop-types'

import entityShape from 'src/shapes/entity'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import query from 'src/queries/studentHomeworkAnswersByChapter.gql'
import gqlLoader from 'src/lib/gqlLoader'
import * as gameActions from 'src/modules/TypeAndSave/reducer'
import TypeAndSave from 'src/modules/TypeAndSave/TypeAndSave'

const propTypes = {
  answer: string,
  disabled: bool,
  ...gamePropTypes,
  entities: arrayOf(
    shape(entityShape).isRequired,
  ),
  studentHomeworkAnswersByChapter: arrayOf(
    shape(),
  ),
}

const defaultProps = {
  answer: null,
  disabled: false,
  entities: [],
  studentHomeworkAnswersByChapter: [
    {
      grade: null,
      props: {},
    },
  ],
}

class TypeAndSaveContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, studentHomeworkAnswersByChapter } = this.props
    const { init } = this.props
    if (studentHomeworkAnswersByChapter[0]) {
      const { props, grade } = studentHomeworkAnswersByChapter[0]
      const { answer } = props || {}
      init({ answer, entities, grade })
    } else {
      init({ entities })
    }
  }

  render() {
    const {
      actualEntities,
      answer,
      completed,
      disabled,
      handleChange,
      mark,
      question,
      retry,
    } = this.props

    return (
      <Game
        answer={answer}
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <TypeAndSave
          answer={answer}
          disabled={disabled}
          handleChange={handleChange}
          question={question}
        />
      </Game>
    )
  }
}

const select = ({ typeAndSave }) => typeAndSave

const actions = dispatch => (
  bindActionCreators(gameActions, dispatch)
)

TypeAndSaveContainer.propTypes = propTypes
TypeAndSaveContainer.defaultProps = defaultProps

export { TypeAndSaveContainer }

const gqlTypeAndSaveContainer =
  compose(gqlLoader(query))(connect(select, actions)(TypeAndSaveContainer))

gqlTypeAndSaveContainer.demoData = {
  entities: ['phrase-this-is-my-family'],
}

export default gqlTypeAndSaveContainer
