import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import sortBy from 'lodash/sortBy'
import { t } from 'i18next'

import academicYearShape from 'src/shapes/academicYear'

const propTypes = {
  academicTermId: string,
  academicYearId: string,
  academicYears: arrayOf(shape(academicYearShape)),
  onChange: func.isRequired,
}

const defaultProps = {
  academicYears: [],
  finishedOn: '',
  startedOn: '',
}

const AcademicYearFilter = ({ academicYearId, academicTermId, academicYears, onChange }) => {
  const value = { academicTermId, academicYearId }
  const selectedYear = academicYears.find(({ id, slug }) => [id, slug].includes(academicYearId))
  if (!selectedYear) return null

  return (
    <div className="mb1 flex mxn print-hide">
      <div className="px">
        <select
          name="qa-select-academic-year"
          value={academicYearId}
          onChange={ev => onChange({ ...value, academicYearId: ev.target.value })}
        >
          <option value="" disabled>
            {t('academicYearFilter.yearSelector')}
          </option>
          {
            academicYears.map((year) => {
              const id = year.slug || year.id
              return (
                <option key={id} value={id}>
                  {year.title}
                </option>
              )
            })
          }
        </select>
      </div>
      <div className="px">
        <select
          name="qa-select-academic-term"
          value={academicTermId}
          onChange={ev => onChange({ ...value, academicTermId: ev.target.value })}
        >
          <option value="all">
            {t('academicYearFilter.wholeYear')}
          </option>
          {
            sortBy(selectedYear.academicTerms, 'startedOn').map((term) => {
              const id = term.slug || term.id
              return (
                <option key={id} value={id}>
                  {term.title}
                </option>
              )
            })
          }
        </select>
      </div>
    </div>
  )
}

AcademicYearFilter.propTypes = propTypes
AcademicYearFilter.defaultProp = defaultProps

export default AcademicYearFilter
