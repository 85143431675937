import React from 'react'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import Div from 'src/components/Div'

const getLessonsByUnit = renderLesson => (group) => {
  const { unitIdentifier, unitTitle } = group[0]
  const lessonsGroup = map(group, renderLesson)
  return (
    <Div key={unitIdentifier} ml={2} mb={2}>
      <Div h4 bold mb1 key={unitIdentifier}>{unitIdentifier} {unitTitle}</Div>
      <Div ml={2}>
        {lessonsGroup}
      </Div>
    </Div>
  )
}

const getLessonsBySubject = renderLesson => (group) => {
  const { subjectTitle } = group[0]
  const lessonsByUnitIdentifier = groupBy(group, 'unitIdentifier')
  return (
    <div key={subjectTitle}>
      <Div h3 mb1 bold>{subjectTitle}</Div>
      {map(lessonsByUnitIdentifier, getLessonsByUnit(renderLesson))}
    </div>
  )
}

const groupLessonsBySubject = (lessons, renderLesson) => (
  map(groupBy(lessons, 'subjectTitle'), getLessonsBySubject(renderLesson))
)

export default groupLessonsBySubject
