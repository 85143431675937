import React from 'react'
import { array, object, func } from 'prop-types'
import Card from 'src/components/Card'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Place from 'src/components/Place'

const propTypes = {
  chunkPlaces: array.isRequired,
  entitiesById: object,
  moveCard: func.isRequired,
  places: object.isRequired,
  types: array,
}

const defaultProps = {
  chunkPlaces: [],
  types: [],
}

const MatchTextWithText = ({
  chunkPlaces,
  entitiesById,
  moveCard,
  places,
  types,
}) => (
  <Deck>
    <Div flex>
      {
        types.map(type => (
          <Div flex justify-end key={type} col={12 / types.length}>
            <Card justify="end" minHeight={false} size={2} title={type} transparent bold />
          </Div>
        ))
      }
    </Div>
    <Div auto flex column>
      {
        chunkPlaces.map(chunk => (
          <Div flex relative key={`row-${chunk}`} flex-1 auto>
            <Div justify-center flex auto>
              {
                chunk.map(placeId => (
                  <Div key={places[placeId].cardId} flex auto relative z2 col={12 / types.length}>
                    <Place
                      id={places[placeId].id}
                      onDrop={moveCard}
                      className="mt1 px1"
                    >
                      <AdaptiveCard
                        audio
                        bold={false}
                        correct={places[placeId].correct}
                        draggable
                        english
                        entity={entitiesById[places[placeId].cardId]}
                        id={places[placeId].id}
                        minHeight={8}
                        p={1}
                      />
                    </Place>
                  </Div>
                ))
              }
              <Div flex absolute z1 col-12 px2 style={{ marginTop: '-0.5rem', top: '50%' }}>
                <hr
                  width="100%"
                  style={{ borderBottomWidth: '1rem', borderColor: 'blue' }}
                />
              </Div>
            </Div>
          </Div>
        ))
      }
    </Div>
  </Deck>
)

MatchTextWithText.defaultProps = defaultProps
MatchTextWithText.propTypes = propTypes

export default MatchTextWithText
