import React from 'react'
import { func, object } from 'prop-types'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  place: object.isRequired,
}

const FamilyPlace = ({
  place: { id, cardId },
  cards,
  entitiesById,
  moveCard,
}) => (
  <Place id={id} onDrop={moveCard}>
    {
      cardId ? (
        <EntityCard
          correct={cards[cardId].correct}
          draggable
          entity={entitiesById[cardId]}
          id={entitiesById[cardId].id}
          image
        />
      ) : (
        <EntityCard
          english
          bold
          audio
          entity={entitiesById[id]}
          size={4}
        />
      )}
  </Place>
)

FamilyPlace.propTypes = propTypes

export default FamilyPlace
