import React from 'react'
import { arrayOf, shape } from 'prop-types'
import chunk from 'lodash/chunk'
import compact from 'lodash/compact'
import uniqBy from 'lodash/uniqBy'
import entityShape from 'src/shapes/entity'
import Slides from 'src/components/Layout/Slides'
import ComicSlide from 'src/modules/SpeechBubblesComic/ComicSlide'

const propTypes = {
  entities: arrayOf(
    shape(entityShape).isRequired,
  ),
}

const defaultProps = {
  entities: [],
}

const SpeechBubblesComic = ({ entities }) => (
  <Slides>
    {chunk(entities, 2).map(([first, second]) => {
      const images = uniqBy([first, second].filter(({ image } = {}) => image), e => e.image.id)
      return (
        <ComicSlide
          key={first.id}
          images={images}
          phrases={compact([first, second])}
        />
      )
    })}
  </Slides>
)

const demoData = {
  entities: [
    'phrase-what-does-your-sister-do',
    'phrase-she-is-a-doctor-she-works-in-a-hospital',
    'phrase-what-does-your-father-do',
    'phrase-he-is-a-teacher-he-works-in-a-school',
    'phrase-what-does-your-sister-do',
    'phrase-she-is-a-fruitseller-she-works-in-a-marketplace',
  ],
}

SpeechBubblesComic.propTypes = propTypes
SpeechBubblesComic.defaultProps = defaultProps
SpeechBubblesComic.demoData = demoData
export default SpeechBubblesComic
