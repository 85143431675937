import React from 'react'
import { bool, func, shape, string } from 'prop-types'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import { withApollo } from '@apollo/react-hoc'
import i18next, { changeLanguage, t } from 'i18next'

import Div from 'src/components/Div'
import { location } from 'src/lib/window'
import Dropdown, { DropdownMenu } from 'src/components/ui/Dropdown'

const propTypes = {
  client: shape().isRequired,
}

const localeLink = ({ active, locale, onClick, name }) => (
  <Div
    className={`qa-item-${locale}`}
    bg={active ? 'blue' : 'white'}
    button-hover
    color={active ? 'white' : 'blue'}
    cursor-pointer
    key={locale}
    px2
    py1
    bold={active}
    onClick={onClick}
  >
    {name}
  </Div>
)

localeLink.propTypes = {
  active: bool.isRequired,
  locale: string.isRequired,
  name: string.isRequired,
  onClick: func.isRequired,
}

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: i18next.language,
    }
  }

  setLocale(locale) {
    this.setState({ locale })
    changeLanguage(locale)
    location.reload()
  }

  render() {
    const { client } = this.props
    const { locale: currentLocale } = this.state

    return (
      <Dropdown>
        <div className="flex flex-1 align-center p1 white">
          <span>{capitalize(currentLocale)}</span>
        </div>
        <DropdownMenu>
          <Div rounded overflow-hidden shadow>
            {map(i18next.languages.sort(), (locale) =>
              localeLink({
                active: locale === currentLocale,
                client,
                locale: t('locale.locale', { lng: locale }),
                name: t('locale.name', { lng: locale }),
                onClick: () => this.setLocale(locale),
              })
            )}
          </Div>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

LanguageSwitcher.propTypes = propTypes

export { LanguageSwitcher }
export default withApollo(LanguageSwitcher)
