import React, { useContext } from 'react'
import { UserContext } from 'src/components/SetUser/SetUser'
import { Redirect } from 'react-router-dom'

const userRedirect = getPath => () => {
  const { slug } = useContext(UserContext)
  return <Redirect to={getPath(slug)} />
}

export default userRedirect
