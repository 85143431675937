import React from 'react'
import { number, object, string, shape, func } from 'prop-types'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Card from 'src/components/Card'
import AdaptiveCard from 'src/components/AdaptiveCard'
import ControlBar from 'src/modules/BarChart/ControlBar'

const propTypes = {
  bars: shape({
    id: shape({
      check: string,
      id: string,
      title: string,
    }),
  }).isRequired,
  barSize: number,
  clickMinus: func.isRequired,
  clickPlus: func.isRequired,
  entitiesById: object.isRequired,
  image: string,
  paragraph: string,
}

const defaultProps = {
  barSize: 0,
  image: null,
  paragraph: '',
}

const BarChart = ({
  bars,
  barSize,
  entitiesById,
  image,
  paragraph,
  clickPlus,
  clickMinus,
}) => (
  <Deck>
    <Div auto flex justify-center>
      {
        image && (
          <Div flex col={paragraph ? 4 : 12}>
            <Card image title={image} minHeight={false} transparent />
          </Div>
        )
      }
      {
        paragraph && (
          <Div key={`key-${barSize}`} auto flex col={image ? 8 : 12}>
            <AdaptiveCard
              align="left"
              auto
              p={2}
              title={paragraph}
            />
          </Div>
        )
      }
    </Div>
    <Div flex relative>
      {
        map(bars, ({ correct, id, index, size }) => (
          <ControlBar
            key={id}
            clickPlus={clickPlus}
            clickMinus={clickMinus}
            correct={correct}
            index={index}
            id={id}
            entitiesById={entitiesById}
            size={size}
          />
        ))
      }
    </Div>
  </Deck>
)

BarChart.defaultProps = defaultProps
BarChart.propTypes = propTypes

export default BarChart
