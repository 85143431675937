import React from 'react'
import { number, shape, bool, string, object } from 'prop-types'
import Target from 'src/components/ObjectTarget/Target'
import DisappearTarget from 'src/components/ObjectTarget/DisappearTarget'

const propTypes = {
  clickable: bool,
  correct: bool,
  cursive: bool,
  decorateTargetImage: string,
  disappearTargetImage: string,
  effectImage: string,
  entity: shape(),
  entityImageStyle: object,
  height: number,
  justify: string,
  secondEffectImage: string,
  selected: bool,
  style: object,
  targetImage: string,
  text: bool,
  width: number,
}

const defaultProps = {
  clickable: undefined,
  correct: null,
  cursive: null,
  decorateTargetImage: null,
  disappearTargetImage: null,
  effectImage: null,
  entity: null,
  entityImageStyle: { padding: '2rem' },
  height: 15,
  justify: 'center',
  secondEffectImage: null,
  selected: null,
  style: {},
  targetImage: null,
  text: null,
  width: 18,
}

const TargetController = ({
  clickable,
  correct,
  cursive,
  decorateTargetImage,
  disappearTargetImage,
  effectImage,
  entity,
  entityImageStyle,
  height,
  justify,
  secondEffectImage,
  selected,
  style,
  targetImage,
  text,
  width,
}) => (
  clickable ?
    (
      <Target
        {...{
          correct,
          cursive,
          decorateTargetImage,
          effectImage,
          entity,
          entityImageStyle,
          height,
          justify,
          secondEffectImage,
          selected,
          style,
          targetImage,
          text,
          width,
        }}
      />
    )
    : (
      <DisappearTarget {...{ disappearTargetImage, height, style, targetImage, width }} />
    )
)

TargetController.propTypes = propTypes
TargetController.defaultProps = defaultProps

export default TargetController
