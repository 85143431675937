import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'
import { usersPath } from 'src/lib/routes'
import { path as userPath } from 'src/pages/User/Show/config'

const path = '/:domain/users/:scope?/:filter?'
const matchPaths = [path, userPath]

const schoolScene = {
  icon: icons[menu.USERS],
  id: menu.PEOPLE,
  matchPaths,
  path: `/:domain${usersPath()}`,
}

const teacherScene = {
  icon: icons[menu.USERS],
  id: menu.MY_STUDENTS,
  path: `/:domain${usersPath({ filter: 'my', scope: 'students' })}`,
}

export {
  path,
  schoolScene,
  teacherScene,
}
