import React from 'react'
import { useHistory } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { string } from 'prop-types'
import usePageParams from 'src/lib/usePageParams'
import { schoolOverviewReportPath } from 'src/lib/routes'
import usePath from 'src/lib/routes/usePath'
import ReportLayout from 'src/components/ReportLayout/ReportLayout'
import Container from 'src/pages/SchoolOverview/Container'
import Grade from 'src/pages/SchoolOverview/Grade'
import Section from 'src/pages/SchoolOverview/Section'
import { path, fullPath, sectionPath, gradePath } from 'src/pages/SchoolOverview/config'

const propTypes = {
  path: string.isRequired,
}

const Overview = () => {
  const overviewPath = usePath(schoolOverviewReportPath)
  const history = useHistory()
  const params = usePageParams()
  const onParamsChange = change => history.push(overviewPath({ ...params, ...change }))
  return (
    <Switch>
      <Route exact path={[path, fullPath]}>
        <ReportLayout {...params} onParamsChange={onParamsChange}>
          <Container />
        </ReportLayout>
      </Route>
      <Route path={sectionPath}>
        <ReportLayout {...params} onParamsChange={onParamsChange}>
          <Section.Container />
        </ReportLayout>
      </Route>
      <Route path={gradePath}>
        <ReportLayout {...params} onParamsChange={onParamsChange}>
          <Grade.Container />
        </ReportLayout>
      </Route>
    </Switch>
  )
}


Overview.propTypes = propTypes

export default Overview
