import React from 'react'
import { shape } from 'prop-types'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import Img from 'src/components/Img'
import KeyLabel from 'src/components/KeyLabel'
import alphabetShape from 'src/shapes/alphabet'

const propTypes = {
  letter: shape(alphabetShape).isRequired,
}

const defaultProps = {
  letter: null,
}

const FlashCards = ({ letter }) => {
  const {
    id,
    sound: { srcEn },
    titleEn: title,
    image: { src },
  } = letter
  return (
    <Div flex auto gutter={2}>
      <Div auto flex justify-center col={8}>
        <Img src={src} />
      </Div>
      <Div flex column col={4}>
        <Div flex column auto justify="center">
          <Div flex justify="center" mb={2}>
            <KeyLabel label="1" />
            <div>
              <Card
                audio
                bold
                fit
                keyToPlay="1"
                key={id}
                size={0}
                src={srcEn}
                title={title}
              />
            </div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

FlashCards.propTypes = propTypes
FlashCards.defaultProps = defaultProps

export default FlashCards
