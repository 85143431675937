import { PHRASE_PREFIX, PARAGRAPH_PREFIX } from 'src/constants/entitySlugPrefixes'

const isPhrase = entity => entity.slug.startsWith(PHRASE_PREFIX)

const isParagraph = entity => entity.slug.startsWith(PARAGRAPH_PREFIX)

// format: (PW+)+
// P meant that should be `phrase-` prefix
// W - word entity
const reduceChunks = (chunks, entity) => {
  if (isPhrase(entity) || isParagraph(entity)) {
    chunks.push([entity])
  } else if (chunks[chunks.length - 1]) {
    chunks[chunks.length - 1].push(entity)
  }
  return chunks
}

const entitiesToChunks = entities => entities.reduce(reduceChunks, [])

export default entitiesToChunks
