import React from 'react'
import { object, string } from 'prop-types'
import ErrorMessage from 'src/components/ErrorMessage'
import Entity from 'src/pages/preview/Dictionary/Entity'

const propTypes = {
  entity: object.isRequired,
  entitySlug: string.isRequired,
}

const DictionaryEntity = ({ entity, entitySlug }) => {
  if (entity) return <Entity {...entity} />
  return (
    <ErrorMessage message={`The entity with slug: ${entitySlug} is not found`} />
  )
}


DictionaryEntity.propTypes = propTypes
export default DictionaryEntity
