import React from 'react'
import { string, arrayOf, shape, bool } from 'prop-types'
import { isSame } from 'src/lib/dates'
import Attendance from 'src/pages/AttendancePage/AttendanceWeekly/StudentAttendance'

const propTypes = {
  attendances: arrayOf(shape({
    attendanceOn: string.isRequired,
    present: bool.isRequired,
  })),
  days: arrayOf(string).isRequired,
  fullName: string.isRequired,
  id: string.isRequired,
}

const defaultProps = {
  attendances: [],
}

const StudentReport = ({
  id,
  fullName,
  days,
  attendances,
}) => (
  <tr id={`qa-student-attendance-${id}`}>
    <td>
      <div className="pl2">
        {fullName}
      </div>
    </td>
    {
      days.map((day) => {
        const attendance = attendances.find(a => isSame(day, a.attendanceOn, 'day'))
        return (
          <Attendance
            key={`${id}-${day}`}
            checked={attendance && attendance.present}
            indeterminate={!attendance}
            day={day}
            studentId={id}
          />
        )
      })
    }
  </tr>
)

StudentReport.propTypes = propTypes
StudentReport.defaultProps = defaultProps

export default StudentReport
