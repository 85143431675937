import React from 'react'
import { bool, func, number, string } from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18next'

import Progress from 'src/components/Progress'
import Div from 'src/components/Div'

const propTypes = {
  actual: number,
  identifier: string,
  index: number,
  onClick: func,
  score: number,
  selected: bool,
  showRatio: bool,
  smallTitle: bool,
  title: string,
  total: number,
  type: string,
  zebraInverted: bool,
}

const defaultProps = {
  bold: false,
  score: 0,
  showRatio: false,
  smallTitle: true,
  zebraInverted: false,
}

const ReportItem = ({
  onClick,
  title,
  identifier,
  smallTitle,
  selected,
  type,
  index,
  total,
  actual,
  score,
  showRatio,
  zebraInverted,
}) => (
  <Div
    z1
    relative
    rounded
    p={2}
    mb={1}
    onClick={onClick}
    bg-body
    className={classNames({
      'cursor-pointer': onClick,
      zebra: zebraInverted ? !(index % 2) : index % 2,
      [identifier]: true,
    })}
  >
    <Div right small mtn1 mrn1>
      {t(`curriculum.${type}`)}
    </Div>
    <Div mb={1} small={smallTitle} bold={selected}>
      <strong>{identifier}</strong> {title}
    </Div>
    <Progress
      actual={actual}
      showRatio={showRatio}
      value={score}
      total={total}
    />
  </Div>
)

ReportItem.propTypes = propTypes
ReportItem.defaultProps = defaultProps
export default ReportItem
