import { func, bool } from 'prop-types'

const gamePropTypes = {
  completed: bool,
  init: func,
  mark: func,
  retry: func,
  started: bool,
}

export default gamePropTypes
