import React from 'react'
import { any, string, shape } from 'prop-types'
import classnames from 'classnames'
import Div from 'src/components/Div'

const propTypes = {
  children: any.isRequired,
  className: string,
  style: shape({}),
}

const defaultProps = {
  className: null,
  style: {},
}

const AnnouncementBar = ({ children, className, style }) => (
  <Div
    bg-white
    black
    className={classnames('Announcement', className)}
    col-12
    left-0
    print-hide
    shadow-bottom
    style={style}
  >
    {children}
  </Div>
)

AnnouncementBar.propTypes = propTypes
AnnouncementBar.defaultProps = defaultProps

export default AnnouncementBar
