import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ProvidePhrase/reducer'
import ProvideQuestion from 'src/modules/ProvidePhrase/ProvideQuestion/ProvideQuestion'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
  speaker: false,
}

class ProvideQuestionContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.initQuestion(this.props)
  }

  render() {
    const {
      mark, retry, completed, actualEntities,
      availableCardsId, cards, entitiesById, moveCard, places,
      answerEntity, choiceEntities, questionEntity, speakerEntity, started, speaker,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ProvideQuestion {...{
          answerEntity,
          availableCardsId,
          cards,
          choiceEntities,
          entitiesById,
          moveCard,
          places,
          questionEntity,
          speaker,
          speakerEntity,
          started,
        }}
        />
      </Game>
    )
  }
}

const selector = state => state.providePhrase
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const ProvideQuestionWithSpeaker = props => <ProvideQuestionContainer speaker {...props} />

export const ProvideQuestionWithSpeakerContainer =
  connect(selector, actions)(ProvideQuestionWithSpeaker)

ProvideQuestionWithSpeakerContainer.demoData = {
  entities: [
    'younger-sister',
    'phrase-who-is-he',
    'phrase-he-is-my-elder-brother',
    'phrase-who-is-she',
    'phrase-she-is-my-elder-sister',
    'phrase-who-are-they',
    'phrase-they-are-my-parents',
  ],
}

ProvideQuestionContainer.propTypes = propTypes
ProvideQuestionContainer.defaultProps = defaultProps
ProvideQuestionContainer.demoData = {
  entities: [
    'phrase-who-is-he',
    'phrase-he-is-my-elder-brother',
    'phrase-who-is-she',
    'phrase-she-is-my-elder-sister',
    'phrase-who-are-they',
    'phrase-they-are-my-parents',
  ],
}

export default connect(selector, actions)(ProvideQuestionContainer)
