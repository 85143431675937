import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import KeyPress from 'src/components/KeyPress'

import { shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/UnscrambleWords/reducer'
import UnscrambleWords from 'src/modules/UnscrambleWords/UnscrambleWords'

const propTypes = {
  ...gamePropTypes,
  entity: shape(entityShape),
}

const defaultProps = {
  completed: null,
  entity: null,
  variants: null,
}

class UnscrambleWordsContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const { mark, retry, completed, backspace, letter, actualEntities,
      availableCards, cards, places, moveCard, hotspotImage, entity } = this.props
    return (
      <Game {...{ actualEntities, completed, init: () => this.init(), mark, retry }}>
        <KeyPress keys={{ Backspace: backspace, Enter: () => {}, rest: letter }} />
        <UnscrambleWords {...{ availableCards, cards, entity, hotspotImage, moveCard, places }} />
      </Game>
    )
  }
}

const select = ({ unscrambleWords }) => unscrambleWords.now

const actions = dispatch => (
  bindActionCreators(cardActions, dispatch)
)

UnscrambleWordsContainer.propTypes = propTypes
UnscrambleWordsContainer.defaultProps = defaultProps
UnscrambleWordsContainer.demoData = {
  entities: [
    'mother',
    'father',
    'family',
  ],
}

export { UnscrambleWordsContainer }
export default connect(select, actions)(UnscrambleWordsContainer)
