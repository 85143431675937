import React from 'react'

import { SubjectSelectorWithStyle } from 'src/selectors/SubjectSelector'
import { useHistory } from 'react-router'
import { homeworkReportPath } from 'src/lib/routes'

import usePageParams from 'src/lib/usePageParams'
import usePath from 'src/lib/routes/usePath'
import YearHomeworkReport from 'src/pages/HomeworkPage/YearHomeworkReportContainer'
import HomeworkReport from 'src/pages/HomeworkPage/HomeworkReportContainer'
import ReportLayout from 'src/components/ReportLayout/ReportLayout'

const HomeworkPage = () => {
  const history = useHistory()
  const path = usePath(homeworkReportPath)
  const params = usePageParams()
  const { subjectId, yearId } = params
  const onParamsChange = params => history.push(path(params))
  return (
    <ReportLayout {...params} onParamsChange={onParamsChange}>
      <div className="relative">
        <SubjectSelectorWithStyle
          allowAll
          subjectId={subjectId}
          onChange={subjectId => onParamsChange({ subjectId })}
        />
        {yearId
          ? <YearHomeworkReport {...params} />
          : <HomeworkReport {...params} />}
      </div>
    </ReportLayout>
  )
}

export default HomeworkPage
