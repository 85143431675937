import React, { Component } from 'react'
import { func, element } from 'prop-types'

const propTypes = {
  FallbackComponent: element.isRequired,
  TheComponent: func.isRequired,
}

export class ErrorReporter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasError } = this.state
    if (hasError && prevState.hasError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false })
    }
  }


  componentDidCatch(error) {
    this.setState({
      error,
      hasError: true,
    })
  }

  render() {
    const { TheComponent, FallbackComponent, ...props } = this.props
    const { hasError, error } = this.state
    return hasError
      ? React.cloneElement(FallbackComponent, { error })
      : <TheComponent {...props} />
  }
}

ErrorReporter.propTypes = propTypes

const errorReporter = (TheComponent, FallbackComponent) => props => (
  <ErrorReporter TheComponent={TheComponent} FallbackComponent={FallbackComponent} {...props} />
)
export default errorReporter
