import React from 'react'
import { useHistory } from 'react-router'

import Skills from 'src/pages/StudentSkillsPage'
import userPath from 'src/lib/routes/user'
import userReport from 'src/lib/routes/userReport'
import usePath from 'src/lib/routes/usePath'
import usePageParams from 'src/lib/usePageParams'

const UserShowSkillsContainer = ({
  userId,
  report,
}) => {
  const history = useHistory()
  const pagePath = report ? userReport : userPath
  const path = usePath(pagePath)
  const params = usePageParams()
  return (
    <Skills
      {...params}
      onSubjectChange={subjectId => history.push(path({ subjectId, tab: 'skills' }))}
      userId={userId}
    />
  )
}

export default UserShowSkillsContainer
