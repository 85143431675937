import React, { useState, useLayoutEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { bool, arrayOf, shape, func } from 'prop-types'
import * as gameActions from 'src/modules/MultipleChoice/reducer'
import Game from 'src/components/Game'
import entityShape from 'src/shapes/entity'
import UnitTestMultipleChoice from 'src/modules/UnitTestMultipleChoice/UnitTestMultipleChoice'

const propTypes = {
  completed: bool,
  entities: arrayOf(shape(entityShape)).isRequired,
  imageChoices: bool,
  initFixed: func.isRequired,
}

const defaultProps = {
  completed: undefined,
  imageChoices: false,
}

const UnitTestMultipleChoiceContainer = ({
  actualEntities,
  initFixed,
  retry,
  mark,
  completed,
  entities,
  isMark,
  ...rest
}) => {
  const [initialized, setInitialized] = useState(false)
  useLayoutEffect(() => {
    if (!initialized && entities) {
      initFixed({ entities, cardsSize: 4 })
      setInitialized(true)
    }
  })


  return initialized && (
    <Game {...{
      actualEntities,
      completed,
      init: initFixed,
      mark,
      retry,
      cardsSize: 4,
      entities,
      isMark,
    }}
    >
      <UnitTestMultipleChoice {...{ completed, ...rest }} />
    </Game>
  )
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const UnitTestMultipleChoiceImage = props => (
  <UnitTestMultipleChoiceContainer {...props} imageChoices />)
export const UnitTestMultipleChoiceImageContainer =
  connect(select, actions)(UnitTestMultipleChoiceImage)

const demoData = {
  entities: [
    'choose-animal',
    'giraffe',
    'bird',
    'snake',
    'mouse',
  ],
}
UnitTestMultipleChoiceContainer.demoData = demoData
UnitTestMultipleChoiceContainer.propTypes = propTypes
UnitTestMultipleChoiceContainer.defaultProps = defaultProps

UnitTestMultipleChoiceImageContainer.demoData = demoData
UnitTestMultipleChoiceImageContainer.propTypes = propTypes
UnitTestMultipleChoiceImageContainer.defaultProps = defaultProps

export { UnitTestMultipleChoiceContainer }

export default connect(select, actions)(UnitTestMultipleChoiceContainer)
