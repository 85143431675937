const loginState = ({ parent, relogin }) => {
  const subdomain = window.location.hostname.split('.')[0]
  const demo = subdomain === 'demo' || subdomain === 'show'
  const app = subdomain === 'app'
  const native = window.Capacitor && window.Capacitor.isNative
  const showSocialButtons = relogin || !(app || native || demo)
  const showLoginForm = !(app || demo) || parent || relogin
  const showSelectSchool = !app
  return {
    app,
    demo,
    showLoginForm,
    showSelectSchool,
    showSocialButtons,
    subdomain,
  }
}

export default loginState
