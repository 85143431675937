import React from 'react'
import { array, string, bool } from 'prop-types'
import classNames from 'classnames'

import Div from 'src/components/Div'
import Access from 'src/components/Access'
import HomeButton from 'src/components/HomeButton'
import HomeworkButton from 'src/components/HomeworkButton'
import LessonButton from 'src/components/LessonButton'

import SequenceMapStep from 'src/components/ui/SequenceMap/SequenceMapStep'
import mapSteps from 'src/components/ui/SequenceMap/lib/mapSteps'

const propTypes = {
  active: string,
  homework: bool.isRequired,
  lessonSlug: string,
  steps: array,
}

const defaultProps = {
  active: undefined,
  lessonSlug: '',
  steps: [],
}

const SequenceMap = ({
  steps,
  active,
  homework,
  lessonSlug,
  ...props
}) => {
  const style = {
    fontSize: 14,
  }
  const createStep = (step, i) => (
    <SequenceMapStep
      key={i}
      width={`${100 / steps.length}%`}
      active={active}
      homework={homework}
      {...step}
    />
  )
  const children = mapSteps(steps).map(createStep)
  return (
    <Div shadow z={3} flex col={12} bg="white" style={{ letterSpacing: '-1px' }}>
      <Div fx={1}>
        <Div
          bold
          flex
          justify="space-between"
          color="gray"
          {...props}
          className={classNames({
            'no-text-selection': homework,
          })}
          style={style}
        >
          <HomeButton color="blue" showTitle={false} underline />
          {children}
          {
            homework ?
              <LessonButton lessonSlug={lessonSlug} />
              : (
                <Access student>
                  <HomeworkButton lessonSlug={lessonSlug} />
                </Access>
              )
          }
        </Div>
      </Div>
    </Div>
  )
}

SequenceMap.propTypes = propTypes
SequenceMap.defaultProps = defaultProps
export default SequenceMap
