import React from 'react'
import { number } from 'prop-types'
import Div from 'src/components/Div'
import alphabetIndex from 'src/lib/alphabetIndex'

const propTypes = {
  index: number.isRequired,
}

const LetterLabel = ({ index }) => (
  <Div size={1} mbn={1} bold center>
    {alphabetIndex(index).toUpperCase()}
  </Div>
)

LetterLabel.propTypes = propTypes
export default LetterLabel
