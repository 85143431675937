import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import ResetPassword from 'src/pages/ResetPassword'

export const path = '/password'

const PasswordRoute = () => (
  <Switch>
    <Route exact path="/password">
      <ResetPassword />
    </Route>
  </Switch>
)

export default PasswordRoute
