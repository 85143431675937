import { Component } from 'react'
import { bool, string } from 'prop-types'
import audioPlayer from 'src/lib/audioPlayer'

const propTypes = {
  isPreview: bool,
  src: string,
}

const defaultProps = {
  isPreview: false,
  src: null,
}

let player = null

export class Audio extends Component {
  componentDidMount() {
    player = this.createPlayer()
  }

  componentWillUnmount() {
    if (player) {
      player.off()
    }
  }

  createPlayer = () => {
    const { isPreview, src } = this.props
    return audioPlayer({
      onLoad: () => {
        player.play({ playOnce: true, silent: isPreview })
      },
      src,
    })
  }

  render() {
    return null
  }
}

Audio.propTypes = propTypes
Audio.defaultProps = defaultProps

export default Audio
