import DomainContext from 'src/contexts/DomainContext'
import { PORTAL_SCOPE } from 'src/constants/routeScopes'
import MenuSection from 'src/pages/TheLayout/Menu/MenuSection'
import { PARENT_SECTION, REPORTS_SECTION } from 'src/pages/TheLayout/Menu/locale/constants'
import { parentSection } from 'src/lib/menu'
import { parentReportsSection } from 'src/lib/menu/reportsSection'
import React from 'react'

const ParentMenu = ({ user }) => (
  <DomainContext.Provider value={PORTAL_SCOPE}>
    <MenuSection
      sectionKey={PARENT_SECTION}
      menuItems={parentSection(user.roleId)}
    />
    <MenuSection
      sectionKey={REPORTS_SECTION}
      menuItems={parentReportsSection}
    />
  </DomainContext.Provider>
)

export default ParentMenu
