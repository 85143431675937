import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { t } from 'i18next'
import { formatDateLong, formatDateTimeLong } from 'src/lib/dates'

import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'
import homeworkAssignmentShape from 'src/shapes/homeworkAssignment'

const propTypes = {
  homeworkAssignments: arrayOf(shape(homeworkAssignmentShape)).isRequired,
}

const HomeworkAssignments = ({ homeworkAssignments = [] }) => (
  <StickyTable>
    <StickyTableHeader>
      <th>{t('dashboard.homeworkAssignments.createdAt')}</th>
      <th>{t('dashboard.homeworkAssignments.lesson')}</th>
      <th>{t('dashboard.homeworkAssignments.year')}</th>
      <th>{t('dashboard.homeworkAssignments.due')}</th>
      <th>{t('dashboard.homeworkAssignments.teacher')}</th>
    </StickyTableHeader>
    <tbody>
      {
        homeworkAssignments.map(({
          id,
          createdAt,
          dueOn,
          lesson: {
            identifier,
            title: lessonTitle,
          },
          year: {
            title: yearTitle,
          },
          user: {
            fullName,
          },
        }) => (
          <tr key={id}>
            <td>{formatDateTimeLong(createdAt)}</td>
            <td>{identifier}: {lessonTitle}</td>
            <td>{yearTitle}</td>
            <td>{dueOn && formatDateLong(dueOn)}</td>
            <td>{fullName}</td>
          </tr>
        ))
      }
    </tbody>
  </StickyTable>
)

HomeworkAssignments.propTypes = propTypes

export default HomeworkAssignments
