import React from 'react'
import { element, string } from 'prop-types'

import Div from 'src/components/Div'
import studyImage from 'src/components/NoContent/studyImage.svg'
import happyImage from 'src/components/NoContent/happyImage.svg'

const propTypes = {
  backgroundSize: string,
  children: element,
  theme: string,
}

const defaultProps = {
  backgroundSize: 'container',
  children: null,
  theme: 'study',
}

const images = {
  happy: happyImage,
  study: studyImage,
}

const NoContent = ({ children, theme, ...rest }) => (
  <Div
    auto
    flex
    justify-center
    center
    py={4}
    px={4}
    mxn={2}
    myn={2}
    backgroundImage={images[theme]}
    h4
    bold
    {...rest}
  >
    {children}
  </Div>
)

NoContent.propTypes = propTypes
NoContent.defaultProps = defaultProps

export default NoContent
