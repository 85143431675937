import React from 'react'
import { t } from 'i18next'
import { guideLessonPath, storyboardLessonPath } from 'src/lib/routes'
import Widget from 'src/components/Widget'
import PrefixedLink from 'src/components/PrefixedLink'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import { HQ } from 'src/constants/serverRole'

const propTypes = {
}

const defaultProps = {
  gradeSlug: 'all',
  unitSlug: 'all',
}

const Lessons = ({ lessons, subjectSlug, unitSlug, gradeSlug, storyboards }) => {
  const title = t('shared.lessons')
  const linkPath = storyboards ? storyboardLessonPath : guideLessonPath
  return (
    <Deck>
      <Div mt={2}>
        <Div flex auto>
          {
            lessons.length > 0 && (
              <Widget title={title} icon="book">
                {
                  lessons.map(({
                    id,
                    identifier,
                    slug,
                    title,
                    approved,
                    hasUnpublishedChapters,
                  }, i) => (
                    <div
                      className={`mb1 ${i && 'pt1 border-top'}`}
                      key={id}
                    >
                      <PrefixedLink to={linkPath({
                        gradeSlug,
                        lessonSlug: slug,
                        slug,
                        subjectSlug,
                        unitSlug,
                      })}
                      >
                        {identifier}: {title}
                      </PrefixedLink>
                      {
                        !approved && (
                          <span className="inline-block px1 ml1 white bg-red rounded">
                            Unapproved
                          </span>
                        )
                      }
                      {
                        HQ && hasUnpublishedChapters && (
                          <span className="inline-block px1 ml1 white bg-red rounded">
                            Has unpublished chapters
                          </span>
                        )
                      }
                    </div>
                  ))
                }
              </Widget>
            )
          }
        </Div>
      </Div>
    </Deck>
  )
}

Lessons.propTypes = propTypes
Lessons.defaultProps = defaultProps

export default Lessons
