import React from 'react'
import { Motion, spring } from 'react-motion'
import mapKey from 'src/lib/mapKey'
import Audio from 'src/components/Audio'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import Div from 'src/components/Div'
import pencil from 'src/modules/math/LengthUnits/pencil.svg'
import meter from 'src/modules/math/LengthUnits/meter.svg'

const locales = {
  cm: {
    en: { subtitle: <div>100 centimeters = 1 meter<br />centimeter = cm</div>, title: 'Centimeter' },
    th: { subtitle: <div>100 เซนติเมตร = 1 เมตร<br />เซนติเมตร = ซม.</div>, title: '  เซนติเมตร  ' },
  },
  m: {
    en: { subtitle: <div>1 meter = 100 centimeters<br />meter = m</div>, title: 'Meter' },
    th: { subtitle: <div>1 เมตร = 100 เซนติเมตร<br />เมตร = ม.</div>, title: '   เมตร   ' },
  },
  mm: {
    en: { subtitle: <div>10 millimeters = 1 centimeter<br />millimeter = mm</div>, title: 'Millimeter' },
    th: { subtitle: <div>10 มิลลิเมตร = 1 เซนติเมตร<br />มิลลิเมตร = มม.</div>, title: '  มิลลิเมตร  ' },
  },
}

const rulerSlide = (i) => {
  const scale = 10 ** i
  const lineThickness = i + 1
  return (
    <Div flex column px={3}>
      <Motion
        key={scale}
        defaultStyle={{ x: -200 }}
        style={{ x: spring(100, { stiffness: 60 }) }}
      >
        { value => (
          <div className="relative">
            <div style={{ bottom: 0,
              height: '4rem',
              marginLeft: `${value.x > 0 ? value.x : 0}%`,
              position: 'absolute',
              width: '3rem',
            }}
            >
              <div
                style={{
                  backgroundImage: `url("${pencil}")`,
                  backgroundPosition: 'bottom left',
                  bottom: 0,
                  height: `${4 * scale}rem`,
                  left: 0,
                  position: 'absolute',
                  transformOrigin: 'bottom left',
                  width: `${4 * scale}rem`,
                }}
              />
            </div>
            <div
              style={{
                background: 'blue',
                height: `${0.4 * lineThickness}rem`,
                width: `${value.x > 0 ? value.x : 0}%`,
              }}
            />
          </div>
        )}
      </Motion>
      <div
        style={{
          backgroundImage: `url("${meter}")`,
          backgroundPosition: '0 0',
          backgroundSize: '100% 100%',
          paddingTop: `${1.32 * scale}%`,
          transformOrigin: 'top left',
          width: `${100 * scale}%`,
        }}
      />
    </Div>
  )
}

const LengthUnits = ({ locale }) => (
  <Slides>
    {[{ measure: 'm' }, { measure: 'cm' }, { measure: 'mm' }].map(({ measure }, i) => (
      <Slide key={mapKey(i)}>
        <Div size={0} center bold mb={2}>
          {locales.m[locale].title}
        </Div>
        <Div size={0} center bold mb={4}>
          {locales.m[locale].subtitle}
        </Div>
        <Audio src={`/sounds/meters/${locale}-${measure}.mp3`} />
        {rulerSlide(i)}
      </Slide>
    ))}
  </Slides>
)

export default LengthUnits
