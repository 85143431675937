import React from 'react'
import { bool, func, object, string } from 'prop-types'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Bubble from 'src/components/Bubble'
import EntityBubble from 'src/components/EntityBubble'
import AdaptiveCard from 'src/components/AdaptiveCard'

const propTypes = {
  answerId: string,
  autoPlay: bool,
  cards: object,
  entitiesById: object,
  onClick: func.isRequired,
}

const defaultProps = {
  answerId: '',
  autoPlay: true,
  cards: {},
  entitiesById: {},
}

const ListeningPhonemes = ({ entitiesById, onClick, autoPlay, answerId, cards }) => (
  <Deck>
    <Div flex justify-center mb4>
      <EntityBubble
        key={answerId}
        audio
        play
        autoPlay={autoPlay}
        english
        entity={entitiesById[answerId]}
        label=" "
        col={3}
      />
    </Div>
    <Div flex>
      {
        map(cards, card => (
          <Div flex px={1} key={card.id} flex-1>
            <Bubble label={card.label} onKeyPress={() => onClick({ id: card.id })}>
              <AdaptiveCard
                english
                keyToPlay={card.label}
                entity={entitiesById[card.id]}
                selected={card.selected}
                correct={card.correct}
                onClick={() => onClick({ id: card.id })}
              />
            </Bubble>
          </Div>
        ))
      }
    </Div>
  </Deck>
)

ListeningPhonemes.propTypes = propTypes
ListeningPhonemes.defaultProps = defaultProps

export default ListeningPhonemes
