import React from 'react'
import { any, arrayOf, bool, string } from 'prop-types'
import map from 'lodash/map'
import Div from 'src/components/Div'

const propTypes = {
  children: any.isRequired,
  cursor: string,
  disabled: bool,
  tips: arrayOf(string),
  tip: string,
  vertical: bool,
}

const defaultProps = {
  cursor: undefined,
  disabled: false,
  vertical: false,
  tips: [],
  tip: undefined,
}

const Tooltip = ({ tip, tips, children, cursor, disabled, vertical }) => (
  <Div tooltip className={disabled && 'col-12'} style={{ cursor }}>
    {!disabled && (
      <Div
        className={`${vertical ? 'tooltiptextVertical' : 'tooltiptext'}`}
        animated
        fadeIn
        quickly
      >
        {map([].concat(tip).concat(tips), value => (
          value && (
          <div key={value}>
            {value}
          </div>
          )))}
      </Div>
    )}
    {children}
  </Div>
)

Tooltip.defaultProps = defaultProps
Tooltip.propTypes = propTypes

export default Tooltip
