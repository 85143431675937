import React from 'react'
import loadYearEvents from 'src/pages/CalendarPage/loadYearEvents'
import YearSummary from 'src/pages/CalendarPage/YearSummary/YearSummary'
import isAcademicDay from 'src/pages/CalendarPage/YearSummary/isAcademicDay'

const YearSummaryContainer = loadYearEvents(({ calendarEvents, year, ...rest }) => (
  <YearSummary
    {...rest}
    {...year}
    isAcademicDay={isAcademicDay(calendarEvents)}
    calendarEvents={calendarEvents}
  />
))

export default YearSummaryContainer
