import React from 'react'
import usePageParams from 'src/lib/usePageParams'
import { Route, Switch } from 'react-router-dom'
import Lesson, { path as teacherGuidePath } from 'src/pages/TeacherGuides/Lesson'
import Lessons, { path as teacgerGuidesPath } from 'src/pages/TeacherGuides/Lessons'

const LessonsRoute = () => (
  <Switch>
    <Route exact path={teacgerGuidesPath}>
      <Lessons {...usePageParams()} />
    </Route>
  </Switch>
)

const LessonRoute = () => (
  <Switch>
    <Route path={teacherGuidePath}>
      <Lesson {...usePageParams()} />
    </Route>
  </Switch>
)

const routes = [
  {
    path: teacgerGuidesPath,
    component: LessonsRoute,
  },
  {
    path: teacherGuidePath,
    component: LessonRoute,
  },
]

export default routes
