import React from 'react'
import { element, string, shape } from 'prop-types'

const propTypes = {
  children: element.isRequired,
  ratio: string,
  style: shape({}),
}

const defaultProps = {
  ratio: '16x9',
  style: {},
}

const AspectRatio = ({ children, ratio, style }) => (
  <div className={`relative ratio-${ratio}`} style={style}>
    <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-column">
      {children}
    </div>
  </div>
)

AspectRatio.propTypes = propTypes
AspectRatio.defaultProps = defaultProps

export default AspectRatio
