const normalizeEntity = entity => ({
  completed: entity.completed || false,
  entityId: entity.entityId || null,
  id: entity.id || '',
  image: entity.image || {},
  meaning: entity.meaning || {},
  meta: entity.meta || {},
  partOfSpeech: entity.partOfSpeech || [],
  phonicsEn: entity.phonicsEn || [],
  phonicsTh: entity.phonicsTh || [],
  slug: entity.slug || '',
  sound: entity.sound || {},
  title: entity.title || '',
  titleEn: entity.titleEn || '',
  titleTh: entity.titleTh || '',
})

export default normalizeEntity
