import { useState } from 'react'

const RESIGN = 'RESIGN'
const ASSIGN = 'ASSIGN'
const DO_NOTHNG = () => {}

const isAsync = fn => fn.constructor.name === 'AsyncFunction'
const asAsync = (fn) => {
  const a = async () => fn()
  return a
}

const useSubmitMode = (assign, resign) => {
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [submitMode, setSubmitMode] = useState(null)
  const [submitTitleKey, setSubmitTitleKey] = useState(null)
  const [submitWarning, setSubmitWarning] = useState(false)

  const switchResignMode = () => {
    setSubmitMode(RESIGN)
    setSubmitEnabled(true)
    setSubmitTitleKey('school.users.resign')
    setSubmitWarning(true)
  }
  const switchAssignMode = () => {
    setSubmitMode(ASSIGN)
    setSubmitEnabled(true)
    setSubmitTitleKey('school.users.assign')
    setSubmitWarning(false)
  }
  const switchDisabledMode = () => {
    setSubmitMode(null)
    setSubmitEnabled(false)
  }

  const submitAsync = () => {
    const actions = {
      ASSIGN: assign,
      RESIGN: resign,
    }
    const action = (actions[submitMode] || DO_NOTHNG)

    return isAsync(action)
      ? action()
      : asAsync(action)()
  }

  return {
    submitAsync,
    submitEnabled,
    submitMode,
    submitTitleKey,
    submitWarning,
    switchAssignMode,
    switchDisabledMode,
    switchResignMode,
  }
}

export default useSubmitMode
