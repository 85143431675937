import React, { Component } from 'react'
import { func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/SelectParagraphTitle/reducer'
import SelectParagraphTitle from 'src/modules/SelectParagraphTitle/SelectParagraphTitle'

const propTypes = {
  ...gamePropTypes,
  onClick: func.isRequired,
}

class SelectParagraphTitleContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      image,
      paragraph,
      cards,
      completed,
      entitiesById,
      mark,
      onClick,
      retry,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <SelectParagraphTitle
          image={image}
          paragraph={paragraph}
          cards={cards}
          onClick={onClick}
          entitiesById={entitiesById}
        />
      </Game>
    )
  }
}

SelectParagraphTitleContainer.propTypes = propTypes
SelectParagraphTitleContainer.demoData = {
  entities: [
    'paragraph-all-about-me',
    'paragraph-ice-cream-with-the-family',
    'paragraph-cinderella-part-2',
    'paragraph-christmas-tree-story-1',
  ],
}

const select = state => state.selectParagraphTitle
const actions = dispatch => bindActionCreators(gameActions, dispatch)
export { SelectParagraphTitleContainer }
export default connect(select, actions)(SelectParagraphTitleContainer)
