import React, { Component } from 'react'
import { bool, number, string } from 'prop-types'
import mapKey from 'src/lib/mapKey'

const TICK_ID = 'tick'

/* eslint react/no-unused-prop-types: 0 */
// we forward props to opts
const propTypes = {
  currentValue: number,

  dialColor: string,
  dialWidth: number,

  downProgressColor: string,
  maximumValue: number,
  needle: bool,
  needleBaseColor: string,

  needleBaseSize: number,
  needleColor: string,
  needleSharp: bool,
  needleWidth: number,
  progressColor: string,
  progressFont: string,
  progressFontSize: string,
  progressRoundedEdge: bool,

  progressWidth: number,
  size: number,
  tickColor: string,
  tickInterval: number,
  tickLength: number,
  tickWidth: number,
}

const defaultProps = {
  currentValue: 25,

  dialColor: '#eee',
  dialWidth: 8,

  downProgressColor: 'red',
  maximumValue: 100,
  needle: true,
  needleBaseColor: '#9d9d9d',

  needleBaseSize: 5,
  needleColor: '#8a8a8a',
  needleSharp: false,
  needleWidth: 3,
  progressColor: '#0E85D3',
  progressFont: 'Serif',
  progressFontSize: '60',
  progressRoundedEdge: true,

  progressWidth: 12,
  size: 200,
  tickColor: '#cacaca',
  tickInterval: 10,
  tickLength: 8,
  tickWidth: 3,
}

class Gauge extends Component {
  defineTick = (opts) => {
    const tX1 = (opts.cX + opts.radius) - (Math.max(opts.dialWidth, opts.progressWidth) / 2)
    const tX2 = tX1 - opts.tickLength

    return (
      <line
        id={TICK_ID}
        x1={tX1}
        y1={opts.cY}
        x2={tX2}
        y2={opts.cY}
        stroke={opts.tickColor}
        strokeWidth={opts.tickWidth}
      />
    )
  }

  renderDial = opts => (
    <circle
      cx={opts.cX}
      cy={opts.cY}
      r={opts.radius}
      fill="none"
      stroke={opts.dialColor}
      strokeWidth={opts.dialWidth}
    />
  )

  renderTicks = (opts) => {
    const tickAngles = []
    for (let i = 0; i <= 360; i += opts.tickInterval) {
      tickAngles.push(i)
    }
    return (
      <g className="ticks">
        {
          tickAngles.map((tickAngle, idx) => (
            <use
              href={`#${TICK_ID}`}
              key={mapKey(idx)}
              transform={`rotate(${tickAngle} ${opts.cX} ${opts.cY})`}
            />
          ))
        }
      </g>
    )
  };

  renderProgress = (opts) => {
    const offset = (opts.circumference * (1 - (opts.currentValue / opts.maximumValue)))
    return (
      <circle
        cx={opts.cX}
        cy={opts.cY}
        r={opts.radius}
        fill="none"
        stroke={opts.progressColor}
        strokeWidth={opts.progressWidth}
        strokeDasharray={opts.circumference}
        strokeDashoffset={offset}
        strokeLinecap={opts.progressRoundedEdge ? 'round' : 'butt'}
      />
    )
  }

  renderNeedle = (opts) => {
    const x1 = opts.cX
    const y1 = opts.cY - (opts.needleWidth / 2)
    const x2 = opts.cX
    const y2 = opts.cY + (opts.needleWidth / 2)
    const x3 = opts.diameter
    const y3 = opts.cY
    const needleAngle = (360 * opts.currentValue) / opts.maximumValue

    let needleElm = null
    if (opts.needleSharp) {
      needleElm = (
        <polygon
          points={`${x1},${y1} ${x2},${y2} ${x3},${y3}`}
          fill={opts.needleColor}
        />
      )
    } else {
      needleElm = (
        <line
          x1={opts.cX}
          y1={opts.cY}
          x2={opts.diameter}
          y2={opts.cY}
          fill="none"
          strokeWidth={opts.needleWidth}
          stroke={opts.needleColor}
        />
      )
    }

    return (
      <g className="needle">
        <g transform={`rotate(${needleAngle} ${opts.cX} ${opts.cY})`}>
          {needleElm}
        </g>
        <circle
          cx={opts.cX}
          cy={opts.cY}
          r={opts.needleBaseSize}
          fill={opts.needleBaseColor}
        />
      </g>
    )
  }

  renderText = opts => (
    <text
      x={opts.cX}
      y={opts.cY + 55}
      fontFamily={opts.progressFont}
      fontSize={opts.progressFontSize}
      transform={`rotate(90 ${opts.cX} ${opts.cY})`}
      textAnchor="middle"
      fill={opts.progressColor}
    >
      {opts.currentValue}
    </text>
  )

  render() {
    const { size, dialWidth } = this.props

    const cX = size / 2
    const cY = size / 2
    const radius = (size - (2 * dialWidth)) / 2
    const diameter = 2 * radius
    const circumference = 2 * Math.PI * radius
    const opts = {
      ...this.props,
      cX,
      cY,
      circumference,
      diameter,
      radius,
    }

    return (
      <svg
        className={opts.className}
        height="100%"
        width="100%"
        viewBox={`0 0 ${size} ${size}`}
      >
        <defs>
          {this.defineTick(opts)}
        </defs>
        <g transform={`rotate(-90 ${cX} ${cY})`}>
          {this.renderDial(opts)}
          {this.renderTicks(opts)}
          {this.renderProgress(opts)}
          {this.renderNeedle(opts)}
          {this.renderText(opts)}
        </g>
      </svg>
    )
  }
}

Gauge.propTypes = propTypes
Gauge.defaultProps = defaultProps

export default Gauge
