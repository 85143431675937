import React from 'react'
import { Field } from 'formik'
import { t } from 'i18next'
import { string, node, object } from 'prop-types'
import ValidationErrorMessage from 'src/components/ValidationErrorMessage'
import pluck from 'src/lib/pluck'

const fieldPropTypes = {
  children: node,
  className: string,
  field: object,
  form: object,
  labelToken: string,
  label: string,
  name: string,
}

const InnerField = ({
  form,
  field,
  className,
  labelToken,
  label = t(labelToken),
  children,
}) => {
  const fieldErrors = pluck(form.errors, field.name)
  const fieldTouched = pluck(form.touched, field.name)
  return (
    <div className={className}>
      <label htmlFor={field.name}>{label}</label>
      <div>{React.cloneElement(children, { ...field })}</div>
      {fieldErrors && fieldTouched ? (
        <ValidationErrorMessage name={field.name} messages={fieldErrors} />
      ) : null}
    </div>
  )
}

const GenericField = ({ name, label, className, labelToken, children }) => (
  <Field name={name}>
    {({ field, form }) => (
      <InnerField
        className={className}
        field={field}
        form={form}
        label={label}
        labelToken={labelToken}
        name={name}
      >
        {children}
      </InnerField>
    )}
  </Field>
)

const propTypes = {
  children: node,
  className: string,
  labelToken: string,
  name: string,
  label: string,
}

GenericField.propTypes = propTypes
InnerField.propTypes = fieldPropTypes

export default GenericField

export { InnerField }
