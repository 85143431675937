import React from 'react'
import { shape, string, func } from 'prop-types'
import Div from 'src/components/Div'
import Widget from 'src/components/Widget'
import { SubjectSelectorWithStyle } from 'src/selectors/SubjectSelector'
import ReleasedHomeworkProgress from 'src/pages/ProgressPage/ReleasedHomeworkProgress'
import { progressReportPath } from 'src/lib/routes'
import usePath from 'src/lib/routes/usePath'
import LessonSelector from 'src/pages/ProgressPage/AttainmentProgress/LessonSelector'
import StudentAttainmentProgress from 'src/pages/ProgressPage/AttainmentProgress/StudentAttainmentProgress'
import UnitList from 'src/pages/ProgressPage/AttainmentProgress/UnitList'

const propTypes = {
  academicTermId: string,
  academicYearId: string,
  limit: string,
  onSubjectChange: func,
  onUnitChange: func,
  subject: shape({
    id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  unitSlug: string,
  yearId: string.isRequired,
}

const defaultProps = {
  academicTermId: undefined,
  academicYearId: undefined,
  limit: undefined,
  onSubjectChange: () => {},
  onUnitChange: () => {},
  unitSlug: undefined,
}

const AttainmentProgress = ({
  academicTermId,
  academicYearId,
  onSubjectChange,
  onUnitChange,
  limit,
  subject,
  unitSlug,
  yearId,
}) => {
  const path = usePath(progressReportPath)
  return (
    <Div flex auto relative>
      <SubjectSelectorWithStyle
        subjectId={subject.slug}
        onChange={onSubjectChange}
      />
      <Widget title={subject.title} icon="book" noPrint={false}>
        <Div flex auto column mxn2>
          <UnitList
            onChange={onUnitChange}
            subjectId={subject.slug}
            unitSlug={unitSlug}
            yearId={yearId}
          />
          { unitSlug && (
            <LessonSelector
              limit={limit}
              path={limit => path({ limit })}
              unitSlug={unitSlug}
            />
          )}
          <ReleasedHomeworkProgress
            limit={limit}
            subjectId={subject.slug}
            unitSlug={unitSlug}
            yearId={yearId}
            academicYearId={academicYearId}
            academicTermId={academicTermId}
          />
          <StudentAttainmentProgress
            academicTermId={academicTermId}
            academicYearId={academicYearId}
            limit={limit}
            subjectId={subject.slug}
            unitSlug={unitSlug}
            yearId={yearId}
          />
        </Div>
      </Widget>
    </Div>
  )
}

AttainmentProgress.propTypes = propTypes
AttainmentProgress.defaultProps = defaultProps

export default AttainmentProgress
