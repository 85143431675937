import React from 'react'
import childrenShape from 'src/shapes/children'
import Access from 'src/components/Access'
import Announcement from 'src/pages/SchoolLayout/Announcement'
import Menu from 'src/pages/TheLayout/Menu'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'
import DomainContext from 'src/contexts/DomainContext'
import Div from 'src/components/Div'

const propTypes = {
  children: childrenShape,
}
const defaultProps = {
  children: null,
}
const SchoolLayout = ({ children }) => (
  <DomainContext.Provider value={SCHOOL_SCOPE}>
    <Access redirect staff>
      <Announcement today />
      <Div flex column auto p1>
        <Menu />
        {children}
      </Div>
    </Access>
  </DomainContext.Provider>
)
SchoolLayout.propTypes = propTypes
SchoolLayout.defaultProps = defaultProps

export default SchoolLayout
