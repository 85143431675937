import { number, string } from 'prop-types'
import React from 'react'
import Div from 'src/components/Div'
import Card from 'src/components/Card'

const propTypes = {
  headerHeight: number.isRequired,
  image: string.isRequired,
}

const ImageHeader = ({ image, headerHeight }) => (
  <Div flex justify-center>
    <Card image title={image} height={headerHeight} minHeight={false} />
  </Div>
)

ImageHeader.propTypes = propTypes

export default ImageHeader
