import React from 'react'
import classNames from 'classnames'
import { string } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const Divider = ({ className, ...props }) => (
  <Div
    border-top
    className={classNames({
      [className]: className,
    })}
    {...props}
  />
)

Divider.propTypes = propTypes
Divider.defaultProps = defaultProps

export default Divider
