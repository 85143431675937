import React from 'react'
import Card from 'src/components/Card'
import { bool, func, string, element } from 'prop-types'

const propTypes = {
  back: element,
  className: string,
  flipped: bool.isRequired,
  front: element.isRequired,
  onClick: func,
}
const defaultProps = {
  back: null,
  className: '',
  onClick: () => {},
}

const Flipper = ({
  onClick,
  flipped,
  front,
  back,
  className,
}) => (
  <div className={`Flipper cursor-pointer ${className}`} onClick={onClick}>
    <div className={`Flipper-container flex flex-column ${flipped && 'flipped'}`}>
      <div className="Flipper-front">{front}</div>
      <div className="Flipper-back absolute stretch flex">
        {back || <Card button className="Flipper-back-bg" />}
      </div>
    </div>
  </div>
)

Flipper.propTypes = propTypes
Flipper.defaultProps = defaultProps

export default Flipper
