import React from 'react'
import { bool, func, node } from 'prop-types'
import AudioMeter from 'src/components/AudioMeter'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import KeyHandler from 'src/components/KeyHandler'
import { isMobile } from 'src/lib/window'

const propTypes = {
  children: node,
  hideKeyHandler: bool,
  loading: bool,
  onKeyPress: func.isRequired,
  onMouseDown: func.isRequired,
  onMouseUp: func.isRequired,
  opaque: bool,
  selected: bool,
}

const defaultProps = {
  hideKeyHandler: false,
  loading: null,
  opaque: false,
  selected: null,
}

const MicrophoneButton = ({
  onMouseDown,
  onMouseUp,
  onKeyPress,
  opaque,
  selected,
  loading,
  children,
  hideKeyHandler,
}) => {
  const className = isMobile() ? 'bg-yellow-fade-in-green-2x' : 'bg-yellow-fade-in-green'
  return (
    <Div
      round
      flex
      justify="center"
      mx2
      border-print
      MicrophoneButton
    >
      <div
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={(e) => {
          e.preventDefault()
          onMouseDown(e)
        }}
        onTouchEnd={(e) => {
          e.preventDefault()
          onMouseUp(e)
        }}
        tabIndex={0}
        role="button"
      >
        <Div
          align-center
          bold
          center
          animated
          overflow-hidden
          className={selected ? className : 'bg-white shadow'}
          cursor-pointer
          flex
          flex-column
          justify-center
          line-height-12
          locale="en"
          muted={!selected && !opaque}
          px1
          relative
          round
          size={0}
          transition-all
          z3
        >
          {selected && <div className="absolute stretch"><AudioMeter /></div>}
          <Div auto flex justify="center" align="center" py2>
            <Div relative>
              {
                loading && !selected
                  ? (
                    <div className="center animated slowly">
                      <Icon spin muted icon="circle-notch" />
                    </div>
                  )
                  : <Icon icon="microphone" className="relative z1" />
              }
              {!hideKeyHandler &&
                <KeyHandler label=" " onKeyPress={onKeyPress} enableKeyUp noPrint={false} />}
            </Div>
          </Div>
          {children}
        </Div>
      </div>
    </Div>
  )
}

MicrophoneButton.propTypes = propTypes
MicrophoneButton.defaultProps = defaultProps
export default MicrophoneButton
