import React from 'react'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'
import { useHistory } from 'react-router'

import parseErrors from 'src/lib/form/parseErrors'
import RegistrationForm from 'src/pages/Registration/RegistrationForm'
import registrationCreateMutation from 'src/pages/Registration/registrationCreateMutation.gql'
import { successMessage } from 'src/lib/toaster'
import { pathDone } from 'src/routes/registration'

const showSuccessMessage = () => {
  const title = t('save.success')
  successMessage({ title })
}

const RegistrationFormContainer = () => {
  const history = useHistory()
  return (
    <Mutation mutation={registrationCreateMutation}>
      {(registrationCreate, { error }) => (
        <RegistrationForm
          errors={parseErrors(error)}
          onSubmit={(values, { setSubmitting, setErrors }) =>
            registrationCreate({ variables: values })
              .then(() => {
                showSuccessMessage()
                history.push(pathDone)
              })
              .catch((errors) => {
                setErrors(parseErrors(errors))
              })
              .finally(() => {
                setSubmitting(false)
              })
          }
        />
      )}
    </Mutation>
  )
}

export default RegistrationFormContainer
