import React from 'react'
import { arrayOf, bool, shape, string } from 'prop-types'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/react-hooks'

import CurrentUser from 'src/pages/CurrentUser/CurrentUser'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import SelectStyle from 'src/components/SelectStyle'
import gqlLoader from 'src/lib/gqlLoader'
import allGradesQuery from 'src/pages/Registration/allGrades.gql'
import userSetLevelMutation from 'src/components/LevelSelector/userSetLevelMutation.gql'
import Fakebutton from 'src/components/Fakebutton'

import sectionShape from 'src/shapes/section'

const propTypes = {
  animated: bool,
  sections: arrayOf(shape(sectionShape)).isRequired,
  sectionSlug: string,
  small: bool,
  userId: string.isRequired,
}

const defaultProps = {
  animated: false,
  sectionSlug: 'primary',
  small: false,
}

const LevelSelector = ({ small, userId, sections, animated, sectionSlug }) => {
  const history = useHistory()
  const [userSetLevel] = useMutation(userSetLevelMutation)
  if (!sections) return null
  const section = sections.find((s) => s.slug === sectionSlug)
  if (!section) return null
  return (
    <CurrentUser id={userId}>
      {({ user }) => {
        if (!user.year) return <div>Year is missing</div>

        const currentGrade = section.grades.find(
          ({ id }) => id === user.year.grade.id
        )
        return (
          <Div flex justify-center>
            <Div relative animated={animated} transition tada>
              <Fakebutton>
                <Icon before icon="users" />
                <span className="bold pr3">{currentGrade.title}</span>
              </Fakebutton>
              <SelectStyle className="bottom-0" white p={small ? 'p' : undefined}>
                <select
                  onChange={({ target: { value: gradeId } }) => {
                    userSetLevel({ variables: { gradeId, userId } })
                      .then(() => history.go())
                  }}
                  className="cursor-pointer"
                  value={currentGrade.id}
                >
                  {section.grades.map((grade) => (
                    <option key={grade.id} value={grade.id}>
                      {grade.title}
                    </option>
                  ))}
                </select>
              </SelectStyle>
            </Div>
          </Div>
        )
      }}
    </CurrentUser>
  )
}

LevelSelector.propTypes = propTypes
LevelSelector.defaultProps = defaultProps
export default gqlLoader(allGradesQuery)(LevelSelector)
