import chunk from 'lodash/chunk'
import every from 'lodash/every'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import map from 'lodash/map'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'MatchTextWithText/INIT'
const MARK = 'MatchTextWithText/MARK'
const RETRY = 'MatchTextWithText/RETRY'
const MOVE = 'MatchTextWithText/MOVE'

const MAXIMUM_PER_ROW = 6

const initialState = {
  actualEntities: [],
  cards: {},
  chunkPlaces: [],
  completed: null,
  entitiesById: {},
  places: {},
  types: [],
}

const checkGameCompleteness = places => every(places, 'correct')

const createTypes = ({ types }, entity) => {
  const prefix = entity.slug.split('-')[0]
  if (prefix === 'collection') {
    return {
      types: types.concat(entity.titleEn),
    }
  }
  return {
    types,
  }
}

const createCard = ({ types, parent }) => (a, entity) => (
  {
    ...a,
    [`${parent}-${entity.id}`]: {
      id: entity.id,
      key: `${parent}-${entity.id}`,
      parent,
      type: types[size(a)],
    },
  }
)

const createCards = types => (a, cardChunk) => {
  const cards = cardChunk.reduce(createCard({ parent: cardChunk[0].id, types }), {})
  return {
    ...a,
    ...cards,
  }
}

const createPlace = type => (a, card) => (
  {
    ...a,
    [`place-${type}-${size(a)}`]: {
      cardId: card.id,
      cardKey: card.key,
      group: size(a),
      id: `place-${type}-${size(a)}`,
      parent: card.parent,
      type,
    },
  }
)

const createPlaces = cards => (a, type, index) => {
  const cardsInType = cards[index] || []
  const places = cardsInType.reduce(createPlace(type), {})
  return {
    ...a,
    ...places,
  }
}

const swapCards = ({ sourceId, targetId, places, cards }) => {
  const newPlace = {
    ...places[targetId],
    cardId: places[sourceId].cardId,
    cardKey: cards[places[sourceId].cardKey].key,
    parent: cards[places[sourceId].cardKey].parent,
  }
  const oldPlace = {
    ...places[sourceId],
    cardId: places[targetId].cardId,
    cardKey: cards[places[targetId].cardKey].key,
    parent: cards[places[targetId].cardKey].parent,
  }
  return {
    ...places,
    [places[targetId].id]: newPlace,
    [places[sourceId].id]: oldPlace,
  }
}

const clearPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.correct ? true : null,
    },
  }
)

const findAllParent = ({ cards, entityId, allPlaces, group }) => {
  const allDuplicateEntities = filter(cards, { id: entityId })
  const allParent = allDuplicateEntities.map(parent => parent.parent)
  const allEntitiesInRow = filter(allPlaces, { group })
  const selectedParent = filter(allEntitiesInRow, parent => allParent.includes(parent.cardId))
  return selectedParent.length > 0
}

const setPlacesStatus = ({ cards, places }, place, _, allPlaces) => (
  {
    cards,
    places: {
      ...places,
      [place.id]: {
        ...place,
        correct: findAllParent({
          allPlaces,
          cards,
          entityId: place.cardId,
          group: place.group,
        })
          && place.type === cards[place.cardKey].type,
      },
    },
  }
)


const updateRowStatus = rowStatus => (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: rowStatus,
    },
  }
)

const updateRowsStatus = (a, places) => {
  const newPlaces = places.reduce(updateRowStatus(checkGameCompleteness(places)), {})
  return {
    ...a,
    ...newPlaces,
  }
}

const rotateArray = a => a.reduce((col, row) => row.map((_, i) => [...(col[i] || []), row[i]]), [])

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const { types } = entities.reduce(createTypes, { types: [] })
      const chunkOfEntites = chunk(entities.slice(types.length), types.length).slice(0, MAXIMUM_PER_ROW)
      const numberOfWord = chunkOfEntites.length
      const cards = chunkOfEntites.reduce(createCards(types), {})
      const cardChunk = chunk(uniqShuffle(Object.values(cards)), size(cards) / types.length)
      const places = types.reduce(createPlaces(cardChunk), {})
      const chunkPlaces = rotateArray(chunk(map(places, 'id'), numberOfWord))
      return {
        ...initialState,
        actualEntities: [].concat(...chunkOfEntites),
        cards,
        chunkPlaces,
        entitiesById: keyBy(entities, 'id'),
        numberOfWord,
        places,
        types,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const placesClearStatus = reduce(state.places, clearPlacesStatus, {})
      const places = swapCards({ cards: state.cards, places: placesClearStatus, sourceId, targetId })
      return {
        ...state,
        places,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    case MARK: {
      const { places } = reduce(
        state.places,
        setPlacesStatus,
        { cards: state.cards },
      )
      const newPlaces = reduce(groupBy(places, 'group'), updateRowsStatus, {})
      return {
        ...state,
        completed: checkGameCompleteness(places),
        places: newPlaces,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, variants }) => ({
  payload: {
    entities,
    variants,
  },
  type: INIT,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

export {
  init,
  retry,
  mark,
  moveCard,
}

export default reducer
