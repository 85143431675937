import React from 'react'
import { useParams, useHistory } from 'react-router'
import { linkTo } from 'src/lib/linkTo'
import StudentAttendanceLayoutContainer from 'src/pages/StudentAttendance/StudentAttendanceLayoutContainer'

const StudentAttendanceContainer = ({ path, userId }) => {
  const { parentId, subjectId } = useParams()
  const history = useHistory()
  return (
    <StudentAttendanceLayoutContainer
      userId={userId}
      subjectId={subjectId}
      onSubjectChange={subjectId => history.push(
        linkTo(path)({
          parentId,
          studentId: userId,
          subject: 'subject',
          subjectId,
          userTab: 'attendance',
        }),
      )}
    />
  )
}

export default StudentAttendanceContainer
