import React, { Component } from 'react'
import { array } from 'prop-types'
import uniqShuffle from 'src/lib/uniqShuffle'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Bubble from 'src/components/Bubble'
import Card from 'src/components/Card'
import Game from 'src/components/Game'
import Gauge from 'src/components/Gauge'
import mapKey from 'src/lib/mapKey'
import ImageAndWord from 'src/modules/ImageTimerWords/ImageAndWord'

const propTypes = {
  entities: array,
}

const defaultProps = {
  entities: [],
}

const TIME_OPTIONS = [5, 10, 15]


class ImageTimerWords extends Component {
  constructor(props) {
    super(props)
    const entity = uniqShuffle(this.props.entities)[0]
    this.state = {
      duration: 10,
      entity,
      intervalId: -1,
      showWord: false,
      time: 0,
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  timeStart = (duration) => {
    if (this.state.intervalId === this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(this.tick, 1000)
    this.setState({
      duration,
      intervalId: this.interval,
      showWord: false,
      time: duration,
    })
  }

  newWord = () => {
    clearInterval(this.interval)
    this.setState({
      duration: 10,
      entity: uniqShuffle(this.props.entities)[0],
      showWord: false,
      time: 0,
    })
  }

  tick = () => {
    if (this.state.time > 0) {
      this.setState({
        time: this.state.time - 1,
      })
    } else {
      this.setState({
        showWord: true,
      })
      clearInterval(this.interval)
    }
  }

  render() {
    return (
      <Game>
        <Deck>
          <Div flex auto>
            <ImageAndWord entity={this.state.entity} showWord={this.state.showWord} />
            <Div flex column p2 justify-center>
              <Div flex height={25} width={25}>
                <Gauge
                  maximumValue={this.state.duration}
                  currentValue={this.state.time}
                />
              </Div>
              <Div flex>
                {
                  TIME_OPTIONS.map((time, index) => (
                    <Div key={mapKey(time)} auto flex p1>
                      <Bubble label={index + 1} onKeyPress={() => this.timeStart(time)}>
                        <Card
                          title={`${time}s`}
                          minHeight={false}
                          onClick={() => this.timeStart(time)}
                          size={2}
                        />
                      </Bubble>
                    </Div>
                  ))
                }
              </Div>
              <Div auto flex px1 mt4>
                <Bubble label={0} onKeyPress={() => this.newWord()}>
                  <Card
                    title="Next"
                    minHeight={false}
                    onClick={() => this.newWord()}
                    size={2}
                  />
                </Bubble>
              </Div>
            </Div>
          </Div>
        </Deck>
      </Game>
    )
  }
}

ImageTimerWords.defaultProps = defaultProps
ImageTimerWords.propTypes = propTypes
ImageTimerWords.demoData = {
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
  ],
}

export default ImageTimerWords
