import React from 'react'
import { number, string } from 'prop-types'

const KawaiiSpeechBubble = props => (
  <div
    style={{
      background: props.color,
      bottom: '50%',
      minHeight: `${props.width / 2}rem`,
      right: `${props.width}rem`,
      width: `${props.width * 2}rem`,
    }}
    className={`speech ${props.classNames}`}
  >
    <div
      className="after"
      style={{
        background: props.color,
      }}
    />
    <span
      className="text"
      style={{
        fontSize: `${props.width / 2}rem`,
      }}
    >
      {props.text}
    </span>
  </div>
)

KawaiiSpeechBubble.propTypes = {
  classNames: string,
  color: string,
  text: string,
  width: number.isRequired,
}

KawaiiSpeechBubble.defaultProps = {
  classNames: null,
  color: '#83D1FB',
  rectangular: true,
  text: null,
  width: 16,
}

export default KawaiiSpeechBubble
