import React from 'react'
import { useParams, useHistory } from 'react-router'
import UserCardsContainer from 'src/pages/preview/UserCards/UserCardsContainer'

const UserCards = () => {
  const params = useParams()
  const history = useHistory()
  return <UserCardsContainer history={history} {...params} />
}

export default UserCards
