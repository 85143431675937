import React from 'react'
import { t } from 'i18next'
import { arrayOf, number, string, oneOfType, func } from 'prop-types'

const propTypes = {
  isSelected: func.isRequired,
  selection: arrayOf(oneOfType([number, string])).isRequired,
  update: func.isRequired,
}

const ToggleSelection = ({ selection, update, isSelected }) => {
  const selected = selection.filter(isSelected)
  const toggleOffMode = selected.length === selection.length
  const toggle = () => {
    update(selection, !toggleOffMode)
  }

  const label = toggleOffMode
    ? t('selection.toggle-off')
    : t('selection.toggle-on')

  /* eslint-disable jsx-a11y/label-has-associated-control  */
  /* eslint-disable jsx-a11y/label-has-for  */
  return <label><input type="checkbox" onClick={toggle} checked={toggleOffMode} />{label}</label>
  /* eslint-enable jsx-a11y/label-has-associated-control  */
  /* eslint-enable jsx-a11y/label-has-for  */
}

ToggleSelection.propTypes = propTypes

export default ToggleSelection
