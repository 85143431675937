import logger from 'redux-logger'
import debounce from 'redux-debounced'
import thunk from 'redux-thunk'
import { compose, createStore, applyMiddleware } from 'redux'
import rootReducer from 'src/reducers'

const initialState = {}
const reducer = compose()(rootReducer)
const middlewares = [debounce(), thunk, logger]

const configureIsolatedStore = () => createStore(reducer, initialState, compose(
  applyMiddleware(...middlewares),
))

export default configureIsolatedStore
