import React from 'react'
import { func, string, shape } from 'prop-types'
import Div from 'src/components/Div'
import { SubjectSelectorWithStyle } from 'src/selectors/SubjectSelector'
import Widget from 'src/components/Widget'
import ReleasedHomeworkProgress from 'src/pages/ProgressPage/ReleasedHomeworkProgress'
import SubjectCoverage from 'src/pages/ProgressPage/CurriculumProgress/SubjectCoverage'

const propTypes = {
  academicTermId: string,
  academicYearId: string,
  onStrandChange: func.isRequired,
  onSubjectChange: func.isRequired,
  standardSlug: string,
  strandSlug: string,
  subject: shape({
    slug: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  userId: string,
  yearId: string,
}

const defaultProps = {
  standardSlug: '',
  strandSlug: '',
  userId: null,
  yearId: null,
}

const CurriculumProgress = ({
  academicTermId,
  academicYearId,
  onStrandChange,
  onSubjectChange,
  standardSlug,
  strandSlug,
  subject,
  userId,
  yearId,
}) => (
  <Div flex auto relative>
    <SubjectSelectorWithStyle subjectId={subject.slug} onChange={onSubjectChange} />
    <Widget title={subject.title} icon="book" noPrint={false}>
      { yearId && (
      <Div flex auto column mxn2>
        <ReleasedHomeworkProgress
          subjectId={subject.slug}
          yearId={yearId}
          academicYearId={academicYearId}
          academicTermId={academicTermId}
        />
        <SubjectCoverage
          key={subject.slug}
          academicTermId={academicTermId}
          academicYearId={academicYearId}
          onChange={onStrandChange}
          standardSlug={standardSlug}
          strandSlug={strandSlug}
          subjectId={subject.slug}
          yearId={yearId}
          userId={userId}
        />
      </Div>
      )}
    </Widget>
  </Div>
)

CurriculumProgress.propTypes = propTypes
CurriculumProgress.defaultProps = defaultProps

export default CurriculumProgress
