import React, { Component } from 'react'
import { number, shape, string, bool, func, object } from 'prop-types'
import Div from 'src/components/Div'
import { getRandomInt } from 'src/lib/getRandomNumber'
import TargetController from 'src/components/ObjectTarget/TargetController'

const propTypes = {
  correct: bool,
  cursive: bool,
  decorateTargetImage: string,
  delay: number,
  disappear: bool,
  disappearTargetImage: string,
  duration: number,
  effectImage: string,
  entity: shape(),
  entityImageStyle: object,
  height: number,
  justify: string,
  mixFont: bool,
  onClick: func,
  rotate: bool,
  secondEffectImage: string,
  selected: bool,
  style: object,
  targetImage: string,
  text: bool,
  width: number,
}

const defaultProps = {
  correct: null,
  cursive: null,
  decorateTargetImage: null,
  delay: null,
  disappear: null,
  disappearTargetImage: null,
  duration: null,
  effectImage: null,
  entity: null,
  entityImageStyle: { padding: '1rem' },
  height: 15,
  justify: 'center',
  mixFont: null,
  onClick: null,
  rotate: null,
  secondEffectImage: null,
  selected: null,
  style: {},
  targetImage: null,
  text: null,
  width: 18,
}

const targetWillAppear = () => getRandomInt(0, 1) === 1

class ObjectTarget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clickable: true,
      cursive: getRandomInt(0, 1) === 1,
      rotate: getRandomInt(-15, 15),
    }
  }

  componentDidMount() {
    const { disappear } = this.props
    if (disappear) {
      this.setState({ clickable: targetWillAppear() })
      this.interval = setInterval(this.tick, getRandomInt(2000, 5000))
    }
  }

  componentWillUnmount() {
    const { disappear } = this.props
    if (disappear) {
      clearInterval(this.interval)
    }
  }

  getClick = () => {
    const { onClick } = this.props
    if (this.state.clickable) return onClick()
    return null
  }

  tick = () => {
    if (this.state.clickable) {
      this.setState({ clickable: false })
    } else {
      this.setState({ clickable: true })
    }
  }

  render() {
    const {
      correct,
      decorateTargetImage,
      disappearTargetImage,
      effectImage,
      entity,
      entityImageStyle,
      height,
      cursive,
      justify,
      mixFont,
      rotate,
      secondEffectImage,
      selected,
      style,
      targetImage,
      text,
      width,
    } = this.props

    const rotateStyle = rotate && { transform: `rotate(${this.state.rotate}deg)` }
    const customStyle = {
      ...rotateStyle,
    }
    return (
      <Div
        relative
        height={height}
        width={width}
        style={customStyle}
        onClick={this.getClick}
      >
        <TargetController
          {...{
            clickable: this.state.clickable,
            correct,
            cursive: cursive || (mixFont && this.state.cursive),
            decorateTargetImage,
            disappearTargetImage,
            effectImage,
            entity,
            entityImageStyle,
            height,
            justify,
            secondEffectImage,
            selected,
            style,
            targetImage,
            text,
            width,
          }}
        />
      </Div>
    )
  }
}

ObjectTarget.propTypes = propTypes
ObjectTarget.defaultProps = defaultProps

export default ObjectTarget
