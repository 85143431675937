import React from 'react'

const defaultColorScheme = {
  errorColor: 'red',
}

const ColorScheme = React.createContext(defaultColorScheme)

export { defaultColorScheme }
export default ColorScheme
