class BaseSpeechRecognition {
  // eslint-disable-next-line class-methods-use-this
  abort() {
  }

  // eslint-disable-next-line class-methods-use-this
  start() {
  }

  // eslint-disable-next-line class-methods-use-this
  stop() {
  }

  onResult(onResultCallback) {
    this.onResultCallback = onResultCallback
  }

  onError(onErrorCallback) {
    this.onErrorCallback = onErrorCallback
  }
}

export default BaseSpeechRecognition
