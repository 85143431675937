import React, { useState } from 'react'
import { string } from 'prop-types'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'
import { successMessage, warningMessage } from 'src/lib/toaster'
import ActionButton from 'src/components/ActionButton'
import resetStudentPassword from 'src/components/ResetStudentPassword/resetStudentPassword.gql'

const propTypes = {
  userId: string.isRequired,
}

const ResetStudentPassword = ({ userId }) => {
  const [inProgress, setInProgress] = useState(false)
  const [completed, setCompleted] = useState(false)

  const onCompleted = () => {
    setInProgress(false)
    setCompleted(true)
    successMessage({ message: t('save.success'), title: t('save.success') })
  }

  const onError = (error) => {
    setInProgress(false)
    setCompleted(false)
    warningMessage({ message: error.message, title: t('shared.error') })
  }

  return (
    <Mutation
      mutation={resetStudentPassword}
      onCompleted={onCompleted}
      onError={onError}
    >{resetPassword => (
      <ActionButton
        icon="lock"
        onClick={() => {
          setInProgress(true)
          resetPassword({ variables: { userId } })
        }}
        inProgress={inProgress}
        completed={completed}
      >
        Reset Password
      </ActionButton>
    )}
    </Mutation>
  )
}

ResetStudentPassword.propTypes = propTypes

export default ResetStudentPassword
