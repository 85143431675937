import { compose } from 'src/lib/redux'
import { connect } from 'react-redux'
import gqlLoader from 'src/lib/gqlLoader'
import formSelectCollectionsQuery from 'src/pages/User/shared/Form/formSelectCollections.gql'
import currentUser from 'src/pages/User/shared/Form/currentUser.gql'
import UserForm from 'src/pages/User/shared/Form/Scene'

const select = ({ tenantSettings }) => tenantSettings

export default compose(
  gqlLoader(formSelectCollectionsQuery),
  gqlLoader(currentUser),
)(connect(select, null)(UserForm))
