import { useParams, useLocation } from 'react-router'
import { parse } from 'query-string'

const pathWithPageParams = path => ({
  domainContext: domain,
}) => {
  const pathParams = useParams()
  const location = useLocation()
  const queryParams = parse(location.search)
  return params => path({ domain, ...pathParams, ...queryParams, ...params })
}

export default pathWithPageParams
