const ActionTypes = {
  /* eslint-disable sort-keys */
  INIT: 'DrawLetters/INIT',
  NEXT: 'DrawLetters/NEXT',
  END: 'DrawLetters/END',
  CLEANUP: 'DrawLetters/CLEANUP',
  MARK: 'DrawLetters/MARK',
  /* eslint-enable sort-keys */
}
const { INIT, NEXT, END, CLEANUP, MARK } = ActionTypes

const initialState = {
  actualEntities: [],
  completed: null,
  needsCleanup: false,
  nextEntityIndex: 0,
  useSlides: false,
}

const nextRound = () => ({
  payload: {},
  type: NEXT,
})
const doNextRound = ({ actualEntities, nextEntityIndex }) => ({
  completed: null,
  entity: actualEntities[nextEntityIndex],
  needsCleanup: true,
  nextEntityIndex: (nextEntityIndex + 1) % actualEntities.length,
})

const onCleanup = () => ({
  payload: {},
  type: CLEANUP,
})
const doCleanup = () => ({
  needsCleanup: false,
})
const mark = () => ({
  type: MARK,
})

const initModule = ({ entities, phonicsAlphabet }) => ({
  payload: { entities, phonicsAlphabet },
  type: INIT,
})
const doInitModule = (s, { entities, phonicsAlphabet }) => {
  const hasEntities = entities && entities.length > 0
  const actualEntities = hasEntities ? entities : phonicsAlphabet
  const useSlides = actualEntities.length > 1
  const state = {
    actualEntities,
    nextEntityIndex: 0,
    useSlides,
  }
  return {
    ...state,
    ...doNextRound(state),
  }
}

const endRound = () => ({
  payload: {},
  type: END,
})
const doEndRound = () => ({
  completed: true,
})

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case INIT: {
      return {
        ...state,
        ...doInitModule(state, payload),
      }
    }
    case NEXT: {
      return {
        ...state,
        ...doNextRound(state, payload),
      }
    }
    case END: {
      return {
        ...state,
        ...doEndRound(state, payload),
      }
    }
    case CLEANUP: {
      return {
        ...state,
        ...doCleanup(state, payload),
      }
    }
    case MARK: {
      return {
        ...state,
        completed: true,
      }
    }
    default: {
      return state
    }
  }
}

const ActionCreators = {
  /* eslint-disable sort-keys */
  initModule,
  nextRound,
  endRound,
  onCleanup,
  mark,
  /* eslint-enable sort-keys */
}

export { ActionCreators, ActionTypes }
export default reducer
