import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import every from 'lodash/every'
import reduce from 'lodash/reduce'

import derange from 'src/lib/derange'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'MatchImageWithText/INIT'
const MARK = 'MatchImageWithText/MARK'
const RETRY = 'MatchImageWithText/RETRY'
const MOVE = 'MatchImageWithText/MOVE'

const initialState = {
  actualEntities: [],
  availableCards: [],
  cards: {},
  completed: null,
  places: {},
}

const checkCompleted = places => (
  every(places, ({ id, child }) => id === child)
)

const setPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.id === place.child,
    },
  }
)

const clearPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.correct ? true : null,
    },
  }
)

const init = ({ entities, variants }) => ({
  payload: {
    entities,
    variants,
  },
  type: INIT,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const entities = uniqShuffle(payload.entities).slice(0, payload.variants)
      const cards = keyBy(entities, 'id')
      const entitiesForPlaces = derange(entities)
      const places = keyBy(entitiesForPlaces.map((place, i) => ({ child: entities[i].id, id: place.id })), 'id')
      return {
        ...initialState,
        actualEntities: entities,
        cards,
        places,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload

      const newPlace = state.places[targetId]
      const oldPlace = find(state.places, ({ child }) => child === sourceId)

      const newPlaces = {
        ...state.places,
        [oldPlace.id]: {
          ...oldPlace,
          child: newPlace.child,
        },
        [newPlace.id]: {
          ...newPlace,
          child: sourceId,
        },
      }

      const places = reduce(newPlaces, clearPlacesStatus, {})
      return {
        ...state,
        places,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    case MARK: {
      const places = reduce(state.places, setPlacesStatus, {})
      return {
        ...state,
        completed: checkCompleted(state.places),
        places,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  retry,
  mark,
  moveCard,
}

export default reducer
