import React from 'react'

import Access from 'src/components/Access'
import BackgroundStyle from 'src/components/BackgroundStyle'
import Div from 'src/components/Div'
import Menu from 'src/pages/TheLayout/Menu'
import { PORTAL_SCOPE } from 'src/constants/routeScopes'
import DomainContext from 'src/contexts/DomainContext'

const PortalLayout = ({ children }) => (
  <DomainContext.Provider value={PORTAL_SCOPE}>
    <Access redirect>
      <Div auto mx2 mt1>
        <BackgroundStyle color="#7BD3F7" />
        <div className="container">
          <Menu />
          <Div flex column>
            <Div>{children}</Div>
          </Div>
        </div>
      </Div>
    </Access>
  </DomainContext.Provider>
)

export default PortalLayout
