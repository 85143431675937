import React from 'react'
import { string, number } from 'prop-types'
import Div from 'src/components/Div'
import TimedLink from 'src/pages/ErrorPage/TimedLink'
import TheLayout from 'src/pages/TheLayout'

const propTypes = {
  delay: number,
  error: string,
  exit: string,
}

const defaultProps = {
  delay: 0,
  error: 'Page not found',
  exit: '/',
}

const ErrorPage = ({ delay, error, exit }) => (
  <TheLayout>
    <Div flex flex-column flex-1 justify-center align-center center>
      <TimedLink delay={delay} to={exit}>
        <Div h2 black mb2>
          {error}
        </Div>
        <i className="h0 fas fa-arrow-circle-left" />
      </TimedLink>
    </Div>
  </TheLayout>
)

ErrorPage.defaultProps = defaultProps
ErrorPage.propTypes = propTypes
export default ErrorPage
