import BaseSpeechRecognition from 'src/lib/SpeechRecognition/BaseSpeechRecognition'

const { warn } = console

class WebkitSpeechRecognition extends BaseSpeechRecognition {
  constructor() {
    super()
    const Klass = window.webkitSpeechRecognition || window.SpeechRecognition
    const recognition = new Klass()
    recognition.lang = 'en-GB'
    recognition.continuous = false
    recognition.interimResults = false
    recognition.maxAlternatives = 5
    recognition.onerror = e => this.onErrorCallback(e)
    this.recognition = recognition
  }

  abort() {
    this.recognition.abort()
    this.started = false
  }

  start() {
    if (this.started) return
    try {
      this.recognition.start()
    } catch (e) {
      warn(e)
    }
    this.started = true
  }

  stop() {
    this.recognition.stop()
    this.started = false
  }

  onResult(onResultCallback) {
    this.recognition.onresult = (event) => {
      const words = Array.from(event.results[0]).map(x => x.transcript)
      onResultCallback(words)
    }
  }

  onError(onErrorCallback) {
    this.recognition.onerror = () => {
      this.stop()
      onErrorCallback()
    }
  }
}

export default WebkitSpeechRecognition
