import React from 'react'
import compact from 'lodash/compact'
import { graphql } from '@apollo/react-hoc'
import { Redirect } from 'react-router-dom'

import ErrorMessage from 'src/components/ErrorMessage'
import gqlDemoData from 'src/lib/gqlDemoData'
import { compose } from 'src/lib/redux'
import { lessonsPath, shortSlugLessonPath } from 'src/lib/routes'
import pluck from 'src/lib/pluck'

const memoizeForLoading = (Component) =>
  React.memo(
    Component,
    // loading should be true to keep previous version
    (_prevProps, { data: { loading } }) => loading
  )

const gqlLoader = (query, loaderConfig = {}, redirectURL = lessonsPath()) => (
  Component
) => {
  const {
    keepWhileLoading = true,
    renderWhenLoading = false,
    shortSlugRedirect = false,
    ...config
  } = loaderConfig
  const theConfig = {
    ...config,
    errorPolicy: 'all',
    options: {
      partialRefetch: true,
      ...config.options,
    },
  }
  const Graphql = compose(
    ...compact([
      graphql(query, theConfig),
      keepWhileLoading && memoizeForLoading,
    ])
  )(({ data, ...rest }) => {
    const { loading, error } = data
    if (error && error.networkError && error.networkError.statusCode === 404) {
      if (shortSlugRedirect) {
        const path = shortSlugLessonPath(window.location.pathname)
        if (path) {
          window.open(path, '_self')
          return null
        }
      }
      return <Redirect to={redirectURL} />
    }
    if (loading && !renderWhenLoading) return null
    if (error && theConfig.errorPolicy !== 'ignore') {
      const extraInfo = pluck(error, 'networkError.result.error.message')
      return <ErrorMessage message={error.message} extraInfo={extraInfo} />
    }
    return <Component {...rest} {...data} />
  })
  // eslint-disable-next-line react/display-name
  return ({ isDemo, ...props } = {}) => {
    if (isDemo) {
      return gqlDemoData({ config, props })(Component)
    }
    return <Graphql {...props} />
  }
}

const networkOnlyConfig = {
  options: {
    fetchPolicy: 'network-only',
  },
}

const pollInterval = (second) => ({
  options: {
    pollInterval: second * 1000,
  },
})

const ignoreError = { errorPolicy: 'ignore' }

export default gqlLoader
export { networkOnlyConfig, pollInterval, ignoreError }
