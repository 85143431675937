import React, { useContext } from 'react'
import { array, func, object, oneOfType } from 'prop-types'
import Div from 'src/components/Div'
import YearForm from 'src/pages/Year/shared/Form'
import { path as yearsPath } from 'src/pages/Years/config'
import { linkTo } from 'src/lib/linkTo'
import DomainContext from 'src/contexts/DomainContext'

const propTypes = {
  errors: oneOfType([array, object]),
  onSubmit: func.isRequired,
}

const defaultProps = {
  errors: [],
}

const YearNew = ({ errors, onSubmit }) => {
  const domain = useContext(DomainContext)
  return (
    <Div py={2} px={3} bg="white" rounded className="qa-new-year-form">
      <YearForm
        errors={errors}
        onSubmit={onSubmit}
        cancelPath={linkTo(yearsPath)({ domain })}
      />
    </Div>
  )
}

YearNew.propTypes = propTypes
YearNew.defaultProps = defaultProps

export default YearNew
