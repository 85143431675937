import userShape from 'src/shapes/user'
import { bool, shape } from 'prop-types'
import { graphql } from '@apollo/react-hoc'
import query from 'src/pages/CurrentUser/currentUserPresent.gql'

const propTypes = {
  data: shape({
    loading: bool.isRequired,
    user: shape(userShape),
  }),
}

const defaultProps = {
  data: {
    user: null,
  },
}

const NoCurrentUser = ({ data: { loading, user }, children }) => (
  !loading && !user ? children : null
)

NoCurrentUser.propTypes = propTypes
NoCurrentUser.defaultProps = defaultProps
export default graphql(query)(NoCurrentUser)
