import React from 'react'
import { oneOfType, objectOf, bool, string, func, shape, number } from 'prop-types'
import map from 'lodash/map'
import Deck from 'src/components/Layout/Deck'
import Bubble from 'src/components/Bubble'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import KeyHandler from 'src/components/KeyHandler'
import Card from 'src/components/Card'

const CORRECT_TEXT_COLOR = {
  false: 'red',
  selected: 'blue',
  true: 'green',
}

const propTypes = {
  autoPlay: bool.isRequired,
  cards: objectOf(
    shape({
      id: string.isRequired,
      label: number.isRequired,
      src: string,
      status: oneOfType([bool, string]),
    }).isRequired,
  ).isRequired,
  entitiesById: shape().isRequired,
  hearEntityId: string.isRequired,
  onClick: func.isRequired,
}

const ToneMarker = ({
  cards,
  onClick,
  entitiesById,
  hearEntityId,
  autoPlay,
}) => (
  <Deck>
    <Bubble>
      <Div flex column justify-center>
        <Div flex justify-center wrap mb={3}>
          {
            map(cards, card => (
              <Div flex column key={card.id} height={10} color={CORRECT_TEXT_COLOR[card.status]}>
                <Card
                  p={1}
                  auto
                  title={card.title}
                  minHeight={false}
                  onClick={() => onClick({ id: card.id })}
                  src={card.src}
                />
                <KeyHandler label={card.label} onKeyPress={() => onClick({ id: card.id })} />
              </Div>
            ))
          }
        </Div>
      </Div>
    </Bubble>
    {
      hearEntityId && (
        <Div flex justify-center mtn4 mb3>
          <Div flex>
            <Bubble label=" ">
              <EntityCard
                key={hearEntityId}
                entity={entitiesById[hearEntityId]}
                audio
                english
                autoPlay={autoPlay}
                play
                keyToPlay=" "
              />
            </Bubble>
          </Div>
        </Div>
      )
    }
  </Deck>
)

ToneMarker.propTypes = propTypes
export default ToneMarker
