const noop = () => {}

// whilst runs each function in series.
// Before each run it will do the "test" function to make sure it should run again.
export default function whilst(test, iterator, callback = noop) {
  if (test()) {
    iterator(function next(err, ...args) {
      if (err) {
        callback(err)
      } else if (test.apply(this, args)) {
        iterator(next)
      } else {
        callback(null)
      }
    })
  } else {
    callback(null)
  }
}
