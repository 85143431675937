import React from 'react'
import { arrayOf, bool, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Word from 'src/modules/Word/Word'

const propTypes = {
  entities: arrayOf(
    shape(entityShape),
  ),
  entity: shape(entityShape),
  withPartOfSpeech: bool,
}

const defaultProps = {
  entities: [],
  entity: null,
  withPartOfSpeech: false,
}

const WordEnglish = ({ entities, entity, withPartOfSpeech }) => {
  const theEntity = entity || entities[0]
  const { phonicsEn: phonics } = theEntity
  return (
    <Word
      entity={theEntity}
      image={theEntity}
      firstLine={{ audio: true, english: true }}
      phonics={phonics || []}
      lastLine={{ audio: true, thai: true }}
      withPartOfSpeech={withPartOfSpeech}
    />
  )
}

WordEnglish.propTypes = propTypes
WordEnglish.defaultProps = defaultProps
WordEnglish.demoData = {
  entities: ['family'],
}

export default WordEnglish
