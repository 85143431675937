
const propsMaybeWithoutMeaning = (props) => {
  const {
    meaning,
    titleEn,
    titleTh,
    sound,
  } = props

  return {
    meaning: meaning || {
      sound,
      titleEn,
      titleTh,
    },
    ...props,
  }
}

export default propsMaybeWithoutMeaning
