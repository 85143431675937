import React from 'react'
import SelectTypeContainer from 'src/modules/SelectType'


const TrueOrFalseImageContainer = props => (
  <SelectTypeContainer
    {...props}
    maximumRow={3}
    trueOrFalseType="image"
  />
)

const demoData = {
  entities: [
    'paragraph-tortoise-and-the-hare-race',
    'collection-true',
    'phrase-i-think-it-s-about-a-tortoise-and-a-hare',
    'phrase-i-think-it-s-about-a-race',
    'collection-false',
    'phrase-i-think-it-s-about-an-elephant-and-an-aeroplane',
    'phrase-i-think-it-s-about-a-party',
  ],
}

TrueOrFalseImageContainer.demoData = demoData

export default TrueOrFalseImageContainer
