import React, { Fragment, useEffect } from 'react'
import { arrayOf, string, shape, func } from 'prop-types'
import { withSelectStyle } from 'src/components/SelectStyle'
import flattenDeep from 'lodash/flattenDeep'

const gradeShape = shape({
  id: string.isRequired,
  title: string.isRequired,
})

const sectionShape = shape({
  grades: arrayOf(gradeShape),
  id: string.isRequired,
  title: string.isRequired,
})

const propTypes = {
  onChange: func.isRequired,
  sections: arrayOf(sectionShape).isRequired,
  yearId: string.isRequired,
}

const defaultProps = {
}

const YearSelector = ({
  sections,
  yearId,
  onChange,
}) => {
  useEffect(() => {
    if (yearId) { return }
    const firstYearId = flattenDeep(
      sections.map(
        s => s.grades.map(
          g => g.years,
        ),
      ),
    )[0].id
    if (firstYearId) { onChange(firstYearId) }
  }, [
    yearId,
    sections,
    onChange,
  ])
  if (!yearId) { return null }
  return (
    <select
      name="qa-class-selector"
      value={yearId}
      onChange={ev => onChange(ev.target.value)}
    >
      {
        sections &&
        sections.map(({ id: sectionId, title: sectionTitle, grades }) => (
          <Fragment key={`${sectionId}-fragment`}>
            <optgroup key={sectionId} label={sectionTitle} />
            <optgroup key={`${sectionId}-before`} />
            {grades.map(({ id: gradeId, title: gradeTitle, years }) => (
              <optgroup key={gradeId} label={gradeTitle}>
                {years.map(({ id, title }) => (
                  <option className={`qa-option-${id}`} key={id} value={id}>
                    {title}
                  </option>
                ))}
              </optgroup>
            ))}
            <optgroup key={`${sectionId}-after`} />
          </Fragment>
        ))
      }
    </select>
  )
}

YearSelector.defaultProps = defaultProps
YearSelector.propTypes = propTypes
const exportYearSelectorWithStyle = withSelectStyle(YearSelector)

export { exportYearSelectorWithStyle as YearSelectorWithStyle }
export default YearSelector
