import React, { useState } from 'react'
import { t } from 'i18next'
import { func, arrayOf, oneOfType, number, string, bool, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import useSubmitMode from 'src/pages/Users/SelectionActions/useSubmitMode'
import useYearGroups from 'src/pages/Users/SelectionActions/useYearGroups'
import AssignYear from 'src/pages/Users/SelectionActions/AssignYear'

const propTypes = {
  assignMutation: func.isRequired,
  cancel: func.isRequired,
  clearOnSuccess: bool,
  list: arrayOf(oneOfType([number, string])).isRequired,
  onFailure: func,
  onSuccess: func,
  resignMutation: func.isRequired,
  sections: arrayOf(shape(entityShape).isRequired).isRequired,
  years: arrayOf(shape(entityShape).isRequired).isRequired,
}
const defaultProps = {
  clearOnSuccess: true,
  onFailure: undefined,
  onSuccess: undefined,
}
const DO_NOTHING = () => {}
const DO_ALERT = err => alert(err)
const invoke = (...fns) => fns.forEach(f => f())


const AssignYearContainer = ({
  assignMutation,
  resignMutation,
  list,
  clearOnSuccess,
  onSuccess,
  onFailure,
  cancel,
  sections,
  years,
}) => {
  const [year, setYear] = useState(null)
  const {
    submitAsync,
    submitEnabled,
    submitTitleKey,
    submitWarning,
    switchAssignMode,
    switchDisabledMode,
    switchResignMode,
  } = useSubmitMode(
    () => assignMutation({ variables: { identifiers: list, yearId: year.id } }),
    () => resignMutation({ variables: { identifiers: list } }),
  )
  const { yearsSorted, yearByTag } = useYearGroups(sections, years)
  const [yearsToDisplay, setYearsToDisplay] = useState(yearsSorted)
  const onSubmit = () => {
    const clearIfToldSo = () => clearOnSuccess && cancel()
    submitAsync()
      .then(invoke(onSuccess || DO_NOTHING, clearIfToldSo))
      .catch(onFailure || DO_ALERT)
  }

  const onSectionChange = (value, sectionIsResign = false) => {
    if (sectionIsResign) {
      switchResignMode()
      setYearsToDisplay(null)
      setYear(null)
    } else {
      switchDisabledMode()
      setYearsToDisplay(yearByTag[value] || [])
    }
  }
  const onYearChange = (value) => {
    const byTargetId = ({ id }) => id === value
    setYear(yearsToDisplay.find(byTargetId))
    switchAssignMode()
  }
  const submitTitle = t(submitTitleKey, { count: list.length, year: year && year.title })
  return (
    <AssignYear {...{
      list,
      onSectionChange,
      onSubmit,
      onYearChange,
      submitEnabled: submitEnabled && list.length > 0,
      submitTitle,
      submitWarning,
      sections, // eslint-disable-line sort-keys
      years: yearsToDisplay,
    }}
    />
  )
}
AssignYearContainer.propTypes = propTypes
AssignYearContainer.defaultProps = defaultProps

export default AssignYearContainer
