import takeWhile from 'lodash/takeWhile'
import drop from 'lodash/drop'

const splitWhile = (array, predicate) => {
  const head = takeWhile(array, predicate)
  const tail = drop(array, head.length)
  return [head, tail]
}

export default splitWhile
