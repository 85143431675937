import * as overviewScene from 'src/pages/SchoolOverview/config'
import { scene as homeworkScene } from 'src/pages/HomeworkPage/config'
import { scene as progressScene } from 'src/pages/ProgressPage/config'
import { scene as bpReportScene } from 'src/pages/BpReportPage/config'
import { scene as attendanceReportScene } from 'src/pages/AttendanceReportPage/config'

import {
  scene as studentHomeworkScene,
  portalPath as homeworkPortalPath,
} from 'src/pages/StudentHomeworkPage/config'
import {
  scene as studentSkillsScene,
  portalPath as skillsPortalPath,
} from 'src/pages/StudentSkillsPage/config'
import {
  scene as studentAttendanceScene,
  portalPath as attendancePortalPath,
} from 'src/pages/StudentAttendance/config'

const staffReportsSection = [
  overviewScene,
  progressScene,
  homeworkScene,
  attendanceReportScene,
  bpReportScene,
]

const teacherReportsSection = [
  progressScene,
  homeworkScene,
  attendanceReportScene,
  bpReportScene,
]

const parentReportsSection = [
  {
    ...studentHomeworkScene,
    path: homeworkPortalPath,
  },
  {
    ...studentSkillsScene,
    path: skillsPortalPath,
  },
  {
    ...studentAttendanceScene,
    path: attendancePortalPath,
  },
]

export {
  staffReportsSection,
  teacherReportsSection,
  parentReportsSection,
}
