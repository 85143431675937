import React, { Fragment } from 'react'
import { compose } from 'src/lib/redux'
import { shape } from 'prop-types'

import Div from 'src/components/Div'
import UserPassport from 'src/components/UserPassport/UserPassport'
import Nav from 'src/pages/User/Show/Nav'
import { STUDENT_ROLE } from 'src/constants/roles'
import userShape from 'src/shapes/user'
import gqlLoader from 'src/lib/gqlLoader'
import userPath from 'src/lib/routes/user'
import userReportPath from 'src/lib/routes/userReport'
import academicYearsQuery from 'src/queries/academicYearsQuery.gql'
import userProfileQuery from 'src/pages/User/shared/userProfile.gql'
import usePath from 'src/lib/routes/usePath'

const propTypes = {
  user: shape(userShape).isRequired,
}

const UserPage = ({ academicYears, report, user, children }) => {
  const pagePath = report ? userReportPath : userPath
  const path = usePath(pagePath)
  return (
    <Div flex auto column>
      <Div mb1>
        <UserPassport academicYears={academicYears} editable user={user} />
      </Div>
      {
        user.role === STUDENT_ROLE && (
          <Fragment>
            <Nav path={params => path(params)} />
            {React.cloneElement(children, {
              ...children.props,
              user,
              userId: user.slug,
            })}
          </Fragment>
        )
      }
    </Div>
  )
}

UserPage.propTypes = propTypes

export default (
  props => (
    props.userId
      ? compose(gqlLoader(userProfileQuery), gqlLoader(academicYearsQuery))(UserPage)(props)
      : null
  )
)
