import PhonicsSounds from 'src/modules/PhonicsSounds/PhonicsSounds'

PhonicsSounds.demoData = {
  entities: [
    'phonics-r',
    'radish',
    'phonics-b',
  ],
}

export default PhonicsSounds
