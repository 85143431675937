import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import size from 'lodash/size'
import find from 'lodash/find'
import every from 'lodash/every'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import moveCards from 'src/reducers/lib/moveCards'
import stripPunctuation from 'src/lib/stripPunctuation'
import addPhonicsInPlaces from 'src/modules/ComicStripFillInTheBlank/addPhonicsInPlaces'

const INIT = 'comicStripFillInTheBlank/INIT'
const MARK = 'comicStripFillInTheBlank/MARK'
const RETRY = 'comicStripFillInTheBlank/RETRY'
const MOVE = 'comicStripFillInTheBlank/MOVE'

const initialState = {
  actualEntities: [],
  availableCardsId: [],
  cards: {},
  completed: null,
  entitiesById: {},
  linesList: [],
}

const findAvailableCards = cards => (
  map(
    map(cards).filter(({ placeId }) => !placeId),
    'id',
  )
)

const numberOfWords = phrase => phrase.split(' ').length

const createWords = ({
  entityCardId,
  entityId,
  initWord,
  places,
  targetWord,
  words,
}, word) => {
  if (!find(words, 'placeId')
    && stripPunctuation(word.toLowerCase()) === stripPunctuation(targetWord.toLowerCase())) {
    const id = `place-${size(places)}`
    return {
      entityCardId,
      entityId,
      initWord,
      places: {
        ...places,
        [id]: {
          entityCardId,
          entityId,
          id,
          word: initWord,
        },
      },
      targetWord,
      words: words.concat({ placeId: id }),
    }
  }
  return {
    entityCardId,
    entityId,
    initWord,
    places,
    targetWord,
    words: words.concat({ title: word }),
  }
}

const createNoSpaceWordArray = (phrase, word) => {
  const re = new RegExp(word, 'i')
  return phrase.replace(re, word.replace(' ', '')).split(' ').map(x => x.trim())
}

const createPhraseArray = (phrase, word) => {
  if (numberOfWords(word) > 1) {
    return createNoSpaceWordArray(phrase, word)
  }
  return phrase.trim().split(' ').map(x => x.trim())
}

const createVariant = ({
  places: initPlaces,
  cards: initCards,
  lines,
}, [firstEntity, secondEntity], i) => {
  const id = `line-${i}`
  const cardId = `card-${i}`
  const { titleEn: phrase = '...', id: entityId } = firstEntity
  const { titleEn: word = '...', id: entityCardId } = secondEntity
  const targetWord = numberOfWords(word) > 1 ? word.replace(' ', '') : word
  const { words, places } = createPhraseArray(phrase, word).reduce(createWords,
    {
      entityCardId,
      entityId,
      initWord: word,
      places: initPlaces,
      targetWord,
      words: [],
    })
  const cards = {
    ...initCards,
    [cardId]: {
      entityId: entityCardId,
      id: cardId,
    },
  }
  return {
    cards,
    lines: {
      ...lines,
      [id]: {
        entityId,
        id,
        phrase,
        placeId: `place-${i}`,
        words,
      },
    },
    places,
  }
}

const checkPlaces = ({ places, cards }, place) => (
  {
    cards,
    places: {
      ...places,
      [place.id]: {
        ...place,
        correct: place.cardId
          ? place.entityCardId
            === cards[place.cardId].entityId
          : false,
      },
    },
  }
)

const correctLines = ({ places, lines }, line) => {
  if (line.placeId && places[line.placeId].correct) {
    return {
      lines: {
        ...lines,
        [line.id]: {
          ...line,
          correct: true,
          words: [{
            title: line.phrase,
          }],
        },
      },
      places,
    }
  }
  return {
    lines: {
      ...lines,
      [line.id]: {
        ...line,
      },
    },
    places,
  }
}

const clearFalseStatus = ({ places }, place) => (
  {
    places: {
      ...places,
      [place.id]: {
        ...place,
        correct: place.correct ? true : null,
      },
    },
  }
)

const createPhonics = ({ entitiesById, places }, place) => {
  const phonics = entitiesById[place.entityId].phonicsEn || []
  return (
    {
      entitiesById,
      places: {
        ...places,
        [place.id]: {
          ...place,
          initPhonics: phonics,
          phonics,
        },
      },
    }
  )
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const pairEntity = chunk(entities, 2)
      const entitiesById = keyBy(entities, 'id')
      const { cards, places: incompletPlaces, lines } = pairEntity.reduce(createVariant, {})
      const { places } = reduce(incompletPlaces,
        createPhonics, {
          entitiesById,
        })
      return {
        ...initialState,
        actualEntities: entities,
        availableCardsId: shuffle(Object.keys(cards)),
        cards,
        entitiesById,
        lines,
        linesList: Object.keys(lines),
        places,
      }
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      if (sourceId === null || targetId === null) {
        const { places } = reduce(state.places,
          clearFalseStatus, {
            ...state,
          })
        return {
          ...state,
          places,
        }
      }
      const newState = moveCards(state, sourceId, targetId)
      const { places: placesPhonics } = reduce(newState.places,
        addPhonicsInPlaces, {
          ...newState,
          card: newState.cards[sourceId],
          targetId,
        })
      const oldPlace = state.places[state.cards[sourceId].placeId]
      const { places: newPlacesPhonics } = oldPlace
        ? reduce(placesPhonics,
          addPhonicsInPlaces, {
            ...newState,
            card: state.places[targetId].cardId ? newState.cards[state.places[targetId].cardId] : find(newState.cards, { entityId: oldPlace.entityCardId }),
            places: placesPhonics,
            targetId: oldPlace.id,
          })
        : {}
      const selectedPlacesPhonics = oldPlace
        ? newPlacesPhonics
        : placesPhonics
      const { places } = reduce(selectedPlacesPhonics,
        clearFalseStatus, {
          places: selectedPlacesPhonics,
        })
      return {
        ...newState,
        availableCardsId: shuffle(findAvailableCards(newState.cards)),
        places,
      }
    }
    case MARK: {
      const { places } = reduce(state.places, checkPlaces, { ...state })
      const completed = every(places, ['correct', true])
      const { lines } = reduce(state.lines, correctLines, { ...state, places })
      return {
        ...state,
        completed,
        lines,
        places,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  retry,
  moveCard,
}
export default reducer
