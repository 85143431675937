import React from 'react'
import { array, bool, func, object, shape } from 'prop-types'

import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Place from 'src/components/Place'
import map from 'lodash/map'
import pick from 'lodash/pick'
import KeyHandler from 'src/components/KeyHandler'
import SpeakerBubble from 'src/components/SpeakerBubble'

const propTypes = {
  answerEntity: object.isRequired,
  availableCardsId: array.isRequired,
  cards: shape().isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
  questionEntity: object.isRequired,
  speaker: bool,
  speakerEntity: object.isRequired,
}

const renderPlace = ({
  cards,
  entitiesById,
  place,
  moveCard,
  answerEntity,
}) => {
  const { id, cardId } = place
  const card = cardId ? cards[cardId] : undefined

  return (
    <Div key={cardId} flex column fx={1}>
      <SpeakerBubble
        bounce
        speakerEntity={answerEntity}
        label={card ? card.index : null}
        right
        hasTip
      >
        <Place
          id={id}
          key={id}
          onDrop={moveCard}
        >
          <Div flex flex-1 justify-center>
            {
              card ? (
                <AdaptiveCard
                  audio
                  autoPlay
                  correct={card.correct}
                  draggable
                  english
                  entity={entitiesById[card.id]}
                  fit
                  id={card.id}
                  keyToPlay={card.index}
                />
              ) : <Card fit title="..." />
            }
          </Div>
        </Place>
      </SpeakerBubble>
    </Div>
  )
}

const ProvideAnswer = ({
  answerEntity,
  availableCardsId,
  cards,
  entitiesById,
  moveCard,
  places,
  questionEntity,
  speaker,
  speakerEntity,
  started,
}) => (
  <Deck>
    <Div flex justify-center>
      <SpeakerBubble
        speakerEntity={speakerEntity}
        autoPlay={started}
        key={questionEntity.id}
        entity={questionEntity}
        label=" "
        english
        hasTip={speaker}
        mln={speaker ? 4 : undefined}
        tip="left"
        p={1}
      />
    </Div>
    <Div flex auto py3>
      {map(places, (place, i) => renderPlace({
        answerEntity,
        cards,
        entitiesById,
        i,
        moveCard,
        place,
      }))}
    </Div>
    <Div flex>
      {
        map(pick(entitiesById, availableCardsId)).map(entity => (
          <Div key={entity.id} flex flex-1 column>
            <AdaptiveCard
              audio
              draggable
              english
              entity={entity}
              height={6}
              onClick={() => moveCard({ sourceId: entity.id, targetId: answerEntity.id })}
              silent
              minHeight={false}
              p={1}
            />
            <KeyHandler
              label={`${cards[entity.id].index}`}
              onKeyPress={() => moveCard({ sourceId: entity.id, targetId: answerEntity.id })}
            />
          </Div>
        ))
      }
    </Div>
  </Deck>
)

ProvideAnswer.propTypes = propTypes
export default ProvideAnswer
