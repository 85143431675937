import React from 'react'
import { bool, shape, string } from 'prop-types'
import Img from 'src/components/Img'
import personImage from 'src/components/PersonImg/PersonImage.svg'

const propTypes = {
  className: string,
  cover: bool,
  position: string,
  src: string,
  style: shape(),
}

const defaultProps = {
  className: '',
  cover: true,
  position: 'center',
  src: personImage,
  style: {},
}

const PersonImg = ({
  className,
  cover,
  position,
  src,
  style,
  ...rest
}) => (
  <Img
    className={className}
    cover={cover}
    position={position}
    src={src}
    style={style}
    {...rest}
  />
)

PersonImg.propTypes = propTypes
PersonImg.defaultProps = defaultProps

export default PersonImg
