import React from 'react'
import { string } from 'prop-types'
import Div from 'src/components/Div'
import Header from 'src/components/Game/Header'
import Description from 'src/components/Game/Description'

const Instruction = ({ title, image, meaning }) => (
  <Div auto flex p={2} Instruction>
    <Div auto flex column>
      <Header {...{ image, title }} />
      <Description {...{ image, meaning }} />
    </Div>
  </Div>
)

Instruction.propTypes = {
  image: string,
  meaning: string,
  sound: string,
  title: string.isRequired,
}

Instruction.defaultProps = {
  image: undefined,
  meaning: '',
  sound: undefined,
}

export default Instruction
