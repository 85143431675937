import React, { Component } from 'react'
import { number, bool, element, func, oneOfType, arrayOf } from 'prop-types'
import Check from 'src/components/Check'
import Cross from 'src/components/Cross'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import { setTimeout, clearTimeout } from 'src/lib/window'

const DEBOUNCE_TIME = 1 * 2100

const propTypes = {
  animation: bool,
  children: oneOfType([element, arrayOf(element)]).isRequired,
  completed: bool,
  onClick: func.isRequired,
  retryDelay: number,
}

const defaultProps = {
  animation: null,
  completed: null,
  retryDelay: null,
}

class CompletableGame extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animation: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { animation, completed } = this.props
    const debounceTime = completed ? (prevProps.retryDelay || DEBOUNCE_TIME) : DEBOUNCE_TIME
    if (animation && animation !== prevProps.animation) {
      this.handleAnimation(completed ? 'tada' : 'shake')
    }
    if (
      (completed === true || completed === false)
      && prevProps.completed !== completed
    ) {
      this.debounce = setTimeout(() => {
        this.handleAfterMark()
      }, debounceTime)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.debounce)
  }

  handleAfterMark() {
    const { onClick, completed } = this.props
    if (completed !== null) onClick()
  }

  handleAnimation(animation) {
    this.setState(() => ({ animation: null }))
    setTimeout(() => this.setState(() => ({ animation })), 0)
  }

  render() {
    const { completed, children } = this.props
    const { animation } = this.state
    return (
      <Deck>
        <Deck
          relative
          onClick={() => {
            if (completed !== null) {
              this.handleAnimation('bounce')
            }
          }}
        >
          {children}
          {
            completed !== null && (
              <Div absolute style={{ height: '100%', width: '100%' }}>&nbsp;</Div>
            )
          }
        </Deck>
        {completed !== null && (
          <div
            className={`
              animated
              ${animation}
              bg-white
              rounded
              p2
              shadow
              z2
              fixed
              bottom-0
              right-0
              shadow
              m3
            `}
          >
            {completed ? <Check noAnimation size={10} /> : <Cross noAnimation size={10} />}
          </div>
        )}
      </Deck>
    )
  }
}

CompletableGame.propTypes = propTypes
CompletableGame.defaultProps = defaultProps

export default CompletableGame
