import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'

const path = '/preview/students'

const scene = {
  icon: icons[menu.STUDENT_CARDS],
  id: menu.STUDENT_CARDS,
  path,
}

export { path, scene }
