import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import KeyPress from 'src/components/KeyPress'

import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/Hangman/reducer'
import {
  updateTriesIterationIcon,
  clearTriesIterationIcon,
} from 'src/components/IterationIcon/reducer'
import {
  enableSoftKeyboard,
  disableSoftKeyboard,
  showSoftKeyboard,
} from 'src/components/SoftKeyboard/reducer'
import { playWrongSound } from 'src/lib/sounds/reducer'
import Hangman from 'src/modules/Hangman/Hangman'
import { setTimeout } from 'src/lib/window'
import { bool, func } from 'prop-types'

const propTypes = {
  ...gamePropTypes,
  completed: bool,
  playWrongSound: func.isRequired,
}

const defaultProps = {
  completed: null,
  showSoftKeyboard: false,
}


class HangmanContainer extends Component {
  componentDidMount() {
    const { enableSoftKeyboard } = this.props
    this.init()
    enableSoftKeyboard()
  }

  componentDidUpdate(prevProps) {
    if (isEqual(this.props, prevProps)) return
    const {
      chop,
      handleChange,
      playWrongSound,
      tries,
      triesIterations,
      updateTriesIterationIcon,
    } = this.props
    setTimeout(() => handleChange(), 700)
    if (chop && tries !== prevProps.tries) {
      playWrongSound()
      updateTriesIterationIcon({ triesIterations })
    }
  }

  componentWillUnmount() {
    const { clearTriesIterationIcon, disableSoftKeyboard } = this.props
    clearTriesIterationIcon()
    disableSoftKeyboard()
  }

  init = () => {
    const { entities, init, triesIterations, updateTriesIterationIcon } = this.props
    init({ entities })
    updateTriesIterationIcon({ triesIterations })
  }

  render() {
    const {
      actualEntities,
      answer,
      chop,
      completed,
      entities,
      entity,
      isSoftKeyboardShow,
      letter,
      mark,
      mood,
      phonicsById,
      places,
      responseText,
      showSoftKeyboard,
      started,
      tries,
      triesIterations,
      usedAplhabet,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={this.init}
      >
        <KeyPress keys={{ rest: letter }} />
        <Hangman {...{
          answer,
          chop,
          completed,
          entities,
          entity,
          isSoftKeyboardShow,
          mood,
          phonicsById,
          places,
          responseText,
          showSoftKeyboard,
          started,
          tries,
          triesIterations,
          usedAplhabet,
        }}
        />
      </Game>
    )
  }
}

const select = state => ({ ...state.hangman, ...state.softKeyboard })

const actions = dispatch => (
  bindActionCreators({
    ...cardActions,
    playWrongSound,
    updateTriesIterationIcon,
    clearTriesIterationIcon,
    enableSoftKeyboard,
    disableSoftKeyboard,
    showSoftKeyboard,
  }, dispatch)
)

HangmanContainer.propTypes = propTypes
HangmanContainer.defaultProps = defaultProps
HangmanContainer.demoData = {
  entities: [
    'father',
    'mother',
    'sister',
  ],
}

export default connect(select, actions)(HangmanContainer)
