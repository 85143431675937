import React from 'react'
import { t } from 'i18next'

import Div from 'src/components/Div'
import left from 'src/pages/Registration/left.svg'
import right from 'src/pages/Registration/right.svg'

const defaultProps = {
  bg: 'blue',
  children: (
    <div>
      <Div h2>{t('registration.registrationNow')}</Div>
      <Div h4>{t('registration.toImproveEnglish')}</Div>
      <Div py2>{t('registration.aboutUs')}</Div>
    </div>
  ),
}

const RegistrationBanner = ({ bg, children }) => (
  <Div relative my3>
    <Div flex my2 bg={bg} rounded justify-center>
      <Div md-show col-4 mtn2 mbn2 relative>
        <Div absolute stretch style={{ backgroundImage: `url(${left})` }} />
      </Div>
      <Div md-col-6 white center py2>
        {children}
      </Div>
      <Div md-col-4 mtn2 mbn2 relative>
        <Div absolute stretch style={{ backgroundImage: `url(${right})` }} />
      </Div>
    </Div>
  </Div>
)

RegistrationBanner.defaultProps = defaultProps
export default RegistrationBanner
