import React, { Component } from 'react'
import { array, bool, func, number, string, oneOfType } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Phonics from 'src/modules/Word/Phonics/Phonics'
import * as phonicsActions from 'src/modules/Word/Phonics/reducer'

const propTypes = {
  initPhonics: func.isRequired,
  keyToPlay: oneOfType([string, number]),
  phonics: array.isRequired,
  playing: oneOfType([string, number]),
  playNext: func.isRequired,
  playPhonics: func.isRequired,
}

const defaultProps = {
  chapterId: null,
  keyToPlay: '2',
  player: null,
  playing: null,
}

const contextTypes = {
  appeared: bool,
}

class PhonicsContainer extends Component {
  componentDidMount() {
    this.init()
  }

  componentDidUpdate({ phonics }) {
    if (this.props.phonics && (phonics.length !== this.props.phonics.length)) {
      this.init()
    }
  }

  init() {
    this.props.initPhonics(this.props.phonics, this.props.keyToPlay)
  }

  render() {
    const {
      keyToPlay,
      playing,
      player,
      phonics,
      playPhonics,
      playNext,
      stopPlay,
    } = this.props
    return (
      <Phonics
        keyToPlay={keyToPlay}
        playNext={playNext}
        playing={playing}
        player={player}
        playPhonics={() => playPhonics(this.props.phonics, this.props.keyToPlay)}
        phonics={phonics}
        stopPlay={stopPlay}
      />
    )
  }
}

PhonicsContainer.propTypes = propTypes
PhonicsContainer.defaultProps = defaultProps
PhonicsContainer.contextTypes = contextTypes

const select = state => state.phonics
const actions = dispatch => bindActionCreators(phonicsActions, dispatch)
export default connect(select, actions, null, { pure: false })(PhonicsContainer)
