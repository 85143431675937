import React from 'react'
import Div from 'src/components/Div'
import RegistrationFormContainer from 'src/pages/Registration/RegistrationFormContainer'
import RegistrationBanner from 'src/pages/Registration/RegistrationBanner'
import { t } from 'i18next'

const Registration = () => (
  <Div container p2>
    <RegistrationFormContainer />
    <RegistrationBanner bg="" to={false}>
      <Div blue h3>
        <span aria-label="image" role="img">🙏</span> {t('registration.thankYou')}
      </Div>
    </RegistrationBanner>
  </Div>
)

export default Registration
