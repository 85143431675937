import { localStorage } from 'src/lib/window'

const { hostname } = window.location
const apiURL = '/queries'
const adminURL = '/admin'
const isDevelopment = !!localStorage.getItem('isDevelopment')
const isOnline = hostname.includes('online.')
const DEFAULT_LOCALE = localStorage.getItem('i18nextLng') || 'th'

export { adminURL, apiURL, DEFAULT_LOCALE, isDevelopment, isOnline }
