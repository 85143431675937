import React from 'react'
import { arrayOf, shape, string, bool } from 'prop-types'
import TableTitleRow from 'src/components/TableTitleRow'
import StudentReport from 'src/pages/AttendancePage/AttendanceWeekly/StudentReport'
import YearTitleRow from 'src/pages/AttendancePage/AttendanceWeekly/YearTitleRow'

const propTypes = {
  days: arrayOf(string).isRequired,
  sections: arrayOf(shape({
    id: string.isRequired,
    title: string.isRequired,
    years: arrayOf(shape({
      id: string.isRequired,
      students: arrayOf(shape({
        attendances: arrayOf(shape({
          attendanceOn: string.isRequired,
          present: bool.isRequired,
        })),
        fullName: string.isRequired,
        id: string.isRequired,
      })),
      title: string.isRequired,
    })),
  })),
}

const defaultProps = {
  sections: [],
}

const TableBody = ({ sections, days }) => (
  <tbody>
    {
      sections.map(section => [
        <TableTitleRow
          key={section.id}
          title={section.title}
        />,
        ...section.years.map(year => [
          <YearTitleRow
            key={year.id}
            days={days}
            paddingLeft={1}
            year={year}
          />,
          year.students.map(user => (
            <StudentReport
              key={user.id}
              {...user}
              days={days}
            />
          )),
        ]),
      ])
    }
  </tbody>
)

TableBody.propTypes = propTypes
TableBody.defaultProps = defaultProps

export default TableBody
