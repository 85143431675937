import React from 'react'
import { bool, shape } from 'prop-types'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import Phonics from 'src/modules/Word/Phonics'
import EntityImage from 'src/components/EntityImage'
import mapKey from 'src/lib/mapKey'

const propTypes = {
  hintImage: bool,
  line: shape().isRequired,
  silent: bool,
}
const defaultProps = {
  hintImage: true,
  line: null,
  silent: false,
}

const getCard = ({
  cards,
  entitiesById,
  large,
  place,
  placeTarget,
  silent,
}) => (
  <Div>
    {
      place && place.cardId ? (
        <EntityCard
          audio
          autoPlay={!silent}
          correct={place.correct}
          draggable
          english
          entity={entitiesById[place.cardId]}
          fit
          silent
          id={place.cardId}
          key={place.cardId}
          keyToPlay={cards[place.cardId].label}
          selected={placeTarget === place.id}
          size={large ? 0 : 2}
        />
      ) : (
        <Card
          title="..."
          size={large ? 0 : 2}
          fit
          selected={place && placeTarget === place.id}
        />
      )}
  </Div>
)

const renderFillInMissingWord = ({
  cards,
  completed,
  entitiesById,
  imageSrc,
  hintImage,
  largeCards,
  line,
  moveCard,
  phonics,
  places,
  placeTarget,
  playPhonics,
  silent,
  words,
}) => (
  <Place key="place0" id="place0" onDrop={moveCard}>
    <Div flex auto>
      <Div flex auto>
        <Card
          key={line.id}
          onClick={!silent ? (() => playPhonics(phonics, ' ')) : null}
          disableSound
        >
          <Div flex column justify-center>
            <Div flex wrap bold justify="center">
              {
                words.map(({ title, placeId }, i) => (
                  <Div
                    flex
                    align-end={!(placeId && line.imageEntityIds[placeId])}
                    column={(placeId && !!line.imageEntityIds[placeId])}
                    key={mapKey(i)}
                  >
                    {
                      hintImage && placeId && line.imageEntityIds[placeId] &&
                        (
                          <Div flex height={largeCards ? 10 : 8} gutter={3}>
                            <Div flex auto key={line.imageEntityIds[placeId]}>
                              <EntityImage
                                className="mxn3"
                                entity={entitiesById[line.imageEntityIds[placeId]]}
                              />
                            </Div>
                          </Div>
                        )
                    }
                    {
                      title
                        ? (
                          <Card
                            title={title}
                            size={largeCards ? 0 : 2}
                            fit
                            transparent={completed}
                          />
                        ) : getCard({
                          cards,
                          entitiesById,
                          large: largeCards,
                          place: places[placeId],
                          placeTarget,
                          silent,
                        })
                    }
                  </Div>
                ))
              }
            </Div>
          </Div>
        </Card>
      </Div>
      {
        line.id && imageSrc && (
          <Div flex col={4}>
            <EntityImage entity={entitiesById[line.id]} contain />
          </Div>
        )
      }
    </Div>
    {
      !silent && (
        <Div hide>
          <Phonics key={line.id} phonics={phonics} keyToPlay=" " />
        </Div>
      )
    }
  </Place>
)

const renderFillInMissingWords = ({
  cards,
  completed,
  entitiesById,
  hintImage,
  imageSrc,
  line,
  moveCard,
  phonics,
  places,
  placeTarget,
  playPhonics,
  silent,
  words,
}) => (
  <Div flex auto>
    <Div flex auto>
      <Card
        key={line.id}
        silent
        onClick={!silent ? (() => playPhonics(phonics, ' ')) : null}
        disableSound
      >
        <Div flex column justify-center>
          <Div flex auto wrap bold justify="center">
            {
              words.map(({ title, placeId }, i) => (
                <Div
                  flex
                  align-end={!(placeId && line.imageEntityIds[placeId])}
                  column={(placeId && !!line.imageEntityIds[placeId])}
                  key={mapKey(i)}
                >
                  {
                    hintImage && placeId && line.imageEntityIds[placeId] &&
                      (
                        <Div flex height={8} gutter={3}>
                          <Div flex auto key={line.imageEntityIds[placeId]}>
                            <EntityImage
                              entity={entitiesById[line.imageEntityIds[placeId]]}
                            />
                          </Div>
                        </Div>
                      )
                  }
                  {
                    title ?
                      <Card title={title} size={2} fit transparent={completed} />
                      : (
                        <Place key={placeId} id={placeId} onDrop={moveCard}>
                          {
                          getCard({
                            cards,
                            entitiesById,
                            place: places[placeId],
                            placeTarget,
                            silent,
                          })
                        }
                        </Place>
                      )}
                </Div>
              ))
            }
            {
              !silent && (
                <Div hide>
                  <Phonics key={line.id} phonics={phonics} keyToPlay=" " />
                </Div>
              )
            }
          </Div>
        </Div>
      </Card>
      {
        line.id && imageSrc && (
          <Div flex col={4}>
            <EntityImage entity={entitiesById[line.id]} contain />
          </Div>
        )
      }
    </Div>
  </Div>
)

const Line = ({
  cards,
  completed,
  entitiesById,
  hintImage,
  largeCards,
  line,
  moveCard,
  multiplePlaces,
  phonics,
  places,
  placeTarget,
  playPhonics,
  silent,
}) => {
  const { words = [], correct = null } = line
  const imageEntity = entitiesById[line.id]
  const imageObject = { ...imageEntity }.image
  const imageSrc = { ...imageObject }.src
  return (
    multiplePlaces
      ? renderFillInMissingWords({
        cards,
        completed,
        correct,
        entitiesById,
        hintImage,
        imageSrc,
        line,
        moveCard,
        phonics,
        places,
        placeTarget,
        playPhonics,
        silent,
        words,
      })
      : renderFillInMissingWord({
        cards,
        completed,
        correct,
        entitiesById,
        hintImage,
        imageSrc,
        largeCards,
        line,
        moveCard,
        phonics,
        places,
        placeTarget,
        playPhonics,
        silent,
        words,
      })
  )
}

Line.propTypes = propTypes
Line.defaultProps = defaultProps

export default Line
