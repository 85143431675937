import React from 'react'
import { arrayOf, shape } from 'prop-types'
import EntityCard from 'src/components/EntityCard'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import { adminEntityPath } from 'src/lib/routes'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
}

const EntitiesList = ({ entities }) => (
  <Div flex wrap>
    {entities.map((entity, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Div key={`${entity.slug}${index}`}>
        <a href={adminEntityPath(entity.slug)}>
          <EntityCard
            english
            fit
            size={4}
            entity={{ ...entity, titleEn: entity.slug }}
          />
        </a>
      </Div>
    ))}
  </Div>
)

EntitiesList.propTypes = propTypes

export default EntitiesList
