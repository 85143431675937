import React from 'react'
import { shape, array, arrayOf } from 'prop-types'
import find from 'lodash/find'
import filter from 'lodash/filter'
import chunk from 'lodash/chunk'
import AcademicCover from 'src/pages/TeacherGuides/AcademicCover'
import Chapter from 'src/pages/preview/Chapter'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import indicatorShape from 'src/shapes/indicator'
import Isolated from 'src/pages/preview/Isolated'
import learn21Image from 'src/images/learn21-transparent.svg'
import uuid from 'uuid'
import PageTitle from 'src/components/ui/PageTitle'

const MAXIMUM_ENTITIES_PER_PAGE = 15

const propTypes = {
  lesson: shape({
    chapters: array.isRequired,
  }).isRequired,
  lessonIndicators: arrayOf(shape(indicatorShape)).isRequired,
  teacherGuide: arrayOf(shape(entityShape)).isRequired,
}

const replace = ['Comic Strip', 'Speech Recognition', 'Speech Bubbles']

const addColon = title => replace.reduce((a, name) => a.replace(`${name} `, `${name}: `), title)

const prefixAndSuffix = ['Fixed', ' Homework', 'Lesson', 'No Image']
const removePrefixAndSuffix = title => (
  prefixAndSuffix.reduce((a, name) => a.replace(name, ''), title)
)

const componentTitle = ({ titleRaw }) => (
  <div className="flex-column mb4">
    <div>
      <span className="bold">{removePrefixAndSuffix(addColon(titleRaw.en))}</span>
    </div>
    <div>
      <span className="bold">{removePrefixAndSuffix(addColon(titleRaw.th))}</span>
    </div>
  </div>
)

const isCollection = slug => slug.split('-')[0] === 'collection'

const findChapter = (chapters, chapterId) => find(chapters, { id: chapterId })

const createHomeworkChaptersLink = (a, chapter) => ([
  ...a,
  ...chapter.homeworkChapters,
])

const displayEntityTitle = (titleEn, titleTh) => {
  if (titleTh) return `${titleEn} : ${titleTh}`
  return titleEn
}

const selectChapterTabTitle = (titleEn) => {
  const groupTitle = titleEn.split(':')[0]
  const groupTitleArray = groupTitle.split(' ')
  if (groupTitleArray[0] === 'Phonics') {
    return 'Presentation / การนำเสนอเนื้อหา'
  }
  if (groupTitleArray[0] === 'Vocabulary') {
    return 'Presentation / การนำเสนอเนื้อหา'
  }
  if (groupTitleArray[0] === 'Language' && groupTitleArray[2] === '1') {
    return 'Presentation / การนำเสนอเนื้อหา'
  }
  if (groupTitleArray[0] === 'Language' && groupTitleArray[2] === '2') {
    return 'Practice / ขั้นการฝึกปฏิบัติ'
  }
  if (groupTitleArray[0] === 'Homework') {
    return 'Production / ประมวนผลการเรียนรู้'
  }
  return null
}

const Lesson = ({
  lesson,
  lessonIndicators,
  teacherGuide,
  subjectId,
  unitId,
  gradeId,
  instructions,
  ...props
}) => {
  const { chapters, unit } = lesson
  const homeworkChaptersLink = chapters.reduce(createHomeworkChaptersLink, [])

  return (
    <Div print-container mx-auto>
      <style>
        {
          `
            body {
              background-image: none !important;
            }
            @media print {
              html { font-size: 8px !important }
              body { font-size: 14px !important }
              .h1 { font-size: 3rem !important }
            }
            .logo-end {
              padding-top: 27px;
              margin-left: -5px;
            }
          `
        }
      </style>
      <PageTitle>{`${lesson.identifier} ${lesson.title} Storyboard`}</PageTitle>
      <div>
        <div className="
          bg-blue-printable
          relative
          height-40vh
          print-show
          mb2
          "
        >
          <Div p2 flex>
            <Div ml2 flex><img alt="Learn21" src={learn21Image} /></Div>
            <Div fontSize={8} white ml2 lineHeight={20} className="logo-end">Storyboards</Div>
          </Div>
        </div>
      </div>
      <span className="h2 px2 bold">
        {lesson.identifier} {lesson.title} Storyboard
      </span>
      <div className="clearfix" />
      <AcademicCover
        indicators={lessonIndicators}
        lesson={lesson}
        unit={unit}
      />
      {chapters.map((chapter, count) => {
        const { component, entities, id: chapterId, titleRaw } = chapter
        const { indicators } = chapter
        const isHomework = chapter.homework
        const homeworkChapters = chapter.homeworkChapters.length > 0
          ? chapter.homeworkChapters
          : filter(homeworkChaptersLink, { chapterId: chapter.id })
        const homeworkChaptersTitle = isHomework ? 'Chapter Link:' : 'Homework Link:'
        const titleEn = { ...titleRaw }.en
        const chapterTabTitle = selectChapterTabTitle(titleEn)
        return (
          <div key={chapter.id} className="relative">
            <div className="printBreak" />
            <div
              className="clearfix mxn1 mb2 bg-white rounded py1 px2 height-80-rem-print"
            >
              {
                !chapter.published && (
                <span className="inline-block px1 white bg-red rounded print-hide">
                  Unpublished
                </span>
                )}
              {
                chapterTabTitle && (
                  <div className="h4">
                    {chapterTabTitle}
                  </div>
                )
              }
              <div className="h3 mb1">
                {componentTitle({ titleRaw: chapter.titleRaw })}
              </div>
              <div className="col-8 px1 left clearfix mb4">
                <div
                  className="relative border shadow bg-darken-1 rounded overflow-hidden"
                  style={{
                    width: '100%',
                    paddingBottom: '2rem',
                    paddingTop: '60%',
                  }}
                >
                  <Div absolute stretch flex column justify="center">
                    <Isolated chapterId={chapterId}>
                      <Chapter
                        lesson={lesson}
                        chapterId={chapterId}
                        instructions={instructions}
                        guide
                        {...props}
                      />
                    </Isolated>
                  </Div>
                </div>
              </div>
              <div className="col-4 pl2 right clearfix mb2 ">
                <Div className="h4r" key={uuid()}>
                  <h2>Entities</h2>
                  {
                    entities.length > 0 && component !== 'TitlePage' && (
                      <Div flex remove-border-print pl1>
                        <Div column key={uuid()} remove-border-print>
                          <ul>
                            {
                                entities.slice(0, MAXIMUM_ENTITIES_PER_PAGE)
                                  .map(({ id, titleEn, titleTh, slug }) => (
                                    <li key={id} className="h6">
                                      <span className={`${isCollection(slug) ? 'bold' : ''}`}>
                                        {displayEntityTitle(titleEn, titleTh)}
                                      </span>
                                    </li>
                                  ))
                              }
                          </ul>
                        </Div>
                      </Div>
                    )
                  }
                </Div>
              </div>
              {
                indicators.length > 0 && (
                  <div className="col-12 left px1 clearfix">
                    <Div className="h4r" key={uuid()}>
                      <h2>Indicators:</h2>
                      <Div flex remove-border-print px1>
                        <Div flex auto column key={uuid()} remove-border-print>
                          <ul>
                            {
                                indicators.map(({ id, identifier, title }) => (
                                  <li key={id} className="h6 mr3">
                                    <span>
                                      {identifier} {title}
                                    </span>
                                  </li>
                                ))
                              }
                          </ul>
                        </Div>
                      </Div>
                    </Div>
                  </div>
                )
              }
              {
                homeworkChapters.length > 0 && (
                  <div className="col-12 left px1 clearfix">
                    <Div className="h4r" key={uuid()}>
                      <h2>{homeworkChaptersTitle}</h2>
                      <Div flex remove-border-print px1>
                        {
                          chunk(homeworkChapters, Math.ceil(homeworkChapters.length / 2))
                            .map(homeworkChaptersArray => (
                              <Div flex auto column col-6 key={uuid()} remove-border-print>
                                <ul>
                                  {
                                    homeworkChaptersArray.map(({ id, chapterId, homeworkId }) => (
                                      <li key={id} className="h6 mr3">
                                        <span>
                                          {
                                            isHomework
                                              ? { ...findChapter(chapters, chapterId) }.title
                                              : { ...findChapter(chapters, homeworkId) }.title
                                          }
                                        </span>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </Div>
                            ))
                        }
                      </Div>
                    </Div>
                  </div>
                )
              }
            </div>
            <Div print-only right bottom>
              {lesson.identifier} {lesson.title} Storyboard &nbsp;
              <strong>/&nbsp;{count + 1}</strong>
            </Div>
          </div>
        )
      })}
    </Div>
  )
}

Lesson.propTypes = propTypes
export default Lesson
