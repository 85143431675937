import React from 'react'
import { array } from 'prop-types'
import map from 'lodash/map'
import { WordEnglish } from 'src/modules/Word'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import { THAI } from 'src/constants/language'
import VocabularyFlashCards from 'src/modules/VocabularyFlashCards/VocabularyFlashCards'

const propTypes = {
  entities: array.isRequired,
}

const createSlide = (entity, index) => (
  <Slide key={entity.key} label={index % 2 !== 0 ? entity.titleTh : '•'}>
    { index % 2 !== 0 ?
      <VocabularyFlashCards entity={entity} language={THAI} />
      :
      <WordEnglish entity={entity} />
    }
  </Slide>
)

const VocabularyReviewContainer = ({ entities }) => {
  const duplicateEntities = (a, entity) => (
    {
      ...a,
      [`TH-${entity.id}`]: {
        ...entity,
        id: `TH-${entity.id}`,
        key: `TH-${entity.id}`,
      },
      [`EN-${entity.id}`]: {
        ...entity,
        id: `EN-${entity.id}`,
        key: `EN-${entity.id}`,
      },
    }
  )

  const newEntities = map(entities.reduce(duplicateEntities, {}))
  return (
    <Slides>
      {
        newEntities.map((newEntity, index) => createSlide(newEntity, index + 1))
      }
    </Slides>
  )
}

VocabularyReviewContainer.propTypes = propTypes
VocabularyReviewContainer.demoData = {
  entities: [
    'mother',
    'father',
    'family',
  ],
}

export default VocabularyReviewContainer
