import React from 'react'
import { arrayOf, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import VocabularyAndSentenceExamples from 'src/modules/VocabularyAndSentenceExamples/VocabularyAndSentenceExamples'

const propTypes = {
  entities: arrayOf(
    shape(entityShape).isRequired,
  ).isRequired,
}

const VocabularyAndSentenceExamplesContainer = ({ entities }) => (
  <VocabularyAndSentenceExamples
    entities={entities}
  />
)

VocabularyAndSentenceExamplesContainer.propTypes = propTypes
VocabularyAndSentenceExamplesContainer.demoData = {
  entities: [
    'phrase-i-am',
    'phrase-i-am-anna',
    'phrase-you-are',
    'phrase-you-are-tom',
    'phrase-we-are',
    'phrase-we-are-family',
    'phrase-they-are',
    'phrase-they-are-my-parents',
    'phrase-he-is',
    'phrase-he-is-tom',
    'phrase-she-is',
    'phrase-she-is-mary',
  ],
}

export default VocabularyAndSentenceExamplesContainer
