import React from 'react'
import { array, shape } from 'prop-types'
import { t } from 'i18next'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'

const propTypes = {
  lesson: shape({
    objectives: array.isRequired,
  }).isRequired,
}

const Objectives = ({ lesson: { objectives } }) => (
  <Deck>
    <Div size={0} bold mb={2}>
      {t('objectives.objectives')}
    </Div>
    <Div size={2} mb={2}>
      {t('objectives.byTheEndOfThisLesson')}
    </Div>
    <Div size={2}>
      <ul>
        {
          objectives.map(({ id, title }) => (
            <li key={id}>
              {title}
            </li>
          ))
        }
      </ul>
    </Div>
  </Deck>
)

Objectives.propTypes = propTypes
export default Objectives
