import React from 'react'
import { bool, object } from 'prop-types'

import Div from 'src/components/Div'
import SentenceLine from 'src/components/SentenceLine'
import SpeakerBubble from 'src/components/SpeakerBubble'

const propTypes = {
  answerEntity: object.isRequired,
  questionEntity: object.isRequired,
  thai: bool,
}

const SpeechBubblesQuestionAndAnswer = ({
  questionEntity,
  answerEntity,
  thai,
}) => (
  <Div flex column auto justify-center>
    <Div flex flex-1 p1 print-m0>
      <SpeakerBubble
        hasTip
        justify="left"
        speakerEntity={questionEntity}
      >
        <SentenceLine
          align="left"
          justify="start"
          entity={questionEntity}
          key={questionEntity.id}
          keyToPlay={1}
          thai={thai}
          height={5}
        />
      </SpeakerBubble>
    </Div>
    <Div flex flex-1 p1 print-m0>
      {
        answerEntity && (
          <SpeakerBubble
            hasTip
            justify="left"
            right
            speakerEntity={answerEntity}
          >
            <SentenceLine
              align="left"
              justify="start"
              entity={answerEntity}
              key={answerEntity.id}
              keyToPlay={thai ? 3 : 2}
              thai={thai}
              height={5}
            />
          </SpeakerBubble>
        )
      }
    </Div>
  </Div>
)

SpeechBubblesQuestionAndAnswer.propTypes = propTypes

export default SpeechBubblesQuestionAndAnswer
