import React from 'react'
import { bool, shape, func, string } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'

const propTypes = {
  answer: string.isRequired,
  disabled: bool,
  guide: bool,
  handleChange: func,
  question: shape(entityShape).isRequired,
}

const defaultProps = {
  disabled: null,
  guide: false,
  handleChange: () => {},
}

const TypeAndSave = ({
  answer,
  disabled,
  handleChange,
  guide,
  question,
}) => (
  <Deck>
    <Div mb2 bold>
      <AdaptiveCard
        align="left"
        english
        entity={question}
        height={6}
        p={2}
        transparent
        justify="start"
      />
    </Div>
    <Div>
      <textarea
        disabled={disabled}
        autoFocus={!!guide}
        placeholder="Your answer here.."
        value={answer}
        onChange={({ target: { value } }) => handleChange(value)}
        style={{ fontSize: '3rem', height: '30rem', width: '100%' }}
      />
    </Div>
  </Deck>
)

TypeAndSave.propTypes = propTypes
TypeAndSave.defaultProps = defaultProps

export default TypeAndSave
