import React from 'react'
import { string } from 'prop-types'

const propTypes = {
  title: string.isRequired,
}

const SectionTitle = ({ title }) => (
  <tr>
    <td className="bold">
      <div>
        {title}
      </div>
    </td>
    <td colSpan="100%" />
  </tr>
)

SectionTitle.propTypes = propTypes

export default SectionTitle
