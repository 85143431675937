import React, { Component } from 'react'
import { func, number, string } from 'prop-types'
import debounce from 'lodash/debounce'
import { t } from 'i18next'
import ReactTable from 'react-table'
import Icon from 'src/components/Icon'
import Widget from 'src/components/Widget'
import LinkToNewYear from 'src/pages/Years/LinkToNewYear'
import YearLinkCell from 'src/pages/Years/components/YearLinkCell'
import PageTitle from 'src/components/ui/PageTitle'

const propTypes = {
  domain: string,
  onFetchData: func.isRequired,
  pageSize: number,
}

const defaultProps = {
  domain: undefined,
  pageSize: 20,
}

const DEFAULT_SORT_COLUMN = 'title'

export class Years extends Component {
  constructor(props) {
    super(props)

    const { domain, pageSize } = this.props
    const sortColumn = DEFAULT_SORT_COLUMN

    this.state = {
      data: [],
      domain,
      loading: false,
      offset: 0,
      pageSize,
      pages: 1,
      sorted: { desc: false, title: sortColumn },
      totalCount: 0,
    }
  }

  componentDidMount() {
    this.handleFetchData(this.state)
  }

  handleFetchData = debounce(async (state) => {
    const { domain, onFetchData } = this.props
    const { offset, sorted } = this.state
    const { pageSize } = state
    await this.setState({ loading: true })

    const options = {
      fetchPolicy: 'network-only',
      variables: {
        domain,
        limit: pageSize,
        offset,
        orderBy: sorted,
      },
    }

    const {
      data: { yearsSearch },
    } = await onFetchData({ options })

    const totalCount = yearsSearch.total_count
    const pageCount = Math.ceil(totalCount / pageSize)

    await this.setState({
      data: yearsSearch.rows,
      loading: false,
      pages: pageCount,
      totalCount,
    })
  }, 200)

  handlePageChange = async (pageIndex) => {
    const { pageSize } = this.state
    await this.setState({
      offset: pageIndex * pageSize,
    })
  }

  handlePageSizeChange = async (pageSize) => {
    await this.setState({
      offset: 0,
      pageSize,
    })
  }

  handleSortedChange = async (newSorted) => {
    const { id, desc } = newSorted[0]
    await this.setState({
      offset: 0,
      sorted: { desc, id },
    })
  }

  render() {
    const {
      data, pages, loading, pageSize, totalCount,
    } = this.state

    const pageTitle = t('menu.YEARS.title')

    const columns = [
      {
        Cell: YearLinkCell,
        Header: t('year.title'),
        accessor: 'title',
        maxWidth: 150,
      },
      {
        Header: t('year.studentCount'),
        accessor: 'total',
        maxWidth: 200,
      },
      {
        Header: t('year.teachersName'),
        accessor: 'teachersName',
      },
    ]

    const sortColumn = DEFAULT_SORT_COLUMN
    const defaultSorted = [{ id: sortColumn }]

    const title = `${pageTitle}: ${totalCount}`

    return (
      <Widget icon="graduation-cap" title={title}>
        <PageTitle>{title}</PageTitle>
        <LinkToNewYear />
        <ReactTable
          className="qa-year-table -striped -highlight"
          columns={columns}
          data={data}
          defaultPageSize={pageSize}
          defaultSorted={defaultSorted}
          loading={loading}
          loadingText=""
          manual
          minRows={1}
          nextText={<span>{t('direction.next')}<Icon icon="arrow-right" after /></span>}
          onFetchData={this.handleFetchData}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortedChange}
          pages={pages}
          previousText={<span><Icon icon="arrow-left" before />  {t('direction.previous')}</span>}
        />
        <LinkToNewYear />
      </Widget>
    )
  }
}

Years.propTypes = propTypes
Years.defaultProps = defaultProps

export default Years
