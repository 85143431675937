import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import { arrayOf, func, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'

import * as gameActions from 'src/modules/ListeningWords/reducer'
import ListeningWordsCore from 'src/modules/ListeningWords/ListeningWordsCore'

export const ListeningWords = props => (
  <ListeningWordsCore init={() => props.initDefault({ entities: props.entities })} {...props} />
)
export const ListeningWordsPaired = props => (
  <ListeningWordsCore init={() => props.initPaired({ entities: props.entities })} {...props} />
)

ListeningWords.propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
  initDefault: func.isRequired,
}
ListeningWords.demoData = {
  entities: [
    'collection-igh',
    'fight',
    'night',
    'fright',
    'eight',
    'collection-y',
    'fry',
    'why',
    'cry',
    'try',
  ],
}

ListeningWordsPaired.propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
  initPaired: func.isRequired,
}
ListeningWordsPaired.demoData = {
  entities: [
    'fight',
    'night',
    'fright',
    'eight',
    'fry',
    'why',
    'cry',
    'try',
  ],
}

const select = state => state.listeningWords
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const ListeningWordsPairedContainer = connect(select, actions)(ListeningWordsPaired)
export default connect(select, actions)(ListeningWords)
