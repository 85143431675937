import React from 'react'
import { t } from 'i18next'

import gradeSystem from 'src/../config/gradeToColor.json'
import StickyTable from 'src/components/StickyTable'
import TableBody from 'src/pages/Grading/TableBody'
import TableHeader from 'src/pages/Grading/TableHeader'
import Widget from 'src/components/Widget'
import { GRADING } from 'src/constants/menu'
import { schoolSection } from 'src/lib/menu'

export const Grading = () => {
  const { icon } = schoolSection.find(item => item.id === GRADING)
  const title = t(`${GRADING}.title`)
  return (
    <Widget icon={icon} color="blue" title={title}>
      <StickyTable>
        <TableHeader />
        <TableBody gradeSystem={gradeSystem} />
      </StickyTable>
    </Widget>
  )
}

export default Grading
