import React, { useContext } from 'react'
import { matchPath, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { arrayOf, bool, string } from 'prop-types'
import isArray from 'lodash/isArray'
import { exists, t } from 'i18next'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import DomainContext from 'src/contexts/DomainContext'
import { linkTo } from 'src/lib/linkTo'
import { UserContext } from 'src/components/SetUser/SetUser'
import { PORTAL_SCOPE, STUDENT_SCOPE } from 'src/constants/routeScopes'

const propTypes = {
  exact: bool,
  icon: string.isRequired,
  id: string.isRequired,
  matchPaths: arrayOf(string),
  path: string.isRequired,
}

const defaultProps = {
  exact: false,
  matchPaths: undefined,
}

export const MenuLink = ({ icon, id, path, matchPaths, exact }) => {
  const domain = useContext(DomainContext)
  const user = useContext(UserContext)
  const userId = (([PORTAL_SCOPE, STUDENT_SCOPE].includes(domain)) && (user && user.slug)) || null
  // using path params to override user ids
  let { parentId, studentId } = useParams()
  // fall back to current user if there is no id in path params (e.g. lessons page)
  parentId = parentId || (user && user.isParent && user.slug) || null
  studentId = studentId || (user && user.isStudent && user.slug) || null
  let to = ''
  try {
    to = linkTo(path)({ domain, parentId, studentId, userId })
  } catch (err) {
    // At this point we don't have all ids to make a link.
    // User will be redirected, but menu has to be rendered first.
  }
  return (
    <Div
      flex
      col={3}
      justify-between
      style={{ minWidth: 150 }}
      noSelect
    >
      <NavLink
        to={to}
        title={exists(`${id}.description`) ? t(`${id}.description`) : null}
        className="button-hover rounded px1 py mb"
        activeClassName="bg-blue white"
        exact={exact}
        isActive={isArray(matchPaths)
          ? (_, location) => matchPath(location.pathname, { path: matchPaths })
          : null
        }
      >
        <Icon icon={icon} before />
        {t(`${id}.title`)}
      </NavLink>
    </Div>
  )
}

MenuLink.propTypes = propTypes
MenuLink.defaultProps = defaultProps

export default MenuLink
