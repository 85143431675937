import React from 'react'
import { object, func } from 'prop-types'
import map from 'lodash/map'
import Deck from 'src/components/Layout/Deck'
import Line from 'src/modules/OrderingSentences/Line'

const propTypes = {
  moveCard: func.isRequired,
  places: object.isRequired,
}

const OrderingSentences = ({
  moveCard,
  places,
  entitiesById,
  placesLength,
}) => (
  <Deck>
    {
      map(places, ({ id, cardId, label, correct, imageSize }) => (
        <Line
          key={id}
          id={id}
          cardId={cardId}
          moveCard={moveCard}
          label={label}
          entitiesById={entitiesById}
          correct={correct}
          imageSize={imageSize}
          placesLength={placesLength}
        />
      ))
    }
  </Deck>
)

OrderingSentences.propTypes = propTypes

export default OrderingSentences
