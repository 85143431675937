import React from 'react'
import { useParams } from 'react-router'
import Access from 'src/components/Access'
import TheLayout from 'src/pages/TheLayout'
import SetUser from 'src/components/SetUser/SetUser'
import StudentLayout from 'src/pages/StudentLayout/StudentLayout'

const StudentLayoutContainer = ({ children }) => {
  const { studentId } = useParams()
  return (
    <TheLayout>
      <Access student parent teacher staff redirect>
        <SetUser userId={studentId}>
          <StudentLayout>
            { children }
          </StudentLayout>
        </SetUser>
      </Access>
    </TheLayout>
  )
}

export default StudentLayoutContainer
