import React from 'react'
import { arrayOf, bool, number, string, shape } from 'prop-types'
import { t } from 'i18next'
import { rangeBy } from 'src/lib/dates'
import Deck from 'src/components/Layout/Deck'
import StickyTable from 'src/components/StickyTable'
import Widget from 'src/components/Widget'

import Header from 'src/pages/AttendancePage/AttendanceWeekly/Header'
import TableHead from 'src/pages/AttendancePage/AttendanceWeekly/TableHead'
import TableBody from 'src/pages/AttendancePage/AttendanceWeekly/TableBody'

const propTypes = {
  attendanceWeekly: shape({
    academicYear: shape({
      title: string.isRequired,
    }).isRequired,
    endDate: string.isRequired,
    sections: arrayOf(shape({
      id: string.isRequired,
      title: string.isRequired,
      years: arrayOf(shape({
        id: string.isRequired,
        students: arrayOf(shape({
          attendances: arrayOf(shape({
            attendanceOn: string.isRequired,
            present: bool.isRequired,
          })),
          fullName: string.isRequired,
          id: string.isRequired,
        })),
        title: string.isRequired,
      })),
    })),
    startDate: string.isRequired,
    week: number.isRequired,
  }).isRequired,
}


const AttendanceWeekly = ({ attendanceWeekly }) => {
  const title = t('attendance.weekly')
  const { startDate, endDate, week, sections, academicYear } = attendanceWeekly
  const beginningOfWeek = startDate
  const endOfWeek = endDate
  const days = rangeBy(beginningOfWeek, endOfWeek, 'days')
  return (
    <Deck>
      <Widget icon="chart-pie" title={title}>
        <Header
          academicYear={academicYear}
          beginningOfWeek={beginningOfWeek}
          endOfWeek={endOfWeek}
          week={week}
        />

        <StickyTable>
          <TableHead days={days} />
          <TableBody days={days} sections={sections} />
        </StickyTable>
      </Widget>
    </Deck>
  )
}

AttendanceWeekly.propTypes = propTypes

export default AttendanceWeekly
