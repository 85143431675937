import React, { useState } from 'react'
import { t } from 'i18next'
import { string, func, element, bool, instanceOf } from 'prop-types'
import { withApollo } from '@apollo/react-hoc'
import { ApolloClient } from 'apollo-client'
import DateRangeFields from 'src/components/DateRangeFields'
import Checkbox from 'src/components/Checkbox'
import Div from 'src/components/Div'

const propTypes = {
  children: element,
  client: instanceOf(ApolloClient),
  endDate: string,
  holiday: bool,
  id: string,
  isNew: bool,
  onCancel: func,
  onSubmit: func.isRequired,
  startDate: string,
  titleEn: string,
  titleTh: string,
}

const defaultProps = {
  children: undefined,
  client: undefined,
  endDate: null,
  holiday: false,
  id: null,
  isNew: null,
  onCancel: null,
  startDate: null,
  titleEn: '',
  titleTh: '',
}

const Form = ({
  client,
  holiday: propsHoliday,
  id,
  titleEn,
  titleTh,
  startDate,
  endDate,
  onCancel,
  onSubmit,
  isNew,
  children,
}) => {
  const [holiday, setHoliday] = useState(propsHoliday)
  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.target
    const inputs = form.elements
    onSubmit(
      {
        endDate: inputs.namedItem('to').value,
        holiday,
        id,
        startDate: inputs.namedItem('from').value,
        titleEn: inputs.namedItem('titleEn').value,
        titleTh: inputs.namedItem('titleTh').value,
      },
      client
    )
    if (isNew) form.reset()
  }
  return (
    <form onSubmit={handleSubmit} className="qa-calendar-events-form">
      <Div flex wrap>
        <Div>
          <label className="block">{t('shared.en')}</label>
          <input type="text" name="titleEn" required defaultValue={titleEn} />
        </Div>
        &nbsp;&nbsp;&nbsp;
        <Div>
          <label className="block">{t('shared.th')}</label>
          <input type="text" name="titleTh" defaultValue={titleTh} />
        </Div>
      </Div>
      <br />
      <div className="row">
        <DateRangeFields blockLabels from={startDate} to={endDate} />
      </div>
      <div className="row">
        <div className="edit-calendar-events__holidays inline-block">
          <label
            className="block cursor-pointer"
            onClick={() => setHoliday(!holiday)}
          >
            <Checkbox value={holiday} /> {t('school.settings.holiday')}
          </label>
        </div>
        <div className="edit-calendar-events__actions">
          <button
            className="btn btn-primary bg-olive mr2 qa-save-event-button"
            type="submit"
          >
            {isNew ? t('shared.add') : t('shared.save')}
          </button>
          <button className="btn btn-primary bg-red mr2" onClick={onCancel}>
            {t('shared.cancel')}
          </button>
          {children}
        </div>
      </div>
    </form>
  )
}

Form.defaultProps = defaultProps
Form.propTypes = propTypes

export default withApollo(Form)
