import React from 'react'
import { oneOf, shape } from 'prop-types'
import Div from 'src/components/Div'

import EntityCard from 'src/components/EntityCard'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import KeyLabel from 'src/components/KeyLabel'
import { ENGLISH, THAI } from 'src/constants/language'

const propTypes = {
  entity: shape(entityShape).isRequired,
  language: oneOf([ENGLISH, THAI]).isRequired,
}
const defaultProps = {
  entity: null,
}

const VocabularyFlashCards = ({ entity, language }) => (
  <Div flex auto gutter={2}>
    <Div flex column col={4} px={2}>
      <EntityImage entity={entity} />
    </Div>
    <Div flex column col={8} px={2}>
      <Div flex column auto justify="center">
        <Div flex justify="center" mb={2}>
          <KeyLabel label="1" />
          <div>
            <EntityCard
              audio
              bold
              type={language}
              fit
              keyToPlay="1"
              key={entity.id}
              entity={entity}
              size={0}
            />
          </div>
        </Div>
      </Div>
    </Div>
  </Div>
)

VocabularyFlashCards.propTypes = propTypes
VocabularyFlashCards.defaultProps = defaultProps

export default VocabularyFlashCards
