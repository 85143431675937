import { withApollo } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import LessonQuery from 'src/queries/pages/Lesson/Lesson.gql'
import studentUnitTestResult from 'src/pages/UnitTest/studentUnitTestResult.gql'
import BackgroundComponentLayoutQuery from 'src/queries/pages/Lesson/BackgroundComponentLayout.gql'
import gqlLoader from 'src/lib/gqlLoader'
import UnitTest from 'src/pages/UnitTest/UnitTest'

export default compose(
  gqlLoader(studentUnitTestResult),
  gqlLoader(BackgroundComponentLayoutQuery),
  gqlLoader(LessonQuery),
)(withApollo(UnitTest))
