import React, { useContext } from 'react'
import { t } from 'i18next'
import uniq from 'lodash/uniq'
import max from 'lodash/max'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import Logo from 'src/components/Logo'
import SchoolTitle from 'src/components/SchoolTitle'
import PersonImg from 'src/components/PersonImg'
import ScalableModule from 'src/components/ScalableModule'
import { LocaleContext } from 'src/lib/contexts'
import {
  dateSevenDayAgo,
  formatDateTimeLong,
  formatDayMediumNumeric,
  formatMonthMedium,
} from 'src/lib/dates'
import bestStudentsPerClassShape
  from 'src/pages/DashboardPage/StudentsLeaderboard/bestStudentsPerClassShape'
import BestStudentSlider from 'src/pages/DashboardPage/BestStudentSlider'

const propTypes = {
  bestStudentsPerClass: bestStudentsPerClassShape,
}

// Unit
const DEFAULT_COLUMN_NUMBER = 8 // unit
const DEFAULT_ROW_NUMBER = 6 // unit

// Ratio
const IMAGE_HEIGHT_RATIO = 4 / 3
const SCREEN_HEIGHT_RATIO = 1080 / 1920

// Size, px
const DEFAULT_SCREEN_WIDTH = 1920
const STUDENT_DETAIL_WIDTH = 100
const DEFAULT_IMAGE_WIDTH = 115
const STUDENT_CARD_WIDTH = STUDENT_DETAIL_WIDTH + DEFAULT_IMAGE_WIDTH

const ensureSection = (a, student, rotation) => {
  const classId = student.classEn.replace(/[./]/g, '-')
  const sectionId = rotation ? `P${classId.slice(-2)}` : classId.slice(0, 3)
  const sectionEn = student.classEn.slice(0, 3)
  const sectionTh = student.classTh.slice(0, 3)
  const initSection = {
    id: sectionId,
    sectionEn,
    sectionTh,
    studentCount: 0,
    classrooms: {},
  }
  const section = a[sectionId] || initSection
  return {
    ...section,
    studentCount: section.studentCount + 1,
  }
}

const ensureClassroom = (section, student) => {
  const classId = student.classEn.replace(/[./]/g, '-')
  const initClassroom = {
    classEn: student.classEn,
    classTh: student.classTh,
    id: classId,
    students: [],
  }
  const classroom = section.classrooms[classId] || initClassroom
  return {
    ...section,
    classrooms: {
      ...section.classrooms,
      [classId]: {
        ...classroom,
        students: classroom.students.concat(student),
      },
    },
  }
}

const createClassOfStudent = ({ rotation }) => (a, student) => {
  const section = ensureClassroom(ensureSection(a, student, rotation), student)
  return {
    ...a,
    [section.id]: section,
  }
}

const headerTextStyle = {
  alignSelf: 'center',
  fontSize: '2.2rem',
  fontWeight: '600',
  paddingBottom: '0.43rem',
}

export const imageUrl = (image) => {
  if (image) return `https://storage.googleapis.com/learn21-tenants/${image}`
  return undefined
}

export const isTBA = ({ studentNameEn }) => studentNameEn === 'TBA'

export const TBAStyle = ({ studentNameEn }) => {
  if (studentNameEn === 'TBA') return ({ opacity: 0.9, textAlign: 'center' })
  return {}
}

const qaStudentIdClass = ({ studentId, studentNameEn }) => (
  isTBA({ studentNameEn }) ? 'qa-student-TBA' : `qa-student-${studentId}`
)

const StudentsLeaderboard = ({ bestStudentsPerClass: { bestStudents } }) => {
  const { locale } = useContext(LocaleContext)
  const columnNumber = uniq(map(bestStudents, (({ classEn }) => classEn.slice(-2)))).length
  const rotation = columnNumber < DEFAULT_ROW_NUMBER
  const classOfStudent = bestStudents.reduce(createClassOfStudent(({ rotation })), {})
  const maximumStudentPerSection = max(map(classOfStudent, 'studentCount'))
  const classTitle = ({ classTh, classEn }) => (locale === 'en' ? classEn : classTh)
  const sectionTitle = ({ sectionEn, sectionTh }) => (locale === 'en' ? sectionEn : sectionTh)
  const studentName = ({ studentNameTh, studentNameEn }) => {
    const studentTitle = locale === 'en' ? studentNameEn : studentNameTh
    return studentTitle.split(' ')[0]
  }

  const extraColumn = maximumStudentPerSection <= DEFAULT_COLUMN_NUMBER
    ? 0
    : maximumStudentPerSection - DEFAULT_COLUMN_NUMBER
  const studentCardWidth = STUDENT_CARD_WIDTH
  const screenWidth = DEFAULT_SCREEN_WIDTH + (extraColumn * studentCardWidth)
  const screenHeight = screenWidth * SCREEN_HEIGHT_RATIO
  const imageWidth = DEFAULT_IMAGE_WIDTH
  const imageHeight = imageWidth * IMAGE_HEIGHT_RATIO

  const DATE_SEVEN_DAY_AGO = dateSevenDayAgo()
  // eslint-disable-next-line
  const SEVEN_DAY_AGO_DATE = `${formatDayMediumNumeric(DATE_SEVEN_DAY_AGO)} ${formatMonthMedium(DATE_SEVEN_DAY_AGO)}`
  // eslint-disable-next-line
  const HEADER_DATE = `${t('leaderboard.StarsOfTheWeek')} ${SEVEN_DAY_AGO_DATE} - ${formatDateTimeLong()}`

  return (
    <ScalableModule screenHeight={screenHeight} screenWidth={screenWidth}>
      <style>
        {
          `body {
            background-color: #0088ee !important;
          }`
        }
      </style>
      <Div flex white>
        {
          !rotation && (
            <Div style={{ width: '50px' }} mr2 />
          )
        }
        <Div flex relative>
          <Logo />
          <Div
            style={headerTextStyle}
          >
            <SchoolTitle bold={false} />
          </Div>
          <Div
            ml2
            style={{
              ...headerTextStyle,
              fontWeight: '300',
            }}
          >
            {HEADER_DATE}
          </Div>
        </Div>
      </Div>
      <Div
        column
        flex
        auto
        justify-center={!rotation}
        style={{
          alignSelf: 'center',
          fontSize: '0.8rem',
        }}
      >
        {map(classOfStudent, (({ classrooms, id, sectionEn, sectionTh }) => (
          <Div flex key={id} className={`qa-section-${id}`}>
            {
              !rotation && (
                <Div
                  color="white"
                  flex
                  style={{
                    alignItems: 'center',
                    fontSize: '2.2rem',
                    width: '50px',
                  }}
                  justify-center
                  mr2
                >
                  {sectionTitle({
                    sectionEn,
                    sectionTh,
                  })}
                </Div>
              )
            }
            {map(classrooms, (({ id: classId, students }) => (
              <Div flex column key={classId} className={`qa-class-${classId}`}>
                <Div flex justify-center>
                  {students.map(({
                    avgStudentGrade,
                    classTh,
                    classEn,
                    id: studentId,
                    studentNameTh,
                    studentNameEn,
                    studentPhotoPath,
                  }, index) => (
                    // eslint-disable-next-line no-nested-ternary
                    students.length > 1 && avgStudentGrade === '4.0' ?
                      index > 0 ? '' : (
                        <BestStudentSlider key={studentId} students={students} />) : (
                          <Div
                            flex
                            auto
                            key={studentId}
                            studentBox
                            className={qaStudentIdClass({
                              studentId,
                              studentNameEn,
                            })}
                          >
                            <Div
                              flex
                              relative
                              style={{
                                height: imageHeight,
                                width: imageWidth,
                              }}
                            >
                              <PersonImg
                                src={imageUrl(studentPhotoPath)}
                                position="center"
                                style={TBAStyle({ studentNameEn })}
                              />
                            </Div>
                            <Div flex column p style={{ width: STUDENT_DETAIL_WIDTH }}>
                              <Div flex justify-center bold>{classTitle({
                                classEn,
                                classTh,
                              })}
                              </Div>
                              <Div flex justify-center style={TBAStyle({ studentNameEn })}>
                                {
                              isTBA({ studentNameEn }) ? t('leaderboard.tryHarder')
                                : studentName({
                                  studentNameEn,
                                  studentNameTh,
                                })
                            }
                              </Div>
                              <Div flex justify-center>
                                {avgStudentGrade && parseFloat(avgStudentGrade)
                                  .toFixed(2)}
                              </Div>
                              <Div flex auto justify-center style={{ alignItems: 'center' }}>
                                {
                              avgStudentGrade === '4.0' && (
                                <Icon
                                  icon="certificate"
                                  className="student-icon blue"
                                  style={{
                                    fontSize: '50px',
                                  }}
                                />
                              )
                            }
                              </Div>
                            </Div>
                          </Div>
                      )))}
                </Div>
              </Div>
            )))}
          </Div>
        )))}
      </Div>
    </ScalableModule>
  )
}

StudentsLeaderboard.propTypes = propTypes
export default StudentsLeaderboard
