import React from 'react'
import { array, func } from 'prop-types'
import propsMaybeWithoutMeaning from 'src/lib/propsMaybeWithoutMeaning'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  entities: array.isRequired,
  initParagraph: func.isRequired,
}

const defaultProps = {
  audioOnly: false,
  cardsSize: 3,
  paragraph: true,
  silent: false,
}

const MultipleChoiceParagraph3 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)

  return (
    <MultipleChoiceCore
      init={() => props.initParagraph({ ...props })}
      {...props}
    />
  )
}

MultipleChoiceParagraph3.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'wiindow',
    'phrase-choose-the-correct-spelling',
    'door',
    'dour',
    'doorr',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)


export const MultipleChoiceParagraph2 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)
  return (
    <MultipleChoiceParagraph3 cardsSize={2} {...props} />
  )
}

export const MultipleChoiceParagraph2Container =
  connect(select, actions)(MultipleChoiceParagraph2)

export const MultipleChoiceParagraph4 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)
  return (
    <MultipleChoiceParagraph3 cardsSize={4} {...props} />
  )
}

export const MultipleChoiceParagraph4Container =
  connect(select, actions)(MultipleChoiceParagraph4)

const demoData2Choices = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'paragraph-all-about-me',
    'phrase-choose-the-correct-spelling',
    'door',
    'dor',
    'phrase-it-is-a-mango',
    'father',
    'mother',
  ],
}

const demoData4Choices = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'windohh',
    'wiindow',
    'paragraph-all-about-me',
    'phrase-choose-the-correct-spelling',
    'door',
    'dor',
    'dour',
    'doorr',
    'phrase-it-is-a-mango',
    'father',
    'mother',
    'sister',
    'brother',
  ],
}

MultipleChoiceParagraph2Container.demoData = demoData2Choices
MultipleChoiceParagraph4Container.demoData = demoData4Choices

MultipleChoiceParagraph2Container.propTypes = propTypes
MultipleChoiceParagraph4Container.propTypes = propTypes

MultipleChoiceParagraph3.propTypes = propTypes
MultipleChoiceParagraph3.defaultProps = defaultProps

export { MultipleChoiceParagraph3 }
export default connect(select, actions)(MultipleChoiceParagraph3)
