import React from 'react'
import { any, object } from 'prop-types'
import i18next from 'i18next'
import { connect } from 'src/lib/redux'
import { LocaleContext } from 'src/lib/contexts'

const propTypes = {
  children: any.isRequired,
  schoolTitle: object.isRequired,
}

export const LocaleProvider = ({ children, schoolTitle }) => {
  const context = { locale: i18next.language, schoolTitle }
  return (
    <LocaleContext.Provider value={context}>
      {children}
    </LocaleContext.Provider>
  )
}

const select = state => ({
  schoolTitle: state.tenantSettings.schoolTitle,
})

LocaleProvider.propTypes = propTypes

export default connect(select)(LocaleProvider)
