import React from 'react'
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types'
import { t } from 'i18next'

import { MENU } from 'src/constants/localeNamespace'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import MenuLink from 'src/pages/TheLayout/Menu/MenuLink'

const propTypes = {
  menuItems: arrayOf(shape({
    common: bool,
    icon: string.isRequired,
    id: string.isRequired,
    link: oneOfType([
      string,
      func,
    ]),
  })).isRequired,
  sectionKey: string.isRequired,
}

const MenuSection = ({ sectionKey, menuItems }) => (
  <section>
    <Div mln2 pt mb>
      <Div bg="blue" mln pl2 white inline-block pr2 relative border-bottom border-right>
        <Div pl>
          <Icon before icon="angle-down" />
          {t(sectionKey, { ns: MENU })}
        </Div>
      </Div>
    </Div>
    <Div flex wrap pb mxn1>
      {
        menuItems.map(({ icon, id, path, matchPaths, exact }) => (
          <MenuLink
            icon={icon}
            id={id}
            key={id}
            matchPaths={matchPaths}
            path={path}
            exact={exact}
          />
        ))
      }
    </Div>
  </section>
)

MenuSection.propTypes = propTypes
export default MenuSection
