import React, { Component } from 'react'
import { array, func, object } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import map from 'lodash/map'
import Slides from 'src/components/Layout/Slides'
import Slide from 'src/components/Layout/Slide'
import * as actions from 'src/modules/FamilyTreeSlideshow/reducer'
import FamilyTree from 'src/modules/FamilyTreeSlideshow/FamilyTree'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  init: func.isRequired,
  placeLocations: array.isRequired,
  places: object.isRequired,
}

class FamilyTreeSlideshowContainer extends Component {
  componentDidMount() {
    const { init } = this.props
    init(this.props)
  }

  getSlides() {
    const { cards, entitiesById, placeLocations, places } = this.props
    const createSlide = (slide, i) => (
      <Slide key={{ id: 'last', ...slide }.id}>
        <FamilyTree
          cards={cards}
          currentSlide={i}
          entitiesById={entitiesById}
          member={slide}
          placeLocations={placeLocations}
          places={places}
        />
      </Slide>
    )
    const slides = map(entitiesById).concat(null)
    return slides.map(createSlide)
  }

  render() {
    return (
      <Slides>
        {this.getSlides()}
      </Slides>
    )
  }
}

FamilyTreeSlideshowContainer.propTypes = propTypes
FamilyTreeSlideshowContainer.demoData = {
  entities: [
    'face-grandfather-father',
    'face-grandmother-father',
    'face-grandfather-mother',
    'face-grandmother-mother',
    'face-father',
    'face-mother',
    'face-younger-sister',
    'face-younger-brother',
    'face-elder-brother',
    'face-elder-sister',
  ],
}
const selector = state => state.familyTreeSlideshow
const boundActions = dispatch => bindActionCreators(actions, dispatch)
export default connect(selector, boundActions)(FamilyTreeSlideshowContainer)
