import uuid from 'uuid/v1'
import createHomeworkAnswer from 'src/mutations/createHomeworkAnswer.gql'
import createHomeworkChapterAnswer from 'src/mutations/createHomeworkChapterAnswer.gql'
import { iterations, defaultIterations } from 'src/lib/homework'

const { log, trace } = console

const INIT = 'homework/INIT'
const SET = 'homework/SET'
const CLEAR = 'homework/CLEAR'
const ITERATION_DONE = 'homework/ITERATION_DONE'
const START_GAME = 'homework/startGame'

const initialState = {
  homeworkIterations: [],
  homeworkSessionId: null,
  started: false,
  totalIterations: 0,
}

const initHomework = () => ({
  type: INIT,
})

const setHomework = moduleId => ({
  payload: {
    moduleId,
  },
  type: SET,
})

const iterationDone = (completed, iteration) => ({
  payload: {
    completed,
    iteration,
  },
  type: ITERATION_DONE,
})

const clearHomework = () => ({
  type: CLEAR,
})

const serverRequest = (props, client) => () => client.mutate(
  {
    mutation: createHomeworkAnswer,
    variables: props,
  },
).then((response) => {
  log('Server response on mutation', response)
}).catch(trace)

const storeChapterAnswer = ({
  chapterId,
  answer,
} = {}, client) => async (dispatch, getState) => {
  const { homework: { homeworkSessionId, homeworkIterations, iterationLog } } = getState()

  try {
    await client.mutate({
      mutation: createHomeworkChapterAnswer,
      variables: {
        chapterId,
        homeworkSessionId,
        answer,
        iterationsTotal: homeworkIterations.length,
        iterationsDone: homeworkIterations.filter(i => i === !!i).length,
        iterationsCorrect: homeworkIterations.filter(i => !!i).length,
        props: {
          iterations: iterationLog,
        },
      },
    })
  } catch (err) {
    trace(err)
  }
}

const storeHomeworkResults = ({
  actualEntities,
  chapterId,
  completed,
  entities,
  slug,
  ...restProps
}, client) => (dispatch, getState) => {
  const actualEntityIds = actualEntities ? actualEntities.map(e => e.id) : []
  const slugEntities = entities ? entities.map(e => e.slug) : undefined
  const { homework: { homeworkSessionId, homeworkIterations } } = getState()
  const props = {
    ...restProps,
    actualEntityIds,
    completed,
    entities: slugEntities,
    iteration: homeworkIterations.filter(i => i === !!i).length,
    totalIterations: homeworkIterations.length,
  }
  dispatch(serverRequest({ chapterId, homeworkSessionId, props, slug }, client))
}

const startGame = ({ started }) => ({
  payload: {
    started,
  },
  type: START_GAME,
})

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case CLEAR: {
      return {
        ...state,
        homeworkIterations: [],
        iterationLog: [],
        totalIterations: 0,
      }
    }
    case ITERATION_DONE: {
      const { completed, iteration } = payload
      const { totalIterations, homeworkIterations, iterationLog = [] } = state
      const index = state.homeworkIterations.indexOf(null)
      const newHomeworkIterations = index < 0 ? homeworkIterations : [
        ...homeworkIterations.slice(0, index),
        !!completed,
        ...homeworkIterations.slice(index + 1, totalIterations),
      ]
      return {
        ...state,
        homeworkIterations: newHomeworkIterations,
        iterationLog: [...iterationLog, iteration],
      }
    }
    case INIT: {
      return {
        ...state,
        homeworkIterations: [],
        iterationLog: [],
        homeworkSessionId: uuid(),
        totalIterations: 0,
      }
    }
    case SET: {
      const totalIterations = iterations[payload.moduleId] || defaultIterations
      return {
        ...state,
        homeworkIterations: Array(totalIterations).fill(null),
        totalIterations,
      }
    }
    case START_GAME: {
      return {
        ...state,
        ...payload,
      }
    }
    default: {
      return state
    }
  }
}

export {
  clearHomework,
  initHomework,
  iterationDone,
  setHomework,
  startGame,
  storeChapterAnswer,
  storeHomeworkResults,
}

export default reducer
