import React from 'react'
import { func, bool } from 'prop-types'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const propTypes = {
  onClick: func,
  value: bool.isRequired,
}

const Checkbox = ({ onClick, value }) => (
  <Div center inline-flex>
    <a
      className="p1 cursor-pointer rounded"
      onClick={onClick}
    >
      <Icon
        center
        className={value ? 'green' : 'black'}
        icon={value ? 'check-square' : 'square'}
      />
    </a>
  </Div>
)

Checkbox.propTypes = propTypes

export default Checkbox
