import React from 'react'
import { any, string, bool, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import pathShape from 'src/shapes/path'

const propTypes = {
  children: any,
  className: string,
  outline: bool,
  path: pathShape,
  search: string,
  selected: bool,
  style: shape({}),
}

const defaultProps = {
  className: '',
  outline: false,
  path: '',
  search: undefined,
  selected: false,
  style: {},
}

const outlineStyle = (width, color) => ({
  textShadow: `${width}px ${width}px 0 ${color},
  -${width}px ${width}px 0 ${color},
  ${width}px -${width}px 0 ${color},
  -${width}px -${width}px 0 ${color}`,
})

const Tab = ({
  selected,
  search: propsSearch,
  outline,
  path,
  children,
  className,
  style,
  ...rest
}) => {
  const { pathname = path, search = propsSearch } = path.pathname ? path : {}
  return (
    <Link
      className={
        `pt1 pb px2 blue rounded-top inline-block bold ${selected && 'bg-white'} ${className}`
      }
      to={{
        pathname,
        search,
      }}
      style={{
        ...(outline ? outlineStyle(0.8, 'rgba(255,255,255,.8)') : {}),
        ...style,
      }}
      {...rest}
    >
      {children}
    </Link>
  )
}

Tab.propTypes = propTypes
Tab.defaultProps = defaultProps
export default Tab
