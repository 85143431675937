import React, { Fragment } from 'react'
import { t } from 'i18next'
import { shape } from 'prop-types'
import { Link } from 'react-router-dom'

import Div from 'src/components/Div'
import Badge from 'src/components/Badge'
import Icon from 'src/components/Icon'
import Logout from 'src/components/Logout'
import { loginPath } from 'src/lib/routes'
import { CurrentUser, NoCurrentUser } from 'src/pages/CurrentUser'
import userShape from 'src/shapes/user'
import userPath from 'src/lib/routes/user'
import { path as registrationPath } from 'src/routes/registration'
import { scopeForRole } from 'src/constants/routeScopes'
import ParentLogin from 'src/pages/UserInfo/ParentLogin'
import { Wrap } from 'src/components/NavBar'

const UserEditLink = ({ user, children, className }) => (
  <CurrentUser>
    {({ user: { roleId } }) => (
      <Link
        className={className}
        to={userPath({
          domainContext: scopeForRole[roleId],
        })({ tab: 'edit', userId: user.slug })}
      >
        {children}
      </Link>
    )}
  </CurrentUser>
)

const CurrentUserInfo = ({ user }) => {
  const { fullName, teachingYears = [] } = user

  return (
    <Fragment>
      <Div flex relative>
        <Wrap>
          <ParentLogin user={user} className="button-hover p1 white">
            <UserEditLink user={user} className="white">
              <span className="nowrap">
                {user.roleId === 'student' ? (
                  <Icon before icon="child" />
                ) : (
                  <Icon before icon="male" />
                )}
                <span className="wrap bold">{fullName}</span>
              </span>
              {teachingYears.length > 0 && (
                <span>
                  {' '}
                  <span
                    className="nowrap"
                    title={teachingYears.map((year) => year.title).join(', ')}
                  >
                    <Icon icon="graduation-cap" />
                    {teachingYears.length > 3 ? (
                      <span className="mln1">
                        <Badge>{teachingYears.length}</Badge>
                      </span>
                    ) : (
                      teachingYears.map((year) => year.title).join(', ')
                    )}
                  </span>{' '}
                </span>
              )}{' '}
              <span className="small thin muted">{user.email}</span>
            </UserEditLink>
          </ParentLogin>
        </Wrap>
        <Wrap className="flex">
          <Logout />
        </Wrap>
      </Div>
    </Fragment>
  )
}

CurrentUserInfo.propTypes = {
  user: shape(userShape),
}

CurrentUserInfo.defaultProps = {
  user: null,
}

const UserInfo = () => (
  <Div flex justify-end align-center white>
    <NoCurrentUser>
      <Wrap>
        <a
          className="button-hover flex align-center p1 white"
          href={`https://online.learn21.school${registrationPath}`}
        >
          <Icon before icon="passport" />
          <span>{t('registration.registration')}</span>
        </a>
      </Wrap>
      <Wrap>
        <Link
          className="flex align-center button-hover p1 white qa-login-button"
          to={loginPath()}
        >
          <Icon before icon="sign-in-alt" />
          <span className="qa-login-button">{t('login.button')}</span>
        </Link>
      </Wrap>
    </NoCurrentUser>
    <CurrentUser>
      <CurrentUserInfo />
    </CurrentUser>
  </Div>
)

export { UserEditLink, CurrentUserInfo }
export default UserInfo
