import chunk from 'lodash/chunk'
import size from 'lodash/size'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'ListeningWords/INIT'
const INIT_PAIRED = 'ListeningWords/INIT_PAIRED'
const INIT_AS_IS = 'ListeningWords/INIT_AS_IS'
const CLICK = 'ListeningWords/CLICK'
const MARK = 'ListeningWords/MARK'
const RETRY = 'ListeningWords/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  showImage: true,
}

const createCollections = (a, entity) => {
  const slugArray = entity.slug.split('-')
  const slug = slugArray.length > 1 && slugArray[0]
  if (['collection', 'phonics', 'letter'].includes(slug)) {
    a.push([])
    return a
  }
  a[a.length - 1].push(entity)
  return a
}

const createCards = (a, entity) => ({
  ...a,
  [entity.id]: {
    id: entity.id,
    label: size(a) + 1,
    selected: null,
  },
})

const clickCard = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    selected: id === card.id ? true : null,
  },
})

const checkCards = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: card.selected ? id === card.id : null,
  },
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities } = payload
      const collections = entities.reduce(createCollections, [])
      const selectedCollection = uniqShuffle(collections)[0]
      const selectedEntities = shuffle(selectedCollection).slice(0, 2)
      const answer = shuffle(selectedEntities)[0]
      const cards = selectedEntities.reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [answer],
        answerId: answer.id,
        cards,
        entitiesById: keyBy(entities, 'id'),
      }
    }

    case (INIT_PAIRED): {
      const { entities } = payload
      const selectedCollection = uniqShuffle(chunk(entities, 2))[0]
      const selectedEntities = shuffle(selectedCollection).slice(0, 2)
      const answer = shuffle(selectedEntities)[0]
      const cards = selectedEntities.reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [answer],
        answerId: answer.id,
        cards,
        entitiesById: keyBy(entities, 'id'),
      }
    }

    case (INIT_AS_IS): {
      const { entities, size: entitiesToDisplay } = payload
      const selectedEntities = (entitiesToDisplay)
        ? shuffle(entities).slice(0, entitiesToDisplay)
        : entities
      const answer = shuffle(selectedEntities)[0]
      const cards = selectedEntities.reduce(createCards, {})
      return {
        ...initialState,
        actualEntities: [answer],
        answerId: answer.id,
        cards,
        entitiesById: keyBy(entities, 'id'),
      }
    }

    case (CLICK): {
      const { id } = payload
      const cards = reduce(state.cards, clickCard(id), {})
      return {
        ...state,
        cards,
      }
    }

    case (MARK): {
      const cards = reduce(state.cards, checkCards(state.answerId), {})
      const selectedCard = find(cards, { selected: true })
      const completed = selectedCard ? selectedCard.correct : false
      return {
        ...state,
        cards,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const initDefault = ({ entities }) => ({
  payload: { entities },
  type: INIT,
})

const initPaired = ({ entities }) => ({
  payload: { entities },
  type: INIT_PAIRED,
})

const initAsIs = ({ entities, size }) => ({
  type: INIT_AS_IS,
  payload: { entities, size },
})

const onClick = ({ id }) => ({
  payload: { id },
  type: CLICK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  initDefault,
  initPaired,
  initAsIs,
  onClick,
  mark,
  retry,
}

export default reducer
