import React from 'react'
import { useHistory } from 'react-router'

import StudentAttendanceLayoutContainer from 'src/pages/StudentAttendance/StudentAttendanceLayoutContainer'
import userPath from 'src/lib/routes/user'
import userReport from 'src/lib/routes/userReport'
import usePath from 'src/lib/routes/usePath'
import usePageParams from 'src/lib/usePageParams'

const UserShowAttendanceContainer = ({
  userId,
  report,
}) => {
  const history = useHistory()
  const pagePath = report ? userReport : userPath
  const path = usePath(pagePath)
  const params = usePageParams()
  return (
    <StudentAttendanceLayoutContainer
      parentId={params.parentId}
      userId={userId}
      subjectId={params.subjectId}
      onSubjectChange={subjectId => history.push(path({ subjectId, tab: 'attendance' }))}
    />
  )
}

export default UserShowAttendanceContainer
