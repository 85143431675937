import React, { Component } from 'react'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DragDropContext from 'src/lib/dragAndDrop'
import { t } from 'i18next'
import CheckEntities from 'src/components/CheckEntities'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import ErrorMessage from 'src/components/ErrorMessage'
import { GameContext } from 'src/lib/contexts'
import * as homeworkActions from 'src/pages/Homework/reducer'
import instructionShape from 'src/shapes/instruction'
import Module from 'src/components/Module'
import ScalableModule from 'src/components/ScalableModule'
import Navigation from 'src/components/Layout/Navigation'
import * as layoutActions from 'src/components/Layout/reducer'
import userShape from 'src/shapes/user'

const propTypes = {
  chapterId: string.isRequired,
  disableMarkButton: func.isRequired,
  enableMarkButton: func.isRequired,
  goToSlide: func.isRequired,
  guide: bool,
  instructions: arrayOf(shape(instructionShape)).isRequired,
  lesson: shape({
    id: string,
  }).isRequired,
  mark: func,
  onSubscribe: func,
  preview: bool,
  showMarkButton: func.isRequired,
  slideIndex: number,
  started: bool,
  startGame: func,
  totalSlides: number.isRequired,
  user: shape(userShape),
}

const defaultProps = {
  chapterIndex: 0,
  guide: false,
  mark: null,
  preview: false,
  slideIndex: 0,
}

const chapterNotFound = (lessonId, chapterId) => (
  <ErrorMessage
    message={`Chapter: ${chapterId} is not found in Lesson: ${lessonId}`}
  />
)

class Chapter extends Component {
  getChapter() {
    const { lesson, chapterId } = this.props
    const { id: lessonId } = lesson
    const chapter = lesson.chapters.find(item => item.id === chapterId)
    if (!chapter) return chapterNotFound(lessonId, chapterId)
    return chapter
  }

  render() {
    const {
      chapterId,
      disableMarkButton,
      enableMarkButton,
      goToSlide,
      guide,
      instructions,
      lesson,
      mark,
      preview,
      showMarkButton,
      slideIndex,
      started,
      startGame,
      totalSlides,
      onSubscribe,
      user,
    } = this.props
    const { id: lessonId } = lesson
    const chapter = this.getChapter()
    const { component: moduleId, id, meta } = chapter
    const isFirstSlide = slideIndex === 0
    const next = () => {
      goToSlide(slideIndex + 1)
    }
    const isLastSlide = totalSlides === 0 || slideIndex === totalSlides - 1
    const previous = () => {
      goToSlide(slideIndex - 1)
    }

    const nextMessage = () => (
      <Div col={10} style={{ textAlign: 'right' }} className="nowrap overflow-hidden ellipsis">
        {t('navigation.next')}
      </Div>
    )
    const previousMessage = () => (
      <Div col={10} style={{ textAlign: 'left' }} className="nowrap overflow-hidden ellipsis">
        {t('navigation.previous')}
      </Div>
    )

    const isVideoPlaceHolder = ({ moduleId, titleEn }) => (
      moduleId === 'Placeholder' && titleEn.split(' ').includes('video')
    )

    const context = {
      chapterId,
      disableMarkButton,
      enableMarkButton,
      guide,
      instructions,
      moduleId,
      onSubscribe,
      showMarkButton,
      startGame,
      started,
      unitTest: true,
      userId: user ? user.id : null,
    }
    const { titleRaw } = chapter
    const titleEn = { ...titleRaw }.en.toLowerCase()
    const moduleIdUpdated = isVideoPlaceHolder({ moduleId, titleEn }) ? 'Video' : moduleId
    return (
      <GameContext.Provider value={context}>
        <Deck
          className={
            isVideoPlaceHolder({ moduleId, titleEn })
            && 'video-fake fa fa-play-circle fa-3x white'
          }
          id={`chapter-${id}`}
        >
          <ScalableModule>
            <Module
              {...{
                ...this.props,
                ...lesson,
                ...chapter,
                ...meta,
                chapterId,
                lessonId,
                moduleId: moduleIdUpdated,
                started,
              }}
            />
          </ScalableModule>
          {preview && (
          <Navigation
            mark={mark}
            next={isLastSlide ? null : next}
            nextMessage={nextMessage}
            preview
            previous={isFirstSlide ? null : previous}
            previousMessage={previousMessage}
          />
          )}
          {preview && <CheckEntities entities={chapter.entities} />}
        </Deck>
      </GameContext.Provider>
    )
  }
}

Chapter.propTypes = propTypes
Chapter.defaultProps = defaultProps

const actions = dispatch => bindActionCreators({ ...homeworkActions, ...layoutActions }, dispatch)
const select = state => ({ ...state.layout, ...state.homework })

export default DragDropContext(connect(select, actions)(Chapter))
