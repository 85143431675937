import React from 'react'
import { t } from 'i18next'
import { func } from 'prop-types'
import usePageParams from 'src/lib/usePageParams'
import { ATTENDANCE_REPORT } from 'src/constants/menu'
import Widget from 'src/components/Widget'
import ReportLayout from 'src/components/ReportLayout/ReportLayout'
import AttendanceTable from 'src/components/AttendanceReport/AttendanceTable'

const propTypes = {
  onParamsChange: func.isRequired,
}

const AttendanceReport = ({
  onParamsChange,
}) => {
  const title = t(`${ATTENDANCE_REPORT}.title`)
  const params = usePageParams()
  return (
    <ReportLayout onParamsChange={onParamsChange}>
      <div id="attendance-report">
        <Widget token={ATTENDANCE_REPORT} title={title}>
          <AttendanceTable {...params} />
        </Widget>
      </div>
    </ReportLayout>
  )
}

AttendanceReport.propTypes = propTypes

export default AttendanceReport
