import { string, shape, oneOfType } from 'prop-types'

const pathShape = oneOfType([
  string,
  shape({
    pathname: string.isRequired,
    search: string,
  }),
])

export default pathShape
