import React from 'react'
import { arrayOf, oneOfType, string, bool, object } from 'prop-types'
import GenericField from 'src/components/forms/GenericField'

const propTypes = {
  className: string,
  disabled: bool,
  errors: oneOfType([string, arrayOf(string)]),
  label: string,
  labelToken: string,
  name: string,
  placeholder: string,
  readOnly: bool,
  required: bool,
  style: object,
  type: string,
  autoComplete: string,
}

const Input = ({
  autoComplete = 'off',
  className,
  disabled = false,
  errors = [],
  label,
  labelToken,
  name,
  placeholder = '',
  readOnly = false,
  required = false,
  style = {},
  type = 'text',
}) => (
  <GenericField
    className={className}
    errors={errors}
    label={label}
    labelToken={labelToken}
    name={name}
  >
    <input
      type={type}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      style={{ width: '100%', ...style }}
      autoComplete={autoComplete}
      required={required}
      className={required ? 'border-blue' : ''}
    />
  </GenericField>
)

Input.propTypes = propTypes

export default Input
