import React from 'react'
import { Redirect } from 'react-router-dom'

import TestRoute, { path as testPath } from 'src/routes/test'
import WelcomeRoute, { path as welcomePath } from 'src/routes/welcome'
import DashboardRoute, { path as dashboardPath } from 'src/routes/dashboard'
import LoginRoute, { path as loginPath } from 'src/routes/login'
import RegistrationRoute, { path as registrationPath } from 'src/routes/registration'
import ResetPasswordRoute, { path as passwordPath } from 'src/routes/password'
import ResetPasswordFormRoute, {
  path as resetPasswordPath,
} from 'src/routes/reset_password'
import LessonRoute, { path as lessonPath } from 'src/routes/lesson'
import LessonsRoute, { path as lessonsPath } from 'src/routes/lessons'
import HomeworkPageRoute, { path as homeworkPath } from 'src/routes/homework'
import CalendarRoute, { path as calendarPath } from 'src/routes/calendar'

import PreviewRoute, { path as previewPath } from 'src/routes/preview'
import SchoolRoute, { path as schoolPath } from 'src/routes/school'
import TeacherRoute, { path as teacherPath } from 'src/routes/teacher'
import PortalRoute, { path as portalPath } from 'src/routes/portal'
import StudentRoute, { path as studentPath } from 'src/routes/student'
import UnitTestRoute, { path as unitTestPath } from 'src/routes/unit-test'
import NoMatch from 'src/routes/NoMatch'

const routes = [
  {
    exact: true,
    path: '/',
    render: () => <Redirect to="/welcome" />, // eslint-disable-line react/display-name
  },
  {
    component: WelcomeRoute,
    path: welcomePath,
  },
  {
    component: DashboardRoute,
    path: dashboardPath,
  },
  {
    component: LoginRoute,
    path: loginPath,
  },
  {
    component: RegistrationRoute,
    path: registrationPath,
  },
  {
    component: ResetPasswordRoute,
    path: passwordPath,
  },
  {
    component: ResetPasswordFormRoute,
    path: resetPasswordPath,
  },
  {
    component: LessonRoute,
    path: lessonPath,
  },
  {
    component: HomeworkPageRoute,
    path: homeworkPath,
  },
  {
    component: PreviewRoute,
    path: previewPath,
  },
  {
    component: TestRoute,
    path: testPath,
  },
  {
    component: LessonsRoute,
    path: lessonsPath,
  },
  {
    component: UnitTestRoute,
    path: unitTestPath,
  },
  {
    component: SchoolRoute,
    path: schoolPath,
  },
  {
    component: TeacherRoute,
    path: teacherPath,
  },
  {
    path: portalPath,
    render: PortalRoute,
  },
  {
    path: studentPath,
    render: StudentRoute,
  },
  {
    path: calendarPath,
    render: CalendarRoute,
  },
  {
    render: NoMatch,
  },
]

export default routes
