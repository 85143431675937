import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'SelectParagraphTitle/INIT'
const CLICK = 'SelectParagraphTitle/CLICK'
const MARK = 'SelectParagraphTitle/MARK'
const RETRY = 'SelectParagraphTitle/RETRY'

const initialState = {
  actualEntities: [],
  answerId: '',
  cards: null,
  completed: null,
  entitiesById: null,
}

const createCard = (a, entity) => ({
  ...a,
  [entity.id]: {
    correct: null,
    id: entity.id,
    label: size(a) + 1,
  },
})

const clickCard = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: null,
    selected: card.id === id,
  },
})

const checkCards = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: card.selected ? card.id === id : null,
  },
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities: allEntities } = payload
      const entities = uniqShuffle(allEntities).slice(0, 3)
      const answer = shuffle(entities)[0]
      const answerId = answer.id
      const image = { ...answer.image }.src
      const paragraph = { ...answer.meaning }.titleEn
      const cards = entities.reduce(createCard, {})
      return {
        ...initialState,
        actualEntities: [answer],
        answerId,
        cards,
        entitiesById: keyBy(allEntities, 'id'),
        image,
        paragraph,
      }
    }

    case (CLICK): {
      const { id } = payload
      const cards = reduce(state.cards, clickCard(id), {})
      return {
        ...state,
        cards,
      }
    }

    case (MARK): {
      const { answerId } = state
      const cards = reduce(state.cards, checkCards(answerId), {})
      const selectedCard = find(cards, { selected: true })
      const completed = selectedCard ? selectedCard.correct : false
      return {
        ...state,
        cards,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: { entities },
  type: INIT,
})

const onClick = ({ id }) => ({
  payload: { id },
  type: CLICK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  onClick,
  mark,
  retry,
}

export default reducer
