import React from 'react'
import { arrayOf, string, shape, func } from 'prop-types'
import { Link } from 'react-router-dom'

import Access from 'src/components/Access'
import { YearSelectorWithStyle } from 'src/selectors/YearSelector'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import PrintInstructions from 'src/components/PrintInstructions'
import UserCard from 'src/pages/preview/UserCards/UserCard'
import userShape from 'src/pages/preview/UserCards/userShape'
import Widget from 'src/components/Widget'

const propTypes = {
  onYearChange: func,
  year: shape({
    students: arrayOf(shape(userShape)),
    teachers: arrayOf(
      shape({
        ...userShape,
        teachingYears: arrayOf(shape({ title: string.isRequired })).isRequired,
      })
    ),
    title: string.isRequired,
  }).isRequired,
}

const defaultProps = {
  onYearChange: () => {},
}

const UserCards = ({
  onYearChange,
  year,
  year: { title, teachers, students },
}) => (
  <Div relative bg="silver" style={{ '-webkit-print-color-adjust': 'exact' }}>
    <Access staff redirect>
      <Div column noPrint>
        <Div flex>
          <Link
            className="button-hover blue bg-white rounded apx1 amr1 flex align-center center"
            to="/school"
          >
            <Icon icon="home" style={{ fontSize: '36px' }} />
          </Link>
          <Div relative flex-1>
            <YearSelectorWithStyle yearId={year.id} onChange={onYearChange} />
            <Widget title={title} icon="users" />
          </Div>
        </Div>
        <PrintInstructions />
      </Div>
      <Div floatbox mx-auto printBreak style={{ width: `${(85.6 + 4) * 3}mm` }}>
        {students.map((student) => (
          <UserCard
            className="qa-student"
            key={student.id}
            user={{ ...student, year: title }}
          />
        ))}
      </Div>
      <Div floatbox mx-auto printBreak style={{ width: `${(85.6 + 4) * 3}mm` }}>
        {teachers.map((teacher) => (
          <UserCard
            className="qa-teacher"
            key={teacher.id}
            user={{
              ...teacher,
              year: title,
            }}
            teacher
          />
        ))}
      </Div>
    </Access>
  </Div>
)

UserCards.propTypes = propTypes
UserCards.defaultProps = defaultProps

export default UserCards
