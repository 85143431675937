import { compose } from 'src/lib/redux'

import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import SchoolOverviewSectionScene from 'src/pages/SchoolOverview/Section/Scene'
import academicYearsQuery from 'src/pages/SchoolOverview/shared/academicYears.gql'
import gradesHomeworkReportQuery from 'src/pages/SchoolOverview/Section/gradesHomeworkReport.gql'
import sectionQuery from 'src/pages/SchoolOverview/Section/section.gql'

export default compose(
  gqlLoader(academicYearsQuery),
  gqlLoader(sectionQuery),
  gqlLoader(gradesHomeworkReportQuery, networkOnlyConfig),
)(SchoolOverviewSectionScene)
