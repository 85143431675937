import React from 'react'
import { array } from 'prop-types'
import { MatchCards } from 'src/modules/MatchCards'

const propTypes = {
  entities: array.isRequired,
}

const defaultProps = {
  initWith: 'initSynonym',
}

const MatchCardsFlip = ({ entities, initWith }) => (
  <MatchCards
    component="MatchCards"
    entities={entities}
    initWith={initWith}
    type="flip"
    types={['image', 'english audio', 'thai']}
  />
)

MatchCardsFlip.propTypes = propTypes
MatchCardsFlip.defaultProps = defaultProps
MatchCardsFlip.demoData = {
  entities: ['face-father', 'face-mother', 'face-grandfather-father', 'face-grandmother-father', 'dad', 'mum', 'grandpa', 'grandma'],
}

export default MatchCardsFlip
