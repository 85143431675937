import React, { useState, Fragment } from 'react'
import { useMutation } from '@apollo/react-hooks'

import Login from 'src/pages/Login/Login'
// import MobileLogin from 'src/pages/Login/MobileLogin'
import NavBar from 'src/components/NavBar'
import NoCurrentUser from 'src/pages/CurrentUser/NoCurrentUser'
import RedirectToHomePage from 'src/pages/Login/RedirectToHomePage'
import { Redirect } from 'react-router-dom'

import getLoginState from 'src/pages/Login/lib/loginState'
import loginByToken from 'src/lib/loginByToken'
import loginMutation from 'src/pages/Login/Login.gql'
import parseErrors from 'src/lib/form/parseErrors'
import usePageParams from 'src/lib/usePageParams'
import { isMobileOrNative } from 'src/lib/window'
import CurrentUser from 'src/pages/CurrentUser'

const LoginContainer = () => {
  const {
    email,
    relogin,
    error,
    parent,
    displayLogin,
    login,
    referer,
  } = usePageParams()
  const loginState = getLoginState({ parent, relogin })
  const [loggedIn, setLoggedIn] = useState(false)

  const [mutationLogin] = useMutation(loginMutation)

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    mutationLogin({ variables: values })
      .then(
        ({
          data: {
            createUserToken: { token },
          },
        }) => {
          loginByToken(token)
          setTimeout(() => setLoggedIn(true), 0)
        }
      )
      .catch((errors) => setErrors(parseErrors(errors)))
      .finally(() => setSubmitting(false))
  }

  const formProps = {
    authEmail: email,
    displayLogin,
    errorMessage: error,
    login,
    loginState,
    onSubmit,
    parent: !!parent,
  }

  const renderForm = () =>
    // (isMobileOrNative() ? (
    //   <MobileLogin {...formProps} />
    // ) : (
      <Login {...formProps} />
    // ))
  if (loggedIn) {
    if (referer) {
      return <CurrentUser><Redirect to={referer} /></CurrentUser>
    }
    return <CurrentUser><RedirectToHomePage /></CurrentUser>
  }
  return (
    <div>
      <NavBar />
      {relogin ? (
        renderForm()
      ) : (
        <Fragment>
          <NoCurrentUser>{renderForm()}</NoCurrentUser>
          <RedirectToHomePage />
        </Fragment>
      )}
    </div>
  )
}

export default LoginContainer
