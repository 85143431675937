import SelectionSupport from 'src/components/Selection/SelectionSupport'
import SelectionState from 'src/components/Selection/SelectionState'
import SelectionActions from 'src/components/Selection/SelectionActions'
import SelectionCheckbox from 'src/components/Selection/SelectionCheckbox'
import withSelection from 'src/components/Selection/withSelection'
import CancelSelection from 'src/components/Selection/CancelSelection'
import ToggleSelection from 'src/components/Selection/ToggleSelection'
import Button from 'src/components/Selection/Button'

const SelectionCheckboxContainer = withSelection(SelectionCheckbox)
const SelectionActionsContainer = withSelection(SelectionActions)
const CancelSelectionContainer = withSelection(CancelSelection)
const ToggleSelectionContainer = withSelection(ToggleSelection)
const SelectionStateContainer = withSelection(SelectionState)

export {
  withSelection,
  SelectionSupport,
  SelectionStateContainer as SelectionState,
  SelectionActionsContainer as SelectionActions,
  SelectionCheckboxContainer as SelectionCheckbox,
  CancelSelectionContainer as CancelSelection,
  ToggleSelectionContainer as ToggleSelection,
  Button,
}
