import buildPath from 'src/lib/routes/buildPath'
import { ATTAINMENT_TAB, CURRICULUM_TAB, STUDENT_TAB } from 'src/constants/progressTabs'
import pathWithPageParams from 'src/lib/routes/pathWithPageParams'
import reportPath from 'src/lib/routes/reportPath'

const homeworkReportPath = pathWithPageParams((params) => {
  const { yearId, subjectId } = params
  return buildPath(
    [
      reportPath(params),
      'homework',
      yearId,
    ],
    { subjectId: subjectId === 'all' ? null : subjectId },
    { router: true, absolute: true },
  )
})

const schoolOverviewReportPath = pathWithPageParams((params) => {
  const { gradeId, sectionId } = params
  return (
    `/${buildPath([
      reportPath(params),
      'overview',
      ...((sectionId && !gradeId) ? ['section', sectionId] : []),
      ...(gradeId ? ['grade', gradeId] : []),
    ])}`
  )
})

const attendanceReportPath = pathWithPageParams((params) => {
  const { yearId } = params
  return `/${buildPath([reportPath(params), 'attendance', yearId])}`
})

const progressReportPath = pathWithPageParams((params) => {
  const {
    tab = CURRICULUM_TAB,
    subjectId,
    yearId,
    unitSlug,
    limit,
    strandSlug,
    standardSlug,
    userId,
  } = params

  const tabPath = (() => {
    switch (tab) {
      case ATTAINMENT_TAB:
        return [
          ATTAINMENT_TAB,
          yearId && `year/${yearId}`,
          subjectId && `subject/${subjectId}`,
          unitSlug && `unit/${unitSlug}`,
          limit && `limit/${limit}`,
        ]
      case STUDENT_TAB:
        return [
          STUDENT_TAB,
          yearId && `year/${yearId}`,
          'user',
          userId && `${userId}`,
          'progress',
          subjectId && `${subjectId}`,
          strandSlug && `strand/${strandSlug}`,
          standardSlug && `standard/${standardSlug}`,
        ]
      default:
        return [
          CURRICULUM_TAB,
          yearId && `year/${yearId}`,
          subjectId && `subject/${subjectId}`,
          strandSlug && `strand/${strandSlug}`,
          standardSlug && `standard/${standardSlug}`,
        ]
    }
  })()

  return `/${buildPath([
    reportPath(params),
    'progress',
    ...tabPath,
  ])}`
})


export {
  attendanceReportPath,
  homeworkReportPath,
  progressReportPath,
  schoolOverviewReportPath,
}
