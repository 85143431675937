import React, { useState } from 'react'
import { arrayOf, string, shape, func, any } from 'prop-types'
import { t } from 'i18next'

import Div from 'src/components/Div'
import Access from 'src/components/Access'
import { isBetween, formatDayLong, formatDateLong } from 'src/lib/dates'
import Icon from 'src/components/Icon'
import YearSummary from 'src/pages/CalendarPage/YearSummary'
import EventForm from 'src/pages/CalendarPage/Events/Form'

const eventsOnDate = (date, events) => (
  events.filter(e => isBetween(date, e.startDate, e.endDate))
)

const academicTermOnDate = (date, terms) => {
  const matches = terms ? terms.filter(e => isBetween(date, e.startedOn, e.finishedOn)) : null
  return matches ? matches[0] : null
}

const DateSummary = ({
  calendarEvents,
  date,
  academicTerms,
  createEvent,
  updateEvent,
  deleteEvent,
}) => {
  const [editing, setEditing] = useState(false)
  const [creating, setCreating] = useState(false)
  const friendlyDate = `${formatDayLong(date)}, ${formatDateLong(date)}`
  const academicTerm = academicTermOnDate(date, academicTerms)
  const events = eventsOnDate(date, calendarEvents).filter(({ tenant }) => tenant)
  const handleDelete = (id) => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(t('shared.areYouSure'))) {
      deleteEvent(id)
    }
  }

  const CreateEditForm = () => (
    editing ? (
      <EventForm
        {...editing}
        onCancel={() => setEditing(false)}
        onSubmit={(data, apolloClient) => {
          updateEvent(data, apolloClient)
          setEditing(false)
        }}
      />
    ) : (
      <EventForm
        onCancel={() => setCreating(false)}
        onSubmit={(data, apolloClient) => {
          createEvent(data, apolloClient)
          setCreating(false)
        }}
        startDate={date}
        endDate={date}
        isNew
      />
    ))

  return (
    <Div my2 className="qa-year-term">
      <Div centered>
        <h3>{friendlyDate}</h3>
      </Div>
      {
        academicTerm ?
          <Div><b>{t('school.settings.academicTerm')}:</b> {academicTerm.title}</Div>
          : <Div><b>{t('school.settings.holidays')}</b></Div>
      }
      <hr />
      <Div>
        { events.length ? (
          <React.Fragment>
            <h5>Events:</h5>
            <ul>
              {events.map(event => (
                <li key={event.id}>
                  {`${event.titleEn} / ${event.titleTh}`}
                  { editing ?
                    null
                    : (
                      <Access staff>
                      &nbsp;
                        <button
                          className="btn btn-primary btn-small bg-olive qa-edit-event-button p"
                          onClick={() => setEditing(event)}
                        >
                          <Icon icon="edit" />
                        </button>
                      &nbsp;
                        <button
                          className="btn btn-primary btn-small bg-red qa-delete-event-button p"
                          onClick={() => handleDelete(event.id)}
                        >
                          <Icon icon="trash" />
                        </button>
                      </Access>
                    )}
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : null}

      </Div>
      <Access staff>
        { editing || creating ?
          <CreateEditForm />
          : (
            <button
              className="col-12 btn btn-primary bg-olive ml12 qa-create-event-button"
              type="submit"
              onClick={() => setCreating(true)}
            >
              {t('school.calendar.createEvent')}
            </button>
          )}
      </Access>
      <Access staff>
        <Div mt3>
          <YearSummary />
        </Div>
      </Access>
    </Div>
  )
}

DateSummary.propTypes = {
  academicTerms: arrayOf(shape({
    finishedOn: any.isRequired,
    id: string.isRequired,
    startedOn: any.isRequired,
    title: string,
  })).isRequired,
  calendarEvents: arrayOf(shape({
    endDate: any.isRequired,
    id: string.isRequired,
    startDate: any.isRequired,
    titleEn: string,
    titleTh: string,
  })).isRequired,
  createEvent: func.isRequired,
  date: string.isRequired,
  deleteEvent: func.isRequired,
  updateEvent: func.isRequired,
}

export default DateSummary
