import React, { Component } from 'react'
import { t } from 'i18next'
import { Provider } from 'react-redux'
import { string } from 'prop-types'
import ErrorMessage from 'src/components/ErrorMessage'
import errorReporter from 'src/lib/errorReporter'
import { GameContext } from 'src/lib/contexts'
import configureIsolatedStore from 'src/pages/preview/isolatedStore'

const propTypes = {
  chapterId: string.isRequired,
}

class Isolated extends Component {
  constructor(props) {
    super(props)
    this.store = configureIsolatedStore()
  }

  render() {
    const { chapterId, children } = this.props
    const context = {
      chapterId,
      isPreview: true,
    }
    return (
      <GameContext.Provider value={context}>
        <Provider store={this.store}>
          {children}
        </Provider>
      </GameContext.Provider>
    )
  }
}

Isolated.propTypes = propTypes

export default errorReporter(
  Isolated,
  <ErrorMessage message={t('error.inChapter')} report />,
)
