import React from 'react'
import uuid from 'uuid'
import { bool, number, shape, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Card from 'src/components/Card'
import TextFit from 'src/components/TextFit'
import CardType from 'src/components/AdaptiveCard/CardType'

/**
 * Render Card
 * - KeyPress to play the sound
 * - with cardType by type
 * - ID for draggable cards
 */

const selectText = ({ title, thai, english, entity }) => {
  if (title) return title
  if (entity) {
    if (thai) return entity.titleTh
    if (english) return entity.titleEn
    return undefined
  }
  return undefined
}

const autoUpdateFocusOnWidth = ({ title, thai, english, entity }) => {
  const text = selectText({ english, entity, thai, title })
  if (typeof text === 'string') return text.split(' ').length < 3
  return false
}

const renderCard = ({
  align,
  alignItems,
  auto,
  entity,
  focusOnWidth,
  justify,
  markdown,
  min,
  reduceFontSize,
  textAlign,
  wrapperJustify,
  ...rest
}) => {
  const { image, thai, english, title, max } = rest
  const isAutoUpdateFocusOnWidth = autoUpdateFocusOnWidth({ english, entity, thai, title })
  return (
    <Card
      id={entity ? entity.id : uuid()}
      adaptiveSize
      auto={auto}
      {...rest}
    >
      <TextFit
        align={align}
        image={image}
        auto={auto}
        focusOnWidth={focusOnWidth || isAutoUpdateFocusOnWidth}
        alignItems={alignItems}
        justify={justify}
        min={min}
        reduceFontSize={reduceFontSize}
        textAlign={textAlign}
        wrapperJustify={wrapperJustify}
        max={max}
      >
        <CardType {...{ auto, entity, markdown, ...rest }} />
      </TextFit>
    </Card>
  )
}

renderCard.defaultProps = {
  alignItems: undefined,
  highlight: null,
  markdown: false,
  max: undefined,
  min: undefined,
  onKeyPress: null,
  reduceFontSize: undefined,
  textAlign: undefined,
  wrapperJustify: undefined,
}

renderCard.propTypes = {
  align: string,
  alignItems: string,
  audioNew: bool.isRequired,
  auto: bool,
  english: bool.isRequired,
  entity: shape(entityShape).isRequired,
  focusOnWidth: bool,
  height: number,
  highlight: shape(),
  image: bool.isRequired,
  imageSrc: string.isRequired,
  justify: string,
  markdown: bool,
  max: number,
  meaning: bool,
  min: number,
  onKeyPress: shape(),
  play: bool.isRequired,
  reduceFontSize: number,
  textAlign: string,
  thai: bool.isRequired,
  width: number,
  wrapperJustify: string,
}

export default renderCard
