import React from 'react'
import {
  any,
  bool,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types'
import Badge from 'src/components/Badge'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'

const propTypes = {
  badge: any,
  color: string,
  icon: string,
  noPrint: bool,
  title: oneOfType([object, string]),
  titleRight: node,
}

const defaultProps = {
  badge: null,
  color: 'blue',
  icon: 'circle',
  noPrint: true,
  title: '',
  titleRight: null,
}

const WidgetHeader = ({
  badge,
  color,
  icon,
  noPrint,
  title,
  titleRight,
}) => {
  if (!title && !icon) return null
  return (
    <Div flex align="center" noPrint={noPrint}>
      <div className="h4 bold flex-auto qa-widget-header-title">
        <Icon icon={icon} color={color} className="muted" before widget />
        {title}
      </div>
      {badge && <Badge pill>{badge}</Badge>}
      {titleRight && titleRight}
    </Div>
  )
}

WidgetHeader.propTypes = propTypes
WidgetHeader.defaultProps = defaultProps

export default WidgetHeader
