import React from 'react'
import { string, shape, arrayOf, bool } from 'prop-types'
import compact from 'lodash/compact'
import yearShape from 'src/shapes/year'
import loadYearEvents from 'src/pages/CalendarPage/loadYearEvents'
import YearCalendar from 'src/components/YearCalendar'
import calendarPath from 'src/pages/CalendarPage/paths'
import usePath from 'src/lib/routes/usePath'
import { calendarEventShape } from 'src/shapes/calendar'

const propTypes = {
  calendarEvents: arrayOf(shape({
    endDate: string,
    holiday: bool,
    startDate: string,
    tenant: bool,
  })).isRequired,
  date: string,
  events: arrayOf(shape(calendarEventShape)),
  year: shape(yearShape),
  yearId: string,
}

const defaultProps = {
  date: undefined,
  events: [],
  year: { academicTerms: [] },
  yearId: undefined,
}

export const YearCalendarContainer = ({
  calendarEvents,
  date,
  events: propsEvents,
  year: { id: yearId, slug: yearSlug, academicTerms },
  ...rest
}) => {
  const datePath = usePath(calendarPath)
  const termMods = academicTerms.map(({ startedOn, finishedOn }) => ({
    academicTerm: true,
    className: 'event',
    endDate: finishedOn,
    startDate: startedOn,
  }))
  const eventMods = calendarEvents.map((event) => {
    const { startDate, endDate, tenant, holiday } = event
    const classNames = [
      tenant ? 'TENANT_EVENT' : 'PUBLIC_EVENT',
      holiday && 'DAY_OFF',
    ].filter(v => !!v)
    return ({ classNames, endDate, event, holiday, startDate })
  })
  const events = compact([
    ...propsEvents,
    ...termMods,
    ...eventMods,
    date && { className: 'selected', endDate: date, startDate: date },
  ])
  return (
    <YearCalendar {...{
      datePath: date => datePath({ date, yearId: yearSlug || yearId }),
      events,
      ...rest,
    }}
    />
  )
}

YearCalendarContainer.propTypes = propTypes
YearCalendarContainer.defaultProps = defaultProps

export default loadYearEvents(YearCalendarContainer)
