import React from 'react'
import { array, number, object } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import flatMap from 'lodash/flatMap'
import EntityImage from 'src/components/EntityImage'
import EntityCard from 'src/components/EntityCard'
import Children from 'src/modules/FamilyTreeSlideshow/Children'
import FamilyPlace from 'src/modules/FamilyTreeSlideshow/FamilyPlace'
import Grandparents from 'src/modules/FamilyTreeSlideshow/Grandparents'

const propTypes = {
  cards: object.isRequired,
  currentSlide: number.isRequired,
  entitiesById: object.isRequired,
  member: object,
  placeLocations: array.isRequired,
  places: object.isRequired,
}

const SEPARATORS = [
  Grandparents,
  Children,
]
const slideToGoTo = (i, index) => (i ? (2 * i) + 2 + index : index)

const FamilyTree = ({
  cards,
  entitiesById,
  member,
  placeLocations,
  places,
  currentSlide,
}) => (
  <Deck>
    <Div flex>
      <Div auto px={1} justify="center">
        {
          flatMap(placeLocations, (row, i) => {
            const Separator = SEPARATORS[i]
            return [
              <Div flex key={i} justify="center">
                {row.map((id, index) => {
                  const slideToGo = slideToGoTo(i, index)
                  return (
                    <FamilyPlace
                      key={id}
                      {...{
                        cards,
                        entitiesById,
                        place: places[id],
                        places,
                      }}
                      slideToGoTo={slideToGo + 1}
                      displayImage={slideToGo < currentSlide}
                      memberId={member && member.id}
                    />
                  )
                })}
              </Div>,
              Separator ? (
                <Div
                  key={`separator-${i}`}
                  mtn={1}
                  mbn={1}
                  style={{ height: '3rem' }}
                >
                  <Separator />
                </Div>
              ) : null,
            ]
          })
        }
      </Div>
      {member && (
      <Div flex column px={1} col={4} justify="center">
        <Div auto flex column>
          {member && (
          <EntityImage
            fit
            entity={member}
            style={{ minHeight: '80%' }}
          />
          )}
        </Div>
        <Div flex>
          {member && (
          <EntityCard
            audio
            autoPlayOnce
            english
            entity={member}
            key={member.id}
          />
          )}
        </Div>
      </Div>
      )}
    </Div>
  </Deck>
)

FamilyTree.propTypes = propTypes

export default FamilyTree
