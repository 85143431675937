import React, { useContext } from 'react'
import { shape, func } from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { t } from 'i18next'
import classnames from 'classnames'

import { linkTo } from 'src/lib/linkTo'
import { path as yearEditPath } from 'src/pages/Year/Edit/config'
import Div from 'src/components/Div'
import yearShape from 'src/shapes/year'
import Access from 'src/components/Access'
import Icon from 'src/components/Icon'
import DomainContext from 'src/contexts/DomainContext'
import ReactRouterPropTypes from 'react-router-prop-types'
import parseErrors from 'src/lib/form/parseErrors'
import { warningMessage } from 'src/lib/toaster'

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  year: shape(yearShape).isRequired,
  yearDelete: func.isRequired,
}

const YearShow = ({ year, yearDelete, history }) => {
  const domain = useContext(DomainContext)

  const handleDelete = (yearId) => {
    yearDelete({ variables: { id: yearId } })
      .then(() => {
        history.push('/school/years')
      }).catch((e) => {
        const parsedError = parseErrors(e)
        warningMessage({ message: parsedError.base[0], title: t('year.year') })
      })
  }

  return (
    <Div>
      <React.Fragment>
        <Access staff>
          <Div h4>
            <Icon before size={3} color="blue" icon="graduation-cap" />
            <span className="bold mr1">Title:</span>
            <span className={classnames({ bold: true })}>
              {year.title}
            </span>
          </Div>

          <Link
            to={linkTo(yearEditPath)({ domain, yearId: year.id })}
            className="black inline-block"
          >
            <Icon icon="edit" />
            {t('shared.edit')}
          </Link>

          <button
            className="qa-year-delete btn btn-primary ml2 bg-red inline-block"
            onClick={() => window.confirm('Are you sure you wish to delete this item?') &&
            handleDelete(year.id)}
          >
            <Icon icon="times" />
            {t('shared.delete')}
          </button>
        </Access>
      </React.Fragment>
    </Div>
  )
}

YearShow.propTypes = propTypes

export default withRouter(YearShow)
