import flatten from 'lodash/flatten'

const sectionTag = ({ slug }) => slug.charAt(0)

const groupAndSort = (sections, years) => {
  const groupBy = (items, groupOf) => items.map(e => [e, groupOf(e)])
    .reduce((acc, [section, group]) => ({
      ...acc,
      [group]: section,
    }), {})

  const sectionByTag = groupBy(sections, sectionTag)
  const sectionById = groupBy(sections, ({ id }) => id)

  // first letter of year and section slugs must be the same, i.g. Primary section and P.1/1 year
  const sectionOf = year => sectionByTag[sectionTag(year)]

  const yearsBySection = years.map(year => ([year, sectionOf(year).id]))
    .reduce(
      (acc, [year, sectionId]) => ({
        ...acc,
        [sectionId]: acc[sectionId] ? [...acc[sectionId], year] : [year],
      }),
      {},
    )

  const yearsSorted = flatten(Object.keys(yearsBySection)
    .map(id => [{ ...sectionById[id], disabled: true }, ...yearsBySection[id]]))

  return [yearsBySection, yearsSorted]
}

export default groupAndSort
