import React from 'react'

import map from 'lodash/map'
import { isMobileOrNative } from 'src/lib/window'
import Human from 'src/components/Human'
import EntityCard from 'src/components/EntityCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import BodyBackground from 'src/components/Layout/BodyBackgroundContainer'
import { array, bool, object, number, string } from 'prop-types'
import Card from 'src/components/Card'
import Img from 'src/components/Img'
import TextFit from 'src/components/TextFit'
import background from 'src/modules/Hangman/images/forest.svg'
import treeBottom from 'src/modules/Hangman/images/tree_bottom.svg'
import treeLeaf1 from 'src/modules/Hangman/images/tree_leaf1.svg'
import treeLeaf2 from 'src/modules/Hangman/images/tree_leaf2.svg'
import treeLeaf3 from 'src/modules/Hangman/images/tree_leaf3.svg'
import treeMiddle1 from 'src/modules/Hangman/images/tree_middle1.svg'
import treeMiddle2 from 'src/modules/Hangman/images/tree_middle2.svg'
import treeStump from 'src/modules/Hangman/images/tree_stump.svg'
import woodcutter from 'src/modules/Hangman/images/woodcutter.svg'
import woodcutterChop from 'src/modules/Hangman/images/woodcutter_chop.svg'

const propTypes = {
  chop: bool.isRequired,
  entity: object.isRequired,
  mood: string.isRequired,
  places: object.isRequired,
  responseText: string.isRequired,
  started: bool.isRequired,
  tries: number.isRequired,
  triesIterations: array.isRequired,
  usedAplhabet: array.isRequired,
}

const defaultProps = {
  entity: {},
}

const renderTreeImage = (tries, index) => {
  switch (index) {
    case 0: {
      if (tries >= 3) return null
      return (
        <Div key={index} flex wrap width={18} height={6}>
          <Div width={6} height={6}>
            <Img src={treeLeaf1} />
          </Div>
          <Div width={6} height={6}>
            <Img src={treeLeaf2} />
          </Div>
          <Div width={6} height={6}>
            <Img src={treeLeaf3} />
          </Div>
        </Div>
      )
    }
    case 1: {
      if (tries >= 1) return null
      return (
        <Div key={index} width={18} height={6}>
          <Img src={treeMiddle1} />
        </Div>
      )
    }
    case 2: {
      if (tries >= 2) return null
      return (
        <Div key={index} width={18} height={6}>
          <Img src={treeMiddle2} />
        </Div>
      )
    }
    default:
      return (
        <Div key={index} width={18} height={6}>
          <Img src={treeMiddle2} />
        </Div>
      )
  }
}

const renderPlace = (place) => {
  if (place.question === ' ') {
    return (
      <Card
        auto
        fit
        id={place.id}
        minWidth={1.5}
        title=""
        transparent
      />
    )
  }
  if (place.correct) {
    return (
      <Card
        auto
        bold
        fit
        id={place.id}
        minWidth={5}
        size={1}
        title={place.question}
        correct
      />
    )
  }
  return (
    <Card
      id={place.id}
      auto
      fit
      minWidth={5}
      title="_"
      size={1}
    />
  )
}
const Hangman = ({
  chop,
  entity,
  isSoftKeyboardShow,
  mood,
  places,
  responseText,
  showSoftKeyboard,
  started,
  tries,
  triesIterations,
  usedAplhabet,
}) => {
  const currentWoodcutter = chop ? woodcutterChop : woodcutter
  const currentTreeBottom = tries === 3 ? treeStump : treeBottom
  return (
    <Deck>
      <BodyBackground url={background} />
      <Div relative key={entity.id} flex auto style={{ minHeight: '30rem' }}>
        <Div col={4} flex px={1}>
          {
            entity.id && (
              <EntityCard image audio english entity={entity} autoPlay={started} transparent />
            )
          }
        </Div>
        <Div relative col={4} px={1} flex column align-center>
          <Div absolute style={{ bottom: '20%' }}>
            {
              triesIterations.map((_, index) => renderTreeImage(tries, index))
            }
            <Div width={18} height={6}>
              <Img src={currentTreeBottom} />
            </Div>
          </Div>
          <Div flex wrap height={4} absolute style={{ bottom: '3%' }}>
            {
              usedAplhabet.map(alphabet => (
                <Card
                  key={alphabet}
                  title={alphabet}
                  size={3}
                  auto
                  fit
                  correct={false}
                />
              ))
            }
          </Div>
        </Div>
        <Div relative col={4} px={1} justify="end" flex column align-center>
          <Div absolute style={{ bottom: '50%' }}>
            <Human
              size={5}
              mood={mood}
              color="#FDC99C"
              text={responseText}
              showTextOnHover={false}
            />
          </Div>
          <Div absolute style={{ bottom: '12%', height: '20rem', width: '20rem' }}>
            <Img key="woodcutter" src={currentWoodcutter} style={{ position: 'absolute' }} />
          </Div>
        </Div>
      </Div>
      <TextFit style={{ height: '15rem' }} wrapperClass="relative">
        <Div
          z={1}
          flex
          justify="center"
          onClick={isMobileOrNative() && showSoftKeyboard}
          relative
          hangmanSoftKeyBoard={isSoftKeyboardShow}
        >
          <Div
            flex
            justify="space-around"
            wrap
            p={1}
            relative
          >
            {
              map(places, place => (
                <Div key={place.id}>
                  { renderPlace(place) }
                </Div>
              ))
            }
          </Div>
        </Div>
      </TextFit>
    </Deck>
  )
}

Hangman.propTypes = propTypes
Hangman.defaultProps = defaultProps

export default Hangman
