import React from 'react'
import SubjectSelector from 'src/selectors/SubjectSelector'
import YearSelector from 'src/selectors/YearSelector'
import Div from 'src/components/Div'

const SubjectAndYearSelector = ({ children, onChange, ...props }) => (
  <div>
    <Div flex mxn mb1>
      <Div px>
        <SubjectSelector
          onChange={subjectId => onChange({ subjectId })}
          {...props}
        />
      </Div>
      <Div px>
        <YearSelector
          onChange={yearId => onChange({ yearId })}
          {...props}
        />
      </Div>
    </Div>
    {React.cloneElement(children, props)}
  </div>
)

export default SubjectAndYearSelector
