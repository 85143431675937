import React from 'react'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import Icon from 'src/components/Icon'
import { homeworkPath } from 'src/lib/routes'

const propTypes = {
  lessonSlug: string.isRequired,
}

const HomeworkButton = ({ lessonSlug }) => (
  <Link
    className="button-hover blue flex align-center"
    to={homeworkPath(lessonSlug)}
    style={{
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '0 8px',
      whiteSpace: 'nowrap',
    }}
  >
    <Icon
      icon="book"
      style={{
        fontSize: '22px',
      }}
    />
    {t('home.homework')}
  </Link>
)

HomeworkButton.propTypes = propTypes
export default HomeworkButton
