import React from 'react'
import { bool, string, number, shape } from 'prop-types'
import classnames from 'classnames'

const propTypes = {
  after: bool,
  before: bool,
  className: string,
  color: string,
  icon: string.isRequired,
  inline: bool,
  muted: bool,
  size: number,
  spin: bool,
  style: shape(),
  widget: bool,
}

const defaultProps = {
  after: false,
  before: false,
  className: '',
  color: undefined,
  inline: false,
  muted: false,
  size: null,
  spin: false,
  style: {},
  widget: false,
}

const makeClassName = ({
  after,
  before,
  className,
  color,
  icon,
  muted,
  size,
  spin,
}) => classnames({
  'fa-spin': spin,
  [`fa-${icon}`]: true,
  [`h${size}`]: size,
  [className]: true,
  [color]: true,
  center: true,
  fas: true,
  muted,
  pl: after,
  pr: before,
})

const makeStyle = ({ inline }) => ({
  width: !inline && '1.75em',
})

const Icon = ({
  after,
  before,
  className,
  color,
  icon,
  inline,
  muted,
  spin,
  size,
  style,
  widget,
}) => (
  <i
    className={makeClassName({
      after,
      before,
      className,
      color,
      icon,
      muted,
      size,
      spin,
      widget,
    })}
    style={{ ...makeStyle({ after, before, inline }), ...style }}
  />
)

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps
export default Icon
