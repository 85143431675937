import React from 'react'
import { string } from 'prop-types'
import { useParams } from 'react-router'
import { changeLanguage } from 'i18next'
import { Redirect } from 'react-router-dom'

const propTypes = {
  to: string.isRequired,
}

const RedirectWithUpdateLocale = ({
  to,
}) => {
  const { locale } = useParams()
  changeLanguage(locale)
  return <Redirect to={to} />
}

RedirectWithUpdateLocale.propTypes = propTypes
export default RedirectWithUpdateLocale
