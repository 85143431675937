/* eslint max-len: 0 */
import React from 'react'

export default () => (
  <svg width="100%" height="100%" viewBox="0 0 800 60" preserveAspectRatio="none">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g id="grandparents" stroke="#979797" strokeWidth="10">
        <path vectorEffect="non-scaling-stroke" d="M100,30 L100,55" id="Line" transform="translate(100.000000, 42.500000) scale(1, -1) translate(-100.000000, -42.500000) " />
        <path vectorEffect="non-scaling-stroke" d="M300,30 L300,55" id="Line" transform="translate(300.000000, 42.500000) scale(1, -1) translate(-300.000000, -42.500000) " />
        <path vectorEffect="non-scaling-stroke" d="M100,30 L700,30" id="Line" transform="translate(400.000000, 30.000000) scale(1, -1) translate(-400.000000, -30.000000) " />
        <path vectorEffect="non-scaling-stroke" d="M300,5 L300,25" id="Line" transform="translate(300.000000, 15.000000) scale(1, -1) translate(-300.000000, -15.000000) " />
        <path vectorEffect="non-scaling-stroke" d="M500,30 L500,55" id="Line" transform="translate(500.000000, 42.500000) scale(1, -1) translate(-500.000000, -42.500000) " />
        <path vectorEffect="non-scaling-stroke" d="M700,30 L700,55" id="Line" transform="translate(700.000000, 42.500000) scale(1, -1) translate(-700.000000, -42.500000) " />
        <path vectorEffect="non-scaling-stroke" d="M500,5 L500,25" id="Line" transform="translate(500.000000, 15.000000) scale(1, -1) translate(-500.000000, -15.000000) " />
      </g>
    </g>
  </svg>
)
