import icons from 'src/lib/menu/icons'
import { LEADERBOARD } from 'src/constants/menu'

export const path = '/dashboard/leaderboard'

export const scene = {
  common: true,
  icon: icons[LEADERBOARD],
  id: LEADERBOARD,
  path,
}
