import React from 'react'
import classnames from 'classnames'
import { arrayOf, bool, number, string } from 'prop-types'
import Div from 'src/components/Div'
import Tooltip from 'src/components/Tooltip'
import Icon from 'src/components/Icon'
import { gradeToColor } from 'src/lib/rateToColor'
import percent from 'src/lib/calculatePercentage'

const propTypes = {
  grade: number.isRequired,
  percentage: number.isRequired,
  showPercent: bool,
  tips: arrayOf(string),
}

const defaultProps = {
  showPercent: true,
}

const Grade = ({ tips, grade, percentage, showPercent, ...rest }) => (
  <Div {...rest}>
    <Tooltip tips={tips || []} disabled={!tips}>
      <Icon icon="circle" className={classnames(gradeToColor(grade), 'qa-circle')} />
      <span className="nowrap qa-grade-value bold">
        {grade}{showPercent && ` / ${percent(percentage)}%`}
      </span>
    </Tooltip>
  </Div>
)

Grade.propTypes = propTypes
Grade.defaultProps = defaultProps

export default Grade
