import React from 'react'
import { number, string } from 'prop-types'

const propTypes = {
  paddingLeft: number,
  title: string.isRequired,
}

const defaultProps = {
  paddingLeft: undefined,
}

const TableTitleRow = ({ title, paddingLeft }) => (
  <tr>
    <td className="bold">
      <div className={paddingLeft ? `pl${paddingLeft}` : null}>
        {title}
      </div>
    </td>
    <td colSpan="100%" />
  </tr>
)

TableTitleRow.propTypes = propTypes
TableTitleRow.defaultProps = defaultProps

export default TableTitleRow
