const attendanceStatus = [
  { emoji: '---', id: '' },
  { emoji: '⭕️', id: 'absent' },
  { emoji: '😷', id: 'sick' },
  { emoji: '📝', id: 'permitted' },
  { emoji: '🕒', id: 'late' },
  { emoji: '☑️', id: 'present' },
]

export default attendanceStatus
