import React from 'react'
import ReactMarkdown from 'react-markdown'
import { bool, string, number } from 'prop-types'
import KeyLabel from 'src/components/KeyLabel'
import Div from 'src/components/Div'

const propTypes = {
  absolute: bool,
  label: string,
  labelPx: number,
  markdown: bool,
  title: string,
}

const defaultProps = {
  absolute: false,
  label: undefined,
  labelPx: undefined,
  markdown: false,
  title: null,
}

const LabelWrapper = ({ label, labelPx, markdown, title, absolute }) => {
  const renderTitle = () => (
    markdown
      ? <ReactMarkdown source={title} className="LabelWrapperMD" />
      : title
  )
  if (label) {
    return (
      <Div LabelWrapper flex relative={!absolute}>
        <KeyLabel
          // eslint-disable-next-line sort-keys
          style={{ height: 0, position: 'absolute', top: '50%' }}
          label={label}
          px={labelPx}
        />
        <Div card-title pr2>
          {renderTitle()}
        </Div>
      </Div>
    )
  }
  return renderTitle()
}
LabelWrapper.propTypes = propTypes
LabelWrapper.defaultProps = defaultProps

export default LabelWrapper
