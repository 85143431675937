import React from 'react'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import { bool, func, objectOf, string } from 'prop-types'
import keyCodes from 'src/components/KeyPress/KeyCode'

const propTypes = {
  answer: bool,
  clearSoftKeyboardKey: func,
  enableKeyUp: bool,
  inputs: string,
  isSoftKeyboardShow: bool,
  keyInput: string,
  keys: objectOf(func).isRequired,
}

const defaultProps = {
  clearSoftKeyboardKey: undefined,
  enableKeyUp: false,
  isSoftKeyboardShow: false,
  keyInput: null,
  answer: false,
  inputs: '',
}

export default class KeyPress extends React.Component {
  constructor(props) {
    super(props)
    this.boundHandleKeyUp = this.handleKeyUp.bind(this)
    this.boundHandleKeyDown = this.handleKeyDown.bind(this)
    this.pressed = {}
  }

  componentDidMount() {
    document.addEventListener('keydown', this.boundHandleKeyDown)
    document.addEventListener('keyup', this.boundHandleKeyUp)
  }

  componentDidUpdate(prevProps) {
    const { clearSoftKeyboardKey, keyInput, isSoftKeyboardShow, inputs, answer, keys } = this.props
    if (isEqual(this.props, prevProps) || !isSoftKeyboardShow || !keyInput) return
    if (keys.rest) {
      if (answer) {
        if (isString(inputs)) {
          keys.rest(inputs)
        }
        clearSoftKeyboardKey()
      } else {
        const keyEvent = new KeyboardEvent('keydown', { keyCode: keyInput })
        clearSoftKeyboardKey()
        this.handleKeyDown(keyEvent)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.boundHandleKeyDown)
    document.removeEventListener('keyup', this.boundHandleKeyUp)
  }

  unprocessable = ({ keyCode, target }) => {
    const key = keyCodes[keyCode]

    if (target === null) return false
    if (/ /.test(key) && /^(INPUT|TEXTAREA|VIDEO)$/.test(target.nodeName)) return true
    if (/Arrow/.test(key) && /^(INPUT|TEXTAREA)$/.test(target.nodeName)) return true
    if (/Enter/.test(key) && /(disableMark)/.test(target.className)) return true

    return false
  }

  handleKeyUp = (e) => {
    const { keys, enableKeyUp } = this.props
    const key = keyCodes[e.keyCode]
    this.pressed[key] = false
    if (!enableKeyUp || this.unprocessable(e)) return
    const callback = keys[key]
    if (callback) callback({ event: e, isKeyCombination: e.shiftKey, isKeyUp: true })
  }

  handleKeyDown = (e) => {
    const { keys, isSoftKeyboardShow } = this.props
    const key = keyCodes[e.keyCode]
    if (this.pressed[key] || this.unprocessable(e)) return
    this.pressed[key] = true && !isSoftKeyboardShow
    const callback = Object.keys(keys).includes('Any') ? keys.Any : keys[key]
    if (callback) {
      callback({ event: e, isKeyCombination: e.shiftKey, isKeyUp: false })
    } else if (keys.rest) {
      keys.rest(key)
    }
  }

  render() {
    return null
  }
}

KeyPress.propTypes = propTypes
KeyPress.defaultProps = defaultProps
