import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import reduce from 'lodash/reduce'
import size from 'lodash/size'

import transformValues from 'src/lib/transformValues'

const MAXIMUN_CHOICE = 6

const INIT = 'followTheInstructions/INIT'
const CLICK = 'followTheInstructions/CLICK'
const MARK = 'followTheInstructions/MARK'
const RETRY = 'followTheInstructions/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  entitiesById: {},
  questionId: '',
}

const checkCardState = answers => (a, card) => {
  if (card.selected) {
    return {
      ...a,
      [card.id]: {
        ...card,
        correct: answers.includes(card.id),
      },
    }
  }
  return {
    ...a,
    [card.id]: card,
  }
}

const createInstance = ({ collections, questions, items, currentQuestionId }, entity) => {
  const prefix = entity.slug.split('-')[0]
  if (prefix === 'collection') {
    return {
      collections: { ...collections, [entity.id]: [] },
      currentQuestionId: entity.id,
      items,
      questions: questions.concat(entity.id),
    }
  }
  return {
    collections: {
      ...collections,
      [currentQuestionId]: collections[currentQuestionId].concat(entity.id),
    },
    currentQuestionId,
    items: items.includes(entity.id) ? items : items.concat(entity.id),
    questions,
  }
}

const createChoice = (a, id) => {
  if (a.choices.length < MAXIMUN_CHOICE) {
    if (!a.choices.includes(id)) {
      return { ...a, choices: a.choices.concat(id) }
    }
    return a
  }
  return a
}

const createCard = (a, id) => (
  {
    ...a,
    [id]: {
      correct: null,
      id,
      label: size(a) + 1,
      selected: null,
    },
  }
)

const setCardStatus = targetId => (a, card) => (
  {
    ...a,
    [card.id]: {
      ...card,
      selected: targetId !== card.id ? null : true,
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const {
        questions,
        items,
        collections,
      } = entities.reduce(createInstance, {
        collections: {},
        currentQuestionId: '',
        items: [],
        questions: [],
      })
      const questionId = uniqShuffle(questions)[0]
      const answers = collections[questionId]
      const { choices } = items.reduce(createChoice, { choices: answers })
      const cards = shuffle(choices).reduce(createCard, {})
      const entitiesById = keyBy(entities, 'id')
      return {
        ...initialState,
        actualEntities: [entitiesById[questionId], ...answers.map(id => entitiesById[id])],
        answers,
        cards,
        entitiesById,
        questionId,
      }
    }

    case CLICK: {
      const { targetId } = payload
      const cards = reduce(state.cards, setCardStatus(targetId), {})
      return {
        ...state,
        cards,
      }
    }

    case MARK: {
      const cards = reduce(state.cards, checkCardState(state.answers), {})
      const completed = { ...find(cards, { selected: true }) }.correct
      return {
        ...state,
        cards,
        completed,
      }
    }

    case RETRY: {
      const { cards } = state
      return {
        ...state,
        cards: transformValues(cards, card => ({ ...card, correct: null })),
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const onClick = ({ targetId }) => ({
  payload: {
    targetId,
  },
  type: CLICK,
})

const retry = () => ({
  type: RETRY,
})

export {
  initialState,
  init,
  mark,
  onClick,
  retry,
}

export default reducer
