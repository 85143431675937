import React from 'react'
import { arrayOf, shape } from 'prop-types'
import chunk from 'lodash/chunk'
import entityShape from 'src/shapes/entity'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import Word from 'src/modules/Word'


const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
}

const createIndividualId = (a, entity) => a.concat({ ...entity, id: `${entity.id}-${a.length}` })

const findImage = ({ mainSentence, exampleSentence }) => {
  const exampleImage = { ...exampleSentence }.image
  const exampleSrc = { ...exampleImage }.src
  if (exampleSrc) return exampleSentence
  return mainSentence
}

const VocabularyAndSentenceExamples = ({ entities }) => {
  const entitiesIndividualId = entities.reduce(createIndividualId, [])
  return (
    <Slides>
      {
        chunk(entitiesIndividualId, 2).map(([mainSentence, exampleSentence]) => {
          const slideId = mainSentence.id
          return (
            <Slide key={slideId} label={mainSentence.titleEn}>
              <Word
                alphabetEn={[]}
                entity={mainSentence}
                firstLine={{ audio: true, english: true }}
                image={findImage({ exampleSentence, mainSentence })}
                lastLine={{ audio: true, english: true }}
                sentenceEntity={exampleSentence}
                sentenceExample
              />
            </Slide>
          )
        })
      }
    </Slides>
  )
}

VocabularyAndSentenceExamples.propTypes = propTypes

export default VocabularyAndSentenceExamples
