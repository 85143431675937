import React from 'react'
import { shape, bool } from 'prop-types'
import i18next from 'i18next'
import Div from 'src/components/Div'

import { connect } from 'src/lib/redux'

const propTypes = {
  bold: bool,
  schoolTitle: shape().isRequired,
}

const defaultProps = {
  bold: true,
  schoolTitle: {},
}

const SchoolTitle = ({ schoolTitle, bold }) => {
  const title = schoolTitle[i18next.language]
  return (
    <Div bold={bold}>
      {title}
    </Div>
  )
}

SchoolTitle.defaultProps = defaultProps
SchoolTitle.propTypes = propTypes
const select = ({ tenantSettings }) => tenantSettings
export default connect(select)(SchoolTitle)
