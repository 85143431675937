import React from 'react'
import sumBy from 'lodash/sumBy'
import { arrayOf, shape, number } from 'prop-types'
import { t } from 'i18next'

import Div from 'src/components/Div'
import PrefixedLink from 'src/components/PrefixedLink'
import AttendanceOverviewSection from 'src/pages/DashboardPage/AttendanceOverview/AttendanceOverviewSection'
import Stats from 'src/pages/DashboardPage/AttendanceOverview/Stats'
import Widget from 'src/components/Widget'
import { ATTENDANCE } from 'src/constants/menu'
import { attendancePath } from 'src/lib/routes'

const propTypes = {
  sections: arrayOf(
    shape({
      present: number.isRequired,
      total: number.isRequired,
    }).isRequired,
  ).isRequired,
}

const pad = (array, desiredLength) => {
  const { length } = array
  const missing = desiredLength - length
  return array.concat(Array(missing < 0 ? 0 : missing).fill({}))
}

const calcTotal = (sections) => {
  const present = sumBy(sections, 'present')
  const total = sumBy(sections, 'total')
  const value = present / total

  return {
    present,
    total,
    value,
  }
}

const AttendanceOverview = ({ sections }) => {
  const { total, present, value } = calcTotal(sections)
  const title = t(`${ATTENDANCE}.title`)
  return (
    <Widget
      title={title}
      icon="chart-pie"
      titleRight={
        <Stats title="" total={total} present={present} />
      }
    >
      <Div flex gutter={2}>
        <Div col={3}>
          <PrefixedLink to={attendancePath()} className="black">
            <AttendanceOverviewSection {...{
              present,
              title,
              total,
              value,
            }}
            />
          </PrefixedLink>
        </Div>
        {
          sections && pad(sections, 3).map((section, i) => (
            // eslint disable-next-line react/no-array-index-key
            <Div key={section.id || i} col={3} px={2}>
              {section.id && <AttendanceOverviewSection {...section} />}
            </Div>
          ))
        }
      </Div>
    </Widget>
  )
}

AttendanceOverview.propTypes = propTypes
export default AttendanceOverview
