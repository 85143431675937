import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import gqlLoader from 'src/lib/gqlLoader'
import query from 'src/queries/academicYearsQuery.gql'
import createAcademicYear from 'src/mutations/AcademicYear/createAcademicYear.gql'
import updateAcademicYear from 'src/mutations/AcademicYear/updateAcademicYear.gql'
import deleteAcademicYear from 'src/mutations/AcademicYear/deleteAcademicYear.gql'
import AcademicYears from 'src/pages/SchoolSettings/AcademicYears/AcademicYears'

const options = {
  refetchQueries: [{ query }],
}
export default compose(
  gqlLoader(query),
  graphql(createAcademicYear, { name: 'createYear', options }),
  graphql(updateAcademicYear, { name: 'updateYear', options }),
  graphql(deleteAcademicYear, { name: 'deleteYear', options }),
)(AcademicYears)
