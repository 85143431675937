import React from 'react'
import map from 'lodash/map'
import size from 'lodash/size'
import { t } from 'i18next'
import EntityCard from 'src/components/EntityCard'
import Answer from 'src/components/Answer'
import WordBank from 'src/components/WordBank'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import KeyPress from 'src/components/KeyPress'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import placeShape from 'src/shapes/place'
import Card from 'src/components/Card'
import AdaptiveCard from 'src/components/AdaptiveCard'
import TextFit from 'src/components/TextFit'

const RESULT_HEIGHT = 6.8

const propTypes = {
  completed: bool,
  enterCount: number.isRequired,
  entity: shape(entityShape).isRequired,
  handleChange: func.isRequired,
  isSoftKeyboardShow: bool,
  mark: func.isRequired,
  place: shape(placeShape),
  started: bool.isRequired,
  wordBank: arrayOf(shape(entityShape)).isRequired,
}

const defaultProps = {
  completed: null,
  place: null,
}

const color = (correct) => {
  if (correct === null) return 'gray'
  if (correct) return 'green'
  return 'red'
}

const isFulstop = text => text === '.'
const fulstopStyle = { position: 'relative', right: '1.2rem' }

const InCorrectResult = ({ result }) => (
  size(result) > 0 ? (
    <TextFit focusOnWidth>
      <Div flex justify-center>
        {
          map(result, word => (
            <Div key={word.id} height={RESULT_HEIGHT} flex>
              <Card
                adaptiveSize
                transparent
                bold
                title={word.title}
                className={color(word.correct)}
                m={false}
                fit
                style={isFulstop(word.title) ? fulstopStyle : undefined}
              />
            </Div>
          ))
        }
      </Div>
    </TextFit>
  )
    : (
      <Div key="empty" flex auto>
        <Card transparent minHeight={false} bold className="red" title="..." m={false} />
      </Div>
    )
)

const CorrectResult = ({ question }) => (
  <Div key="correctAnswer" flex auto>
    <AdaptiveCard transparent minHeight={false} bold className="green" title={question} m={false} />
  </Div>
)

const Result = ({ result, completed, question }) => (
  <Div flex auto bg="white" height={RESULT_HEIGHT} m1 justify-center>
    {
      completed ? (
        <CorrectResult question={question} />
      ) : (
        <InCorrectResult result={result} />
      )
    }
  </Div>
)

const audioFallback = (entity) => {
  const fallback = !entity.titleTh
  return fallback && {
    english: true,
    play: true,
    thai: false,
  }
}

const ListenAndTypeSentences = ({
  completed,
  enterCount,
  entity,
  handleChange,
  mark,
  place,
  started,
  wordBank,
  isSoftKeyboardShow,
}) => (
  <Deck className="disable-select ListenAndTypeSentences">
    {
      enterCount === 1 && (
        <Div fixed onClick={mark} style={{ height: '100%', width: '100%' }}>
          <KeyPress keys={{ Any: () => mark() }} />
        </Div>
      )
    }
    <Div key={entity.id} flex auto gutter={1}>
      <Div col={6} flex px={1}>
        {
          entity.id && (
            <EntityCard image audio english entity={entity} autoPlay={started} />
          )
         }
      </Div>
      <Div col={6} px={1} justify="center" flex column>
        <Div>
          {
            entity.id && (
            <AdaptiveCard
              audio
              entity={entity}
              p={1}
              thai
              {...audioFallback(entity)}
            />
            )
           }
        </Div>
        <WordBank entities={wordBank} />
      </Div>
    </Div>
    <Div flex justify="center" relative>
      <Div flex auto justify="center" relative answerSoftKeyBoard={isSoftKeyboardShow}>
        {
          (completed !== null) && place ? (
            <Result result={place.result} completed={completed} question={place.question} />
          )
            : (place && (
              <Div key={place.id} flex auto>
                <Answer
                  auto
                  disabled={enterCount > 0 ? !completed : null}
                  id={place.id}
                  onChange={value => handleChange(value)}
                  size={1}
                  value={place.answer || ''}
                  placeholder={t('shared.ListenAndTypeSentence')}
                />
              </Div>
            ))
        }
      </Div>
    </Div>
  </Deck>
)

Result.propTypes = {
  result: shape({
    correct: bool,
    id: string,
    word: string,
  }),
}
Result.defaultProps = {
  result: null,
}
ListenAndTypeSentences.propTypes = propTypes
ListenAndTypeSentences.defaultProps = defaultProps

export {
  audioFallback,
  Result,
  CorrectResult,
  InCorrectResult,
}
export default ListenAndTypeSentences
