const DISABLE_MARK = 'layout/DISABLE_MARK'
const CLEAR_MARK = 'layout/CLEAR_MARK'
const DISABLE_NAVIGATION = 'layout/DISABLE_NAVIGATION'
const ENABLE_MARK = 'layout/ENABLE_MARK'
const ENABLE_NAVIGATION = 'layout/ENABLE_NAVIGATION'
const GOTO_CHAPTER = 'layout/GOTO_CHAPTER'
const GOTO_SLIDE = 'layout/GOTO_SLIDE'
const INIT_SLIDES = 'layout/INIT_SLIDES'
const NEXT_CHAPTER = 'layout/NEXT_CHAPTER'
const NEXT_SLIDE = 'layout/NEXT_SLIDE'
const PREVIOUS_SLIDE = 'layout/PREVIOUS_SLIDE'
const SET_NAVIGATION = 'layout/SET_NAVIGATION'
const SHOW_MARK = 'layout/SHOW_MARK'
const SHOW_INSTRUCTION = 'layout/SHOW_INSTRUCTION'
const HIDE_INSTRUCTION = 'layout/HIDE_INSTRUCTION'

const initialState = {
  disableMark: false,
  disableNavigation: false,
  hasNextSlide: false,
  hasPrevSlide: false,
  isShowInstruction: false,
  mark: null,
  totalChapters: 0,
  totalSlides: 0,
}

const calculateNextAndPrevSlide = ({ totalSlides, slideIndex }) => (
  {
    hasNextSlide: totalSlides > 0 && slideIndex + 1 < totalSlides,
    hasPrevSlide: slideIndex - 1 >= 0,
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DISABLE_MARK:
    case ENABLE_MARK:
    case SHOW_MARK:
    case SHOW_INSTRUCTION:
    case HIDE_INSTRUCTION:
    case GOTO_SLIDE:
      return {
        ...state,
        ...payload,
        ...calculateNextAndPrevSlide({
          slideIndex: payload.slideIndex,
          totalSlides: state.totalSlides,
        }),
      }
    case GOTO_CHAPTER:
      return {
        ...state,
        ...payload,
        slideIndex: 0,
      }
    case SET_NAVIGATION: {
      const { totalSlides, slideIndex } = state
      const { slideIndex: newSlideIndex } = payload
      return {
        ...state,
        ...payload,
        ...calculateNextAndPrevSlide({ slideIndex: newSlideIndex || slideIndex, totalSlides }),
      }
    }
    case INIT_SLIDES: {
      const { totalSlides } = payload
      const lastSlideIndex = totalSlides ? totalSlides - 1 : undefined
      const slideIndex = state.goToLastSlide ? lastSlideIndex : (state.slideIndex || 0)
      return {
        ...state,
        ...payload,
        ...calculateNextAndPrevSlide({ slideIndex, totalSlides }),
        goToLastSlide: totalSlides ? false : state.goToLastSlide,
        slideIndex,
      }
    }
    case NEXT_CHAPTER:
      return {
        ...state,
        chapterIndex: (state.totalChapters === state.chapterIndex + 1)
          ? state.chapterIndex
          : state.chapterIndex + 1,
      }
    case DISABLE_NAVIGATION:
      return {
        ...state,
        ...payload,
      }
    case NEXT_SLIDE: {
      const { totalSlides, slideIndex } = state
      const newSlideIndex = slideIndex + 1 === totalSlides ? slideIndex : slideIndex + 1
      return {
        ...state,
        ...calculateNextAndPrevSlide({ slideIndex: newSlideIndex, totalSlides }),
        slideIndex: newSlideIndex,
      }
    }
    case PREVIOUS_SLIDE: {
      const { slideIndex, totalSlides } = state
      const newSlideIndex = slideIndex === 0 ? slideIndex : slideIndex - 1
      return {
        ...state,
        ...calculateNextAndPrevSlide({ slideIndex: newSlideIndex, totalSlides }),
        slideIndex: newSlideIndex,
      }
    }
    case ENABLE_NAVIGATION:
      return {
        ...state,
        ...payload,
      }
    case CLEAR_MARK: {
      const { mark } = state
      const { prevMark } = payload
      return {
        ...state,
        mark: prevMark === mark ? null : mark,
      }
    }
    default:
      return state
  }
}

const initSlides = ({ total }) => ({
  payload: {
    totalSlides: total,
  },
  type: INIT_SLIDES,
})

const disableMarkButton = () => ({
  payload: {
    disableMark: true,
  },
  type: DISABLE_MARK,
})

const enableMarkButton = () => ({
  payload: {
    disableMark: false,
  },
  type: ENABLE_MARK,
})

const showMarkButton = mark => ({
  payload: {
    mark,
  },
  type: SHOW_MARK,
})

const showInstruction = () => ({
  payload: {
    isShowInstruction: true,
  },
  type: SHOW_INSTRUCTION,
})

const hideInstruction = () => ({
  payload: {
    isShowInstruction: false,
  },
  type: HIDE_INSTRUCTION,
})

const setNavigation = payload => ({
  payload: {
    disableNavigation: false,
    ...payload,
  },
  type: SET_NAVIGATION,
})

const goToSlide = slideIndex => ({
  payload: {
    slideIndex,
  },
  type: GOTO_SLIDE,
})

const nextSlide = () => ({
  type: NEXT_SLIDE,
})

const prevSlide = () => ({
  type: PREVIOUS_SLIDE,
})

const goToChapter = ({ chapterIndex, goToLastSlide }) => ({
  payload: {
    chapterIndex,
    goToLastSlide,
  },
  type: GOTO_CHAPTER,
})

const nextChapter = () => ({
  type: NEXT_CHAPTER,
})

const disableNavigationButton = () => ({
  payload: {
    disableNavigation: true,
  },
  type: DISABLE_NAVIGATION,
})

const enableNavigationButton = () => ({
  payload: {
    disableNavigation: false,
  },
  type: ENABLE_NAVIGATION,
})

const clearMark = payload => ({
  payload,
  type: CLEAR_MARK,
})

export {
  clearMark,
  disableMarkButton,
  disableNavigationButton,
  enableMarkButton,
  enableNavigationButton,
  goToChapter,
  goToSlide,
  hideInstruction,
  initSlides,
  nextChapter,
  nextSlide,
  prevSlide,
  setNavigation,
  showInstruction,
  showMarkButton,
}

export default reducer
