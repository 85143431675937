import React from 'react'
import { bool, func, object, shape, string, objectOf, oneOfType, number } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import KeyPress from 'src/components/KeyPress'

const propTypes = {
  cards: objectOf(
    shape({
      id: string.isRequired,
      label: number.isRequired,
      src: string,
      status: oneOfType([bool, string]),
    }).isRequired,
  ).isRequired,
  entitiesById: object.isRequired,
  selectCard: func.isRequired,
}

const TextChoices = ({ cards, entitiesById, selectCard }) => (
  <Div flex flex-1 column>
    {
      map(cards, card => (
        <Div key={card.key} flex auto flex-1>
          <AdaptiveCard
            absolutelyPositionedLabel
            audio
            auto
            bold={false}
            correct={card.correct}
            english
            entity={entitiesById[card.id]}
            keyToPlay={card.label}
            onClick={() => selectCard({ cardId: card.id })}
            label={card.label}
            selected={card.selected}
            minHeight={false}
            max={5}
            wrapperJustify="start"
            min={0.1}
            textAlign="left"
          />
          <KeyPress keys={{ [card.label]: () => selectCard({ cardId: card.id }) }} />
        </Div>
      ))
    }
  </Div>
)

TextChoices.propTypes = propTypes

export default TextChoices
