import React from 'react'
import { func, object, string } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EntityCard from 'src/components/EntityCard'
import KeyHandler from 'src/components/KeyHandler'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Img from 'src/components/Img'

const propTypes = {
  cards: object,
  entitiesById: object,
  image: string,
  onClick: func,
  paragraph: string,
}

const SelectParagraphTitle = ({ image, paragraph, cards, onClick, entitiesById }) => (
  <Deck>
    {
      map(cards, card => (
        <Div flex flex-1 key={card.id}>
          <Div flex column justify-center>
            <KeyHandler label={card.label} onKeyPress={() => onClick({ id: card.id })} />
          </Div>
          <EntityCard
            audio
            auto
            className="px2"
            english
            entity={entitiesById[card.id]}
            align="left"
            size={2}
            keyToPlay={card.label}
            onClick={() => onClick({ id: card.id })}
            selected={card.selected}
            correct={card.correct}
          />
        </Div>
      ))
    }
    {
      (image || paragraph) && (
        <Div flex auto height={20}>
          {
            image && (
              <Div flex auto col={paragraph ? 6 : 12} m>
                <Img src={image} />
              </Div>
            )
          }
          {
            paragraph && (
              <Div flex auto col={image ? 6 : 12}>
                <AdaptiveCard
                  auto
                  p={1}
                  title={paragraph}
                />
              </Div>
            )
          }
        </Div>
      )
    }
  </Deck>
)

SelectParagraphTitle.propTypes = propTypes
export default SelectParagraphTitle
