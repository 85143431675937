import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/BarChart/reducer'
import BarChart from 'src/modules/BarChart/BarChart'

const propTypes = {
  ...gamePropTypes,
}

class BarChartContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  render() {
    const {
      completed,
      bars,
      barSize,
      entitiesById,
      mark,
      retry,
      image,
      paragraph,
      clickPlus,
      clickMinus,
      actualEntities,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <BarChart
          {
            ...{
              barSize,
              bars,
              clickMinus,
              clickPlus,
              entitiesById,
              image,
              paragraph,
            }
          }
        />
      </Game>
    )
  }
}

const select = ({ barChart }) => barChart
const actions = dispatch => bindActionCreators(cardActions, dispatch)

BarChartContainer.propTypes = propTypes
BarChartContainer.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'eat',
    'two',
    'sleep',
    'three',
    'run',
    'five',
  ],
}

export { BarChartContainer }
export default connect(select, actions)(BarChartContainer)
