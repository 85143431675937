import React from 'react'
import { number, bool, array } from 'prop-types'

const Container = ({ children, isFinished, animationDuration }) => (
  isFinished ?
    null
    : (
      <div
        style={{
          pointerEvents: 'none',
          transition: `opacity ${animationDuration}ms linear`,
        }}
      >
        {children}
      </div>
    ))

Container.defaultProps = {
  isFinished: false,
}

Container.propTypes = {
  animationDuration: number.isRequired,
  children: array.isRequired,
  isFinished: bool,
}

export default Container
