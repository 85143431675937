import React from 'react'
import { shape, string } from 'prop-types'
import { t } from 'i18next'

import Div from 'src/components/Div'
import EmblemOfThailand from 'src/images/Emblem_of_Thailand.svg'
import GradeDistribution from 'src/pages/ProgressPage/AttainmentProgress/StudentAttainmentProgress/GradeDistribution'
import Img from 'src/components/Img'
import PrintInstructions from 'src/components/PrintInstructions'
import SchoolTitle from 'src/components/SchoolTitle'
import academicYearShape from 'src/shapes/academicYear'
import yearShape from 'src/shapes/year'
import subjectShape from 'src/shapes/subject'

const propTypes = {
  academicTermId: string.isRequired,
  academicYear: shape(academicYearShape).isRequired,
  classAttainment: shape({
    gradeDistribution: shape(),
  }).isRequired,
  subject: shape(subjectShape).isRequired,
  year: shape(yearShape).isRequired,
}

const BpReportPage = ({
  academicTermId,
  academicYear,
  classAttainment: {
    gradeDistribution,
  },
  subject: { title: subjectTitle },
  year: { title: yearTitle },
}) => {
  if (!academicYear) { return null }
  const academicTerm = academicTermId !== 'all'
    ? academicYear.academicTerms.find(({ id, slug }) => [id, slug].includes(academicTermId))
    : {}
  const academicTermTitle = { ...academicTerm }.title
  return (
    <Div>
      <PrintInstructions />
      <Div bg-white rounded shadow container p2>
        <Div right-align bold>{t('bp.bp')}.5</Div>
        <div style={{ height: '4rem' }}>
          <Img src={EmblemOfThailand} />
        </div>
        <Div center bold>
          <Div my2>{t('bp.title')}</Div>
          <Div my2>
            <SchoolTitle />
            {' '}
            {academicYear.title}
          </Div>
          {academicTermTitle && (
            <Div my2 inline regular>
              {academicTermTitle}
            </Div>
          )}
        </Div>
        <Div flex flex-column align-center>
          <Div h2>{yearTitle} {subjectTitle}</Div>
          <GradeDistribution distribution={gradeDistribution} />
        </Div>
      </Div>
    </Div>
  )
}

BpReportPage.propTypes = propTypes
export default BpReportPage
