import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'
import sortBy from 'lodash/sortBy'
import Div from 'src/components/Div'
import Donut from 'src/components/Donut'
import GradeDistribution from 'src/pages/ProgressPage/AttainmentProgress/StudentAttainmentProgress/GradeDistribution'
import Student from 'src/pages/ProgressPage/AttainmentProgress/StudentAttainmentProgress/Student'

const propTypes = {
  classAttainment: shape({
    students: arrayOf(
      shape({
        assigned_count: number.isRequired,
        completed_count: number.isRequired,
        full_name: string.isRequired,
        grade: number.isRequired,
        id: string.isRequired,
        rate: number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  yearId: string.isRequired,
}

const StudentAttainmentProgress = ({
  classAttainment: {
    students,
    score,
    gradeDistribution,
  },
  yearId,
}) => (
  <Div mb2 p2>
    <Div flex gutter={1}>
      <Div col={2} mx={1} className="center">
        <Donut value={score} size={2} fixed={4} />
      </Div>
      <Div col={10} mx={2} justify="center" flex>
        <GradeDistribution distribution={gradeDistribution} />
      </Div>
    </Div>
    {
      sortBy(students, 'identifier').map((student, index) => (
        <Student
          key={student.id}
          {...{
            ...student,
            yearId,
            zebra: !!(index % 2),
          }}
        />
      ))
    }
  </Div>
)

StudentAttainmentProgress.propTypes = propTypes
export default StudentAttainmentProgress
