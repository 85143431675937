import React from 'react'
import { GameContext } from 'src/lib/contexts'
import Audio from 'src/components/Audio/Audio'

const AudioContainer = props => (
  <GameContext.Consumer>
    {({ isPreview }) => <Audio {...props} isPreview={isPreview} />}
  </GameContext.Consumer>
)

export default AudioContainer
