import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import { bool } from 'prop-types'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ParagraphMissingWords/reducer'
import ParagraphMissingWords from 'src/modules/ParagraphMissingWords/ParagraphMissingWords'

const propTypes = {
  ...gamePropTypes,
  punctuation: bool,
}

const defaultProps = {
  completed: null,
  punctuation: null,
  punctuationMode: false,
}

class ParagraphMissingWordsContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    if (this.props.punctuation) {
      this.props.initPunctuation(this.props)
    } else {
      this.props.init(this.props)
    }
  }

  render() {
    const {
      actualEntities,
      availableCardsId,
      cards,
      completed,
      entitiesById,
      image,
      mark,
      moveCard,
      places,
      punctuationMode,
      retry,
      words,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ParagraphMissingWords
          availableCardsId={availableCardsId}
          cards={cards}
          entitiesById={entitiesById}
          image={image}
          punctuationMode={punctuationMode}
          moveCard={moveCard}
          places={places}
          words={words}
        />
      </Game>
    )
  }
}

ParagraphMissingWordsContainer.propTypes = propTypes
ParagraphMissingWordsContainer.defaultProps = defaultProps
ParagraphMissingWordsContainer.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'is',
    'am',
    'are',
    'apple',
  ],
}

const selector = state => state.paragraphMissingWords
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { ParagraphMissingWordsContainer }
export default connect(selector, actions)(ParagraphMissingWordsContainer)
