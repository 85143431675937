/* eslint-disable max-len */
/* eslint-disable sort-keys */
import {
  ACTIVITIES,
  ADMISSIONS,
  ALL_STUDENTS,
  ATTENDANCE,
  ATTENDANCE_REPORT,
  BP_REPORT,
  CALENDAR,
  CHILDREN,
  DOCUMENT_MANAGEMENT,
  EXAMINATIONS,
  GRADING,
  HOMEWORK,
  LEADERBOARD,
  LESSON_MODULES,
  LESSONS,
  LIBRARY,
  MY_FAMILY,
  MY_STUDENTS,
  MY_STUDENTS_ASSESSMENT,
  MY_STUDENTS_ATTENDANCE,
  MY_STUDENTS_ATTENDANCE_REPORT,
  MY_STUDENTS_CURRICULUM,
  MY_STUDENTS_PROGRESS,
  PARENT_DATA,
  PARENTS,
  PEOPLE,
  PROGRESS,
  RESOURCE_MANAGEMENT,
  SCHOOL,
  SCHOOL_ATTENDANCE,
  SCHOOL_ATTENDANCE_REPORT,
  SCHOOL_CURRICULUM,
  SCHOOL_PROGRESS,
  SCHOOL_SETTINGS,
  SKILLS,
  SPECIAL_NEEDS,
  STAFF,
  STAFF_DATA,
  STUDENT_CARDS,
  STUDENT_DATA,
  TEACHER,
  TEACHER_GUIDES,
  TEACHERS,
  TIMETABLE,
  USERS,
  YEARS,
} from 'src/constants/menu'

export default {
  'locale.locale': 'en',
  'locale.name': 'English',

  'component.titlePage': 'Title Page',

  'shared.all': 'All',
  'units.mm': 'mm',
  'units.cm': 'cm',
  'units.m': 'm',
  'units.C': '°C',

  'all.units': 'All Units',
  'answer.yes': 'Yes',
  'answer.no': 'No',
  'answer.true': 'True',
  'answer.false': 'False',

  'shared.academicYear': 'Academic Year',
  'shared.announcements': 'Announcements',
  'shared.dictionary': 'Dictionary',
  'shared.done': 'Done',
  'shared.error': 'Error',
  'shared.lessons': 'Lessons',
  'shared.parent': 'Parent',
  'shared.school': 'School',
  'shared.student': 'Student',
  'shared.teacher': 'Teacher',
  'shared.term': 'Term',
  'shared.english': 'English',
  'shared.en': 'En',
  'shared.thai': 'ไทย',
  'shared.th': 'ไทย',
  'shared.today': 'Today',
  'shared.week': 'Week',
  'shared.weekend': 'Weekend',
  'shared.areYouSure': 'Are you sure?',

  'leaderboard.tryHarder': 'No 3.0+ students',
  'leaderboard.StarsOfTheWeek': 'Stars of the week',

  'direction.from': 'From',
  'direction.to': 'To',
  'direction.next': 'Next',
  'direction.previous': 'Previous',

  'home.homework': 'Homework',
  'home.users': 'Users',

  'shared.add': 'Add',
  'shared.edit': 'Edit',
  'shared.new': 'New',
  'shared.notFound.user': 'Could not find user',
  'shared.save': 'Save',
  'shared.delete': 'Delete',
  'save.success': 'Saved successfully',
  'shared.cancel': 'Cancel',
  'shared.select': 'Select',
  'shared.skip': 'Skip',
  'shared.microphoneCheck': 'Checking microphone',
  'send.email.success': 'Email sent successfully',
  'send.default.success':
    'The password was reset to the one that was issued for you.',
  'shared.typeHere': 'Type here',
  'shared.ListenAndTypeSentence': 'Listen and type the sentence.',

  'errors.recordInvalid': 'Validation failed',
  'errors.missingUsername': 'Please Fill Username',
  'errors.passwordNotSame': 'Please Fill Same Password',
  'errors.passwordIsBlank': 'This can not be blank',
  'errors.actionNotAllowed.title': 'Action Not Allowed',
  'errors.actionNotAllowed.message':
    'This action is only available from the school',
  'errors.noRecordsFound': 'No records found',

  'overview.title': 'Overview',

  [`${ACTIVITIES}.description`]: 'Extracurricular lessons, clubs, sports and other events',
  [`${ACTIVITIES}.title`]: 'Activities',
  [`${ADMISSIONS}.description`]: 'Support registration process & management reporting',
  [`${ADMISSIONS}.title`]: 'Admissions',
  [`${ALL_STUDENTS}.title`]: 'All Students',
  [`${ATTENDANCE_REPORT}.title`]: 'Attendance Report',
  [`${ATTENDANCE}.title`]: 'Attendance',
  [`${CALENDAR}.description`]: 'Event planning across all departments of the school',
  [`${CALENDAR}.title`]: 'Calendar',
  [`${CHILDREN}.title`]: 'Children',
  [`${DOCUMENT_MANAGEMENT}.description`]: 'Organize documents, invoices and reports to parents, tracks all communications with pupils, parents and staff',
  [`${DOCUMENT_MANAGEMENT}.title`]: 'Document Management',
  [`${EXAMINATIONS}.description`]: 'Summative Assessment: Complete view and edit grades, produce report cards, school transcripts and documents',
  [`${EXAMINATIONS}.title`]: 'Examinations',
  [`${GRADING}.description`]: 'Show the grade system',
  [`${GRADING}.title`]: 'Grade boundaries',
  [`${HOMEWORK}.title`]: 'Homework',
  [`${LEADERBOARD}.description`]: 'Show the student leader board',
  [`${LEADERBOARD}.title`]: 'Leader Board',
  [`${BP_REPORT}.title`]: 'Basic Progress Report',
  [`${LESSON_MODULES}.description`]: 'Show the lesson modules',
  [`${LESSON_MODULES}.title`]: 'Activities',
  [`${LESSONS}.title`]: 'Lessons',
  [`${LIBRARY}.description`]: 'Search for Books, control stocks, track and assign books for students to read, circulation process online',
  [`${LIBRARY}.title`]: 'Library',
  [`${MY_FAMILY}.title`]: 'My Family',
  [`${MY_STUDENTS_ASSESSMENT}.description`]: 'Track pupils Formative Assessment progress through the curriculum against the Standard Indicators in the curriculum',
  [`${MY_STUDENTS_ASSESSMENT}.title`]: 'Mark Homework',
  [`${MY_STUDENTS_ATTENDANCE_REPORT}.description`]: 'Report attendance by period, day, term and year for your students',
  [`${MY_STUDENTS_ATTENDANCE_REPORT}.title`]: 'Attendance Report',
  [`${MY_STUDENTS_ATTENDANCE}.description`]: 'Manage attendance by period, day, term and year for your students',
  [`${MY_STUDENTS_ATTENDANCE}.title`]: 'Students Attendance',
  [`${MY_STUDENTS_CURRICULUM}.description`]: 'Manage subject range and rooms to specific subjects for your students',
  [`${MY_STUDENTS_CURRICULUM}.title`]: 'Curriculum',
  [`${MY_STUDENTS_PROGRESS}.description`]: 'Tracking Learning & teaching status for your students',
  [`${MY_STUDENTS_PROGRESS}.title`]: 'Progress',
  [`${MY_STUDENTS}.title`]: 'Students',
  [`${PARENT_DATA}.description`]: 'Parents contact details, Parent check school reports, attendance, upcoming events, tuition & extra-curricular accounts',
  [`${PARENT_DATA}.title`]: 'Parent Data',
  [`${PARENTS}.title`]: 'Parents',
  [`${PEOPLE}.title`]: 'People',
  [`${PROGRESS}.title`]: 'Progress',
  [`${RESOURCE_MANAGEMENT}.description`]: 'It includes inventory of stocks, materials and equipment',
  [`${RESOURCE_MANAGEMENT}.title`]: 'Resource Management',
  [`${SCHOOL_ATTENDANCE_REPORT}.description`]: 'Report attendance by period, day, term and year',
  [`${SCHOOL_ATTENDANCE_REPORT}.title`]: 'Attendance Report',
  [`${SCHOOL_ATTENDANCE}.description`]: 'Manage attendance by period, day, term and year',
  [`${SCHOOL_ATTENDANCE}.title`]: 'Attendance',
  [`${SCHOOL_CURRICULUM}.description`]: 'Manage subject range, rooms & teachers to specific subjects',
  [`${SCHOOL_CURRICULUM}.title`]: 'Curriculum',
  [`${SCHOOL_PROGRESS}.description`]: 'Tracking Learning & teaching status from whole school to individual student & teacher',
  [`${SCHOOL_PROGRESS}.title`]: 'Progress',
  [`${SCHOOL_SETTINGS}.title`]: 'Settings',
  [`${SCHOOL}.title`]: 'School',
  [`${SKILLS}.title`]: 'Skills',
  [`${SPECIAL_NEEDS}.description`]: 'Track the information relating to the learning needs of special students',
  [`${SPECIAL_NEEDS}.title`]: 'Special Needs',
  [`${STAFF_DATA}.description`]: 'Staff information and details, Access to key pupil and school information for school on web browsing and any mobile device',
  [`${STAFF_DATA}.title`]: 'Staff Data',
  [`${STAFF}.title`]: 'Staff',
  [`${STUDENT_CARDS}.title`]: 'Student Cards',
  [`${STUDENT_DATA}.description`]: 'Manage student data that ensures data integrity, accurate reporting and quick access to information',
  [`${STUDENT_DATA}.title`]: 'Student Data',
  [`${TEACHER_GUIDES}.title`]: 'Teacher Guides',
  [`${TEACHERS}.title`]: 'Teachers',
  [`${TEACHER}.title`]: 'Teacher',
  [`${TIMETABLE}.description`]: 'Show the timetable of the whole school, individual class and section',
  [`${TIMETABLE}.title`]: 'Timetable',
  [`${USERS}.title`]: 'Users',
  [`${YEARS}.title`]: 'Years',

  'academicYearFilter.yearSelector': 'Select Academic Year',
  'academicYearFilter.wholeYear': 'Whole Year',

  'assessment.toMark': 'To Mark',
  'assessment.marked': 'Marked',
  'assessment.homeworkEmpty': 'No finished homework yet.',
  'assessment.mark': 'Mark',
  'assessment.grade': 'Grade',

  'dashboard.homeworkAssignments.title': 'Year Assignments',
  'dashboard.homeworkAssignments.createdAt': 'Created At',
  'dashboard.homeworkAssignments.lesson': 'Lesson',
  'dashboard.homeworkAssignments.year': 'Year',
  'dashboard.homeworkAssignments.due': 'Due',
  'dashboard.homeworkAssignments.teacher': 'Teacher',

  'grade.system.color': 'Color',
  'grade.system.grade': 'Grade',
  'grade.system.percent': 'Percent',
  'grade.system.number': 'Grade (Number)',
  'grade.system.titleEn': 'English Description',
  'grade.system.titleTh': 'Thai Description',

  'homework.grade': 'Grade',
  'homework.students': 'Students',
  'homework.completedRate': 'Homework Completed / Assigned',
  'homework.assignedRate': 'Homework Assigned / Available',

  'objectives.objectives': 'Objectives',
  'objectives.byTheEndOfThisLesson':
    'By the end of this lesson, you will be able to:',

  'wellDone.wellDone': 'Well Done!',
  'wellDone.howDoYouFeel': 'How do you feel about your work?',
  'wellDone.youHaveLearned': 'Today you have learned to:',

  'results.results': 'Results',

  'skills.reading': 'Reading',
  'skills.writing': 'Writing',
  'skills.speaking': 'Speaking',
  'skills.listening': 'Listening',
  'skills.title': 'Skills',

  'subjects.math': 'Math',
  'subjects.english': 'English',
  'subjects.science': 'Science',
  'subjects.thai': 'Thai',

  'curriculum.subject': 'Subject',
  'curriculum.strand': 'Strand',
  'curriculum.standard': 'Standard',
  'curriculum.grade': 'Grade',
  'curriculum.indicator': 'Indicator',

  'attendance.allPresent': 'All Present',
  'attendance.scanCard': 'Scan user card',
  'attendance.shared.absent': 'Absent',
  'attendance.shared.present': 'Present',
  'attendance.status.present': 'Present',
  'attendance.status.late': 'Late',
  'attendance.status.absent': 'Absent',
  'attendance.status.sick': 'Sick',
  'attendance.status.permitted': 'Permitted',
  'attendance.nav.report': 'Report',
  'attendance.nav.weekly': 'Weekly',
  'attendance.report.days': 'Days',
  'attendance.report.attendance': 'Attendance',
  'attendance.report.attendanceSections': 'Attendance Sections',
  'attendance.report.grade': 'Grade',
  'attendance.report.students': 'Students',
  'attendance.progress.percentDescription': 'Attendance Overview',
  'attendance.report.progress': 'Attendance',
  'attendance.progress.bodyDescription': 'Attendance',
  'attendance.progress.actual': 'Present',
  'attendance.progress.total': 'Days',
  'attendance.weekly': 'Attendance Weekly',

  'navigation.beginning': 'Beginning',
  'navigation.end': 'End',
  'navigation.help': 'Help',
  'navigation.mark': 'Mark It',
  'navigation.next': 'Next',
  'navigation.previous': 'Prev',
  'navigation.lesson': 'Lesson',

  'lessons.unrelease.warning':
    'Warning: The results are in for this homework. Continue?',

  'error.inChapter': "This chapter has an error and won't be shown.",
  'error.inModule': "This module has an error and won't be shown.",
  'error.noChapter':
    'This chapter is under construction and will be available soon.',
  'moduleGallery.selectOne': 'Select a module',

  'progress.selectStudent':
    'Select a class from the selector and a student from the list',

  'user.user': 'User',
  'user.name': 'Name',
  'user.firstName': 'First Name',
  'user.firstNameEn': 'First Name (En)',
  'user.firstNameTh': 'First Name (Th)',
  'user.lastName': 'Last Name',
  'user.lastNameEn': 'Last Name (En)',
  'user.lastNameTh': 'Last Name (Th)',
  'user.email': 'Email',
  'user.password': 'Password',
  'user.confirmPassword': 'Confirm Password',
  'user.relationship': 'Relationship',
  'user.identifier': 'Identifier',
  'user.role': 'Role',
  'user.section': 'Section',
  'user.year': 'Year',
  'user.teachingYears': 'Teaching Years',
  'user.children': 'Children',
  'user.parents': 'Parents',
  'user.birthdate': 'Birthdate',
  'user.medicalNote': 'Medical Note',
  'user.updatePhoto': 'Update photo',
  'user.pleaseChangePasswordAt': 'Please change your password at ',
  'user.academicTerms': 'Academic Terms',
  'user.academicTerm': 'Academic Term',

  'student.for': 'for Completed Homework Assignments',
  'student.progress.title': 'Homework',
  'student.progress.bodyDescription': 'Homework Chapters',
  'student.progress.percentDescription': 'Completed Homework Chapters',
  'student.todo': 'Homework To Do',
  'student.done': 'Homework Done',
  // 'student.todoEmpty': 'Well done! Ask your teacher for more homework.',
  'student.todoEmpty': 'Well done! Time for another level!',
  'student.doneEmpty': 'Ready to start your homework?',
  'student.name': 'Name',
  'student.studentId': 'Student ID',
  'student.class': 'Class',
  'student.personaldetails': 'Personal',
  'student.schedule': 'Schedule',
  'student.calendar': 'Calendar',
  'student.points': 'Points',
  'student.menu': 'Menu',
  'student.attendance': 'Attendance',

  'login.button': 'Login',
  'login.login': 'ID or Email',
  'login.email': 'Email',
  'login.error': 'Invalid user or password',
  'login.errors.invalidLocation': 'Please log in to {{subdomain}}.l21.sc first',
  'login.errors.invalidPassword': 'Invalid password',
  'login.errors.invalidUser': 'Invalid user',
  'login.errors.loginRequired': 'Login required',
  'login.forgot': 'Forgot password?',
  'login.google': 'Log in with Google',
  'login.facebook': 'Log in with Facebook',
  'login.loginAs': 'Log in as',
  'login.password': 'Password',
  'login.resetPassword': 'Reset Password',
  'login.backToLogin': 'Back to Login',
  'login.parentLogin': 'Parent login',

  'logout.button': 'Logout',

  'demo.button': 'Demo School',
  'demo.login1': 'Please select your school to log in',
  'demo.login2': 'or browse the',

  'guide.subject': 'Subject',
  'guide.strands': 'Strand',
  'guide.standards': 'Standard',
  'guide.indicators': 'Indicators',
  'guide.suggestion': 'Suggestions:',
  'guide.unit': 'Unit',
  'guide.lesson': 'Lesson',
  'guide.level': 'Level',
  'guide.time': 'Time',
  'guide.hour': 'Hour',
  'guide.learningObjectives': 'Learning Objectives',
  'guide.teachingMaterials.title': 'Teaching Materials',
  'guide.teachingMaterials.info': 'Learn21 E-learning programme',
  'guide.measurementAndEvaluation.title': 'Measurement and Evaluation',
  'guide.measurementAndEvaluation.info1':
    'Homework from Learn21 Interactive e-learning programme.',
  'guide.measurementAndEvaluation.info2':
    'Class Assessment from Learn21 Interactive e-learning programme.',
  'guide.measurementAndEvaluation.info3':
    'Self Assessment from Learn21 Interactive e-learning programme.',
  'guide.subjectCode': 'Subject',
  'guide.indexEntities': 'Index Entities',
  'guide.printButton': 'Print / Export',
  'guide.presentation': 'Presentation',
  'guide.practice': 'Practice',
  'guide.production': 'Production',
  'guide.printInstructionImage1': '/images/print-settings-en-1.png',
  'guide.printInstructionImage2': '/images/print-settings-en-2.png',
  'guide.printInstructionImage3': '/images/print-settings-en-3.png',

  'school.progress.percentDescription': 'Completed Homework Assignments',
  'school.progress.bodyDescription': 'Homework Assignments',
  'progress.actual': 'Completed',
  'progress.total': 'Total',
  'progress.averageAttainment': 'Average Attainment',
  'progress.averageTries': 'Average Tries',
  'progress.totalTries': 'Tries',

  'school.overview': 'Overview',
  'school.grade': 'Year',
  'school.averageProgress': 'Average Progress',
  'school.homeworkReleased': 'Homework Released',
  'school.attainmentProgress': 'Class Attainment',
  'school.curriculumProgress': 'Curriculum Coverage',
  'school.studentProgress': 'Student Individual Progress',
  'school.for': 'for Completed Homework Assignments',
  'school.unit': 'Unit',
  'school.lesson': 'Lesson',
  'school.selectUnit': 'Select Unit',
  'school.calendar.createEvent': 'Create Event',
  'school.calendar.schoolOpen': 'School Open',
  'school.calendar.schoolClosed': 'School Closed',
  'school.calendar.schoolHolidays': 'School Holidays',
  'school.calendar.schoolEvent': 'School Event',

  'school.settings.academicTerms': 'Academic Terms',
  'school.settings.academicYears': 'Academic Years',
  'school.settings.academicTerm': 'Academic Term',
  'school.settings.holidays': 'Holidays',
  'school.settings.holiday': 'Holiday',
  'school.settings.calendarDays': 'Calendar Days',
  'school.settings.academicDays': 'Academic Days',
  'school.settings.academicDay': 'Academic Day',
  'school.settings.calendar': 'Calendar',
  'school.settings.currentYear': 'Current Year',
  'school.settings.schoolEvents': 'School Events',
  'school.settings.school': 'School',
  'school.settings.schoolTitle': 'School Title',
  'school.settings.selectAcademicYear': 'Select Academic Year',
  'school.settings.selectEvent': 'Select Event',
  'school.users.assign': 'Assign {{count}} to {{- year}}',
  'school.users.resign': 'Resign {{count}} from class',
  'shortcut.instructions.title': 'Shortcuts',
  'shortcut.instructions.leftArrow': 'Prev Slide',
  'shortcut.instructions.rightArrow': 'Next Slide',
  'shortcut.instructions.space': 'Play / Pause Video',
  'shortcut.instructions.numbers': 'Play Sound',
  'released.progress.percentDescription': 'Homework Assignments',
  'released.progress.bodyDescription': 'Homework Assignments',
  'released.progress.actual': 'Released',
  'released.progress.total': 'Total',

  'browser.warning.title': 'Unsupported browser',
  'browser.warning.message':
    'Please use Chrome browser or download the mobile app',

  'year.year': 'Year',
  'year.code': 'Code',
  'year.empty': 'No students for this period',
  'year.grade': 'Grade',
  'year.id': 'Id',
  'year.title': 'Title',
  'year.studentCount': 'Student Count',
  'year.teachersName': 'Teacher Name',

  'selection.clear': 'Clear selection',
  'selection.toggle-on': 'Select this page',
  'selection.toggle-off': 'Unselect this page',

  'bp.bp': 'BP',
  'bp.title': 'Course learners progress report',

  'registration.registration': 'Registration',
  'registration.parent': 'Parent',
  'registration.children': 'Children',
  'registration.registrationNow': 'Register now',
  'registration.toImproveEnglish': 'To improve English skills',
  'registration.aboutUs': 'Try our proven way of learning Primary School English. Program is aligned with the Thai School Curriculum. Free registration and trial period.',
  'registration.addStudent': 'Add a student',
  'registration.thankYou': 'Thank you!',
  'registration.done': '**Thank you for signing up.**\n\nPlease click the link we have sent you to confirm your email.',
  'registration.complete': '**Hold on a second**\n\nCreating your account.',

  'student.loginAndStart': 'Login and Start',
  'student.loginAndDoHomework': 'Login and do homework',
  'student.switchLevel': 'Switch grades',
  'student.seeSkills': 'See skills progress',
  'student.seeAttendance': 'See attendance',

  'welcome.tagline': 'The school of tomorrow... Today',
}
