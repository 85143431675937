import React, { useState } from 'react'
import { t } from 'i18next'
import Widget from 'src/components/Widget'
import Div from 'src/components/Div'
import {
  formatDayShortNumeric,
  formatMonthMedium,
  formatYearLong,
  add,
  subtract,
  startOf,
  endOf,
  dateToday,
  isAfter,
} from 'src/lib/dates'
import ActionLink from 'src/components/ActionLink'
import HomeworkAssignments from 'src/pages/SchoolDashboardPage/HomeworkAssignments/HomeworkAssignmentsContainer'

const formatDate = date => `${formatMonthMedium(date)} ${formatDayShortNumeric(date)}`

const HomeworkAssignmentsPages = () => {
  const today = dateToday()
  const [month, setMonth] = useState(startOf(today, 'month'))
  const startDate = startOf(month, 'month')
  const endDate = endOf(month, 'month')
  return (
    <Widget title={t('dashboard.homeworkAssignments.title')} icon="calendar-check">
      <Div flex auto my2>
        <ActionLink
          className="qa-assignments-prev"
          before
          icon="arrow-left"
          onClick={() => setMonth(subtract(startDate, 1, 'month'))}
        >
          {t('navigation.previous')}
        </ActionLink>
        <Div center fx={1}>
          <Div h4 bold className="qa-assignments-dates">
            {formatDate(startDate)} - {formatDate(endDate)}
          </Div>
          <Div bold className="qa-assignments-year">
            {formatYearLong(startDate)}
          </Div>
        </Div>
        <ActionLink
          className="qa-assignments-next"
          after
          icon="arrow-right"
          onClick={() => setMonth(add(startDate, 1, 'month'))}
          disabled={isAfter(endDate, today)}
        >
          {t('navigation.next')}
        </ActionLink>
      </Div>
      <HomeworkAssignments startDate={month} endDate={endDate} />
    </Widget>
  )
}

export default HomeworkAssignmentsPages
