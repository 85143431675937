import React from 'react'
import { object, func } from 'prop-types'
import map from 'lodash/map'
import Line from 'src/modules/MatchImageWithText/Line'

const propTypes = {
  cards: object.isRequired,
  moveCard: func.isRequired,
  places: object.isRequired,
}

const MatchImageWithText = ({
  cards,
  moveCard,
  places,
}) => (
  <div>
    {
      map(places, ({ id, child, correct }) => (
        <Line
          key={id}
          id={id}
          entity={cards[id]}
          child={cards[child]}
          moveCard={moveCard}
          correct={correct}
        />
      ))
    }
  </div>
)

MatchImageWithText.propTypes = propTypes

export default MatchImageWithText
