import { arrayOf, shape, string } from 'prop-types'
import localizedField from 'src/shapes/localizedField'
import gradeShape from 'src/shapes/grade'
import lessonShape from 'src/shapes/lesson'
import sectionShape from 'src/shapes/section'

const unitShape = {
  grade: shape(gradeShape),
  id: string,
  identifier: string,
  lessons: arrayOf(shape(lessonShape)),
  slug: string,
  subject: shape(sectionShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default unitShape
