import React from 'react'
import { t } from 'i18next'
import { useMutation } from '@apollo/react-hooks'

import Fakebutton from 'src/components/Fakebutton'
import Icon from 'src/components/Icon'
import loginByToken from 'src/lib/loginByToken'
import studentLoginMutation from 'src/pages/Students/studentLoginMutation.gql'
import { warningMessage } from 'src/lib/toaster'

const StudentLogin = ({ studentId }) => {
  const [studentLogin, { loading }] = useMutation(studentLoginMutation)
  const mutate = () =>
    !loading && studentLogin({ variables: { studentId } })
      .then(({ data: { studentLoginMutation: { token } } }) => loginByToken(token))
      .catch((error) => error && warningMessage({ title: error.message }))
  return (
    <Fakebutton muted={loading} bg="green" onClick={mutate}>
      <Icon icon="flag-checkered" />
      {t('student.loginAndStart')}
      <Icon icon="sign-in-alt" />
    </Fakebutton>
  )
}

export default StudentLogin
