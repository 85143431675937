import React from 'react'
import { array, shape, string } from 'prop-types'
import StickyTable from 'src/components/StickyTable'
import gqlLoader from 'src/lib/gqlLoader'
import query from 'src/queries/pages/AttendanceReport/attendanceReport.gql'
import TableBody from 'src/components/AttendanceReport/TableBody'
import TableHeader from 'src/components/AttendanceReport/TableHeader'

const propTypes = {
  academicTermId: string,
  academicYearId: string,
  attendanceReport: shape({
    sections: array,
  }).isRequired,
  yearId: string,
}

const defaultProps = {
  academicTermId: undefined,
  academicYearId: undefined,
  yearId: undefined,
}

const AttendanceTable = ({
  academicTermId,
  academicYearId,
  attendanceReport: { sections },
  yearId,
}) => {
  const params = { yearId, academicYearId, academicTermId }
  return (
    <StickyTable>
      <TableHeader />
      <TableBody sections={sections} {...params} />
    </StickyTable>
  )
}

AttendanceTable.propTypes = propTypes
AttendanceTable.defaultProps = defaultProps

export default gqlLoader(query)(AttendanceTable)
