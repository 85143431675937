import chunk from 'lodash/chunk'
import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import reduce from 'lodash/reduce'

const INIT_SPELLING = 'spelling/INIT_SPELLING'
const PLAY = 'spelling/PLAY'
const NEXT = 'spelling/NEXT'

const initSpelling = phonics => ({
  payload: {
    phonics,
  },
  type: INIT_SPELLING,
})

const playPhonics = () => ({
  type: PLAY,
})

const playNext = () => ({
  type: NEXT,
})


const initialState = {
  letters: [],
  playNext,
  playPhonics,
  playState: 0,
  playing: null,
  sizes: [],
  soundList: [],
}

const calculateSizes = pair => (
  Object.keys(pair[0]).length + 1
)

const createLetters = alphabets => (a, letter) => {
  if (letter === ' ') {
    return {
      ...a,
      [`letter-${size(a)}-space`]: {
        id: `letter-${size(a)}-space`,
        slug: 'letter-space',
        sound: {
          id: 'space-sound',
          srcEn: '',
          title: ' ',
        },
        titleEn: ' ',
      },
    }
  }
  if (alphabets[letter]) {
    return {
      ...a,
      [`letter-${size(a)}-${letter}`]: alphabets[letter],
    }
  }
  return {
    ...a,
  }
}

const pickLetters = alphabet => (a, phonic) => {
  const { title } = phonic
  const alphabets = keyBy(alphabet, 'titleEn')
  const titleArray = title.toLowerCase().replace(/'/, '').trim().split('')
  const letters = titleArray.reduce(createLetters(alphabets), {})
  return [
    ...a,
    letters,
    phonic,
  ]
}

const createSubSoundList = (a, letter) => {
  if (letter.sound.srcEn) {
    return {
      ...a,
      count: a.count + 1,
      subSoundList: a.subSoundList.concat(a.count),
    }
  }
  return { ...a, count: a.count + 1 }
}

const createSoundList = (a, letters) => {
  if (letters.id && letters.src) {
    return {
      ...a,
      count: a.count + 1,
      soundList: a.soundList.concat(a.count),
    }
  }
  if (letters.id) {
    return {
      ...a,
      count: a.count + 1,
    }
  }
  const { subSoundList, count } = reduce(letters, createSubSoundList,
    { count: a.count, subSoundList: [] })
  return {
    ...a,
    count,
    soundList: a.soundList.concat(...subSoundList),
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_SPELLING: {
      const { alphabetEn, phonics, word } = payload.phonics
      const letters = phonics.reduce(pickLetters(alphabetEn), [])
      const sizes = chunk(letters, 2).map(pair => calculateSizes(pair))
      const { soundList } = reduce(letters, createSoundList, { count: 0, soundList: [] })
      if (word) {
        const playing = 0
        return {
          ...initialState,
          letters,
          playing,
          sizes,
          soundList,
        }
      }
      return { ...initialState, letters, sizes, soundList }
    }
    case PLAY: {
      const { soundList } = state
      const playState = 0
      const playing = soundList[playState]
      return { ...state, playState, playing }
    }
    case NEXT: {
      const { playState, soundList } = state
      const toPlay = playState + 1
      const playing = toPlay === soundList.length ? null : soundList[toPlay]
      return { ...state, playState: toPlay, playing }
    }
    default: {
      return state
    }
  }
}

export {
  initSpelling,
  playPhonics,
  playNext,
}

export default reducer
