import React from 'react'
import { bool, shape, string } from 'prop-types'
import round from 'lodash/round'

import entityShape from 'src/shapes/entity'
import Card from 'src/components/Card'
import Div from 'src/components/Div'
import ImageOrPlaceholder from 'src/components/ImageOrPlaceholder'
import Img from 'src/components/Img'
import Icon from 'src/components/Icon'

const playButtonOrTitle = (audio, title) => {
  if (audio) return (<div className="px3 blue"><Icon icon="volume-up" /></div>)

  return (<div>{title}</div>)
}

const isBold = ({ audio, bold }) => {
  if (audio && (bold === undefined)) return true
  if (audio && (bold === false)) return false
  return audio || bold
}

/**
 *  Card content by type
 */
const cardType = ({
  audio,
  bg,
  children,
  cover,
  m,
  cm,
  mm,
  english,
  entity,
  image,
  play,
  rounded,
  thai,
  imageSrc,
  bold,
  withPartOfSpeech,
}) => {
  if (children) return children
  if (image) return <ImageOrPlaceholder entity={entity} cover={cover} bg={bg} rounded={rounded} />
  if (imageSrc) return <Img src={imageSrc} cover={cover} bg={bg} rounded={rounded} />
  if (play) return playButtonOrTitle(audio, entity.titleEn)
  if (thai && audio) return <div className="bold">{entity.titleTh}</div>
  if (english && audio) {
    return (
      <Div>
        <Div bold={isBold({ audio, bold })}>
          {entity.titleEn}
        </Div>
        {
          withPartOfSpeech && entity.partOfSpeech && !entity.partOfSpeech.includes('none') && (
            <Div
              blue
              style={{
                fontSize: '2rem',
                paddingBottom: '1rem',
              }}
            >
              { entity.partOfSpeech.join(' / ') }
            </Div>
          )
        }
      </Div>
    )
  }
  if (thai) return entity.titleTh
  if (english) return entity.titleEn
  if (m) return `${entity.title} m`
  if (cm) return `${round(entity.title * 100)} cm`
  if (mm) return `${round(entity.title * 1000)} mm`
  return entity.title
}

cardType.propTypes = {
  audio: bool.isRequired,
  bg: string,
  bold: bool,
  children: shape(),
  cm: bool,
  cover: bool,
  english: bool.isRequired,
  entity: shape(entityShape).isRequired,
  image: bool.isRequired,
  imageSrc: string.isRequired,
  m: bool,
  mm: bool,
  play: bool.isRequired,
  rounded: bool,
  thai: bool.isRequired,
}

cardType.defaultProps = {
  bg: undefined,
  bold: undefined,
  children: null,
  cm: false,
  cover: false,
  m: false,
  mm: false,
  rounded: false,
}

/**
 * Render Card
 *  - KeyPress to play the sound
 *  - with cardType by type
 *  - ID for draggable cards
 */
const renderCard = ({ entity, ...rest }) => (
  <Card id={entity.id} {...rest}>
    {cardType({ entity, ...rest })}
  </Card>
)

renderCard.defaultProps = {
  onKeyPress: null,
}

renderCard.propTypes = {
  audioNew: bool.isRequired,
  english: bool.isRequired,
  entity: shape(entityShape).isRequired,
  image: bool.isRequired,
  imageSrc: string.isRequired,
  onKeyPress: shape(),
  play: bool.isRequired,
  thai: bool.isRequired,
}

export default renderCard
