import React from 'react'
import { object, string, bool } from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'

import { lessonsPath } from 'src/lib/routes'
import ErrorMessage from 'src/components/ErrorMessage'
import errorReporter from 'src/lib/errorReporter'
import Module from 'src/components/Module'

const propTypes = {
  chapter: object.isRequired,
  chapterId: string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  homework: bool,
  homeworkSessionId: string,
  identifier: string.isRequired,
  lesson: object.isRequired,
  moduleId: string.isRequired,
}

const defaultProps = {
  homework: false,
}

const Chapter = ({
  chapter,
  chapterId,
  homework,
  homeworkSessionId,
  identifier,
  lesson,
  moduleId,
  started,
  history,
  onSlideSelect,
}) => {
  if (!chapter) {
    return history.push(lessonsPath())
  }
  const { slug: lessonSlug } = lesson
  const { meta, ...restChapter } = chapter
  return (
    <Module
      key={chapterId}
      {...{
        ...meta,
        ...restChapter,
        chapterId,
        homework,
        homeworkSessionId,
        identifier,
        lesson,
        lessonSlug,
        moduleId,
        onSlideSelect,
        started,
      }}
    />
  )
}

Chapter.propTypes = propTypes
Chapter.defaultProps = defaultProps

const ChapterWithErrorReporter = errorReporter(
  withRouter(Chapter),
  <ErrorMessage message={t('error.inChapter')} report />,
)

export default ChapterWithErrorReporter
