import addPhonicSound from 'src/lib/addPhonicSound'

const addPhonicsInPlaces = ({ places, entitiesById, targetId, card }, place) => {
  if (targetId === place.id) {
    const initPhonics = place.initPhonics || []
    const expectPhonic = place.word
    const { newPhonics } = initPhonics.reduce(addPhonicSound,
      {
        counter: 0,
        newPhonics: [],
        phonic: entitiesById[card.entityId],
        target: expectPhonic,
      })
    return (
      {
        card,
        entitiesById,
        places: {
          ...places,
          [place.id]: {
            ...place,
            phonics: newPhonics,
          },
        },
        targetId,
      }
    )
  }
  return (
    {
      card,
      entitiesById,
      places: {
        ...places,
      },
      targetId,
    }
  )
}

export default addPhonicsInPlaces
