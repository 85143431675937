import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Registration from 'src/pages/Registration'
import RegistrationDone from 'src/pages/Registration/RegistrationDone'
import RegistrationComplete from 'src/pages/Registration/RegistrationComplete'
import NoCurrentUser from 'src/pages/CurrentUser/NoCurrentUser'
import RedirectToHomePage from 'src/pages/Login/RedirectToHomePage'
import TheLayout from 'src/pages/TheLayout'

const path = '/registration'
const pathDone = '/registration/done'
const pathComplete = '/registration/complete'

const RegistrationRoute = () => (
  <TheLayout>
    <NoCurrentUser>
      <Switch>
        <Route exact path={path}>
          <Registration />
        </Route>
        <Route exact path={pathDone}>
          <RegistrationDone />
        </Route>
        <Route exact path={pathComplete}>
          <RegistrationComplete />
        </Route>
      </Switch>
    </NoCurrentUser>
    <RedirectToHomePage />
  </TheLayout>
)

export { path, pathDone }
export default RegistrationRoute
