import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'i18next'
import Button from 'src/components/Button'
import AcademicTerm from 'src/pages/SchoolSettings/AcademicYears/AcademicTerms/AcademicTerm'

const propTypes = {
  academicYear: object.isRequired,
  createTerm: func.isRequired,
  deleteTerm: func.isRequired,
  updateTerm: func.isRequired,
}

const AcademicTerms = ({ academicYear, createTerm, updateTerm, deleteTerm }) => {
  const academicTerms = academicYear.academicTerms || []

  const handleCreate = () => {
    const { startedOn, finishedOn, id } = academicYear
    createTerm({
      variables: {
        finishedOn,
        startedOn,
        yearId: id,
      },
    })
  }
  const handleDelete = id => deleteTerm({ variables: { id } })
  const handleUpdate = term => updateTerm({ variables: { ...term } })

  return (
    <div className="academic-terms-holder">
      <p>
        <strong>
          {t('school.settings.academicTerms')}
        </strong>
      </p>
      {academicTerms.map(term => (
        <AcademicTerm
          {...term}
          key={term.id}
          onTermChange={term => handleUpdate(term)}
          onTermRemove={() => handleDelete(term.id)}
        />
      ))}
      <Button onClick={handleCreate}>
        {t('shared.add')}
      </Button>
    </div>
  )
}

AcademicTerms.propTypes = propTypes

export default AcademicTerms
