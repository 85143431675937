import React, { Fragment } from 'react'
import { t } from 'i18next'
import { bool, string, shape, arrayOf, func } from 'prop-types'
import { Link } from 'react-router-dom'
import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import Checkbox from 'src/components/Checkbox'
import Div from 'src/components/Div'
import Widget from 'src/components/Widget'
import allPresentMutation from 'src/mutations/StudentList/AllPresent.gql'
import toggleAttendanceQuery from 'src/mutations/StudentList/ToggleAttendance.gql'
import setAttendanceStatusQuery from 'src/mutations/StudentList/SetStatus.gql'
import Hover from 'src/utilities/Hover'
import attendanceStatus from 'src/constants/attendanceStatus'
import UserCardScanner from 'src/components/UserCardScanner'
import withToast from 'src/lib/withToast'
import StudentCard from 'src/components/StudentList/StudentCard'

const propTypes = {
  allPresent: func.isRequired,
  attendance: bool,
  setAttendanceStatus: func.isRequired,
  studentPath: func.isRequired,
  students: arrayOf(shape()).isRequired,
  toggleAttendance: func.isRequired,
  userId: string,
  year: shape().isRequired,
}

const defaultProps = {
  attendance: false,
  userId: undefined,
}

const StudentList = ({
  allPresent,
  attendance,
  toggleAttendance,
  setAttendanceStatus,
  students,
  studentPath,
  userId,
  year,
}) => (
  <Widget title={{ ...year }.title} icon="users">
    {
      attendance && (
        <Div p1 mxn2 flex justify="between">
          <div>
            <Checkbox
              value={filter(students, 'present').length === students.length}
              onClick={() => allPresent({ variables: { id: year.id } })}
            />
            {t('attendance.allPresent')}
          </div>
          <Div mr2>
            <UserCardScanner
              onScan={id => withToast({
                error: (err) => {
                  const { networkError: { statusCode } = {} } = err || {}
                  if (statusCode === 404) return t('shared.notFound.user')
                  return t('shared.error')
                },
                success: ({ name, year }) => `${name} (${year}): ${t('attendance.status.present')}`,
              })(
                async () => {
                  const { data: { setAttendanceStatus: { user: {
                    fullName: name, year: { title: year },
                  } } } } = await setAttendanceStatus({ variables: { id, status: 'present' } })
                  return { name, year }
                },
              )}
            />
          </Div>
        </Div>
      )
    }
    {
      students.length
        ? sortBy(students, 'identifier')
          .map((user) => {
            const {
              id,
              slug,
              identifier,
              name,
              present,
              attendanceStatus: status,
            } = user
            return (
              <Hover
                hoverStyle={{ top: 0 }}
                key={id}
                onHover={(
                  <StudentCard
                    user={user}
                    left={attendance}
                    year={year.title}
                  />
                )}
              >
                <Div
                  flex
                  key={id}
                  align="center"
                  className={`mxn1 ${(id === userId || slug === userId) && 'selected'}`}
                >
                  {
                    attendance && (
                      <Fragment>
                        <Checkbox
                          onClick={() => toggleAttendance({ variables: { id } })}
                          value={present}
                        />
                        <select
                          style={{ width: '120px' }}
                          onChange={ev => setAttendanceStatus({
                            variables: {
                              id,
                              status: ev.target.value,
                            },
                          })}
                          value={status}
                        >
                          {attendanceStatus
                            .map(({ id, emoji }) => (
                              <option
                                key={id}
                                value={id}
                              >
                                {emoji} {id ? t(`attendance.status.${id}`) : null}
                              </option>
                            ))}
                        </select>
                      </Fragment>
                    )}
                  <Div auto flex>
                    <Link
                      to={studentPath(id, slug)}
                      className="p1 button-hover rounded col-12"
                    >
                      <Div flex auto>
                        <Div flex col-8>
                          {name}
                        </Div>
                        <Div flex col-4 justify-center>
                          {identifier}
                        </Div>
                      </Div>
                    </Link>
                  </Div>
                </Div>
              </Hover>
            )
          })
        : <Div p1 center className="gray">{t('year.empty')}</Div>
    }
  </Widget>
)

StudentList.propTypes = propTypes
StudentList.defaultProps = defaultProps

export default compose(
  graphql(toggleAttendanceQuery, { name: 'toggleAttendance' }),
  graphql(setAttendanceStatusQuery, { name: 'setAttendanceStatus' }),
  graphql(allPresentMutation, { name: 'allPresent' }),
)(StudentList)
