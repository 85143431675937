import React from 'react'
import { bool, string } from 'prop-types'
import Img from 'src/components/Img'

const propTypes = {
  correct: bool,
  effectImage: string,
  secondEffectImage: string,
}

const defaultProps = {
  correct: null,
  effectImage: null,
  secondEffectImage: null,
}

const EffectImage = ({ correct, effectImage, secondEffectImage }) => {
  if (correct) return <Img rounder src={effectImage} />
  if (correct === false) {
    return <Img rounder src={secondEffectImage || effectImage} />
  }
  return <Img rounder src={effectImage} />
}

EffectImage.propTypes = propTypes
EffectImage.defaultProps = defaultProps

export default EffectImage
