import React from 'react'
import { arrayOf, bool, element, func, oneOfType, string } from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const childType = oneOfType([element, string])
const propTypes = {
  after: bool,
  before: bool,
  children: oneOfType([childType, arrayOf(childType)]).isRequired,
  disabled: bool,
  icon: string,
  onClick: func,
  to: string,
}

const defaultProps = {
  after: undefined,
  before: undefined,
  disabled: false,
  icon: undefined,
  onClick: () => {
  },
  to: undefined,
}

const Span = props => <span {...props} />
/* eslint-disable-next-line jsx-a11y/anchor-has-content */
const A = props => <a {...props} />

const ActionLink = ({ children, disabled, onClick, to, icon, before, after }) => {
  // eslint-disable-next-line no-nested-ternary
  const Wrapper = disabled ? Span : (to ? Link : A)
  const className = `inline-block rounded px1 py ${disabled ? 'gray' : 'bg-blue white button-hover cursor-pointer'}`
  const prevnextBtnClassName = `flex flex-row align-center inline-block rounded px1 py1  ${disabled ? 'gray' : 'bg-blue white button-hover cursor-pointer'}`
  return (
    <Div flex flex-column justify-center>
      <Wrapper
        to={to}
        onClick={!disabled ? onClick : undefined}
        className={icon === 'arrow-left' || icon === 'arrow-right' ?
          prevnextBtnClassName : className}
      >
        {icon && before && <Icon icon={icon} before={before} />}
        {children}
        {icon && after && <Icon icon={icon} after={after} />}
      </Wrapper>
    </Div>
  )
}

ActionLink.propTypes = propTypes
ActionLink.defaultProps = defaultProps

export default ActionLink
