import { arrayOf, shape, string, bool } from 'prop-types'

const dateType = string
const eventType = shape()

const calendarEventShape = {
  academicTerm: bool,
  endDate: dateType,
  event: eventType,
  holiday: bool,
  startDate: dateType,
}

const dayModifierShape = {
  className: string,
  events: arrayOf(eventType),
  tooltips: arrayOf(string),
}

export {
  dayModifierShape,
  calendarEventShape,
}
