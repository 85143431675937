import React from 'react'
import SpeechRecognitionQAContainer from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer/SpeechRecognitionQAContainer'

const demoData = {
  entities: [
    'phrase-what-is-he-doing',
    'phrase-he-is-working',
    'phrase-what-is-she-doing',
    'phrase-she-is-washing-the-dishes',
    'phrase-what-are-they-doing',
    'phrase-they-are-eating-lunch',
    'phrase-what-is-he-doing',
    'phrase-he-is-watering-the-plants',
    'phrase-what-is-she-doing',
    'phrase-she-is-cooking-dinner',
    'phrase-what-are-they-doing',
    'phrase-they-are-reading-books',
  ],
}

export const SpeechRecognitionProvideAnswers = props => <SpeechRecognitionQAContainer {...props} />

export const SpeechRecognitionHideAnswer = props => <SpeechRecognitionQAContainer hide {...props} />

export const SpeechRecognitionProvideQuestions = props => (
  <SpeechRecognitionQAContainer asking {...props} />
)

export const SpeechRecognitionHideQuestion = props => (
  <SpeechRecognitionQAContainer asking hide {...props} />)

SpeechRecognitionProvideAnswers.demoData = demoData
SpeechRecognitionHideAnswer.demoData = demoData
SpeechRecognitionProvideQuestions.demoData = demoData
SpeechRecognitionHideQuestion.demoData = demoData
