import React, { Component } from 'react'
import { func, shape } from 'prop-types'
import gamePropTypes from 'src/shapes/Game'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import * as gameActions from 'src/modules/ListenAndOrderSentences/reducer'
import ListenAndOrderSentences from 'src/modules/ListenAndOrderSentences/ListenAndOrderSentences'

const propTypes = {
  ...gamePropTypes,
  cards: shape().isRequired,
  lines: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

class ListenAndOrderSentencesContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      cards,
      completed,
      lines,
      mark,
      moveCard,
      places,
      retry,
      started,
    } = this.props
    return (
      <Game {...{ actualEntities, completed, init: () => this.init(), mark, retry }}>
        <ListenAndOrderSentences
          autoPlay={started}
          cards={cards}
          lines={lines}
          moveCard={moveCard}
          places={places}
          completed={completed}
        />
      </Game>
    )
  }
}

ListenAndOrderSentencesContainer.propTypes = propTypes
ListenAndOrderSentencesContainer.demoData = {
  entities: [
    'phrase-this-is-my-family',
    'phrase-this-is-my-father',
    'phrase-this-is-my-mother',
  ],
}

const select = state => state.listenAndOrderSentences
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { ListenAndOrderSentencesContainer }
export default connect(select, actions)(ListenAndOrderSentencesContainer)
