import React from 'react'
import { array, string } from 'prop-types'
import Div from 'src/components/Div'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import Card from 'src/components/Card'
import EntityCard from 'src/components/EntityCard'

const propTypes = {
  phonicsEn: array,
  phonicsTh: array,
  titleEn: string,
  titleTh: string,
}

const renderPhonics = phonics => (
  phonics && (
  <Div flex wrap>
    {
        phonics.map(syllable => [
          syllable.title[0] === ' ' && <Card title="_" fit />,
          <Card
            key={syllable.id}
            fit
            audio={!!(syllable.src)}
            className={syllable.src ? 'shadow' : 'border'}
            src={syllable.src}
            size={4}
            title={syllable.title}
          />,
        ])
      }
  </Div>
  ))

const Entity = ({
  slug,
  titleEn,
  titleTh,
  phonicsEn,
  phonicsTh,
  meaning,
  meta,
  image,
  sound,
  partOfSpeech,
}) => (
  <Div id={slug} col={12} rounded px={1} py={1} mb={1} bg="white">
    <Div flex gutter={1}>
      <Div col={2} px={1}>
        {
          image
            ? <EntityCard image entity={{ image }} />
            : <div className="p2 bg-orange white center bold rounded">Missing Image</div>
        }
      </Div>
      <Div col={4} px={1}>
        {
          titleEn && (
          <Card
            fit
            audio={!!(sound && sound.srcEn)}
            className={sound && sound.srcEn ? 'shadow' : 'border'}
            src={sound && sound.srcEn}
            title={titleEn}
            whiteSpace="pre-line"
            size={3}
          />
          )
        }
        {renderPhonics(phonicsEn)}
        <p>
          {
          meaning && meaning.titleEn && (
          <Card
            fit
            audio={!!(meaning.sound && meaning.sound.srcEn)}
            className={meaning.sound && meaning.sound.srcEn ? 'shadow regular' : 'border regular'}
            src={meaning.sound && meaning.sound.srcEn}
            title={meaning.titleEn}
            size={4}
            whiteSpace="pre-line"
          />
          )}
        </p>
        {!isEmpty(meta) && map(meta, (values, key) => (
          <div>
            <p style={{ marginBottom: 0 }}>{key}</p>
            <ul style={{ marginLeft: '2rem' }}>
              {map(values, value => (
                <li>{value}</li>
              ))}
            </ul>
          </div>
        ))}
      </Div>
      <Div col={4} px={1}>
        {
          titleTh && (
          <Card
            fit
            audio={!!(sound && sound.srcTh)}
            className={sound && sound.srcTh ? 'shadow' : 'border'}
            src={sound && sound.srcTh}
            title={titleTh}
            size={3}
          />
          )
        }
        {renderPhonics(phonicsTh)}
        <p>
          {
            meaning && meaning.titleTh && (
            <Card
              fit
              audio={!!(meaning.sound && meaning.sound.srcTh)}
              className={meaning.sound && meaning.sound.srcTh ? 'shadow regular' : 'border regular'}
              src={meaning.sound && meaning.sound.srcTh}
              title={meaning.titleTh}
              size={4}
            />
            )}
        </p>
        {!isEmpty(partOfSpeech) && (
          <div>
            <p style={{ marginBottom: 0 }}>Part of Speech</p>
            <ul style={{ marginLeft: '2rem' }}>
              {partOfSpeech.map(value => (
                <li key={value}>{value}</li>
              ))}
            </ul>
          </div>
        )}
      </Div>
    </Div>
  </Div>
)

Entity.propTypes = propTypes
export default Entity
