import React from 'react'
import { bool, shape, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import TextFit from 'src/components/TextFit'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import Icon from 'src/components/Icon'
import Img from 'src/components/Img'
import createHighlightSentence from 'src/components/AdaptiveCard/createHighlightSentence'
import LabelWrapper from 'src/components/AdaptiveCard/LabelWrapper'

/**
 *  Card content by type
 */
const isBold = ({ audio, bold }) => {
  if (audio && (bold === undefined)) return true
  if (audio && (bold === false)) return false
  return audio || bold
}

const propTypes = {
  audio: bool.isRequired,
  bg: string,
  bold: bool,
  children: shape(),
  cm: bool,
  cover: bool,
  english: bool.isRequired,
  entity: shape(entityShape),
  image: bool.isRequired,
  imageSrc: string,
  m: bool,
  markdown: bool,
  meaning: bool,
  mm: bool,
  play: bool.isRequired,
  rounded: bool,
  thai: bool.isRequired,
  withPartOfSpeech: bool,
}

const defaultProps = {
  align: 'center',
  bg: undefined,
  bold: undefined,
  children: null,
  cm: false,
  cover: false,
  entity: undefined,
  fallbackEnglish: false,
  height: undefined,
  justify: 'center',
  label: null,
  labelPx: null,
  m: false,
  markdown: false,
  max: undefined,
  meaning: null,
  min: undefined,
  mm: false,
  rounded: false,
  width: undefined,
  withPartOfSpeech: null,
}

const CardType = ({
  absolutelyPositionedLabel,
  audio,
  bg,
  bold,
  children,
  cm,
  cover,
  english,
  entity,
  highlight,
  image,
  imageSrc,
  label,
  labelPx,
  m,
  markdown,
  meaning,
  mm,
  play,
  rounded,
  thai,
  title,
  withPartOfSpeech,
}) => {
  const entityTitle = ({ titleEn, titleTh }) => (thai && titleTh) || (english && titleEn)
  if (play) return <div className="px3 blue"><Icon icon="volume-up" /></div>
  if (children) return children
  if (title) return <Div preWrap>{title}</Div>
  if (highlight && entity) {
    return (
      // eslint-disable-next-line
      <div dangerouslySetInnerHTML={{__html: createHighlightSentence(entity.titleEn, highlight.titleEn)}} />
    )
  }
  if (image) return <EntityImage entity={entity} cover={cover} bg={bg} rounded={rounded} />
  if (imageSrc) return <Img src={imageSrc} cover={cover} bg={bg} rounded={rounded} />
  if (thai && meaning && audio) return <Div bold preWrap>{entity.meaning.titleTh}</Div>
  if (english && meaning && audio) {
    return (
      <Div className="bold pre-wrap">{entity.meaning.titleEn}</Div>
    )
  }
  if (thai && meaning) return <Div bold={bold} preWrap>{entity.meaning.titleTh}</Div>
  if (english && meaning) {
    return (
      <Div bold={bold} preWrap>{entity.meaning.titleEn}</Div>
    )
  }
  const labelProps = entity && { absolute: absolutelyPositionedLabel, label, labelPx, markdown, title: entityTitle(entity) }
  if (thai && audio) {
    return (
      <Div bold preWrap>
        <LabelWrapper {...labelProps} />
      </Div>
    )
  }
  if (english && audio) {
    return (
      <div>
        <Div bold={isBold({ audio, bold })} preWrap>
          <LabelWrapper {...labelProps} />
        </Div>
        {
          withPartOfSpeech && entity.partOfSpeech && !entity.partOfSpeech.includes('none') &&
            entity.partOfSpeech.length !== 0 && (
            <Div flex blue height={2}>
              <TextFit>
                { entity.partOfSpeech.join(' / ') }
              </TextFit>
            </Div>
          )
        }
      </div>
    )
  }
  if (thai) {
    return (
      <div bold={bold} preWrap>
        <LabelWrapper {...labelProps} />
      </div>
    )
  }
  if (english) {
    return (
      <div>
        <Div bold={bold} preWrap>
          <LabelWrapper {...labelProps} />
        </Div>
        {
          withPartOfSpeech && entity.partOfSpeech && !entity.partOfSpeech.includes('none') &&
            entity.partOfSpeech.length !== 0 && (
            <Div flex blue height={2}>
              <TextFit>
                { entity.partOfSpeech.join(' / ') }
              </TextFit>
            </Div>
          )
        }
      </div>
    )
  }
  if (m) return `${entity.title} m`
  if (cm) return `${Math.round(entity.title * 100)} cm`
  if (mm) return `${Math.round(entity.title * 1000)} mm`
  return entity.title
}

CardType.propTypes = propTypes
CardType.defaultProps = defaultProps
export default CardType
