import React from 'react'
import { bool, number, object, string, func } from 'prop-types'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import AdaptiveCard from 'src/components/AdaptiveCard'

const propTypes = {
  clickMinus: func,
  clickPlus: func,
  correct: bool,
  entitiesById: object,
  id: string,
  index: number,
  size: number,
}

const colorSelecting = {
  0: 'pink-cream ',
  1: 'blue-cream',
}

const Bar = ({ id, index, size }) => (
  <Div flex column my>
    {
      [...Array(size).keys()].reverse().map(level => (
        <Div key={`${id}-${level}`} flex justify-center>
          <Card
            bg={colorSelecting[index % 2]}
            className="mx animated bounceIn shadow col-8"
            fit
            key={`${id}-${level}`}
            m={false}
            size={4}
            style={{ border: '1px solid white', marginBottom: '-1px' }}
            title={level + 1}
            col-8
          />
        </Div>
      ))
    }
  </Div>
)

const Control = ({ correct, clickPlus, clickMinus, entitiesById, id }) => (
  <Div flex px>
    <Card
      className="my"
      correct={correct}
      fit
      m={false}
      onClick={() => clickPlus({ id })}
      size={2}
      title="+"
    />
    <AdaptiveCard
      audio
      correct={correct}
      english
      entity={entitiesById[id]}
      height={3.8}
      minHeight={false}
    />
    <Card
      className="my"
      correct={correct}
      fit
      m={false}
      onClick={() => clickMinus({ id })}
      size={2}
      title="-"
    />
  </Div>
)

const ControlBar = ({ correct, clickPlus, clickMinus, index, id, entitiesById, size }) => (
  <Div auto flex column justify-end relative z2>
    <Bar id={id} index={index} size={size} />
    <Control
      clickMinus={clickMinus}
      clickPlus={clickPlus}
      correct={correct}
      entitiesById={entitiesById}
      id={id}
    />
  </Div>
)

ControlBar.propTypes = propTypes

export { Bar, Control }
export default ControlBar
