const numberOfWords = phrase => phrase.split(' ').length

const addArrayToPhonics = ({ phonics, newLocalPhonics, placePhonic }) => {
  phonics.forEach((phonicTarget, i) => {
    if (i === 0) {
      newLocalPhonics.push({ ...placePhonic, id: phonicTarget.id, src: phonicTarget.src })
    } else {
      newLocalPhonics.push(phonicTarget)
    }
  })
  return newLocalPhonics
}

const returnResultPhoincsMoreThanOne = ({
  targetArray,
  placePhonic,
  counter,
  newPhonics,
  phonic,
  target,
  phonics,
  newLocalPhonics,
}) => {
  if (targetArray.indexOf(placePhonic.title) === -1) {
    return {
      counter,
      newPhonics: newPhonics.concat(placePhonic),
      phonic,
      target,
    }
  }
  if (placePhonic.title === phonic.titleEn.split(' ')[0]) {
    return {
      counter,
      newPhonics: addArrayToPhonics({ newLocalPhonics, phonics, placePhonic }),
      phonic,
      target,
    }
  }
  return {
    counter,
    newPhonics,
    phonic,
    target,
  }
}

const returnResultPhonicIsOne = ({
  targetArray,
  placePhonic,
  counter,
  newPhonics,
  phonic,
  target,
  src,
  id,
}) => {
  if (targetArray.indexOf(placePhonic.title) === -1) {
    return {
      counter,
      newPhonics: newPhonics.concat(placePhonic),
      phonic,
      target,
    }
  }
  if (counter < 1) {
    return {
      counter: (counter + 1),
      newPhonics: newPhonics.concat({ ...placePhonic, id, src }),
      phonic,
      target,
    }
  }
  return {
    counter,
    newPhonics,
    phonic,
    target,
  }
}

const returnResultTargetAndPhonicsMoreThanOne = ({
  counter,
  id,
  newLocalPhonics,
  newPhonics,
  phonic,
  phonics,
  placePhonic,
  src,
  target,
  targetArray,
}) => {
  if (numberOfWords(phonic.titleEn) > 1) {
    return returnResultPhoincsMoreThanOne({
      counter,
      newLocalPhonics,
      newPhonics,
      phonic,
      phonics,
      placePhonic,
      target,
      targetArray,
    })
  }
  return returnResultPhonicIsOne({
    counter,
    id,
    newPhonics,
    phonic,
    placePhonic,
    src,
    target,
    targetArray,
  })
}

const returnResultTargetMoreThanOnePhonicIsOne = ({
  counter,
  id,
  newPhonics,
  phonic,
  placePhonic,
  src,
  target,
  targetArray,
}) => {
  if (targetArray.indexOf(placePhonic.title) === -1) {
    return {
      counter,
      newPhonics: newPhonics.concat(placePhonic),
      phonic,
      target,
    }
  }
  if (counter < 1) {
    return {
      counter: (counter + 1),
      newPhonics: newPhonics.concat({ ...placePhonic, id, src }),
      phonic,
      target,
    }
  }
  return {
    counter,
    newPhonics,
    phonic,
    target,
  }
}

const returnResultTargetIsOneAndPhonicIsOne = ({
  counter,
  id,
  newLocalPhonics,
  newPhonics,
  phonic,
  phonics,
  placePhonic,
  src,
  target,
}) => {
  if (numberOfWords(phonic.titleEn) > 1) {
    if (target !== placePhonic.title) {
      return {
        counter,
        newPhonics: newPhonics.concat(placePhonic),
        phonic,
        target,
      }
    }
    return {
      counter,
      newPhonics: addArrayToPhonics({ newLocalPhonics, phonics, placePhonic }),
      phonic,
      target,
    }
  }
  return {
    counter,
    newPhonics: placePhonic.title !== target
      ? newPhonics.concat(placePhonic)
      : newPhonics.concat({ ...placePhonic, id, src }),
    phonic,
    target,
  }
}

const addPhonicSound = ({ counter, target, phonic, newPhonics }, placePhonic) => {
  const expectSound = phonic.sound || {}
  const { id } = expectSound
  const newLocalPhonics = newPhonics
  const phonics = phonic.phonicsEn
  const src = expectSound.srcEn || null
  const targetArray = target.split(' ')

  if (numberOfWords(target) > 1) {
    if (numberOfWords(phonic.titleEn) > 1) {
      return returnResultTargetAndPhonicsMoreThanOne({
        counter,
        id,
        newLocalPhonics,
        newPhonics,
        phonic,
        phonics,
        placePhonic,
        src,
        target,
        targetArray,
      })
    }
    return returnResultTargetMoreThanOnePhonicIsOne({
      counter,
      id,
      newPhonics,
      phonic,
      placePhonic,
      src,
      target,
      targetArray,
    })
  }
  return returnResultTargetIsOneAndPhonicIsOne({
    counter,
    id,
    newLocalPhonics,
    newPhonics,
    phonic,
    phonics,
    placePhonic,
    src,
    target,
  })
}

export default addPhonicSound
