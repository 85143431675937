import React from 'react'
import { number, string, array } from 'prop-types'
import Div from 'src/components/Div'
import Img from 'src/components/Img'
import TwinGunImage from 'src/components/ObjectRotation/images/TwinGun.svg'
import RailGunImage from 'src/components/ObjectRotation/images/RailGun.svg'
import CrossBowImage from 'src/components/ObjectRotation/images/CrossBow.svg'
import SpaceGun from 'src/components/ObjectRotation/images/SpaceGun.svg'

const MAX_DEG = 55

const propTypes = {
  bottom: number,
  children: array,
  height: number,
  objectImage: string,
  width: number,
}

const defaultProps = {
  bottom: -20,
  children: null,
  height: 30,
  objectImage: null,
  width: 20,
}

const IMAGES = {
  crossbow: CrossBowImage,
  railgun: RailGunImage,
  spacegun: SpaceGun,
  twin: TwinGunImage,
}

const generateDeg = (x, width) => (((x - (width / 2)) / width) * 2) * MAX_DEG

class ObjectRotation extends React.Component {
  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseMove = ({ x }) => {
    this.setState({ x })
  }

  render() {
    const {
      height: imageHeight,
      width: imageWidth,
      bottom,
      children,
      objectImage,
    } = this.props

    const { x } = { ...this.state }
    const browserWidth = window.innerWidth
    const style = {
      bottom: `${bottom}%`,
      transform: `rotate(${generateDeg(x, browserWidth)}deg)`,
      transformOrigin: 'bottom',
    }

    return (
      <Div
        absolute
        flex
        height={imageHeight}
        width={imageWidth}
        style={style}
      >
        {
          children
            ? { children }
            : <Img rounder src={IMAGES[objectImage]} />
        }
      </Div>
    )
  }
}

ObjectRotation.defaultProps = defaultProps
ObjectRotation.propTypes = propTypes

export default ObjectRotation
