import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { arrayOf, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import {
  enableSoftKeyboard,
  disableSoftKeyboard,
} from 'src/components/SoftKeyboard/reducer'
import * as gameActions from 'src/modules/ListenAndTypeWords/reducer'
import ListenAndTypeWords from 'src/modules/ListenAndTypeWords/ListenAndTypeWords'

const propTypes = {
  ...gamePropTypes,
  entities: arrayOf(
    shape(entityShape).isRequired,
  ),
}

const defaultProps = {
  entities: [],
}

class ListenAndTypeWordsContainer extends Component {
  componentDidMount() {
    this.init()
    this.props.enableSoftKeyboard()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  componentWillUnmount() {
    this.props.disableSoftKeyboard()
  }

  render() {
    const {
      actualEntities,
      answer,
      completed,
      entities,
      handleChange,
      isSoftKeyboardShow,
      mark,
      question,
      retry,
      started,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <ListenAndTypeWords
          answer={answer}
          autoPlay={started}
          completed={completed}
          entities={entities}
          handleChange={handleChange}
          question={question}
          isSoftKeyboardShow={isSoftKeyboardShow}
        />
      </Game>
    )
  }
}

const select = state => ({ ...state.listenAndTypeWords, ...state.softKeyboard })

const actions = dispatch => (
  bindActionCreators({
    ...gameActions,
    disableSoftKeyboard,
    enableSoftKeyboard,
  }, dispatch)
)

ListenAndTypeWordsContainer.propTypes = propTypes
ListenAndTypeWordsContainer.defaultProps = defaultProps
ListenAndTypeWordsContainer.demoData = {
  entities: ['father', 'dad', 'mother', 'mum', 'grandfather', 'grandpa', 'grandmother', 'grandma'],
}

export { ListenAndTypeWordsContainer }
export default connect(select, actions)(ListenAndTypeWordsContainer)
