import shuffle from 'lodash/shuffle'
import chunk from 'lodash/chunk'
import every from 'lodash/every'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'ListenAndSelect/INIT'
const CLICK = 'ListenAndSelect/CLICK'
const CLICK_AND_MARK = 'ListenAndSelect/CLICKANDMARK'
const MARK = 'ListenAndSelect/MARK'
const RETRY = 'ListenAndSelect/RETRY'

const initialState = {
  actualEntities: [],
  completed: null,
  lines: {},
}

const createCard = (entity, i) => {
  const label = i + 1
  return {
    entity,
    id: entity.id,
    label,
    selected: false,
  }
}

const checkGameCompleteness = lines => every(lines, 'correct')

const checkVariantsCompleteness = answer => (a, variant, id) => ({
  ...a,
  [id]: {
    ...variant,
    correct: variant.selected ? answer === id : undefined,
  },
})

const checkLineCompleteness = (a, line, id) => {
  const variants = reduce(line.variants, checkVariantsCompleteness(id), {})
  const correct = find(variants, 'correct')
  return {
    ...a,
    [id]: {
      ...line,
      correct,
      variants,
    },
  }
}

const createLine = ({ entities, cols }) => {
  const [entity, ...rest] = entities
  const { id } = entity
  const row = shuffle([entity].concat(shuffle(rest).slice(0, cols - 1)))
  const variants = keyBy(row.map(createCard), 'id')
  return {
    entity,
    id,
    variants,
  }
}

const clickCard = col => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: null,
    selected: card.id === col,
  },
})

const createArray = (num, callback) => (
  Array(parseInt(num, 10)).fill().map(callback)
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const
        { entities: allEntities, unitTest, cols, rows } = payload
      const entities = unitTest ? uniqShuffle(chunk(allEntities, cols))[0] : uniqShuffle(allEntities)
      const lines = keyBy(
        createArray(rows, () => createLine({
          cols: parseInt(cols, 10),
          entities,
        })),
        'id',
      )
      return {
        ...initialState,
        actualEntities: [map(lines, 'entity')[0]],
        lines,
      }
    }

    case (CLICK): {
      const
        { lines } = state
      const { row, col } = payload
      const line = lines[row]
      const variants = reduce(line.variants, clickCard(col), {})
      const newLine = {
        [line.id]: {
          ...line,
          variants,
        },
      }

      return {
        ...state,
        lines: {
          ...lines,
          ...newLine,
        },
      }
    }

    case (MARK): {
      const
        { lines } = state
      const newLines = reduce(lines, checkLineCompleteness, {})
      const completed = checkGameCompleteness(newLines)
      return {
        ...state,
        completed,
        lines: newLines,
      }
    }

    case (CLICK_AND_MARK): {
      const
        { lines } = state
      const { row, col } = payload
      const line = lines[row]
      const variants = reduce(line.variants, clickCard(col), {})
      const tempLine = {
        [line.id]: {
          ...line,
          variants,
        },
      }
      const newLines = reduce(tempLine, checkLineCompleteness, {})
      const completed = checkGameCompleteness(newLines)
      return {
        ...state,
        completed,
        lines: newLines,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({
  cols,
  entities,
  rows,
  unitTest,
}) => ({
  payload: {
    cols,
    entities,
    rows,
    unitTest,
  },
  type: INIT,
})

const onClick = ({ row, col }) => ({
  payload: { col, row },
  type: CLICK,
})

const onClickAndMark = ({ row, col }) => ({
  payload: { col, row },
  type: CLICK_AND_MARK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  onClick,
  onClickAndMark,
  retry,
}

export default reducer
