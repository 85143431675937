import React from 'react'
import { compose } from 'src/lib/redux'
import classSelectorQuery from 'src/queries/student-list/class-selector.gql'
import studentCardsQuery from 'src/queries/pages/preview/StudentCards.gql'
import gqlLoader from 'src/lib/gqlLoader'
import { previewStudentsPath } from 'src/lib/routes'
import UserCards from 'src/pages/preview/UserCards/UserCards'

export default compose(
  gqlLoader(studentCardsQuery),
  gqlLoader(classSelectorQuery),
)(({ history, sections, students, year }) => (
  <UserCards
    {...{
      sections,
      students,
      year,
      onYearChange: yearId => history.push(previewStudentsPath({ yearId })),
    }}
  />
))
