import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import map from 'lodash/map'
import take from 'lodash/take'
import tail from 'lodash/tail'
import uniqBy from 'lodash/uniqBy'
import pick from 'lodash/pick'
import find from 'lodash/find'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import shuffle from 'lodash/shuffle'
import replaceWord from 'src/lib/replaceWord'
import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'
import addSpaceBetweenPunctuation from 'src/lib/addSpaceBetweenPunctuation'
import { PHRASE_PREFIX, PARAGRAPH_PREFIX } from 'src/constants/entitySlugPrefixes'
import {
  addPhonicSound,
  createSelectedPhonics,
  updateSelectedPhonics,
} from 'src/modules/FillInMissingWords/phonicsAction'
import entitiesToChunks from 'src/modules/FillInMissingWords/entitiesToChunks'

const INIT = 'fillInMissingWords/INIT'
const MOVE = 'fillInMissingWords/MOVE'
const MARK = 'fillInMissingWords/MARK'
const RETRY = 'fillInMissingWords/RETRY'

const initialState = {
  cards: {},
  completed: null,
  entitiesById: {},
  imgSpeaker: {},
  line: {},
  phonics: [],
  placeList: [],
  places: {},
  placeSelected: 0,
  selectedPhonics: [],
}

const createWordsAndPlaces = ({ places, words }, title) => {
  if (isString(title)) return { places, words: [...words, { title }] }
  if (find(places, { title: title.word })) {
    return { places, words: [...words, { title: title.word }] }
  }
  const { word } = title
  const id = `place${size(places)}`
  return {
    places: {
      ...places,
      [id]: {
        cardId: '',
        id,
        title: word,
      },
    },
    words: [...words, { placeId: id }],
  }
}

const createVariantArray = (phrase, words) => {
  const replacedWords = words
    .reduce((parts, word) => replaceWord(parts, word, () => ({ word })), [phrase])
  const splitWords = replacedWords.map(part => (
    isString(part)
      ? addSpaceBetweenPunctuation(part).split(' ').filter(Boolean).map(v => v.trim())
      : part))
  const returnWords = flatten(splitWords)
  return returnWords
}

const filterEntityIdByImage = (a, entity) => (
  {
    ...a,
    [`place${size(a)}`]: entity.image ? entity.id : null,
  }
)

const createMergeWords = ({ isPrevTitle, prevWord, words }, word) => {
  const key = Object.keys(word)[0]
  if (key === 'placeId') {
    return {
      isPrevTitle: false,
      prevWord: '',
      words: words.concat(word),
    }
  }
  if (key === 'title') {
    const REGEX = /[.,"/#!$%^&*;:{}=\-_\][`~“()?]/
    if (REGEX.test(prevWord)) {
      return {
        isPrevTitle,
        prevWord: word.title,
        words: words.concat(word),
      }
    }
    if (isPrevTitle) {
      const lastWord = words.pop()
      const space = REGEX.test(word.title) ? '' : ' '
      const newLastWord = { title: lastWord.title.concat(`${space}${word.title}`) }
      return {
        isPrevTitle,
        prevWord: word.title,
        words: words.concat(newLastWord),
      }
    }
  }
  return {
    isPrevTitle: true,
    prevWord: word.title,
    words: words.concat(word),
  }
}

const variantsReducer = (chunk) => {
  const [variant, ...targets] = chunk
  const target = targets.map(entity => entity.titleEn)
  const variantTitle = variant.titleEn
  const phonics = variant.phonicsEn || []
  const selectedPhonics = createSelectedPhonics(chunk)
  const { words, places } = createVariantArray(variantTitle, target)
    .reduce(createWordsAndPlaces, { places: {}, words: [] })
  const completeVariant = variant.titleEn
  const { words: mergeWords } = words.reduce(createMergeWords, {
    isPrevTitle: false, words: [], prevWord: '',
  })
  const line = {
    completeVariant,
    expected: target,
    id: variant.id,
    imageEntityIds: targets.reduce(filterEntityIdByImage, {}),
    words: mergeWords,
  }
  return {
    line,
    phonics,
    places,
    selectedPhonics,
  }
}
const selectChoices = (expectedChoices, allChoices, limit) => {
  const expectedTitles = expectedChoices.map(entity => entity.titleEn)
  const expected = compact(expectedTitles
    .map(expectedTitle => find(allChoices, { titleEn: expectedTitle })))
  const other = take(
    compact(allChoices.map(choice => !expectedTitles.includes(choice.titleEn) && choice)),
    Math.max(limit - expected.length, 1),
  )
  return [...expected, ...other]
}

const selectNewPlaceSelected = ({ placeList, targetId, places }) => {
  let count = 0
  let position = placeList.indexOf(targetId)
  while (count !== placeList.length) {
    const next = position + 1
    if (next === placeList.length) {
      position = 0
      if (!places[placeList[position]].correct) {
        return position
      }
      count += 1
    } else {
      position = next
      if (!places[placeList[position]].correct) {
        return position
      }
      count += 1
    }
  }
  return 0
}

const createCheckedPlace = ({ places, newPlaces, expected, cards }, placeId) => {
  if (places[placeId].cardId) {
    return {
      cards,
      expected,
      newPlaces: {
        ...newPlaces,
        [placeId]: {
          ...places[placeId],
          correct: expected[size(newPlaces)] === cards[places[placeId].cardId].title,
        },
      },
      places,
    }
  }
  return {
    cards,
    expected,
    newPlaces: {
      ...newPlaces,
      [placeId]: {
        ...places[placeId],
      },
    },
    places,
  }
}

const createCleardStatusPlace = ({ places, newPlaces, targetId }, placeId) => (
  {
    newPlaces: {
      ...newPlaces,
      [placeId]: {
        ...places[placeId],
        correct: places[placeId].correct && placeId !== targetId ? true : null,
      },
    },
    places,
    targetId,
  }
)

const updatePhonics = ({
  sourceId,
  targetId,
  selectedPhonics,
  state,
}) => {
  const { cards, places } = moveCards(state, sourceId, targetId)
  const { newSelectedPhonics } = selectedPhonics.reduce(updateSelectedPhonics, {
    newSelectedPhonics: [],
    phonic: state.entitiesById[sourceId],
    target: state.places[targetId].title,
  })
  const oldPlace = state.places[state.cards[sourceId].placeId]
  const { newSelectedPhonics: newSwapSelectedPhonics } = oldPlace
    ? newSelectedPhonics.reduce(updateSelectedPhonics, {
      newSelectedPhonics: [],
      phonic: state.places[targetId].cardId ? state.entitiesById[state.places[targetId].cardId] : state.entitiesById[find(cards, { title: oldPlace.title }).id],
      target: oldPlace.title,
    })
    : {}
  const selectedPhonicsAddSound = oldPlace
    ? newSwapSelectedPhonics
    : newSelectedPhonics
  return {
    cards,
    newSelectedPhonics,
    places,
    selectedPhonicsAddSound,
  }
}

const getChoices = ({ entities, fixed, chunk, unitTest }) => {
  const chunkChoices = uniqBy(tail(chunk), 'id')
  if (fixed) return chunkChoices

  const allChoices = shuffle(uniqBy(entities, 'id').filter(({ slug }) => !(
    slug.startsWith(PHRASE_PREFIX) || slug.startsWith(PARAGRAPH_PREFIX)
  )))

  return selectChoices(chunkChoices, allChoices, unitTest ? 4 : 3)
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities, fixed, unitTest } = payload
      const chunks = entitiesToChunks(entities)
      const firstChunk = (unitTest ? chunks : uniqShuffle(chunks))[0]
      const currentChunk = fixed ? take(firstChunk, 2) : firstChunk
      const {
        places,
        line,
        phonics,
        selectedPhonics,
      } = variantsReducer(currentChunk)

      const choices = getChoices({ chunk: firstChunk, entities, fixed, unitTest })
      const cards = keyBy(shuffle(choices).map((entity, i) => ({
        id: entity.id,
        label: i + 1,
        title: entity.titleEn,
      })), 'id')

      const placeList = Object.keys(places)
      return {
        ...initialState,
        actualEntities: currentChunk,
        cards,
        entitiesById: keyBy(entities, 'id'),
        initPhonics: phonics,
        line,
        phonics,
        placeList,
        places,
        placeTarget: 'place0',
        selectedPhonics,
      }
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      const { line, initPhonics, selectedPhonics, entitiesById, placeList } = state
      const newLine = {
        ...line,
        correct: null,
      }

      const {
        selectedPhonicsAddSound,
        newSelectedPhonics,
        places,
        cards,
      } = updatePhonics({
        selectedPhonics,
        sourceId,
        state,
        targetId,
      })
      const { newPhonics } = initPhonics.reduce(addPhonicSound,
        {
          counter: 0,
          mark: [],
          newPhonics: [],
          selectedPhonics: selectedPhonicsAddSound,
          target: entitiesById[sourceId].titleEn.toLowerCase(),
        })
      const { newPlaces } = placeList.reduce(createCleardStatusPlace, { places, targetId })
      const newPlaceSelected = selectNewPlaceSelected({ placeList, places, targetId })
      return {
        ...state,
        cards,
        line: newLine,
        phonics: newPhonics,
        places: newPlaces,
        placeSelected: newPlaceSelected,
        placeTarget: placeList[newPlaceSelected],
        selectedPhonics: newSelectedPhonics,
      }
    }
    case MARK: {
      const { line, cards, places, placeList } = state
      const wordsForLine = words => map(pick(cards, map(pick(places, map(filter(words, 'placeId'), 'placeId')), 'cardId')), 'title')
      const isLineComplete = ({ words, expected }) => isEqual(expected, wordsForLine(words))
      const lineReducer = () => (
        {
          ...line,
          correct: isLineComplete(line),
        }
      )
      const lineCompletedReducer = () => (
        {
          ...line,
          words: [{ title: line.completeVariant }],
        }
      )
      const newLine = lineReducer()
      const completed = newLine.correct
      const { newPlaces } = placeList.reduce(createCheckedPlace,
        { cards, expected: line.expected, places })
      const completeLine = completed ? lineCompletedReducer() : newLine
      const newPlaceSelected = completed ? 0 : selectNewPlaceSelected({ placeList, places: newPlaces, targetId: 'place1' })
      return {
        ...state,
        completed,
        line: completeLine,
        places: newPlaces,
        placeSelected: newPlaceSelected,
        placeTarget: placeList[newPlaceSelected],
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities, fixed, unitTest }) => ({
  payload: {
    entities,
    fixed,
    unitTest,
  },
  type: INIT,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  moveCard,
  mark,
  retry,
}
export default reducer
