import uniqShuffle from 'src/lib/uniqShuffle'
import whitespaceEqual from 'src/lib/whitespaceEqual'
import isString from 'lodash/isString'

const INIT = 'listenAndTypeWords/INIT'
const CHANGE = 'listenAndTypeWords/CHANGE'
const MARK = 'listenAndTypeWords/MARK'
const RETRY = 'listenAndTypeWords/RETRY'

const initialState = {
  actualEntities: [],
  answer: '',
  completed: null,
  entitiesList: [],
  question: {},
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const question = uniqShuffle(entities)[0]
      return {
        ...initialState,
        actualEntities: [question],
        entities,
        question,
      }
    }
    case CHANGE: {
      const { answer } = payload
      if (isString(answer)) return { ...state, answer }
      return state
    }
    case MARK: {
      const { answer, question } = state
      const completed = whitespaceEqual(answer.toLowerCase(), question.titleEn.toLowerCase())
      return { ...state, completed }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const handleChange = answer => ({
  payload: {
    answer,
  },
  type: CHANGE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  handleChange,
  mark,
  retry,
}

export default reducer
