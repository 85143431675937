import React from 'react'
import { number, shape, bool, func } from 'prop-types'
import ObjectTarget from 'src/components/ObjectTarget'
import Decoration from 'src/components/ObjectTarget/images/decorations/Pin.svg'
import paperTarget from 'src/components/ObjectTarget/images/targets/PaperTarget.svg'
import SplashGreen from 'src/components/ObjectTarget/images/effects/SplashGreen.svg'
import SplashPink from 'src/components/ObjectTarget/images/effects/SplashPink.svg'

const propTypes = {
  animation: bool,
  cursive: bool,
  entity: shape(),
  height: number,
  mixFont: bool,
  onClick: func,
  selected: bool,
  text: bool,
  width: number,
}

const defaultProps = {
  cursive: null,
  mixFont: null,
  text: null,
}

const entityImageStyle = {
  paddingBottom: '0.5rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  paddingTop: '0.5rem',
}

const PaperTarget = props => (
  <ObjectTarget
    targetImage={paperTarget}
    effectImage={SplashGreen}
    secondEffectImage={SplashPink}
    decorateTargetImage={Decoration}
    entityImageStyle={entityImageStyle}
    {...props}
  />
)

PaperTarget.defaultProps = defaultProps
PaperTarget.propTypes = propTypes

export default PaperTarget
