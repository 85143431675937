import React from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import meanBy from 'lodash/meanBy'

import { SKILLS } from 'src/constants/menu'
import subjectShape from 'src/shapes/subject'
import icons from 'src/lib/menu/icons'

import Div from 'src/components/Div'
import Donut from 'src/components/Donut'
import { SubjectSelectorWithStyle } from 'src/selectors/SubjectSelector'
import Widget from 'src/components/Widget'
import SkillProgress from 'src/pages/StudentSkillsPage/SkillProgress'

const propTypes = {
  onSubjectChange: func.isRequired,
  subject: shape(subjectShape).isRequired,
  user: shape({
    skills: arrayOf(shape(
      {
        id: string.isRequired,
        rate: number.isRequired,
      },
    )).isRequired,
  }).isRequired,
}

const StudentProgress = ({
  subject,
  user: { skills },
  onSubjectChange,
}) => {
  const avgRate = meanBy(skills, 'rate')
  return (
    <Div flex auto relative key={subject.slug}>
      <SubjectSelectorWithStyle
        subjectId={subject.slug}
        onChange={onSubjectChange}
      />
      <Widget title={subject.title} icon={icons[SKILLS]} noPrint={false}>
        <Div flex gutter={1}>
          <Div
            col={2}
            mx={1}
            className="center"
          >
            <Donut value={avgRate} size={2} fixed={4} />
          </Div>
          <Div col={10}>
            {
              skills.map((skill, index) => (
                <SkillProgress key={skill.id} skill={skill} index={index} />
              ))
            }
          </Div>
        </Div>
      </Widget>
    </Div>
  )
}

StudentProgress.propTypes = propTypes

export default StudentProgress
