import React from 'react'
import demoData from 'src/spec/demoData'

const gqlDemoData = ({ config: { options }, props }) => (Component) => {
  const theOptions = options && options(props)
  const {
    variables,
    variables: { entities = [] } = {
      entities: props.variables.entities,
    },
  } = theOptions || {}
  const { entities: demoEntities, ...restDemoData } = demoData
  const data = { entities: entities.map(entity => demoEntities[entity]) }
  return <Component {...props} {...data} variables={variables} {...restDemoData} />
}

export default gqlDemoData
