import React, { Fragment, useContext } from 'react'
import { shape } from 'prop-types'
import { t } from 'i18next'
import compact from 'lodash/compact'
import userShape from 'src/shapes/user'
import * as menu from 'src/constants/menu'
import {
  TEACHER_SCOPE,
  SCHOOL_SCOPE,
  STUDENT_SCOPE,
  PORTAL_SCOPE,
  scopeForRole,
} from 'src/constants/routeScopes'
import DomainContext from 'src/contexts/DomainContext'
import Tab from 'src/components/Tab'
import Tabs from 'src/components/Tabs'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { path as schoolPath } from 'src/routes/school/index.js'
import { path as teacherPath } from 'src/routes/teacher/index.js'
import { path as portalPath } from 'src/routes/portal/index.js'
import StaffMenu from 'src/pages/TheLayout/Menu/StaffMenu'
import TeacherMenu from 'src/pages/TheLayout/Menu/TeacherMenu'
import StudentMenu from 'src/pages/TheLayout/Menu/StudentMenu'
import ParentMenu from 'src/pages/TheLayout/Menu/ParentMenu'

const propTypes = {
  user: shape(userShape),
}

const defaultProps = {
  user: undefined,
}

const tabPaths = {
  [PORTAL_SCOPE]: portalPath,
  [SCHOOL_SCOPE]: schoolPath,
  [TEACHER_SCOPE]: teacherPath,
}

const Menu = ({ user }) => {
  const scope = useContext(DomainContext)
  if (!user) return null
  const tabs = compact([
    user.isStaff && SCHOOL_SCOPE,
    user.isTeacher && TEACHER_SCOPE,
    user.isParent && PORTAL_SCOPE,
    user.isStudent && STUDENT_SCOPE,
  ])
  const displayTabs = tabs.length > 1
  return (
    <div className="rounded mb1 print-hide qa-menu">
      {displayTabs && (
        <Tabs>
          {tabs.map((tab) => (
            <Tab
              className={`qa-tab-${tab}`}
              key={tab}
              path={tabPaths[tab]}
              selected={tab === scope}
              outline={
                [PORTAL_SCOPE, STUDENT_SCOPE].includes(scope) || scope === null
              }
            >
              {(() => {
                switch (tab) {
                  case SCHOOL_SCOPE:
                    return (
                      <Fragment>
                        <Icon icon="building" /> {t(`${menu.STAFF}.title`)}
                      </Fragment>
                    )
                  case TEACHER_SCOPE:
                    return (
                      <Fragment>
                        <Icon icon="graduation-cap" />{' '}
                        {t(`${menu.TEACHER}.title`)}
                      </Fragment>
                    )
                  case PORTAL_SCOPE:
                    return (
                      <Fragment>
                        <Icon icon="male" /> {t('shared.parent')}
                      </Fragment>
                    )
                  case STUDENT_SCOPE:
                    return (
                      <Fragment>
                        <Icon icon="male" /> {t('shared.student')}
                      </Fragment>
                    )
                  default:
                    return null
                }
              })()}
            </Tab>
          ))}
        </Tabs>
      )}
      <Div bg-white px2 rounded>
        {(() => {
          const scopeOrDefault = scope || scopeForRole[user.roleId]
          switch (scopeOrDefault) {
            case SCHOOL_SCOPE:
              return <StaffMenu user={user} />
            case TEACHER_SCOPE:
              return <TeacherMenu user={user} />
            case PORTAL_SCOPE:
              return <ParentMenu user={user} />
            case STUDENT_SCOPE:
              return <StudentMenu user={user} />
            default:
              return null
          }
        })()}
      </Div>
    </div>
  )
}

Menu.propTypes = propTypes
Menu.defaultProps = defaultProps
export default Menu
