import createCalendarEvent from 'src/mutations/CalendarEvent/createCalendarEvent.gql'
import updateCalendarEvent from 'src/mutations/CalendarEvent/updateCalendarEvent.gql'
import deleteCalendarEvent from 'src/mutations/CalendarEvent/deleteCalendarEvent.gql'
import calendarEventsQuery from 'src/pages/CalendarPage/calendarEvents.gql'
import withToast from 'src/lib/withToast'
import { dateToday, startOf, endOf } from 'src/lib/dates'

const CREATE_EVENT = 'calendar/CREATE_EVENT'
const UPDATE_EVENT = 'calendar/UPDATE_EVENT'
const DELETE_EVENT = 'calendar/DELETE_EVENT'

const refetchCalendarEvents = ({
  startDate = startOf(dateToday(), 'year'),
  endDate = endOf(dateToday(), 'year'),
} = {}) => ({
  query: calendarEventsQuery,
  variables: {
    endDate,
    startDate,
  },
})

const serverRequest = (mutation, variables, client, refetchParams) => client.mutate(
  {
    mutation,
    refetchQueries: [refetchCalendarEvents(refetchParams)],
    variables,
  },
)

const initialState = {
  events: [],
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_EVENT: {
      return { ...state, events: state.events.concat(payload.event) }
    }
    case UPDATE_EVENT: {
      const events = state.events.map(event => (
        event.id === payload.event.id ? payload.event : event
      ))
      return { ...state, events }
    }
    case DELETE_EVENT: {
      return { ...state, events: state.events.filter(event => event.id !== payload.id) }
    }
    default: {
      return state
    }
  }
}

const createEvent = (client, refetchParams) => event => async (dispatch) => {
  withToast()(async () => {
    const {
      data: { createCalendarEvent: { calendarEvent } },
    } = await serverRequest(createCalendarEvent, event, client, refetchParams)
    dispatch({
      payload: { event: calendarEvent },
      type: CREATE_EVENT,
    })
  })
}

const updateEvent = (client, refetchParams) => event => async (dispatch) => {
  withToast()(async () => {
    const {
      data: { updateCalendarEvent: { calendarEvent } },
    } = await serverRequest(updateCalendarEvent, event, client, refetchParams)
    dispatch({
      payload: { event: calendarEvent },
      type: UPDATE_EVENT,
    })
  })
}

const deleteEvent = (client, refetchParams) => id => async (dispatch) => {
  withToast()(async () => {
    await serverRequest(deleteCalendarEvent, { id }, client, refetchParams)
    dispatch({
      payload: { id },
      type: DELETE_EVENT,
    })
  })
}

export {
  createEvent,
  updateEvent,
  deleteEvent,
}
export default reducer
