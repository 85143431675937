import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { linkTo } from 'src/lib/linkTo'

const propTypes = {
  children: arrayOf(shape({
    id: String.isRequired,
    slug: String,
  })),
  parentId: string.isRequired,
  path: string.isRequired,
}

const RedirectToFirstChild = ({ domain, path, parentId, children }) => {
  const [{ id, slug }] = children || [{}]
  const studentId = slug || id
  return studentId ? <Redirect to={linkTo(path)({ domain, parentId, studentId })} /> : null
}

RedirectToFirstChild.propTypes = propTypes

export default RedirectToFirstChild
