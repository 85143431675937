import React from 'react'
import { array, func, shape } from 'prop-types'
import alphabetIndex from 'src/lib/alphabetIndex'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import EntityCard from 'src/components/EntityCard'
import EntityImage from 'src/components/EntityImage'
import LetterLabel from 'src/components/LetterLabel'
import Place from 'src/components/Place'
import map from 'lodash/map'
import pick from 'lodash/pick'

const propTypes = {
  availableCardsId: array.isRequired,
  cards: shape().isRequired,
  entitiesById: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderCard = (entity, index) => (
  <Div column key={entity.id}>
    <LetterLabel index={index} />
    <EntityCard
      audio
      draggable
      english
      entity={entity}
      fit
      keyToPlay={alphabetIndex(index)}
      size={2}
    />
  </Div>
)

const renderPlace = (cards, entitiesById, place, moveCard, i) => {
  const { id, cardId } = place
  const card = cardId ? cards[cardId] : undefined

  return (
    <Div key={i} flex column px={1} fx={1}>
      <Place
        id={id}
        key={id}
        onDrop={moveCard}
      >
        <Div flex auto>
          <EntityImage entity={entitiesById[place.id]} />
        </Div>
        {
          card ? (
            <Div>
              <Card
                correct={card.correct}
                draggable
                fit
                id={card.id}
                title={card.titleEn}
              />
            </Div>
          ) : <Card fit title="..." />
        }
      </Place>
    </Div>
  )
}

const AgeComparison = ({ availableCardsId, cards, entitiesById, moveCard, places }) => (
  <Deck>
    <Div gutter={1} justify="center" flex>
      {
        map(pick(entitiesById, availableCardsId)).map(renderCard)
      }
    </Div>
    <Div fx={1} gutter={1} flex>
      {map(places, (place, i) => renderPlace(cards, entitiesById, place, moveCard, i))}
    </Div>
  </Deck>
)

AgeComparison.propTypes = propTypes
export default AgeComparison
