import React from 'react'
import ListenAndSelectContainer from 'src/modules/ListenAndSelect/ListenAndSelectContainer'

const defaultProps = {
  cols: 3,
  image: null,
  rows: 1,
  silent: false,
}

const ListenAndSelectFixed = props => (
  <ListenAndSelectContainer {...props} unitTest />
)

ListenAndSelectFixed.defaultProps = defaultProps
ListenAndSelectFixed.demoData = {
  cols: 3,
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
    'father',
    'mother',
    'younger-brother',
    'younger-sister',
    'father',
    'mother',
  ],
  rows: 1,
}

export default ListenAndSelectFixed
