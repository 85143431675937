import React from 'react'
import { shape, arrayOf, string, oneOfType, element, array } from 'prop-types'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import pickBy from 'lodash/pickBy'
import Dropdown, { DropdownMenu } from 'src/components/ui/Dropdown'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'

const propTypes = {
  children: oneOfType([element, array]),
  className: string,
  user: shape({
    parents: arrayOf(shape({
      email: string,
      fullName: string.isRequired,
      id: string.isRequired,
      identifier: string,
      lastLoginAt: string,
    })),
  }).isRequired,
}

const defaultProps = {
  children: null,
  className: null,
}

const ParentLogin = ({ children, className, user: { parents } }) => (
  <Dropdown className={className}>
    {children}
    {!!parents.length && (
    <DropdownMenu right>
      <Div rounded overflow-hidden shadow>
        {parents.map(({ id, email, identifier, fullName, lastLoginAt }) => (
          <Div
            key={id}
            bg-white
            bold
            button-hover
            className="qa-parent"
            cursor-pointer
            nowrap
            px2
            py1
            relative
            style={{ textAlign: 'right' }}
          >
            <Link
              to={`/login?${qs.stringify(pickBy({
                displayLogin: !(email || identifier) ? fullName : null,
                login: (email || identifier || id),
                parent: true,
                relogin: true,
              }))}`}
              className="nowrap black"
            >
              <Icon icon="male" />
              <span className="qa-name">{fullName}</span>
              <Icon
                icon="circle"
                className="red relative qa-logged-in"
                style={{
                  fontSize: '.6em',
                  left: '-0.2rem',
                  verticalAlign: 'text-top',
                  visibility: lastLoginAt ? 'hidden' : 'visible',
                }}
              />
              <Icon icon="sign-in-alt" />
            </Link>
          </Div>
        ))}
      </Div>
    </DropdownMenu>
    )}
  </Dropdown>
)

ParentLogin.propTypes = propTypes
ParentLogin.defaultProps = defaultProps

export default ParentLogin
