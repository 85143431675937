import React from 'react'
import { withApollo } from '@apollo/react-hoc'

import { bindActionCreators, connect } from 'src/lib/redux'
import loadYearEvents from 'src/pages/CalendarPage/loadYearEvents'
import { createEvent, deleteEvent, updateEvent } from 'src/pages/CalendarPage/reducer'
import DateSummary from 'src/pages/CalendarPage/DateSummary/DateSummary'

const actions = (dispatch, { client, startDate, endDate }) => bindActionCreators({
  createEvent: createEvent(client, { endDate, startDate }),
  deleteEvent: deleteEvent(client, { endDate, startDate }),
  updateEvent: updateEvent(client, { endDate, startDate }),
}, dispatch)

const DateSummaryWithActions = withApollo(connect(null, actions)(DateSummary))

export default loadYearEvents(({ year, calendarEvents, date, startDate, endDate }) => (
  <DateSummaryWithActions
    calendarEvents={calendarEvents}
    date={date}
    startDate={startDate}
    endDate={endDate}
    {...year}
  />
))
