import shuffle from 'lodash/shuffle'
import keyBy from 'lodash/keyBy'
import chunk from 'lodash/chunk'
import uniqShuffle from 'src/lib/uniqShuffle'

const initialState = {
  answers: [],
  entitiesById: {},
  selected: null,
  completed: null,
}

const INIT = 'multipleChoiceMissingWordsAudio/INIT'
const SELECT = 'multipleChoiceMissingWordsAudio/SELECT'
const RETRY = 'multipleChoiceMissingWordsAudio/RETRY'
const MARK = 'multipleChoiceMissingWordsAudio/MARK'

const init = ({ cardsSize, entities }) => ({ type: INIT, payload: { cardsSize, entities } })
const onSelectAnswer = answerId => ({ type: SELECT, payload: { answerId } })
const retry = () => ({ type: RETRY })
const mark = () => ({ type: MARK })

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { cardsSize = 3, entities } = payload
      const [audio, phrase, correct, ...incorrect] = uniqShuffle(
        (chunk(entities, cardsSize + 2)),
      )[0]
      return {
        ...initialState,
        answers: shuffle([...incorrect, correct]).map(({ id }) => id),
        audio: audio.id,
        correct: correct.id,
        entitiesById: keyBy(entities, 'id'),
        phrase: phrase.id,
      }
    }

    case SELECT: {
      const { answerId } = payload
      return {
        ...state,
        selected: answerId,
      }
    }

    case RETRY: {
      return {
        ...state,
        selected: null,
        completed: null,
      }
    }

    case MARK: {
      const { selected, correct } = state
      return {
        ...state,
        completed: selected === correct,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  onSelectAnswer,
  retry,
  mark,
}

export default reducer
