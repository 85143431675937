const TOASTR_OPTIONS = {
  newestOnTop: true,
  position: 'top-right',
  progressBar: false,
  timeOut: 3000,
  transitionIn: 'bounceInDown',
  transitionOut: 'bounceOutUp',
}

export {
  TOASTR_OPTIONS,
}
