import { t } from 'i18next'

const { warn } = console

export const translate = (text) => {
  switch (text) {
    case 'mm': return t('units.mm')
    case 'cm': return t('units.cm')
    case 'm': return t('units.m')
    case 'C': return t('units.C')

    case 'yes': return t('answer.yes')
    case 'no': return t('answer.no')

    case 'true': return t('answer.true')
    case 'false': return t('answer.false')

    default: {
      warn(`Translation missing for ${text}`)
      return `Translation missing ${text}`
    }
  }
}
