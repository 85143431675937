import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isString from 'lodash/isString'
import splitByWord from 'src/lib/splitByWord'

const replaceWord = (items, word, replaceWith) => (
  flatten(items.reduce((acc, item) => {
    if (!isString(item)) return [...acc, item]
    return splitByWord(item, word)
      .reduce((acc, next, i, arr) => compact(
        [...acc, next, i >= arr.length - 1 ? null : replaceWith()],
      ), acc)
  }, []))
)

export default replaceWord
