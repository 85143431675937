import React from 'react'
import StudentHomeworkPage from 'src/pages/StudentHomeworkPage/StudentHomeworkPageContainer'
import StudentPage from 'src/pages/PortalLayout/StudentPage'

const PortalStudentAttendance = ({ path }) => (
  <StudentPage path={path}>
    <StudentHomeworkPage />
  </StudentPage>
)

export default PortalStudentAttendance
