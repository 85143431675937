import phonicsReducer from 'src/modules/Word/Phonics/reducer'
import spellingReducer from 'src/modules/Word/Spelling/reducer'
import WordEnglish from 'src/modules/Word/WordEnglish'
import Word from 'src/modules/Word/Word'
import WordSpelling from 'src/modules/Word/WordSpelling'
import WordInLineSpelling from 'src/modules/Word/WordInLineSpelling'

export {
  phonicsReducer,
  spellingReducer,
  WordEnglish,
  WordSpelling,
  WordInLineSpelling,
}

export default Word
