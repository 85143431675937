import React from 'react'
import { string } from 'prop-types'

const Header = ({ title, image }) => {
  if (image) return <h2>{title}</h2>
  return <h1>{title}</h1>
}

Header.propTypes = {
  image: string,
  title: string.isRequired,
}

Header.defaultProps = {
  image: undefined,
}

export default Header
