import React from 'react'
import { shape } from 'prop-types'
import Slide from 'src/components/Layout/Slide'
import Div from 'src/components/Div'
import WithPlayButton from 'src/components/WithPlayButton'
import ImageOrPlaceholder from 'src/components/ImageOrPlaceholder'
import wait from 'src/lib/wait'
import entityShape from 'src/shapes/entity'
import useSequence from 'src/lib/useSequence'

const propTypes = {
  letter: shape(entityShape),
  phonics: shape(entityShape).isRequired,
}

const defaultProps = {
  letter: null,
}

const LetterSlide = ({ letter, phonics }) => {
  const steps = [
    ...(letter ? [letter, letter, letter, () => wait(1000)] : []),
    phonics, phonics, phonics,
  ]
  const [step, nextStep] = useSequence(steps)
  return (
    <Slide key={phonics.id}>
      <WithPlayButton
        key_={step}
        autoPlay={step > 0}
        entity={steps[step]}
        playNext={nextStep}
      >
        {letter && <Div flex flex-1><ImageOrPlaceholder entity={letter} /></Div>}
        <Div flex flex-1><ImageOrPlaceholder entity={phonics} /></Div>
      </WithPlayButton>
    </Slide>
  )
}

LetterSlide.propTypes = propTypes
LetterSlide.defaultProps = defaultProps

export default LetterSlide
