import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { Link } from 'react-router-dom'
import Card from 'src/components/Card'
import Div from 'src/components/Div'
import { lessonsPath } from 'src/lib/routes'
import yearShape from 'src/shapes/year'

const propTypes = {
  gradeSlug: string.isRequired,
  years: arrayOf(shape(yearShape)).isRequired,
  yearSlug: string,
}

const defaultProps = {
  yearSlug: undefined,
}

const LessonsPageSectionYears = ({ years, gradeSlug, yearSlug }) => (
  <Div className="qa-grade-years" flex mb={1}>
    {
      years.map(({ id, slug, title }) => (
        <Link key={id} to={lessonsPath({ gradeSlug, yearSlug: slug })}>
          <Card
            bold
            button
            fit
            locale="en"
            selected={slug === yearSlug}
            size={2}
            title={title}
          />
        </Link>
      ))
    }
  </Div>
)

LessonsPageSectionYears.propTypes = propTypes
LessonsPageSectionYears.defaultProps = defaultProps

export default LessonsPageSectionYears
