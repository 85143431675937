import React from 'react'
import { array, func, number, string } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import * as layoutActions from 'src/components/Layout/reducer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import Transition from 'react-transition-group/Transition'
import SlidesNavigation from 'src/components/SlidesNavigation/SlidesNavigation'

const propTypes = {
  children: array.isRequired,
  className: string,
  goToSlide: func,
  initSlides: func.isRequired,
  slideIndex: number,
}

const defaultProps = {
  className: '',
  slideIndex: 0,
}

class Slides extends React.Component {
  componentDidMount() {
    this.initSlides()
  }

  componentDidUpdate({ children }) {
    if (children.length !== this.props.children.length) {
      this.initSlides()
    }
  }

  componentWillUnmount() {
    const { initSlides } = this.props
    initSlides({ total: 0 })
  }

  getSafeChildren() {
    const { children } = this.props
    return children ? [].concat(children) : []
  }

  initSlides() {
    const { initSlides } = this.props
    const slides = this.getSafeChildren()
    initSlides({ total: slides.length })
  }

  render() {
    const { slideIndex, className, goToSlide } = this.props
    const slides = this.getSafeChildren()

    const slideToNav = (slide, slideIndex) => ({
      id: `slide-${slideIndex}`,
      onClick: () => goToSlide(slideIndex),
      title: slide.props.label || (slideIndex + 1),
    })
    const navigation = slides.map(slideToNav)
    const currentId = navigation[slideIndex] ? navigation[slideIndex].id : null

    return (
      <Deck className={className} style={{ display: 'flex' }}>
        <Div style={{ flexGrow: 1, position: 'relative' }}>
          {slides.map((slide, index) => (
            <Transition
              key={slide.key}
              timeout={200}
              in={slideIndex === index}
              mountOnEnter
              unmountOnExit
            >
              {state => (
                <Deck
                  style={{
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                  }}
                  className={cx({
                    animated: true,
                    fadeIn: state === 'entering',
                    fadeOut: state === 'exiting',
                  })}
                >
                  {slide}
                </Deck>
              )}
            </Transition>
          ))}
        </Div>
        <Div style={{ flexGrow: 0 }} mt2>
          <SlidesNavigation currentId={currentId} items={navigation} />
        </Div>
      </Deck>
    )
  }
}

Slides.propTypes = propTypes
Slides.defaultProps = defaultProps

const select = state => (
  state.layout
)

const actions = dispatch => (
  bindActionCreators(layoutActions, dispatch)
)

export { Slides }
export default connect(select, actions)(Slides)
