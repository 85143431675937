import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import ResetPasswordForm from 'src/pages/ResetPassword/ResetPasswordForm'

export const path = '/reset_password'

const PasswordRoute = () => (
  <Switch>
    <Route exact path="/reset_password" component={ResetPasswordForm} />
  </Switch>
)

export default PasswordRoute
