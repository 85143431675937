import React from 'react'
import { array, func } from 'prop-types'
import propsMaybeWithoutMeaning from 'src/lib/propsMaybeWithoutMeaning'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  entities: array.isRequired,
  initAudio: func.isRequired,
}

const defaultProps = {
  audioOnly: true,
  cardsSize: 3,
  paragraph: true,
  silent: false,
}

const MultipleChoiceAudio3 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)

  return (
    <MultipleChoiceCore
      init={() => props.initAudio({ ...props })}
      {...props}
    />
  )
}

MultipleChoiceAudio3.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'wiindow',
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'door',
    'dour',
    'doorr',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export const MultipleChoiceAudio2 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)
  return (
    <MultipleChoiceAudio3 silent cardsSize={2} {...props} />
  )
}

export const MultipleChoiceAudio2Container =
  connect(select, actions)(MultipleChoiceAudio2)

export const MultipleChoiceAudio4 = (rawProps) => {
  const props = propsMaybeWithoutMeaning(rawProps)
  return (
    <MultipleChoiceAudio3 silent cardsSize={4} {...props} />
  )
}

export const MultipleChoiceAudio4Container =
  connect(select, actions)(MultipleChoiceAudio4)

const demoData2Choices = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'paragraph-all-about-me',
    'phrase-choose-the-correct-spelling',
    'door',
    'dor',
    'paragraph-all-about-me',
    'phrase-it-is-a-mango',
    'father',
    'mother',
  ],
}

const demoData4Choices = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'windohh',
    'wiindow',
    'paragraph-all-about-me',
    'phrase-choose-the-correct-spelling',
    'door',
    'dor',
    'dour',
    'doorr',
    'paragraph-all-about-me',
    'phrase-it-is-a-mango',
    'father',
    'mother',
    'sister',
    'brother',
  ],
}

MultipleChoiceAudio2Container.demoData = demoData2Choices
MultipleChoiceAudio4Container.demoData = demoData4Choices

MultipleChoiceAudio2Container.propTypes = propTypes
MultipleChoiceAudio4Container.propTypes = propTypes

MultipleChoiceAudio3.defaultProps = defaultProps

export { MultipleChoiceAudio3 }
export default connect(select, actions)(MultipleChoiceAudio3)
