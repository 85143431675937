import React from 'react'
import { array, bool, func, string } from 'prop-types'
import { t } from 'i18next'
import { ALL } from 'src/constants/shared'
import PrefixedLink from 'src/components/PrefixedLink'
import Div from 'src/components/Div'

const propTypes = {
  gradeSlug: string,
  redirectPath: func.isRequired,
  showAll: bool,
  subjects: array,
  subjectSlug: string,
}

const defaultProps = {
  gradeSlug: '',
  sections: [],
  showAll: false,
  subjectSlug: '',
  subjects: [],
}

const SubjectFilter = ({
  redirectPath,
  gradeSlug: selectedGradeSlug,
  subjectSlug: selectedSubjectSlug,
  subjects,
  showAll,
}) => {
  const subjectAll = { slug: '', title: t(ALL) }
  const allSubjects = showAll ? [subjectAll, ...subjects] : subjects
  return (
    <Div flex wrap print-m0 gutter={1} mb={1}>
      {allSubjects.map(({ slug, title }) => (
        <Div key={slug}>
          <Div print-show bold>{selectedSubjectSlug === slug && title}</Div>
          <Div print-hide>
            <PrefixedLink
              to={redirectPath({ gradeSlug: selectedGradeSlug, subjectSlug: slug })}
              className={`
              bold px2 py1 border inline-block rounded button-hover
              ${selectedSubjectSlug === slug && 'bg-blue white'}
            `}
            >
              {title}
            </PrefixedLink>
          </Div>
        </Div>
      ))}
    </Div>
  )
}

SubjectFilter.defaultProps = defaultProps
SubjectFilter.propTypes = propTypes
export default SubjectFilter
