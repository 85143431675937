import DomainContext from 'src/contexts/DomainContext'
import { STUDENT_SCOPE } from 'src/constants/routeScopes'
import MenuSection from 'src/pages/TheLayout/Menu/MenuSection'
import { STUDENT_SECTION } from 'src/pages/TheLayout/Menu/locale/constants'
import { studentSection } from 'src/lib/menu'
import React from 'react'

const StudentMenu = ({ user }) => (
  <DomainContext.Provider value={STUDENT_SCOPE}>
    <MenuSection
      sectionKey={STUDENT_SECTION}
      menuItems={studentSection(user.roleId)}
    />
  </DomainContext.Provider>
)

export default StudentMenu
