import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import fetch from 'unfetch'
import { t } from 'i18next'

import { apiURL } from 'config'
import store from 'src/store'
import { logout } from 'src/components/Logout'
import { localStorage } from 'src/lib/window'
import { warningMessage } from 'src/lib/toaster'
import { queryStart, queryStop, queryReset } from 'src/lib/apolloClient/reducer'

const {
  NetworkStatusNotifier,
  link: networkStatusNotifierLink,
} = createNetworkStatusNotifier()

const cache = new InMemoryCache({
  // eslint-disable-next-line no-underscore-dangle
  dataIdFromObject: o => `${o.__typename}-${o.id}`,
})

const reducerLink = new ApolloLink((operation, forward) => {
  store.dispatch(queryStart())
  return forward(operation).map((data) => {
    store.dispatch(queryStop())
    store.dispatch(queryReset())
    return data
  })
})

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401) logout()
    if (networkError.statusCode === 405) {
      const message = t('errors.actionNotAllowed.message')
      const title = t('errors.actionNotAllowed.title')
      warningMessage({ message, title })
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      locale: localStorage.getItem('i18nextLng'),
    },
  }
})

const uploadLink = createUploadLink({
  credentials: 'same-origin',
  fetch,
  uri: apiURL,
})

const link = ApolloLink.from([
  errorLink,
  authLink,
  reducerLink,
  uploadLink,
])

const client = new ApolloClient({
  cache,
  link: networkStatusNotifierLink.concat(link),
})

export { NetworkStatusNotifier }
export default client
