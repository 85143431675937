import React from 'react'
import { shape, string, arrayOf } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import Markdown from 'react-markdown'
import Bubble from 'src/components/Bubble'

const propTypes = {
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
  language: string.isRequired,
}

const defaultProps = {
  entities: [],
}

const Story = ({ entities, language }) => {
  const columns = entities
    .map((entity) => {
      const title = entity[`title${language}`]
      const { id, meaning } = entity
      const root = (meaning && meaning.id) ? meaning : entity
      const text = root[`title${language}`]
      if (!text) return null
      const audioSrc = root.sound ? root.sound[`src${language}`] : ''
      return { id, text, title, audioSrc, image: entity }
    })
    .filter(v => !!v)
  return (
    <Deck>
      <Div flex auto gutter-2 justify="center">
        {columns.map(({ id, text, image, audioSrc }, i) => (
          <Div flex flex-1 key={id}>
            <Bubble label={i + 1}>
              <Div flex flex-1 col={12} column justify="between">
                <Div flex auto p1 pre-wrap>
                  <AdaptiveCard
                    auto
                    align="left"
                    alignItems="top"
                    justify="left"
                    audio
                    keyToPlay={`${i + 1}`}
                    src={audioSrc}
                    wrapperJustify="left"
                    reduceFontSize={0.9}
                  >
                    <Markdown source={text} />
                  </AdaptiveCard>
                </Div>
                <Div flex height={15}>
                  <Div flex-1 justify="right">
                    <EntityImage entity={image} style={{ backgroundPosition: 'center right' }} />
                  </Div>
                </Div>
              </Div>
            </Bubble>
          </Div>
        ))}
      </Div>
    </Deck>
  )
}

Story.propTypes = propTypes
Story.defaultProps = defaultProps

export default Story
