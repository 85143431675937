import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import { init, mark, retry } from 'src/modules/OddOneOut/reducer'
import OddOneOutScene from 'src/modules/OddOneOut/OddOneOutSceneContainer'
import entityShape from 'src/shapes/entity'

const propTypes = {
  ...gamePropTypes,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
}

class OddOneOutGame extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, init } = this.props
    init({ entities })
  }

  render() {
    const { completed, mark, retry } = this.props
    const { init } = this
    return (
      <Game {
        ...{
          completed,
          init,
          mark,
          retry,
        }}
      >
        <OddOneOutScene />
      </Game>
    )
  }
}

OddOneOutGame.propTypes = propTypes
OddOneOutGame.demoData = {
  entities: [
    'fry', 'phonics-u', 'phonics-f', 'phonics-r', 'phonics-ai',
  ],
}

export default connect(
  (({ oddOneOut }) => oddOneOut),
  dispatch => bindActionCreators({ init, mark, retry }, dispatch),
)(OddOneOutGame)
