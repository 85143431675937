import React from 'react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import Access from 'src/components/Access'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'
import { linkTo } from 'src/lib/linkTo'
import { path as newYearPath } from 'src/pages/Year/New/config'

const LinkToNewYear = () => (
  <Access staff>
    <div>
      <Link
        className="btn btn-outline btn-small"
        to={linkTo(newYearPath)({ domain: SCHOOL_SCOPE })}
      >
        {t('shared.add')}
      </Link>
    </div>
  </Access>
)

export default LinkToNewYear
