import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import entityShape from 'src/shapes/entity'
import { init, mark, retry } from 'src/modules/PhonicBlends/reducer'
import PhonicBlendsScene from 'src/modules/PhonicBlends/PhonicBlendsSceneContainer'

const propTypes = {
  ...gamePropTypes,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
}

class PhonicBlendsGame extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, init, chunkSize } = this.props
    init({ entities, chunkSize })
  }

  render() {
    const { completed, mark, retry } = this.props
    const { init } = this
    return (
      <Game {...{ completed, init, mark, retry }}>
        <PhonicBlendsScene />
      </Game>
    )
  }
}

PhonicBlendsGame.propTypes = propTypes

export default connect(
  (({ phonicBlends }) => phonicBlends),
  dispatch => bindActionCreators({ init, mark, retry }, dispatch),
)(PhonicBlendsGame)
