import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomeworkPage from 'src/pages/Lesson/HomeworkPage'

export const path = '/homework/:lessonSlug/:chapters?/:chapterIndex?/:slides?/:slideIndex?'

const HomeworkRoute = () => (
  <Switch>
    <Route path="/homework/:lessonSlug/:chapters?/:chapterIndex?/:slides?/:slideIndex?">
      <HomeworkPage />
    </Route>
  </Switch>
)

export default HomeworkRoute
