import React, { useState } from 'react'
import { arrayOf, bool, string, shape } from 'prop-types'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import Div from 'src/components/Div'
import { t } from 'i18next'
import { HOMEWORK } from 'src/constants/menu'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Icon from 'src/components/Icon'
import { toggleFullscreen, isFullScreen } from 'src/lib/window'

const propTypes = {
  entities: arrayOf(
    shape(entityShape),
  ).isRequired,
  homework: bool.isRequired,
  identifier: string.isRequired,
  subtitle: string,
  titleRaw: shape({
    en: string.isRequired,
    th: string.isRequired,
  }).isRequired,
}

const defaultProps = {
  subtitle: null,
}

const capitalizeFirstLetter = title => (
  title.charAt(0).toUpperCase() + title.slice(1)
)

const cleanTitle = title => (
  title.split(': ')[0]
)

const TitlePage = (props) => {
  const {
    entities: [entity],
    homework,
    identifier,
    subtitle,
    titleRaw: { th: titleTh, en: titleEn },
  } = props

  const [fullscreen, setFullscreen] = useState(isFullScreen())

  const handleToggleFullscreen = () => {
    toggleFullscreen()
    setFullscreen(!fullscreen)
  }

  return (
    <Div flex column auto>
      <Div flex justify="end">
        <Div className="cursor-pointer" onClick={handleToggleFullscreen}>
          <Icon icon={fullscreen ? 'compress' : 'expand'} size={1} />
        </Div>
      </Div>
      <Div flex justify="center">
        <AdaptiveCard
          bold
          title={cleanTitle(capitalizeFirstLetter(titleEn))}
          transparent
          minHeight={false}
          height={6}
        />
      </Div>
      <Div flex fx={1}>
        <EntityImage key={{ ...entity }.id} entity={entity} />
      </Div>
      {titleTh && (
        <Div flex justify="center">
          <AdaptiveCard
            bold
            title={cleanTitle(titleTh)}
            transparent
            minHeight={false}
            height={6}
          />
        </Div>
      )}
      {
        <Div size={1} center muted>
          {` ${identifier} `}
          {
            homework
              ? t(`${HOMEWORK}.title`)
              : subtitle
          }
        </Div>
      }
    </Div>
  )
}

TitlePage.propTypes = propTypes
TitlePage.defaultProps = defaultProps
TitlePage.demoData = {
  entities: ['family'],
  identifier: 'p3f1l1',
  subtitle: 'Subtitle',
  titleRaw: {
    en: 'Title',
    th: 'หัวข้อ',
  },
}

export default TitlePage
