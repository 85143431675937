import React from 'react'
import { arrayOf, string, shape, func, bool } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import entityShape from 'src/shapes/entity'
import Place from 'src/components/Place'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import EntityCard from 'src/components/EntityCard'
import Card from 'src/components/Card/Card'
import EntityBubble from 'src/components/EntityBubble'
import KeyLabel from 'src/components/KeyLabel'

const propTypes = {
  choices: arrayOf(shape({
    entity: shape(entityShape).isRequired,
    id: string.isRequired,
  }).isRequired).isRequired,
  onMove: func.isRequired,
  words: arrayOf(shape({
    ...entityShape,
    places: arrayOf(shape({
      id: string.isRequired,
      value: string,
      entity: shape(entityShape),
      success: bool,
    })).isRequired,
  }).isRequired).isRequired,
}

const PhonicBlendsScene = ({ choices, words, onMove }) => {
  const singleWord = words.length === 1
  let seq = 0
  return (
    <Deck>
      <Div flex column mb={2}>
        {words.map(({ places, ...word }, i) => (
          <Div flex key={word.id} mb={4} className="qa-word">
            <Div width={12} mr={4}>
              <EntityBubble
                entity={word}
                label={singleWord ? ' ' : i + 1}
                audio
                english
                play
              />
            </Div>
            <Div flex flex-1>
              {places.map(({ id, value, entity, success }) => (
                <Div flex-1 key={id}>
                  <Place
                    id={id}
                    onDrop={({ sourceId, targetId }) => (
                      onMove({ choiceId: sourceId, placeId: targetId })
                    )}
                  >
                    {entity ? (
                      <EntityCard
                        audio
                        correct={success}
                        draggable
                        english
                        entity={entity}
                        id={value}
                      />
                    ) : (
                      <Card correct={success}>_</Card>
                    )}
                  </Place>
                </Div>
              ))}
            </Div>
            <Div width={20}><EntityImage entity={word} /></Div>
          </Div>
        ))}
      </Div>
      <Div height={8} flex justify="center">
        {choices.map(({ entity, id }) => {
          const letter = entity.slug.replace('phonics-', '')
          const indexBase = singleWord ? 0 : words.length
          const keyToPlay = letter.length === 1 ? letter : ((seq += 1) + indexBase)
          return (
            <Div key={id} flex column>
              <EntityCard
                className="qa-choice"
                audio
                draggable
                english
                entity={entity}
                id={id}
                fit
                label={keyToPlay}
                keyToPlay={keyToPlay}
              />
              <KeyLabel label={keyToPlay} />
            </Div>
          )
        })}
      </Div>
    </Deck>
  )
}

PhonicBlendsScene.propTypes = propTypes

export default PhonicBlendsScene
