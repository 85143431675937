import { string, shape, arrayOf } from 'prop-types'

const bestStudentsPerClassShape = shape({
  bestStudentsPerClassShape: arrayOf(
    shape({
      avgStudentGrade: string.isRequired,
      classEn: string.isRequired,
      classTh: string.isRequired,
      studentNameEn: string.isRequired,
      studentNameTh: string.isRequired,
      studentPhotoPath: string.isRequired,
    }),
  ),
})

export default bestStudentsPerClassShape
