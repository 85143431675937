import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import ErrorMessage from 'src/components/ErrorMessage'
import * as components from 'src/modules'
import { LocaleContext } from 'src/lib/contexts'
import normalizeEntity from 'src/lib/normalizeEntity'
import entityShape from 'src/shapes/entity'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
  moduleId: string.isRequired,
}

const componentNotFound = component => (
  <ErrorMessage
    message={`src/modules/${component}/index.js is not found
    or is not listed on src/modules/index.js`}
  />
)

export const getComponent = (component) => {
  if (components[component]) {
    return {
      Component: components[component],
      error: undefined,
    }
  }
  return { Component: undefined, error: componentNotFound(component) }
}

const Module = ({ moduleId, entities, ...props }) => {
  const { Component, error } = getComponent(moduleId)
  if (error) return error
  return (
    <LocaleContext.Consumer>
      {
        ({ locale }) => (
          <Component
            entities={entities.map(normalizeEntity)}
            locale={locale}
            moduleId={moduleId}
            {...props}
          />
        )
      }
    </LocaleContext.Consumer>
  )
}

Module.propTypes = propTypes

export default Module
