import React from 'react'
import { bool, func, string } from 'prop-types'
import { week, weekYear } from 'src/lib/dates'
import Checkbox from 'react-three-state-checkbox'

const propTypes = {
  checked: bool,
  day: string.isRequired,
  indeterminate: bool,
  mutate: func.isRequired,
  studentId: string,
  yearId: string,
}

const defaultProps = {
  checked: false,
  indeterminate: true,
  studentId: undefined,
  yearId: undefined,
}

const Attendance = ({
  checked,
  indeterminate,
  studentId,
  day,
  mutate,
  yearId,
}) => {
  const determinedClassName = checked ? 'bg-green' : 'bg-orange'
  const className = indeterminate ? '' : determinedClassName
  const onChange = () => mutate({
    variables: {
      date: day,
      studentId,
      week: week(day),
      year: weekYear(day),
      yearId,
    },
  })

  return (
    <td className={`col-1 ${className}`}>
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
      />
    </td>
  )
}

Attendance.propTypes = propTypes
Attendance.defaultProps = defaultProps

export default Attendance
