import icons from 'src/lib/menu/icons'
import { SCHOOL_PROGRESS, PROGRESS } from 'src/constants/menu'
import { path, matchPaths } from 'src/pages/ProgressPage/paths'

export { path }

export const scene = {
  icon: icons[PROGRESS],
  id: SCHOOL_PROGRESS,
  path,
  matchPaths,
}

export default {
  scene,
}
