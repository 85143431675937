import React from 'react'
import { array, shape } from 'prop-types'
import Check from 'src/components/Check'
import { t } from 'i18next'
import * as layoutActions from 'src/components/Layout/reducer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Deck from 'src/components/Layout/Deck'

const propTypes = {
  lesson: shape({
    objectives: array.isRequired,
  }).isRequired,
}

const WellDone = ({ lesson: { objectives } }) => (
  <Deck>
    <div className="left-align px4">
      <div className="h0 bold mb2 flex align-center">
        <Check className="mr3" />
        {t('wellDone.wellDone')}
      </div>
      <div className="h2 mb2">
        {t('wellDone.youHaveLearned')}
      </div>
      <ul className="h3">
        {
          objectives.map(({ id, title }) => (
            <li key={id}>
              {title}
            </li>
          ))
        }
      </ul>
    </div>
  </Deck>
)

WellDone.propTypes = propTypes

const actions = dispatch => (
  bindActionCreators(layoutActions, dispatch)
)

export default connect(null, actions)(WellDone)
