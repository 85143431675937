import React from 'react'
import { shape, string, bool, arrayOf } from 'prop-types'
import { t } from 'i18next'
import { connect } from 'src/lib/redux'
import Chapter from 'src/pages/Chapter'
import ErrorMessage from 'src/components/ErrorMessage'
import instructionShape from 'src/shapes/instruction'
import LessonLayout from 'src/pages/Lesson/LessonLayout'
import userShape from 'src/shapes/user'
import { LessonBackground } from 'src/components/Layout/BodyBackground'
import PageTitle from 'src/components/ui/PageTitle'

const propTypes = {
  backgroundComponentLayout: shape(),
  chapterIndex: string,
  homework: bool,
  homeworkSessionId: string,
  instructions: arrayOf(shape(instructionShape)).isRequired,
  lesson: shape().isRequired,
  lessonSlug: string.isRequired,
  slideIndex: string,
  started: bool,
  user: shape(userShape),
}

const defaultProps = {
  backgroundComponentLayout: {},
  chapterIndex: '0',
  homework: false,
  slideIndex: '0',
  user: null,
}

const Lesson = ({
  backgroundComponentLayout,
  chapterIndex,
  homework,
  homeworkSessionId,
  instructions,
  lesson,
  lessonSlug,
  microphoneCheck,
  slideIndex,
  started,
  user,
}) => {
  const { chapters, identifier } = { ...lesson }
  const chapter = chapters[chapterIndex]
  const { component: moduleId } = chapter || {}
  const { id: chapterId } = { ...chapter }
  const chapterIndexInt = parseInt(chapterIndex, 10)
  const slideIndexInt = parseInt(slideIndex, 10)
  const pageTitle = chapter
    ? `${lesson.title} / ${chapter.title}`
    : lesson.title
  const content = chapter ? (
    <Chapter
      {...{
        chapter,
        chapterId,
        homework,
        homeworkSessionId,
        identifier,
        lesson,
        moduleId,
        started,
      }}
    />
  ) : (
    <ErrorMessage message={t('error.noChapter')} bgColor="blue" />
  )
  return (
    <LessonLayout
      {...{
        backgroundComponentLayout,
        chapterId,
        chapterIndex: chapterIndexInt,
        chapters,
        homework,
        instructions,
        lessonSlug,
        microphoneCheck,
        moduleId,
        slideIndex: slideIndexInt,
        user,
      }}
    >
      <PageTitle>{pageTitle}</PageTitle>
      <LessonBackground entity={backgroundComponentLayout} />
      {content}
    </LessonLayout>
  )
}

Lesson.propTypes = propTypes
Lesson.defaultProps = defaultProps

const select = (state) => state.homework
export default connect(select)(Lesson)
