import last from 'lodash/last'

const isMatchingTitle = ({ title: title1 }, { title: title2 }) => {
  const match1 = title1.split(': ')
  const match2 = title2.split(': ')
  return match2.length > 1 && (match1[0].toLowerCase() === match2[0].toLowerCase())
}

const addChild = (prev, step) => {
  const prevTitle = prev.title.split(': ')[0]
  const stepTitle = step.title.split(': ')[1]
  const children = prev.children || [{ ...prev, title: prev.title.split(': ')[1] }]
  return {
    children: children.concat({
      ...step,
      title: stepTitle,
    }),
    title: prevTitle,
  }
}

const stepsReducer = (a, step) => {
  const prev = last(a)
  if (prev && isMatchingTitle(prev, step)) {
    const { title, children } = addChild(prev, step)
    prev.title = title
    prev.children = children
    return a
  }
  return a.concat(step)
}

const mapSteps = steps => steps.reduce(stepsReducer, [])

export default mapSteps
