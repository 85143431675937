import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import entityShape from 'src/shapes/entity'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import {
  updateTriesIterationIcon,
  clearTriesIterationIcon,
} from 'src/components/IterationIcon/reducer'
import * as speechRecognitionActions from 'src/modules/SpeechRecognition/reducer'
import SpeechRecognition from 'src/modules/SpeechRecognition/SpeechRecognition'
import withSpeechRecognition from 'src/modules/SpeechRecognition/SpeechRecognitionHOC'

const propTypes = {
  ...gamePropTypes,
  correct: bool,
  cursive: bool,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
  entity: shape(entityShape),
  isCursive: bool,
  loadingOff: func,
  loadingOn: func,
  mark: func,
  microphoneOff: func,
  microphoneOn: func,
  selected: bool,
  showOriginalText: func,
  transcripts: arrayOf(shape({
    id: string,
    title: string,
  })),
}

const defaultProps = {
  completed: null,
  correct: null,
  cursive: false,
  hide: false,
  hideAnswer: false,
  mark: null,
}

export const SpeechRecognitionGame = ({
  actualEntities,
  entities,
  init,
  completed,
  correct,
  entity,
  cursive,
  hide,
  hideAnswer,
  isCursive,
  loading,
  retry,
  selected,
  silent,
  showOriginalText,
  started,
  transcripts,
  onStart,
  onStop,
  onKeyPress,
}) => {
  if (!entity.id) return null
  const initGame = () => init({ entities, hide })
  return (
    <Game
      actualEntities={actualEntities}
      init={initGame}
      completed={completed}
      retry={retry}
      mark={() => {}}
    >
      <SpeechRecognition
        autoPlay={started}
        correct={correct}
        entity={entity}
        hideAnswer={hideAnswer}
        hide={hide}
        cursive={isCursive && cursive}
        keyPressRecognition={onKeyPress}
        loading={loading}
        silent={silent}
        showOriginalText={showOriginalText}
        selected={selected}
        startRecognition={onStart}
        stopRecognition={onStop}
        transcripts={transcripts}
      />
    </Game>
  )
}

const select = ({ speechRecognition }) => speechRecognition
const actions = dispatch => bindActionCreators({
  ...speechRecognitionActions,
  clearTriesIterationIcon,
  updateTriesIterationIcon,
}, dispatch)

const composedHOCs = compose(connect(select, actions), withSpeechRecognition)

export const SpeechRecognitionContainer = composedHOCs(SpeechRecognitionGame)
export const SpeechRecognitionImageContainer = composedHOCs(SpeechRecognitionGame, { hide: true })
export const SpeechRecognitionPrintedAndCursiveTextContainer =
  composedHOCs(SpeechRecognitionGame, { cursive: true })

const demoData = {
  entities: ['mother', 'father', 'family', 'elder-sister'],
}

SpeechRecognitionContainer.propTypes = propTypes
SpeechRecognitionContainer.defaultProps = defaultProps
SpeechRecognitionContainer.demoData = demoData
SpeechRecognitionPrintedAndCursiveTextContainer.demoData = demoData
SpeechRecognitionImageContainer.demoData = demoData

export default SpeechRecognitionContainer
