/* eslint react/prefer-stateless-function: 1 */
import React from 'react'
import chunk from 'lodash/chunk'
import map from 'lodash/map'
import classNames from 'classnames'
import { array, func, shape, string } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Card from 'src/components/Card'
import KeyLabel from 'src/components/KeyLabel'
import Place from 'src/components/Place'

const propTypes = {
  availableCardsId: array.isRequired,
  cover: string,
  direction: string.isRequired,
  entitiesById: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderCard = ({ entity, isPlace, correct, id, label }) => (
  <div key={id}>
    <AdaptiveCard
      audio
      bold
      correct={correct}
      draggable
      english
      entity={entity}
      fit
      height={3}
      p={1}
      id={id}
      keyToPlay={label}
      lessTransparent={isPlace}
      width={20}
    />
  </div>
)

const renderPlaces = ({ entitiesById, place, moveCard, isVertical, cards, isLabel, isDiagram }) => {
  const { id, cardId, correct, label } = place
  const card = cardId
    ? renderCard({
      correct,
      entity: entitiesById[cards[cardId].entityId],
      id: cardId,
      isPlace: true,
      label,
    })
    : (
      <Card
        auto
        dashed
        fit
        size={2}
        title=" "
        transparent
        whiteSpace
        width={isDiagram ? 20 : null}
      />
    )
  return (
    <Place
      id={id}
      key={id}
      onDrop={moveCard}
      className={
        classNames({
          'col-4': isVertical,
          'justify-center flex auto column': true,
          'min-width-20': isLabel,
        })
      }
    >
      {
        isLabel ?
          (
            <Div justify-start flex key={cardId} p={1}>
              <Div
                bg="white"
                mr1
                circle
                style={{
                  height: '4rem',
                  width: '4rem',
                }}
                lessTransparent
              >
                <KeyLabel label={label} height={4} color="black" />
              </Div>
              <Div auto flex justify-center column>
                {card}
              </Div>
            </Div>
          )
          :
          (
            <Div justify-center key={cardId} p={1}>
              {card}
            </Div>
          )
      }
    </Place>
  )
}

const renderPlacesForGrid = ({ entitiesById, place, moveCard, cards }) => {
  const { id, cardId, correct, label } = place
  const card = cardId
    ? renderCard({
      correct,
      entity: entitiesById[cards[cardId].entityId],
      id: cardId,
      isPlace: true,
      label,
    })
    : <Card fit size={2} title=" " transparent dashed whiteSpace="pre" />
  return (
    <Div flex auto key={id}>
      <Div
        absolute
        bg="white"
        circle
        mx={2}
        mt={2}
        style={{
          height: '4rem',
          width: '4rem',
        }}
      >
        <KeyLabel label={label} height={4} />
      </Div>
      <Place
        id={id}
        key={id}
        onDrop={moveCard}
        style={{
          height: '18.75rem',
          width: '18.75rem',
        }}
      >
        <Div justify-center flex auto column key={cardId} p={1}>
          {card}
        </Div>
      </Place>
    </Div>
  )
}

const renderLabelImageGrid = ({ cover, entitiesById, places, moveCard, cards }) => {
  const chunkPlaces = chunk(Object.keys(places), 4)
  return (
    <Deck backgroundImage={cover} column>
      {
        map(chunkPlaces, (placesInChunk, i) => (
          <Div key={i} flex auto justify="center">
            {map(placesInChunk, place => (
              renderPlacesForGrid({
                cards,
                entitiesById,
                isVertical: true,
                moveCard,
                place: places[place],
              })
            ))}
          </Div>
        ))
    }
    </Deck>
  )
}

const renderLabelImageDiagram = ({
  availableCardsId,
  cards,
  cover,
  entitiesById,
  moveCard,
  places,
}) => (
  <Deck>
    <Div flex auto>
      <Div flex col-8 column>
        <Div flex wrap justify="center" mb2>
          {
            availableCardsId.map(card => renderCard({
              entity: entitiesById[cards[card].entityId],
              id: card,
              isLabel: true,
            }))
          }
        </Div>
        <Div key={cover} backgroundImage={cover} backgroundSize="container" flex auto />
      </Div>
      <Div flex auto column justify-center>
        {
          map(places, place => (
            <Div flex justify-center key={place.id}>
              <Div flex>
                {renderPlaces({
                  cards,
                  entitiesById,
                  isDiagram: true,
                  isLabel: true,
                  moveCard,
                  place,
                })}
              </Div>
            </Div>
          ))
        }
      </Div>
    </Div>
  </Deck>
)

const LabelImage = ({
  availableCardsId,
  entitiesById,
  places,
  moveCard,
  cover,
  cards,
  direction,
  identifier,
}) => (
  direction === 'diagram'
    ? (
      <Deck>
        {
          renderLabelImageDiagram({
            availableCardsId,
            cards,
            cover,
            direction,
            entitiesById,
            moveCard,
            places,
          })
        }
      </Deck>
    )
    : (
      <Deck>
        <Div
          flex
          wrap
          justify="center"
          style={{
            minHeight: direction === 'grid' && identifier && '6.5rem',
          }}
        >
          {
            availableCardsId.map(card => renderCard({
              entity: entitiesById[cards[card].entityId],
              id: card,
            }))
          }
        </Div>
        {
          renderLabelImageGrid({ cards, cover, entitiesById, identifier, moveCard, places })
        }
      </Deck>
    )
)

LabelImage.propTypes = propTypes
export default LabelImage
