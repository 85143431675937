import React from 'react'
import { shape, string, bool } from 'prop-types'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import RobotCommandImage from 'src/components/ObjectCommand/images/RobotCommand.svg'
import WoodCommandImage from 'src/components/ObjectCommand/images/WoodCommand.svg'

const propTypes = {
  autoPlay: bool,
  entity: shape(),
  objectImage: string,
}

const IMAGES = {
  robot: RobotCommandImage,
  wood: WoodCommandImage,
}

const defaultProps = {
  autoPlay: null,
  entity: null,
  objectImage: null,
}

const ObjectCommand = ({ entity, autoPlay, objectImage }) => (
  <Div relative z2 flex width={21.5}>
    <EntityCard
      key={entity.id}
      english
      entity={entity}
      imageSrc={IMAGES[objectImage]}
      keyToPlay=" "
      audio
      autoPlay={autoPlay}
      transparent
    />
  </Div>
)
ObjectCommand.propTypes = propTypes
ObjectCommand.defaultProps = defaultProps

export default ObjectCommand
