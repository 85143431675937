import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  number,
  shape,
  string,
  oneOfType,
} from 'prop-types'
import CompletableGame from 'src/components/Game/CompletableGame'
import GameWrapper from 'src/components/GameWrapper'
import KeyPress from 'src/components/KeyPress'
import * as layoutActions from 'src/components/Layout/reducer'
import Instructions from 'src/components/Game/Instructions'
import instructionShape from 'src/shapes/instruction'
import {
  playGameCompletedSound as playGameCompletedSoundAction,
  playGameNotCompletedSound as playGameNotCompletedSoundAction,
} from 'src/lib/sounds/reducer'

const propTypes = {
  children: oneOfType([element, array]).isRequired,
  clearMark: func.isRequired,
  completed: bool,
  disableMark: bool,
  disableMarkButton: func,
  enableMarkButton: func,
  hideInstruction: func.isRequired,
  homework: bool,
  init: func.isRequired,
  instructions: arrayOf(shape(instructionShape)),
  isMark: bool,
  isShowInstruction: bool,
  mark: func.isRequired,
  moduleId: string,
  noCorrectEffectSound: bool,
  onSubscribe: func,
  playGameCompletedSound: func.isRequired,
  playGameNotCompletedSound: func.isRequired,
  retry: func.isRequired,
  retryDelay: number,
  showInstruction: func.isRequired,
  showMarkButton: func,
  started: bool,
  startGame: func,
}

const defaultProps = {
  completed: null,
  disableMark: undefined,
  disableMarkButton: undefined,
  enableMarkButton: undefined,
  homework: false,
  instructions: [],
  isMark: false,
  isShowInstruction: false,
  moduleId: null,
  noCorrectEffectSound: null,
  onSubscribe: undefined,
  retryDelay: null,
  showMarkButton: undefined,
  startGame: undefined,
  started: false,
}

class Game extends React.Component {
  constructor(props) {
    super(props)
    const { mark, showMarkButton, startGame, onSubscribe, showInstruction } = this.props
    showMarkButton(mark)
    if (startGame) startGame({ started: false })
    if (showInstruction) showInstruction()
    if (onSubscribe) onSubscribe(this)
  }

  componentDidUpdate(prevProps) {
    const {
      completed,
      enableMarkButton,
      isMark,
      mark,
      noCorrectEffectSound,
      onSubscribe,
      playGameCompletedSound,
      playGameNotCompletedSound,
      disableMark,
    } = this.props
    if (onSubscribe && isMark !== prevProps.isMark) onSubscribe(this)
    if (
      (completed === true || completed === false) &&
      completed !== prevProps.completed
    ) {
      if (completed && !noCorrectEffectSound) playGameCompletedSound()
      if (!completed) playGameNotCompletedSound()
    }
    if (disableMark && mark && completed === null) enableMarkButton()
  }

  componentWillUnmount() {
    const { clearMark, mark } = this.props
    clearMark({ prevMark: mark })
  }

  handleAfterMark() {
    const { completed, retry, init } = this.props
    if (completed === false) {
      retry()
    } else if (completed === true) {
      init(this.props)
    }
  }

  handleEnterKeyPress() {
    const { completed, disableMarkButton, isShowInstruction, mark } = this.props
    if (isShowInstruction) {
      this.handleInstruction()
      return
    }
    if (completed === null && mark) {
      mark()
      disableMarkButton()
    }
  }

  handleInstruction() {
    const { started, startGame, hideInstruction } = this.props
    if (!started) {
      startGame({ started: true })
    }
    hideInstruction()
  }

  render() {
    const {
      children,
      completed,
      homework,
      instructions,
      isShowInstruction,
      moduleId,
      retryDelay,
      onSubscribe,
    } = this.props
    // todo: define props from unitTest or muted component
    if (onSubscribe) {
      return <CompletableGame onClick={() => {}}>{children}</CompletableGame>
    }

    return (
      <GameWrapper
        isShowInstruction={isShowInstruction}
        completed={completed}
        gameProps={this.props}
        moduleId={moduleId}
        homework={homework}
      >
        <CompletableGame
          retryDelay={retryDelay}
          completed={completed}
          onClick={() => this.handleAfterMark()}
          animation
        >
          <Fragment>
            <KeyPress keys={{ Enter: () => this.handleEnterKeyPress() }} />
            <Instructions
              isShowInstruction={isShowInstruction}
              handleInstruction={() => this.handleInstruction()}
              instructions={instructions}
              moduleId={moduleId}
            >
              {children}
            </Instructions>
          </Fragment>
        </CompletableGame>
      </GameWrapper>
    )
  }
}

Game.propTypes = propTypes
Game.defaultProps = defaultProps

export { Game }

const mapStateToProps = state => ({
  disableMark: state.layout.disableMark,
  isShowInstruction: state.layout.isShowInstruction,
})
const mapDispatchToProps = dispatch => bindActionCreators({
  ...layoutActions,
  playGameCompletedSound: playGameCompletedSoundAction,
  playGameNotCompletedSound: playGameNotCompletedSoundAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Game)
