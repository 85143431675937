import createHomeworkAnswer from 'src/mutations/createHomeworkAnswer.gql'
import uuid from 'uuid/v4'
import isNil from 'lodash/isNil'
import createHomeworkChapterAnswer from 'src/mutations/createHomeworkChapterAnswer.gql'

const { trace } = console

const SUBSCRIBE_GAME = 'unitTest/SUBSCRIBE_GAME'
const FINISH = 'unitTest/FINISH'
const INITIAL = 'unitTest/INITIAL'
const CLEAR = 'unitTest/CLEAR'

const initialState = {
  finished: false,
  subscribedGames: [],
}

const checkDuplicateSubscriber = game => (a, subscriber) => {
  if (a === true) return true
  if (subscriber.props.chapterId === game.props.chapterId) {
    return true
  }
  return false
}

const updateDuplicateSubscriber = game => (a, subscriber) => {
  if (subscriber.props.chapterId === game.props.chapterId) {
    return a.concat(game)
  }
  return a.concat(subscriber)
}

const updateSubscriber = ({ subscribedGames, game }) => {
  if (subscribedGames.length === 0) return [game]
  if (subscribedGames.reduce(checkDuplicateSubscriber(game), false)) {
    return subscribedGames.reduce(updateDuplicateSubscriber(game), [])
  }
  return [...subscribedGames, game]
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case INITIAL:
    case FINISH:
    case CLEAR: {
      return { ...state, ...payload }
    }
    case SUBSCRIBE_GAME: {
      const { subscribedGames } = state
      return {
        ...state,
        subscribedGames: updateSubscriber({ game: payload.game, subscribedGames }),
      }
    }
    default:
      return state
  }
}

const subscribeGame = game => ({
  payload: { game },
  type: SUBSCRIBE_GAME,
})

const slugs = entities => entities.map(({ slug }) => slug)

const storeResults = client => async (results) => {
  const homeworkSessionId = uuid()

  try {
    await Promise.all(results.map(({
      actualEntities, answer, chapterId, completed,
    }) => {
      const iterations = [{
        answer,
        correct: completed,
        entities: (actualEntities || []).map(({ slug }) => slug),
      }]

      return (
        client.mutate({
          mutation: createHomeworkChapterAnswer,
          variables: {
            chapterId,
            homeworkSessionId,
            answer,
            iterationsTotal: 1,
            iterationsDone: 1,
            iterationsCorrect: completed ? 1 : 0,
            props: {
              iterations,
            },
          },
        })
      )
    }))
  } catch (err) {
    trace(err)
  }

  await Promise.all(results.map(({
    actualEntities, answer, chapterId, completed, entities,
  }) => (
    client.mutate(
      {
        mutation: createHomeworkAnswer,
        variables: {
          chapterId,
          homeworkSessionId,
          props: {
            actualEntities: slugs(actualEntities),
            answer,
            completed,
            entities: slugs(entities),
            unitTest: true,
          },
        },
      },
    )
  )))
}

const finish = client => (dispatch, getState) => {
  const { unitTest: { subscribedGames } } = getState()
  storeResults(client)(
    subscribedGames
      .map(({ props: {
        actualEntities, answer, chapterId, completed, entities,
      } }) => ({ actualEntities, answer, chapterId, completed, entities }))
      .filter(({ completed }) => !isNil(completed)),
  )
  dispatch({
    payload: { finished: true },
    type: FINISH,
  })
}

const initial = () => ({
  payload: { finished: false },
  type: INITIAL,
})

const clearSubscribedGames = () => ({
  payload: { subscribedGames: [] },
  type: CLEAR,
})

export { subscribeGame, finish, initial, clearSubscribedGames }
