import React from 'react'
import LabelImage from 'src/modules/LabelImage/LabelImageContainer'

const defaultProps = {
  direction: 'diagram',
}

const LabelImageDiagram = (props) => {
  const { mark, init, retry, completed, entities, direction } = props
  return (
    <LabelImage {
      ...{
        completed,
        direction,
        entities,
        init,
        mark,
        retry,
      }
    }
    />
  )
}

LabelImageDiagram.defaultProps = defaultProps
LabelImageDiagram.demoData = {
  entities: [
    'image-plant-diagram',
    'flower',
    'fruit',
    'leaf',
    'stem',
    'seed',
    'root',
  ],
}

export default LabelImageDiagram
