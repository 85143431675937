import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from 'src/pages/DashboardPage'
import StudentsLeaderboard from 'src/pages/DashboardPage/StudentsLeaderboard'
import RedirectWithUpdateLocale from 'src/components/RedirectWithUpdateLocale'

export const path = '/dashboard'

const DashboardRoute = () => (
  <Switch>
    <Route exact path="/dashboard">
      <Dashboard />
    </Route>
    <Route exact path="/dashboard/leaderboard/:locale">
      <RedirectWithUpdateLocale to="/dashboard/leaderboard" />
    </Route>
    <Route exact path="/dashboard/leaderboard/">
      <StudentsLeaderboard />
    </Route>
  </Switch>
)

export default DashboardRoute
