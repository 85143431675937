import React from 'react'
import { element } from 'prop-types'

const { log } = console

const propTypes = {
  children: element,
}

const Debug = (props) => {
  log(props)
  return (
    <div>
      {JSON.stringify(Object.keys(props))}
      {props.children}
    </div>
  )
}

Debug.propTypes = propTypes
export default Debug
