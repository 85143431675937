import React from 'react'
import { Link } from 'react-router-dom'

import { linkTo } from 'src/lib/linkTo'
import DomainContext from 'src/contexts/DomainContext'
import { path as yearPath } from 'src/pages/Year/Show/config'

const YearLinkCell = ({
  original: { id },
  value,
}) => (
  <DomainContext.Consumer>
    {
      domain => (
        <Link className="qa-year-table-row" to={linkTo(yearPath)({ domain, yearId: id })}>
          <span>{value}</span>
        </Link>
      )
    }
  </DomainContext.Consumer>
)

export default YearLinkCell
