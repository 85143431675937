import React from 'react'
import { arrayOf, shape, object, string, bool } from 'prop-types'
import { t } from 'i18next'
import loginByToken from 'src/lib/loginByToken'

const userShape = shape({
  id: string,
  title: object,
  token: string,
})

const propTypes = {
  demoSwitcher: shape({
    roles: arrayOf(shape({ id: string.isRequired, title: string.isRequired })),
    users: arrayOf(userShape),
  }),
  showSelect: bool.isRequired,
}

const defaultProps = {
  demoSwitcher: {
    roles: [],
    users: [],
  },
}

const DemoSwitcher = ({ demoSwitcher: { roles, users }, showSelect }) => {
  if (!roles.length && !showSelect) return null
  return (
    <div>
      <select
        className="col-12"
        id="qa-select-role"
        onChange={(e) => loginByToken(e.target.value)}
        value="---"
        disabled={!roles.length}
      >
        <option disabled value="---">
          {t('login.loginAs')}
        </option>
        {roles.map(({ id, title, token }) => (
          <option key={id} value={token}>
            {title}
          </option>
        ))}
        <option disabled value="---">
          ---
        </option>
        {users.map(({ id, title, token }) => (
          <option key={id} value={token}>
            {title}
          </option>
        ))}
      </select>
    </div>
  )
}

DemoSwitcher.propTypes = propTypes
DemoSwitcher.defaultProps = defaultProps
export default DemoSwitcher
