import React from 'react'
import { GameContext } from 'src/lib/contexts'
import BodyBackground from 'src/components/Layout/BodyBackground.js'

const BodyBackgroundContainer = props => (
  <GameContext.Consumer>
    {({ guide }) => <BodyBackground {...props} guide={guide} />}
  </GameContext.Consumer>
)

export default BodyBackgroundContainer
