import React from 'react'

import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Place from 'src/components/Place'
import mapKey from 'src/lib/mapKey'

const Words = ({
  cards,
  line,
  moveCard,
  places,
}) => (
  <Div flex>
    {
      line.map(({ placeId, title }, i) => (
        <Div flex flex-1 key={mapKey(i)}>
          {
            placeId ? (
              <Place id={placeId} onDrop={moveCard}>
                <AdaptiveCard
                  key={places[placeId].cardId}
                  draggable
                  height={11}
                  p={1}
                  focusOnWidth
                  {...cards[places[placeId].cardId]}
                />
              </Place>
            ) : <AdaptiveCard {...{ title }} />
          }
        </Div>
      ))
    }
  </Div>
)

export default Words
