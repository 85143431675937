import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { t } from 'i18next'
import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'

const propTypes = {
  curriculum: arrayOf(
    shape({
      id: string.isRequired,
      indicator: string.isRequired,
      indicatorIdentifier: string.isRequired,
      standard: string.isRequired,
      standardIdentifier: string.isRequired,
      strand: string.isRequired,
      strandIdentifier: string.isRequired,
      subject: string.isRequired,
    }),
  ).isRequired,
}

const IndicatorsRoot = ({
  curriculum,
}) => (
  <StickyTable>
    <StickyTableHeader>
      <th>
        {t('curriculum.subject')}
      </th>
      <th>
        {t('curriculum.strand')}
        <span className="normal muted">
          {' '}
          {uniqBy(curriculum, 'strand').length}
        </span>
      </th>
      <th>
        {t('curriculum.standard')}
        <span className="normal muted">
          {' '}
          {uniqBy(curriculum, 'standard').length}
        </span>
      </th>
      <th>
        {t('curriculum.indicator')}
        <span className="normal muted">
          {' '}
          {curriculum.length}
        </span>
      </th>
    </StickyTableHeader>
    <tbody>
      {
        curriculum.map(({
          id,
          subject,
          standard,
          strand,
          indicator,
          standardIdentifier,
          strandIdentifier,
          indicatorIdentifier,
        }) => (
          <tr key={id}>
            <td>
              <div className="bold">
                {subject}
              </div>
            </td>
            <td>
              <div className="bold">
                {strandIdentifier}
              </div>
              {strand}
            </td>
            <td>
              <div className="bold">
                {standardIdentifier}
              </div>
              {standard}
            </td>
            <td>
              <div className="bold">
                {indicatorIdentifier}
              </div>
              {indicator}
            </td>
          </tr>
        ))
      }
    </tbody>
  </StickyTable>
)

IndicatorsRoot.propTypes = propTypes
export default IndicatorsRoot
