import process from 'process'

// that series allows us to control the execution flow of callbacks.
// task is array of series function or step that we want to process in order
// cb is callback function that will run at the end, after the last task
export default function series(tasks, cb) {
  const results = []
  // task index
  let current = 0
  let isSync = true

  // this fucntion work like Promise
  // it will assure that cb is called after of all tasks
  function done(err) {
    function end() {
      if (cb) cb(err, results)
    }
    if (isSync) process.nextTick(end)
    else end()
  }

  // run functions in series task by task
  function each(err, result) {
    results.push(result)
    // eslint-disable-next-line
    if (++current >= tasks.length || err) done(err)
    else tasks[current](each)
  }

  if (tasks.length > 0) tasks[0](each)
  else done(null)

  isSync = false
}
