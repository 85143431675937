import React from 'react'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import { t } from 'i18next'
import { arrayOf, shape, string, func } from 'prop-types'
import { dateToday, rangeBy } from 'src/lib/dates'
import eventTitleFallBack from 'src/lib/eventTitleFallBack'
import Div from 'src/components/Div'
import { classNamePrefix } from 'src/components/YearCalendar/Day'
import { calendarEventShape } from 'src/shapes/calendar'
import { isWeekend } from 'src/pages/CalendarPage/YearSummary/isAcademicDay'
import Month from 'src/components/YearCalendar/Month'

const propTypes = {
  datePath: func,
  endDate: string.isRequired,
  events: arrayOf(shape(calendarEventShape)),
  startDate: string.isRequired,
}

const defaultProps = {
  datePath: null,
  events: [],
}

const addEvent = (acc = {}, day, mod) => {
  // mutates things for performance reasons
  if (!acc[day]) acc[day] = []
  acc[day].push(mod)
  return acc
}

const eventsToDayModifier = (calendarEventsArg, day) => {
  const calendarEvents = isWeekend(day)
    ? calendarEventsArg.filter(({ academicTerm }) => !academicTerm)
    : calendarEventsArg
  const events = calendarEvents.reduce((acc, mod) => compact([...acc, mod.event]), [])
  const schoolHoliday = !!calendarEvents.find(({ holiday }) => holiday)
  const academicTerm = !!calendarEvents.find(({ academicTerm }) => academicTerm)
  const tooltips = uniq(flatten(compact([
    (academicTerm && !schoolHoliday) && t('school.settings.academicDay'),
    schoolHoliday && t('school.settings.holiday'),
    ...events.map(event => eventTitleFallBack(event)),
    ...calendarEvents.map(({ tooltip }) => tooltip),
  ])))
  const className = calendarEvents
    .reduce((acc, mod) => compact([...acc, mod.className, ...(mod.classNames || [])]), [])
    .map(cls => `${classNamePrefix}-${cls}`)
    .join(' ')
  return { className, tooltips }
}

const prepareDayModifiers = (events) => {
  // grouping all calendar events by day and putting them into hash
  const modifiersByDay = events.reduce((acc, mod) => {
    const { startDate, endDate } = mod
    return rangeBy(startDate, endDate, 'day')
      .reduce((acc, day) => addEvent(acc, day, mod), acc)
  }, {})
  // squashing events for each day into { tooltips, className } modifier to pass to Day component
  Object.keys(modifiersByDay).forEach((day) => {
    modifiersByDay[day] = eventsToDayModifier(modifiersByDay[day], day)
  })
  return modifiersByDay
}

const YearCalendar = ({
  startDate,
  endDate,
  events,
  datePath,
}) => {
  const today = dateToday()
  const eventsWithToday = [...events, { className: 'today', endDate: today, startDate: today }]
  const dayModifiers = prepareDayModifiers(eventsWithToday)
  const months = rangeBy(startDate, endDate, 'month')
  return (
    <Div center className="YearCalendar mx-auto">
      {
        months.map(month => (
          <Month
            dayModifiers={dayModifiers}
            datePath={datePath}
            date={month}
            key={`month-${month}`}
          />
        ))
      }
    </Div>
  )
}

YearCalendar.propTypes = propTypes
YearCalendar.defaultProps = defaultProps
export default YearCalendar
