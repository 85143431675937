const ACTIVITIES = 'menu.ACTIVITIES'
const ADMISSIONS = 'menu.ADMISSIONS'
const ALL_STUDENTS = 'menu.ALL_STUDENTS'
const ASSESSMENT = 'menu.ASSESSMENT'
const ATTENDANCE = 'menu.ATTENDANCE'
const ATTENDANCE_REPORT = 'menu.ATTENDANCE_REPORT'
const BP_REPORT = 'menu.BP_REPORT'
const CALENDAR = 'menu.CALENDAR'
const CHILDREN = 'menu.CHILDREN'
const DOCUMENT_MANAGEMENT = 'menu.DOCUMENT_MANAGEMENT'
const EXAMINATIONS = 'menu.EXAMINATIONS'
const GRADING = 'menu.GRADING'
const HOMEWORK = 'menu.HOMEWORK'
const LEADERBOARD = 'menu.LEADERBOARD'
const LESSON_MODULES = 'menu.LESSON_MODULES'
const LESSONS = 'menu.LESSONS'
const LIBRARY = 'menu.LIBRARY'
const MY_FAMILY = 'menu.MY_FAMILY'
const MY_STUDENTS = 'menu.MY_STUDENTS'
const MY_STUDENTS_ASSESSMENT = 'menu.MY_STUDENTS_ASSESSMENT'
const MY_STUDENTS_ATTENDANCE = 'menu.MY_STUDENTS_ATTENDANCE'
const MY_STUDENTS_ATTENDANCE_REPORT = 'menu.MY_STUDENTS_ATTENDANCE_REPORT'
const MY_STUDENTS_CURRICULUM = 'menu.MY_STUDENTS_CURRICULUM'
const MY_STUDENTS_PROGRESS = 'menu.MY_STUDENTS_PROGRESS'
const PARENT_DATA = 'menu.PARENT_DATA'
const PARENTS = 'menu.PARENTS'
const PEOPLE = 'menu.PEOPLE'
const PROGRESS = 'menu.PROGRESS'
const RESOURCE_MANAGEMENT = 'menu.RESOURCE_MANAGEMENT'
const SCHOOL = 'menu.SCHOOL'
const SCHOOL_ATTENDANCE = 'menu.SCHOOL_ATTENDANCE'
const SCHOOL_ATTENDANCE_REPORT = 'menu.SCHOOL_ATTENDANCE_REPORT'
const SCHOOL_CURRICULUM = 'menu.SCHOOL_CURRICULUM'
const SCHOOL_PROGRESS = 'menu.SCHOOL_PROGRESS'
const SCHOOL_SETTINGS = 'menu.SCHOOL_SETTINGS'
const SKILLS = 'menu.SKILLS'
const SPECIAL_NEEDS = 'menu.SPECIAL_NEEDS'
const STAFF = 'menu.STAFF'
const STAFF_DATA = 'menu.STAFF_DATA'
const STUDENT_CARDS = 'menu.STUDENT_CARDS'
const STUDENT_DATA = 'menu.STUDENT_DATA'
const TEACHER = 'menu.TEACHER'
const TEACHER_GUIDES = 'menu.TEACHER_GUIDES'
const TEACHERS = 'menu.TEACHERS'
const TIMETABLE = 'menu.TIMETABLE'
const USERS = 'menu.USERS'
const YEARS = 'menu.YEARS'

export {
  ACTIVITIES,
  ADMISSIONS,
  ALL_STUDENTS,
  ASSESSMENT,
  ATTENDANCE,
  ATTENDANCE_REPORT,
  BP_REPORT,
  CALENDAR,
  CHILDREN,
  DOCUMENT_MANAGEMENT,
  EXAMINATIONS,
  GRADING,
  HOMEWORK,
  LEADERBOARD,
  LESSON_MODULES,
  LESSONS,
  LIBRARY,
  MY_FAMILY,
  MY_STUDENTS,
  MY_STUDENTS_ASSESSMENT,
  MY_STUDENTS_ATTENDANCE,
  MY_STUDENTS_ATTENDANCE_REPORT,
  MY_STUDENTS_CURRICULUM,
  MY_STUDENTS_PROGRESS,
  PARENT_DATA,
  PARENTS,
  PEOPLE,
  PROGRESS,
  RESOURCE_MANAGEMENT,
  SCHOOL,
  SCHOOL_ATTENDANCE,
  SCHOOL_ATTENDANCE_REPORT,
  SCHOOL_CURRICULUM,
  SCHOOL_PROGRESS,
  SCHOOL_SETTINGS,
  SKILLS,
  SPECIAL_NEEDS,
  STAFF,
  STAFF_DATA,
  STUDENT_CARDS,
  STUDENT_DATA,
  TEACHER,
  TEACHER_GUIDES,
  TEACHERS,
  TIMETABLE,
  USERS,
  YEARS,
}
