import React from 'react'
import { bool, element, string } from 'prop-types'
import Icon from 'src/components/Icon'

const propTypes = {
  children: element.isRequired,
  className: string,
  p: string,
  rounded: bool,
  white: bool,
}

const defaultProps = {
  className: '',
  p: 'p1',
  rounded: false,
  white: false,
}

const withSelectStyle = (Component) => (props) => (
  <SelectStyle>
    <Component {...props} />
  </SelectStyle>
)

const SelectStyle = ({ children, className, rounded, p, white }) => (
  <div
    className={`
    ${className}
    ${rounded ? 'rounded' : 'rounded-top'}
    absolute
    ${white ? 'white' : 'black'}
    button-hover
    cursor-pointer
    invisible-select
    left-0
    right-0
    top-0
  `}
  >
    <div className={`h4 align right ${p}`}>
      <Icon icon="caret-down" />
    </div>
    {children}
  </div>
)

SelectStyle.defaultProps = defaultProps
SelectStyle.propTypes = propTypes

export { withSelectStyle }
export default SelectStyle
