import ObjectTarget from 'src/components/ObjectTarget/ObjectTarget'
import TvTarget from 'src/components/ObjectTarget/targets/TvTarget'
import PaperTarget from 'src/components/ObjectTarget/targets/PaperTarget'
import RiverTarget from 'src/components/ObjectTarget/targets/RiverTarget'
import CircleTarget from 'src/components/ObjectTarget/targets/CircleTarget'
import MoleTarget from 'src/components/ObjectTarget/targets/MoleTarget'

export {
  TvTarget,
  PaperTarget,
  RiverTarget,
  CircleTarget,
  MoleTarget,
}

export default ObjectTarget
