import React from 'react'
import { string, number } from 'prop-types'
import { t } from 'i18next'
import Div from 'src/components/Div'
import Donut from 'src/components/Donut'

const propTypes = {
  absent: number.isRequired,
  attendance: string.isRequired,
  days: number.isRequired,
  present: number.isRequired,
}

const line = (key, value) => (
  <Div flex mb1 h5>
    <div style={{ width: 150 }}>{t(`attendance.${key}`)}:</div>
    <div className=" bold">{value}</div>
  </Div>
)

const StudentAttendance = ({
  days, present, absent, attendance,
}) => (
  <Div flex auto gutter-1>
    <Div
      col={2}
      mx={1}
      className="center"
    >
      <Donut value={parseInt(attendance, 10) / 100} size={2} fixed={4} />
    </Div>
    <Div auto flex column pl2 pt2 col-6>
      {line('report.days', days)}
      {line('shared.present', present)}
      {line('shared.absent', absent)}
      {line('report.attendance', attendance)}
    </Div>
  </Div>
)

StudentAttendance.propTypes = propTypes

export default StudentAttendance
