import { arrayOf, bool, shape, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import lessonShape from 'src/shapes/lesson'
import localizedField from 'src/shapes/localizedField'

const chapterShape = {
  component: string,
  entities: arrayOf(shape(entityShape)),
  homework: bool,
  id: string,
  identifier: string,
  lesson: shape(lessonShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default chapterShape
