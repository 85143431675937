import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { WordEnglish } from 'src/modules/Word'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import entityShape from 'src/shapes/entity'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
}

const Dictionary = ({ entities }) => (
  <Slides>
    {
      entities.map(entity => (
        <Slide key={entity.id} label={entity.titleEn}>
          <WordEnglish withPartOfSpeech entity={entity} />
        </Slide>
      ))
    }
  </Slides>
)

Dictionary.propTypes = propTypes
Dictionary.demoData = {
  entities: [
    'mother',
    'father',
    'family',
  ],
}

export default Dictionary
