import React from 'react'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'
import Card from 'src/components/Card'
import { lessonsPath } from 'src/lib/routes'

const propTypes = {
  gradeSlug: string.isRequired,
  slug: string.isRequired,
  title: string.isRequired,
}

const LessonsPageSectionGrade = ({ slug, title, gradeSlug }) => (
  <Link to={lessonsPath({ gradeSlug: slug })}>
    <Card
      bold
      button
      fit
      locale="en"
      selected={slug === gradeSlug}
      size={3}
      title={title}
    />
  </Link>
)

LessonsPageSectionGrade.propTypes = propTypes
export default LessonsPageSectionGrade
