import { shape, string } from 'prop-types'
import localizedField from 'src/shapes/localizedField'
import standardShape from 'src/shapes/standard'
import gradeShape from 'src/shapes/grade'

const indicatorShape = {
  code: string,
  grade: shape(gradeShape),
  id: string,
  identifier: string,
  standard: shape(standardShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default indicatorShape
