import React from 'react'
import classNames from 'classnames'
import { arrayOf, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import {
  formatMonthMedium,
  formatDayShortNumeric,
  isAfter,
  dateToday,
  subtract,
  formatDateTimeLong,
  formatDateLong,
} from 'src/lib/dates'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import groupLessonsBySubject from 'src/lib/groupLessonsBySubject'
import lessonShape from 'src/shapes/lesson'
import NoContent from 'src/components/NoContent'
import Progress from 'src/components/Progress'
import TimesTries from 'src/components/TimesTries'
import Widget from 'src/components/Widget'
import { lessonPath, homeworkPath, unitTestPath } from 'src/lib/routes'
import { STUDENT } from 'src/constants/translation'
import Icon from 'src/components/Icon'
import Tooltip from 'src/components/Tooltip'
import { isDevelopment } from 'config'
import LevelSelector from 'src/components/LevelSelector'

const propTypes = {
  studentLessons: shape({
    done: arrayOf(shape(lessonShape)).isRequired,
    todo: arrayOf(shape(lessonShape)).isRequired,
  }),
}

const LessonDueOn = ({ dueOn }) => {
  const isOverDue = dueOn && isAfter(dateToday(), dueOn)
  const isOverDueTomorrow =
    dueOn && isAfter(dateToday(), subtract(dueOn, 1, 'day'))

  return (
    <span
      className={classNames({
        orange: isOverDueTomorrow,
        pl1: true,
        red: isOverDue,
        right: true,
      })}
    >
      {`${formatMonthMedium(dueOn)} ${formatDayShortNumeric(dueOn)}`}
    </span>
  )
}

const HomeworkProgress = ({ actual, grade, rate, total, tries }) => (
  <div className="flex sm-col-6 col-12">
    <Progress
      actual={actual}
      grade={grade}
      secondDimension={rate}
      showRatio
      type={STUDENT}
      total={total}
      colorByGrade
    />
    <TimesTries tries={tries} />
  </div>
)

const HomeworkTodoLink = ({ index, id, identifier, title, dueOn, unitTest }) => (
  <Div flex align-center>
    <Link
      to={(unitTest ? unitTestPath : homeworkPath)(id)}
      id={`qa-homework-${id}`}
    >
      <Div
        flex
        my1
        p1
        button-hover
        rounded
      >
        <Div auto bold flex align="center" h3={index === 0}>
          {identifier} {title}
          {/* eslint-disable-next-line react/jsx-key */}
          {false && isDevelopment && (
            <Div mx2>
              <Tooltip tips={[formatDateLong(dueOn)]}>
                <Icon far icon="square" />
              </Tooltip>
              <LessonDueOn dueOn={dueOn} />
            </Div>
          )}
        </Div>
      </Div>
    </Link>
    <Link to={true ? unitTestPath(id) : lessonPath(id)}>
      <Div flex mx1 p rounded muted border align-center>
        <Div rounded pr1 button-hover flex align-center>
          <Icon icon="book" />{t('navigation.lesson')}
        </Div>
      </Div>
    </Link>
  </Div>
)

const HomeworkProgressLink = ({
  id,
  identifier,
  title,
  actual,
  grade,
  rate,
  total,
  tries,
  unitTest,
  lastDone,
}) => (
  <Link
    to={(unitTest ? unitTestPath : homeworkPath)(id)}
    id={`qa-homework-${id}`}
  >
    <Div
      className="flex flex-column sm-flex-row"
      my={1}
      p={1}
      button-hover
      rounded
    >
      <Div auto bold flex align="center">
        {identifier} {title}
        <Tooltip tips={[formatDateTimeLong(lastDone)]}>
          <Icon icon="check-square" />
        </Tooltip>
      </Div>
      <HomeworkProgress
        actual={actual}
        grade={grade}
        rate={rate}
        total={total}
        tries={tries}
      />
    </Div>
  </Link>
)

const getLessonsLinks = ({ lessons, showDate }) =>
  groupLessonsBySubject(
    lessons,
    ({
      title,
      identifier,
      id,
      total,
      actual,
      dueOn,
      grade,
      rate,
      tries,
      unitTest,
      lastDone,
    }, index) =>
      (showDate ? (
        <HomeworkTodoLink
          index={index}
          key={id}
          id={id}
          identifier={identifier}
          title={title}
          dueOn={dueOn}
          unitTest={unitTest}
        />
      ) : (
        <HomeworkProgressLink
          key={id}
          id={id}
          identifier={identifier}
          title={title}
          actual={actual}
          grade={grade}
          rate={rate}
          total={total}
          tries={tries}
          unitTest={unitTest}
          lastDone={lastDone}
        />
      ))
  )

const Column = ({ userId, title, lessons, icon, showDate, theme }) => {
  const lessonsLinks = getLessonsLinks({ lessons, showDate })
  return (
    <Widget icon={icon} title={t(title)}>
      <Div mbn={1}>{lessonsLinks.length > 0 && lessonsLinks}</Div>
      {lessonsLinks.length === 0 && (
        <Div flex auto justify="center" align="center">
          <NoContent theme={theme}>
            <div>
              <div>{t(`${title}Empty`)}</div>
              {title === 'student.todo' && <LevelSelector userId={userId} />}
            </div>
          </NoContent>
        </Div>
      )}
    </Widget>
  )
}

const StudentHomeworkPage = ({ userId, studentLessons: { todo, done } }) => (
  <Deck>
    <Column
      title="student.todo"
      showDate
      lessons={todo}
      icon="tasks"
      theme="happy"
      userId={userId}
    />
    <Div mb1 />
    <Column title="student.done" lessons={done} icon="check" />
  </Deck>
)

StudentHomeworkPage.propTypes = propTypes

export default StudentHomeworkPage
