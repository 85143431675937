import React, { useState } from 'react'
import { func } from 'prop-types'
import QrReader from 'react-qr-reader'
import { t } from 'i18next'
import Icon from 'src/components/Icon'
import Button from 'src/components/Button'
import ReactModal from 'react-modal'
import Div from 'src/components/Div'

const propTypes = {
  onScan: func.isRequired,
}

const UserCardScanner = ({ onScan }) => {
  const [error, setError] = useState(null)
  const [scanning, setScanning] = useState(false)
  const handleScan = (data) => {
    if (data === null) return
    setScanning(false)
    onScan(data)
  }
  return (
    <div>
      <Button
        onClick={() => setScanning(!scanning)}
      >
        <Icon icon="qrcode" />{t('attendance.scanCard')}
      </Button>
      {scanning && (
        <ReactModal
          isOpen={scanning}
          onRequestClose={() => setScanning(false)}
          className="modal-fit"
        >
          <div className="modal-fit--container">
            <div className="modal-fit--body">
              <QrReader
                delay={100}
                onError={setError}
                onScan={handleScan}
              />
              {error && <Div red className="qa-error">{error.message}</Div>}
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  )
}

UserCardScanner.propTypes = propTypes

export default UserCardScanner
