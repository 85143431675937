import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import {
  enableSoftKeyboard,
  disableSoftKeyboard,
} from 'src/components/SoftKeyboard/reducer'
import * as cardActions from 'src/modules/ListenAndTypeSentences/reducer'
import ListenAndTypeSentences from 'src/modules/ListenAndTypeSentences/ListenAndTypeSentences'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
  variants: null,
  isSoftKeyboardShow: false,
}

class ListenAndTypeSentencesContainer extends Component {
  componentDidMount() {
    this.init()
    this.props.enableSoftKeyboard()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  componentWillUnmount() {
    this.props.disableSoftKeyboard()
  }

  render() {
    const {
      actualEntities,
      completed,
      enterCount,
      entity,
      handleChange,
      mark,
      phonicsById,
      place,
      retry,
      started,
      wordBank,
      isSoftKeyboardShow,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={enterCount > 1 ? completed : null}
        mark={mark}
        retry={retry}
      >
        <ListenAndTypeSentences {...{
          completed,
          enterCount,
          entity,
          handleChange,
          mark,
          phonicsById,
          place,
          started,
          wordBank,
          isSoftKeyboardShow,
        }}
        />
      </Game>
    )
  }
}

const select = state => ({ ...state.listenAndTypeSentences, ...state.softKeyboard })

const actions = dispatch => (
  bindActionCreators({
    ...cardActions,
    disableSoftKeyboard,
    enableSoftKeyboard,
  }, dispatch)
)

ListenAndTypeSentencesContainer.propTypes = propTypes
ListenAndTypeSentencesContainer.defaultProps = defaultProps
ListenAndTypeSentencesContainer.demoData = {
  entities: [
    'phrase-this-is-my-family',
    'phrase-she-is-my-elder-sister',
    'phrase-she-is-cooking-dinner',
    'phrase-she-is-washing-the-dishes',
    'phrase-they-are-reading-books',
  ],
}

export { ListenAndTypeSentencesContainer }
export default connect(select, actions)(ListenAndTypeSentencesContainer)
