import React from 'react'
import ListenAndShootContainer from 'src/modules/ListenAndShoot/ListenAndShootContainer'

const defaultProps = {
  mixFont: true,
  text: true,
}

const ListenAndShootPrintedAndCursiveText = props => <ListenAndShootContainer {...props} />

ListenAndShootPrintedAndCursiveText.defaultProps = defaultProps
ListenAndShootPrintedAndCursiveText.demoData = {
  cols: 4,
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
    'mother',
  ],
  rows: 1,
}

export default ListenAndShootPrintedAndCursiveText
