import { PARENT_ROLE, STAFF_ROLE, STUDENT_ROLE, TEACHER_ROLE } from 'src/constants/roles'

export const PORTAL_SCOPE = 'portal'
export const SCHOOL_SCOPE = 'school'
export const STUDENT_SCOPE = 'student'
export const TEACHER_SCOPE = 'teacher'

export const scopeForRole = {
  [PARENT_ROLE]: PORTAL_SCOPE,
  [STAFF_ROLE]: SCHOOL_SCOPE,
  [STUDENT_ROLE]: STUDENT_SCOPE,
  [TEACHER_ROLE]: TEACHER_SCOPE,
}
