import React from 'react'
import { bool, shape, string } from 'prop-types'
import { Link } from 'react-router-dom'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import SchoolTitle from 'src/components/SchoolTitle'
import { userHomePagePath } from 'src/lib/routes'
import userShape from 'src/shapes/user'

const propTypes = {
  color: string,
  data: shape({
    user: shape(userShape),
  }).isRequired,
  showTitle: bool,
  underline: bool,
}

const defaultProps = {
  color: 'white',
  data: {
    user: {},
  },
  showTitle: true,
  underline: false,
}

const HomeButton = ({ data: { user }, color, showTitle, underline }) => (
  <Div flex align-center button-hover relative>
    <Link
      style={{ fontSize: 16, padding: 8 }}
      className={`flex align-center ${color}`}
      to={userHomePagePath(user)}
    >
      <Div flex align-center>
        <Icon icon="home" style={{ fontSize: 22 }} />
        <Div sm-show>
          {showTitle && <div style={{ paddingLeft: 4 }}><SchoolTitle /></div>}
        </Div>
      </Div>
    </Link>
    {
      underline &&
        <Div bg="blue" muted absolute right-0 bottom-0 left-0 style={{ height: 4 }} />
    }
  </Div>
)

HomeButton.propTypes = propTypes
HomeButton.defaultProps = defaultProps
export default HomeButton
