import React from 'react'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const STYLE = {
  WebkitTextStroke: '1px rgba(255,255,255,.5)',
}

const MobileLoading = ({ queriesCount }) => {
  const { Capacitor } = window
  const isMobileApp = Capacitor && Capacitor.isNative
  const loading = queriesCount > 0
  if (!(isMobileApp && loading)) return null

  return (
    <Div
      absolute
      flex
      align="center"
      justify="center"
      style={{
        height: '100%',
        zIndex: '99999',
      }}
      className="bg-aqua less-transparent col-12"
    >
      <Div
        flex
        className="h2 animated slowly fadeIn blue"
        style={STYLE}
      >
        <Icon spin icon="circle-notch" />
      </Div>
    </Div>
  )
}

export default MobileLoading
