import React from 'react'
import usePageParams from 'src/lib/usePageParams'
import LessonsPage from 'src/pages/LessonsPage'
import LessonsLayout from 'src/pages/LessonsLayout'

const LessonsLayoutPage = () => (
  <LessonsLayout>
    <LessonsPage {...usePageParams()} />
  </LessonsLayout>
)

export default LessonsLayoutPage
