import React from 'react'
import { bool, func, object, string, number } from 'prop-types'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Bubble from 'src/components/Bubble'
import Card from 'src/components/Card'
import EntityBubble from 'src/components/EntityBubble'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EntityImage from 'src/components/EntityImage'

const propTypes = {
  answerId: string,
  audioRepeat: number,
  autoPlay: bool,
  cards: object,
  entitiesById: object,
  onClick: func.isRequired,
  showImage: bool,
}

const defaultProps = {
  answerId: '',
  audioRepeat: 1,
  autoPlay: true,
  cards: {},
  entitiesById: {},
  showImage: true,
}

const ListeningWords = ({
  audioRepeat,
  entitiesById,
  onClick,
  autoPlay,
  answerId,
  cards,
  showImage,
}) => (
  <Deck>
    <Div flex justify-center mb4>
      <EntityBubble
        key={answerId}
        audio
        audioRepeat={audioRepeat}
        play
        autoPlay={autoPlay}
        english
        entity={entitiesById[answerId]}
        label=" "
        col={3}
      />
    </Div>
    <Div flex>
      {
        map(cards, card => (
          <Div auto flex px={1} key={card.id}>
            <Bubble label={card.label} onKeyPress={() => onClick({ id: card.id })}>
              <Card
                auto
                shrink
                className="flex"
                selected={card.selected}
                correct={card.correct}
                onClick={() => onClick({ id: card.id })}
              >
                {
                  showImage && (
                    <Div flex height={15}>
                      <EntityImage entity={entitiesById[card.id]} />
                    </Div>
                  )
                }
                <AdaptiveCard
                  english
                  silent
                  entity={entitiesById[card.id]}
                  height={showImage ? 8 : 20}
                  minHeight={false}
                  transparent
                />
              </Card>
            </Bubble>
          </Div>
        ))
      }
    </Div>
  </Deck>
)

ListeningWords.propTypes = propTypes
ListeningWords.defaultProps = defaultProps

export default ListeningWords
