import gradeToColorData from 'src/../config/gradeToColor.json'

const percentCal = (percent) => {
  if (percent % 5 < 3 || percent % 5 === 0) {
    return Math.floor(percent / 5) * 5
  }
  return Math.ceil(percent / 5) * 5
}

const rateToColor = (ratio) => {
  const percent = ratio * 100
  const percentUp = percentCal(percent)
  if (percentUp >= gradeToColorData[4].percent) {
    return gradeToColorData[4].color
  } if (percentUp >= gradeToColorData[3].percent) {
    return gradeToColorData[3].color
  } if (percentUp >= gradeToColorData[2].percent) {
    return gradeToColorData[2].color
  } if (percentUp >= gradeToColorData[1].percent) {
    return gradeToColorData[1].color
  }
  return gradeToColorData[0].color
}


const percentToGrade = (percent) => {
  const thePercent = percent <= 1 ? percent * 100 : percent
  if (thePercent >= gradeToColorData[4].percent) return 4
  if (thePercent >= gradeToColorData[3.5].percent) return 3.5
  if (thePercent >= gradeToColorData[3].percent) return 3
  if (thePercent >= gradeToColorData[2.5].percent) return 2.5
  if (thePercent >= gradeToColorData[2].percent) return 2
  if (thePercent >= gradeToColorData[1.5].percent) return 1.5
  if (thePercent >= gradeToColorData[1].percent) return 1
  return 0
}

const gradeToColor = grade => gradeToColorData[grade].color


export default rateToColor

export { percentToGrade, gradeToColor }
