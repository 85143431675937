import React from 'react'
import { Redirect } from 'react-router-dom'
import { shape, string } from 'prop-types'
import { lessonsPath } from 'src/lib/routes'

const propTypes = {
  lowestGrade: shape({
    slug: string.isRequired,
  }).isRequired,
}

const LessonRedirect = ({ lowestGrade: { slug } }) => {
  const path = lessonsPath({ gradeSlug: slug })
  return <Redirect to={path} />
}

LessonRedirect.propTypes = propTypes

export default LessonRedirect
