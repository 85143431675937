import React from 'react'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import Img from 'src/components/Img'
import classnames from 'classnames'
import { func, bool, arrayOf, shape } from 'prop-types'
import { t } from 'i18next'
import module from 'src/shapes/entity'
import IterationIcon from 'src/components/IterationIcon'
import Hover from 'src/utilities/Hover'
import KeyPress from 'src/components/KeyPress'
import HomeworkIteration from 'src/components/HomeworkIteration'
import ShortcutInfo from 'src/modules/TitlePage/ShortcutInfo'

const propTypes = {
  help: func,
  homeworkIterations: arrayOf(bool),
  mark: func,
  next: func,
  nextMessage: func,
  nextModuleInstruction: shape(module),
  preview: bool,
  previous: func,
  previousMessage: func,
  previousModuleInstruction: shape(module),
  shortcutInfo: bool,
}

const defaultProps = {
  help: null,
  homeworkIterations: [],
  mark: null,
  next: null,
  nextMessage: () => {},
  nextModuleInstruction: {},
  preview: false,
  previous: null,
  previousMessage: () => {},
  previousModuleInstruction: {},
  shortcutInfo: false,
}

const helpMessage = t('navigation.help')
const markMessage = t('navigation.mark')

const Navigation = ({
  help,
  homeworkIterations,
  mark,
  shortcutInfo,
  next,
  nextMessage,
  nextModuleInstruction,
  preview,
  previous,
  previousMessage,
  previousModuleInstruction,
}) => (
  <Div flex justify="center" bg="darken-1" relative z3 noPrint>
    <Div auto flex iterationIcon>
      {shortcutInfo && <ShortcutInfo />}
      <HomeworkIteration homeworkIterations={homeworkIterations} />
    </Div>
    <Div
      flex
      z1
      className={`
        shadow
        bg-darken-2
        roundedTopLeft
        roundedTopRight
        no-text-selection
        md-col-${preview ? 12 : 6}
      `}
      col-12
    >
      <Div
        align="center"
        bold
        col={3}
        flex
        id="qa-prev"
        justify="center"
        onClick={previous}
        className={classnames({
          'blue button-hover cursor-pointer': previous,
          'gray muted': !previous,
          'roundedTopLeft bg-white': true,
        })}
      >
        {previous && <KeyPress keys={{ ArrowLeft: previous }} />}
        <Hover
          id="qa-previousModuleInstruction"
          className="col-12 p1"
          disableHoverOnMobile
          hoverStyle={{ bottom: '3.5rem', right: '22rem' }}
          onHover={
            (
              { ...previousModuleInstruction.image }.src && (
                <Div height={18} width={32} shadow animated fadeIn slowly>
                  <Img
                    src={{ ...previousModuleInstruction.image }.src}
                    cover
                  />
                </Div>
              )
            )
          }
        >
          <Div flex align-center justify-center>
            <Icon icon="arrow-left" before />
            {previousMessage && previousMessage()}
          </Div>
        </Hover>
      </Div>
      <Div
        align="center"
        bold
        flex
        id="qa-mark"
        justify="center"
        onClick={() => mark && mark()}
        style={{ margin: '0 1px' }}
        className={classnames({
          'bg-white gray muted': !mark,
          'button-hover cursor-pointer bg-blue white bold': mark,
          'md-col-3 ap1': true,
        })}
        col-6
      >
        <Div className="nowrap overflow-hidden ellipsis">
          {markMessage}
        </Div>
        <Icon icon="level-down-alt fa-rotate-90" />
      </Div>
      <Div
        align="center"
        bold
        col={3}
        flex
        id="qa-help"
        justify="center"
        onClick={() => help && help()}
        style={{ marginRight: '1px' }}
        className={classnames({
          'bg-white': true,
          'button-hover cursor-pointer blue': help,
          'gray muted': !help,
        })}
        navigation-help
      >
        <Div className="nowrap overflow-hidden ellipsis">
          {helpMessage}
        </Div>
        <span className="pl"> F1</span>
        {help && <KeyPress keys={{ F1: help }} />}
      </Div>
      <Div
        align="center"
        bold
        col={3}
        flex
        id="qa-next"
        justify="center"
        onClick={next}
        className={classnames({
          'button-hover cursor-pointer blue': next,
          'gray muted': !next,
          'roundedTopRight bg-white': true,
        })}
      >
        <Hover
          id="qa-nextModuleInstruction"
          className="col-12 p1"
          disableHoverOnMobile
          hoverStyle={{ bottom: '3.5rem', left: '1rem' }}
          onHover={
            (
              { ...nextModuleInstruction.image }.src && (
                <Div height={18} width={32} shadow animated fadeIn slowly>
                  <Img
                    src={{ ...nextModuleInstruction.image }.src}
                    cover
                  />
                </Div>
              )
            )
          }
        >
          <Div flex align-center justify-center>
            {nextMessage && nextMessage()}
            <Icon icon="arrow-right" after />
          </Div>
        </Hover>
        {next && <KeyPress keys={{ ArrowRight: next }} />}
      </Div>
    </Div>
    <Div auto flex iterationIcon>
      <IterationIcon />
    </Div>
  </Div>
)

Navigation.propTypes = propTypes
Navigation.defaultProps = defaultProps

export default Navigation
