import React from 'react'
import { renderRoutes } from 'react-router-config'
import { array, string } from 'prop-types'
import GeneralFilter from 'src/components/GeneralFilter'
import Div from 'src/components/Div'
import { storyboardsFullPath } from 'src/lib/routes'
import PrintInstructions from 'src/components/PrintInstructions'
import routes from 'src/pages/Storyboards/routes'

const propTypes = {
  gradeSlug: string,
  sections: array,
  subjects: array,
  subjectSlug: string,
  unitSlug: string,
}

const defaultProps = {
  gradeSlug: '',
  sections: [],
  subjectSlug: '',
  subjects: [],
  unitSlug: '',
}

const firstGradeId = (sections) => {
  if (sections.length > 0) {
    if (sections[0].grades.length > 0) {
      return sections[0].grades[0].id
    }
  }
  return null
}

const ManualPage = ({
  gradeSlug,
  sections,
  subjectSlug,
  subjects,
  unitSlug,
}) => (
  <Div flex column>
    <Div print-hide>
      <GeneralFilter
        redirectPath={storyboardsFullPath}
        gradeSlug={gradeSlug || firstGradeId(sections)}
        subjectSlug={subjectSlug || subjects[0].slug}
        unitSlug={unitSlug}
        showUnits
      />
    </Div>
    <PrintInstructions />
    <Div relative>{renderRoutes(routes)}</Div>
  </Div>
)

ManualPage.defaultProps = defaultProps
ManualPage.propTypes = propTypes
export default ManualPage
