import SpeechBubblesQuestionAndAnswerContainer, {
  SpeechBubblesQuestionAndAnswerWithThaiContaine as SpeechBubblesQuestionAndAnswerWithThai,
} from 'src/modules/SpeechBubblesQuestionAndAnswer/SpeechBubblesQuestionAndAnswerContainer'
import reducer from 'src/modules/SpeechBubblesQuestionAndAnswer/reducer'

export {
  reducer,
  SpeechBubblesQuestionAndAnswerWithThai,
}
export default SpeechBubblesQuestionAndAnswerContainer
