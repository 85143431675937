class Queue {
  constructor() {
    this.callbacks = []
  }

 deviceReady = () => {
   this.ready = true
   this.callbacks.map(callback => callback())
   this.callbacks = []
 }

  queue = (callback) => {
    if (this.ready) {
      callback()
      return
    }
    this.callbacks = this.callbacks.concat(callback)
  }
}

const theQueue = new Queue()

const queue = callback => theQueue.queue(callback)

document.addEventListener('deviceready', theQueue.deviceReady)
export default queue
