import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'
import { path as teacherGuidePath } from 'src/pages/TeacherGuides/Lesson'
import { path as teacgerGuidesPath } from 'src/pages/TeacherGuides/Lessons'

const path = '/:domain/guides'
const matchPaths = [teacherGuidePath, teacgerGuidesPath]
const scene = {
  icon: icons[menu.LESSONS],
  id: menu.TEACHER_GUIDES,
  matchPaths,
  path,
}
export {
  path,
  matchPaths,
  scene,
}
