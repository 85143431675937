import React from 'react'
import { t } from 'i18next'
import ahoy from 'ahoy.js'
import trackEvent, { setUser } from 'src/components/analytics'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'
import { location, localStorage } from 'src/lib/window'
import { loginPath, lessonsPath } from 'src/lib/routes'
import wait from 'src/lib/wait'

const logout = async (redirectionPath = loginPath) => {
  trackEvent({
    action: 'logout',
    category: 'user',
  })
  await wait(1000)
  setUser(null)
  localStorage.removeItem('token')
  ahoy.reset()
  location.replace(redirectionPath())
}

const handleLogout = () => logout(lessonsPath)

const Logout = () => (
  <Div
    flex
    p1
    align-center
    nowrap
    button-hover
    px1
    cursor-pointer
    qa-logout-button
    onClick={handleLogout}
    onDoubleClick={handleLogout}
  >
    <Div sm-show>
      {t('logout.button')}
    </Div>
    <Icon icon="sign-out-alt" />
  </Div>
)

export default Logout
export {
  logout,
}
