import React from 'react'
import { useParams, useHistory } from 'react-router'
import StudentProgressPage from 'src/pages/StudentSkillsPage'
import { linkTo } from 'src/lib/linkTo'
import StudentPage from 'src/pages/PortalLayout/StudentPage'

const PortalStudentProgress = ({ path }) => {
  const history = useHistory()
  const { domain, subjectId, studentId, parentId } = useParams()
  return (
    <StudentPage path={path}>
      <StudentProgressPage
        subjectId={subjectId}
        onSubjectChange={subjectId => history.push(
          linkTo(path)({ domain, parentId, studentId, subject: 'subject', subjectId }),
        )}
      />
    </StudentPage>
  )
}

export default PortalStudentProgress
