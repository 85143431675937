import compact from 'lodash/compact'
import { PORTAL_SCOPE, STUDENT_SCOPE } from 'src/constants/routeScopes'

const calendarPath = ({ domainContext: domain, userContext: user }) => ({ yearId, date } = {}) => (
  compact([
    `/${domain}`,
    [STUDENT_SCOPE, PORTAL_SCOPE].includes(domain) && user && `/${user.slug}`,
    '/calendar',
    yearId && `/year/${yearId}`,
    date && `/${date}`,
  ]).join('')
)

export default calendarPath
