import React from 'react'
import { func, shape, string } from 'prop-types'
import chunk from 'lodash/chunk'
import size from 'lodash/size'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityBubble from 'src/components/EntityBubble'

const propTypes = {
  cards: shape().isRequired,
  entitiesById: shape().isRequired,
  onClick: func.isRequired,
  questionId: string.isRequired,
}

const FollowTheInstructions = ({ cards, onClick, entitiesById, questionId }) => (
  <Deck>
    <Div gutter={1} justify="center" flex mb3>
      <EntityBubble
        audio
        english
        entity={entitiesById[questionId]}
        key={questionId}
        label=" "
      />
    </Div>
    <Div gutter={1} justify="center" flex column auto>
      {
        chunk(Object.values(cards), size(cards) / 2).map(row => (
          <Div flex auto key={row[0].id} mb3>
            {
              row.map(card => (
                <Div key={card.id} flex auto p>
                  <EntityBubble
                    audio
                    correct={card.correct}
                    english
                    entity={entitiesById[card.id]}
                    id={card.id}
                    image
                    label={card.label}
                    minHeight={false}
                    onClick={() => onClick({ targetId: card.id })}
                    onKeyPress={() => onClick({ targetId: card.id })}
                    selected={card.selected}
                  />
                </Div>
              ))
            }
          </Div>
        ))
      }
    </Div>
  </Deck>
)

FollowTheInstructions.propTypes = propTypes
export default FollowTheInstructions
