import React from 'react'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { lessonPath } from 'src/lib/routes'
import { t } from 'i18next'

const propTypes = {
  lessonSlug: string.isRequired,
}

const LessonButton = ({ lessonSlug }) => (
  <Link
    className="button-hover blue"
    to={lessonPath(lessonSlug)}
    style={{
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '6px 16px 0 8px',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
    }}
  >
    <Icon
      icon="book"
      style={{
        fontSize: '22px',
        marginRight: '4px',
        padding: '0 8px',
      }}
    />
    <Div inline-block align-top>
      {t('navigation.lesson')}
    </Div>
  </Link>
)

LessonButton.propTypes = propTypes
export default LessonButton
