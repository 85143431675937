import { string } from 'prop-types'

const roleShape = {
  id: string,
  title: string,
  titleEn: string,
  titleTh: string,
}

export default roleShape
