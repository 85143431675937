const formatActualDecimals = (actual, stripInsignificantZero = true) => {
  if (!stripInsignificantZero) return parseFloat(actual).toFixed(1)
  if (!Number.isInteger(actual)) {
    if (actual.toFixed(1) === '0.0') return 0
    return actual.toFixed(1)
  }
  return actual
}

export default formatActualDecimals
