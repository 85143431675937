import SortingImagesHomework from 'src/modules/Sorting/SortingImagesHomework'
import SortingImagesLesson from 'src/modules/Sorting/SortingImagesLesson'
import SortingWordsHomework from 'src/modules/Sorting/SortingWordsHomework'
import SortingWordsLesson from 'src/modules/Sorting/SortingWordsLesson'

import reducer from 'src/modules/Sorting/reducer'

export {
  reducer,
  SortingImagesHomework,
  SortingImagesLesson,
  SortingWordsHomework,
}

export default SortingWordsLesson
