import React from 'react'
import { string } from 'prop-types'
import ObjectRotation from 'src/components/ObjectRotation'


const propTypes = {
  theme: string.isRequired,
}

const GUNS = {
  backyard: 'twin',
  fence: 'twin',
  mole: null,
  river: 'crossbow',
  space: 'spacegun',
}

const ObjectRotationSelector = ({ theme }) => (
  GUNS[theme] && <ObjectRotation objectImage={GUNS[theme]} />
)

ObjectRotationSelector.propTypes = propTypes
export default ObjectRotationSelector
