import React, { Component } from 'react'
import { string, func } from 'prop-types'
import { graphql } from '@apollo/react-hoc'
import { dateToday, add, formatMonthMedium, formatDayMediumNumeric } from 'src/lib/dates'
import DatePicker from 'src/components/DatePicker'
import { t } from 'i18next'

import Access from 'src/components/Access'
import trackEvent from 'src/components/analytics'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import mutation from 'src/pages/LessonsPage/Lessons/setHomeworkDueOn.gql'

const propTypes = {
  dueOn: string,
  lessonId: string.isRequired,
  mutate: func.isRequired,
  yearId: string,
}

const defaultProps = {
  dueOn: '',
  yearId: undefined,
}

class LessonsPageLessonsToggleReleaseHomework extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      pending: false,
    }
  }

  handleToggleRelease() {
    const { dueOn } = this.props
    const date = dueOn ? '' : add(dateToday(), 1, 'week')
    this.handleMutate(date)
  }

  toggleCalendar() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  async handleMutate(dueOn, force = false) {
    const { dueOn: prevDueOn, lessonId, mutate, yearId } = this.props
    this.setState({ pending: true })
    try {
      const { data: { setHomeworkDueOn: { lesson: { slug: lessonSlug } } } } = await mutate({
        variables: { dueOn, force, lessonId, yearId },
      })
      if (!dueOn && prevDueOn) {
        trackEvent({ action: 'unrelease', category: 'homework', label: lessonSlug })
      }
      if (!prevDueOn && dueOn) {
        trackEvent({ action: 'release', category: 'homework', label: lessonSlug })
      }
    } catch (err) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm(t('lessons.unrelease.warning'))) {
        this.handleMutate(dueOn, true)
      }
    }
    this.setState({ pending: false })
  }

  handleSelect(date) {
    this.handleMutate(date)
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { isOpen, pending } = this.state
    const { yearId, dueOn, lessonId } = this.props
    return (
      <Access teacher staff>
        <a
          className={`${yearId ? 'button-hover cursor-pointer' : ''} p1 rounded mln3 mr1`}
          id={`qa-assign-lesson-${lessonId}`}
          onClick={() => yearId && this.handleToggleRelease()}
        >
          <Icon
            muted={!yearId}
            center
            className={(pending && 'gray') || (dueOn && 'green') || (yearId && 'red') || 'gray'}
            icon={dueOn ? 'check-square' : 'square'}
          />
        </a>
        {
          dueOn && (
          <button
            className="btn btn-outline h6 mr2"
            onClick={() => this.toggleCalendar()}
          >
            {`${formatMonthMedium(dueOn)} ${formatDayMediumNumeric(dueOn)}`}
          </button>
          )
        }
        {
          isOpen && (
          <Div inline-block animated fadeIn>
            <DatePicker
              withPortal
              inline
              onClickOutside={() => this.toggleCalendar()}
              selected={dueOn}
              onChange={date => this.handleSelect(date)}
            />
          </Div>
          )
        }
      </Access>
    )
  }
}

LessonsPageLessonsToggleReleaseHomework.defaultProps = defaultProps
LessonsPageLessonsToggleReleaseHomework.propTypes = propTypes

export default graphql(mutation)(LessonsPageLessonsToggleReleaseHomework)
