import React from 'react'
import { useHistory } from 'react-router'
import { arrayOf, shape } from 'prop-types'
import { t } from 'i18next'

import Breadcrumbs from 'src/components/Breadcrumbs'
import Widget from 'src/components/Widget'
import OverviewChart from 'src/pages/SchoolOverview/shared/OverviewChart'
import academicYearShape from 'src/shapes/academicYear'
import { schoolOverviewReportPath } from 'src/lib/routes'
import { icon } from 'src/pages/SchoolOverview/config'
import sectionShape from 'src/shapes/section'
import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'
import usePath from 'src/lib/routes/usePath'
import HomeworkPageTableRow from 'src/pages/SchoolOverview/shared/TableRow'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  sections: arrayOf(shape(sectionShape)),
}

const defaultProps = {
  dataset: [],
  sections: [],
}

const SchoolOverviewScene = ({ academicYears, sections }) => {
  const history = useHistory()
  const path = usePath(schoolOverviewReportPath)
  return (
    <Widget
      icon={icon}
      title={<Breadcrumbs currentTitle={t('school.overview')} />}
    >
      <OverviewChart
        academicYears={academicYears}
        dataset={sections}
        onClick={section => history.push(path({ sectionId: section.slug }))}
      />
      <StickyTable>
        <StickyTableHeader>
          <th className="col-2">{t('user.section')}</th>
          <th className="col-1">{t('homework.students')}</th>
          <th className="col-1">{t('homework.grade')}</th>
          <th className="col-4">{t('homework.completedRate')}</th>
          <th className="col-4">{t('homework.assignedRate')}</th>
        </StickyTableHeader>
        <tbody>
          {
            sections.map(section => (
              <HomeworkPageTableRow
                key={section.id}
                resource={section}
                isSection
                linkTo={path({ sectionId: section.slug })}
              />
            ))
          }
        </tbody>
      </StickyTable>
    </Widget>
  )
}

SchoolOverviewScene.propTypes = propTypes
SchoolOverviewScene.defaultProps = defaultProps

export default SchoolOverviewScene
