import React from 'react'
import { any, bool, func, number, object, oneOfType, string } from 'prop-types'
import classnames from 'classnames'
import audioPlayer from 'src/lib/audioPlayer'
import KeyPress from 'src/components/KeyPress'
import Core from 'src/components/Card/core'
import DraggableCard from 'src/components/Card/DraggableCard'

const propTypes = {
  adaptiveSize: bool,
  align: string,
  audio: bool,
  audioRepeat: number,
  auto: bool,
  autoPlay: bool,
  autoPlayOnce: bool,
  bg: string,
  bold: bool,
  button: bool,
  children: any,
  className: string,
  correct: bool,
  cursive: bool,
  dashed: bool,
  draggable: bool,
  fit: bool,
  height: number,
  image: bool,
  inline: bool,
  isPreview: bool,
  justify: string,
  keyToPlay: oneOfType([string, number]),
  lessTransparent: bool,
  m: bool,
  minHeight: oneOfType([string, number, bool]),
  minWidth: number,
  muted: bool,
  notInGroup: bool,
  onClick: func,
  p: oneOfType([number, bool]),
  paddingLeft: number,
  paddingRight: number,
  playNext: func,
  round: bool,
  selected: bool,
  semiTransparent: bool,
  shrink: bool,
  silent: bool,
  size: number,
  src: oneOfType([bool, string]),
  state: string,
  stopPlay: func,
  style: object,
  title: oneOfType([string, number]),
  transparent: bool,
  type: string,
  whiteSpace: string,
  width: number,
}

const defaultProps = {
  audioRepeat: 1,
  adaptiveSize: false,
  align: 'center',
  audio: false,
  autoPlay: false,
  autoPlayOnce: false,
  bg: null,
  correct: null,
  cursive: null,
  height: null,
  image: false,
  inline: false,
  isPreview: false,
  justify: 'center',
  keyToPlay: null,
  lessTransparent: false,
  m: true,
  muted: false,
  notInGroup: false,
  onClick: null,
  p: null,
  playNext: null,
  round: false,
  selected: false,
  silent: false,
  stopPlay: null,
  type: null,
  width: null,
  whiteSpace: undefined,
}

const selectedClassName = {
  completed: 'bg-lime muted',
  false: 'bg-white',
  gray: 'bg-darken-1',
  null: 'bg-white',
  selected: 'bg-aqua',
  true: 'bg-aqua',
  undefined: 'bg-white',
}

const selectedClassNameWithBG = ({ state, bg }) => {
  if ((state === undefined || state === false || state === null) && bg) return `bg-${bg}`
  return selectedClassName[state]
}

const correctClassName = {
  false: 'bg-red',
  true: 'bg-lime',
}

const selectedTextColor = ({ correct, bg, theState }) => (
  `${correctClassName[correct] || selectedClassNameWithBG({ bg, state: theState })}`
)

const wrapIfDraggable = (core, { draggable, ...props }) => (
  draggable
    ? <DraggableCard {...props}>{core}</DraggableCard>
    : core
)

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      audioIteration: 0,
      playing: false,
    }
  }

  componentDidMount() {
    this.initSound()
  }

  componentDidUpdate({ autoPlay }) {
    this.initSound()
    this.playInGroup({ prevAutoPlay: autoPlay })
  }

  componentWillUnmount() {
    this.cancelledCallbacks = true
    if (this.player) this.player.off()
  }

  setKeyToPlay(keyToPlay) {
    this.onKeyPress = {
      [keyToPlay]: () => this.playSound(),
    }
  }

  handleOnClick = () => {
    const { onClick, silent } = this.props
    if (onClick) onClick()
    if (!silent) {
      this.playSound()
    }
  }

  playSound({ playOnce = false } = {}) {
    const { isPreview } = this.props
    if (this.cancelledCallbacks) return
    if (this.player) {
      this.player.play({ playOnce, silent: isPreview })
    }
  }

  audioPlaying() {
    if (this.cancelledCallbacks) return
    this.setState({
      playing: true,
    })
  }

  audioDone() {
    this.setState({
      playing: false,
    })
  }

  playInGroup({ prevAutoPlay }) {
    const { autoPlay, notInGroup } = this.props
    if (!notInGroup) {
      const autoPlayUpdate = prevAutoPlay !== autoPlay
      if (autoPlayUpdate && autoPlay) {
        this.playSound()
      }
    }
  }

  initSound() {
    const { keyToPlay, src, silent } = this.props
    if (keyToPlay && !silent) this.setKeyToPlay(keyToPlay)
    if (src && (!this.player || src !== this.player.getSrc())) {
      this.setState({
        audioIteration: 0,
      })
      this.player = audioPlayer({
        onEnd: () => {
          const { audioIteration } = this.state
          const { audioRepeat } = this.props
          if (audioIteration + 1 >= audioRepeat) {
            this.setState({ audioIteration: 0 })
            const { playNext } = this.props
            this.audioDone()
            if (playNext && !this.cancelledCallbacks) {
              playNext()
            }
          } else {
            this.setState({ audioIteration: audioIteration + 1 })
            this.playSound()
          }
        },
        onLoad: () => {
          const { autoPlay, autoPlayOnce } = this.props
          if ((autoPlay || autoPlayOnce)) this.playSound({ playOnce: autoPlayOnce })
        },
        onPlay: () => this.audioPlaying(),
        onStop: () => {
          const { stopPlay } = this.props
          this.audioDone()
          if (stopPlay && !this.cancelledCallbacks) stopPlay()
        },
        src,
      })
    }
  }

  render() {
    const {
      adaptiveSize,
      align,
      audio,
      auto,
      bg,
      bold,
      button,
      children,
      className,
      correct,
      cursive,
      dashed,
      draggable,
      fit,
      height,
      image,
      inline,
      justify,
      lessTransparent,
      m,
      minHeight,
      minWidth,
      muted,
      onClick,
      p,
      paddingLeft,
      paddingRight,
      round,
      selected,
      semiTransparent,
      shrink,
      src,
      size,
      state,
      style,
      title,
      transparent,
      type,
      whiteSpace,
      width,
    } = this.props
    const { playing } = this.state
    const theState = state || selected || playing
    const theType = type || (image && 'image')
    const pointer = onClick || (audio && src) || button
    const pointerNone = !pointer && !draggable
    const sizeStyle = {
      height: (height ? `${height}rem` : false),
      paddingLeft: (paddingLeft ? `${paddingLeft}rem` : false),
      paddingRight: (paddingRight ? `${paddingRight}rem` : false),
      width: (width ? `${width}rem` : false),
    }
    const core = (
      <div
        onClick={this.handleOnClick}
        tabIndex={0}
        role="button"
        onDoubleClick={() => false}
        className={classnames({
          [`${className}`]: className,
          'bg-transparent': transparent,
          bold,
          Card: true,
          cursive,
          'cursor-pointer button-hover': pointer,
          dashed,
          'flex-column': !shrink && !inline,
          flex: !shrink && !inline,
          'flex-1': !fit,
          'flex-1 cursor-move button-hover': draggable,
          'flex-auto': auto,
          image,
          inline,
          'inline-block': shrink,
          'less-transparent': lessTransparent,
          m,
          muted,
          p: typeof p === 'boolean',
          'pointer-none': pointerNone,
          px1: fit,
          relative: true,
          round,
          rounded: !round,
          'semi-transparent': semiTransparent,
          [`transition-all line-height-12 justify-${justify}`]: true,
          [`${selectedTextColor({ bg, correct, theState })}`]: true,
          [`align-${align} ${align}`]: !adaptiveSize,
          [`h${size === undefined ? 1 : size}`]: !adaptiveSize,
          [`p${p}`]: typeof p === 'number',
        })}
        style={{
          ...sizeStyle,
          ...style,
          minHeight: ((!(fit || shrink || minHeight === false || auto || typeof minHeight === 'number') && '11rem') || (minHeight !== false && `${minHeight}rem`)),
          minWidth: (minWidth && `${minWidth}rem`),
          whiteSpace: (whiteSpace && `${whiteSpace}`),
        }}
      >
        {children || <Core {...{ inline, title, type: theType }} />}
        {this.onKeyPress && <KeyPress keys={this.onKeyPress} />}
      </div>
    )
    return wrapIfDraggable(core, this.props)
  }
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
