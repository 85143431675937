import React from 'react'
import { func } from 'prop-types'
import { t } from 'i18next'
import { Formik, Form } from 'formik'

import { loginPath } from 'src/lib/routes'
import { Link } from 'react-router-dom'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import Logo from 'src/components/Logo'
import Input from 'src/components/forms/Input'

const propTypes = {
  onSubmit: func.isRequired,
}

const initialValues = {
  login: '',
}

const validate = (values) => {
  const errors = {}

  if (!values.login) {
    errors.login = 'Required'
  }

  return errors
}

const ResetPassword = ({ onSubmit }) => (
  <Formik validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
    {({ isSubmitting }) => (
      <Form>
        <Div mb={2} mx-auto style={{ maxWidth: '20rem' }}>
          <Div bg="blue" white rounded px3 py3 shadow my4>
            <Logo />
            <div className="mb2">
              <Input
                name="login"
                placeholder={t('login.login')}
                className="col-12"
                required
              />
            </div>
            <div className="mb2">
              <button
                className="col-12 btn p2 button-hover rounded bg-green white white shadow"
                type="submit"
                disabled={isSubmitting}
              >
                {t('login.resetPassword')}
                <Icon icon="arrow-right" after />
              </button>
            </div>
            <Link className="white underline" to={loginPath()}>
              <Icon icon="arrow-left" before />
              {t('login.backToLogin')}
            </Link>
          </Div>
        </Div>
      </Form>
    )}
  </Formik>
)

ResetPassword.propTypes = propTypes
export default ResetPassword
