const INIT = 'typeAndSave/INIT'
const CHANGE = 'typeAndSave/CHANGE'
const MARK = 'typeAndSave/MARK'

const initialState = {
  actualEntities: [],
  answer: '',
  completed: null,
  disabled: false,
  question: {},
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { answer, entities, grade } = payload
      const question = entities[0]
      return {
        ...initialState,
        actualEntities: [question],
        answer: answer || '',
        disabled: grade !== null && grade !== undefined,
        entities,
        question,
      }
    }
    case CHANGE: {
      const { answer } = payload
      return {
        ...state,
        answer,
      }
    }
    case MARK: {
      const { answer } = state
      const completed = answer.length !== 0
      return {
        ...state,
        completed,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ answer, entities, grade }) => ({
  payload: {
    answer,
    entities,
    grade,
  },
  type: INIT,
})

const handleChange = answer => ({
  payload: {
    answer,
  },
  type: CHANGE,
})


const mark = () => ({
  type: MARK,
})

export {
  handleChange,
  init,
  mark,
}

export default reducer
