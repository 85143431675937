import size from 'lodash/size'
import every from 'lodash/every'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import uniqShuffle from 'src/lib/uniqShuffle'
import normalizeString from 'src/lib/normalizeString'
import addSpaceBetweenPunctuation from 'src/lib/addSpaceBetweenPunctuation'

const INIT = 'PartsOfSpeech/INIT'
const CLICK = 'PartsOfSpeech/CLICK'
const MARK = 'PartsOfSpeech/MARK'
const RETRY = 'PartsOfSpeech/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
}

const createCards = wordTitles => (a, title, index) => {
  if (wordTitles.includes(normalizeString(title))) {
    return (
      {
        ...a,
        [`${title}-${index}`]: {
          id: `${title}-${index}`,
          title,
          answer: true,
          label: size(a) + 1,
          selected: null,
        },
      }
    )
  }
  return (
    {
      ...a,
      [`${title}-${index}`]: {
        id: `${title}-${index}`,
        title,
        answer: false,
        label: size(a) + 1,
        selected: null,
      },
    }
  )
}

const createQuestionCollections = (a, entity, index, entities) => {
  const slugArray = entity.slug.split('-')
  const { phrase, words } = a
  if (slugArray[0] === 'phrase' || slugArray[0] === 'paragraph') {
    if (a.phrase) {
      return {
        ...a,
        phrase: entity,
        questionCollections: a.questionCollections.concat({ phrase, words }),
        words: [],
      }
    }
    return {
      ...a,
      phrase: entity,
    }
  }
  if (index === (entities.length - 1)) {
    return {
      ...a,
      questionCollections: a.questionCollections.concat({ phrase, words: words.concat(entity) }),
    }
  }
  return {
    ...a,
    words: a.words.concat(entity),
  }
}

const createWordTitles = (a, word) => a.concat(normalizeString(word.titleEn))

const isSelected = selected => (selected ? true : null)

const clickCard = id => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    selected: id === card.id ? isSelected(!card.selected) : card.selected,
  },
})

const isCheckCard = correct => (correct === null ? false : correct)

const checkCards = (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: (card.selected || card.answer) ? isCheckCard(card.answer && card.selected) : null,
  },
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const instructionId = payload.entities[0].id
      const entities = payload.entities.slice(1)
      const { questionCollections } = entities.reduce(createQuestionCollections, {
        phrase: null,
        questionCollections: [],
        words: [],
      })
      const { phrase, words } = uniqShuffle(questionCollections)[0]
      const wordTitles = words.reduce(createWordTitles, [])
      const titleEnArray = addSpaceBetweenPunctuation(phrase.titleEn).split(' ')
      const cards = titleEnArray.reduce(createCards(wordTitles), {})
      return {
        ...initialState,
        actualEntities: [phrase, ...words],
        cards,
        entitiesById: keyBy(payload.entities, 'id'),
        instructionId,
        phraseId: phrase.id,
      }
    }

    case (CLICK): {
      const { id } = payload
      const cards = reduce(state.cards, clickCard(id), {})
      return {
        ...state,
        cards,
      }
    }

    case (MARK): {
      const cards = reduce(state.cards, checkCards, {})
      const selectedCard = every(
        uniq([...filter(cards, { selected: true }), ...filter(cards, { answer: true })]),
        'correct',
      )
      return {
        ...state,
        cards,
        completed: selectedCard,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: { entities },
  type: INIT,
})

const onClick = ({ id }) => ({
  payload: { id },
  type: CLICK,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  onClick,
  mark,
  retry,
}

export default reducer
