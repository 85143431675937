import React, { Component } from 'react'
import { shape, func, number, bool, string } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ListenAndSelect/reducer'
import ListenAndSelect from 'src/modules/ListenAndSelect/ListenAndSelect'

const propTypes = {
  ...gamePropTypes,
  cols: number,
  image: shape({
    id: string,
    src: string,
  }),
  lines: shape().isRequired,
  onClick: func.isRequired,
  rows: number,
  silent: bool,
}

const defaultProps = {
  cols: 3,
  image: null,
  rows: 1,
  silent: false,
}

class ListenAndSelectContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      completed,
      image,
      lines,
      mark,
      onClick,
      retry,
      silent,
      started,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ListenAndSelect
          autoPlay={started}
          image={image}
          lines={lines}
          onClick={onClick}
          silent={silent}
        />
      </Game>
    )
  }
}

ListenAndSelectContainer.defaultProps = defaultProps
ListenAndSelectContainer.propTypes = propTypes
ListenAndSelectContainer.demoData = {
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
  ],
}

const select = state => state.listenAndSelect
const actions = dispatch => bindActionCreators(gameActions, dispatch)
export { ListenAndSelectContainer }
export default connect(select, actions)(ListenAndSelectContainer)
