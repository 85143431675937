import React from 'react'
import LessonRedirect from 'src/components/LessonRedirect'
import LessonsLayout from 'src/pages/LessonsLayout'

const LessonsRedirect = ({ ...props }) => (
  <LessonsLayout>
    <LessonRedirect {...props} />
  </LessonsLayout>
)

export default LessonsRedirect
