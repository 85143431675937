import React from 'react'
import { Link } from 'react-router-dom'
import { arrayOf, number, shape, string } from 'prop-types'
import { t } from 'i18next'

import StickyTable from 'src/components/StickyTable/StickyTable'
import subjectShape from 'src/shapes/subject'
import unitShape from 'src/shapes/unit'
import usePath from 'src/lib/routes/usePath'
import userPath from 'src/lib/routes/user'
import Widget from 'src/components/Widget'
import { HOMEWORK } from 'src/constants/menu'

const propTypes = {
  homeworkResults: arrayOf(shape({
    lessonId: string,
    rate: number,
  })),
  subject: shape(subjectShape),
  year: shape({
    id: string,
    students: arrayOf(shape({
      fullName: string,
      id: string,
    })),
    title: string,
  }).isRequired,
  yearUnits: arrayOf(shape(unitShape)),
}

const defaultProps = {
  homeworkResults: [],
  subject: undefined,
  yearUnits: [],
}

const YearHomeworkReport = ({ year, yearUnits, subject, homeworkResults }) => {
  const lessons = yearUnits.flatMap(unit => unit.lessons)
  const pathToUser = usePath(userPath)
  return (
    <Widget token={HOMEWORK} title={`${subject ? subject.title : ''} ${t(`${HOMEWORK}.title`)}`}>
      <StickyTable>
        {/* TODO: Get rid of StickyTableHeader #2816 */}
        <thead>
          <tr>
            <th className="col-2" rowSpan={2} style={{ minWidth: '12rem' }} />
            {
              yearUnits.map(unit => (
                <th key={unit.id} className={`qa-unit-${unit.id}`} colSpan={unit.lessons.length}>
                  {unit.identifier} {unit.title}
                </th>
              ))
            }
          </tr>
          <tr>
            {
              lessons.map(lesson => (
                <th key={lesson.id} className={`qa-lesson-${lesson.id}`}>
                  {lesson.identifier}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            year.students.map(student => (
              <tr key={student.id} id={`qa-student-${student.id}`}>
                <td style={{ minWidth: '12rem' }}>
                  <Link to={pathToUser({ userId: student.slug })}>
                    {student.fullName}
                  </Link>
                </td>
                {
                  lessons.map((lesson) => {
                    const studentHomeworkResult = homeworkResults.find(
                      result => result.lessonId === lesson.id && result.userId === student.id,
                    )
                    return (
                      <td
                        key={student.id + lesson.id}
                        id={`qa-student-${student.id}-${lesson.id}`}
                      >
                        {
                          studentHomeworkResult
                            ? `${
                              studentHomeworkResult.grade
                            } / ${Math.round(studentHomeworkResult.rate * 100)} %`
                            : '- / -'
                        }
                      </td>
                    )
                  })
                }
              </tr>
            ))
          }
        </tbody>
      </StickyTable>
    </Widget>
  )
}

YearHomeworkReport.propTypes = propTypes
YearHomeworkReport.defaultProps = defaultProps

export default YearHomeworkReport
