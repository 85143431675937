const generateEntities = total => (
  new Array(total).fill().map((item, i) => ({
    id: `ent-${i}`,
    image: {
      id: `ent-${i}-img-${i}`,
      src: `ent-${i}-img.jpg`,
    },
    meaning: {
      id: `ent-${i}-meaning`,
      sound: `ent-${i}-meaning-sound`,
      titleEn: `ent-${i}-meaning-titleEn`,
      titleTh: `ent-${i}-meaning-titleTh`,
    },
    phonicsEn: [
      {
        id: `ent-${i}-phonic-en-1`,
        src: `ent-${i}-phonic-en-src-1`,
        title: `ent-${i}-phonic-en-title-1`,
      },
      {
        id: `ent-${i}-phonic-en-2`,
        src: `ent-${i}-phonic-en-src-2`,
        title: `'ent-${i}-phonic-en-title-2`,
      },
    ],
    phonicsTh: [
      {
        id: `ent-${i}-phonic-th-1`,
        src: `ent-${i}-phonic-th-src-1`,
        title: `ent-${i}-phonic-th-title-1`,
      },
      {
        id: `ent-${i}-phonic-th-2`,
        src: `ent-${i}-phonic-th-src-2`,
        title: `ent-${i}-phonic-th-title-2`,
      },
    ],
    slug: `ent-${i}`,
    sound: {
      id: `ent-${i}-sound-${i}`,
      srcEn: `ent-${i}-sound-EN.mp3`,
      srcTh: `ent-${i}-sound-TH.mp3`,
    },
    title: `ent-${i}`,
    titleEn: `Ent ${i}.`,
    titleTh: `เอ็นติตี้ ${i}`,
  }))
)

export default generateEntities
