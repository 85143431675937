import React from 'react'
import { useParams } from 'react-router'
import TheLayout from 'src/pages/TheLayout'
import SetUser from 'src/components/SetUser/SetUser'
import Access from 'src/components/Access'
import PortalLayout from 'src/pages/PortalLayout/PortalLayout'


const PortalLayoutPage = ({ children }) => {
  const { parentId } = useParams()
  return (
    <TheLayout>
      <Access parent teacher staff redirect>
        <SetUser userId={parentId}>
          <PortalLayout>
            {children}
          </PortalLayout>
        </SetUser>
      </Access>
    </TheLayout>
  )
}

export default PortalLayoutPage
