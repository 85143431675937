import { compose } from 'src/lib/redux'
import subjectQuery from 'src/queries/subject.gql'
import BpReportPage from 'src/pages/BpReportPage/BpReportPage'
import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import classAttainmentQuery from 'src/pages/ProgressPage/AttainmentProgress/StudentAttainmentProgress/classAttainment.gql'
import yearQuery from 'src/pages/Year/shared/year.gql'
import academicYearQuery from 'src/queries/academicYear.gql'

export default compose(
  gqlLoader(academicYearQuery),
  gqlLoader(classAttainmentQuery, networkOnlyConfig),
  gqlLoader(subjectQuery),
  gqlLoader(yearQuery),
)(BpReportPage)
