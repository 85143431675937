import {
  ACTIVITIES,
  ADMISSIONS,
  ASSESSMENT,
  ATTENDANCE,
  ATTENDANCE_REPORT,
  BP_REPORT,
  CALENDAR,
  CHILDREN,
  DOCUMENT_MANAGEMENT,
  GRADING,
  HOMEWORK,
  LEADERBOARD,
  LESSONS,
  MY_STUDENTS_CURRICULUM,
  PARENT_DATA,
  PARENTS,
  PROGRESS,
  SCHOOL,
  SCHOOL_CURRICULUM,
  SCHOOL_SETTINGS,
  SKILLS,
  SPECIAL_NEEDS,
  STUDENT_CARDS,
  STUDENT_DATA,
  TEACHER,
  TIMETABLE,
  USERS,
  YEARS,
} from 'src/constants/menu'

const icons = {
  [ACTIVITIES]: 'futball',
  [ADMISSIONS]: 'user-plus',
  [ASSESSMENT]: 'th-list',
  [ATTENDANCE]: 'battery-full',
  [ATTENDANCE_REPORT]: 'chart-pie',
  [BP_REPORT]: 'file',
  [CALENDAR]: 'calendar',
  [CHILDREN]: 'users',
  [DOCUMENT_MANAGEMENT]: 'file',
  [GRADING]: 'check-square',
  [HOMEWORK]: 'graduation-cap',
  [LEADERBOARD]: 'crown',
  [LESSONS]: 'book',
  [MY_STUDENTS_CURRICULUM]: 'book',
  [PARENT_DATA]: 'male',
  [PARENTS]: 'users',
  [PROGRESS]: 'chart-bar',
  [SCHOOL]: 'building',
  [SCHOOL_CURRICULUM]: 'book',
  [SCHOOL_SETTINGS]: 'cogs',
  [SKILLS]: 'chart-pie',
  [SPECIAL_NEEDS]: 'wheelchair',
  [STUDENT_CARDS]: 'address-card',
  [STUDENT_DATA]: 'user',
  [TEACHER]: 'graduation-cap',
  [TIMETABLE]: 'calendar',
  [USERS]: 'users',
  [YEARS]: 'graduation-cap',
}

export default icons
