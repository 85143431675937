import { compose } from 'src/lib/redux'
import gqlLoader from 'src/lib/gqlLoader'

import LessonQuery from 'src/queries/pages/Lesson/Lesson.gql'
import BackgroundComponentLayoutQuery from 'src/queries/pages/Lesson/BackgroundComponentLayout.gql'
import CurrentUserQuery from 'src/pages/CurrentUser/CurrentUser.gql'
import Lesson from 'src/pages/Lesson/Lesson'

export default compose(
  gqlLoader(BackgroundComponentLayoutQuery),
  gqlLoader(CurrentUserQuery),
  gqlLoader(LessonQuery, { shortSlugRedirect: true }),
)(Lesson)
