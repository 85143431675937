import { arrayOf, shape, string } from 'prop-types'
import indicatorShape from 'src/shapes/indicator'
import localizedField from 'src/shapes/localizedField'
import strandShape from 'src/shapes/strand'

const standardShape = {
  code: string,
  id: string,
  identifier: string,
  indicators: arrayOf(shape(indicatorShape)),
  strand: shape(strandShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default standardShape
