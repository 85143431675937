import variableValidation from 'src/lib/variableValidation'
import ListOfNumberWord from 'src/lib/speechRecognitionCorrection.json'

const speechRecognitionCorrection = (transcript) => {
  if (transcript.split(' ').length > 1) {
    const listwords = transcript.split(' ')
    return variableValidation(
      listwords
        .map((word) => {
          if (ListOfNumberWord[word]) {
            return ListOfNumberWord[word]
          }
          return word
        })
        .join(' '),
    )
  }
  if (parseInt(transcript, 10) >= 0 && ListOfNumberWord[transcript]) {
    return variableValidation(ListOfNumberWord[transcript])
  }
  return variableValidation(transcript)
}

export default speechRecognitionCorrection
