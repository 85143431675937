import React from 'react'
import { arrayOf, shape } from 'prop-types'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import EntityCard from 'src/components/EntityCard'

const propTypes = {
  entities: arrayOf(
    shape(entityShape).isRequired,
  ).isRequired,
}

const defaultProps = {
  entities: [],
}

const WordBank = ({ entities }) => (
  <Div flex wrap justify="center" className="mt2">
    {
      entities.map(entity => (
        <EntityCard
          english
          entity={entity}
          fit
          key={entity.id}
          selected={entity.selected}
          size={4}
        />
      ))
    }
  </Div>
)
WordBank.propTypes = propTypes
WordBank.defaultProps = defaultProps
export default WordBank
