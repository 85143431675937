import React from 'react'
import { shape, string } from 'prop-types'
import { STUDENT_ROLE } from 'src/constants/roles'
import BackgroundStyle from 'src/components/BackgroundStyle'
import background from 'src/pages/StudentLayout/background.svg'

const propTypes = {
  user: shape({
    roleId: string,
  }),
}

const defaultProps = {
  user: {
    roleId: null,
  },
}

const UserBackgroundStyle = ({ user }) => (
  user.roleId === STUDENT_ROLE
    ? <BackgroundStyle color="#00142d" backgroundUrl={background} />
    : <BackgroundStyle color="#7BD3F7" />
)

UserBackgroundStyle.propTypes = propTypes
UserBackgroundStyle.defaultProps = defaultProps

export default UserBackgroundStyle
