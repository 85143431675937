import React, { useState } from 'react'
import { func } from 'prop-types'
import noop from 'lodash/noop'
import MicrophoneButton from 'src/components/MicrophoneButton'
import withSpeechRecognition from 'src/modules/SpeechRecognition/SpeechRecognitionHOC'

const propTypes = {
  onComplete: func.isRequired,
}

const MicWithSpeechRecognition = withSpeechRecognition(({
  onStart,
  onStop,
  onKeyPress,
  selected,
  loading,
  ...rest
}) => (
  <MicrophoneButton
    loading={loading}
    selected={selected}
    onKeyPress={onKeyPress}
    onMouseDown={onStart}
    onMouseUp={onStop}
    {...rest}
  />
))

const SpeechRecognition = ({ onComplete, ...rest }) => {
  const [micOn, setMicOn] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <MicWithSpeechRecognition
      handleResult={onComplete}
      init={noop}
      loading={loading}
      loadingOn={() => setLoading(true)}
      loadingOff={() => setLoading(false)}
      microphoneOn={() => setMicOn(true)}
      microphoneOff={() => setMicOn(false)}
      mark={noop}
      selected={micOn}
      completed={null}
      correct={null}
      processTranscripts={onComplete}
      {...rest}
    />
  )
}

SpeechRecognition.propTypes = propTypes

export default SpeechRecognition
