import React from 'react'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'

const Spinner = () => {
  const navBar = document.getElementById('navBar')
  const top = 15 + (navBar ? navBar.clientHeight : 0)
  return (
    <Div
      pr2
      style={{
        display: 'block',
        position: 'absolute',
        right: 0,
        top,
        zIndex: 5,
      }}
    >
      <Icon spin color="green" size={3} icon="circle-notch" />
    </Div>
  )
}

export default Spinner
