import React, { Component } from 'react'
import { Mutation } from '@apollo/react-components'
import { graphql } from '@apollo/react-hoc'
import { t } from 'i18next'
import { Redirect } from 'react-router-dom'
import URLSearchParams from '@ungap/url-search-params'

import parseErrors from 'src/lib/form/parseErrors'
import RedirectToHomePage from 'src/pages/Login/RedirectToHomePage'
import ResetPasswordForm from 'src/pages/ResetPassword/ResetPasswordForm/ResetPasswordForm'
import userUpdatePasswordMutation from 'src/pages/ResetPassword/ResetPasswordForm/userUpdatePasswordMutation.gql'
import { localStorage } from 'src/lib/window'
import { loginPath } from 'src/lib/routes'
import { successMessage } from 'src/lib/toaster'

const propTypes = {}

const ControlForm = ({ done, mutation, token }) =>
  (done ? (
    <RedirectToHomePage />
  ) : (
    <ResetPasswordForm
      token={token}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        mutation({ variables: { ...values, token } }).catch((errors) => {
          setErrors(parseErrors(errors))
        })
        setSubmitting(false)
      }}
    />
  ))

const showSuccessMessage = () => {
  const title = t('user.user')
  const message = t('save.success')
  successMessage({ message, title })
}

export class ResetPasswordFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { done: false }
  }

  componentDidMount() {
    const { location } = this.props
    const { search } = location
    const params = new URLSearchParams(search)
    if (params.has('token')) {
      localStorage.setItem('token', params.get('token'))
    }
  }

  handleSuccess() {
    showSuccessMessage()
    this.setState({ done: true })
  }

  render() {
    const { location } = this.props
    const { search } = location
    const params = new URLSearchParams(search)

    if (!params.has('token')) return <Redirect to={loginPath()} />
    const token = params.get('token')
    return (
      <div>
        <Mutation
          mutation={userUpdatePasswordMutation}
          onCompleted={(values) => {
            this.handleSuccess(values)
          }}
        >
          {(userUpdatePassword, { error }) => (
            <ControlForm
              done={this.state.done}
              error={error}
              mutation={userUpdatePassword}
              token={token}
            />
          )}
        </Mutation>
      </div>
    )
  }
}

ResetPasswordFormContainer.propTypes = propTypes

export default graphql(userUpdatePasswordMutation)(ResetPasswordFormContainer)
