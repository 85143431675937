import React from 'react'
import { arrayOf, string, shape, func } from 'prop-types'

import SelectStyle from 'src/components/SelectStyle'

const propTypes = {
  children: arrayOf(shape({
    fullName: string.isRequired,
    id: string.isRequired,
    slug: string,
  })),
  onChange: func,
  value: string,
}

const compareStudentYears = (a, b) => {
  if (a.year.title > b.year.title) {
    return 1
  }
  if (a.year.title < b.year.title) {
    return -1
  }
  return 0
}

const SelectChildren = ({ children, onChange, value, white }) => (
  <SelectStyle white={white} rounded className="qa-select-children">
    <select
      name="qa-select-children"
      id="qa-select-children"
      value={value}
      onChange={e => onChange(e.target.value)}
    >
      {
        children.sort(compareStudentYears).map(({ id, slug, fullName, year }) => (
          <option key={id} value={slug || id}>
            {year.title} {fullName}
          </option>
        ))
      }
    </select>
  </SelectStyle>
)

SelectChildren.propTypes = propTypes
export default SelectChildren
