import React from 'react'
import { string } from 'prop-types'
import ObjectCommand from 'src/components/ObjectCommand'

const propTypes = {
  theme: string.isRequired,
}

const COMMANDS = {
  backyard: 'wood',
  fence: 'wood',
  mole: 'wood',
  river: 'wood',
  space: 'robot',
}

const ObjectCommandSelector = ({
  theme,
  ...rest
}) => <ObjectCommand objectImage={COMMANDS[theme]} {...rest} />

ObjectCommandSelector.propTypes = propTypes
export default ObjectCommandSelector
