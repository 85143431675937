import React from 'react'
import ParagraphMissingWordsContainer from 'src/modules/ParagraphMissingWords/ParagraphMissingWordsContainer'

const defaultProps = {
  completed: null,
  punctuation: true,
}

const ParagraphMissingPunctuation = props => (
  <ParagraphMissingWordsContainer
    {...props}
  />
)


ParagraphMissingPunctuation.defaultProps = defaultProps
ParagraphMissingPunctuation.demoData = {
  entities: [
    'paragraph-sentencepunctuationmarker',
    'paragraph-christmas-tree-story-1',
  ],
}

export default ParagraphMissingPunctuation
