import React, { Fragment } from 'react'
import { element, shape, bool, number, string, func, arrayOf } from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import map from 'lodash/map'
import { withRouter } from 'react-router-dom'

import DragDropContext from 'src/lib/dragAndDrop'
import { t } from 'i18next'
import { GameContext } from 'src/lib/contexts'
import SequenceMap from 'src/components/ui/SequenceMap'
import { lessonFullPath } from 'src/lib/routes'
import { connect, bindActionCreators } from 'src/lib/redux'
import * as layoutActions from 'src/components/Layout/reducer'
import * as homeworkActions from 'src/pages/Homework/reducer'
import trackEvent from 'src/components/analytics'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Navigation from 'src/components/Layout/Navigation'
import ScalableModule from 'src/components/ScalableModule'
import instructionShape from 'src/shapes/instruction'
import userShape from 'src/shapes/user'
import AdminBar from 'src/pages/Lesson/AdminBar'
import MicrophoneCheck from 'src/components/MicrophoneCheck/MicrophoneCheck'
import camelToKebab from 'src/lib/camelToKebab'
import steps from 'src/pages/Lesson/steps'

const propTypes = {
  chapterId: string.isRequired,
  chapterIndex: number.isRequired,
  chapters: arrayOf(shape({
    component: string.isRequired,
    title: string.isRequired,
  })).isRequired,
  children: arrayOf(element).isRequired,
  clearHomework: func.isRequired,
  disableMark: bool.isRequired,
  disableMarkButton: func.isRequired,
  disableNavigation: bool.isRequired,
  enableMarkButton: func.isRequired,
  goToChapter: func.isRequired,
  hasNextSlide: bool.isRequired,
  hasPrevSlide: bool.isRequired,
  hideInstruction: func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  homework: bool.isRequired,
  homeworkIterations: arrayOf(bool).isRequired,
  initHomework: func.isRequired,
  instructions: arrayOf(shape(instructionShape)).isRequired,
  isShowInstruction: bool,
  lessonSlug: string.isRequired,
  mark: func,
  moduleId: string.isRequired,
  nextSlide: func.isRequired,
  prevSlide: func.isRequired,
  setNavigation: func.isRequired,
  showInstruction: func.isRequired,
  showMarkButton: func.isRequired,
  slideIndex: number.isRequired,
  started: bool.isRequired,
  startGame: func.isRequired,
  totalChapters: number.isRequired,
  totalIterations: number.isRequired,
  totalSlides: number.isRequired,
  user: shape(userShape),
}

const defaultProps = {
  isShowInstruction: false,
  mark: null,
  user: null,
}

const filterString = (text) => {
  if (typeof text === 'string' || text instanceof String) {
    const arrayString = text.split(':')
    if (arrayString.length > 1) return arrayString[1]
    return arrayString[0]
  }
  return ''
}

const matchInstructionByName = moduleId => (
  ({ slug }) => (`instructions-${camelToKebab(moduleId)}` === slug)
)

class LessonLayout extends React.Component {
  componentDidMount() {
    const {
      clearHomework,
      homework,
      lessonSlug,
      slideIndex,
      totalChapters,
      setNavigation,
      initHomework,
    } = this.props
    setNavigation({
      chapterIndex: this.getChapterIndex(),
      slideIndex,
      totalChapters,
    })
    if (homework) initHomework()
    clearHomework()
    if (homework) {
      trackEvent({
        action: 'begin',
        category: 'homework',
        label: lessonSlug,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      chapterId,
      chapterIndex,
      slideIndex,
      clearHomework,
    } = this.props
    if (this.isRedirectionAvailable()) {
      this.redirection()
    } else if (chapterId !== prevProps.chapterId) {
      this.updateNavigation()
      clearHomework()
    }

    if (chapterIndex !== prevProps.chapterIndex || slideIndex !== prevProps.slideIndex) {
      this.updateURL()
    }
  }

  componentWillUnmount() {
    const { setNavigation } = this.props
    setNavigation({ chapterIndex: 0, slideIndex: 0, totalChapters: 0 })
  }

  getRedirectionKeyPrefix() {
    const { user: { id: userId }, lessonSlug } = this.props
    return `homework-redirection-chapter-by-user-lesson=${userId}-${lessonSlug}`
  }

  setRedirection() {
    const { chapterIndex, homework } = this.props
    const redirectToChapterIndex =
      this.isLastChapter() || this.isFirstChapter() ? '' : chapterIndex
    if (homework) {
      sessionStorage.setItem(
        this.getRedirectionKeyPrefix(),
        redirectToChapterIndex,
      )
    }
  }

  getChapterIndex() {
    const { chapterIndex, chapters } = this.props
    const chaptersModuleId = map(chapters, 'component')
    if (this.isRedirectionAvailable()) {
      return this.getRedirectionChapterIndex()
    }
    if (chaptersModuleId.length === 0) return 0
    if (chapterIndex > chaptersModuleId.length - 1) return chaptersModuleId.length - 1
    return chapterIndex
  }

  getRedirectionChapterIndex() {
    const redirectToChapterIndex = sessionStorage.getItem(
      this.getRedirectionKeyPrefix(),
    )
    return redirectToChapterIndex && parseInt(redirectToChapterIndex, 10)
  }

  isRedirectionAvailable() {
    const { homework } = this.props
    return (
      homework && this.isFirstChapter() && this.getRedirectionChapterIndex()
    )
  }

  redirection() {
    const { lessonSlug, homework, history } = this.props
    history.push(
      lessonFullPath({
        chapterIndex: this.getRedirectionChapterIndex(),
        homework,
        lessonSlug,
      }),
    )
  }

  isFirstChapter() {
    const { chapterIndex } = this.props
    return chapterIndex <= 0
  }

  isLastChapter() {
    const { chapterIndex, chapters } = this.props
    return chapterIndex === chapters.length - 1
  }

  updateNavigation() {
    const { chapterIndex, totalChapters, setNavigation } = this.props
    setNavigation({ chapterIndex, totalChapters })
    this.setRedirection()
  }

  updateURL() {
    const { history, lessonSlug, chapterIndex, slideIndex, totalSlides, homework } = this.props
    history.push(
      lessonFullPath({
        chapterIndex,
        hasSlides: !!totalSlides && slideIndex != null,
        homework,
        lessonSlug,
        slideIndex,
      }),
      homework,
    )
  }

  render() {
    const {
      chapterId,
      chapterIndex,
      chapters,
      children,
      disableMark,
      disableMarkButton,
      disableNavigation,
      enableMarkButton,
      goToChapter,
      hasNextSlide,
      hasPrevSlide,
      hideInstruction,
      homework,
      homeworkIterations,
      instructions,
      isShowInstruction,
      lessonSlug,
      mark,
      microphoneCheck,
      moduleId,
      nextSlide,
      prevSlide,
      showInstruction,
      showMarkButton,
      started,
      startGame,
      totalIterations,
      user,
    } = this.props
    const prevChapterIndex = this.isFirstChapter() ? null : chapterIndex - 1
    const nextChapterIndex = this.isLastChapter() ? null : chapterIndex + 1
    const chaptersName = map(chapters, 'title')
    const chaptersModuleId = map(chapters, 'component')

    const previous = ({ isKeyCombination } = {}) => {
      if (hasPrevSlide && !isKeyCombination) {
        prevSlide()
      } else if (prevChapterIndex !== null) {
        goToChapter({ chapterIndex: prevChapterIndex, goToLastSlide: true })
      }
    }

    const next = ({ isKeyCombination } = {}) => {
      if (hasNextSlide && !isKeyCombination) {
        nextSlide()
      } else if (nextChapterIndex !== null) {
        goToChapter({ chapterIndex: nextChapterIndex })
      }
    }

    const selectPreviousMessage = () => {
      if (hasPrevSlide || homework) {
        return t('navigation.previous')
      }
      if (prevChapterIndex == null) {
        return t('navigation.beginning')
      }
      if (prevChapterIndex !== null) {
        return (filterString(chaptersName[chapterIndex - 1]))
      }
      return t('navigation.previous')
    }
    const previousMessage = () => (
      <Div className="nowrap overflow-hidden ellipsis navigation-text">
        {selectPreviousMessage()}
      </Div>
    )
    const previousModuleInstruction = () => {
      if (!hasPrevSlide && !homework && (instructions !== null)
      && (prevChapterIndex !== null) && (chaptersModuleId.length > 0)
      && chaptersModuleId[chapterIndex - 1]) {
        return instructions.find(matchInstructionByName(chaptersModuleId[chapterIndex - 1]))
      }
      return {}
    }

    const selectNextMessage = () => {
      if (hasNextSlide || homework) {
        return t('navigation.next')
      }
      if (nextChapterIndex == null) {
        return t('navigation.end')
      }
      if (nextChapterIndex !== null) {
        return (filterString(chaptersName[chapterIndex + 1]))
      }
      return t('navigation.next')
    }
    const nextMessage = () => (
      <Div className="nowrap overflow-hidden ellipsis navigation-text">
        {selectNextMessage()}
      </Div>
    )
    const nextModuleInstruction = () => {
      if (!hasNextSlide && !homework && (instructions !== null)
      && (nextChapterIndex !== null) && (chaptersModuleId.length > 0)
      && chaptersModuleId[chapterIndex + 1]) {
        return instructions.find(matchInstructionByName(chaptersModuleId[chapterIndex + 1]))
      }
      return {}
    }

    const markAndDisable = () => {
      if (disableMark || !mark) return null
      return () => {
        mark()
        disableMarkButton()
      }
    }
    const help = () => {
      if (isShowInstruction) {
        hideInstruction()
      } else {
        showInstruction()
      }
    }

    const helpAndDisable = () => {
      if (mark) {
        return help
      }
      return null
    }

    const homeworkNavigation =
      totalIterations > 0
        ? (
          <Navigation
            homeworkIterations={homeworkIterations}
            mark={markAndDisable()}
            help={helpAndDisable()}
            previousMessage={previousMessage}
            nextMessage={nextMessage}
            previousModuleInstruction={previousModuleInstruction()}
            nextModuleInstruction={nextModuleInstruction()}
          />
        )
        : (
          <Navigation
            mark={markAndDisable()}
            next={this.isLastChapter() ? null : next}
            previous={null}
            help={helpAndDisable()}
            previousMessage={previousMessage}
            nextMessage={nextMessage}
            previousModuleInstruction={previousModuleInstruction()}
            nextModuleInstruction={nextModuleInstruction()}
          />
        )
    const context = {
      chapterId,
      disableMarkButton,
      enableMarkButton,
      homework,
      instructions,
      moduleId,
      showMarkButton,
      startGame,
      started,
      userId: user ? user.id : null,
    }
    return (
      <GameContext.Provider value={context}>
        <Deck className="overflow-hidden disable-select">
          <SequenceMap
            active={chapterIndex.toString()}
            steps={steps({ chapters, goToChapter, homework })}
            homework={homework}
            lessonSlug={lessonSlug}
          />
          <MicrophoneCheck disabled={!microphoneCheck} Wrapper={ScalableModule}>
            <ScalableModule>
              <Fragment>
                {children}
                <AdminBar
                  chapterIndex={chapterIndex}
                  lessonSlug={lessonSlug}
                  chapterId={chapterId}
                />
              </Fragment>
            </ScalableModule>
            {homework
              ? homeworkNavigation
              : (
                <Navigation
                  mark={markAndDisable()}
                  next={this.isLastChapter() || disableNavigation ? null : next}
                  previous={this.isFirstChapter() || disableNavigation ? null : previous}
                  help={helpAndDisable()}
                  previousMessage={previousMessage}
                  nextMessage={nextMessage}
                  previousModuleInstruction={previousModuleInstruction()}
                  nextModuleInstruction={nextModuleInstruction()}
                  homeworkIterations={homeworkIterations}
                  shortcutInfo={moduleId === 'TitlePage'}
                />
              )}
          </MicrophoneCheck>
        </Deck>
      </GameContext.Provider>
    )
  }
}

LessonLayout.propTypes = propTypes
LessonLayout.defaultProps = defaultProps

const select = state => ({
  ...state.layout,
  ...state.homework,
})

const actions = dispatch => bindActionCreators({ ...layoutActions, ...homeworkActions }, dispatch)
export default connect(select, actions)(
  DragDropContext(withRouter(LessonLayout)),
)
