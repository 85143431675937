import React from 'react'
import { string, shape } from 'prop-types'

import { Link } from 'react-router-dom'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import userPath from 'src/lib/routes/user'
import usePath from 'src/lib/routes/usePath'

const propTypes = {
  parent: shape({
    fullName: string.isRequired,
    slug: string.isRequired,
  }).isRequired,
}

const ParentRow = ({ parent: { slug, fullName } }) => (
  <Div my1>
    <Link to={usePath(userPath)({ tab: 'edit', userId: slug })}>
      <Icon icon="male" />
      {fullName}
      <Icon icon="edit" />
    </Link>
  </Div>
)

ParentRow.propTypes = propTypes

export default ParentRow
