import React from 'react'
import { bool, func, number, string } from 'prop-types'
import { t } from 'i18next'
import Div from 'src/components/Div'
import { translate } from 'src/components/Translate'
import { isMobile, isMobileOrNative } from 'src/lib/window'

import KeyPress from 'src/components/KeyPress'

const propTypes = {
  auto: bool,
  correct: bool,
  guide: bool,
  onChange: func,
  placeholder: string,
  showSoftKeyboard: func.isRequired,
  size: number,
  units: string,
  unitTest: bool,
  value: string,
}

const defaultProps = {
  auto: false,
  guide: false,
  size: 2,
  unitTest: false,
  placeholder: t('shared.typeHere'),
}

const colorResult = {
  false: 'brown',
  true: 'olive',
}

const bgResult = {
  false: 'bg-red',
}

const getUnits = units => (
  units ? (
    <Div col={4} p={1} size={1} bold>
      {translate(units)}
    </Div>
  ) : null
)

const Answer = ({
  auto, correct, guide, onChange, size, units, unitTest, value, placeholder,
  showSoftKeyboard,
}) => {
  const color = colorResult[correct]
  const bg = bgResult[correct]
  return (
    <Div
      flex
      gutter={1}
      px={1}
      align="center"
      className={color}
      auto={auto}
      onClick={isMobileOrNative() && showSoftKeyboard}
    >
      <Div col={getUnits(units) ? 8 : 12} p={1} className="Answer">
        <input
          autoFocus={!guide && !unitTest && !isMobile}
          className={`h${size} border rounded center bold col-12 ${color} ${bg}`}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={isMobileOrNative()}
          type="text"
          value={value}
        />
        {
          isMobileOrNative() && (
            <KeyPress keys={{ rest: onChange }} answer />
          )
        }
      </Div>
      {getUnits(units)}
    </Div>
  )
}

Answer.defaultProps = defaultProps
Answer.propTypes = propTypes
export default Answer
