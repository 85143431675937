import React from 'react'
import { useParams, useHistory } from 'react-router'
import StudentProgressPage from 'src/pages/StudentSkillsPage'
import { linkTo } from 'src/lib/linkTo'

const StudentProgress = ({ userId, path }) => {
  const { subjectId } = useParams()
  const history = useHistory()
  return (
    <StudentProgressPage
      userId={userId}
      subjectId={subjectId}
      onSubjectChange={subjectId => history.push(
        linkTo(path)({ studentId: userId, subject: 'subject', subjectId }),
      )}
    />
  )
}

export default StudentProgress
