import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import gqlLoader from 'src/lib/gqlLoader'
import yearQuery from 'src/pages/Year/shared/year.gql'
import YearShow from 'src/pages/Year/Show/Scene'
import yearDeleteMutation from 'src/pages/Year/Show/yearDeleteMutation.gql'

export default compose(
  gqlLoader(yearQuery),
  graphql(yearDeleteMutation, { name: 'yearDelete' }),
)(YearShow)
