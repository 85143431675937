import React from 'react'
import { arrayOf, shape, func, bool, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Div from 'src/components/Div'
import { SelectionState } from 'src/components/Selection'

const propTypes = {
  onSectionChange: func.isRequired,
  onSubmit: func.isRequired,
  onYearChange: func.isRequired,
  sections: arrayOf(shape(entityShape).isRequired).isRequired,
  submitEnabled: bool,
  submitTitle: string,
  submitWarning: bool,
  years: arrayOf(shape(entityShape).isRequired),
}
const defaultProps = {
  submitEnabled: false,
  submitTitle: null,
  submitWarning: false,
  years: null,
}

const NONE = 'NONE'
const RESIGN = 'RESIGN'
const nullIfNone = val => (val === NONE ? null : val)

const AssignYear = ({
  years,
  sections,
  submitEnabled,
  submitTitle,
  submitWarning,
  onSubmit,
  onSectionChange,
  onYearChange,
}) => (
  <Div flex>
    <select
      onChange={({ target: { value } }) => onSectionChange(value, value === RESIGN)}
      defaultValue={NONE}
      className="mr1"
    >
      <option value={NONE} disabled>Section</option>
      <option value={RESIGN}>-- RESIGNED --</option>
      {sections.map(({ id, title }) => (
        <option key={id} value={id} className="pr1">
          {title}
        </option>
      ))}
    </select>
    { years && (
      <select
        disabled={years.length < 1}
        onChange={({ target: { value } }) => onYearChange(nullIfNone(value))}
        defaultValue={NONE}
        className="mr1 pl1"
      >
        <option disabled value={NONE} className="pr1">Year</option>
        {years.map(({ id, title, disabled }) => (
          <option key={id} value={id} disabled={disabled} className="pr1">
            {title}
          </option>
        ))}
      </select>
    )}
    <SelectionState
      onClick={() => onSubmit()}
      title={submitTitle}
      warning={submitWarning}
      enabled={submitEnabled}
    />
  </Div>
)

AssignYear.propTypes = propTypes
AssignYear.defaultProps = defaultProps

export default AssignYear
