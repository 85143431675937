import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LessonsPage from 'src/pages/LessonsLayout/LessonsPage'
import LessonsRedirect from 'src/pages/LessonsLayout/LessonsRedirect'

export const path = '/lessons'

const LessonsRoute = () => (
  <Switch>
    <Route exact path="/lessons">
      <LessonsRedirect />
    </Route>
    <Route path="/lessons/:grade?/:gradeSlug?/:year?/:yearSlug?">
      <LessonsPage />
    </Route>
  </Switch>
)

export default LessonsRoute
