import React from 'react'
import { arrayOf, string, shape, func, bool } from 'prop-types'
import { t } from 'i18next'
import {
  add,
  diff,
  formatDateLong,
  formatRange,
  isBetween,
  rangeBy,
  subtract,
} from 'src/lib/dates'
import eventTitleFallBack from 'src/lib/eventTitleFallBack'
import orderBy from 'lodash/orderBy'
import Div from 'src/components/Div'

const insertHolidays = terms => terms.reduce((acc, next, i, arr) => {
  const prev = arr[i - 1]
  return prev ? [
    ...acc,
    {
      finishedOn: subtract(next.startedOn, 1, 'days'),
      holidays: true,
      id: `holidays-${prev.id}`,
      startedOn: add(prev.finishedOn, 1, 'days'),
    },
    next,
  ] : [...acc, next]
}, [])

const withIndex = terms => terms.map((t, i) => ({ ...t, index: i + 1 }))

const addEventIntoTerm = (a, event) => {
  const events = a.events || []
  if (isBetween(event.startDate, a.startedOn, a.finishedOn)) {
    return {
      ...a,
      events: events.concat(event),
    }
  }
  return a
}

const createTermsWithEvents = calendarEvents => (a, term) => {
  const termWithEvents = calendarEvents.reduce(addEventIntoTerm, { ...term })
  return a.concat(termWithEvents)
}

const eventTimeTitle = ({ startDate, endDate }) => `${formatRange(startDate, endDate)}: `

const YearSummary = ({
  title,
  hideTitle,
  academicTerms,
  startedOn: yearStartedOn,
  finishedOn: yearFinishedOn,
  isAcademicDay,
  calendarEvents,
}) => {
  const terms = insertHolidays(withIndex(orderBy(academicTerms, 'startedOn', 'asc')))
  const termsWithEvents = terms.reduce(createTermsWithEvents(calendarEvents), [])
  return (
    <Div>
      <Div mb2>
        {!hideTitle &&
          <Div><b className="qa-year-title">{title} {t('shared.academicYear')}</b></Div>}
        <div className="qa-year-days">
          {diff(yearFinishedOn, yearStartedOn, 'days')} {t('school.settings.calendarDays')}
        </div>
      </Div>
      {termsWithEvents.map(({ id, index, holidays, startedOn, finishedOn, events }) => {
        const days = rangeBy(startedOn, finishedOn, 'day')
        return (
          <Div mb2 key={id} className="qa-year-term">
            <Div>
              <b className="qa-term-title">
                {holidays
                  ? t('school.settings.holidays')
                  : `${t('school.settings.academicTerm')} ${index}`}
              </b>
            </Div>
            <div className="qa-term-dates">
              {formatDateLong(startedOn)} – {formatDateLong(finishedOn)}
            </div>
            {holidays
              ? <span>{days.length} {t('school.settings.calendarDays')}</span>
              : (
                <span>
                  {days.filter(isAcademicDay).length} {t('school.settings.academicDays')}
                </span>
              )}
            {
              events && (
                <ul className="ml3">
                  {
                    events.map((event => (
                      <li key={event.id}>
                        <span className="bold">{eventTimeTitle(event)}</span>
                        <span>
                          {eventTitleFallBack(event)}
                        </span>
                      </li>
                    )))
                  }
                </ul>
              )
            }
          </Div>
        )
      })}
    </Div>
  )
}

YearSummary.propTypes = {
  academicTerms: arrayOf(shape({
    finishedOn: string.isRequired,
    id: string.isRequired,
    startedOn: string.isRequired,
    title: string,
  })).isRequired,
  finishedOn: string.isRequired,
  hideTitle: bool,
  isAcademicDay: func.isRequired,
  startedOn: string.isRequired,
  title: string.isRequired,
}

export default YearSummary
