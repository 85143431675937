import React from 'react'
import { array } from 'prop-types'
import orderBy from 'lodash/orderBy'
import normalizeEntity from 'src/lib/normalizeEntity'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import FlashCards from 'src/modules/AlphabetFlashCard/FlashCards'

const propTypes = {
  alphabetEn: array.isRequired,
}

const slideForLetter = letter => (
  <Slide key={letter.id} label={letter.titleEn}>
    <FlashCards letter={letter} />
  </Slide>
)

const AlphabetFlashCard = ({ alphabetEn: alphabet }) => {
  const orderAlphabet = orderBy(alphabet.map(normalizeEntity), 'titleEn')
  return (
    <Slides>
      {orderAlphabet.map(slideForLetter)}
    </Slides>
  )
}

AlphabetFlashCard.propTypes = propTypes

export default AlphabetFlashCard
