/* eslint-disable no-return-assign */

import React from 'react'
import { t } from 'i18next'

import { location } from 'src/lib/window'

const SchoolSelector = ({ schools }) => {
  const subdomain = location.hostname.split('.')[0]
  return (
    <div className="mb2">
      <select
        className="col-12"
        onChange={e => location.hostname = location.hostname.replace(subdomain, e.target.value)}
        value={subdomain}
      >
        <option disabled value="app">
          {t('login:selectTheSchool')}
        </option>
        {
          schools.map(school => (
            <option key={school.subdomain} value={school.subdomain}>{school.title}</option>
          ))
        }
      </select>
    </div>
  )
}

export default SchoolSelector
