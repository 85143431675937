import React from 'react'
import StudentList from 'src/components/StudentList/StudentListContainer'
import Div from 'src/components/Div'
import ReportLayout from 'src/components/ReportLayout/ReportLayout'
import usePath from 'src/lib/routes/usePath'
import userReport from 'src/lib/routes/userReport'
import { progressReportPath } from 'src/lib/routes'
import Nav from 'src/pages/ProgressPage/Nav'

const setKeys = (object, keys, value) => (
  keys.reduce((acc, next) => ({ ...acc, [next]: value }), object)
)

const updatePath = path => (update) => {
  let updated = { ...path, ...update }

  if (path.subjectId && updated.subjectId !== path.subjectId) {
    updated = setKeys(updated, ['unitSlug', 'limit', 'strandSlug', 'standardSlug'], null)
  }

  if (path.unitSlug && updated.unitSlug !== path.unitSlug) {
    updated = setKeys(updated, ['limit'], null)
  }

  if (path.strandId && updated.strandId !== path.strandId) {
    updated = setKeys(updated, ['strandSlug'], null)
  }

  if (path.academicYearId && updated.academicYearId !== path.academicYearId) {
    updated = setKeys(updated, ['academicTermId'], null)
  }

  return updated
}

const ProgressPage = ({
  history,
  match,
  pageState,
  Component,
}) => {
  const { academicYearId, domain, subjectId, tab, userId, yearId } = pageState
  const studentPath = usePath(userReport)
  const progressPath = usePath(progressReportPath)
  const updatedPath = update => progressPath(updatePath(pageState)(update))
  const changePath = update => history.push(updatedPath(update))
  return (
    <ReportLayout {...pageState} onParamsChange={changePath}>
      <Div>
        <Nav tab={tab} tabPath={tab => updatedPath({ tab })} />
        <Div flex gutter={1} className="flex-column">
          <Div col={3} px={1} mb={2} noPrint>
            <StudentList
              academicYearId={academicYearId}
              domain={domain}
              studentPath={(_, userId) => studentPath({
                userId,
                subjectId,
              })}
              userId={userId}
              yearId={yearId}
              onYearChange={yearId => changePath({ yearId })}
            />
          </Div>
          <Div flex col={9} px={1} className="print-col-12">
            <Component
              {...pageState}
              onPathChange={changePath}
              report
              history={history}
              match={match}
            />
          </Div>
        </Div>
      </Div>
    </ReportLayout>
  )
}

export default ProgressPage
