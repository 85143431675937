import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { Link } from 'react-router-dom'

const propTypes = {
  crumbs: arrayOf(shape({
    path: string.isRequired,
    title: string.isRequired,
  })),
  currentTitle: string.isRequired,
}

const defaultProps = {
  crumbs: [],
}

const Breadcrumbs = ({
  crumbs,
  currentTitle,
}) => (
  <span>
    {
      crumbs.map(crumb => (
        <span key={crumb.path}>
          <Link to={crumb.path}>{crumb.title}</Link>
          {' / '}
        </span>
      ))
    }
    {
      currentTitle
    }
  </span>
)

Breadcrumbs.propTypes = propTypes
Breadcrumbs.defaultProps = defaultProps

export default Breadcrumbs
