import React from 'react'
import { useParams } from 'react-router'
import { t } from 'i18next'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import PrefixedLink from 'src/components/PrefixedLink'
import { attendancePath, attendanceWeeklyPath } from 'src/lib/routes'
import Tabs from 'src/components/Tabs'

// TDOD: Need to get rid of the PrefixedLink #5361
const Tab = ({ selected, children, path }) => (
  <Div btn rounded-top bg={selected ? 'white' : ''}>
    <PrefixedLink to={path}>
      {children}
    </PrefixedLink>
  </Div>
)

const Nav = () => {
  const { tab = 'today' } = useParams()
  return (
    <Tabs mb1>
      <Tab selected={tab === 'today'} path={attendancePath()}>
        <Icon icon="chart-area" />{' '}{t('shared.today')}
      </Tab>
      <Tab selected={tab === 'weekly'} path={attendanceWeeklyPath()}>
        <Icon icon="chart-line" />{' '}{t('attendance.nav.weekly')}
      </Tab>
    </Tabs>
  )
}

export default Nav
