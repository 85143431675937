import i18next from 'i18next'

const eventTitleFallBack = (event) => {
  if (i18next.language === 'en') {
    if (event.titleEn) return event.titleEn
    return event.titleTh
  }
  if (i18next.language === 'th') {
    if (event.titleTh) return event.titleTh
    return event.titleEn
  }
  return ''
}

export default eventTitleFallBack
