import React from 'react'
import { func, shape } from 'prop-types'

import chunk from 'lodash/chunk'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Place from 'src/components/Place'
import mapKey from 'src/lib/mapKey'

const propTypes = {
  cards: shape().isRequired,
  entitiesById: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderCard = ({ cardId, cards, entitiesById }) => {
  const card = cards[cardId]
  const entity = entitiesById[card.entityId]
  const locale = {
    [card.locale]: true,
  }
  return (
    <AdaptiveCard
      {...locale}
      correct={card.correct}
      draggable
      entity={entity}
      fit
      id={cardId}
      key={cardId}
      minHeight={8}
      audio
    />
  )
}

const MatchTranslation = ({
  entitiesById,
  cards,
  places,
  moveCard,
}) => {
  const pairPlaces = places ? chunk(Object.keys(places).map(id => places[id]), 2) : []
  return (
    <Deck>
      {pairPlaces.map((pair, index) => (
        <Div key={mapKey(index)} flex gutter={1} flex-1>
          {
              pair.map(({ id, cardId }) => (
                <Place
                  id={id}
                  key={id}
                  onDrop={moveCard}
                >
                  {renderCard({ cardId, cards, entitiesById })}
                </Place>
              ))
          }
        </Div>
      ))}
    </Deck>
  )
}

MatchTranslation.propTypes = propTypes
export default MatchTranslation
