import { Howl } from 'howler'

let lastAudio = null
const audioPlayedKeyPrefix = 'audio-played='

function audioPlayer({
  isMarkAudio,
  prefixSrc,
  src,
  loop = false,
  volume = 1,
  onStop = () => {},
  onLoad = () => {},
  onEnd = () => {},
  onPlay = () => {},
}) {
  const audio = new Howl({
    autoplay: false,
    html5: true,
    loop,
    onend: () => {
      lastAudio = null
      onEnd()
    },
    onload: onLoad,
    onplay: onPlay,
    onstop: onStop,
    src: [src],
    volume,
  })

  const prefixAudio = new Howl({
    autoplay: false,
    html5: true,
    loop,
    onend: () => {
      lastAudio = null
      onEnd()
    },
    onload: onLoad,
    onplay: onPlay,
    onstop: onStop,
    src: [prefixSrc],
    volume,
  })

  function stopLastAudio() {
    if (!lastAudio) return
    lastAudio.stop()
  }

  function isPlayed() {
    return sessionStorage.getItem(audioPlayedKeyPrefix + src)
  }

  function setPlayed() {
    lastAudio = audio
    sessionStorage.setItem(audioPlayedKeyPrefix + src, true)
  }

  function play({ playOnce, silent } = {}) {
    if (playOnce && (silent || isPlayed())) return
    stopLastAudio()
    if (isMarkAudio) prefixAudio.rate(1, prefixAudio.play())
    audio.play()
    setPlayed()
  }

  function off() {
    if (isMarkAudio) {
      prefixAudio.off()
      prefixAudio.unload()
    }
    audio.off()
    audio.unload()
  }

  function getSrc() {
    return src
  }

  function stop() {
    if (isMarkAudio) prefixAudio.stop()
    audio.stop()
  }

  return {
    getSrc,
    off,
    play,
    stop,
  }
}

export default audioPlayer
