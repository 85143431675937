import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Spelling from 'src/modules/Word/Spelling/Spelling'
import * as spellingActions from 'src/modules/Word/Spelling/reducer'

const { array, func, number } = PropTypes
const propTypes = {
  alphabetEn: array.isRequired,
  initSpelling: func.isRequired,
  letters: array.isRequired,
  phonics: array.isRequired,
  playing: number,
  playNext: func.isRequired,
  playPhonics: func.isRequired,
  sizes: array.isRequired,
}

const defaultProps = {
  playing: null,
}

class SpellingContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { alphabetEn, phonics, initSpelling } = this.props
    initSpelling({ alphabetEn, phonics })
  }

  render() {
    const {
      letters,
      playing,
      playPhonics,
      playNext,
      sizes,
    } = this.props
    return (
      <Spelling
        playNext={() => playNext()}
        playing={playing}
        playPhonics={() => playPhonics()}
        letters={letters}
        sizes={sizes}
      />
    )
  }
}

SpellingContainer.propTypes = propTypes
SpellingContainer.defaultProps = defaultProps

const select = state => state.spelling
const actions = dispatch => bindActionCreators(spellingActions, dispatch)

export { SpellingContainer }
export default connect(select, actions)(SpellingContainer)
