import React from 'react'
import { t } from 'i18next'

const TableHeader = () => (
  <thead>
    <tr>
      <th className="col-3" />
      <th className="col-1">
        {t('user.identifier')}
      </th>
      <th className="col-1">
        {t('attendance.report.days')}
      </th>
      <th className="col-1">
        {t('attendance.shared.present')}
      </th>
      <th className="col-1">
        {t('attendance.shared.absent')}
      </th>
      <th className="col-2">
        {t('attendance.report.attendance')}
      </th>
      <th className="col-1">
        {t('attendance.report.grade')}
      </th>
      <th className="col-2" />
    </tr>
  </thead>
)

export default TableHeader
