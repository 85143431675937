import React, { useState, createContext, useEffect, useRef, useContext } from 'react'
import ResizeObserver from 'react-resize-observer'
import { element, arrayOf, oneOfType, objectOf, string, number } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'

const WIDTH = 1360
const HEIGHT = 765
const ASPECT_RATIO = 16 / 9

const propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  screenHeight: number,
  screenWidth: number,
}

const defaultProps = {
  screenHeight: HEIGHT,
  screenWidth: WIDTH,
}

const considerVerticalBody = ({ height, width }) => {
  document.body.className = width / height < ASPECT_RATIO ? 'vertical' : ''
}

const ScaleContext = createContext()

const calcScale = ({
  domRect: { width, height },
  screenHeight,
  screenWidth,
}) => Math.min(width / screenWidth, height / screenHeight)

const calculateStyleToFit = ({ curScale: scale, screenHeight, screenWidth }) => {
  const size = {
    height: `${screenHeight}px`,
    width: `${screenWidth}px`,
  }
  return {
    ...size,
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%) scale(${scale})`,
  }
}

const ScalableModule = ({ children, screenHeight, screenWidth }) => {
  const [style, setStyle] = useState({})
  const [scale, setScale] = useState(1)

  const doResize = (domRect) => {
    const curScale = calcScale({ domRect, screenHeight, screenWidth })
    considerVerticalBody(domRect)
    setStyle(calculateStyleToFit({ curScale, screenHeight, screenWidth }))
    setScale(curScale)
  }

  return (
    <Deck relative>
      <ScaleContext.Provider value={{ scale }}>
        <Div
          absolute
          flex
          column
          justify="center"
          style={{
            padding: `${2 / ASPECT_RATIO}rem ${2}rem`,
            ...style,
          }}
          className="content"
        >
          {children}
        </Div>
      </ScaleContext.Provider>
      <ResizeObserver onResize={doResize} />
    </Deck>
  )
}
ScalableModule.propTypes = propTypes
ScalableModule.defaultProps = defaultProps

const Unscaled = ({ children, style }) => {
  const [unscaledStyle, setUnscaledStyle] = useState({})
  const sizeRef = useRef(null)
  const ctx = useContext(ScaleContext)
  const { scale } = ctx || { scale: 1 }
  const calculateUnscaledStyles = () => {
    const e = sizeRef.current
    if (e) {
      const unscale = 1 / scale
      const width = e.offsetWidth * scale
      const height = e.offsetHeight * scale
      const left = (e.offsetWidth - width) / 2
      const top = (e.offsetHeight - height) / 2

      return {
        height: `${height}px`,
        transform: `translate(${left}px, ${top}px) scale(${unscale})`,
        width: `${width}px`,
      }
    }
    return {}
  }
  const doResize = () => {
    setUnscaledStyle(calculateUnscaledStyles())
  }
  useEffect(() => {
    doResize()
  })

  return (
    <div ref={sizeRef} style={{ ...style, height: '100%', width: '100%' }}>
      <div style={unscaledStyle}>{children}</div>
    </div>
  )
}
Unscaled.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  style: objectOf(oneOfType([string, number])),
}

Unscaled.defaultProps = {
  children: null,
  style: null,
}

export default ScalableModule

export { ScaleContext, Unscaled }
