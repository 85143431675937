import React from 'react'
import { any, string } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  children: any,
  className: string,
}

const defaultProps = {
  className: '',
}

const Tabs = ({ children, className, ...rest }) => (
  <Div noPrint className={className} {...rest}>
    <Div>{children}</Div>
    <Div bg-white pb shadow />
  </Div>
)

Tabs.propTypes = propTypes
Tabs.defaultProps = defaultProps
export default Tabs
