import React from 'react'
import { connect } from 'src/lib/redux'
import { bindActionCreators } from 'redux'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { bool, func, string } from 'prop-types'

import Icon from 'src/components/Icon'
import Div from 'src/components/Div'
import { isMobileOrNative } from 'src/lib/window'

import { display, layout } from 'src/components/SoftKeyboard/keyboardLayout'

import { showSoftKeyboard, hideSoftKeyboard, updateSoftKeyboardKey } from 'src/components/SoftKeyboard/reducer'

const propTypes = {
  hideSoftKeyboard: func.isRequired,
  isSoftKeyboardEnable: bool.isRequired,
  isSoftKeyboardShow: bool.isRequired,
  layoutName: string.isRequired,
  showSoftKeyboard: func.isRequired,
  updateSoftKeyboardKey: func.isRequired,
}

const SoftKeyboard = ({
  hideSoftKeyboard,
  isSoftKeyboardEnable,
  isSoftKeyboardShow,
  layoutName,
  showSoftKeyboard,
  updateSoftKeyboardKey,
}) => {
  if (isSoftKeyboardEnable === false || isMobileOrNative() === undefined) return null
  if (isSoftKeyboardShow) {
    return (
      <Div flex column key="SoftKeyboard">
        <Div
          circle
          bg="white"
          onClick={hideSoftKeyboard}
          height={4}
          width={4}
          z={1}
          absolute
          style={{ fontSize: '2.5rem', right: '1rem', top: '4rem' }}
          flex
        >
          <Icon
            icon="chevron-right"
            style={{ alignSelf: 'center', color: 'gray', width: '4rem' }}
          />
        </Div>
        <Div
          onClick={hideSoftKeyboard}
          style={{ position: 'fixed', height: '100%', width: '100%' }}
          z={2}
        />
        <Div
          style={{ position: 'fixed', bottom: '0', width: '-webkit-fill-available' }}
          z={4}
          bg="white"
        >
          <Keyboard
            onKeyPress={button => updateSoftKeyboardKey({ keyInput: button })}
            layout={layout}
            display={display}
            layoutName={layoutName}
          />
        </Div>
      </Div>
    )
  }
  return (
    <Div
      circle
      bg="white"
      onClick={showSoftKeyboard}
      height={4}
      width={4}
      z={1}
      absolute
      style={{ fontSize: '2.5rem', right: '1rem', top: '4rem' }}
      flex
    >
      <Icon icon="keyboard" style={{ alignSelf: 'center', color: 'gray', width: '4rem' }} />
    </Div>
  )
}

SoftKeyboard.propTypes = propTypes

const select = state => ({
  isSoftKeyboardEnable: state.softKeyboard.isSoftKeyboardEnable,
  isSoftKeyboardShow: state.softKeyboard.isSoftKeyboardShow,
  layoutName: state.softKeyboard.layoutName,
})

const actions = dispatch => bindActionCreators({
  hideSoftKeyboard,
  showSoftKeyboard,
  updateSoftKeyboardKey,
}, dispatch)

export { SoftKeyboard }
export default connect(select, actions)(SoftKeyboard)
