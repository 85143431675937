import React from 'react'
import ListenAndSelect from 'src/modules/ListenAndSelect'

const defaultProps = {
  image: true,
}

export const MultipleChoiceImageContainer = props => <ListenAndSelect {...props} />

const demoData = {
  entities: [
    'apple',
    'carrot',
    'phrase-it-is-a-banana',
    'phrase-it-is-a-mango',
    'father',
    'mother',
    'sister',
    'brother',
  ],
}
MultipleChoiceImageContainer.demoData = demoData

MultipleChoiceImageContainer.defaultProps = defaultProps
