import ImageHeader from 'src/modules/SelectType/Header/ImageHeader'
import StandardHeader from 'src/modules/SelectType/Header/StandardHeader'

const EmptyHeader = () => (null)

const map = {
  image: ImageHeader,
  standard: StandardHeader,
}

const headerForType = trueOrFalseType => map[trueOrFalseType] || EmptyHeader

export default headerForType
