import React from 'react'
import isNil from 'lodash/isNil'
import { shape, bool, func } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import SpeakerBubble from 'src/components/SpeakerBubble'
import Bubble from 'src/components/Bubble'
import TextFit from 'src/components/TextFit'
import SpeechRecognition from 'src/modules/ListenAndSpeak/SpeechRecognition'
import KeyLabel from 'src/components/KeyLabel'
import EntityCard from 'src/components/EntityCard'
import replaceWord from 'src/lib/replaceWord'

const propTypes = {
  answer: shape(entityShape).isRequired,
  completed: bool,
  onAnswer: func.isRequired,
  phrase: shape(entityShape).isRequired,
  question: shape(entityShape).isRequired,
}

const defaultProps = {
  completed: null,
}

const ListenAndSpeakScene = ({
  phrase,
  question,
  answer,
  onAnswer,
  completed,
}) => {
  const hasAnswer = !isNil(completed)
  const correct = completed
  let seq = 0
  return (
    <Deck>
      <Div flex flex-1 p1>
        <SpeakerBubble
          hasTip
          justify="left"
          speakerEntity={phrase}
        >
          <EntityCard
            play
            autoPlay
            english
            audio
            entity={phrase}
            keyToPlay="1"
          />
          <Div absolute flex auto column left-0 px justify="center" style={{ height: '100%' }}>
            <KeyLabel label="1" />
          </Div>
        </SpeakerBubble>
      </Div>
      <Div flex flex-1 p1>
        <Div flex align="center">
          <SpeechRecognition onComplete={onAnswer} />
        </Div>
        <Bubble>
          <Div
            className="qa-question-phrase-container"
            flex
            key={answer.id}
          >
            <TextFit>
              <Div flex wrap justify="right" px2 p1 className="qa-question-phrase">
                {/* eslint-disable-next-line no-return-assign */}
                {replaceWord([question.titleEn], answer.titleEn, () => (
                  <Div
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`placeholder-${seq += 1}`}
                    p1
                    mx2
                    mtn={1}
                    rounded
                    transition-all
                    /* eslint-disable-next-line no-nested-ternary */
                    bg={hasAnswer ? (correct ? 'green' : 'red') : 'silver'}
                    className="qa-answer"
                  >
                    {hasAnswer ? answer.titleEn : '...'}
                  </Div>
                ))}
              </Div>
            </TextFit>
          </Div>
        </Bubble>
      </Div>
    </Deck>
  )
}

ListenAndSpeakScene.propTypes = propTypes
ListenAndSpeakScene.defaultProps = defaultProps

export default ListenAndSpeakScene
