import React from 'react'
import { number, shape, string } from 'prop-types'
import { t } from 'i18next'
import classNames from 'classnames'

import Div from 'src/components/Div'
import Progress from 'src/components/Progress'
import Icon from 'src/components/Icon'
import { skillIcon } from 'src/pages/StudentSkillsPage/config'

const propTypes = {
  index: number.isRequired,
  skill: shape(
    {
      id: string.isRequired,
      rate: number.isRequired,
    },
  ).isRequired,
}

const SkillProgress = ({ skill, index }) => (
  <Div
    z1
    relative
    rounded
    p={2}
    mb={1}
    bg-body
    className={
      classNames(
        `qa-skill-${skill.id}`,
        {
          zebra: index % 2,
        },
      )
    }
  >
    <Div mb={1} bold>
      <Icon icon={skillIcon[skill.id]} />
      {t(`skills.${skill.id}`)}
    </Div>
    <Progress
      value={skill.rate}
      colorByRate
    />
  </Div>
)

SkillProgress.propTypes = propTypes

export default SkillProgress
