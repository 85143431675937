import React from 'react'
import { string, element } from 'prop-types'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import isFunction from 'lodash/isFunction'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import usePageParams from 'src/lib/usePageParams'

const propTypes = {
  children: element.isRequired,
  id: string.isRequired,
}

const Modal = ({ id, children }) => {
  const history = useHistory()
  const open = usePageParams()[id]
  const closeModal = () => history.push(`?${id}=`)
  return open ? (
    <Div fixed stretch z2>
      <Div fixed stretch bg-darken-4 flex flex-column onClick={closeModal} />
      <Div container p3>
        <Div bg-white rounded p3 relative shadow animated fadeIn>
          {isFunction(children) ? children({ closeModal }) : children}
          <Div absolute top-0 right-0 mt1 cursor-pointer onClick={closeModal}>
            <Icon icon="times" size={4} />
          </Div>
        </Div>
      </Div>
    </Div>
  ) : null
}
Modal.propTypes = propTypes

const OpenModal = ({ id, children }) => <Link to={`?${id}=1`}>{children}</Link>
OpenModal.propTypes = propTypes

export { OpenModal }
export default Modal
