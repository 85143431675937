import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'
import { path as yearPath } from 'src/pages/Year/Show/config'

const path = '/:domain/years'
const matchPaths = [path, yearPath]
const scene = {
  exact: true,
  icon: icons[menu.YEARS],
  id: menu.YEARS,
  matchPaths,
  path,
}

export {
  path,
  scene,
}
