import sample from 'lodash/sample'

const PLAY = 'sounds/PLAY'
const END = 'sounds/END'

const initialState = {
  soundsPlaying: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAY:
      return {
        soundsPlaying: [...state.soundsPlaying, action.payload],
      }
    case END:
      return {
        soundsPlaying: state.soundsPlaying.filter((s, i) => i !== action.payload),
      }
    default:
      return state
  }
}

const gameCompletedSoundAwesome = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/Awesome.mp3',
}

const gameCompletedSoundCorrect = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/Correct.mp3',
}

const gameCompletedSoundGoodJob = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/GoodJob.mp3',
}

const gameCompletedSoundGreatJob = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/GreatJob.mp3',
}

const gameCompletedSoundWellDone = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/WellDone.mp3',
}

const gameCompletedSoundYeah = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/Yeah.mp3',
}

const gameCompletedSoundYes = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/Yes.mp3',
}

const gameCompletedSoundSuper = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Ping.mp3',
  src: '/sounds/game/Super.mp3',
}

const gameNotCompletedIncorrect = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/Incorrect.mp3',
}

const gameNotCompletedNope = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/Nope.mp3',
}

const gameNotCompletedOops = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/Oops.mp3',
}

const gameNotCompletedSorry = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/Sorry.mp3',
}

const gameNotCompletedTryAgain = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/TryAgain.mp3',
}

const gameNotCompletedTryAgainNextTime = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/TryAgainNextTime.mp3',
}

const gameNotCompletedUhOh = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/UhOh.mp3',
}

const gameNotCompletedWrong = {
  isMarkAudio: true,
  prefixSrc: '/sounds/game/Boing.mp3',
  src: '/sounds/game/Wrong.mp3',
}

const playGameCompletedSound = () => ({
  payload: sample([
    gameCompletedSoundAwesome,
    gameCompletedSoundCorrect,
    gameCompletedSoundGoodJob,
    gameCompletedSoundGreatJob,
    gameCompletedSoundSuper,
    gameCompletedSoundWellDone,
    gameCompletedSoundYeah,
    gameCompletedSoundYes,
  ]),
  type: PLAY,
})

const playGameNotCompletedSound = () => ({
  payload: sample([
    gameNotCompletedIncorrect,
    gameNotCompletedNope,
    gameNotCompletedOops,
    gameNotCompletedSorry,
    gameNotCompletedTryAgain,
    gameNotCompletedTryAgainNextTime,
    gameNotCompletedUhOh,
    gameNotCompletedWrong,
  ]),
  type: PLAY,
})

const playGunShotSound = () => ({
  payload: {
    src: '/sounds/card/click.wav',
  },
  type: PLAY,
})

const playWrongSound = () => ({
  payload: {
    src: '/sounds/game/Boing.mp3',
  },
  type: PLAY,
})

const soundFinishedPlaying = index => ({
  payload: index,
  type: END,
})

export default reducer

export {
  playGameNotCompletedSound,
  playGameCompletedSound,
  playWrongSound,
  soundFinishedPlaying,
  playGunShotSound,
}
