import React from 'react'
import { shape } from 'prop-types'
import compact from 'lodash/compact'
import Slide from 'src/components/Layout/Slide'
import Div from 'src/components/Div'
import ImageOrPlaceholder from 'src/components/ImageOrPlaceholder'
import entityShape from 'src/shapes/entity'
import useSequence from 'src/lib/useSequence'
import WithPlayButton from 'src/components/WithPlayButton'
import wait from 'src/lib/wait'

const propTypes = {
  phonics: shape(entityShape).isRequired,
  word: shape(entityShape),
}

const defaultProps = {
  word: null,
}

const PhonicsSlide = ({ phonics, word }) => {
  const steps = compact([phonics, phonics, phonics, ...(word ? [() => wait(300), word] : [])])
  const [step, nextStep] = useSequence(steps)
  return (
    <Slide key={phonics.id}>
      <WithPlayButton
        key_={step}
        autoPlay={step > 0}
        entity={steps[step]}
        playNext={nextStep}
      >
        <Div flex flex-1><ImageOrPlaceholder entity={phonics} /></Div>
        {word && (
          <Div flex flex-1>
            <ImageOrPlaceholder entity={word} />
          </Div>
        )}
      </WithPlayButton>
    </Slide>
  )
}

PhonicsSlide.propTypes = propTypes
PhonicsSlide.defaultProps = defaultProps

export default PhonicsSlide
