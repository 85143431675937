import React from 'react'
import ListenAndShootContainer from 'src/modules/ListenAndShoot/ListenAndShootContainer'

const defaultProps = {
  cursive: true,
  text: true,
}

const ListenAndShootCursiveText = props => <ListenAndShootContainer {...props} />

ListenAndShootCursiveText.defaultProps = defaultProps
ListenAndShootCursiveText.demoData = {
  cols: 4,
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
    'mother',
  ],
  rows: 1,
}


export default ListenAndShootCursiveText
