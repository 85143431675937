import icons from 'src/lib/menu/icons'
import { HOMEWORK } from 'src/constants/menu'

const path = '/:domain/reports/homework'

const scene = {
  icon: icons[HOMEWORK],
  id: HOMEWORK,
  matchPaths: [path, '/:domain/reports/:academicYearId/:academicTermId/homework'],
  path,
}

export {
  path,
  scene,
}
