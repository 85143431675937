import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'

const path = '/:domain/curriculum'

const schoolScene = {
  icon: icons[menu.LESSONS],
  id: menu.SCHOOL_CURRICULUM,
  path,
}

const teacherScene = {
  icon: icons[menu.LESSONS],
  id: menu.MY_STUDENTS_CURRICULUM,
  path,
}

export { path, schoolScene, teacherScene }
