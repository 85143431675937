import React from 'react'
import { bool, func, shape, string } from 'prop-types'
import { YearSelectorWithStyle } from 'src/selectors/YearSelector'
import StudentList from 'src/components/StudentList/StudentList'

const propTypes = {
  academicYearId: string,
  attendance: bool,
  domain: string,
  marked: bool,
  onYearChange: func.isRequired,
  studentPath: func.isRequired,
  userId: string,
  year: shape(),
}

const defaultProps = {
  academicYearId: null,
  attendance: false,
  domain: null,
  marked: false,
  userId: null,
  year: null,
}

const StudentListLayout = ({
  academicYearId,
  attendance,
  marked,
  userId,
  studentPath,
  domain,
  year,
  onYearChange,
}) => {
  if (!year) { return null }
  return (
    <div className="relative">
      <YearSelectorWithStyle
        onChange={onYearChange}
        yearId={year.id}
        domain={domain}
        academicYearId={academicYearId}
      />
      <StudentList
        attendance={attendance}
        marked={marked}
        studentPath={studentPath}
        students={{ ...year }.students}
        userId={userId}
        year={year}
      />
    </div>
  )
}

StudentListLayout.propTypes = propTypes
StudentListLayout.defaultProps = defaultProps
export default StudentListLayout
