import uniq from 'lodash/uniq'

const buildSteps = ({ normalizedChapters, homework, goToChapter }) => (
  normalizedChapters.map(({ title, published }, i) => ({
    i,
    onClick: () => !homework && goToChapter({ chapterIndex: i }),
    published,
    title,
  }))
)
const stripPrefix = ({ chapters, prefix }) => (
  chapters.map(c => ({ ...c, title: c.title.replace(`${prefix}: `, '') }))
)

const steps = ({ chapters, homework, goToChapter }) => {
  const prefixes = uniq(chapters.map(c => c.title.split(': ')[0]))
  const normalizedChapters = prefixes.length === 1
    ? stripPrefix({ chapters, prefix: prefixes[0] })
    : chapters
  return buildSteps({
    goToChapter,
    homework,
    normalizedChapters,
  })
}

export default steps
