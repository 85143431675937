import React from 'react'
import usePageParams from 'src/lib/usePageParams'
import { func } from 'prop-types'
import { Redirect } from 'react-router-dom'
import defaultYearId from 'src/lib/defaultYearId'
import gqlLoader from 'src/lib/gqlLoader'
import studentList from 'src/queries/student-list/class-selector.gql'
import ErrorMessage from 'src/components/ErrorMessage'
import { t } from 'i18next'

const RedirectToDefaultYear = gqlLoader(studentList)(({ sections, path }) => (
  defaultYearId(sections)
    ? <Redirect to={path({ params: usePageParams(), yearId: defaultYearId(sections) })} />
    : <ErrorMessage message={t('errors.noRecordsFound')} />
))

RedirectToDefaultYear.propTypes = {
  path: func.isRequired,
}

export default RedirectToDefaultYear
