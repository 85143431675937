import React from 'react'
import phonicShape from 'src/shapes/phonic'
import { arrayOf, shape } from 'prop-types'
import Slide from 'src/components/Layout/Slide'
// eslint-disable-next-line import/no-named-as-default
import Slides from 'src/components/Layout/Slides'
import DrawLetter from 'src/modules/DrawLetters/DrawLetter'

const propTypes = {
  actualEntities: arrayOf(shape(phonicShape).isRequired).isRequired,
}
const defaultProps = {
}

// eslint-disable-next-line react/prop-types
const createSlide = ({ entity, ...rest }) => (
  <Slide key={entity.id} label={entity.titleEn}>
    <DrawLetter {...{ ...rest, entity }} />
  </Slide>
)
const DrawLetters = ({ actualEntities, ...rest }) => (
  <Slides>{actualEntities.map(entity => createSlide({ ...rest, entity }))}</Slides>
)
DrawLetters.propTypes = propTypes
DrawLetters.defaultProps = defaultProps

export default DrawLetters
