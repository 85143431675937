import React from 'react'
import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EntityImage from 'src/components/EntityImage'
import Bubble from 'src/components/Bubble'

const Speaker = ({
  autoPlay,
  entitiesById,
  firstEntityId,
  label,
  secondEntityId,
  silent,
}) => (
  <Div auto flex py={2} px={2}>
    <Bubble
      label={silent ? null : label}
    >
      <Div flex col={8} pr1>
        {firstEntityId && (
        <AdaptiveCard
          autoPlay={autoPlay}
          notInGroup
          audio
          english
          entity={entitiesById[firstEntityId]}
          key={firstEntityId}
          keyToPlay={label}
          silent={silent}
          p
        />
        )}
      </Div>
      <Div col={4} flex auto pr1>
        {secondEntityId &&
          <EntityImage entity={entitiesById[secondEntityId]} />}
      </Div>
    </Bubble>
  </Div>
)

export default Speaker
