import React from 'react'
import { func, string, object, bool } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import Div from 'src/components/Div'
import Card from 'src/components/Card'

const propTypes = {
  cardId: string,
  correct: bool,
  entitiesById: object,
  id: string,
  moveCard: func,
}

const Line = ({ id, cardId, label, entitiesById, moveCard, correct, imageSize, placesLength }) => {
  const sound = entitiesById[cardId].sound || { srcEn: '' }
  const src = sound.srcEn || ''
  const isShowImage = placesLength <= 5
  const labelFontSize = placesLength < 8 ? 1 : 5
  const theImageSize = isShowImage ? imageSize : 0
  return (
    <Place id={id} onDrop={moveCard} className="flex-1">
      <Div flex auto>
        <Div flex justify-center width={4} size={labelFontSize}>
          <Div flex column justify-center>
            {`${label}.`}
          </Div>
        </Div>
        <Card
          minHeight={false}
          key={cardId}
          draggable
          id={id}
          audio
          src={src}
          correct={correct}
        >
          <Div flex col-12 auto>
            {
              isShowImage && (
                <Div flex className={`col-${theImageSize}`} auto mr1>
                  <EntityCard
                    minHeight={false}
                    english
                    image
                    entity={entitiesById[cardId]}
                    transparent
                  />
                </Div>
              )
            }
            <Div flex className={`col-${12 - theImageSize}`} auto px>
              <AdaptiveCard
                minHeight={false}
                english
                entity={entitiesById[cardId]}
                transparent
                reduceFontSize={0.7}
              />
            </Div>
          </Div>
        </Card>
      </Div>
    </Place>
  )
}

Line.propTypes = propTypes
export default Line
