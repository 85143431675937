import React from 'react'
import { bool, number, func, string, arrayOf, oneOf, shape, objectOf } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Deck from 'src/components/Layout/Deck'
import Header from 'src/modules/SelectType/Header'
import Line from 'src/modules/SelectType/Line'

const shapeOfLine = shape({
  correct: bool,
  id: string.isRequired,
  ids: arrayOf(string),
  onClick: func,
  select: string,
  silent: bool,
})

const propTypes = {
  audioOnly: bool,
  choicesType: arrayOf(string),
  entitiesById: objectOf(shape(entityShape)),
  headerHeight: number,
  image: string,
  lines: arrayOf(shapeOfLine.isRequired).isRequired,
  onClick: func.isRequired,
  paragraphIds: arrayOf(string),
  silent: bool,
  trueOrFalseType: oneOf(['image', 'standard']),
}

const defaultProps = {
  audioOnly: false,
  choicesType: [],
  entitiesById: {},
  headerHeight: 22,
  image: null,
  paragraphIds: [],
  silent: undefined,
  trueOrFalseType: undefined,
}

const SelectType = ({
  audioOnly,
  choicesType,
  entitiesById,
  headerHeight,
  image,
  lines,
  onClick,
  paragraphIds,
  silent,
  trueOrFalseType,
}) => (
  <Deck>
    <Header {...{
      audioOnly,
      entitiesById,
      headerHeight,
      image,
      paragraphIds,
      trueOrFalseType,
    }}
    />
    {lines.map(({ id, ids, select, correct, type }) => (
      <Line
        {...{
          choicesType,
          correct,
          entitiesById,
          id,
          ids,
          onClick,
          select,
          silent,
          type,
        }}
        key={id}
      />
    ))}
  </Deck>
)

SelectType.propTypes = propTypes
SelectType.defaultProps = defaultProps
export default SelectType
