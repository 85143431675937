import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/MatchTextWithImage/reducer'
import MatchTextWithImage from 'src/modules/MatchTextWithImage/MatchTextWithImage'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
  variants: null,
}

class MatchTextWithImageContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      mark,
      retry,
      completed,
      availableCards,
      cards,
      places,
      moveCard,
    } = this.props
    return (
      <Game {...{ actualEntities, completed, init: () => this.init(), mark, retry }}>
        <MatchTextWithImage {...{ availableCards, cards, moveCard, places }} />
      </Game>
    )
  }
}

MatchTextWithImageContainer.propTypes = propTypes
MatchTextWithImageContainer.defaultProps = defaultProps
MatchTextWithImageContainer.demoData = {
  entities: [
    'mother',
    'father',
    'family',
  ],
}

const select = state => state.matchTextWithImage

const actions = dispatch => (
  bindActionCreators(cardActions, dispatch)
)

export { MatchTextWithImageContainer }
export default connect(select, actions)(MatchTextWithImageContainer)
