import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { t } from 'i18next'

const INSTRUCTIONS = [
  { key: 'leftArrow', value: '←' },
  { key: 'rightArrow', value: '→' },
  { key: 'numbers', value: '1-9' },
  { key: 'space', value: 'Space' },
]

const ShortcutInfo = () => {
  const [shown, setShown] = useState(false)

  const showInfo = () => setShown(true)
  const hideInfo = () => setShown(false)

  return (
    <div>
      <i
        className="fas fa-question-circle fa absolute left-0 ml1 bottom-0 z3 cursor-pointer"
        style={{ top: '-50%' }}
        onClick={showInfo}
      />
      <ReactModal
        isOpen={shown}
        onRequestClose={hideInfo}
        ariaHideApp={false}
        className="modal-small"
      >
        <i className="fas fa-2x fa-times right cursor-pointer" onClick={hideInfo} />
        <h3>
          {t('shortcut.instructions.title')}
        </h3>
        {
          INSTRUCTIONS.map(
            ({ key, value }) => (
              <p key={key}>
                <kbd>
                  {value}
                </kbd>
                {' - '}
                {t(`shortcut.instructions.${key}`)}
              </p>
            ),
          )
        }
      </ReactModal>
    </div>
  )
}

export default ShortcutInfo
