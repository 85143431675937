import React from 'react'
import { useParams } from 'react-router'
import DictionaryEntityContainer from 'src/pages/preview/Dictionary/DictionaryEntityContainer'
import Div from 'src/components/Div'

const PreviewDictionaryPage = () => {
  const params = useParams()
  return (
    <Div vh100 flex column>
      <DictionaryEntityContainer {...params} />
    </Div>
  )
}

export default PreviewDictionaryPage
