import { arrayOf, shape, string } from 'prop-types'
import localizedField from 'src/shapes/localizedField'
import sectionShape from 'src/shapes/section'
import unitShape from 'src/shapes/unit'
import yearShape from 'src/shapes/year'

const gradeShape = {
  code: string,
  id: string,
  identifier: string,
  section: shape(sectionShape),
  slug: string,
  title: string,
  titleRaw: shape(localizedField),
  units: arrayOf(shape(unitShape)),
  years: arrayOf(shape(yearShape)),
}

export default gradeShape
