import React from 'react'
import {
  array,
  arrayOf,
  bool,
  func,
  shape,
} from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import entityShape from 'src/shapes/entity'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import {
  updateTriesIterationIcon,
  clearTriesIterationIcon,
} from 'src/components/IterationIcon/reducer'
import SpeechRecognitionQABase from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer/SpeechRecognitionQABase'
import withSpeechRecognition from 'src/modules/SpeechRecognition/SpeechRecognitionHOC'
import * as speechRecognitionActions from 'src/modules/SpeechRecognition/reducer'

const propTypes = {
  ...gamePropTypes,
  asking: bool,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
  hide: bool,
  loading: bool,
  microphoneOff: func.isRequired,
  selected: bool.isRequired,
  silent: bool,
  transcripts: array.isRequired,
}

const defaultProps = {
  asking: false,
  completed: null,
  hide: false,
  hideAnswer: false,
  loading: false,
  silent: false,
}

export const SpeechRecognitionQAContainer = ({
  actualEntities,
  answerId,
  asking,
  completed,
  correct,
  entitiesById,
  entities,
  loading,
  hide,
  hideAnswer,
  hintTranscripts,
  microphoneOff,
  init,
  questionId,
  retry,
  silent,
  selected,
  started,
  transcripts,
  onStart,
  onStop,
  onKeyPress,
}) => {
  if (!entitiesById) return null
  const initGame = () => init({ asking, entities, hide })
  return (
    <Game
      actualEntities={actualEntities}
      completed={completed}
      init={initGame}
      mark={() => {}}
      retry={retry}
    >
      {questionId && (
      <SpeechRecognitionQABase
        answerId={answerId}
        asking={asking}
        autoPlay={started}
        correct={correct}
        entitiesById={entitiesById}
        hideAnswer={hideAnswer}
        hintTranscripts={hintTranscripts}
        keyPressRecognition={onKeyPress}
        loading={loading}
        microphoneOff={microphoneOff}
        questionId={questionId}
        selected={selected}
        silent={silent}
        startRecognition={onStart}
        stopRecognition={onStop}
        transcripts={transcripts}
      />
      )}
    </Game>
  )
}

const select = ({ speechRecognition }) => speechRecognition
const actions = dispatch => bindActionCreators({
  ...speechRecognitionActions,
  clearTriesIterationIcon,
  init: speechRecognitionActions.initQuestionAndAnswer,
  updateTriesIterationIcon,
}, dispatch)

const composedHOCs = compose(connect(select, actions), withSpeechRecognition)

SpeechRecognitionQAContainer.propTypes = propTypes
SpeechRecognitionQAContainer.defaultProps = defaultProps

export default composedHOCs(SpeechRecognitionQAContainer)
