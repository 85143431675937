import React from 'react'
import { Link } from 'react-router-dom'
import { bool, number, string, oneOfType } from 'prop-types'
import classNames from 'classnames'
import Div from 'src/components/Div'
import Progress from 'src/components/Progress'
import TimesTries from 'src/components/TimesTries'
import usePath from 'src/lib/routes/usePath'
import userReport from 'src/lib/routes/userReport'

const propTypes = {
  assigned_count: number.isRequired,
  completed_count: number.isRequired,
  full_name: string.isRequired,
  grade: oneOfType([string, number]).isRequired,
  rate: number.isRequired,
  slug: string.isRequired,
  tries: oneOfType([string, number]).isRequired,
  zebra: bool.isRequired,
}

const defaultProps = {
}

const Student = ({
  slug: userId,
  completed_count,
  grade,
  full_name: fullName,
  rate,
  assigned_count,
  tries,
  zebra,
}) => (
  <Div
    border-top
    p={2}
    mxn={2}
    className={classNames({ zebra })}
  >
    <Div flex gutter>
      <Div col={3} flex px={1}>
        <Div bold flex align-center>
          <Link to={usePath(userReport)({ tab: 'progress', userId })}>
            {fullName}
          </Link>
        </Div>
      </Div>
      <Div auto flex px={1}>
        <Progress
          actual={completed_count}
          grade={grade}
          showRatio
          secondDimension={rate}
          total={assigned_count}
          colorByGrade
        />
        <TimesTries tries={tries} />
      </Div>
    </Div>
  </Div>
)

Student.propTypes = propTypes
Student.defaultProps = defaultProps
export default Student
