import chunk from 'lodash/chunk'
import React from 'react'
import classnames from 'classnames'
import { array, shape, func } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import ComicImage from 'src/components/ComicImage'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import EntityCard from 'src/components/EntityCard'
import ParagraphCard from 'src/components/ParagraphCard'
import Place from 'src/components/Place'
import Phonics from 'src/modules/Word/Phonics'
import TextFit from 'src/components/TextFit'

const propTypes = {
  availableCardsId: array.isRequired,
  cards: shape().isRequired,
  entitiesById: shape().isRequired,
  lines: shape(),
  linesList: array.isRequired,
  moveCard: func.isRequired,
  places: shape(),
  playPhonics: func,
}

const renderCard = ({ entity, correct, cardId, isSelected }) => (
  <div key={cardId}>
    {
      isSelected
        ? (
          <ParagraphCard
            adaptiveSize
            audio
            correct={correct}
            draggable
            english
            entity={entity}
            id={cardId}
            minWidth={3}
            paddingLeft={0.2}
            paddingRight={0.2}
            silent
            state="gray"
          />
        )
        : (
          <EntityCard
            audio
            correct={correct}
            draggable
            english
            entity={entity}
            fit
            size={2}
            id={cardId}
          />
        )
    }
  </div>

)

const renderPlace = ({ entitiesById, place, cards }) => {
  const { cardId, correct, id } = place
  return (
    <Div inline flex align="end" justify="end" px key={id}>
      {
        cardId
          ? (
            renderCard({
              cardId,
              correct,
              entity: entitiesById[cards[cardId].entityId],
              isSelected: true,
            })
          )
          : (
            <ParagraphCard
              adaptiveSize
              correct={correct}
              minWidth={3}
              paddingLeft={0.2}
              paddingRight={0.2}
              state="gray"
              title="..."
            />
          )
      }
    </Div>
  )
}

const renderWord = ({ words, i, place, entitiesById, cards, word }) => {
  const next = (i + 1) < words.length ? words[i + 1] : null
  const isTitle = next ? next.title : null
  const className = classnames({ pr1: isTitle })
  const title = word.title
    ? <span className={className} key={`${i}-${word.title}`}>{ `${word.title}`}</span>
    : renderPlace({ cards, entitiesById, place })
  return title
}

const renderLine = ({
  cards,
  entitiesById,
  line,
  moveCard,
  places,
  playPhonics,
  spacePerCol,
}, i) => (
  <Div
    col={spacePerCol}
    column
    flex
    justify-start
    key={line.id}
    flex-1
  >
    <Place
      id={line.correct ? null : line.placeId}
      onDrop={moveCard}
      mx={false}
    >
      <ComicImage
        entity={entitiesById[line.entityId]}
        label={i + 1}
        onClick={() => playPhonics({ ...places[line.placeId] }.phonics, i + 1)}
      />
      {
        line.correct ? (
          <Div
            button-hover
            cursor-pointer
            flex
            height={4.8}
            justify="center"
            onClick={() => playPhonics({ ...places[line.placeId] }.phonics, i + 1)}
            rounded
          >
            <AdaptiveCard
              auto
              correct={line.correct}
              className="py2"
              p={2}
              title={line.words[0].title}
            />
          </Div>
        ) : (
          <Div
            bg="white"
            button-hover
            cursor-pointer
            flex
            height={4.3}
            justify="center"
            m
            onClick={() => playPhonics({ ...places[line.placeId] }.phonics, i + 1)}
            rounded
          >
            <TextFit>
              <Div flex center auto justify-center wrap p1>
                {
                  line.words.map((word, i, words) => (
                    renderWord({
                      cards,
                      entitiesById,
                      i,
                      place: places[word.placeId],
                      word,
                      words,
                    })
                  ))
                }
              </Div>
            </TextFit>
          </Div>
        )}
      <Div hide>
        <Phonics
          key={line.placeId}
          keyToPlay={i + 1}
          phonics={{ ...places[line.placeId] }.phonics}
          playId={i + 1}
        />
      </Div>
    </Place>
  </Div>
)

const ComicStripFillInTheBlank = ({
  availableCardsId,
  cards,
  entitiesById,
  lines,
  linesList,
  moveCard,
  places,
  playPhonics,
}) => {
  const numberOfEntityPerRow = Math.round(linesList.length / 2)
  const entityChunks = chunk(linesList, numberOfEntityPerRow)
  const spacePerCol = 12 / numberOfEntityPerRow
  return (
    <Deck>
      <Div flex justify="center" wrap>
        {
          availableCardsId.map(cardId => renderCard({
            cardId,
            entity: entitiesById[cards[cardId].entityId],
            isSelected: false,
          }))}
      </Div>
      <Div column flex-1 flex bg="black" rounded p>
        {
          entityChunks.map((entityChunk, i) => (
            <Div flex flex-1 key={entityChunk[0]} justify="center">
              {
                entityChunk.map((lineId, j) => (
                  renderLine({
                    cards,
                    entitiesById,
                    line: lines[lineId],
                    moveCard,
                    places,
                    playPhonics,
                    spacePerCol,
                  },
                  (i * numberOfEntityPerRow) + j)
                ))
              }
            </Div>
          ))
        }
      </Div>
    </Deck>
  )
}

ComicStripFillInTheBlank.propTypes = propTypes

export default ComicStripFillInTheBlank
