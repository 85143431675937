import React from 'react'
import { Route } from 'react-router-dom'
import ErrorPage from 'src/pages/ErrorPage'

const NoMatch = () => (
  <Route path="*" status={404}>
    <ErrorPage />
  </Route>
)

export default NoMatch
