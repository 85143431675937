import React from 'react'
import { useParams } from 'react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import SchoolDashboard from 'src/pages/SchoolDashboardPage'
import SchoolLayout from 'src/pages/SchoolLayout'
import SchoolOverview from 'src/pages/SchoolOverview'
import { matchPaths as schoolOverviewPaths } from 'src/pages/SchoolOverview/config'

import Users from 'src/pages/Users'
import UserEdit from 'src/pages/User/Edit'
import Years from 'src/pages/Years'
import YearNew from 'src/pages/Year/New'
import YearShow from 'src/pages/Year/Show'
import YearEdit from 'src/pages/Year/Edit'
import Grading from 'src/pages/Grading'
import PreviewModulePage from 'src/pages/preview/Modules/PreviewModulePage'
import ModuleScreenShots from 'src/components/ModuleScreenShots'
import AttendanceReportPage from 'src/pages/AttendanceReportPage'
import SchoolSettings from 'src/pages/SchoolSettings'
import CalendarPage from 'src/pages/CalendarPage'
import Curriculum from 'src/pages/Curriculum'
import HomeworkPage from 'src/pages/HomeworkPage'
import TeacherGuides from 'src/pages/TeacherGuides'
import Storyboards from 'src/pages/Storyboards'
import BpReportPage from 'src/pages/BpReportPage'
import { matchPaths as bpReportMatchPaths } from 'src/pages/BpReportPage/config'

import {
  CurriculumProgressPage,
  AttainmentProgressPage,
  withProgressLayout,
} from 'src/pages/ProgressPage/routes'
import {
  TodayAttendance,
  WeeklyAttendance,
} from 'src/pages/AttendancePage/routes'
import UserPage from 'src/pages/User/Show/UserPage'
import Progress from 'src/pages/User/Show/Progress'
import Skills from 'src/pages/User/Show/Skills'
import Attendance from 'src/pages/User/Show/Attendance'
import UserNew from 'src/pages/User/New/Container'

export const path = '/school'

// eslint-disable-next-line react/prop-types
const SchoolLayoutRoute = ({ component: Component, exact, domain, path, report, userId }) => (
  <Route path={path} exact={exact}>
    <SchoolLayout>
      <Component path={path} report={report} userId={userId} domain={domain} />
    </SchoolLayout>
  </Route>
)

// eslint-disable-next-line react/display-name
const WithUserPage = Component => ({ domain, path, report, userId }) => {
  // eslint-disable-next-line
  const { userId: paramUserId } = useParams()
  return (
    <UserPage userId={paramUserId || userId} report={report}>
      <Component path={path} report={report} userId={userId} domain={domain} />
    </UserPage>
  )
}

const SchoolRoute = () => (
  <Switch>
    <SchoolLayoutRoute exact path="/school" component={SchoolDashboard} />
    <SchoolLayoutRoute path={schoolOverviewPaths} component={SchoolOverview} />
    <SchoolLayoutRoute path="/school/users/:scope?/:filter?" component={Users} domain="school" />
    <Route
      exact
      path="/school/user"
      render={() => <Redirect from="/school/user" to="/school/users" />}
    />
    <SchoolLayoutRoute
      path="/school/user/new"
      component={UserNew}
    />
    <SchoolLayoutRoute
      path="/school/user/:userId/edit"
      component={UserEdit}
    />
    <SchoolLayoutRoute
      path="/school/user/:userId/:userTab(progress)/:subjectId?/:strandSlug?/:standardSlug?"
      component={WithUserPage(Progress)}
    />
    <SchoolLayoutRoute
      path="/school/user/:userId/:userTab(skills)/:subjectId?"
      component={WithUserPage(Skills)}
    />
    <SchoolLayoutRoute
      path="/school/user/:userId/:userTab(attendance)/:subjectId?"
      component={WithUserPage(Attendance)}
    />
    <SchoolLayoutRoute
      path="/school/user/:userId"
      component={WithUserPage(Progress)}
    />
    <SchoolLayoutRoute
      exact
      path="/school/users/:scope?/:filter?"
      domain="school"
      component={Users}
    />

    <SchoolLayoutRoute
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(curriculum)/:year(year)?/:yearId?/:subject(subject)?/:subjectId?/:strand(strand)?/:strandSlug?/:standard(standard)?/:standardSlug?"
      component={CurriculumProgressPage}
    />
    <SchoolLayoutRoute
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(attainment)/:year(year)?/:yearId?/:subject(subject)?/:subjectId?/:unit(unit)?/:unitSlug?/:limit(limit)?/:limit?"
      component={AttainmentProgressPage}
    />
    <SchoolLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/progress/:subjectId?"
      component={withProgressLayout(WithUserPage(Progress))}
    />
    <SchoolLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(progress)/:subject(subject)?/:subjectId?/:strand(strand)?/:strandSlug?/:standard(standard)?/:standardSlug?"
      component={withProgressLayout(WithUserPage(Progress))}
    />
    <SchoolLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(skills)/:subject(subject)?/:subjectId?"
      component={withProgressLayout(WithUserPage(Skills))}
    />
    <SchoolLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/school/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(attendance)/:subject(subject)?/:subjectId?"
      component={withProgressLayout(WithUserPage(Attendance))}
    />
    <SchoolLayoutRoute
      exact
      path={bpReportMatchPaths}
      component={BpReportPage}
    />
    <SchoolLayoutRoute
      exact
      path="/school/reports/:academicYearId/:academicTermId/progress/:subjectId?"
      component={CurriculumProgressPage}
    />
    <SchoolLayoutRoute
      path="/school/reports/progress"
      component={CurriculumProgressPage}
    />
    <SchoolLayoutRoute path="/school/grading" component={Grading} />
    <SchoolLayoutRoute path="/school/preview/modules/:moduleId" component={PreviewModulePage} />
    <SchoolLayoutRoute path="/school/preview/modules" component={ModuleScreenShots} />
    <SchoolLayoutRoute
      path="/school/reports/:academicYearId/:academicTermId/attendance/:yearId?"
      component={AttendanceReportPage}
    />
    <SchoolLayoutRoute
      path="/:domain(school)/reports/attendance"
      component={AttendanceReportPage}
    />
    <SchoolLayoutRoute exact path="/:domain(school)/attendance" component={TodayAttendance} />
    <SchoolLayoutRoute
      path="/:domain(school)/attendance/:tab(today)/:yearId?"
      component={TodayAttendance}
    />
    <SchoolLayoutRoute
      path="/:domain(school)/attendance/:tab(weekly)/:year?/:week?"
      component={WeeklyAttendance}
    />
    <SchoolLayoutRoute path="/school/settings/:sectionId?" component={SchoolSettings} />
    <SchoolLayoutRoute
      path={[
        '/:domain(school)/calendar/:year(year)/:yearId/:date?',
        '/:domain(school)/calendar/:date?',
      ]}
      component={CalendarPage}
    />
    <SchoolLayoutRoute
      path="/school/curriculum/:gradeOrSubjectFirst?/:firstId?/:gradeOrSubjectSecond?/:secondId?"
      component={Curriculum}
    />
    <SchoolLayoutRoute path="/school/reports/homework" component={HomeworkPage} />
    <SchoolLayoutRoute
      path="/school/reports/:academicYearId/:academicTermId/homework/:yearId?"
      component={HomeworkPage}
    />
    <SchoolLayoutRoute
      path="/school/guides/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={TeacherGuides}
    />
    <SchoolLayoutRoute
      // eslint-disable-next-line max-len
      path="/school/guide/lesson/:lessonSlug/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={TeacherGuides}
    />
    <SchoolLayoutRoute
      path="/school/storyboards/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={Storyboards}
    />
    <SchoolLayoutRoute
      // eslint-disable-next-line max-len
      path="/school/storyboard/lesson/:lessonSlug/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={Storyboards}
    />
    <SchoolLayoutRoute path="/school/years" component={Years} />
    <SchoolLayoutRoute path="/school/year/new" component={YearNew} />
    <SchoolLayoutRoute path="/school/year/:yearId/edit" component={YearEdit} />
    <SchoolLayoutRoute path="/school/year/:yearId" component={YearShow} />
    <Redirect to="/lessons" />
  </Switch>
)

export default SchoolRoute
