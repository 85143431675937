import React from 'react'
import { func, object } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as allActions from 'src/modules/OrderingSentences/reducer'
import OrderingSentences from 'src/modules/OrderingSentences/OrderingSentences'

const propTypes = {
  ...gamePropTypes,
  moveCard: func.isRequired,
  places: object.isRequired,
}

class OrderingSentencesContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      mark,
      retry,
      completed,
      places,
      moveCard,
      entitiesById,
      placesLength,
    } = this.props
    return (
      <Game {
        ...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <OrderingSentences {...{ entitiesById, moveCard, places, placesLength }} />
      </Game>
    )
  }
}

OrderingSentencesContainer.propTypes = propTypes
OrderingSentencesContainer.demoData = {
  entities: [
    'mother',
    'father',
    'family',
    'elder-sister',
  ],
}

const selector = state => state.orderingSentences
const actions = dispatch => bindActionCreators(allActions, dispatch)
export default connect(selector, actions)(OrderingSentencesContainer)
