import React from 'react'
import { array, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import ToneMarkerContainer from 'src/modules/ToneMarker/ToneMarkerContainer'
import * as gameActions from 'src/modules/ToneMarker/reducer'

const propTypes = {
  entities: array.isRequired,
  initSentence: func.isRequired,
}

const SentenceMarkerContainer = props => (
  <ToneMarkerContainer init={() => props.initSentence({ entities: props.entities })} {...props} />
)

SentenceMarkerContainer.demoData = {
  entities: [
    'phrase-how-often-do-you-sleep',
    'phrase-how-often-do-you-exercise',
    'sleep',
    'phrase-i-exercise-more-than-five-hours-a-week',
    'phrase-i-exercise-less-than-five-hours-a-week',
    'more',
  ],
}

const select = state => state.toneMarker
const actions = dispatch => bindActionCreators(gameActions, dispatch)

SentenceMarkerContainer.propTypes = propTypes

export { SentenceMarkerContainer }
export default connect(select, actions)(SentenceMarkerContainer)
