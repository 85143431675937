import React from 'react'
import classNames from 'classnames'
import { any, string } from 'prop-types'

const propTypes = {
  children: any.isRequired,
  color: string,
}

const defaultProps = {
  color: 'blue',
}

const Badge = ({ color, children }) => (
  <span className={classNames({
    'align-top': true,
    'inline-block': true,
    [`bg-${color}`]: color,
    ml: true,
    px: true,
    rounded: true,
    small: true,
    white: true,
  })}
  >
    {children}
  </span>
)

Badge.propTypes = propTypes
Badge.defaultProps = defaultProps

export default Badge
