/* eslint max-len: 0 */

import React from 'react'

export default () => (
  <svg width="100%" height="100%" viewBox="0 0 800 60" preserveAspectRatio="none">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g id="grandparents" stroke="#979797" strokeWidth="8">
        <path vectorEffect="non-scaling-stroke" d="M100,5 L100,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M300,5 L300,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M100,30 L300,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M200,35 L200,55" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M500,5 L500,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M700,5 L700,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M500,30 L700,30" id="Line" />
        <path vectorEffect="non-scaling-stroke" d="M600,35 L600,55" id="Line" />
      </g>
    </g>
  </svg>
)
