import React from 'react'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'
import { useHistory } from 'react-router'

import parseErrors from 'src/lib/form/parseErrors'
import StudentCreateForm from 'src/pages/Students/StudentCreate/StudentCreateForm'
import studentCreateMutation from 'src/pages/Students/StudentCreate/studentCreateMutation.gql'
import { successMessage } from 'src/lib/toaster'

const showSuccessMessage = () => {
  const title = t('save.success')
  successMessage({ title })
}

const StudentCreateFormContainer = () => {
  const history = useHistory()
  return (
    <Mutation mutation={studentCreateMutation}>
      {(studentCreate, { error }) => (
        <StudentCreateForm
          errors={parseErrors(error)}
          onSubmit={(values, { setSubmitting, setErrors }) =>
            studentCreate({ variables: { input: values } })
              .then(() => {
                showSuccessMessage()
                history.go()
              })
              .catch((errors) => {
                setErrors(parseErrors(errors))
              })
              .finally(() => {
                setSubmitting(false)
              })
          }
        />
      )}
    </Mutation>
  )
}

export default StudentCreateFormContainer
