import React from 'react'
import { bindActionCreators } from 'redux'
import { array, func } from 'prop-types'
import { connect } from 'react-redux'
import * as cardActions from 'src/modules/BarChart/reducer'
import BarChartContainer from 'src/modules/BarChart/BarChartContainer'

const propTypes = {
  entities: array.isRequired,
  initWordCount: func.isRequired,
}

const BarChartWordCountContainer = props => (
  <BarChartContainer init={() => props.initWordCount({ entities: props.entities })} {...props} />
)

const select = ({ barChart }) => barChart
const actions = dispatch => bindActionCreators(cardActions, dispatch)

BarChartWordCountContainer.propTypes = propTypes
BarChartWordCountContainer.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'is',
    'am',
    'are',
  ],
}

export { BarChartWordCountContainer }
export default connect(select, actions)(BarChartWordCountContainer)
