import compact from 'lodash/compact'
import ahoy from 'ahoy.js'
import { localStorage } from 'src/lib/window'
import ReactRouterGA from 'src/components/analytics/ReactRouterGA'
import GA from 'src/components/analytics/GA'

const { protocol, hostname, port: locationPort } = document.location
const port = locationPort === '3333' ? '3000' : locationPort
const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`

let ahoyUserData = {}

ahoy.configure({
  eventsUrl: `${origin}/ahoy/events`,
  visitsUrl: `${origin}/ahoy/visits`,
})

const ensureSession = () => {
  const token = localStorage.getItem('token')
  if (!token) return false

  ahoy.configure({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
    withCredentials: true,
  })
  return true
}

const trackEvent = ({ action, category, ...rest }) => {
  if (!ensureSession()) return

  GA.event({ action, category, ...rest })
  ahoy.track(compact([category, action]).join('.'), { ...ahoyUserData, ...rest })
}

const setPage = (page) => {
  if (!ensureSession()) return

  GA.set({ page })
  GA.pageview(page)
  ahoy.configure({ page })
  ahoy.trackView(ahoyUserData)
}

const setUser = (user) => {
  const subdomain = window.location.hostname.split('.').slice(-3)[0]

  if (user) {
    const { id, slug, roleId, fullNameEn } = user
    const userId = [subdomain, roleId, slug].filter(x => x).join('_')
    GA.set({
      dimension1: subdomain,
      dimension2: roleId,
      dimension3: fullNameEn,
      dimension4: id,
      userId,
    })
    ahoyUserData = {
      subdomain,
      user: userId,
      userId: id,
      userRole: roleId,
      userFullName: fullNameEn,
    }
  } else {
    GA.set({
      dimension2: undefined,
      dimension3: undefined,
      userId: undefined,
    })
    ahoyUserData = { subdomain }
  }
}

export {
  ReactRouterGA,
  setPage,
  setUser,
}

export default trackEvent
