import React from 'react'
import { func, bool } from 'prop-types'
import { t } from 'i18next'
import Tabs from 'src/components/Tabs'
import Tab from 'src/components/Tab'

const propTypes = {
  marked: bool,
  path: func.isRequired,
}

const defaultProps = {
  marked: false,
}

const AssessmentMenu = ({ path, marked }) => (
  <Tabs mb1>
    <Tab
      path={path()}
      selected={!marked}
      className="qa-to-mark-link"
    >
      {t('assessment.toMark')}
    </Tab>
    <Tab
      path={path({ marked: true })}
      selected={marked}
      className="qa-marked-link"
    >
      {t('assessment.marked')}
    </Tab>
  </Tabs>
)

AssessmentMenu.propTypes = propTypes
AssessmentMenu.defaultProps = defaultProps
export default AssessmentMenu
