import React, { Component } from 'react'
import { bool, shape, func, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ListenAndSelect/reducer'
import {
  playGunShotSound as playGunShotSoundAction,
} from 'src/lib/sounds/reducer'
import { getRandomInt } from 'src/lib/getRandomNumber'
import ListenAndShoot from 'src/modules/ListenAndShoot/ListenAndShoot'
import river from 'src/modules/ListenAndShoot/images/river.svg'
import mole from 'src/modules/ListenAndShoot/images/mole.svg'
import fence from 'src/modules/ListenAndShoot/images/fence.svg'
import backyard from 'src/modules/ListenAndShoot/images/backyard.svg'
import space from 'src/modules/ListenAndShoot/images/space.svg'

const THEMES = ['space', 'river', 'mole', 'fence', 'backyard']
const BACKGROUND = [space, river, mole, fence, backyard]


const propTypes = {
  ...gamePropTypes,
  cols: number.isRequired,
  cursive: bool,
  lines: shape().isRequired,
  mixFont: bool,
  onClickAndMark: func.isRequired,
  playGunShotSound: func.isRequired,
  rows: number.isRequired,
  text: bool,
}

const defaultProps = {
  cols: 4,
  cursive: null,
  lines: null,
  mixFont: null,
  rows: 1,
  text: null,
}

class ListenAndShootContainer extends Component {
  constructor(props) {
    super(props)
    const theme = getRandomInt(0, 4)
    this.state = {
      background: BACKGROUND[theme],
      theme: THEMES[theme],
    }
  }

  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      completed,
      cursive,
      lines,
      playGunShotSound,
      mark,
      mixFont,
      onClickAndMark,
      retry,
      started,
      text,
      unitTest,
    } = this.props
    const { background, theme } = this.state
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ListenAndShoot
          autoPlay={started}
          background={background}
          cursive={cursive}
          lines={lines}
          mixFont={mixFont}
          playGunShotSound={playGunShotSound}
          onClick={onClickAndMark}
          text={text}
          theme={theme}
          unitTest={unitTest}
        />
      </Game>
    )
  }
}

ListenAndShootContainer.defaultProps = defaultProps
ListenAndShootContainer.propTypes = propTypes
ListenAndShootContainer.demoData = {
  cols: 4,
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
    'mother',
  ],
  rows: 1,
}

const select = state => state.listenAndSelect
const actions = dispatch => bindActionCreators({
  ...gameActions,
  playGunShotSound: playGunShotSoundAction,
}, dispatch)

export { ListenAndShootContainer }
export default connect(select, actions)(ListenAndShootContainer)
