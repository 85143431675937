import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import { t } from 'i18next'
import find from 'lodash/find'

import Div from 'src/components/Div'
import Divider from 'src/components/Divider'
import Icon from 'src/components/Icon'
import SelectStyle from 'src/components/SelectStyle'

const propTypes = {
  onChange: func.isRequired,
  subjectId: string.isRequired,
  units: arrayOf(shape({
    id: string.isRequired,
    identifier: string.isRequired,
    slug: string.isRequired,
    title: string.isRequired,
  })).isRequired,
  unitSlug: string,
}

const defaultProps = {
  unitSlug: undefined,
}

const UnitList = ({
  subjectId,
  onChange,
  unitSlug,
  units,
}) => {
  const unit = find(units, { slug: unitSlug })
  return (
    <div>
      <Div flex px={2} py={1} mtn={1} mbn={1} align="center" noPrint relative>
        <div className="h4 bold flex-auto">
          <Icon color="blue" icon="clipboard" className="muted" before />
          {
            unit ?
              <span>{unit.title} {unit.identifier}</span>
              :
              t('all.units')
          }
        </div>
        <Div auto flex px={2}>
          <SelectStyle className="qa-unit-list">
            <select
              className="flex-1 mb2"
              name="qa-unit-list"
              value={unitSlug}
              onChange={ev => onChange({ subjectId, unitSlug: ev.target.value })}
            >
              <option key="all" value="">
                {t('all.units')}
              </option>
              {
                units.map(({
                  id,
                  identifier,
                  slug,
                  title,
                }) => (
                  <option key={id} value={slug}>
                    {title} {identifier}
                  </option>
                ))}
            </select>
          </SelectStyle>
        </Div>
      </Div>
      <Divider className="muted mt1 mb0 noPrint" />
    </div>
  )
}

UnitList.defaultProps = defaultProps
UnitList.propTypes = propTypes

export default UnitList
