import React from 'react'
import gqlLoader from 'src/lib/gqlLoader'
import allGradesQuery from 'src/pages/Registration/allGrades.gql'
import Select from 'src/components/forms/Select'

const SelectGrade = ({ sections, sectionSlug = 'primary', ...props }) => {
  if (!sections) return null
  const section = sections.find((s) => s.slug === sectionSlug)
  if (!section) return null
  return (
    <Select options={section.grades} labelToken="school.grade" {...props} />
  )
}

export default gqlLoader(allGradesQuery)(SelectGrade)
