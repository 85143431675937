import { stringify } from 'query-string'
import compact from 'lodash/compact'

const buildPath = (path, query = {}, options = {}) => {
  const { router, absolute } = options
  const pathname = (absolute ? '/' : '') + compact([].concat(path)).join('/')
  const search = stringify(query)
  return router ? { pathname, search } : compact([pathname, search]).join('?')
}

export default buildPath
