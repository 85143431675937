import React from 'react'
import { array, bool, func, object, oneOfType, shape, string } from 'prop-types'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import ColorScheme, { defaultColorScheme } from 'src/contexts/ColorScheme'
import DemoSwitcher from 'src/components/DemoSwitcher'
import Div from 'src/components/Div'
import ErrorMessage from 'src/pages/Login/ErrorMessage'
import Icon from 'src/components/Icon'
import Input from 'src/components/forms/Input'
import Logo from 'src/components/Logo'
import OAuthButton from 'src/components/OAuthButton'
import SchoolSelector from 'src/pages/Login/SchoolSelector'
import SchoolTitle from 'src/components/SchoolTitle'
import { resetPasswordPath } from 'src/lib/routes'

const propTypes = {
  authEmail: string,
  displayLogin: bool,
  errorMessage: string,
  errors: oneOfType([array, object]),
  login: string,
  loginState: shape({
    app: bool,
    demo: bool,
    showLoginForm: bool,
    showSelectSchool: bool,
    showSocialButtons: bool,
    subdomain: string,
  }).isRequired,
  onSubmit: func.isRequired,
  parent: bool,
}

const defaultProps = {
  authEmail: undefined,
  errorMessage: undefined,
  errors: undefined,
  login: '',
  parent: false,
}

const initialValues = {
  displayLogin: '',
  login: '',
  password: '',
}

const validate = (values) => {
  const errors = {}

  if (!values.login) {
    errors.login = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const invalidEmailMessage = (authEmail) => `Email ${authEmail} not found.`

const Login = ({
  authEmail,
  displayLogin,
  errorMessage,
  login,
  loginState: {
    app,
    demo,
    subdomain,
    showSocialButtons,
    showLoginForm,
    showSelectSchool,
  },
  onSubmit,
  parent,
}) => (
  <div
    className="flex flex-column bg-blue sm-bg-none white"
    style={{ height: 'calc(100vh - 40px)' }}
  >
    <ColorScheme.Provider
      value={{ ...defaultColorScheme, errorColor: 'yellow' }}
    >
      <div className="mb4 sm-show" />
      <Div
        bg-blue
        mt2
        mx-auto
        px3
        py3
        rounded
        sm-shadow
        white
        style={{ maxWidth: '20rem' }}
      >
        <Logo />
        <Formik
          validate={validate}
          initialValues={{ ...initialValues, displayLogin, login }}
          onSubmit={onSubmit}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <Div my={2}>
                {app ? (
                  <div>
                    <div className="center">{t('demo.login1')}</div>
                    <Div my={1}>
                      <SchoolSelector />
                    </Div>
                    <div className="center">{t('demo.login2')}</div>
                    <Div my={1}>
                      <a
                        className="col-12 btn p2 button button-hover rounded
                          bg-green white white shadow center"
                        href="https://demo.learn21.school/lessons"
                      >
                        {t('demo.button')}
                        <Icon icon="arrow-right" after />
                      </a>
                    </Div>
                  </div>
                ) : (
                  <Div center mtn={2}>
                    <SchoolTitle />
                  </Div>
                )}
              </Div>
              {showLoginForm && (
                <Div my={2}>
                  <Div mb={2}>
                    <Input
                      name="login"
                      placeholder={t('login.login')}
                      className="col-12"
                      errors={errors.login}
                      required
                      readOnly={parent}
                      style={displayLogin && { visibility: 'hidden' }}
                    />
                    {displayLogin && (
                      <Input
                        readOnly
                        name="displayLogin"
                        placeholder={t('login.login')}
                        className="col-12"
                        errors={errors.login}
                        required
                      />
                    )}
                  </Div>
                  <Div mb={2}>
                    <Input
                      name="password"
                      placeholder={t('login.password')}
                      className="col-12"
                      errors={errors.password}
                      type="password"
                      autoComplete="off"
                      required
                    />
                  </Div>
                  <ErrorMessage {...{ errorMessage, values: { subdomain } }} />
                  {authEmail && (
                    <ErrorMessage
                      errorMessage={invalidEmailMessage(authEmail)}
                    />
                  )}
                  <Div my={1}>
                    <button
                      type="submit"
                      className="col-12 btn p2 button-hover rounded bg-green white white shadow"
                      disabled={isSubmitting}
                    >
                      {t('login.button')}
                      <Icon icon="arrow-right" after />
                    </button>
                  </Div>
                  <Div my={1} center>
                    <Link className="white underline" to={resetPasswordPath()}>
                      <Icon icon="question-circle" before />
                      {t('login.forgot')}
                    </Link>
                  </Div>
                </Div>
              )}
              <Div my={2}>
                <DemoSwitcher showSelect={demo} />
              </Div>
              {showSelectSchool && (
                <Div center my2 bold>
                  <a className="white" href="https://app.learn21.school/login">
                    <Icon before icon="arrow-left" />
                    {t('login:selectTheSchool')}
                  </a>
                </Div>
              )}
            </Form>
          )}
        </Formik>
      </Div>
    </ColorScheme.Provider>
  </div>
)

Login.propTypes = propTypes
Login.defaultProps = defaultProps
export default Login
