import React from 'react'
import { array, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  entities: array.isRequired,
  initQuestions: func.isRequired,
}

const MultipleChoiceFixedQuestionsContainer = props => (
  <MultipleChoiceCore
    init={() => props.initQuestions({ entities: props.entities })}
    {...props}
  />
)

MultipleChoiceFixedQuestionsContainer.demoData = {
  entities: [
    'phrase-choose-the-correct-spelling',
    'door',
    'dour',
    'doorr',
    'phrase-what-is-it',
    'carrot',
    'banana',
    'mango',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

MultipleChoiceFixedQuestionsContainer.propTypes = propTypes

export { MultipleChoiceFixedQuestionsContainer }
export default connect(select, actions)(MultipleChoiceFixedQuestionsContainer)
