import React, { Component } from 'react'
import ResizeObserver from 'react-resize-observer'
import { arrayOf, element } from 'prop-types'

const propTypes = {
  children: arrayOf(element).isRequired,
}

class StickyTable extends Component {
  state = {
    height: 0,
    width: 'auto',
    widths: [],
  }

  setSize = () => {
    const width = this.table.offsetWidth
    const height = this.table.tHead.offsetHeight + 1
    const thead = this.table.tHead
    const lastTr = Array.from(thead.children)[thead.children.length - 1]
    const widths = Array.from(lastTr.children).map(th => th.offsetWidth)
    this.setState({ height, width, widths })
  }

  render() {
    const { children, children: [Header] } = this.props
    const { height, width, widths } = this.state
    return (
      <div className="overflow-x-auto">
        <table
          className="print-hide sticky border-bottom top-0 zebra bordered z1"
          style={{
            marginBottom: -height,
            width,
          }}
        >
          { React.cloneElement(Header, { widths }) }
        </table>
        <table className="zebra bordered" ref={(table) => { this.table = table }}>
          { children }
        </table>
        <ResizeObserver onResize={this.setSize} />
      </div>
    )
  }
}

StickyTable.propTypes = propTypes

export default StickyTable
