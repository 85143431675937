import isEqual from 'lodash/isEqual'

import buildSpeechRecognition from 'src/modules/SpeechRecognition/lib/buildSpeechRecognition'
import { func, bool } from 'prop-types'
import React, { Component } from 'react'

const propTypes = {
  completed: bool,
  correct: bool,
  init: func.isRequired,
  loadingOff: func.isRequired,
  loadingOn: func.isRequired,
  mark: func.isRequired,
  microphoneOff: func.isRequired,
  microphoneOn: func.isRequired,
  selected: bool,
}

const defaultProps = {
  triesIterations: [undefined, undefined, undefined],
  updateTriesIterationIcon: undefined,
  clearTriesIterationIcon: undefined,
}

function withSpeechRecognition(WrappedComponent, data) {
  class WithSpeechRecognition extends Component {
    constructor(props) {
      super(props)
      props.init({ ...props, ...data })
      const { triesIterations, updateTriesIterationIcon } = props
      this.recognition = buildSpeechRecognition({
        onResult: this.handleResult,
      })
      if (updateTriesIterationIcon) updateTriesIterationIcon({ triesIterations })
    }

    componentDidUpdate(prevProps) {
      if (isEqual(this.props, prevProps)) return
      const {
        triesIterations,
        updateTriesIterationIcon,
      } = this.props
      if (triesIterations !== prevProps.triesIterations && updateTriesIterationIcon) {
        updateTriesIterationIcon({ triesIterations })
      }
    }

    componentWillUnmount() {
      const { clearTriesIterationIcon } = this.props
      if (clearTriesIterationIcon) clearTriesIterationIcon()
    }

    handleResult = (transcripts) => {
      this.props.processTranscripts(transcripts)
    }

    handleStart = () => {
      const { completed, correct, microphoneOn, selected } = this.props
      if (completed !== null) return
      if (correct !== null) return
      if (!selected) {
        if (navigator.vibrate) navigator.vibrate(500)
        microphoneOn()
      }
      this.recognition.start()
    }

    handleStop = () => {
      const {
        microphoneOff, mark,
      } = this.props
      microphoneOff()
      mark()
      this.recognition.stop()
    }

    handleKeyPress = ({ isKeyUp }) => (isKeyUp ? this.handleStop() : this.handleStart())

    render() {
      return (
        <WrappedComponent
          onStart={this.handleStart}
          onStop={this.handleStop}
          onKeyPress={this.handleKeyPress}
          {...this.props}
          {...data}
        />
      )
    }
  }

  WithSpeechRecognition.propTypes = propTypes
  WithSpeechRecognition.defaultProps = defaultProps
  return WithSpeechRecognition
}

export default withSpeechRecognition
