import buildPath from 'src/lib/routes/buildPath'
import pathWithPageParams from 'src/lib/routes/pathWithPageParams'
import reportPath from 'src/lib/routes/reportPath'

const tabPath = ({ tab, subjectId, strandSlug, standardSlug }) => {
  switch (tab) {
    case 'progress': return buildPath([tab, subjectId, strandSlug, standardSlug])
    case 'skills': return buildPath([tab, subjectId])
    case 'attendance': return buildPath([tab, subjectId])
    default: return buildPath(['progress', subjectId])
  }
}

const userReportPath = pathWithPageParams((params) => {
  const { userTab, userId, yearId } = params
  return `/${buildPath([
    reportPath(params),
    'progress/student',
    `${yearId ? `year/${yearId}` : ''}`,
    'user',
    userId,
    tabPath({ ...params, tab: userTab }),
  ])}`
})

export default userReportPath
