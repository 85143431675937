import React from 'react'
import { bool, shape } from 'prop-types'
import { graphql } from '@apollo/react-hoc'

import userShape from 'src/shapes/user'
import { setUser } from 'src/components/analytics'
import query from 'src/pages/CurrentUser/CurrentUser.gql'

const propTypes = {
  data: shape({
    loading: bool.isRequired,
    user: shape(userShape),
  }),
  includingGuest: bool,
}

const defaultProps = {
  data: {
    user: null,
  },
}

const CurrentUser = ({ data: { loading, user }, children, includingGuest }) => {
  if (!loading && user) {
    setUser(user)
    if (typeof children === 'function') {
      return children({ user })
    }
    return React.cloneElement(children, { user })
  }
  setUser(null)
  return includingGuest ? React.cloneElement(children, { user }) : null
}

CurrentUser.propTypes = propTypes
CurrentUser.defaultProps = defaultProps
export default graphql(query)(CurrentUser)
