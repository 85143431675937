import MultipleChoice from 'src/modules/MultipleChoice/MultipleChoiceContainer'
import MultipleChoiceFixed from 'src/modules/MultipleChoice/MultipleChoiceFixedContainer'
import MultipleChoiceFixedMuted from 'src/modules/MultipleChoice/MultipleChoiceFixedMutedContainer'
import MultipleChoiceFixedQuestions from 'src/modules/MultipleChoice/MultipleChoiceFixedQuestionsContainer'
import {
  MultipleChoiceImageContainer as MultipleChoiceImage,
} from 'src/modules/MultipleChoice/MultipleChoiceImageContainer'
import MultipleChoiceThai from 'src/modules/MultipleChoice/MultipleChoiceThaiContainer'
import MultipleChoiceParagraph3, {
  MultipleChoiceParagraph2Container as MultipleChoiceParagraph2,
  MultipleChoiceParagraph4Container as MultipleChoiceParagraph4,
} from 'src/modules/MultipleChoice/MultipleChoiceParagraphContainer'
import MultipleChoiceAudio3, {
  MultipleChoiceAudio2Container as MultipleChoiceAudio2,
  MultipleChoiceAudio4Container as MultipleChoiceAudio4,
} from 'src/modules/MultipleChoice/MultipleChoiceAudioContainer'
import reducer from 'src/modules/MultipleChoice/reducer'

export {
  MultipleChoice,
  MultipleChoiceFixed,
  MultipleChoiceFixedMuted,
  MultipleChoiceFixedQuestions,
  MultipleChoiceImage,
  MultipleChoiceThai,
  MultipleChoiceParagraph2,
  MultipleChoiceParagraph3,
  MultipleChoiceParagraph4,
  MultipleChoiceAudio2,
  MultipleChoiceAudio3,
  MultipleChoiceAudio4,
  reducer,
}
