import React from 'react'
import isNil from 'lodash/isNil'
import { arrayOf, string, shape, func } from 'prop-types'
import Deck from 'src/components/Layout/Deck'
import WithPlayButton from 'src/components/WithPlayButton'
import EntityBubble from 'src/components/EntityBubble'
import entityShape from 'src/shapes/entity'
import useSequence from 'src/lib/useSequence'
import Div from 'src/components/Div'

const propTypes = {
  answers: arrayOf(shape(entityShape).isRequired).isRequired,
  correct: string,
  onSelectAnswer: func.isRequired,
  phonics: arrayOf(shape(entityShape).isRequired).isRequired,
  selected: string,
}

const defaultProps = {
  selected: null,
  correct: null,
}

const BlendingSoundsScene = ({ answers, onSelectAnswer, phonics, selected, correct }) => {
  const [step, nextStep] = useSequence(phonics)
  return (
    <Deck>
      <WithPlayButton
        key_={step}
        autoPlay={step > 0}
        entity={phonics[step]}
        playNext={nextStep}
        playButtonFirst
      >
        <Div flex-1 flex column justify="between">
          {answers.map((answer, i) => {
            const isSelected = selected === answer.id
            return (
              <Div flex-1 key={answer.id}>
                <EntityBubble
                  className="qa-answer"
                  selected={isSelected}
                  correct={(isSelected && !isNil(correct)) ? correct === answer.id : null}
                  entity={answer}
                  english
                  label={i + 1}
                  onKeyPress={() => onSelectAnswer(answer.id)}
                  onClick={() => onSelectAnswer(answer.id)}
                />
              </Div>
            )
          })}
        </Div>
      </WithPlayButton>
    </Deck>
  )
}

BlendingSoundsScene.propTypes = propTypes
BlendingSoundsScene.defaultProps = defaultProps

export default BlendingSoundsScene
