import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useParams } from 'react-router'
import TeacherLayout from 'src/pages/TeacherLayout'
import DashboardPage from 'src/pages/DashboardPage'
import Users from 'src/pages/Users'
import UserEdit from 'src/pages/User/Edit'
import AssessmentPage from 'src/pages/TeacherLayout/AssessmentPage'
import {
  withProgressLayout,
  CurriculumProgressPage,
  AttainmentProgressPage,
} from 'src/pages/ProgressPage/routes'
import Grading from 'src/pages/Grading'
import PreviewModulePage from 'src/pages/preview/Modules/PreviewModulePage'
import ModuleScreenShots from 'src/components/ModuleScreenShots'
import {
  TodayAttendance,
  WeeklyAttendance,
} from 'src/pages/AttendancePage/routes'
import AttendanceReportPage from 'src/pages/AttendanceReportPage'
import Curriculum from 'src/pages/Curriculum'
import HomeworkPage from 'src/pages/HomeworkPage'
import TeacherGuides from 'src/pages/TeacherGuides'
import RedirectToDefaultYear from 'src/pages/TeacherLayout/AssessmentPage/RedirectToDefaultYear'
import { assessmentHomeworkPath } from 'src/lib/routes'
import Progress from 'src/pages/User/Show/Progress'
import Skills from 'src/pages/User/Show/Skills'
import Attendance from 'src/pages/User/Show/Attendance'
import UserPage from 'src/pages/User/Show/UserPage'
import CalendarPage from 'src/pages/CalendarPage'

export const path = '/teacher'

const TeacherLayoutRoute = ({ component: Component, exact, path, report, userId }) => (
  <Route path={path} exact={exact}>
    <TeacherLayout>
      <Component path={path} report={report} userId={userId} />
    </TeacherLayout>
  </Route>
)

const WithUserPage = Component => ({ path, report, userId }) => {
  // eslint-disable-next-line
  const { userId: paramUserId } = useParams()
  return (
    <UserPage userId={paramUserId || userId} report={report}>
      <Component path={path} report={report} userId={userId} />
    </UserPage>
  )
}

const TeacherRoute = () => (
  <Switch>
    <TeacherLayoutRoute exact path="/teacher" component={DashboardPage} />
    <TeacherLayoutRoute path="/teacher/users/:scope?/:filter?" component={Users} />
    <TeacherLayoutRoute exact path="/teacher" component={DashboardPage} />
    <Route
      exact
      path="/teacher/user"
      render={() => <Redirect from="/teacher/user" to="/teacher/users" />}
    />
    <TeacherLayoutRoute
      path="/teacher/user/:userId/edit"
      component={UserEdit}
    />
    <TeacherLayoutRoute
      path="/teacher/user/:userId/:userTab(progress)/:subjectId?/:strandSlug?/:standardSlug?"
      component={WithUserPage(Progress)}
    />
    <TeacherLayoutRoute
      path="/teacher/user/:userId/:userTab(skills)/:subjectId?"
      component={WithUserPage(Skills)}
    />
    <TeacherLayoutRoute
      path="/teacher/user/:userId/:userTab(attendance)/:subjectId?"
      component={WithUserPage(Attendance)}
    />
    <TeacherLayoutRoute
      path="/teacher/user/:userId"
      component={WithUserPage(Progress)}
    />
    <TeacherLayoutRoute
      path="/teacher/users/:scope?/:filter?"
      component={Users}
    />

    <TeacherLayoutRoute
      // eslint-disable-next-line max-len
      path="/teacher/assessment/:marked(marked)?/year/:yearId/:user(user)?/:userId?/:homework(homework)?/:chapterId?"
      component={AssessmentPage}
    />
    <TeacherLayoutRoute
      // eslint-disable-next-line max-len
      path="/teacher/assessment/:marked(marked)?/:user(user)?/:userId?/:homework(homework)?/:chapterId?"
      component={
       () => (
         <RedirectToDefaultYear
           path={({ params, yearId }) => assessmentHomeworkPath({ ...params, yearId })}
         />
       )
      }
    />
    <TeacherLayoutRoute
      path={[
        '/:domain(teacher)/calendar/:year(year)/:yearId/:date?',
        '/:domain(teacher)/calendar/:date?',
      ]}
      component={CalendarPage}
    />
    <TeacherLayoutRoute
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(curriculum)/:year(year)?/:yearId?/:subject(subject)?/:subjectId?/:strand(strand)?/:strandSlug?/:standard(standard)?/:standardSlug?"
      component={CurriculumProgressPage}
    />
    <TeacherLayoutRoute
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(attainment)/:year(year)?/:yearId?/:subject(subject)?/:subjectId?/:unit(unit)?/:unitSlug?/:limit(limit)?/:limit?"
      component={AttainmentProgressPage}
    />
    <TeacherLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/progress/:subjectId?"
      component={withProgressLayout(WithUserPage(Progress))}
    />
    <TeacherLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(progress)/:subject(subject)?/:subjectId?/:strand(strand)?/:strandSlug?/:standard(standard)?/:standardSlug?"
      component={withProgressLayout(WithUserPage(Progress))}
    />
    <TeacherLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(skills)/:subject(subject)?/:subjectId?"
      component={withProgressLayout(WithUserPage(Skills))}
    />
    <TeacherLayoutRoute
      exact
      // eslint-disable-next-line max-len
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:tab(student)/:year?/:yearId?/user/:userId?/:userTab(attendance)/:subject(subject)?/:subjectId?"
      component={withProgressLayout(WithUserPage(Attendance))}
    />
    <TeacherLayoutRoute
      exact
      path="/teacher/reports/:academicYearId/:academicTermId/progress/:subjectId?"
      component={CurriculumProgressPage}
    />
    <TeacherLayoutRoute
      path="/teacher/reports/progress"
      component={CurriculumProgressPage}
    />
    <TeacherLayoutRoute path="/teacher/grading" component={Grading} />
    <Route
      exact
      path="/teacher/preview"
      render={() => <Redirect from="/teacher/preview" to="/teacher/preview/modules" />}
    />
    <TeacherLayoutRoute path="/teacher/preview/modules/:moduleId" component={PreviewModulePage} />
    <TeacherLayoutRoute path="/teacher/preview/modules" component={ModuleScreenShots} />

    <TeacherLayoutRoute exact path="/:domain(teacher)/attendance" component={TodayAttendance} />
    <TeacherLayoutRoute
      path="/teacher/attendance/:tab(today)/:yearId?"
      component={TodayAttendance}
    />
    <TeacherLayoutRoute
      path="/teacher/attendance/:tab(weekly)/:year?/:week?"
      component={WeeklyAttendance}
    />
    <TeacherLayoutRoute
      path="/teacher/reports/attendance"
      component={AttendanceReportPage}
    />
    <TeacherLayoutRoute
      path="/teacher/reports/:academicYearId/:academicTermId/attendance/:yearId?"
      component={AttendanceReportPage}
    />
    <TeacherLayoutRoute
      path="/teacher/curriculum/:gradeOrSubjectFirst?/:firstId?/:gradeOrSubjectSecond?/:secondId?"
      component={Curriculum}
    />
    <TeacherLayoutRoute exact path="/teacher/reports/homework" component={HomeworkPage} />
    <TeacherLayoutRoute
      path="/teacher/reports/:academicYearId/:academicTermId/homework/:yearId?"
      component={HomeworkPage}
    />
    <TeacherLayoutRoute
      path="/teacher/guides/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={TeacherGuides}
    />
    <Route
      exact
      path="/teacher/guide/lesson"
      render={() => <Redirect from="/teacher/guide/lesson" to="/teacher/guides" />}
    />
    <TeacherLayoutRoute
      // eslint-disable-next-line max-len
      path="/teacher/guide/lesson/:lessonSlug/:grade?/:gradeSlug?/:subject?/:subjectSlug?/:unit?/:unitSlug?"
      component={TeacherGuides}
    />
    <Redirect to="/lessons" />
  </Switch>
)

export default TeacherRoute
