import { arrayOf, bool, shape, string } from 'prop-types'
import chapterShape from 'src/shapes/chapter'
import indicatorShape from 'src/shapes/indicator'
import localizedField from 'src/shapes/localizedField'
import objectiveShape from 'src/shapes/objective'
import subjectShape from 'src/shapes/subject'
import unitShape from 'src/shapes/unit'
import homeworkAssignmentShape from 'src/shapes/homeworkAssignment'

export default {
  chapters: arrayOf(shape(chapterShape)),
  code: string,
  homeworkAssignments: arrayOf(shape(homeworkAssignmentShape)),
  id: string.isRequired,
  identifier: string.isRequired,
  indicators: arrayOf(shape(indicatorShape)),
  objectives: arrayOf(shape(objectiveShape)),
  slug: string,
  subject: shape(subjectShape),
  title: string,
  titleRaw: shape(localizedField),
  unit: shape(unitShape),
  unitTest: bool,
}
