import icons from 'src/lib/menu/icons'
import { GRADING } from 'src/constants/menu'

export const path = '/:domain/grading'

export const scene = {
  common: true,
  icon: icons[GRADING],
  id: GRADING,
  path,
}

export default {
  scene,
}
