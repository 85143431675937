import React, { Fragment } from 'react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { array, any, string, shape } from 'prop-types'
import yearShape from 'src/shapes/year'
import { CALENDAR, SCHOOL_SETTINGS } from 'src/constants/menu'
import ActionLink from 'src/components/ActionLink'
import Widget from 'src/components/Widget'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { schoolSettingsPath } from 'src/lib/routes'
import icons from 'src/lib/menu/icons'
import Access from 'src/components/Access/AccessContainer'
import usePath from 'src/lib/routes/usePath'
import calendarPath from 'src/pages/CalendarPage/paths'
import YearCalendar from 'src/pages/CalendarPage/YearCalendarContainer'

const propTypes = {
  academicYears: array.isRequired,
  children: any,
  date: string,
  year: shape(yearShape),
}

const defaultProps = {
  year: undefined,
}

const CalendarPage = ({
  academicYears,
  children,
  date,
  year,
}) => {
  const { title, id: yearId } = year || {}
  const currentIndex = academicYears.findIndex(({ id }) => id === yearId)
  const nextYear = academicYears[currentIndex + 1]
  const prevYear = academicYears[currentIndex - 1]
  const pathTo = usePath(calendarPath)
  return (
    <Widget
      token={CALENDAR}
      title={(
        <Fragment>
          {t(`${CALENDAR}.title`)}
          <Access staff>
            <Link
              to={schoolSettingsPath('calendar')}
              title={t(`${SCHOOL_SETTINGS}.title`)}
              style={{ float: 'right' }}
            >
              <Icon icon={icons[SCHOOL_SETTINGS]} />
            </Link>
          </Access>
        </Fragment>
      )}
    >
      <Div flex gutter-1>
        <Div col={9} px1>
          <Div flex gutter-1>
            <Div>
              <ActionLink
                disabled={!prevYear}
                to={prevYear && pathTo({ yearId: prevYear.slug || prevYear.id })}
                icon="arrow-left"
                before
              >
                {t('navigation.previous')}
              </ActionLink>
            </Div>
            <Div auto center h4 bold>
              {title} {t('shared.academicYear')}
            </Div>
            <Div>
              <ActionLink
                disabled={!nextYear}
                to={nextYear && pathTo({ yearId: nextYear.slug || prevYear.id })}
                icon="arrow-right"
                after
              >
                {t('navigation.next')}
              </ActionLink>
            </Div>
          </Div>
          <YearCalendar date={date} yearId={yearId} />
        </Div>
        <Div col={3} px1>
          {children}
        </Div>
      </Div>
    </Widget>
  )
}

CalendarPage.propTypes = propTypes
CalendarPage.defaultProps = defaultProps

export default CalendarPage
