import buildPath from 'src/lib/routes/buildPath'

const assessmentPath = ({ marked } = {}) => (
  buildPath(['/teacher/assessment', marked && 'marked'])
)

const assessmentStudentPath = ({ userId, yearId, ...rest }) => (
  buildPath([assessmentPath(rest), yearId && `year/${yearId}`, userId && `user/${userId}`])
)

const assessmentHomeworkPath = ({ chapterId, ...rest }) => (
  buildPath([assessmentStudentPath(rest), chapterId && `homework/${chapterId}`])
)

export {
  assessmentStudentPath,
  assessmentHomeworkPath,
  assessmentPath,
}
