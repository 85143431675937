import every from 'lodash/every'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isUndefined from 'lodash/isUndefined'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniqShuffle from 'src/lib/uniqShuffle'
import slice from 'lodash/slice'
import transformValues from 'src/lib/transformValues'

const INIT = 'Sorting/INIT'
const MOVE = 'Sorting/MOVE'
const MARK = 'Sorting/MARK'
const MOVE_HOMEWORK = 'Sorting/MOVE_HOMEWORK'
const RETRY = 'Sorting/RETRY'
const RETRY_HOMEWORK = 'Sorting/RETRY_HOMEWORK'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  displayableCardsId: [],
  entitiesById: {},
  places: {},
}

const createCards = ({ basketId }) => (a, { id }) => ({
  ...a,
  [id]: { basketId, entityId: id, id },
})

const createPlaces = (a, { id }) => ({
  ...a,
  [id]: { id },
})

const checkCardState = (a, card) => {
  const newCard = {
    [card.id]: {
      ...card,
      correct: card.placeId === card.basketId,
    },
  }
  return {
    ...a,
    ...newCard,
  }
}

const findAvailableCards = cards => (
  map(
    map(cards).filter(({ placeId }) => !placeId),
    'id',
  )
)

const createCardsFromCollection = ({ basketEntities }) => (a, collection, index) => {
  const newCards = collection.reduce(createCards({ basketId: basketEntities[index].id }), {})

  return a.concat(Object.values(newCards))
}

const splitEntitiesByCollection = ({ collectionIndexs, entities }) => (
  (a, currentCollectionIndex, index) => {
    const currentCollection = slice(entities, currentCollectionIndex + 1, collectionIndexs[index + 1])

    return [...a, currentCollection]
  }
)
const splitEntitiesByCollectionAndCreateCards = ({ basketEntities, entities }) => {
  const collectionIndexs = filter(
    map(entities, (entity, index) => {
      if (includes(entity.slug, 'collection')) {
        return index
      }
      return undefined
    }),
    collectionIndex => !isUndefined(collectionIndex),
  )
  const entitiesByCollection = reduce(collectionIndexs, splitEntitiesByCollection({ collectionIndexs, entities }), {})
  const entitiesByCollectionCards = entitiesByCollection.reduce(createCardsFromCollection({ basketEntities }), [])

  return keyBy(uniqShuffle(entitiesByCollectionCards), 'id')
}

const findDisplayableCards = availableCardsId => (
  availableCardsId.length <= 8 ? availableCardsId : availableCardsId.slice(0, 8)
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const basketEntities = filter(entities, e => includes(e.slug, 'collection'))
      const cards = splitEntitiesByCollectionAndCreateCards({ basketEntities, entities })
      const displayableCardsId = findDisplayableCards(map(cards, 'id'))
      const places = basketEntities.reduce(createPlaces, {})
      return {
        ...initialState,
        actualEntities: entities,
        cards,
        displayableCardsId,
        entitiesById: keyBy(entities, 'id'),
        places,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards } = state
      const newCards = {
        ...cards,
        [sourceId]: {
          ...cards[sourceId],
          placeId: targetId,
        },
      }
      const checkedCards = reduce(newCards, checkCardState, {})
      const availableCardsId = findAvailableCards(newCards)
      const displayableCardsId = findDisplayableCards(availableCardsId)
      const completed = Object.keys(checkedCards).length && every(checkedCards, 'correct')
      const newState = {
        ...state,
        cards: checkedCards,
        completed: completed ? true : null,
        displayableCardsId,
      }
      return {
        ...state,
        ...newState,
      }
    }

    case MOVE_HOMEWORK: {
      const { sourceId, targetId } = payload
      const { cards } = state
      const newCards = {
        ...cards,
        [sourceId]: {
          ...cards[sourceId],
          placeId: targetId,
        },
      }
      const availableCardsId = findAvailableCards(newCards)
      const displayableCardsId = findDisplayableCards(availableCardsId)
      const newState = {
        ...state,
        cards: newCards,
        displayableCardsId,
      }
      return {
        ...state,
        ...newState,
      }
    }

    case MARK: {
      const { cards } = state
      const checkedCards = reduce(cards, checkCardState, {})
      const completed = Object.keys(checkedCards).length && every(checkedCards, 'correct')
      const newState = {
        ...state,
        cards: {
          ...cards,
          ...checkedCards,
        },
        completed,
      }
      return newState
    }


    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    case RETRY_HOMEWORK: {
      const { cards } = state
      return {
        ...state,
        cards: transformValues(cards, card => ({ ...card, correct: null })),
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const moveHomework = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE_HOMEWORK,
})

const retry = () => ({
  type: RETRY,
})

const retryHomework = () => ({
  type: RETRY_HOMEWORK,
})

export {
  initialState,
  init,
  mark,
  moveHomework,
  moveCard,
  retry,
  retryHomework,
}

export default reducer
