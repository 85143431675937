import { bool, shape, string } from 'prop-types'
import lessonShape from 'src/shapes/lesson'
import userShape from 'src/shapes/user'
import yearShape from 'src/shapes/year'

const homeworkAssignmentShape = {
  dueOn: string,
  hasAnswers: bool,
  id: string,
  lesson: shape(lessonShape),
  lessonId: string,
  user: shape(userShape),
  year: shape(yearShape),
  yearId: string,
}

export default homeworkAssignmentShape
