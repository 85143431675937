import React from 'react'
import { bool, func, object, string } from 'prop-types'
import map from 'lodash/map'
import BodyBackground from 'src/components/Layout/BodyBackgroundContainer'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import ObjectRotationSelector from 'src/modules/ListenAndShoot/ObjectRotationSelector'
import ObjectTargetSelector from 'src/modules/ListenAndShoot/ObjectTargetSelector'
import ObjectCommandSelector from 'src/modules/ListenAndShoot/ObjectCommandSelector'

const propTypes = {
  background: string.isRequired,
  cursive: bool,
  lines: object.isRequired,
  mixFont: bool,
  onClick: func.isRequired,
  text: bool,
  theme: string.isRequired,
  unitTest: bool,
}

const JUSTIFY = {
  backyard: 'start',
  fence: 'start',
  mole: 'center',
  river: 'center',
  space: 'start',
}

const ListenAndShoot = ({
  autoPlay,
  background,
  cursive,
  lines,
  mixFont,
  onClick,
  playGunShotSound,
  text,
  theme,
  unitTest,
}) => (
  <Deck>
    <BodyBackground url={background} unitTest={unitTest} />
    <Div flex auto justify="center">
      {
        map(lines, (line, rowId) => (
          <Div auto flex column justify="start" key={rowId}>
            <Div
              flex
              auto
              justify={JUSTIFY[theme]}
              column
              px2
              onClick={playGunShotSound}
            >
              <Div flex height={15} relative>
                {
                  map(line.variants, ({ id, entity, selected, correct }, objectId, objectList) => (
                    <Div auto flex p2 key={id}>
                      <ObjectTargetSelector
                        correct={correct}
                        cursive={cursive}
                        entity={entity}
                        mixFont={mixFont}
                        objectId={objectId}
                        objectList={objectList}
                        onClick={() => onClick({ col: id, row: rowId })}
                        selected={selected}
                        text={text}
                        theme={theme}
                      />
                    </Div>
                  ))
                }
              </Div>
            </Div>
            <Div flex height={15} pb3 px3 justify="start">
              <Div flex column auto justify="end">
                <ObjectCommandSelector
                  entity={line.entity}
                  autoPlay={autoPlay}
                  theme={theme}
                />
              </Div>
            </Div>
          </Div>
        ))
      }
      <ObjectRotationSelector theme={theme} />
    </Div>
  </Deck>
)


ListenAndShoot.propTypes = propTypes
export default ListenAndShoot
