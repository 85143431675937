import { func } from 'prop-types'
import { t } from 'i18next'
import React from 'react'
import Button from 'src/components/Selection/Button'

const propTypes = {
  cancel: func.isRequired,
}

const style = { color: '#c11010' }

const CancelSelection = ({ cancel }) => (
  <Button {...{ onClick: cancel, outline: false, style }}>✖ {t('selection.clear')}</Button>
)

CancelSelection.propTypes = propTypes

export default CancelSelection
