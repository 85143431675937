import React from 'react'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityBubble from 'src/components/EntityBubble'
import map from 'lodash/map'

const Line = ({ line: { entity, variants }, onClick, autoPlay, image, silent }) => (
  <Deck>
    <Div auto flex column justify="start">
      <Div flex pb3 px1>
        {
          entity && (
            <EntityBubble
              key={entity.id}
              english
              entity={entity}
              label=" "
              play={!image}
              audio
              autoPlay={!silent && autoPlay}
              silent={silent}
            />
          )
        }
      </Div>
      <Div flex auto pb3>
        {
          map(variants, ({ id, entity, selected, correct, label }) => (
            <Div auto flex px1 key={id}>
              <EntityBubble
                entity={entity}
                selected={selected}
                correct={correct}
                onClick={() => onClick(id)}
                english
                onKeyPress={() => onClick(id)}
                label={label}
                image
                silent
              />
            </Div>
          ))
        }
      </Div>
    </Div>
  </Deck>
)

export default Line
