import React from 'react'
import { array, bool, func, object, shape } from 'prop-types'

import AdaptiveCard from 'src/components/AdaptiveCard'
import Bubble from 'src/components/Bubble'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import EntityImage from 'src/components/EntityImage'
import Place from 'src/components/Place'
import map from 'lodash/map'
import pick from 'lodash/pick'
import KeyHandler from 'src/components/KeyHandler'
import SpeakerBubble from 'src/components/SpeakerBubble'

const propTypes = {
  answerEntity: object.isRequired,
  availableCardsId: array.isRequired,
  cards: shape().isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
  questionEntity: object.isRequired,
  speaker: bool,
  speakerEntity: object.isRequired,
}


const renderPlace = ({ cards, entitiesById, place, moveCard, speakerEntity, speaker }) => {
  const { id, cardId } = place
  const card = cardId ? cards[cardId] : undefined

  return (
    <Div z0 key={cardId} justify-center flex flex-1 mbn3>
      {
        !speaker && (
          <Div flex col={2} />
        )
      }
      <SpeakerBubble
        bounce
        speakerEntity={speakerEntity}
        label={card ? card.index : null}
        left
        hasTip={speaker}
        justify="center"
        mln={speaker ? 3 : undefined}
      >
        <Place
          id={id}
          key={id}
          onDrop={moveCard}
        >
          <Div flex flex-1 justify-center>
            {
              card ? (
                <AdaptiveCard
                  audio
                  autoPlay
                  correct={card.correct}
                  draggable
                  english
                  entity={entitiesById[card.id]}
                  fit
                  id={card.id}
                  keyToPlay={card.index}
                />
              ) : <Card fit title="..." />
            }
          </Div>
        </Place>
      </SpeakerBubble>
      <Div flex col={speaker ? 3 : 2} />
    </Div>
  )
}

const ProvideQuestion = ({
  answerEntity,
  availableCardsId,
  cards,
  entitiesById,
  moveCard,
  places,
  questionEntity,
  speaker,
  speakerEntity,
  started,
}) => (
  <Deck relative>
    <Div flex>
      {
        map(pick(entitiesById, availableCardsId)).map(entity => (
          <Div key={entity.id} flex-1>
            <AdaptiveCard
              audio
              draggable
              english
              entity={entity}
              minHeight={false}
              height={6}
              onClick={() => moveCard({ sourceId: entity.id, targetId: questionEntity.id })}
              silent
              size={3}
            />
            <KeyHandler
              label={`${cards[entity.id].index}`}
              onKeyPress={() => moveCard({ sourceId: entity.id, targetId: questionEntity.id })}
            />
          </Div>
        ))
      }
    </Div>
    <Div z1 flex auto justify-center>
      {
        map(places, (place, i) => renderPlace({
          cards, entitiesById, i, moveCard, place, speaker, speakerEntity }))
      }
    </Div>
    <Div flex mt={2}>
      <Div flex col={3} />
      <Bubble
        key={answerEntity.id}
        label=" "
        mln={4}
        mrn={3}
        tip="right"
      >
        <AdaptiveCard
          audio
          autoPlay={started}
          english
          entity={answerEntity}
          height={16}
        />
      </Bubble>
      <Div flex col={3}>
        <EntityImage entity={answerEntity} />
      </Div>
    </Div>
  </Deck>
)

ProvideQuestion.propTypes = propTypes
export default ProvideQuestion
