import React from 'react'
import { useParams } from 'react-router'
import UnitTestContainer from 'src/pages/UnitTest/UnitTestContainer'

const UnitTest = () => {
  const { lessonSlug } = useParams()
  return (
    <UnitTestContainer lessonSlug={lessonSlug} homework />
  )
}
export default UnitTest
