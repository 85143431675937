import { arrayOf, string, bool } from 'prop-types'
import academicTermShape from 'src/shapes/academicTerm'

const academicYearShape = {
  academicTerms: arrayOf(academicTermShape),
  current: bool,
  finishedOn: string,
  startedOn: string,
  title: string,
}

export default academicYearShape
