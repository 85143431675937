import React from 'react'
import { shape } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  gradeSystem: shape(),
}

const defaultProps = {
  gradeSystem: {},
}

const createRangeOfPercent = (range, gradeSystem) => (a, key, index, keyList) => {
  if (index === 0) {
    return {
      ...a,
      [key]: `${gradeSystem[key].percent} - 100`,
    }
  }
  return {
    ...a,
    [key]: `${gradeSystem[key].percent} - ${gradeSystem[keyList[index - 1]].percent - 1}`,
  }
}

const TableBody = ({ gradeSystem }) => {
  const listOfKeys = Object.keys(gradeSystem).sort().reverse()
  const RangeOfPercent = listOfKeys.reduce(
    createRangeOfPercent(listOfKeys.length - 1, gradeSystem),
    {},
  )
  return (
    <tbody>
      {
        listOfKeys.map(key => (
          <tr key={key}>
            <td>{RangeOfPercent[key]}</td>
            <td><Div p2 bg={gradeSystem[key].color} /></td>
            <td>{gradeSystem[key].grade}</td>
            <td>{key}</td>
            <td>{gradeSystem[key].title_en}</td>
            <td>{gradeSystem[key].title_th}</td>
          </tr>
        ))
      }
    </tbody>
  )
}

TableBody.propTypes = propTypes
TableBody.defaultProps = defaultProps
export default TableBody
