import FillInMissingWords, {
  FillInMissingWordsNoImageContainer as FillInMissingWordsNoImage,
  FillInMissingWordsNoImageFixedContainer as FillInMissingWordsNoImageFixed,
} from 'src/modules/FillInMissingWords/FillInMissingWordsContainer'
import reducer from 'src/modules/FillInMissingWords/reducer'

export {
  reducer,
  FillInMissingWords,
  FillInMissingWordsNoImage,
  FillInMissingWordsNoImageFixed,
}
