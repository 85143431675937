import React from 'react'
import { shape } from 'prop-types'
import Div from 'src/components/Div'
import yearShape from 'src/shapes/year'
import YearForm from 'src/pages/Year/shared/Form'

const propTypes = {
  year: shape(yearShape).isRequired,
}

const YearEdit = ({ errors, year, onSubmit, cancelPath }) => (
  <Div py={1} px={3} bg="white" rounded>
    <Div flex gutter={2}>
      <Div auto>
        <YearForm
          initialValues={year}
          errors={errors}
          onSubmit={onSubmit}
          cancelPath={cancelPath}
        />
      </Div>
    </Div>
  </Div>
)

YearEdit.propTypes = propTypes
export default YearEdit
