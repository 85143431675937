import React from 'react'
import { shape, array, arrayOf } from 'prop-types'
import { t } from 'i18next'
import chunk from 'lodash/chunk'
import AcademicCover from 'src/pages/TeacherGuides/AcademicCover'
import Chapter from 'src/pages/preview/Chapter'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import indicatorShape from 'src/shapes/indicator'
import Isolated from 'src/pages/preview/Isolated'
import learn21Image from 'src/images/learn21-transparent.svg'
import uuid from 'uuid'
import PageTitle from 'src/components/ui/PageTitle'
import camelToKebab from 'src/lib/camelToKebab'

const MAXIMUM_ENTITIES_PER_COLUMN = 8
const NUMBER_OF_ENTITY_COLUMN = 2

const propTypes = {
  lesson: shape({
    chapters: array.isRequired,
  }).isRequired,
  lessonIndicators: arrayOf(shape(indicatorShape)).isRequired,
  teacherGuide: arrayOf(shape(entityShape)).isRequired,
}

const matchTeacherGuideByName = moduleId => (
  ({ slug }) => (`teacher-guide-${camelToKebab(moduleId)}` === slug)
)

const matchInstructionByName = moduleId => (
  ({ slug }) => (`instructions-${camelToKebab(moduleId)}` === slug)
)

const replace = ['Comic Strip', 'Speech Recognition', 'Speech Bubbles']

const addColon = title => replace.reduce((a, name) => a.replace(`${name} `, `${name}: `), title)

const prefixAndSuffix = ['Fixed', ' Homework', 'Lesson', 'No Image']
const removePrefixAndSuffix = title => (
  prefixAndSuffix.reduce((a, name) => a.replace(name, ''), title)
)

const componentTitle = ({ component, title }) => {
  if (component === 'TitlePage') return t('component.titlePage')
  return <span className="bold">{removePrefixAndSuffix(addColon(title))}</span>
}

const isCollection = slug => slug.split('-')[0] === 'collection'

const selectChapterTabTitle = (titleEn) => {
  const groupTitle = titleEn.split(':')[0]
  const groupTitleArray = groupTitle.split(' ')
  if (groupTitleArray[0] === 'Vocabulary' || groupTitleArray[0] === 'Phonics') {
    return t('guide.presentation')
  }
  if (groupTitleArray[0] === 'Language' && groupTitleArray[2] === '1') {
    return t('guide.presentation')
  }
  if (groupTitleArray[0] === 'Language' && groupTitleArray[2] === '2') {
    return t('guide.practice')
  }
  if (groupTitleArray[0] === 'Homework') {
    return t('guide.production')
  }
  return null
}

const Lesson = ({
  lesson,
  lessonIndicators,
  teacherGuide,
  subjectId,
  unitId,
  gradeId,
  instructions,
  ...props
}) => {
  const { chapters, unit } = lesson
  const hideChapters = ['TitlePage', 'CurriculumMapping', 'Objectives', 'HomeworkDone', 'WellDone']
  const printableChapters = chapters.filter(({ component }) => !hideChapters.includes(component))
  return (
    <Div print-container mx-auto>
      <style>
        {
          `
            body {
              background-image: none !important;
            }
            @media print {
              html { font-size: 8px !important }
              body { font-size: 14px !important }
              .h1 { font-size: 3rem !important }
            }
            .logo-end {
              padding-top: 27px;
              margin-left: -5px;
            }
          `
        }
      </style>
      <PageTitle>{`${lesson.identifier} ${lesson.title} Teacher Guide`}</PageTitle>
      <div>
        <div className="
          bg-blue-printable
          relative
          height-40vh
          print-show
          mb2
          "
        >
          <Div p2 flex>
            <Div ml2 flex><img alt="Learn21" src={learn21Image} /></Div>
            <Div fontSize={8} white ml2 lineHeight={22} className="logo-end">Teacher Guides</Div>
          </Div>
        </div>
      </div>
      <span className="h2 px2 bold">
        {lesson.identifier} {lesson.title} Teacher Guide
      </span>
      <div className="clearfix" />
      <AcademicCover
        indicators={lessonIndicators}
        lesson={lesson}
        unit={unit}
      />
      {printableChapters.map((chapter, count) => {
        const pageBreak = count % 2
        const { component, entities, id: chapterId, titleRaw } = chapter
        const instruction = instructions.find(matchInstructionByName(component))
        const { meaning } = { ...instruction }
        const instructionTitle = { ...meaning }.title
        const suggestion = teacherGuide.find(matchTeacherGuideByName(component))
        const titleEn = { ...titleRaw }.en
        const chapterTabTitle = selectChapterTabTitle(titleEn)
        return (
          <div key={chapter.id} className="relative">
            { pageBreak === 0 && (<div className="printBreak" />)}
            <div
              className="clearfix mxn1 mb2 bg-white rounded py1 px2"
              style={{ maxHeight: '60rem' }}
            >
              {
                !chapter.published && (
                <span className="inline-block px1 white bg-red rounded print-hide">
                  Unpublished
                </span>
                )}
              <div className="col-6 px1 right clearfix mb2">
                <div
                  className="relative border shadow bg-darken-1 rounded overflow-hidden"
                  style={{
                    paddingBottom: '2rem',
                    paddingTop: '60%',
                    width: '100%',
                  }}
                >
                  <Div absolute stretch flex column justify="center">
                    <Isolated chapterId={chapterId}>
                      <Chapter
                        lesson={lesson}
                        chapterId={chapterId}
                        instructions={instructions}
                        guide
                        {...props}
                      />
                    </Isolated>
                  </Div>
                </div>
              </div>
              <div className="col-6 px1 right clearfix mb2">
                {
                  chapterTabTitle && (
                    <div className="h4">
                      {chapterTabTitle}
                    </div>
                  )
                }
                <div className="h3 mb1">
                  {componentTitle({ component: chapter.component, title: chapter.title })}
                </div>
                <Div key={uuid()} className="h4">
                  {
                    instructionTitle &&
                    (
                      <Div
                        key={uuid()}
                        mb={2}
                        remove-border-print
                        style={{
                          display: 'flex',
                          flexFlow: 'column wrap',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Div flex wrap col-12 align-center mr2 mb1 h6>
                          {instructionTitle}
                        </Div>
                      </Div>
                    )
                  }
                </Div>
                <Div className="h4r" key={uuid()}>
                  {
                    entities.length > 0 && component !== 'TitlePage' && (
                      <Div flex remove-border-print mb={2}>
                        {
                          chunk(entities, MAXIMUM_ENTITIES_PER_COLUMN)
                            .slice(0, NUMBER_OF_ENTITY_COLUMN)
                            .map(entityArray => (
                              <Div column col-6 key={uuid()} remove-border-print>
                                <ul>
                                  {
                                    entityArray.map(({ id, titleEn, slug }) => (
                                      <li key={id} className="h6 mr3">
                                        <Div className="nowrap overflow-hidden ellipsis">
                                          <span className={`${isCollection(slug) ? 'bold' : ''}`}>
                                            {titleEn}
                                          </span>
                                        </Div>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </Div>
                            ))}
                      </Div>
                    )
                  }
                </Div>
                <Div key={uuid()} className="h4">
                  {
                    suggestion && suggestion.title !== '-' &&
                    (
                      <Div
                        key={uuid()}
                        mb={2}
                        remove-border-print
                        style={{
                          display: 'flex',
                          flexFlow: 'column wrap',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Div flex wrap col-12 align-center mr2 mb1 h6>
                          {suggestion.title}
                        </Div>
                      </Div>
                    )
                  }
                </Div>
              </div>
            </div>
            {
              (count % 2 === 1) && (
                <Div print-only right bottom>
                  {lesson.identifier} {lesson.title} Teacher Guide &nbsp;
                  <strong>/&nbsp;{(count + 1) / 2}</strong>
                </Div>
              )
            }
          </div>
        )
      })}
      {
        printableChapters.length % 2 !== 0 && (
          <div key="extra-page" className="relative print-only">
            <div
              className="clearfix mb2"
              style={{ maxHeight: '60rem' }}
            >
              <div className="col-6 mb2">
                <div
                  className="relative"
                  style={{
                    paddingBottom: '2rem',
                    paddingTop: '60%',
                    width: '100%',
                  }}
                />
              </div>
            </div>
            <div className="printBreak">
              <Div print-only right bottom>
                {lesson.identifier} {lesson.title} Teacher Guide &nbsp;
                <strong>/&nbsp;{(printableChapters.length + 1) / 2}</strong>
              </Div>
            </div>
          </div>
        )
      }
    </Div>
  )
}

Lesson.propTypes = propTypes
export default Lesson
