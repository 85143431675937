import {
  TrueOrFalseParagraphs,
  TrueOrFalseImage,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
} from 'src/modules/SelectType/TrueOrFalse'
import CorrectOrIncorrect from 'src/modules/SelectType/CorrectOrIncorrect'
import reducer from 'src/modules/SelectType/reducer'
import SelectTypeContainer from 'src/modules/SelectType/SelectTypeContainer'

export {
  CorrectOrIncorrect,
  reducer,
  TrueOrFalseImage,
  TrueOrFalseParagraphs,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
}

export default SelectTypeContainer
