import React from 'react'
import usePageParams from 'src/lib/usePageParams'
import Access from 'src/components/Access'
import LessonContainer from 'src/pages/Lesson/LessonContainer'


const HomeworkPage = () => (
  <Access redirect>
    <LessonContainer {...usePageParams()} homework microphoneCheck />
  </Access>
)


export default HomeworkPage
