const UPDATE_TRIESITERATION = 'iterationIcon/UPDATE_TRIESITERATION'
const CLEAR_TRIESITERATION = 'iterationIcon/CLEAR_TRIESITERATION'
const UPDATE_CARDITERATION = 'iterationIcon/UPDATE_CARDITERATION'
const CLEAR_CARDITERATION = 'iterationIcon/CLEAR_CARDITERATION'

const initialState = {
  cardIterations: [],
  triesIterations: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TRIESITERATION:
      return {
        ...state,
        triesIterations: action.payload.triesIterations,
      }
    case CLEAR_TRIESITERATION:
      return {
        ...state,
        triesIterations: [],
      }
    case UPDATE_CARDITERATION:
      return {
        ...state,
        cardIterations: action.payload.cardIterations,
      }
    case CLEAR_CARDITERATION:
      return {
        ...state,
        cardIterations: [],
      }
    default:
      return state
  }
}

const updateTriesIterationIcon = ({ triesIterations }) => ({
  payload: { triesIterations },
  type: UPDATE_TRIESITERATION,
})

const clearTriesIterationIcon = () => ({
  type: CLEAR_TRIESITERATION,
})

const updateCardIterationIcon = ({ cardIterations }) => ({
  payload: { cardIterations },
  type: UPDATE_CARDITERATION,
})

const clearCardIterationIcon = () => ({
  type: CLEAR_CARDITERATION,
})

export default reducer

export {
  clearCardIterationIcon,
  clearTriesIterationIcon,
  updateCardIterationIcon,
  updateTriesIterationIcon,
}
