import { Component } from 'react'
import { any, bool } from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router-dom'
import { setPage } from 'src/components/analytics'

const { info } = console

const propTypes = {
  children: any.isRequired,
  debug: bool,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
}

const defaultProps = {
  debug: false,
}

class ReactRouterGA extends Component {
  componentDidMount() {
    this.sendPageView(this.props.location)
    this.props.history.listen(this.sendPageView)
  }

  sendPageView = (location) => {
    setPage(location.pathname)
    if (this.props.debug) {
      info(`[react-router-ga] Page view: ${location.pathname}`)
    }
  }

  render() {
    return this.props.children
  }
}

ReactRouterGA.defaultProps = defaultProps
ReactRouterGA.propTypes = propTypes
export default withRouter(ReactRouterGA)
