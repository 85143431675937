import React from 'react'
import { string } from 'prop-types'

const propTypes = {
  backgroundUrl: string,
  color: string,
}
const defaultProps = {
  backgroundUrl: undefined,
  color: '#00142d',
}

const BackgroundStyle = ({ color, backgroundUrl }) => (
  <style>
    {
      `
        body {
          background-color: ${color};
          ${backgroundUrl && `background-image: url("${backgroundUrl}");`}
          background-position: center bottom;
          background-repeat: repeat-x;
        }
      `
    }
  </style>
)

BackgroundStyle.propTypes = propTypes
BackgroundStyle.defaultProps = defaultProps

export default BackgroundStyle
