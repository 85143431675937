import React from 'react'
import colors from 'colors.css'
import { arrayOf, func, number, shape, string } from 'prop-types'
import { t } from 'i18next'
import { Bar, BarChart, Cell, Label, Legend, ReferenceLine, ResponsiveContainer, XAxis } from 'recharts'

import Div from 'src/components/Div'
import rateToColor from 'src/lib/rateToColor'
import academicYearShape from 'src/shapes/academicYear'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  dataset: arrayOf(shape({
    homeworkRate: number.isRequired,
    id: string.isRequired,
    title: string,
  })),
  onClick: func.isRequired,
}

const defaultProps = {
  dataset: [],
}

const OverviewChart = ({ dataset, academicYears, onClick }) => {
  const academicYear = academicYears[0]
  const startedOn = new Date({ ...academicYear }.startedOn)
  const finishedOn = new Date({ ...academicYear }.finishedOn)
  const today = new Date()
  const todayLinePosition = (today - startedOn) / (finishedOn - startedOn)
  const data = dataset.map(
    ({
      completedHomeworkCount,
      availableHomeworkCount,
      ...rest
    }) => ({
      progress: availableHomeworkCount && completedHomeworkCount ? completedHomeworkCount / availableHomeworkCount : 0,
      ...rest,
    }),
  )
  return (
    <Div style={{ height: '15rem' }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          barCategoryGap={0}
        >
          <Legend
            verticalAlign="top"
            height={36}
            payload={[{ type: 'line', value: `— ${t('shared.today')}` }]}
          />
          <XAxis dataKey="title" />
          <Bar type="monotone" dataKey="progress" onClick={onClick}>
            {
              dataset.map(({ id, homeworkRate }) => (
                <Cell
                  key={id}
                  fill={colors[rateToColor(homeworkRate)]}
                />
              ))
            }
          </Bar>
          <ReferenceLine y={todayLinePosition} stroke={colors.blue} />
          <ReferenceLine y={0.5} stroke="black" ifOverflow="extendDomain" strokeDasharray="3 3">
            <Label position="insideTopLeft">
              {`${t('shared.term')} 1`}
            </Label>
          </ReferenceLine>
          <ReferenceLine y={1} stroke="black" ifOverflow="extendDomain" strokeDasharray="3 3">
            <Label position="insideTopLeft">
              {`${t('shared.term')} 2`}
            </Label>
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
    </Div>
  )
}

OverviewChart.propTypes = propTypes
OverviewChart.defaultProps = defaultProps

export default OverviewChart
