import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import BlendingSoundsScene from 'src/modules/BlendingSounds/BlendingSoundsSceneContainer'
import { init, mark, retry } from 'src/modules/BlendingSounds/reducer'
import gamePropTypes from 'src/shapes/Game'
import entityShape from 'src/shapes/entity'

const propTypes = {
  ...gamePropTypes,
  entities: arrayOf(shape(entityShape).isRequired).isRequired,
}

class BlendingSoundsGame extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, init } = this.props
    init({ entities })
  }

  render() {
    const { completed, mark, retry } = this.props
    const { init } = this
    return (
      <Game {...{ completed, init, mark, retry }}>
        <BlendingSoundsScene />
      </Game>
    )
  }
}

BlendingSoundsGame.propTypes = propTypes
BlendingSoundsGame.demoData = {
  entities: [
    'phonics-t',
    'phonics-r',
    'phonics-e',
    'tree',
    'train',
    'trap',
    'phonics-t',
    'phonics-r',
    'phonics-a',
    'phonics-p',
    'trap',
    'tree',
    'train',
  ],
}

export default connect(
  (({ blendingSounds }) => blendingSounds),
  dispatch => bindActionCreators({ init, mark, retry }, dispatch),
)(BlendingSoundsGame)
