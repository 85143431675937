import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import PortalLayout from 'src/pages/PortalLayout'
import PortalStudentHomework from 'src/pages/PortalLayout/PortalStudentHomework'
import PortalStudentProgress from 'src/pages/PortalLayout/PortalStudentProgress'
import PortalStudentAttendance from 'src/pages/PortalLayout/PortalStudentAttendance'
import UserEdit from 'src/pages/User/Edit'
import userRedirect from 'src/lib/userRedirect'
import PortalStudents from 'src/pages/PortalLayout/PortalStudents'
import CalendarPage from 'src/pages/CalendarPage'

export const path = '/portal'

const PortalLayoutRoute = ({ component: Component, path, exact }) => (
  <Route path={path} exact={exact}>
    <PortalLayout>
      <Component path={path} />
    </PortalLayout>
  </Route>
)

const PortalRoute = () => (
  <Switch>
    <PortalLayoutRoute
      exact
      path="/portal"
      component={userRedirect(userId => `/portal/${userId}`)}
    />
    <PortalLayoutRoute exact path="/portal/:parentId" component={PortalStudents} />
    <PortalLayoutRoute
      path="/portal/:parentId/edit/:studentId"
      component={UserEdit}
    />
    <PortalLayoutRoute
      path="/:domain(portal)/:parentId/homework/:studentId?"
      component={PortalStudentHomework}
    />
    <PortalLayoutRoute
      path="/:domain(portal)/:parentId/skills/:studentId?/:subject(subject)?/:subjectId?"
      component={PortalStudentProgress}
    />
    <PortalLayoutRoute
      path="/:domain(portal)/:parentId/attendance/:studentId?/:subjectId?"
      component={PortalStudentAttendance}
    />
    <PortalLayoutRoute
      path={[
        '/:domain(portal)/:parentId/calendar/:year(year)/:yearId/:date?',
        '/:domain(portal)/:parentId/calendar/:date?',
      ]}
      component={CalendarPage}
    />
    <Redirect to="/lessons" />
  </Switch>
)

export default PortalRoute
