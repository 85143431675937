import {
  ListeningSounds2Container,
  ListeningSounds3Container,
} from 'src/modules/ListeningSounds/ListeningSoundsContainer'

ListeningSounds2Container.demoData = {
  entities: [
    'phonics-t',
    'phonics-d',
    'phonics-p',
    'phonics-b',
  ],
}
ListeningSounds3Container.demoData = ListeningSounds2Container.demoData

export {
  ListeningSounds2Container as ListeningSounds2,
  ListeningSounds3Container as ListeningSounds3,
}
