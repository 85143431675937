import React, { Component } from 'react'
import { bool, func, string, array, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/SelectType/reducer'
import SelectType from 'src/modules/SelectType/SelectType'

const propTypes = {
  ...gamePropTypes,
  choicesType: array.isRequired,
  cols: number,
  lines: array.isRequired,
  onClick: func.isRequired,
  paragraph: string,
  silent: bool,
}

const defaultProps = {
  cols: 1,
  silent: false,
}

class SelectTypeContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { init, ...params } = this.props
    init(params)
  }

  render() {
    const {
      actualEntities,
      audioOnly,
      headerHeight,
      mark,
      retry,
      completed,
      lines,
      paragraphIds,
      onClick,
      entitiesById,
      choicesType,
      image,
      silent,
      trueOrFalseType,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <SelectType
          audioOnly={audioOnly}
          choicesType={choicesType}
          entitiesById={entitiesById}
          image={image}
          headerHeight={headerHeight}
          lines={lines}
          onClick={onClick}
          paragraphIds={paragraphIds}
          silent={silent}
          trueOrFalseType={trueOrFalseType}
        />
      </Game>
    )
  }
}

SelectTypeContainer.defaultProps = defaultProps
SelectTypeContainer.propTypes = propTypes
SelectTypeContainer.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'collection-true',
    'phrase-she-is-washing-the-dishes',
    'phrase-take-these-pills',
    'collection-false',
    'phrase-he-is-watering-the-plants-in-the-garden',
    'phrase-he-is-my-elder-brother',
    'collection-null',
    'phrase-he-is-my-elder-brother',
    'phrase-it-is-a-mango',
  ],
}

const select = state => state.selectType
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { SelectTypeContainer }
export default connect(select, actions)(SelectTypeContainer)
