import React from 'react'
import { string, shape, oneOfType, element, arrayOf } from 'prop-types'
import { graphql } from '@apollo/react-hoc'
import RedirectToHomePage from 'src/pages/Login/RedirectToHomePage'
import loadUser from 'src/components/SetUser/loadUser.gql'

const UserContext = React.createContext({})

const SetUser = ({ userId, user, children }) => {
  if (userId && !user) return <RedirectToHomePage includingGuest /> // no access to user
  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}

SetUser.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  user: shape({
    id: string.isRequired,
  }),
  userId: string,
}

/**
 * Sets user context for it's children. If no `userId` provided resets context to current user.
 */
const SetUserContainer = graphql(loadUser)(({ data: { loading, user }, children, userId }) => (
  loading ? null : <SetUser userId={userId} user={user}>{children}</SetUser>
))

SetUserContainer.propTypes = {
  userId: string,
}

export {
  UserContext,
}

export default SetUserContainer
