import icons from 'src/lib/menu/icons'
import { ATTENDANCE } from 'src/constants/menu'

const path = '/:domain/:studentId?/attendance/:subjectId?'

const portalPath = '/portal/:parentId/attendance/:studentId?/:subjectId?'

const scene = {
  icon: icons[ATTENDANCE],
  id: ATTENDANCE,
  path,
}

export {
  path, portalPath, scene,
}
