import every from 'lodash/every'
import moveCards from 'src/reducers/lib/moveCards'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import transformValues from 'src/lib/transformValues'
import chunk from 'lodash/chunk'
import zip from 'lodash/zip'

const INIT = 'MatchTranslation/INIT'
const MARK = 'MatchTranslation/MARK'
const MOVE = 'MatchTranslation/MOVE'
const RETRY = 'MatchTranslation/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  pairedPlaces: [],
  places: {},
  selectedEntities: [],
}

const createCards = (a, { id }) => ({
  ...a,
  [`e-${id}`]: { entityId: id, id: `e-${id}`, locale: 'english' },
  [`t-${id}`]: { entityId: id, id: `t-${id}`, locale: 'thai' },
})

const createPlaces = (a, { id }) => ({
  ...a,
  [`e-${id}`]: { id: `e-${id}` },
  [`t-${id}`]: { id: `t-${id}` },
})

const checkCorrectness = (a, pair) => {
  const { cards, places } = a
  const [english, thai] = pair
  const englishPlace = places[english]
  const thaiPlace = places[thai]
  const englisCard = cards[englishPlace.cardId]
  const thaiCard = cards[thaiPlace.cardId]
  const newState = {
    ...a,
    cards: {
      ...cards,
      [englisCard.id]: {
        ...englisCard,
        correct: englisCard.entityId === thaiCard.entityId,
      },
      [thaiCard.id]: {
        ...thaiCard,
        correct: englisCard.entityId === thaiCard.entityId,
      },
    },
  }
  return { ...newState }
}

const splitAndShufflePlacesAndCards = ({ places, cards }) => {
  const placeKeys = Object.keys(places)
  const englishPlaceIds = placeKeys.filter((_, i) => i % 2 === 0)
  const thaiPlaceIds = placeKeys.filter((_, i) => i % 2 !== 0)

  const cardKeys = Object.keys(cards)
  const englishCardIds = cardKeys.filter((_, i) => i % 2 === 0)
  const thaiCardIds = shuffle(cardKeys.filter((_, i) => i % 2 !== 0))

  englishPlaceIds.forEach((englishPlaceId, i) => {
    const englishCardId = englishCardIds[i]
    // eslint-disable-next-line no-param-reassign
    places[englishPlaceId].cardId = englishCardId
    // eslint-disable-next-line no-param-reassign
    cards[englishCardId].placeId = englishPlaceId
  })
  thaiPlaceIds.forEach((thaiPlaceId, i) => {
    const thaiCardId = thaiCardIds[i]
    // eslint-disable-next-line no-param-reassign
    places[thaiPlaceId].cardId = thaiCardId
    // eslint-disable-next-line no-param-reassign
    cards[thaiCardId].placeId = thaiPlaceId
  })
  return {
    englishPlaceIds,
    shuffledCards: cards,
    shuffledPlaces: places,
    thaiPlaceIds,
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const selectedEntities = chunk(uniqShuffle(entities), 5)[0]
      const cards = selectedEntities.reduce(createCards, {})
      const places = selectedEntities.reduce(createPlaces, {})
      const {
        shuffledCards,
        shuffledPlaces,
        englishPlaceIds,
        thaiPlaceIds,
      } = splitAndShufflePlacesAndCards({ cards, places })
      const pairedPlaces = zip(englishPlaceIds, thaiPlaceIds)
      return ({
        ...initialState,
        actualEntities: selectedEntities,
        cards: shuffledCards,
        pairedPlaces,
        places: shuffledPlaces,
        selectedEntities,
      })
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      const newState = {
        cards,
        places,
      }
      return {
        ...state,
        ...newState,
      }
    }

    case MARK: {
      const { cards, places, pairedPlaces } = state
      const { cards: newCards } = reduce(pairedPlaces, checkCorrectness, { cards, places })
      const completed = every(newCards, ['correct', true])
      return {
        ...state,
        cards: {
          ...cards,
          ...newCards,
        },
        completed,
      }
    }

    case RETRY: {
      const { cards } = state
      return {
        ...state,
        cards: transformValues(cards, card => ({ ...card, correct: null })),
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: { entities },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  moveCard,
  retry,
}
export default reducer
