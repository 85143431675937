import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import Welcome from 'src/pages/Welcome'

export const path = '/welcome'

const WelcomeRoute = () => (
  <Switch>
    <Route exact path="/welcome" component={Welcome} />
  </Switch>
)

export default WelcomeRoute
