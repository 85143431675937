import React from 'react'
import { useParams } from 'react-router'
import StudentsContainer from 'src/pages/Students/StudentsContainer'

const StudentLayout = ({ path, domain }) => {
  const { parentId, userId } = useParams()
  return (
    <StudentsContainer
      domain={domain}
      parentId={parentId}
      path={path}
      userId={userId}
    />
  )
}

export default StudentLayout
