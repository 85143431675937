import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { func, number, element, oneOfType, string, arrayOf } from 'prop-types'
import useTimeout from 'src/lib/useTimeout'

const propTypes = {
  children: oneOfType([string, element, arrayOf(element), func]).isRequired,
  delay: number.isRequired,
  to: string.isRequired,
}

const TimedLink = ({ delay, children, to, ...rest }) => {
  const doneWaiting = useTimeout(delay)
  return !!delay && doneWaiting ? (
    <Redirect to={to} />
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
}

TimedLink.propTypes = propTypes

export default TimedLink
