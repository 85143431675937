import React from 'react'
import { string, shape, arrayOf } from 'prop-types'
import entityShape from 'src/shapes/entity'
import EntityCard from 'src/components/EntityCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
  title: string.isRequired,
}

const renderCard = entity => (
  <EntityCard
    key={entity.id}
    english
    entity={entity}
    fit
    size={2}
  />
)

const Placeholder = ({ title, entities }) => (
  <Deck>
    <Div flex justify="center" align="center">
      <h1>{title}</h1>
    </Div>
    <Div flex wrap justify="center">
      {entities.map(renderCard)}
    </Div>
  </Deck>
)

Placeholder.propTypes = propTypes
Placeholder.demoData = {
  title: 'Placeholder',
}

export default Placeholder
