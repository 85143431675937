import chunk from 'lodash/chunk'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import normalizeString from 'src/lib/normalizeString'
import uniqShuffle from 'src/lib/uniqShuffle'
import addSpaceBetweenPunctuation from 'src/lib/addSpaceBetweenPunctuation'

const INIT = 'FillInMissingWordType/INIT'
const CHANGE = 'FillInMissingWordType/CHANGE'
const MARK = 'FillInMissingWordType/MARK'
const RETRY = 'FillInMissingWordType/RETRY'

const initialState = {
  actualEntities: [],
  answer: '',
  completed: null,
  lines: {},
}

const normalizeStringCompare = (a, b) => normalizeString(a) === normalizeString(b)

const createVariantArray = (phrase, word) => (
  addSpaceBetweenPunctuation(phrase)
    .replace(word, word.replace(/\s+/g, ''))
    .split(' ')
    .filter(Boolean)
    .map(x => x.trim())
)

const createCard = (realTitle, wordTitle) => (a, phraseWordTitle, index) => ({
  ...a,
  [`${phraseWordTitle}-${index}`]: {
    answer: normalizeStringCompare(realTitle, phraseWordTitle),
    id: `${phraseWordTitle}-${index}`,
    realTitle: normalizeStringCompare(realTitle, phraseWordTitle) ? wordTitle : phraseWordTitle,
    selected: normalizeStringCompare(realTitle, phraseWordTitle),
    title: normalizeStringCompare(realTitle, phraseWordTitle) ? '...' : phraseWordTitle,
  },
})

const checkAndUpdateCards = answer => (a, card) => ({
  ...a,
  [card.id]: {
    ...card,
    correct: card.selected ? card.realTitle === answer : null,
    selected: null,
    title: card.selected ? card.realTitle : card.title,
  },
})

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT): {
      const { entities } = payload
      const [phrase, word] = uniqShuffle(chunk(entities, 2))[0]
      const wordTitle = word.titleEn
      const cards = createVariantArray(phrase.titleEn, wordTitle)
        .reduce(
          createCard(wordTitle.replace(wordTitle, wordTitle.replace(/\s+/g, '')), wordTitle),
          {},
        )
      return {
        ...initialState,
        actualEntities: [phrase, word],
        cards,
        entitiesById: keyBy(entities, 'id'),
        image: phrase.id,
        realAnswer: phrase,
      }
    }

    case CHANGE: {
      const { answer } = payload
      const newState = { ...state, answer }
      return newState
    }

    case (MARK): {
      const { answer, realAnswer } = state
      const completed = find(state.cards, { answer: true }).realTitle === answer
      const cards = completed ? { [realAnswer.id]: { ...realAnswer, title: realAnswer.titleEn } }
        : reduce(state.cards, checkAndUpdateCards(answer), {})
      return {
        ...state,
        cards,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: { entities },
  type: INIT,
})

const handleChange = answer => ({
  payload: { answer },
  type: CHANGE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  handleChange,
  retry,
}

export default reducer
