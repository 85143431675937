import reject from 'lodash/reject'
import { STUDENT_ROLE } from 'src/constants/roles'
import { scene as lessonsScene } from 'src/pages/LessonsLayout/config'
import { scene as homeworkScene } from 'src/pages/StudentHomeworkPage/config'
import { scene as studentSkillsScene } from 'src/pages/StudentSkillsPage/config'
import { scene as calendarScene } from 'src/pages/CalendarPage/config'
import { scene as studentAttendanceScene } from 'src/pages/StudentAttendance/config'

const studentItems = [
  homeworkScene,
  lessonsScene,
  studentSkillsScene,
  studentAttendanceScene,
  calendarScene,
]

const studentSection = role => (
  (role === STUDENT_ROLE) ? studentItems : reject(studentItems, 'common')
)
export default studentSection
