import chunk from 'lodash/chunk'
import every from 'lodash/every'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import uniqBy from 'lodash/uniqBy'

import moveCards from 'src/reducers/lib/moveCards'
import transformValues from 'src/lib/transformValues'

const INIT_ANSWER = 'ProvideAnswer/INIT_ANSWER'
const INIT_QUESTION = 'ProvideAnswer/INIT_QUESTION'
const MOVE = 'ProvideAnswer/MOVE'
const MARK = 'ProvideAnswer/MARK'
const RETRY = 'ProvideAnswer/RETRY'

const initialState = {
  actualEntities: [],
  answerEntity: {},
  availableCardsId: [],
  cards: {},
  completed: null,
  entitiesById: {},
  places: {},
  questionEntity: {},
  speakerEntity: {},
}

const createCards = (a, entity, index) => ({
  ...a,
  [entity.id]: {
    autoPlay: false,
    correct: null,
    id: entity.id,
    index: index + 1,
    placeId: null,
    titleEn: entity.titleEn,
  },
})

const createPlaces = (a, entity) => {
  const { places } = a
  const newPlace = {
    [entity.id]: {
      cardId: null,
      id: entity.id,
    },
  }

  return {
    ...a,
    places: {
      ...places,
      ...newPlace,
    },
  }
}

const checkCardState = cards => (a, place) => {
  const card = { ...cards[place.cardId] }
  const newCard = place.cardId ? {
    [place.cardId]: {
      ...card,
      correct: { ...card }.id === place.id,
    },
  } : null
  return {
    ...a,
    ...newCard,
  }
}

const findAvailableCards = cards => (
  map(
    map(cards).filter(({ placeId }) => !placeId),
    'id',
  )
)

const extractChoicesForAnswer = (a, entitiesChunk) => (
  [...a, entitiesChunk[1] || {}]
)

const extractChoicesForQuestion = (a, entitiesChunk) => (
  [...a, entitiesChunk[0] || {}]
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_ANSWER: {
      const { entities, speaker } = payload
      const speakerEntity = speaker ? entities[0] || {} : null
      const theEntities = speaker ? entities.slice(1) : entities
      const chunkedEntities = uniqShuffle(chunk(theEntities, 2))
      const [questionEntity, answerEntity] = chunkedEntities[0] || {}
      const choiceEntities = shuffle(uniqBy(chunkedEntities.reduce(extractChoicesForAnswer, []), 'id').slice(0, 3))
      const cards = choiceEntities.reduce(createCards, {}) || {}
      const { places } = [answerEntity].reduce(createPlaces, { cards: {}, places: {} })

      return {
        ...initialState,
        actualEntities: [answerEntity, questionEntity],
        answerEntity,
        availableCardsId: map(cards, 'id'),
        cards,
        choiceEntities,
        entitiesById: keyBy(entities, 'id'),
        places,
        questionEntity,
        speakerEntity,

      }
    }

    case INIT_QUESTION: {
      const { entities, speaker } = payload
      const speakerEntity = speaker ? entities[0] || {} : null
      const theEntities = speaker ? entities.slice(1) : entities
      const chunkedEntities = uniqShuffle(chunk(theEntities, 2))
      const [questionEntity, answerEntity] = chunkedEntities[0] || {}
      const choiceEntities = shuffle(uniqBy(chunkedEntities.reduce(extractChoicesForQuestion, []), 'id').slice(0, 3))
      const cards = choiceEntities.reduce(createCards, {}) || {}
      const { places } = [questionEntity].reduce(createPlaces, { cards: {}, places: {} })

      return {
        ...initialState,
        actualEntities: [questionEntity, answerEntity],
        answerEntity,
        availableCardsId: map(cards, 'id'),
        cards,
        choiceEntities,
        entitiesById: keyBy(entities, 'id'),
        places,
        questionEntity,
        speakerEntity,

      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      const newState = {
        availableCardsId: findAvailableCards(cards),
        cards,
        places,
      }
      return {
        ...state,
        ...newState,
      }
    }

    case MARK: {
      const { cards, places } = state
      const checkedCards = reduce(places, checkCardState(cards), {})
      const completed = every(checkedCards, 'correct') && !!Object.keys(checkedCards).length
      const newState = {
        ...state,
        cards: {
          ...cards,
          ...checkedCards,
        },
        completed,
      }
      return newState
    }

    case RETRY: {
      const { cards } = state
      return {
        ...state,
        cards: transformValues(cards, card => ({ ...card, correct: null })),
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, speaker }) => ({
  payload: {
    entities,
    speaker,
  },
  type: INIT_ANSWER,
})

const initQuestion = ({ entities, speaker }) => ({
  payload: {
    entities,
    speaker,
  },
  type: INIT_QUESTION,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  initialState,
  init,
  initQuestion,
  mark,
  moveCard,
  retry,
}

export default reducer
