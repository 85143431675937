import React from 'react'
import chunk from 'lodash/chunk'
import alphabetIndex from 'src/lib/alphabetIndex'
import Card from 'src/components/Card'
import { array, object, func, number, oneOfType, string } from 'prop-types'
import capitalize from 'lodash/capitalize'
import Div from 'src/components/Div'
import KeyPress from 'src/components/KeyPress'
import EntityCard from 'src/components/EntityCard'
import Flipper from 'src/components/Flipper'
import mapKey from 'src/lib/mapKey'
import KeyLabel from 'src/components/KeyLabel'

const propTypes = {
  cards: object.isRequired,
  lastCardClickedId: oneOfType([string, number]),
  onClick: func.isRequired,
  type: string.isRequired,
  types: array.isRequired,
}

const defaultProps = {
  lastCardClickedId: null,
}


const NUMBER_CARDS_PER_COLUMN = 3

const calculateAlphabet = ({ column, row, cards }) => {
  const numberOfRow = Object.keys(cards).length / NUMBER_CARDS_PER_COLUMN
  return ((numberOfRow * row) + column)
}

const MatchCards = ({
  cards,
  onClick,
  type,
  types,
  lastCardClickedId,
}) => (
  <Div flex wrap MatchCards>
    {
      chunk(Object.keys(cards), NUMBER_CARDS_PER_COLUMN).map((chunkCard, i) => (
        <Div key={mapKey(i)} flex flex-1 column>
          {
            chunkCard.map((id, index) => (
              <Div
                key={id}
                onClick={() => onClick(id)}
              >
                {
                  type === 'flip' ?
                    (
                      <Flipper
                        onClick={() => {}}
                        flipped={!cards[id].state}
                        front={
                          (
                            <EntityCard
                              {...cards[id]}
                              disableSound
                              silent
                              autoPlay={cards[id].state && lastCardClickedId === id}
                              size={2}
                              style={{ height: '11.75rem' }}
                              {...types}
                            />
                          )
                        }
                        back={
                          (
                            <Card
                              button
                              className="Flipper-back-bg"
                              style={{ height: '11.75rem' }}
                              disableSound
                            >
                              <Div
                                align="center"
                                bg="white"
                                bold
                                circle
                                flex
                                justify="center"
                                size={0}
                                style={{ height: '1.5em', width: '1.5em' }}
                              >
                                {capitalize(alphabetIndex(calculateAlphabet({
                                  cards,
                                  column: i,
                                  row: index,
                                })))}
                                <KeyPress keys={{ [alphabetIndex(
                                  calculateAlphabet({
                                    cards,
                                    column: i,
                                    row: index,
                                  }),
                                )]: () => onClick(id) }}
                                />
                              </Div>
                            </Card>
                          )
                        }
                      />
                    )
                    :
                    (
                      <Div>
                        <Div absolute>
                          <KeyLabel label={(index + 1) + (i) + (NUMBER_CARDS_PER_COLUMN * index)} />
                        </Div>
                        <EntityCard
                          {...cards[id]}
                          size={2}
                          button
                          style={{ height: '11.75rem' }}
                          disableSound
                        />
                      </Div>
                    )
                }
              </Div>
            ))
          }
        </Div>
      ))
      }
  </Div>
)

MatchCards.defaultProps = defaultProps
MatchCards.propTypes = propTypes

export default MatchCards
