import { t } from 'i18next'

import { SCHOOL_SETTINGS } from 'src/constants/menu'
import { successMessage } from 'src/lib/toaster'
import tenantSettingsQuery from 'src/queries/tenant_settings.gql'
import tenantSettingsMutation from 'src/mutations/updateTenantSettings.gql'

const { error } = console

export const LOAD_SETTINGS = 'LOAD_SETTINGS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

const initialState = {
  schoolTitle: {
    en: '',
    th: '',
  },
  L21Role: '',
  L21Tenant: '',
}

const showSuccessMessage = () => {
  const title = t(`${SCHOOL_SETTINGS}.title`)
  const message = t('save.success')
  successMessage({ message, title })
}

const loadSettings = client => (dispatch) => {
  client
    .query({ query: tenantSettingsQuery })
    .then(
      res => dispatch({ payload: res.data.tenantSettings, type: LOAD_SETTINGS }),
    )
    .catch(error)
}

const updateSettings = (id, data, client) => dispatch => (
  client
    .mutate({
      mutation: tenantSettingsMutation,
      variables: { data, id },
    })
    .then((res) => {
      dispatch({
        payload: res.data.updateTenantSettings.tenantSettings,
        type: UPDATE_SETTINGS,
      })
      showSuccessMessage()
    })
    .catch(error)
)
const updateSettingsById = (id, data, client) => (dispatch, getState) => dispatch(
  updateSettings(id, data || getState().tenantSettings[id], client),
)

const updateSchoolTitle = (data, client) => updateSettingsById('school_title', data, client)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_SETTINGS:
      // check if we have settings in database, otherwise use default
      if (Object.keys(payload).length > 0) {
        return { ...state, ...payload }
      }
      return state
    case UPDATE_SETTINGS: {
      return { ...state, ...payload }
    }
    default:
      return state
  }
}

export default reducer

export {
  initialState,
  loadSettings,
  updateSettings,
  updateSchoolTitle,
}
