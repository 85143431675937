import { HQ } from 'src/constants/serverRole'
import comingSoonImage from 'src/images/ComingSoonImage.svg'

const normalizeImage = (src) => {
  if (src) return src
  if (HQ) return comingSoonImage
  return undefined
}

export default normalizeImage
