import keyBy from 'lodash/keyBy'
import every from 'lodash/every'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT = 'OrderingSentences/INIT'
const MARK = 'OrderingSentences/MARK'
const RETRY = 'OrderingSentences/RETRY'
const MOVE = 'OrderingSentences/MOVE'

const initialState = {
  actualEntities: [],
  entitiesById: {},
  places: {},
}

const checkGameCompleteness = places => every(places, 'correct')

const selectImageSize = (length) => {
  if (length <= 3) return 3
  if (length > 3 && length <= 5) return 2
  return 1
}

const setPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.cardId === place.entityId,
    },
  }
)

const clearPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.correct ? true : null,
    },
  }
)

const createPlaces = cards => (a, entity, _, entities) => (
  {
    ...a,
    [`place-${size(a)}`]: {
      cardId: cards[size(a)].id,
      entityId: entity.id,
      id: `place-${size(a)}`,
      imageSize: selectImageSize(entities.length),
      label: size(a) + 1,
    },
  }
)

const swapCards = ({ sourceId, targetId, places }) => {
  const newPlace = {
    ...places[targetId],
    cardId: places[sourceId].cardId,
  }
  const oldPlace = {
    ...places[sourceId],
    cardId: places[targetId].cardId,
  }
  return {
    ...places,
    [places[targetId].id]: newPlace,
    [places[sourceId].id]: oldPlace,
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const places = entities.reduce(createPlaces(uniqShuffle(entities)), {})
      return {
        ...initialState,
        actualEntities: entities,
        entitiesById: keyBy(entities, 'id'),
        places,
        placesLength: size(places),
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const newPlaces = swapCards({ places: state.places, sourceId, targetId })
      const places = reduce(newPlaces, clearPlacesStatus, {})
      return {
        ...state,
        places,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    case MARK: {
      const places = reduce(state.places, setPlacesStatus, {})
      return {
        ...state,
        completed: checkGameCompleteness(places),
        places,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, variants }) => ({
  payload: {
    entities,
    variants,
  },
  type: INIT,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

export {
  init,
  retry,
  mark,
  moveCard,
}

export default reducer
