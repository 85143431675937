import React, { useState, useEffect } from 'react'
import Game from 'src/components/Game'
import phonicShape from 'src/shapes/phonic'
import { arrayOf, bool, func, shape } from 'prop-types'
import DrawLetters from 'src/modules/DrawLetters/DrawLetters'
import DrawLetter from 'src/modules/DrawLetters/DrawLetter'

const propTypes = {
  actualEntities: arrayOf(shape(phonicShape).isRequired),
  completed: bool,
  // entity: phonicShape,
  initModule: func.isRequired,
  useSlides: bool,
}
const defaultProps = {
  actualEntities: null,
  completed: null,
  // entity: null,
  useSlides: null,
}

const DrawLettersGame = ({
  actualEntities,
  phonicsAlphabet,
  initModule,
  nextRound,
  endRound,
  completed,
  entities,
  useSlides,
  mark,
  ...rest
}) => {
  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (!initialized && entities) {
      initModule({ entities, phonicsAlphabet })
      setInitialized(true)
    }
  })

  const init = nextRound
  const retry = nextRound

  return (
    initialized && (
      <Game {...{ actualEntities, completed, init, mark, retry }}>
        {useSlides
          ? <DrawLetters {...{ ...rest, actualEntities }} />
          : <DrawLetter {...{ ...rest }} />
        }
      </Game>
    )
  )
}

DrawLettersGame.demoData = {
  entities: [...[...'xyz'].map(l => `phonics-${l}`)],
}
DrawLettersGame.propTypes = propTypes
DrawLettersGame.defaultProps = defaultProps

export default DrawLettersGame
