import { arrayOf, string, shape } from 'prop-types'
import image from 'src/shapes/image'

const entityShape = {
  id: string,
  image: shape(image),
  meaning: shape(),
  meaningEn: string,
  meaningTh: string,
  meta: shape(),
  phonicsEn: arrayOf(shape({
    id: string,
    src: string,
    title: string,
  })),
  phonicsTh: arrayOf(shape({
    id: string,
    src: string,
    title: string,
  })),
  slug: string,
  sound: shape({
    srcEn: string,
    srcTh: string,
  }),
  title: string,
  titleEn: string,
  titleTh: string,
}

export default entityShape
