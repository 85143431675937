import ProvideAnswer, { ProvideAnswerWithSpeaker } from 'src/modules/ProvidePhrase/ProvideAnswer'
import ProvideQuestion, { ProvideQuestionWithSpeaker } from 'src/modules/ProvidePhrase/ProvideQuestion'
import reducer from 'src/modules/ProvidePhrase/reducer'

export {
  ProvideQuestion,
  ProvideAnswerWithSpeaker,
  ProvideQuestionWithSpeaker,
  reducer,
}

export default ProvideAnswer
