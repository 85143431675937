import React from 'react'
import { bool, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as phonicsActions from 'src/modules/Word/Phonics/reducer'
import * as gameAction from 'src/modules/FillInMissingWords/reducer'
import FillInMissingWord from 'src/modules/FillInMissingWords/FillInMissingWord'

const propTypes = {
  ...gamePropTypes,
  hintImage: bool,
  init: func.isRequired,
  silent: bool,
}

const defaultProps = {
  hintImage: true,
  silent: false,
}

class FillInMissingWordsContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities, fixed, unitTest, init } = this.props
    init({ entities, fixed, unitTest })
  }

  render() {
    const {
      actualEntities,
      cards,
      completed,
      entitiesById,
      hintImage,
      line,
      mark,
      moveCard,
      phonics,
      places,
      placeTarget,
      playPhonics,
      retry,
      silent,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <FillInMissingWord {...{
          cards,
          completed,
          entitiesById,
          hintImage,
          line,
          moveCard,
          multiplePlaces: true,
          phonics,
          places,
          placeTarget,
          playPhonics,
          silent,
        }}
        />
      </Game>
    )
  }
}

const selector = state => state.fillInMissingWords
const actions = dispatch => bindActionCreators({ ...phonicsActions, ...gameAction }, dispatch)

FillInMissingWordsContainer.defaultProps = defaultProps
FillInMissingWordsContainer.propTypes = propTypes

const FillInMissingWordsNoImage = props => (
  <FillInMissingWordsContainer hintImage={false} {...props} />)

const FillInMissingWordsNoImageContainer =
  connect(selector, actions)(FillInMissingWordsNoImage)

const FillInMissingWordsNoImageFixed = props => (
  <FillInMissingWordsContainer {...props} fixed hintImage={false} />)

const FillInMissingWordsNoImageFixedContainer =
  connect(selector, actions)(FillInMissingWordsNoImageFixed)

FillInMissingWordsContainer.demoData = {
  entities: [
    'phrase-she-is-my-elder-sister',
    'is',
    'elder-sister',
    'phrase-last-monday-i-ate-mangoes',
    'monday',
    'mangoes',
    'phrase-last-sunday-i-ate-lettuces',
    'sunday',
    'lettuces',
  ],
}
FillInMissingWordsNoImageContainer.demoData = {
  entities: [
    'phrase-she-is-my-elder-sister',
    'is',
    'elder-sister',
    'phrase-last-monday-i-ate-mangoes',
    'monday',
    'mangoes',
    'phrase-last-sunday-i-ate-lettuces',
    'sunday',
    'lettuces',
  ],
}
FillInMissingWordsNoImageFixedContainer.demoData = {
  entities: [
    'phrase-i-am',
    'am',
    'are',
    'is',
    'phrase-we-are-parents',
    'are',
    'is',
    'does',
  ],
}

export default connect(selector, actions)(FillInMissingWordsContainer)
export {
  FillInMissingWordsContainer,
  FillInMissingWordsNoImage,
  FillInMissingWordsNoImageContainer,
  FillInMissingWordsNoImageFixed,
  FillInMissingWordsNoImageFixedContainer,
}
