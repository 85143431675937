import { string } from 'prop-types'
import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import HomeworkReport from 'src/pages/HomeworkPage/HomeworkReport'
import homeworkReportQuery from 'src/pages/HomeworkPage/sectionsYearsHomework.gql'

const HomeworkReportContainer = gqlLoader(homeworkReportQuery, networkOnlyConfig)(HomeworkReport)

HomeworkReportContainer.propTypes = {
  domain: string.isRequired,
  subjectId: string,
}

export default HomeworkReportContainer
