import React from 'react'
import { array, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  entities: array.isRequired,
  initDefault: func.isRequired,
}

const MultipleChoiceThaiContainer = props => (
  <MultipleChoiceCore
    init={() => props.initDefault({ entities: props.entities })}
    {...props}
  />
)

MultipleChoiceThaiContainer.demoData = {
  entities: [
    'phrase-who-is-she',
    'phrase-she-is-mary',
    'phrase-who-is-he',
    'phrase-he-is-tom',
    'phrase-who-is-he',
    'phrase-he-is-my-elder-brother',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

MultipleChoiceThaiContainer.propTypes = propTypes

export { MultipleChoiceThaiContainer }
export default connect(select, actions)(MultipleChoiceThaiContainer)
