import map from 'lodash/map'
import React from 'react'
import { array, shape, func } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Card from 'src/components/Card'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import KeyLabel from 'src/components/KeyLabel'
import Place from 'src/components/Place'

const propTypes = {
  availableCards: array.isRequired,
  cards: shape().isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderCard = (entity, correct, isInPlace) => (
  <Div flex auto={isInPlace} key={entity.id} col={isInPlace ? 12 : 3} m>
    <AdaptiveCard
      audio
      correct={correct}
      draggable
      english
      entity={entity}
      height={5}
      id={entity.id}
      key={entity.id}
      minHeight={false}
      p={1}
    />
  </Div>
)

const renderImage = ({ id, cards, index }) => {
  const entity = cards[id]
  return (
    <Div flex column auto>
      <Div flex auto>
        <EntityCard
          english
          entity={entity}
          image
          keyToPlay={index + 1}
        />
      </Div>
      <KeyLabel bold color="black" label={index + 1} size={1} />
    </Div>
  )
}

const renderPlace = ({ id, cards, index, isImage, cardId, correct, moveCard }) => {
  const children = cards[cardId]
  return (
    <Place
      id={id}
      key={id}
      onDrop={moveCard}
    >
      {
        isImage ?
          renderImage({ cards, id, index })
          : (
            <Div flex column auto>
              {
              children
                ?
                renderCard(children, correct, true)
                : <Card size={2} minHeight={false} title="..." />
            }
            </Div>
          )}
    </Place>
  )
}

const MatchTextWithImage = ({ availableCards, cards, places, moveCard }) => (
  <Deck>
    <Div flex justify="center" gutter={1}>
      {availableCards.map(id => renderCard(cards[id]))}
    </Div>
    <Div flex auto gutter={1}>
      {
        map(places).map(
          ({ id }, index) => renderPlace({
            cards,
            id,
            index,
            isImage: true,
            moveCard,
          }),
        )
      }
    </Div>
    <Div flex gutter={1}>
      {
        map(places).map(
          ({ id, cardId, correct }) => renderPlace({
            cardId,
            cards,
            correct,
            id,
            moveCard,
          }),
        )
      }
    </Div>
  </Deck>
)

MatchTextWithImage.propTypes = propTypes
export default MatchTextWithImage
