import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import URLSearchParams from '@ungap/url-search-params'
import usePageParams from 'src/lib/usePageParams'
import Login from 'src/pages/Login/index'
import loginByToken from 'src/lib/loginByToken'

export const path = '/login'

const LoginRoute = () => {
  const history = useHistory()
  const location = useLocation()
  const params = usePageParams()
  const urlParams = new URLSearchParams(location.search)
  const routeProps = { history, location, params }
  if (urlParams.has('token')) {
    loginByToken(urlParams.get('token'), { resetStore: false })
    history.replace(path)
    return null
  }

  return (
    <Switch>
      <Route exact path="/login">
        <Login {...routeProps} />
      </Route>
    </Switch>
  )
}

export default LoginRoute
