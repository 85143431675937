import React, { Component } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { func, number, string } from 'prop-types'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'
import * as components from 'src/modules'
import { homeworkIteration } from 'src/lib/homework.js'

const propTypes = {
  iteration: number.isRequired,
  linkToHome: string.isRequired,
  moduleId: string,
  onChange: func.isRequired,
}

const defaultProps = {
  moduleId: undefined,
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: `${props.moduleId} x${props.iteration}`,
    }
  }

  handleInputChange = (inputValue) => {
    const { moduleId, iteration } = this.props
    this.setState({
      inputValue,
      value: `${moduleId} x${iteration}`,
    })
  }

  render() {
    const { onChange, linkToHome } = this.props
    const { inputValue, value } = this.state
    return (
      <Div flex wrap ap1 col={12} style={{ fontSize: 32 }}>
        <Link
          className="button-hover blue bg-white rounded apx1 amr1 flex align-center center"
          to={linkToHome}
        >
          <Icon icon="home" style={{ fontSize: '36px' }} />
        </Link>
        <Div fx={1} relative z4>
          <Select
            className="ModuleDemoSelect"
            style={{ height: 'auto' }}
            value={value}
            inputValue={inputValue}
            onChange={onChange}
            onInputChange={this.handleInputChange}
            placeholder={t('moduleGallery.selectOne')}
            options={Object.keys(components).sort().map(id => ({
              className: 'h5',
              label: `${id} x${homeworkIteration({ module: id })}`,
              value: id,
            }))}
          />
        </Div>
      </Div>
    )
  }
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
