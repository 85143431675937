import React, { Fragment } from 'react'
import { func, element, arrayOf, shape, string } from 'prop-types'
import usePageParams from 'src/lib/usePageParams'
import AcademicYearFilter from 'src/components/ReportLayout/AcademicYearFilter'
import academicYearShape from 'src/shapes/academicYear'
import academicYearsQuery from 'src/queries/academicYearsQuery.gql'
import gqlLoader from 'src/lib/gqlLoader'

const propTypes = {
  academicYearId: string,
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  children: element.isRequired,
  onParamsChange: func,
}

const defaultProps = {
  academicYearId: null,
  onParamsChange: () => {},
}

const ReportLayout = ({
  academicYearId: academicYearIdProps,
  academicYears,
  children,
  onParamsChange,
  ...rest
}) => {
  const params = usePageParams()
  const { academicTermId, academicYearId } = params
  const theAcademicYearId = academicYearId || academicYearIdProps
  const displayYear = theAcademicYearId
    ? academicYears.find(({ id, slug }) => [id, slug].includes(theAcademicYearId))
    : academicYears.find(({ academicTerms }) => (
      academicTerms.find(({ id, slug }) => [id, slug].includes(academicTermId))
    )) || academicYears.find(({ current }) => current)
  if (!displayYear) return null

  const reportParams = { academicTermId, academicYearId: displayYear.slug || displayYear.id, ...params }
  const changeParams = change => (
    onParamsChange({
      ...change,
      academicTermId: change.academicYearId === reportParams.academicYearId
        ? change.academicTermId || reportParams.academicTermId
        : null,
    })
  )
  if (![displayYear.id, displayYear.slug].includes(theAcademicYearId)) {
    changeParams(reportParams)
    return null
  }

  return (
    <Fragment>
      <AcademicYearFilter
        {...reportParams}
        academicYears={academicYears}
        onChange={changeParams}
      />
      {React.cloneElement(children, {
        ...reportParams,
        ...rest,
      })}
    </Fragment>
  )
}

ReportLayout.propTypes = propTypes
ReportLayout.defaultProps = defaultProps

export default gqlLoader(academicYearsQuery)(ReportLayout)
