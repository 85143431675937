import React from 'react'
import { bool, func, object, shape, string, objectOf, oneOfType, number } from 'prop-types'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import { ENGLISH } from 'src/constants/language'
import ImageChoices from 'src/modules/UnitTestMultipleChoice/ImageChoices'
import TextChoices from 'src/modules/UnitTestMultipleChoice/TextChoices'
import MultipleChoiceImageCard from 'src/modules/UnitTestMultipleChoice/MultipleChoiceImageCard'

const propTypes = {
  cards: objectOf(
    shape({
      id: string.isRequired,
      label: number.isRequired,
      src: string,
      status: oneOfType([bool, string]),
    }).isRequired,
  ).isRequired,
  entitiesById: object.isRequired,
  imageChoices: bool,
  questionId: string,
  selectCard: func.isRequired,
}

const defaultProps = {
  questionId: '',
  imageChoices: false,
  started: false,
}

const entityHasImage = entity => !!({ ...entity }.image && entity.image.src)
const entityHasSound = entity => !!({ ...entity }.sound && entity.sound.srcEn)

const UnitTestMultipleChoice = ({
  cards,
  entitiesById,
  imageChoices,
  questionId,
  selectCard,
}) => {
  const questionEntity = entitiesById[questionId]
  const showImage = entityHasImage(questionEntity)
  const showPlayImage = entityHasSound(questionEntity) && !showImage
  return (
    <Deck className="UnitTestMultipleChoice">
      <Div flex auto px1>
        {
          (showImage || showPlayImage) && (
            <Div flex column col={6} auto>
              <MultipleChoiceImageCard
                imageEntity={questionEntity}
                showImage={showImage}
                showPlayImage={showPlayImage}
              />
            </Div>
          )
        }
        <Div flex justify="center" col={6} column auto>
          {
            questionId && (
              <Div flex justify="center" className={`flex-col-${imageChoices ? 4 : 6}`}>
                <AdaptiveCard
                  auto
                  bold
                  english={ENGLISH}
                  entity={entitiesById[questionId]}
                  justify="start"
                  key={entitiesById[questionId].id}
                  markdown
                  min={0.1}
                  minHeight={false}
                  p={1}
                  reduceFontSize={0.95}
                  style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                  textAlign="left"
                />
              </Div>
            )
          }
          {
            imageChoices
              ? (
                <ImageChoices cards={cards} entitiesById={entitiesById} selectCard={selectCard} />
              )
              : (
                <TextChoices cards={cards} entitiesById={entitiesById} selectCard={selectCard} />
              )
          }
        </Div>
      </Div>
    </Deck>
  )
}

UnitTestMultipleChoice.propTypes = propTypes
UnitTestMultipleChoice.defaultProps = defaultProps

export default UnitTestMultipleChoice
