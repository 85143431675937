import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import Access from 'src/components/Access'
import Div from 'src/components/Div'
import Img from 'src/components/Img'
import Widget from 'src/components/Widget'
import LessonsPageSectionGrade from 'src/pages/LessonsPage/Section/Grade'
import LessonsPageLessons from 'src/pages/LessonsPage/Lessons'
import LessonsPageSectionYears from 'src/pages/LessonsPage/Section/Years'
import kindergarten from 'src/pages/LessonsPage/Section/kindergarten.svg'
import primary from 'src/pages/LessonsPage/Section/primary.svg'
import secondary from 'src/pages/LessonsPage/Section/secondary.svg'
import gradeShape from 'src/shapes/grade'

const propTypes = {
  grades: arrayOf(shape(gradeShape)).isRequired,
  gradeSlug: string,
  id: string.isRequired,
  title: string.isRequired,
  yearSlug: string,
}

const defaultProps = {
  gradeSlug: undefined,
  yearSlug: undefined,
}

const IMAGES = {
  kindergarten,
  primary,
  secondary,
}

const LessonsPageSection = ({
  id,
  grades,
  gradeSlug,
  yearSlug,
  title,
}) => {
  const selectedGrade = grades.find(grade => grade.slug === gradeSlug)
  const selectedYear = selectedGrade && selectedGrade.years.find(year => year.slug === yearSlug)
  return (
    <Div mb={1} relative shadow>
      <Widget title={title} icon="child">
        <Div flex>
          <Div className="qa-grades" flex col={10} wrap mb={1}>
            {
              grades.map(grade => (
                <LessonsPageSectionGrade
                  key={grade.id}
                  {...grade}
                  gradeSlug={gradeSlug}
                />
              ))
            }
          </Div>
          <Div col-3 sm-col-2 relative mtn4>
            <Div flex absolute left-0 right-0>
              <Img className="mtn1" src={IMAGES[id]} alt={title} />
              <Div style={{ paddingTop: '100%' }} />
            </Div>
          </Div>
        </Div>
        {
          selectedGrade && (
            <Access teacher staff>
              <LessonsPageSectionYears
                gradeSlug={gradeSlug}
                years={selectedGrade.years}
                yearSlug={yearSlug}
              />
            </Access>
          )
        }
        <Div mb={3}>
          {
            selectedGrade &&
            <LessonsPageLessons gradeId={selectedGrade.id} selectedYear={selectedYear} />
          }
        </Div>
      </Widget>
    </Div>
  )
}

LessonsPageSection.propTypes = propTypes
LessonsPageSection.defaultProps = defaultProps

export default LessonsPageSection
