import React from 'react'
import { string } from 'prop-types'
import { Mutation } from '@apollo/react-components'

import UpdateAvatar from 'src/pages/User/shared/UpdateAvatar/Scene'
import updateAvatarMutation from 'src/pages/User/shared/UpdateAvatar/updateAvatarMutation.gql'
import userProfileQuery from 'src/pages/User/Edit/user.gql'

const propTypes = {
  userId: string.isRequired,
}
const UpdateAvatarContainer = ({ userId }) => (
  <Mutation
    mutation={updateAvatarMutation}
    update={
      (cache, { data: { userUpdateAvatar } }) => {
        const variables = { userId }
        const { user } = cache.readQuery({ query: userProfileQuery, variables })
        cache.writeQuery({
          data: {
            user: {
              ...user,
              imageUrl: userUpdateAvatar.imageUrl,
            },
          },
          query: userProfileQuery,
          variables,
        })
      }
    }
  >
    {
      updateAvatar => (
        <UpdateAvatar userId={userId} updateAvatar={updateAvatar} />
      )
    }
  </Mutation>
)

UpdateAvatarContainer.propTypes = propTypes

export default UpdateAvatarContainer
