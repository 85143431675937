import React from 'react'
import { number, string } from 'prop-types'
import Badge from 'src/components/Badge'

const propTypes = {
  present: number.isRequired,
  title: string.isRequired,
  total: number.isRequired,
}

const Stats = ({
  present,
  total,
  title,
}) => {
  const absent = total - present
  return (
    <div>
      <div className="bold">{title}</div>
      <div>
        <span className="bold bigger">{present}</span>
        <span className="muted"> / {total}</span>
        {absent > 0 && <Badge color="green">{absent}</Badge>}
      </div>
    </div>
  )
}
Stats.propTypes = propTypes
export default Stats
