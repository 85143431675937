import { number, string } from 'prop-types'

const yearShape = {
  gradeId: string,
  id: string,
  present: number,
  sectionId: string,
  slug: string,
  title: string,
  total: number,
}

export default yearShape
