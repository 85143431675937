import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'

const path = '/:domain/:parentId'

const scene = {
  exact: true,
  icon: icons[menu.USERS],
  id: menu.MY_FAMILY,
  path,
}

export {
  path, scene,
}
