import React from 'react'
import { func, object, array, string } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import Bubble from 'src/components/Bubble'
import Deck from 'src/components/Layout/Deck'
import EntityBubble from 'src/components/EntityBubble'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  paragraphEntities: array.isRequired,
  questionId: string.isRequired,
  selectCard: func.isRequired,
}

const MultipleChoiceMultipleParagraphs = ({
  cards,
  entitiesById,
  paragraphEntities,
  questionId,
  selectCard,
}) => (
  <Deck>
    {
      paragraphEntities.length > 0 && (
        <Div flex justify-center mb2 auto>
          {
           paragraphEntities.map(entityId => (
             <Div key={entityId} flex flex-1>
               <AdaptiveCard
                 align="left"
                 minHeight={false}
                 p={1}
                 entity={entitiesById[entityId]}
                 english
                 meaning
               />
             </Div>
           ))
          }
        </Div>
      )
    }
    {
      questionId && (
        <Div flex justify="center" mb3>
          <EntityBubble
            audio
            bold
            english
            entity={entitiesById[questionId]}
            key={entitiesById[questionId].id}
            label=" "
            size={2}
            minHeight={8}
          />
        </Div>
      )
    }
    <Div flex justify="center">
      {
        map(cards, card => (
          <Div key={card.key} flex column px={1} flex-1 pb={3}>
            <Bubble
              label={card.label}
              onKeyPress={() => selectCard({ cardId: card.id })}
            >
              <AdaptiveCard
                audio
                auto
                bold
                correct={card.correct}
                english
                entity={entitiesById[card.id]}
                id={card.id}
                height={4}
                keyToPlay={card.label}
                onClick={() => selectCard({ cardId: card.id })}
                selected={card.selected}
              />
            </Bubble>
          </Div>
        ))
      }
    </Div>
  </Deck>
)

MultipleChoiceMultipleParagraphs.propTypes = propTypes

export default MultipleChoiceMultipleParagraphs
