import React, { Component } from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import URLSearchParams from '@ungap/url-search-params'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import { t } from 'i18next'
import ReactTable from 'react-table'
import * as menu from 'src/constants/menu'
import { PARENTS, STAFF, STUDENTS, TEACHERS } from 'src/constants/userScopes'
import Icon from 'src/components/Icon'
import Widget from 'src/components/Widget'
import LinkToNewUser from 'src/pages/Users/LinkToNewUser'
import FamilyRelationshipCell from 'src/pages/Users/components/FamilyRelationshipCell'
import UserLinkCell from 'src/pages/Users/components/UserLinkCell'
import RolesCell from 'src/pages/Users/components/RolesCell'
import { AssignYear } from 'src/pages/Users/SelectionActions'
import { SelectionSupport, SelectionActions, SelectionCheckbox, CancelSelection, ToggleSelection } from 'src/components/Selection'
import { SelectionCheckboxLabel } from 'src/components/Selection/SelectionCheckbox'
import Div from 'src/components/Div'

const entityType = shape({
  id: string.isRequired,
  title: string.isRequired,
})

const propTypes = {
  domain: string,
  filter: string,
  onFetchData: func.isRequired,
  pageSize: number,
  roles: arrayOf(entityType).isRequired,
  scope: string,
  sections: arrayOf(entityType).isRequired,
  years: arrayOf(entityType).isRequired,
}

const defaultProps = {
  domain: undefined,
  filter: '',
  pageSize: 20,
  scope: '',
}

const DEFAULT_SORT_COLUMN = 'full_name'
const DEFAULT_STUDENT_SORT_COLUMN = 'year'

const createDropdown = ({ filter, onChange, records }) => (
  <select
    onChange={event => onChange(event.target.value)}
    value={filter && filter.value}
  >
    <option />
    {
      records.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))
    }
  </select>
)

const userTitleId = ({ filter, scope }) => {
  switch (scope) {
    case STUDENTS: {
      return filter === 'my' ? menu.MY_STUDENTS : menu.ALL_STUDENTS
    }
    case PARENTS: {
      return menu.PARENTS
    }
    case TEACHERS: {
      return menu.TEACHERS
    }
    case STAFF: {
      return menu.STAFF
    }
    default: {
      return menu.USERS
    }
  }
}

const matchById = expected => ({ id }) => id === expected
const matchRoles = matchById('roles')

const filteredByStudentsOnly = (filtered) => {
  const matchByValue = expected => ({ value }) => value === expected
  const matchStudent = matchByValue('student')
  const rolesToFilterIn = filtered.filter(matchRoles)

  return (rolesToFilterIn.length === 1)
    ? !!rolesToFilterIn.find(matchStudent)
    : false
}

export class Users extends Component {
  constructor(props) {
    super(props)

    const { pageSize, scope, domain } = this.props
    const isStudentView = scope === STUDENTS
    const sortColumn = isStudentView
      ? DEFAULT_STUDENT_SORT_COLUMN
      : DEFAULT_SORT_COLUMN
    this.state = {
      data: [],
      domain,
      filtered: {},
      loading: false,
      offset: 0,
      pageSize,
      pages: 1,
      sorted: { desc: false, id: sortColumn },
      totalCount: 0,
    }
  }

  componentDidMount() {
    const { location } = window
    const { search } = location
    const params = new URLSearchParams(search)
    const columns = this.updateFilter(params)
    this.setState({ filtered: { columns } })
  }

  componentDidUpdate(prevProps) {
    const { scope } = this.props
    if (scope !== prevProps.scope) this.handleFetchData(this.state)
  }

  setParams = (columns) => {
    const { sections, years } = this.props
    const searchParams = new URLSearchParams()
    columns.forEach(({ id, value }) => {
      if (id === 'year') {
        searchParams.set(id, find(years, { id: value }).slug)
      } else if (id === 'section') {
        searchParams.set(id, find(sections, { id: value }).slug)
      } else {
        searchParams.set(id, value)
      }
    })
    return searchParams.toString()
  }

  updateFilter = params => (
    compact([
      ...['identifier', 'roles', 'full_name', 'email']
        .map(param => params.has(param) && { id: param, value: params.get(param) }),
      params.has('section') && {
        id: 'section', value: find(this.props.sections, { slug: params.get('section') }).id,
      },
      params.has('year') && {
        id: 'year', value: find(this.props.years, { slug: params.get('year') }).id,
      },
    ])
  )

  relationshipFilterFormatter = ({ filter, onChange }) => {
    const parentMsg = t('shared.parent')
    return createDropdown({
      filter,
      onChange,
      records: [{ id: true, title: parentMsg }],
    })
  }

  sectionFilterFormatter = ({ filter, onChange }) => createDropdown(
    { filter, onChange, records: this.props.sections },
  )

  yearFilterFormatter = ({ filter, onChange }) => createDropdown(
    { filter, onChange, records: this.props.years },
  )

  roleFilterFormatter = roles => ({ filter, onChange }) => createDropdown(
    { filter, onChange, records: roles },
  )

  handleFetchData = debounce(async (state) => {
    const { filter, onFetchData, scope, domain, parentId } = this.props
    const { filtered, offset, sorted } = this.state
    const { pageSize } = state
    await this.setState({ loading: true })

    const options = {
      fetchPolicy: 'network-only',
      variables: {
        domain,
        filter,
        limit: pageSize,
        offset,
        orderBy: sorted,
        parentId,
        scope,
        textFilter: filtered,
      },
    }

    const {
      data: { usersSearch },
    } = await onFetchData({ options })
    const totalCount = usersSearch.total_count
    const pageCount = Math.ceil(totalCount / pageSize)

    await this.setState({
      data: usersSearch.rows,
      loading: false,
      pages: pageCount,
      totalCount,
    })
  }, 200)

  handleFilteredChange = async (columns) => {
    const { history } = this.props
    const url = this.setParams(columns)
    await this.setState({ filtered: { columns } })
    history.push(`?${url}`)
  }

  handlePageChange = async (pageIndex) => {
    const { pageSize } = this.state
    await this.setState({
      offset: pageIndex * pageSize,
    })
  }

  handlePageSizeChange = async (pageSize) => {
    await this.setState({
      offset: 0,
      pageSize,
    })
  }

  handleSortedChange = async (newSorted) => {
    const { id, desc } = newSorted[0]
    await this.setState({
      offset: 0,
      sorted: { desc, id },
    })
  }

  createPageTitle = ({ id, filtered: columns }) => {
    const { sections, years, roles: allRoles } = this.props
    if (columns.length > 0) {
      const {
        identifier,
        full_name,
        email,
        section,
        year,
        roles,
        parent,
        ...rest
      } = keyBy(columns, 'id')
      const items = [
        ...compact([identifier, full_name, email]).map(c => c.value),
        section && find(sections, { id: section.value }).title,
        year && find(years, { id: year.value }).title,
        roles && find(allRoles, { id: roles.value }).title,
        parent && parent.value ? t('shared.parent') : null,
        ...Object.values(rest).map(({ id, value }) => `${id}: ${value}`),
      ]
      return compact(items).join(', ')
    }
    return t(`${id}.title`)
  }

  render() {
    const { filter, scope, domain, roles } = this.props
    const { sections, years } = this.props
    const {
      data, pages, loading, pageSize, totalCount, filtered: { columns: filtered = [] },
    } = this.state


    const id = userTitleId({ filter, scope })
    const pageTitle = this.createPageTitle({ filtered, id })
    const isSchoolDomain = domain === 'school'
    const isStudentView = filteredByStudentsOnly(filtered) || (scope === STUDENTS && !filtered.some(matchRoles))
    const isFamilyView = domain === 'portal'
    const showPagination = !isFamilyView

    const columns = [
      {
        Cell: SelectionCheckbox,
        Header: '',
        accessor: 'identifier',
        maxWidth: 25,
        sortable: false,
        filterable: false,
        show: isStudentView && isSchoolDomain,
      },
      {
        Cell: SelectionCheckboxLabel,
        Header: 'Identifier',
        accessor: 'identifier',
        maxWidth: 150,
        sortable: false,
      },
      {
        Cell: UserLinkCell,
        Header: t('user.name'),
        accessor: 'full_name',
      },
      {
        Cell: props => <RolesCell roles={roles} {...props} />,
        Filter: this.roleFilterFormatter(roles),
        Header: t('user.role'),
        accessor: 'roles',
        show: !isFamilyView,
      },
      {
        Header: t('user.email'),
        accessor: 'email',
      },
      {
        Cell: FamilyRelationshipCell,
        Filter: this.relationshipFilterFormatter,
        Header: t('user.relationship'),
        accessor: 'parent',
        show: isFamilyView,
      },
      {
        Filter: this.sectionFilterFormatter,
        Header: t('user.section'),
        accessor: 'section',
      },
      {
        Filter: this.yearFilterFormatter,
        Header: t('user.year'),
        accessor: 'year',
      },
    ]

    const sortColumn = isStudentView
      ? DEFAULT_STUDENT_SORT_COLUMN
      : DEFAULT_SORT_COLUMN
    const defaultSorted = [{ id: sortColumn }]
    const refresh = () => this.handleFetchData(this.state)

    return (
      <Widget icon="users" title={`${pageTitle}: ${totalCount}`}>
        <SelectionSupport>
          <Div flex flex-row>
            <LinkToNewUser mr1 />
            { isStudentView && (
              <SelectionActions>
                <AssignYear {...{ sections, years }} onSuccess={refresh} clearOnSuccess />
                <CancelSelection />
              </SelectionActions>
            )}
          </Div>
          { isStudentView && (
            <Div flex flex-row mt1>
              <SelectionActions>
                <ToggleSelection selection={data ? data.map(item => item.identifier) : []} />
              </SelectionActions>
            </Div>
          )}
          <ReactTable
            className="qa-users-table -striped -highlight"
            columns={columns}
            data={data}
            defaultPageSize={pageSize}
            defaultSorted={defaultSorted}
            filterable
            filtered={filtered}
            loading={loading}
            loadingText=""
            manual
            minRows={1}
            nextText={<span>{t('direction.next')}<Icon icon="arrow-right" after /></span>}
            onFetchData={this.handleFetchData}
            onFilteredChange={this.handleFilteredChange}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            onSortedChange={this.handleSortedChange}
            pages={pages}
            previousText={<span><Icon icon="arrow-left" before /> {t('direction.previous')}</span>}
            showPagination={showPagination}
            showPaginationTop={showPagination}
          />
        </SelectionSupport>
        <LinkToNewUser />
      </Widget>
    )
  }
}

Users.propTypes = propTypes
Users.defaultProps = defaultProps

export default Users
