import React from 'react'
import { arrayOf, bool, oneOfType, string } from 'prop-types'
import { Route } from 'react-router-dom'
import Tab from 'src/components/Tab'
import pathShape from 'src/shapes/path'

const propTypes = {
  exact: bool,
  match: oneOfType([arrayOf(string), string]),
  to: pathShape.isRequired,
}

const defaultProps = {
  exact: false,
  match: undefined,
}

const NavTab = ({ to, match: matchPaths, exact, ...rest }) => (
  <Route path={(matchPaths || [to]).map(path => path.pathname || path)} exact={exact}>
    {({ match }) => <Tab path={to} selected={!!match} {...rest} />}
  </Route>
)

NavTab.propTypes = propTypes
NavTab.defaultProps = defaultProps

export default NavTab
