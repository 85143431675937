import query from 'src/queries/lesson/Objectives.gql'
import gqlLoader from 'src/lib/gqlLoader'
import Objectives from 'src/modules/Objectives/Objectives'

const ObjectivesContainer = gqlLoader(query)(Objectives)
ObjectivesContainer.demoData = {
  lessonSlug: 'p3f1l2',
}

export default ObjectivesContainer
