import React, { Component } from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/FollowTheInstructions/reducer'
import FollowTheInstructions from 'src/modules/FollowTheInstructions/FollowTheInstructions'

const propTypes = {
  ...gamePropTypes,
}

class FollowTheInstructionsContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const { mark, retry, completed, cards, entitiesById,
      actualEntities, questionId, onClick } = this.props
    return (
      <Game
        {...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <FollowTheInstructions
          {...{ cards, entitiesById, onClick, questionId }}
        />
      </Game>
    )
  }
}

FollowTheInstructionsContainer.propTypes = propTypes
FollowTheInstructionsContainer.demoData = {
  entities: [
    'collection-fruits',
    'banana',
    'mango',
    'orange',
    'pear',
    'pineapple',
    'collection-vegetables',
    'apple',
    'cabbage',
    'carrot',
    'lettuce',
    'radish',
  ],
}
const select = state => state.followTheInstructions
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { FollowTheInstructionsContainer }
export default connect(select, actions)(FollowTheInstructionsContainer)
