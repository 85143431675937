import React from 'react'
import { shape } from 'prop-types'
import EntityImage from 'src/components/EntityImage'
import TextFit from 'src/components/TextFit/TextFit'
import entityShape from 'src/shapes/entity'

const propTypes = {
  entity: shape(entityShape).isRequired,
}

const ImageOrPlaceholder = ({ entity, ...rest }) => (
  (entity.image || {}).src
    ? <EntityImage entity={entity} {...rest} />
    : (
      <TextFit
        style={{ color: 'gray' }}
        className="print-hide"
        reduceFontSize={0.4}
      >
        {entity.titleEn}
      </TextFit>
    )
)

ImageOrPlaceholder.propTypes = propTypes

export default ImageOrPlaceholder
