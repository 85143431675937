import React, { Component } from 'react'
import { arrayOf, number, func, shape } from 'prop-types'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Game from 'src/components/Game'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import KeyPress from 'src/components/KeyPress'
import * as matchCardsAction from 'src/modules/MatchCards/reducer.js'
import entityShape from 'src/shapes/entity'
import ActionCard from 'src/modules/FindTheMissingImage/ActionCard'

const propTypes = {
  entities: arrayOf(shape(entityShape)),
  numberImage: number,
  updateCardIteration: func.isRequired,
}

const defaultProps = {
  entities: [],
  numberImage: 4,
}

const ITERATION_LENGTH = 3

class FindTheMissingImage extends Component {
  constructor(props) {
    super(props)
    const { entities: propsEntities, numberImage, updateCardIteration } = this.props
    const entities = uniqShuffle(propsEntities).slice(0, numberImage)
    updateCardIteration({ length: ITERATION_LENGTH })
    this.state = {
      action: 0,
      entities,
      target: shuffle(entities)[0],
    }
  }

  handleAction() {
    const { action } = this.state
    const { updateCardIteration } = this.props
    if (action < 3) {
      const newAction = action + 1
      updateCardIteration({ action: newAction, length: ITERATION_LENGTH })
      this.setState({ action: newAction })
    } else {
      this.newRound()
    }
  }

  newRound() {
    const { entities: propsEntities, numberImage, updateCardIteration } = this.props
    const entities = uniqShuffle(propsEntities).slice(0, numberImage)
    updateCardIteration({ length: ITERATION_LENGTH })
    this.setState({
      action: 0,
      entities,
      target: shuffle(entities)[0],
    })
  }

  render() {
    const { action, entities, target } = this.state
    return (
      <Game>
        <Deck>
          <Div flex wrap auto>
            {
              entities.map(entity => (
                <ActionCard key={entity.id} action={action} entity={entity} target={target} />
              ))
            }
          </Div>
          <KeyPress keys={{ Enter: () => this.handleAction() }} />
        </Deck>
      </Game>
    )
  }
}

FindTheMissingImage.defaultProps = defaultProps
FindTheMissingImage.propTypes = propTypes
FindTheMissingImage.demoData = {
  entities: [
    'family',
    'elder-brother',
    'elder-sister',
    'father',
    'mother',
    'mango',
    'banana',
    'skate',
    'sleep',
    'happy',
    'doctor',
  ],
}

const actions = dispatch => (
  bindActionCreators(matchCardsAction, dispatch)
)

export { FindTheMissingImage }
export default connect(null, actions)(FindTheMissingImage)
