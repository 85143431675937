import React from 'react'
import {
  arrayOf,
  element,
  bool,
  object,
  shape,
  oneOfType,
  func,
} from 'prop-types'
import { Redirect, useLocation } from 'react-router-dom'
import isFunction from 'lodash/isFunction'

import { HQ } from 'src/constants/serverRole'
import { loginPath, userHomePagePath } from 'src/lib/routes'

const propTypes = {
  children: oneOfType([element, arrayOf(element), func]).isRequired,
  data: shape({
    loading: bool,
    user: object,
  }).isRequired,
  hq: bool,
  parent: bool,
  redirect: bool,
  staff: bool,
  student: bool,
  teacher: bool,
}

const defaultProps = {
  hq: false,
  parent: false,
  redirect: false,
  staff: false,
  student: false,
  teacher: false,
}

const isAuthorized = ({ parent, staff, student, teacher, user }) => {
  if (parent && user && user.isParent) return true
  if (staff && user && user.isStaff) return true
  if (student && user && user.isStudent) return true
  if (teacher && user && user.isTeacher) return true
  return user && !(parent || staff || student || teacher)
}

const Access = ({
  children,
  data: { loading, user },
  hq,
  parent,
  redirect,
  staff,
  student,
  teacher,
}) => {
  const location = useLocation()
  if (hq && !HQ) return null
  if (loading) return null
  if (isAuthorized({ parent, staff, student, teacher, user })) {
    if (isFunction(children)) return children(user)
    return children
  }
  if (!redirect) return null
  if (user) {
    return <Redirect to={userHomePagePath(user)} />
  }
  return (
    <Redirect
      to={loginPath({
        error: 'login.errors.loginRequired',
        referer: location.pathname,
      })}
    />
  )
}

Access.propTypes = propTypes
Access.defaultProps = defaultProps

export default Access
