import React, { Component } from 'react'
import { array, arrayOf, bool, func, shape } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import entityShape from 'src/shapes/entity'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import SpeechBubblesQuestionAndAnswer from 'src/modules/SpeechBubblesQuestionAndAnswer/SpeechBubblesQuestionAndAnswer'
import * as phrasesActions from 'src/modules/SpeechBubblesQuestionAndAnswer/reducer'

const propTypes = {
  chunkedEntities: array.isRequired,
  entities: arrayOf(shape(entityShape)).isRequired,
  init: func.isRequired,
  thai: bool,
}

class SpeechBubblesQuestionAndAnswerContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props.entities)
  }

  createSlide(chunked, i) {
    const { thai } = this.props
    return (
      <Slide key={i}>
        <SpeechBubblesQuestionAndAnswer
          key={chunked[0].id}
          questionEntity={chunked[0]}
          answerEntity={chunked[1]}
          thai={thai}
        />
      </Slide>
    )
  }

  render() {
    const { chunkedEntities } = this.props
    return (
      <Slides>
        {chunkedEntities &&
          chunkedEntities.map((chunked, i) => this.createSlide(chunked, i))}
      </Slides>
    )
  }
}

const SpeechBubblesQuestionAndAnswerWithThai = (props) => (
  <SpeechBubblesQuestionAndAnswerContainer {...props} thai />
)

const demoData = {
  entities: [
    'phrase-what-is-he-doing',
    'phrase-he-is-working',
    'phrase-what-is-she-doing',
    'phrase-she-is-cooking-dinner',
  ],
}

SpeechBubblesQuestionAndAnswerWithThai.propTypes = propTypes
SpeechBubblesQuestionAndAnswerWithThai.demoData = demoData

SpeechBubblesQuestionAndAnswerContainer.propTypes = propTypes
SpeechBubblesQuestionAndAnswerContainer.demoData = demoData

const select = (state) => state.speechBubblesQuestionAnswer
const actions = (dispatch) => bindActionCreators(phrasesActions, dispatch)

export const SpeechBubblesQuestionAndAnswerWithThaiContaine = connect(
  select,
  actions
)(SpeechBubblesQuestionAndAnswerWithThai)
export default connect(select, actions)(SpeechBubblesQuestionAndAnswerContainer)
