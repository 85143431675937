import React, { Fragment } from 'react'
import BackButton from 'src/components/BackButton'
import Div from 'src/components/Div'
import HomeButton from 'src/components/HomeButton'
import LanguageSwitcher from 'src/pages/LanguageSwitcher'
import UserInfo from 'src/pages/UserInfo'
import ParentLoginReminder from 'src/components/ParentLoginReminder'

const Wrap = ({ children, className = '' }) => (
  <div className={`${className} border-left border-top border-light`}>
    <div className="flex-1 flex border-right border-bottom">{children}</div>
  </div>
)

const NavBar = () => (
  <Fragment>
    <Div
      id="navBar"
      flex
      bg="blue"
      z2
      print-hide
      align-stretch
      relative
    >
      <Div flex>
        <Wrap className="flex">
          <BackButton />
        </Wrap>
        <Wrap className="flex">
          <HomeButton />
        </Wrap>
      </Div>
      <Div flex flex-auto border-bottom />
      <Wrap className="flex">
        <LanguageSwitcher />
      </Wrap>
      <UserInfo />
    </Div>
    <ParentLoginReminder />
  </Fragment>
)

export { Wrap }
export default NavBar
