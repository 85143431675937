import React from 'react'
import { string } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  bg: string,
}

const defaultProps = {
  bg: 'orange',
}

const Fakebutton = ({ bg, ...props }) => (
  <Div
    cursor-pointer
    nowrap
    border
    rounded
    px2
    py1
    button-hover
    bold
    white
    bg={bg}
    {...props}
  />
)

Fakebutton.propTypes = propTypes
Fakebutton.defaultProps = defaultProps
export default Fakebutton
