import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

const withPhonics = (alphabetPhonics, keyboard) => {
  const normalizePhonics = keyBy(alphabetPhonics, 'titleEn')
  return keyboard.map(key => ({
    ...key,
    phonicSrc: get(normalizePhonics[key.letter], 'sound.srcEn'),
  }))
}

export default withPhonics
