import React from 'react'
import { shape, func, string, bool } from 'prop-types'
import KeyPress from 'src/components/KeyPress'
import Deck from 'src/components/Layout/Deck'
import * as layoutActions from 'src/components/Layout/reducer'
import { enterFullScreen, exitFullScreen, toggleFullscreen } from 'src/lib/window'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import learn21Image from 'src/images/learn21.svg'

const FORWARD_TIME = 5
const BACKWARD_TIME = 5

const propTypes = {
  autoPlay: bool,
  disableNavigation: bool,
  disableNavigationButton: func.isRequired,
  enableNavigationButton: func.isRequired,
  video: shape({
    src: string.isRequired,
  }).isRequired,
}

const defaultProps = {
  autoPlay: false,
  disableNavigation: false,
}

const Video = ({
  video,
  autoPlay,
  disableNavigation,
  enableNavigationButton,
  disableNavigationButton,
}) => {
  const videoElementRef = React.createRef()

  const navigationControl = () => {
    const videoElement = videoElementRef.current
    if (videoElement) {
      if (!videoElement.seeking) {
        if (videoElement.paused) {
          enableNavigationButton()
        } else {
          disableNavigationButton()
        }
      }
    }
  }

  const handleEnded = () => {
    exitFullScreen()
    navigationControl()
  }

  const togglePlay = (event) => {
    event.preventDefault()
    const videoElement = videoElementRef.current
    if (videoElement.paused) {
      enterFullScreen(videoElement)
      videoElement.play()
    } else {
      videoElement.play().then(() => videoElement.pause())
    }
  }

  const forward = () => {
    const videoElement = videoElementRef.current
    if (disableNavigation && videoElement.currentTime) {
      videoElement.currentTime += FORWARD_TIME
      videoElement.play()
    }
  }

  const backward = () => {
    const videoElement = videoElementRef.current
    if (disableNavigation && videoElement.currentTime) {
      videoElement.currentTime -= BACKWARD_TIME
      videoElement.play()
    }
  }

  const videoPreviewSrc = video.previewSrc || learn21Image

  const keys = {
    ' ': ({ event }) => togglePlay(event),
    ArrowDown: () => exitFullScreen(),
    ArrowLeft: backward,
    ArrowRight: forward,
    ArrowUp: () => enterFullScreen(videoElementRef.current),
    f: () => toggleFullscreen(videoElementRef.current),
  }

  return (
    <Deck relative>
      <video
        autoPlay={autoPlay}
        className="stretch absolute rounded cursor-pointer"
        controls
        controlsList="nodownload"
        onClick={togglePlay}
        poster={videoPreviewSrc}
        preload={videoPreviewSrc ? 'none' : 'auto'}
        onPlay={navigationControl}
        onPlaying={navigationControl}
        onPause={navigationControl}
        onEnded={handleEnded}
        ref={videoElementRef}
        src={video.src}
      />
      <KeyPress keys={keys} />
    </Deck>
  )
}

Video.propTypes = propTypes
Video.defaultProps = defaultProps

const select = state => (
  { ...state.layout }
)

const actions = dispatch => (
  bindActionCreators(layoutActions, dispatch)
)

export default connect(select, actions)(Video)
