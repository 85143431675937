import React from 'react'
import { array, func, shape } from 'prop-types'
import { compose } from 'src/lib/redux'

import gqlLoader from 'src/lib/gqlLoader'
import subjectQuery from 'src/queries/subject.gql'
import query from 'src/queries/pages/AttendanceReport/attendanceReport.gql'
import subjectShape from 'src/shapes/subject'
import { SubjectSelectorWithStyle } from 'src/selectors/SubjectSelector'
import Div from 'src/components/Div'
import Widget from 'src/components/Widget'
import StudentAttendance from 'src/pages/StudentAttendance/StudentAttendance'

const propTypes = {
  attendanceReport: shape({
    sections: array,
  }).isRequired,
  onSubjectChange: func.isRequired,
  subject: shape(subjectShape).isRequired,
}

const getStudents = sections => sections.reduce((students, section) => {
  section.years.map(year => students.push(...year.students))
  return students
}, [])

export const StudentAttendanceLayoutContainer = ({
  attendanceReport,
  onSubjectChange,
  subject,
}) => {
  const { sections } = { ...attendanceReport }
  return (
    <Div flex auto relative key={subject.slug}>
      <SubjectSelectorWithStyle
        subjectId={subject.slug}
        onChange={onSubjectChange}
      />
      <Widget icon="chart-pie" title={subject.title}>
        <Div flex wrap className="qa-students-attendance">
          {
            sections &&
            getStudents(sections).map(student => (
              <StudentAttendance key={student.id} {...student} />
            ))
          }
        </Div>
      </Widget>
    </Div>
  )
}

StudentAttendanceLayoutContainer.propTypes = propTypes

export default compose(gqlLoader(subjectQuery), gqlLoader(query))(StudentAttendanceLayoutContainer)
