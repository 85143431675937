import { adminURL } from 'config'
import calendarPath from 'src/pages/CalendarPage/paths'
import {
  STUDENT_ROLE,
  PARENT_ROLE,
  TEACHER_ROLE,
  STAFF_ROLE,
} from 'src/constants/roles'
import {
  PORTAL_SCOPE,
  SCHOOL_SCOPE,
  STUDENT_SCOPE,
  TEACHER_SCOPE,
} from 'src/constants/routeScopes'
import buildPath from 'src/lib/routes/buildPath'

/**
 * Paths
 * They are used to redirect to a route and support arguments.
 * Sometimes they are used also on React router when they don't have arguments to avoid duplication
 */
const schoolPath = () => `/${SCHOOL_SCOPE}`
const teacherPath = () => `/${TEACHER_SCOPE}/attendance`
const portalPath = () => `/${PORTAL_SCOPE}`
const studentPath = ({ page, id } = {}) => (
  [`/${STUDENT_SCOPE}`, page && `${page}`, id && `${id}`].filter(x => x).join('/')
)
const lessonsPath = ({ gradeSlug, yearSlug } = {}) => (
  [
    '/lessons',
    gradeSlug && `grade/${gradeSlug}`,
    yearSlug && `year/${yearSlug}`,
  ].filter(x => x).join('/')
)
const authPath = provider => `/auth/${provider}`
const loginPath = props => buildPath('/login', props)
const resetPasswordPath = ({ error } = {}) => (
  ['/password', error && `?error=${error}`].filter(x => x).join('/'))
const previewStudentsPath = ({ yearId } = {}) => buildPath(['/preview/students', yearId])
const guideLessonPath = ({ gradeSlug, lessonSlug, subjectSlug, unitSlug }) => (
  [
    `/guide/lesson/${lessonSlug}`,
    gradeSlug && `grade/${gradeSlug}`,
    subjectSlug && `subject/${subjectSlug}`,
    unitSlug && `unit/${unitSlug}`,
  ]
    .filter(x => x)
    .join('/')
)
const storyboardLessonPath = ({ gradeSlug, lessonSlug, subjectSlug, unitSlug }) => (
  [
    `/storyboard/lesson/${lessonSlug}`,
    gradeSlug && `grade/${gradeSlug}`,
    subjectSlug && `subject/${subjectSlug}`,
    unitSlug && `unit/${unitSlug}`,
  ]
    .filter(x => x)
    .join('/')
)
const guidesFullPath = ({ gradeSlug, subjectSlug, unitSlug }) => (
  [
    '/guides',
    gradeSlug && `grade/${gradeSlug}`,
    subjectSlug && `subject/${subjectSlug}`,
    unitSlug && `unit/${unitSlug}`,
  ]
    .filter(x => x)
    .join('/')
)
const storyboardsFullPath = ({ gradeSlug, subjectSlug, unitSlug }) => (
  [
    '/storyboards',
    gradeSlug && `grade/${gradeSlug}`,
    subjectSlug && `subject/${subjectSlug}`,
    unitSlug && `unit/${unitSlug}`,
  ]
    .filter(x => x)
    .join('/')
)
const lessonFullPath = ({
  slideIndex,
  chapterIndex,
  lessonSlug,
  hasSlides,
  homework,
}) => (
  `/${
    homework ? 'homework' : 'lesson'
  }/${lessonSlug}/chapter/${chapterIndex}${
    hasSlides ? '/slides/' : ''
  }${hasSlides ? slideIndex : ''}`
)
const homeworkPath = slug => ['/homework', slug && `${slug}`].filter(x => x).join('/')
const lessonPath = slug => `/lesson/${slug}`
const shortSlugLessonPath = (longPath) => {
  const longSlugMatch = longPath.match(/\/lesson\/(\w-\w+-\w+-\w+-\w+-\w+-\w+).*/)
  if (!longSlugMatch) return null

  const longSlug = longSlugMatch[1]
  const shortSlug = longSlug.substring(0, 8)
  return longPath.replace(/(\w-\w+-\w+-\w+-\w+-\w+-\w+)/, shortSlug)
}
const unitTestPath = slug => `/unit-test/${slug}`
const schoolSettingsPath = sectionId => `/school/settings${sectionId ? `/${sectionId}` : ''}`

const previewModulePath = moduleId => `/preview/modules/${moduleId}`
const schoolPreviewModulePath = ({ moduleId }) => (
  `/school/preview/modules/${moduleId}`
)
const teacherPreviewModulePath = ({ moduleId }) => (
  `/teacher/preview/modules/${moduleId}`
)
const previewCapturePath = moduleId => `/preview/captures/${moduleId}`
const adminEntityPath = slug => `${adminURL}/entities/${slug}`
const usersPath = ({ scope, filter } = {}) => (
  [
    '/users',
    scope && `${scope}`,
    filter && `${filter}`,
  ]
    .filter(x => x)
    .join('/')
)
const userPath = id => `/user/${id}`
const yearsPath = () => '/years'
const curriculumFullPath = ({ gradeSlug, subjectSlug }) => (
  [
    '/curriculum',
    gradeSlug && `grade/${gradeSlug}`,
    subjectSlug && `subject/${subjectSlug}`,
  ]
    .filter(x => x)
    .join('/')
)
const userHomePagePath = (user) => {
  switch (user && user.roleId) {
    case STUDENT_ROLE:
      return studentPath()
    case PARENT_ROLE:
      return portalPath()
    case TEACHER_ROLE:
      return teacherPath()
    case STAFF_ROLE:
      return schoolPath()
    default:
      return lessonsPath()
  }
}

const activeRedirect = (path) => {
  const arr = path.split('/')
  arr.pop()
  return arr.join('/')
}

const attendanceStudentReportPath = (domain, slug) => `/${domain}/user/${slug}/attendance`

const unittestWithChapterPath = ({ lessonSlug, chapterId }) => (
  `/unit-test/${lessonSlug}/#chapter-${chapterId}`
)

export {
  activeRedirect,
  adminEntityPath,
  attendanceStudentReportPath,
  authPath,
  calendarPath,
  curriculumFullPath,
  guidesFullPath,
  guideLessonPath,
  homeworkPath,
  lessonFullPath,
  lessonPath,
  lessonsPath,
  loginPath,
  portalPath,
  previewCapturePath,
  previewModulePath,
  previewStudentsPath,
  resetPasswordPath,
  schoolPath,
  schoolPreviewModulePath,
  schoolSettingsPath,
  shortSlugLessonPath,
  storyboardLessonPath,
  storyboardsFullPath,
  studentPath,
  teacherPath,
  teacherPreviewModulePath,
  userHomePagePath,
  unitTestPath,
  unittestWithChapterPath,
  userPath,
  usersPath,
  yearsPath,
}
