import React from 'react'
import { bool, func, object, shape, string, objectOf, oneOfType, number } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import KeyHandler from 'src/components/KeyHandler'

const propTypes = {
  cards: objectOf(
    shape({
      id: string.isRequired,
      label: number.isRequired,
      src: string,
      status: oneOfType([bool, string]),
    }).isRequired,
  ).isRequired,
  entitiesById: object.isRequired,
  selectCard: func.isRequired,
}

const ImageChoices = ({ cards, entitiesById, selectCard }) => (
  <Div flex flex-1 wrap ImageChoices>
    {
      map(cards, card => (
        <Div key={card.key} flex auto col-6 relative>
          <Div
            mr1
            absolute
            z1
          >
            <KeyHandler
              label={card.label}
              onKeyPress={() => selectCard({ cardId: card.id })}
              color="black"
            />
          </Div>
          <AdaptiveCard
            audio
            auto
            bold
            correct={card.correct}
            entity={entitiesById[card.id]}
            image
            keyToPlay={card.label}
            label={card.label}
            minHeight={false}
            onClick={() => selectCard({ cardId: card.id })}
            selected={card.selected}
          />
        </Div>
      ))
    }
  </Div>
)

ImageChoices.propTypes = propTypes

export default ImageChoices
