import React from 'react'
import { useLocation, useHistory } from 'react-router'
import usePageParams from 'src/lib/usePageParams'
import CurriculumProgress from 'src/pages/ProgressPage/CurriculumProgress'
import AttainmentProgress from 'src/pages/ProgressPage/AttainmentProgress'
import ProgressPage from 'src/pages/ProgressPage/ProgressPageContainer'

const withLayout = Component => () => (
  <ProgressPage
    history={useHistory()}
    location={useLocation()}
    pageState={usePageParams()}
    Component={Component}
  />
)

const CurriculumProgressPage = withLayout(({ onPathChange, ...rest }) => (
  <CurriculumProgress
    {...rest}
    onStrandChange={update => onPathChange(update)}
    onSubjectChange={subjectId => onPathChange({ subjectId })}
  />
))

const AttainmentProgressPage = withLayout(({ onPathChange, ...rest }) => (
  <AttainmentProgress
    {...rest}
    onUnitChange={update => onPathChange(update)}
    onSubjectChange={subjectId => onPathChange({ subjectId })}
  />
))

export {
  withLayout as withProgressLayout,
  CurriculumProgressPage,
  AttainmentProgressPage,
}
