import { arrayOf, shape, number, string } from 'prop-types'
import gradeShape from 'src/shapes/grade'
import yearShape from 'src/shapes/year'

const sectionShape = {
  grades: arrayOf(shape(gradeShape)),
  id: string,
  present: number,
  slug: string,
  title: string,
  total: number,
  years: arrayOf(shape(yearShape)),
}

export default sectionShape
