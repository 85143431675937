import React, { useContext } from 'react'
import { boolean, number, string } from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Badge from 'src/components/Badge'
import Icon from 'src/components/Icon'
import DomainContext from 'src/contexts/DomainContext'
import { path as attendanceTodayPath } from 'src/pages/AttendancePage/AttendanceToday/config'
import { linkTo } from 'src/lib/linkTo'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'

const propTypes = {
  id: string.isRequired,
  present: number.isRequired,
  selected: boolean,
  title: string.isRequired,
  total: number.isRequired,
}

const defaultProps = {
  selected: false,
}

const AttendanceYear = ({ id, present, selected, title, total }) => {
  const full = total - present === 0
  const domain = useContext(DomainContext) || SCHOOL_SCOPE
  return (
    <Link
      className={classnames(
        `mb button-hover block p1 border rounded relative qa-attendance-year-${id}`,
        { selected },
      )}
      to={linkTo(attendanceTodayPath)({ domain, yearId: id })}
    >
      {!full && !!present && (
      <div className="absolute top-0 right-0 mtn1 mrn1">
        <Badge>
          {total - present}
        </Badge>
      </div>
      )}
      {full && (
      <div className="absolute top-0 right-0 mrn1 green">
        <Icon icon="check-circle" />
      </div>
      )}
      <div className="mr1 bold">
        {title}
      </div>
      <div className="black">
        {present}
        <small className="muted">
          {' / '}
          {total}
        </small>
      </div>
    </Link>
  )
}

AttendanceYear.propTypes = propTypes
AttendanceYear.defaultProps = defaultProps

export default AttendanceYear
