import React from 'react'
import BackgroundStyle from 'src/components/BackgroundStyle'
import Div from 'src/components/Div'
import Menu from 'src/pages/TheLayout/Menu'
import background from 'src/pages/LessonsLayout/background.svg'

const LessonsLayout = ({ children }) => (
  <Div safe-area>
    <Div auto mt1 px1>
      <BackgroundStyle color="#7BD3F7" backgroundUrl={background} />
      <Div container>
        <Menu />
        { children }
      </Div>
    </Div>
  </Div>
)

export default LessonsLayout
