import React from 'react'
import { bool, func, number, oneOfType, string } from 'prop-types'

import KeyLabel from 'src/components/KeyLabel'
import KeyPress from 'src/components/KeyPress'

const propTypes = {
  color: string,
  enableKeyUp: bool,
  isBubbleLabel: bool,
  label: oneOfType([string, number]).isRequired,
  noPrint: bool,
  onKeyPress: func,
  px: number,
  size: number,
}

const LABEL_NUMBER_TO_LETTER = {
  10: 'a',
  11: 'b',
  12: 'c',
  13: 'd',
  14: 'e',
  15: 'f',
  16: 'g',
  17: 'h',
  18: 'i',
  19: 'j',
  20: 'k',
  21: 'l',
  22: 'm',
  23: 'n',
  24: 'o',
  25: 'p',
  26: 'q',
  27: 'r',
  28: 's',
  29: 't',
  30: 'u',
  31: 'v',
  32: 'w',
  33: 'x',
  34: 'y',
  35: 'z',
}

const defaultProps = {
  enableKeyUp: false,
  isBubbleLabel: false,
  noPrint: true,
  onKeyPress: undefined,
  size: 3,
  color: undefined,
  height: undefined,
  px: undefined,
}

const KeyHandler = ({
  color,
  enableKeyUp,
  isBubbleLabel,
  label,
  noPrint,
  onKeyPress,
  px,
  size,
}) => {
  const theLabel = LABEL_NUMBER_TO_LETTER[label] ? LABEL_NUMBER_TO_LETTER[label] : label
  return (
    <div>
      <KeyLabel
        label={theLabel}
        size={size}
        isBubbleLabel={isBubbleLabel}
        noPrint={noPrint}
        color={color}
        px={px}
      />
      <KeyPress
        keys={{ [theLabel]: onKeyPress }}
        enableKeyUp={enableKeyUp}
      />
    </div>
  )
}

KeyHandler.defaultProps = defaultProps
KeyHandler.propTypes = propTypes
export default KeyHandler
