import pick from 'lodash/pick'

import icons from 'src/lib/menu/icons'
import { BP_REPORT } from 'src/constants/menu'
import reportPath from 'src/lib/routes/reportPath'
import buildPath from 'src/lib/routes/buildPath'
import pathWithPageParams from 'src/lib/routes/pathWithPageParams'

const path = '/:domain/reports/bp-report'
const matchPaths = [
  path,
  '/:domain/reports/:academicYearId/:academicTermId/bp-report/:subjectId/:yearId',
]

const makePath = pathWithPageParams((params) => {
  const { subjectId, yearId, academicTermId, academicYearId } = params
  const hasEnoughParams = (academicYearId || academicTermId)
    && subjectId
    && yearId

  return `/${buildPath([
    reportPath(hasEnoughParams ? params : pick(params, ['domain'])),
    'bp-report',
    ...(subjectId && yearId
      ? ([
        subjectId,
        yearId,
      ])
      : []
    ),
  ])}`
})

const scene = {
  icon: icons[BP_REPORT],
  id: BP_REPORT,
  matchPaths,
  path,
}

export {
  path,
  matchPaths,
  makePath,
  scene,
}

export default scene
