import React from 'react'
import { number, func, array } from 'prop-types'
import chunk from 'lodash/chunk'
import Card from 'src/components/Card'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import max from 'lodash/max'
import sum from 'lodash/sum'

const propTypes = {
  letters: array.isRequired,
  playing: number,
  playNext: func.isRequired,
  playPhonics: func.isRequired,
  sizes: array.isRequired,
}

const Spelling = ({
  letters,
  playing,
  playNext,
  playPhonics,
  sizes,
}) => (
  <Div flex>
    <Div
      column
      className="h0 rounded blue white cursor-pointer"
      onClick={playPhonics}
    >
      <Icon icon="play-circle" />
    </Div>
    <Div px={1} flex column align="center" justify="center">
      {chunk(letters, 2).map((pair, i) => {
        const letters = pair[0]
        const chunkLength = sizes[i]
        const previousLength = sum(sizes.slice(0, i))
        const { src, title } = pair[1]
        const phonicsPlayable = (chunkLength + previousLength) - 1
        return ([
          Object.keys(letters).map((letter, j) => {
            const { sound: { srcEn }, titleEn } = letters[letter]
            const lettersPlayable = (i ? previousLength + j : i + j)
            return (
              playing === lettersPlayable && (
              <Div
                flex
                align="center"
                style={{ flex: `${max(sizes)}` }}
              >
                <Card
                  audio
                  autoPlay={playing === lettersPlayable}
                  bold
                  className="h1"
                  title={titleEn}
                  src={srcEn}
                  fit
                  key={`${letter}-${lettersPlayable}`}
                  onClick={() => {}}
                  phonic
                  playNext={playNext}
                />
              </Div>
              ))
          }),
          playing === phonicsPlayable && (
          <Div
            flex
            align="center"
            style={{ flex: `${max(sizes)}` }}
          >
            <Card
              audio
              autoPlay={playing === phonicsPlayable}
              bold
              className="h1"
              title={title}
              src={src}
              fit
              key={`${title}-${phonicsPlayable}`}
              onClick={() => {}}
              phonic
              playNext={playNext}
            />
          </Div>
          ),
        ])
      })}
    </Div>
  </Div>
)

Spelling.propTypes = propTypes
export default Spelling
