import React from 'react'
import { arrayOf, shape, string, func } from 'prop-types'
import { t } from 'i18next'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Divider from 'src/components/Divider'
import Icon from 'src/components/Icon'
import LessonLink from 'src/pages/ProgressPage/AttainmentProgress/LessonSelector/LessonLink'

const propTypes = {
  lessons: arrayOf(shape({
    id: string.isRequired,
    identifier: string.isRequired,
    title: string.isRequired,
  })),
  limit: string,
  path: func.isRequired,
}

const defaultProps = {
  lessons: [],
  limit: null,
}

const creatLessonList = (a, lesson, index) => ({
  ...a,
  [index + 1]: { ...lesson, index: index + 1 },
})

const LessonSelector = ({
  lessons,
  limit,
  path,
}) => {
  const lessonList = lessons.reduce(creatLessonList, {})
  return (
    <div>
      <Div flex px={2} py={1} mbn={1} align="left" noPrint column>
        <div className="h4 bold flex align-center">
          <Icon icon="list" className="muted" before />
          {t('school.lesson')}
        </div>
        <Div auto flex wrap justify-start mln>
          {
            map(lessonList, ({
              id,
              identifier,
              index,
              title,
            }) => (
              <LessonLink
                key={id}
                identifier={identifier}
                selected={((limit >= index) || (limit === null))}
                to={path(index)}
                title={title}
              />
            ))}
        </Div>
      </Div>
      <Divider className="muted mt1 mb0 noPrint" />
    </div>
  )
}

LessonSelector.defaultProps = defaultProps
LessonSelector.propTypes = propTypes

export default LessonSelector
