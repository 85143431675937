import React, { Fragment, memo } from 'react'
import { string, shape, func } from 'prop-types'
import {
  dateToday,
  formatMonthLong,
  formatYearLong,
  formatDayShort,
  weeksOfMonth,
  monthEdges,
  daysOfWeek,
} from 'src/lib/dates'
import Week from 'src/components/YearCalendar/Week'

const classNamePrefix = 'rc-Month'

const propTypes = {
  date: string,
  datePath: func,
  dayModifiers: shape(),
}

const defaultProps = {
  date: dateToday(),
  dayModifiers: {},
}

const Header = memo(({ date }) => (
  <Fragment>
    <header key="header" className="center bold">
      {`${formatMonthLong(date)} ${formatYearLong(date)}`}
    </header>
    <div>
      {
        daysOfWeek(date).map(weekday => (
          <div key={`weekday-header-${weekday}`} className="cell weekday">
            {formatDayShort(weekday)}
          </div>
        ))
      }
    </div>
  </Fragment>
))

Header.propTypes = {
  date: string.isRequired,
}

const Month = ({ date, dayModifiers, datePath }) => {
  const edges = monthEdges(date)

  return (
    <div className={classNamePrefix}>
      <Header date={date} />
      {
        weeksOfMonth(date).map(weekDate => (
          <Week
            datePath={datePath}
            key={`week-${weekDate}`}
            date={weekDate}
            edges={edges}
            dayModifiers={dayModifiers}
          />
        ))
      }
    </div>
  )
}

Month.propTypes = propTypes
Month.defaultProps = defaultProps

export default Month
