import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import StudentLayout from 'src/pages/StudentLayout'
import StudentHomeworkPage from 'src/pages/StudentHomeworkPage'
import StudentProgress from 'src/pages/StudentLayout/StudentProgress'
import StudentAttendance from 'src/pages/StudentAttendance'
import userRedirect from 'src/lib/userRedirect'
import CalendarPage from 'src/pages/CalendarPage'
import UserEdit from 'src/pages/User/Edit'

export const path = '/student'

const StudentLayoutRoute = ({ component: Component, path, exact }) => (
  <Route path={path} exact={exact}>
    <StudentLayout>
      <Component path={path} />
    </StudentLayout>
  </Route>
)

const StudentRoute = () => (
  <Switch>
    <StudentLayoutRoute
      exact
      path="/student"
      component={userRedirect(userId => `/student/${userId}`)}
    />
    <StudentLayoutRoute
      path="/student/user/:userId/edit"
      component={UserEdit}
    />

    <StudentLayoutRoute exact path="/student/:studentId" component={StudentHomeworkPage} />
    <StudentLayoutRoute path="/student/:studentId/homework" component={StudentHomeworkPage} />
    <StudentLayoutRoute
      path="/student/:studentId/skills/:subject(subject)?/:subjectId?"
      component={StudentProgress}
    />
    <StudentLayoutRoute
      path="/student/:studentId/attendance/:subjectId?"
      component={StudentAttendance}
    />
    <StudentLayoutRoute
      path={[
        '/student/:studentId/calendar/:year(year)/:yearId/:date?',
        '/student/:studentId/calendar/:date?',
      ]}
      component={CalendarPage}
    />
    <Redirect to="/lessons" />
  </Switch>
)

export default StudentRoute
