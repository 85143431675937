import getSpeechRecognition from 'src/lib/getSpeechRecognition'
import { t } from 'i18next'
import { warningMessage } from 'src/lib/toaster'
import normalizeString from 'src/lib/normalizeString'

const { info, warn } = console

const buildSpeechRecognition = ({ onResult }) => {
  const recognition = new (getSpeechRecognition())()
  recognition.onResult((words) => { info(words); onResult(words.map(normalizeString)) })
  recognition.onError(warn)
  if (recognition.fake) {
    warningMessage({ message: t('browser.warning.message'), title: t('browser.warning.title') })
  }
  return recognition
}

export default buildSpeechRecognition
