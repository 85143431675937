import React, { useContext } from 'react'
import { string, object, array, oneOfType } from 'prop-types'
import { t } from 'i18next'

import Icon from 'src/components/Icon'
import ColorScheme from 'src/contexts/ColorScheme'

const propTypes = {
  id: string,
  messages: oneOfType([string, object, array]),
  name: string,
}

const prepareMessages = (name, messages) =>
  []
    .concat(messages)
    .map((m) => m.replace(name, ''))
    .join(', ')

const ValidationErrorMessage = ({ name, messages, id }) => {
  const { errorColor } = useContext(ColorScheme)

  if (id) {
    return (
      <div className={`rounded white ${errorColor} py`}>
        <Icon icon="exclamation-triangle" />
        {` ${prepareMessages(name, t(id))}`}
      </div>
    )
  }
  return messages ? (
    <div className={`rounded white ${errorColor} py`}>
      <Icon icon="exclamation-triangle" />
      {` ${prepareMessages(name, messages)}`}
    </div>
  ) : null
}

ValidationErrorMessage.propTypes = propTypes

export { ValidationErrorMessage }
export default ValidationErrorMessage
