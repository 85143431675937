import { display } from 'src/components/SoftKeyboard/keyboardLayout'

const SHOW = 'SOFT_KEYBOARD/SHOW'
const HIDE = 'SOFT_KEYBOARD/HIDE'
const ENABLE = 'SOFT_KEYBOARD/ENABLE'
const DISABLE = 'SOFT_KEYBOARD/DISABLE'
const UPDATE_KEY = 'SOFT_KEYBOARD/UPDATE_KEY'
const CLEAR_KEY = 'SOFT_KEYBOARD/CLEAR_KEY'
const HIDE_CLEAR_INPUTS = 'SOFT_KEYBOARD/HIDE_CLEAR_INPUTS'

const initialState = {
  inputs: '',
  isSoftKeyboardEnable: false,
  isSoftKeyboardShow: false,
  layoutName: 'default',
  keyInput: null,
}

const updateInputs = ({ inputs, keyInput }) => {
  if (keyInput === '8') return inputs.slice(0, -1)
  if (keyInput === '32') return inputs.concat(' ')
  if (display[keyInput] === undefined) return inputs
  return inputs.concat(display[keyInput])
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isSoftKeyboardShow: true,
      }
    case HIDE:
      return {
        ...state,
        isSoftKeyboardShow: false,
      }
    case ENABLE:
      return {
        ...state,
        isSoftKeyboardEnable: true,
      }
    case DISABLE:
      return {
        ...initialState,
      }
    case UPDATE_KEY: {
      const { keyInput } = action.payload
      const { inputs, layoutName } = state
      if (keyInput === '401') return { ...state, layoutName: 'num' }
      if (keyInput === '402') return { ...state, layoutName: 'default' }
      if (keyInput === '403') return { ...state, layoutName: 'shift' }
      if (keyInput === '404') return { ...state, layoutName: 'default' }
      if (keyInput === '405') return { ...state, layoutName: 'symbol' }

      return {
        ...state,
        inputs: updateInputs({ inputs, keyInput }),
        keyInput,
        layoutName: layoutName === 'shift' ? 'default' : layoutName,
      }
    }
    case CLEAR_KEY:
      return {
        ...state,
        keyInput: null,
      }
    case HIDE_CLEAR_INPUTS:
      return {
        ...state,
        inputs: '',
        isSoftKeyboardShow: false,
      }
    default:
      return state
  }
}

const showSoftKeyboard = () => ({
  type: SHOW,
})

const hideSoftKeyboard = () => ({
  type: HIDE,
})

const enableSoftKeyboard = () => ({
  type: ENABLE,
})

const disableSoftKeyboard = () => ({
  type: DISABLE,
})

const clearSoftKeyboardKey = () => ({
  type: CLEAR_KEY,
})

const hideAndClearSoftKeyboardInputs = () => ({
  type: HIDE_CLEAR_INPUTS,
})

const updateSoftKeyboardKey = payload => ({
  payload,
  type: UPDATE_KEY,
})

export default reducer

export {
  showSoftKeyboard,
  clearSoftKeyboardKey,
  hideSoftKeyboard,
  enableSoftKeyboard,
  disableSoftKeyboard,
  updateSoftKeyboardKey,
  hideAndClearSoftKeyboardInputs,
}
