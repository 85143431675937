import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import dot from 'dot-object'
import { DEFAULT_LOCALE } from 'config'

import { MENU } from 'src/constants/localeNamespace'
import loginEN from 'src/pages/Login/locale/en.json'
import loginTH from 'src/pages/Login/locale/th.json'
import menuEN from 'src/pages/TheLayout/Menu/locale/en.json'
import menuTH from 'src/pages/TheLayout/Menu/locale/th.json'
import progressEN from 'src/pages/ProgressPage/locale/en.json'
import progressTH from 'src/pages/ProgressPage/locale/th.json'
import roleEN from 'src/locale/role/en.json'
import roleTH from 'src/locale/role/th.json'
import translationEN from 'src/locale/en'
import translationTH from 'src/locale/th'

const resources = {
  en: {
    [MENU]: menuEN,
    login: loginEN,
    progress: progressEN,
    role: roleEN,
    translation: dot.object(translationEN),
  },
  th: {
    [MENU]: menuTH,
    login: loginTH,
    progress: progressTH,
    role: roleTH,
    translation: dot.object(translationTH),
  },
}

i18n.use(LanguageDetector).init({
  debug: true,
  fallbackLng: ['th', 'en'],
  lng: DEFAULT_LOCALE,
  load: 'unspecific',
  resources,
  whitelist: ['en', 'th'],
})

export default i18n
