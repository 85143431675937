/* eslint react/prefer-stateless-function: 1 */
import React from 'react'
import { shape, bool, func, array } from 'prop-types'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Card from 'src/components/Card'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import KeyHandler from 'src/components/KeyHandler'
import MicrophoneButton from 'src/components/MicrophoneButton'

const propTypes = {
  correct: bool,
  cursive: bool,
  entity: shape(entityShape).isRequired,
  hide: bool,
  hideAnswer: bool,
  keyPressRecognition: func.isRequired,
  loading: bool,
  selected: bool.isRequired,
  showOriginalText: func.isRequired,
  silent: bool,
  startRecognition: func.isRequired,
  stopRecognition: func.isRequired,
  transcripts: array.isRequired,
}

const defaultProps = {
  correct: false,
  cursive: false,
  hide: false,
  hideAnswer: false,
  loading: false,
  silent: false,
}

const selectColor = (correct) => {
  if (correct === undefined) return { fontWeight: 'bold' }
  if (correct) return { color: 'green' }
  return { color: 'red', textDecoration: 'underline' }
}

const SpeechRecognition = ({
  autoPlay,
  correct,
  entity,
  hide,
  hideAnswer,
  cursive,
  keyPressRecognition,
  loading,
  showOriginalText,
  selected,
  silent,
  startRecognition,
  stopRecognition,
  transcripts,
}) => (
  <Deck cursive={cursive}>
    <Div flex auto gutter={2}>
      <Div col={4}>
        <EntityImage entity={entity} />
      </Div>
      <Div col={8} flex>
        <Div flex auto justify="center" align="center">
          <Div flex>
            <Div mt1 pt2>
              {
                !silent && (
                  <KeyHandler
                    label="1"
                    onKeyPress={showOriginalText}
                  />
                )
              }
            </Div>
            <Div justify-center align-left column>
              <Card
                audio={!hide}
                autoPlay={autoPlay && !hide && !silent}
                correct={correct}
                english
                fit
                key={entity.id}
                keyToPlay={1}
                silent={silent}
                size={1}
                src={{ ...entity.sound }.srcEn}
              >
                <Div flex p1 wrap justify-center>
                  {
                    hideAnswer ? (
                      <Div key="hide" flex mr1>
                        <Card
                          title="?"
                          minHeight={false}
                          transparent
                          style={selectColor(undefined)}
                        />
                      </Div>
                    )
                      : (
                        transcripts.map(({ titleEn, id, correct }) => (
                          <Div key={id} flex mr1 remove-border-print>
                            <Card
                              title={titleEn}
                              minHeight={false}
                              transparent
                              style={selectColor(correct)}
                              className="remove-border-print"
                            />
                          </Div>
                        ))
                      )
                  }
                </Div>
              </Card>
              <Div flex justify-center pt3>
                <MicrophoneButton
                  loading={loading}
                  selected={selected}
                  onKeyPress={keyPressRecognition}
                  onMouseDown={startRecognition}
                  onMouseUp={stopRecognition}
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  </Deck>
)

SpeechRecognition.defaultProps = defaultProps
SpeechRecognition.propTypes = propTypes
export default SpeechRecognition
