import React from 'react'
import { bool, number, object, string, func } from 'prop-types'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import * as layoutActions from 'src/components/Layout/reducer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const propTypes = {
  displayImage: bool.isRequired,
  entitiesById: object.isRequired,
  goToSlide: func.isRequired,
  memberId: string,
  place: object.isRequired,
  slideToGoTo: number.isRequired,
}

const FamilyPlace = ({
  displayImage,
  entitiesById,
  memberId,
  place: { id, cardId },
  slideToGoTo,
  goToSlide,
}) => (
  <Place id={id}>
    <EntityCard
      entity={entitiesById[cardId]}
      id={entitiesById[cardId].id}
      english
      bold
      image={cardId && displayImage}
      selected={cardId === memberId}
      size={4}
      silent={cardId && !displayImage}
      onClick={() => goToSlide(slideToGoTo)}
    />
  </Place>
)

FamilyPlace.propTypes = propTypes

const actions = dispatch => (
  bindActionCreators(layoutActions, dispatch)
)

export default connect(null, actions)(FamilyPlace)
