import React from 'react'
import mapValues from 'lodash/mapValues'
import { func, string, array } from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import { fromMoment, toMoment, DATE_PICKER_FORMAT } from 'src/lib/dates'

const propTypes = {
  dateFormat: string,
  highlightDates: array,
  maxDate: string,
  minDate: string,
  name: string,
  onChange: func.isRequired,
  selected: string,
}

const defaultProps = {
  dateFormat: DATE_PICKER_FORMAT,
  highlightDates: [],
  maxDate: undefined,
  minDate: undefined,
  name: null,
  selected: null,
}

const highlightDatesAsMoment = highlightDates => (
  highlightDates.map(o => mapValues(o, dates => dates.map(toMoment)))
)

const DatePicker = ({
  maxDate,
  minDate,
  name,
  selected,
  dateFormat,
  onChange,
  highlightDates,
  ...rest
}) => (
  <ReactDatePicker
    maxDate={maxDate ? toMoment(maxDate) : undefined}
    minDate={minDate ? toMoment(minDate) : undefined}
    name={name}
    selected={toMoment(selected)}
    dateFormat={dateFormat}
    onChange={(date) => { onChange(fromMoment(date)) }}
    highlightDates={highlightDates ? highlightDatesAsMoment(highlightDates) : highlightDates}
    disabledKeyboardNavigation
    {...rest}
  />
)

DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default DatePicker
