import diff from 'fast-diff'
import every from 'lodash/every'
import size from 'lodash/size'
import uniqShuffle from 'src/lib/uniqShuffle'
import shuffle from 'lodash/shuffle'
import isString from 'lodash/isString'

const CHANGE = 'ListenAndTypeSentences/CHANGE'
const INIT = 'ListenAndTypeSentences/INIT'
const MARK = 'ListenAndTypeSentences/MARK'
const RETRY = 'ListenAndTypeSentences/RETRY'

export const initialState = {
  actualEntities: [],
  completed: null,
  corrected: null,
  enterCount: 0,
  entity: {},
  place: null,
}

const NUMBER_OF_ENTITIES = 4

const numberToStatus = (number) => {
  if (number === -1) return null
  if (number === 0) return true
  return false
}

const createPlace = (entity) => {
  const { id, titleEn } = entity
  return {
    answer: '',
    id,
    question: titleEn,
  }
}

const selectEntities = (entities, entityTarget) => ([
  entityTarget,
  ...entities.filter(({ id }) => id !== entityTarget.id).slice(0, NUMBER_OF_ENTITIES),
])

const updateSelectedWordBack = questionWord => (a, entity) => ([
  ...a,
  { ...entity, selected: entity.titleEn === questionWord },
])

const createResult = (a, [number, word], index) => {
  const id = `${index}-${word.trim().split(' ')[0]}`
  return {
    ...a,
    [id]: {
      correct: numberToStatus(number),
      id,
      title: word,
    },
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const entity = uniqShuffle(entities)[0]
      const wordBank = shuffle(selectEntities(entities, entity))
      const place = createPlace(entity)
      return {
        ...initialState,
        actualEntities: [entity],
        entity,
        place,
        wordBank,
      }
    }
    case CHANGE: {
      const { answer } = payload
      const { place } = state
      if (isString(answer)) {
        return {
          ...state,
          place: {
            ...place,
            answer,
          },
        }
      }
      return state
    }
    case MARK: {
      const { place, wordBank } = state
      const result = diff(place.question.trim(), place.answer.trim().replace(/\s\s+/g, ' ')).reduce(createResult, {})
      const completed = size(result) > 0 ? every(result, 'correct') : false
      const wordBankWithSelected = wordBank.reduce(updateSelectedWordBack(place.question), [])
      return {
        ...state,
        completed,
        enterCount: state.enterCount + 1,
        place: {
          ...place,
          result,
        },
        wordBank: wordBankWithSelected,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const handleChange = answer => ({
  payload: {
    answer,
  },
  type: CHANGE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  handleChange,
  init,
  mark,
  retry,
}

export default reducer
