import React, { Component } from 'react'
import { shape, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import {
  enableSoftKeyboard,
  disableSoftKeyboard,
} from 'src/components/SoftKeyboard/reducer'
import * as gameActions from 'src/modules/FillInMissingWordType/reducer'
import FillInMissingWordType from 'src/modules/FillInMissingWordType/FillInMissingWordType'

const propTypes = {
  ...gamePropTypes,
  cards: shape().isRequired,
  handleChange: func.isRequired,
}

const defaultProps = {
  image: null,
}

class FillInMissingWordTypeContainer extends Component {
  componentDidMount() {
    this.init()
    this.props.enableSoftKeyboard()
  }

  init() {
    this.props.init(this.props)
  }

  componentWillUnmount() {
    this.props.disableSoftKeyboard()
  }

  render() {
    const {
      actualEntities,
      answer,
      cards,
      completed,
      entitiesById,
      handleChange,
      image,
      isSoftKeyboardShow,
      mark,
      retry,
      started,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <FillInMissingWordType
          answer={answer}
          autoPlay={started}
          cards={cards}
          completed={completed}
          entitiesById={entitiesById}
          handleChange={handleChange}
          image={image}
          isSoftKeyboardShow={isSoftKeyboardShow}
        />
      </Game>
    )
  }
}

FillInMissingWordTypeContainer.defaultProps = defaultProps
FillInMissingWordTypeContainer.propTypes = propTypes
FillInMissingWordTypeContainer.demoData = {
  entities: [
    'phrase-i-am',
    'am',
    'phrase-we-are-parents',
    'are',
    'phrase-he-is',
    'is',
  ],
}

const select = state => ({ ...state.fillInMissingWordType, ...state.softKeyboard })
const actions = dispatch => bindActionCreators({
  ...gameActions,
  enableSoftKeyboard,
  disableSoftKeyboard,
}, dispatch)
export { FillInMissingWordTypeContainer }
export default connect(select, actions)(FillInMissingWordTypeContainer)
