import React from 'react'
import classnames from 'classnames'
import { bool, number, oneOfType, string } from 'prop-types'

import Div from 'src/components/Div'

const propTypes = {
  className: string,
  color: string,
  height: number,
  isBubbleLabel: bool,
  label: oneOfType([string, number]).isRequired,
  noPrint: bool,
  px: number,
  size: number,
}

const defaultProps = {
  className: undefined,
  color: 'gray',
  height: null,
  isBubbleLabel: false,
  noPrint: true,
  px: 2,
  size: 3,
}

const getLabel = label => (label === ' ' ? '␣' : label)

const KeyLabel = ({
  className,
  color,
  height,
  isBubbleLabel,
  label,
  px,
  size,
  noPrint,
  ...rest
}) => (
  <Div
    align="center"
    color={color}
    className={classnames('KeyLabel', className)}
    flex
    height={height}
    justify="center"
    px={px}
    size={size}
    noPrint={noPrint}
    normal
    {...rest}
  >
    {
      isBubbleLabel ? (
        <Div py={3} bubble-label-position>
          {getLabel(label)}
        </Div>
      ) : (
        <div>
          {getLabel(label)}
        </div>
      )}
  </Div>
)

KeyLabel.defaultProps = defaultProps
KeyLabel.propTypes = propTypes
export default KeyLabel
