import React from 'react'
import { number, string, shape, bool, func } from 'prop-types'
import ObjectTarget from 'src/components/ObjectTarget'
import riverTarget from 'src/components/ObjectTarget/images/targets/RiverTarget.svg'
import True from 'src/components/ObjectTarget/images/effects/True.svg'
import False from 'src/components/ObjectTarget/images/effects/False.svg'
import Decoration from 'src/components/ObjectTarget/images/decorations/ArrowAndTartget.svg'

const propTypes = {
  animation: bool,
  cursive: bool,
  entity: shape(),
  height: number,
  justify: string,
  mixFont: bool,
  onClick: func,
  selected: bool,
  text: bool,
  width: number,
}

const defaultProps = {
  cursive: null,
  justify: 'center',
  mixFont: null,
  text: null,
}

const entityImageStyle = {
  paddingBottom: '2.5rem',
  paddingLeft: '1.2rem',
  paddingRight: '1.2rem',
  paddingTop: '1rem',
}

const RiverTarget = props => (
  <ObjectTarget
    targetImage={riverTarget}
    effectImage={True}
    secondEffectImage={False}
    decorateTargetImage={Decoration}
    entityImageStyle={entityImageStyle}
    {...props}
  />
)

RiverTarget.defaultProps = defaultProps
RiverTarget.propTypes = propTypes

export default RiverTarget
