import { t } from 'i18next'
import { toastr } from 'react-redux-toastr'

export const successMessage = ({ message, title }) => {
  toastr.success(title, message)
}

export const infoMessage = ({ message, title }) => {
  toastr.info(title, message)
}

export const warningMessage = ({ message, title, localeMessage }) => (
  localeMessage ?
    toastr.warning(title, t(localeMessage))
    : toastr.warning(title, message)
)

export const confirmMessage = ({ message, options }) => {
  toastr.confirm(message, options)
}
