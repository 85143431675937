import React from 'react'
import { shape } from 'prop-types'
import Slide from 'src/components/Layout/Slide'
import Div from 'src/components/Div'
import AspectRatio from 'src/components/AspectRatio'
import Video from 'src/modules/Video/Video'
import entityShape from 'src/shapes/entity'
import videoShape from 'src/shapes/video'
import useSequence from 'src/lib/useSequence'
import WithPlayButton from 'src/components/WithPlayButton'

const propTypes = {
  letter: shape(entityShape).isRequired,
  video: shape(videoShape),
}

const defaultProps = {
  video: null,
}

const LetterSlide = ({ letter, video }) => {
  const steps = [letter, letter, letter]
  const [step, nextStep] = useSequence(steps)
  return (
    <Slide key={letter.id}>
      <WithPlayButton
        key_={step}
        autoPlay={step > 0}
        entity={steps[step]}
        playNext={nextStep}
        video={video}
      >
        {video && (
          <Div flex flex-1 justify="center">
            <Div column flex-1 style={{ alignSelf: 'center' }}>
              <AspectRatio><Video video={video} /></AspectRatio>
            </Div>
          </Div>
        )}
      </WithPlayButton>
    </Slide>
  )
}

LetterSlide.propTypes = propTypes
LetterSlide.defaultProps = defaultProps

export default LetterSlide
