import compact from 'lodash/compact'
import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'
import replaceWord from 'src/lib/replaceWord'
import stripPunctuation from 'src/lib/stripPunctuation'
import addExtraSpaceBetweenPunctuation from 'src/modules/ParagraphMissingWords/addExtraSpaceBetweenPunctuation'

const INIT = 'ParagraphMissingWords/INIT'
const INITPUNCTUATION = 'ParagraphMissingWords/INITPUNCTUATION'
const MOVE = 'ParagraphMissingWords/MOVE'
const MARK = 'ParagraphMissingWords/MARK'
const RETRY = 'ParagraphMissingWords/RETRY'

const initialState = {
  actualEntities: [],
  availableCardsId: [],
  cards: {},
  choices: [],
  completed: null,
  entitiesById: {},
  places: {},
  words: [],
}

const createExtraPunctuation = punctuationList => (a, letter) => {
  if (a.breakPoint) return a
  if (punctuationList.includes(letter)) {
    return {
      ...a,
      extraPunctuation: a.extraPunctuation.concat(letter),
    }
  }
  return { ...a, breakPoint: true }
}

const createPlace = ({ entityId, placeId, title, word }) => {
  if (title !== word) {
    const punctuationList = [...new Set(word.match(/[.,\\'\\"\\/#!$%\\^&\\*;:{}=><\-_`~“()?]/g))]
    const {
      extraPunctuation: extraPunctuationBeforWord,
    } = word.split('').reduce(createExtraPunctuation(punctuationList), {
      breakPoint: false,
      extraPunctuation: [],
    })
    const beforeWord = extraPunctuationBeforWord.join('')
    const {
      extraPunctuation: extraPunctuationAfterWord,
    } = word.split('').reverse().reduce(createExtraPunctuation(punctuationList), {
      breakPoint: false,
      extraPunctuation: [],
    })
    const afterWord = extraPunctuationAfterWord.reverse().join('')
    return { afterWord, beforeWord, cardId: null, entityId, id: placeId }
  }
  return { cardId: null, entityId, id: placeId }
}

const createWordsAndPlacesAndCards = ({
  saveWord,
  words,
  places,
  cards,
  choices,
  entities,
  punctuation,
}, word) => {
  const title = punctuation ? word : stripPunctuation(word)
  if (choices.indexOf(title.toLowerCase()) > -1) {
    const placeId = `place${size(places)}`
    const cardId = `card${size(cards)}`
    const entityId = filter(entities, { titleEn: title.toLowerCase() })[0].id
    const place = createPlace({ entityId, placeId, title, word })
    const card = { entityId, id: cardId, placeId: null, title }
    return {
      cards: { ...cards, [cardId]: card },
      choices,
      entities,
      places: { ...places, [placeId]: place },
      punctuation,
      words: words.concat([{ title: saveWord.join(' ') }, { placeId }]),
      saveWord: [],
    }
  }
  return {
    cards,
    choices,
    entities,
    places,
    punctuation,
    words,
    saveWord: saveWord.concat(word),
  }
}

const setPlaceStatus = ({ newPlaces, places }, placeId) => (
  {
    newPlaces: {
      ...newPlaces,
      [placeId]: {
        ...places[placeId],
        correct: places[placeId].correct === false ? null : places[placeId].correct },
    },
    places,
  }
)

const isCorrect = ({ newPlaces, places, cards }, placeId) => {
  const cardEntityId = { ...cards[places[placeId].cardId] }.entityId
  const correct = cardEntityId === places[placeId].entityId
  const newplace = { ...places[placeId], correct, id: placeId }
  return {
    cards,
    newPlaces: { ...newPlaces, [placeId]: { ...newplace } },
    places,
  }
}

const createPunctuationEntities = ({ punctuationEntities }, punctuation) => (
  {
    punctuationEntities: punctuationEntities.concat({
      id: `punctuation-${punctuationEntities.length}`,
      titleEn: punctuation,
    }),
  }
)

const getMeaning = (entity) => {
  if (entity.meaning) {
    if (entity.meaning.titleEn) return entity.meaning.titleEn.trim()
  }
  if (entity.titleEn) return entity.titleEn.trim()
  return ''
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const actualEntities = entities
      const [paragraph, ...choices] = entities
      const { id: image } = paragraph
      const paragraphStr = getMeaning(paragraph)
      let seq = 0
      /* eslint-disable-next-line no-plusplus */
      const nextId = () => seq++
      // find parts of text, that match each choice,
      // and replace them with placeholder objects
      const words = choices
        .reduce((words, { titleEn: title, id: entityId }) => (
          replaceWord(words, title, () => ({ entityId, title }))
        ), [paragraphStr])
        .map(word => (
          word.entityId ? { ...word, placeId: `place${nextId()}` } : word
        ))
      const wordPlaces = words.filter(({ entityId }) => entityId)
      const places = keyBy(wordPlaces.map(({ entityId, placeId }) => ({
        cardId: null,
        entityId,
        id: placeId,
      })), 'id')
      const wordPlacesByEntity = keyBy(wordPlaces, 'entityId')
      const cards = keyBy([].concat(
        wordPlaces.map(({ entityId, title, placeId }) => ({
          entityId,
          id: placeId.replace('place', 'card'),
          placeId: null,
          title,
        })),
        // choices not used in text
        choices
          .filter(({ id }) => !wordPlacesByEntity[id])
          .map(({ id, titleEn: title }) => ({
            entityId: id,
            id: `card${nextId()}`,
            placeId: null,
            title,
          })),
      ), 'id')
      const preparedWords = flatten(words.map(word => (
        word.placeId ?
          [word] :
          compact(flatten(word.split('\n').map((line => [line, '\n']))).slice(0, -1))
            .map(title => ({ title }))
      )))
      return {
        ...initialState,
        actualEntities,
        availableCardsId: shuffle(cards),
        cards,
        choices,
        entitiesById: keyBy(entities, 'id'),
        image: image || '',
        places,
        punctuationMode: false,
        words: preparedWords,
      }
    }
    case INITPUNCTUATION: {
      const { entities } = payload
      const entity = uniqShuffle(entities)[0]
      const actualEntities = [entity]
      const image = entity.id || ''
      const meaningTitle = getMeaning(entity)
      const punctuationList = [...new Set(meaningTitle.match(/[.,\\'\\"\\/#!$%\\^&\\*;:{}=><\-_`~“()?]/g))]
      const {
        punctuationEntities,
      } = punctuationList.reduce(createPunctuationEntities, { punctuationEntities: [] })
      const {
        paragraphWithExtraSpace,
      } = meaningTitle.split('').reduce(addExtraSpaceBetweenPunctuation, {
        paragraphWithExtraSpace: [],
        punctuationList,
        space: null,
      })
      const { words, places, cards } = meaningTitle
        ? paragraphWithExtraSpace.join('').trim().split(' ').reduce(createWordsAndPlacesAndCards,
          {
            cards: {},
            choices: punctuationList,
            entities: punctuationEntities,
            places: {},
            punctuation: true,
            words: [],
            saveWord: [],
          })
        : { cards: {}, place: {}, words: [] }
      const availableCardsId = shuffle(filter(cards, { placeId: null }))
      return {
        ...initialState,
        actualEntities,
        availableCardsId,
        cards,
        choices: punctuationList,
        entitiesById: keyBy(payload.entities, 'id'),
        image,
        places,
        punctuationMode: true,
        words,
      }
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      const { places, cards } = moveCards(state, sourceId, targetId)
      const { newPlaces } = Object.keys(places).reduce(setPlaceStatus, { newPlaces: {}, places })
      const availableCardsId = filter(cards, { placeId: null })[0] ? shuffle(filter(cards, { placeId: null })) : []
      return {
        ...state,
        availableCardsId,
        cards,
        places: newPlaces,
      }
    }
    case MARK: {
      const { places, cards } = state
      const { newPlaces } = Object.keys(places)
        .reduce(isCorrect, { cards, newPlaces: {}, places })
      const completed = filter(newPlaces, { correct: false }).length === 0
      return {
        ...state,
        completed,
        places: newPlaces,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const initPunctuation = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INITPUNCTUATION,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  initPunctuation,
  mark,
  moveCard,
  retry,
}

export default reducer
