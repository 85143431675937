import compact from 'lodash/compact'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import uniq from 'lodash/uniq'
import fallbackToEnglish from 'src/modules/MatchCards/fallbackToEnglish'

const INIT = 'matchCards/INIT'
const INIT_SYNONYM = 'matchCards/INIT_SYNONYM'
const CLICK = 'matchCards/CLICK'
const COMPLETED = 'matchCards/COMPLETED'
const RESET = 'matchCards/RESET'
const UPDATE_CARD_ITERATION = 'matchCards/UPDATE_CARD_ITERATION'

const MAX_CARDS = 12

const initialState = {
  actualEntities: [],
  cardIterations: [],
  cards: {},
  completed: null,
  typesLength: 0,
}

const joinEntityWithTypes = entity => (a, type) => {
  const id = `${entity.id}_${type}`
  const card = {
    entity,
    id,
    state: null,
    type,
  }
  return { ...a, [id]: card }
}

const joinEntitiesWithTypes = types => (a, entity) => ({
  ...a,
  ...types.reduce(joinEntityWithTypes(entity), {}),
})

const createCards = (entities, types) => (
  entities.reduce(joinEntitiesWithTypes(types), {})
)

const createCardsFromPairs = types => (a, pair) => {
  const first = pair[0]
  const last = pair[1]
  const typesFirst = [types[0], types[1]]
  const typesLast = [types[2]]
  if (!first || !last) {
    return {
      ...a,
    }
  }
  const firstCards = createCards([first], typesFirst)
  const lastCards = createCards([last], typesLast)
  return {
    ...a,
    ...firstCards,
    ...lastCards,
  }
}

const flipCard = (cards, id) => {
  const card = cards[id]
  const { state } = card
  return ({
    [id]: {
      ...card,
      state: state === 'selected' ? null : 'selected',
    },
  })
}

const convertSelectedTo = (selected, newState) => {
  const reducer = (a, card) => ({
    ...a,
    [card.id]: {
      ...card,
      correct: newState === 'completed' ? true : null,
      state: newState,
    },
  })
  return selected.reduce(reducer, {})
}

const areAllCardsCompleted = (cards) => {
  const completedCards = compact(
    map(cards, ({ state }) => state === 'completed'),
  )
  return completedCards.length === Object.keys(cards).length
}

const findSelected = cards => filter(cards, card => card.state === 'selected')

const findCompletedWords = (cards, typesLength) => {
  const selected = findSelected(cards)
  if (selected.length < typesLength) { return ({}) }
  const ids = uniq(selected.map(card => card.entity.entityId || card.entity.id || card.entity.title))
  if (ids.length === 1) {
    return convertSelectedTo(selected, 'completed')
  }
  return convertSelectedTo(selected, null)
}

const createCardIterations = ({ typesLength, selectedCardNumber = 0, state = null }) => {
  if (state === null) {
    if (selectedCardNumber === 0) return Array(typesLength).fill()
    const cardIterations = Array(typesLength).fill(true, 0, selectedCardNumber)
    if (selectedCardNumber < typesLength) {
      return cardIterations.fill(undefined, selectedCardNumber)
    }
    return cardIterations
  }
  if (state) {
    return Array(1).fill('completed')
  }
  return Array(1).fill('uncompleted')
}

const isCompletedWords = (cards, typesLength) => {
  const selected = findSelected(cards)
  if (selected.length < typesLength) { return ({}) }
  const ids = uniq(selected.map(card => card.entity.entityId || card.entity.id || card.entity.title))
  return ids.length === 1
}

const createCheckCardIterations = ({ selectedCardNumber, typesLength, cards }) => {
  if (selectedCardNumber === typesLength) {
    if (isCompletedWords(cards, typesLength)) {
      return createCardIterations({ state: true })
    }
    return createCardIterations({ state: false })
  }
  return createCardIterations({ selectedCardNumber, typesLength })
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities: allEntities, types } = payload
      const { length } = types
      const entities = uniqShuffle(allEntities).slice(0, MAX_CARDS / length)
      const allCards = createCards(entities, types)
      const cards = pick(
        allCards,
        shuffle(Object.keys(allCards)),
      )

      const cardIterations = createCardIterations({ typesLength: length })
      return {
        ...initialState,
        actualEntities: entities,
        cardIterations,
        cards: fallbackToEnglish(cards),
        typesLength: types.length,
      }
    }

    case INIT_SYNONYM: {
      const { entities: allEntities, types } = payload
      const { length } = types
      const pairedEntities = uniqShuffle(groupBy(allEntities, e => e.entityId || e.id)).slice(0, MAX_CARDS / length)
      const allCards = reduce(pairedEntities, createCardsFromPairs(types), {})
      const cards = pick(
        allCards,
        shuffle(Object.keys(allCards)),
      )
      const cardIterations = createCardIterations({ typesLength: length })
      return {
        ...initialState,
        actualEntities: [].concat(...pairedEntities),
        cardIterations,
        cards: fallbackToEnglish(cards),
        typesLength: types.length,
      }
    }

    case UPDATE_CARD_ITERATION: {
      const { action = 0, length } = payload
      return {
        cardIterations: createCardIterations({ selectedCardNumber: action, typesLength: length }),
      }
    }

    case CLICK: {
      const { cards, typesLength } = state
      const { id } = payload
      const selectedCardNumber = findSelected(cards).length
      if (selectedCardNumber >= typesLength) { return state }
      if (cards[id].state === 'completed') { return state }
      const flippedCard = flipCard(cards, id)
      const cardsAfterFlip = {
        ...cards,
        ...flippedCard,
      }
      const newCards = {
        ...state.cards,
        ...cardsAfterFlip,
      }
      const cardIterations = createCardIterations({ selectedCardNumber: (selectedCardNumber + 1), typesLength })
      return {
        ...state,
        cardIterations,
        cards: newCards,
        lastCardClickedId: id,
      }
    }

    case COMPLETED: {
      const { cards, typesLength } = state
      const cardsWithCompletedWords = findCompletedWords(cards, typesLength)
      const newCards = {
        ...cards,
        ...cardsWithCompletedWords,
      }
      const selectedCardNumber = findSelected(cards).length
      const cardIterations = createCheckCardIterations({ cards, selectedCardNumber, typesLength })
      const completed = areAllCardsCompleted(newCards) || null

      return {
        ...state,
        cardIterations,
        cards: newCards,
        completed,
      }
    }
    case RESET: {
      const { cards, typesLength } = state
      const selectedCardNumber = findSelected(cards).length
      return {
        ...state,
        cardIterations: createCardIterations({ selectedCardNumber, typesLength }),
      }
    }

    default: {
      return state
    }
  }
}

export const initDefault = ({ entities, types, variants }) => ({
  payload: {
    entities,
    types,
    variants,
  },
  type: INIT,
})

const init = ({ entities, types, variants, ...props }) => (dispatch) => {
  dispatch((props[props.initWith] || initDefault).call(null, { entities, types, variants }))
}

const initSynonym = ({ entities, types }) => ({
  payload: {
    entities,
    types,
  },
  type: INIT_SYNONYM,
})

const clickAction = id => ({
  payload: {
    id,
  },
  type: CLICK,
})

const checkForCompleted = () => ({
  meta: {
    debounce: {
      time: 500,
    },
  },
  type: COMPLETED,
})

const checkForReset = () => ({
  meta: {
    debounce: {
      time: 1500,
    },
  },
  type: RESET,
})

const updateCardIteration = ({ action, length }) => ({
  payload: {
    action,
    length,
  },
  type: UPDATE_CARD_ITERATION,
})

const click = id => (dispatch) => {
  dispatch(clickAction(id))
  dispatch(checkForCompleted())
  dispatch(checkForReset())
}

export {
  checkForCompleted,
  click,
  init,
  initialState,
  initSynonym,
  checkForReset,
  updateCardIteration,
}

export default reducer
