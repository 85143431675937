import React from 'react'
import { arrayOf, shape } from 'prop-types'
import chunk from 'lodash/chunk'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import entityShape from 'src/shapes/entity'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'
import WordBoardLine from 'src/modules/WordBoard/WordBoardLine'

const propTypes = {
  entities: arrayOf(
    shape(entityShape).isRequired,
  ).isRequired,
}

const ENTITIES_PER_BOARD = 4

const createSlide = chunks => (
  <Slide key={chunks[0].id}>
    {
      chunks.map((entity, index) => (
        <Div flex flex-col-3 flex-grow key={entity.id}>
          <Div flex column col={2} align="center">
            <EntityImage
              fit
              entity={entity}
              style={{ maxWidth: '8em' }}
              inline-block
            />
          </Div>
          <Div auto flex column col={10}>
            <WordBoardLine
              entity={entity}
              index={index}
              key={entity.id}
            />
          </Div>
        </Div>
      ))
    }
  </Slide>
)

const WordBoard = ({ entities }) => {
  const entitiesChunk = chunk(entities, ENTITIES_PER_BOARD)
  return (
    <Slides className="WordBoard">
      {entitiesChunk.map(chunks => createSlide(chunks))}
    </Slides>
  )
}

WordBoard.propTypes = propTypes

WordBoard.demoData = {
  entities: [
    'father',
    'dad',
    'mother',
    'mum',
    'parents',
    'elder-brother',
    'elder-sister',
    'grandfather',
    'grandmother',
    'family',
  ],
}

export default WordBoard
