import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'src/routes/NoMatch'
import PublicCalendar from 'src/pages/PublicCalendar'

export const path = '/calendar'

const CalendarRoute = () => (
  <Switch>
    <Route exact path="/calendar">
      <PublicCalendar />
    </Route>
  </Switch>
)

export default CalendarRoute
