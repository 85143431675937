import React from 'react'
import { bool, number, oneOf } from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18next'
import { ATTENDANCE, RELEASED, SCHOOL, STUDENT } from 'src/constants/translation'
import Access from 'src/components/Access'
import Div from 'src/components/Div'
import rateToColor, { gradeToColor } from 'src/lib/rateToColor'
import Tooltip from 'src/components/Tooltip'
import calculatePercentage from 'src/lib/calculatePercentage'
import formatActualDecimals from 'src/lib/formatActualDecimals'
import Grade from 'src/components/Grade'

const propTypes = {
  actual: number,
  bold: bool,
  colorByGrade: bool,
  grade: number,
  noColors: bool,
  noTooltip: bool,
  secondDimension: number,
  showRatio: bool,
  total: number,
  type: oneOf([ATTENDANCE, RELEASED, SCHOOL, STUDENT]),
  value: number,
}

const defaultProps = {
  actual: 0,
  bold: true,
  colorByGrade: false,
  grade: 0,
  noColors: false,
  noTooltip: false,
  secondDimension: null,
  showRatio: false,
  total: 0,
  type: SCHOOL,
  value: 0,
}

const getPercentage = (value, total, actual) => {
  if (value) return calculatePercentage(value)
  if (!actual) return 0
  if (!total) return 0
  return calculatePercentage(actual / total)
}

const setColor = ({ colorByGrade, colorByRate, grade, theValue }) => {
  if (colorByGrade) return gradeToColor(grade)
  if (colorByRate) return rateToColor(theValue)
  return 'blue'
}

export const Progress = ({
  actual,
  bold,
  colorByGrade,
  grade,
  noColors,
  noTooltip,
  secondDimension,
  showRatio,
  total,
  type,
  value,
}) => {
  const percentage = getPercentage(value, total, actual)
  const theValue = value || percentage / 100
  return (
    <Div flex fx={1} gutter={1} align="center" style={{ fontSize: '.9rem' }}>
      {secondDimension !== null && (
        <Grade
          grade={grade}
          percentage={secondDimension}
          tips={[`${t('school.grade')} / ${t('school.averageProgress')}`, t(`${type}.for`)]}
          inline-block
          bold
          style={{ width: '6rem' }}
        />
      )}
      <Div auto mx={1}>
        <Tooltip
          tips={['Homework Completed/Assigned']}
          vertical
          disabled={!colorByGrade}
        >
          <progress
            value={theValue}
            max="1"
            className={classNames({
              Progress: true,
              [`progress-bg-${
                setColor({
                  colorByGrade,
                  grade,
                  theValue,
                })
              }`]: !noColors && true,
            })}
          />
        </Tooltip>
      </Div>
      <Div inline-block line-height-2 style={{ width: '4.5rem' }}>
        <Access teacher staff>
          <Div mx={1} bold={bold}>
            <Tooltip
              tips={[t(`${type}.progress.percentDescription`)]}
              disabled={noTooltip}
            >
              <span id="qa-percentage">
                {`${percentage}%`}
              </span>
            </Tooltip>
          </Div>
        </Access>
        {showRatio && (
          <Div mx={1} bold={bold}>
            <Tooltip
              tips={[
                t(`${type}.progress.bodyDescription`),
                `${t([`${type}.progress.actual`, 'progress.actual'])} 
                / 
                ${t([`${type}.progress.total`, 'progress.total'])}`,
              ]}
              disabled={noTooltip}
            >
              <span id="qa-ratio">
                {`${formatActualDecimals(actual)}/${total}`}
              </span>
            </Tooltip>
          </Div>
        )}
      </Div>
    </Div>
  )
}

Progress.propTypes = propTypes
Progress.defaultProps = defaultProps

export default Progress
