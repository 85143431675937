import React, { Component } from 'react'
import { func, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/ListeningPhonemes/reducer'
import ListeningPhonemes from 'src/modules/ListeningPhonemes/ListeningPhonemes'

const propTypes = {
  ...gamePropTypes,
  maxChoices: number.isRequired,
  onClick: func.isRequired,
}

class ListeningPhonemesContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      completed,
      entitiesById,
      answerId,
      cards,
      mark,
      onClick,
      retry,
      started,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <ListeningPhonemes
          autoPlay={started}
          entitiesById={entitiesById}
          answerId={answerId}
          onClick={onClick}
          cards={cards}
        />
      </Game>
    )
  }
}

ListeningPhonemesContainer.propTypes = propTypes
ListeningPhonemesContainer.demoData = {
  entities: [
    'collection-igh',
    'fight',
    'night',
    'fright',
    'eight',
    'collection-y',
    'fry',
    'why',
    'cry',
    'try',
  ],
}

const select = state => state.listeningPhonemes
const actions = dispatch => bindActionCreators(gameActions, dispatch)
export { ListeningPhonemesContainer }
export default connect(select, actions)(ListeningPhonemesContainer)
