import React, { useContext } from 'react'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { UserContext } from 'src/components/SetUser/SetUser'
import { linkTo } from 'src/lib/linkTo'
import { portalPath as homeworkPath } from 'src/pages/StudentHomeworkPage/config'
import StudentBar from 'src/pages/Students/StudentBar'

const propTypes = {
  name: string.isRequired,
  slug: string.isRequired,
}

const StudentRow = ({ slug: studentId, name }) => {
  const { slug: parentId } = useContext(UserContext)
  return (
    <Div>
      <Div mb1 h4>
        <Link to={linkTo(homeworkPath)({ parentId, studentId })}>
          <span className="bold">
            <Icon icon="child" />
            {name}
          </span>
        </Link>
      </Div>
      <StudentBar studentId={studentId} />
    </Div>
  )
}

StudentRow.propTypes = propTypes

export default StudentRow
