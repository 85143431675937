import React from 'react'
import { number, string, object } from 'prop-types'
import Img from 'src/components/Img'
import Div from 'src/components/Div'

const propTypes = {
  disappearTargetImage: string,
  height: number,
  style: object,
  targetImage: string,
  width: number,
}

const defaultProps = {
  disappearTargetImage: null,
  height: 15,
  style: {},
  targetImage: null,
  width: 18,
}

const DisappearTarget = ({ height, width, targetImage, style, disappearTargetImage }) => (
  <Div flex auto>
    <Div absolute z1 height={height} width={width} style={{ ...style }}>
      <Img rounder src={disappearTargetImage || targetImage} />
    </Div>
  </Div>
)

DisappearTarget.propTypes = propTypes
DisappearTarget.defaultProps = defaultProps

export default DisappearTarget
