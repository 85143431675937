import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showSoftKeyboard } from 'src/components/SoftKeyboard/reducer'
import { GameContext } from 'src/lib/contexts'
import Answer from 'src/components/Answer/Answer'

const AnswerContainer = props => (
  <GameContext.Consumer>
    {({ guide, unitTest }) => <Answer {...props} guide={guide} unitTest={unitTest} />}
  </GameContext.Consumer>
)

const actions = dispatch => bindActionCreators({ showSoftKeyboard }, dispatch)

export { AnswerContainer }

export default connect(null, actions)(AnswerContainer)
