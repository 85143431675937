import React, { Component } from 'react'

import { mobileLanscape } from 'src/lib/window'
import Footer from 'src/components/Footer'
import NavBar from 'src/components/NavBar'
import queue from 'src/lib/queue'
import Div from 'src/components/Div'

class TheLayout extends Component {
  componentDidMount() {
    queue(() => mobileLanscape())
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <Div flex column className="vh100" pb4 style={{ cursor: 'default' }}>
          <NavBar />
          { children }
        </Div>
        <Footer />
      </div>
    )
  }
}

export default TheLayout
