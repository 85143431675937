import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'
import Div from 'src/components/Div'
import Donut from 'src/components/Donut'
import yearShape from 'src/shapes/year'
import Stats from 'src/pages/DashboardPage/AttendanceOverview/Stats'
import AttendanceYear from 'src/pages/AttendancePage/AttendanceToday/AttendanceYear'

const propTypes = {
  present: number.isRequired,
  title: string.isRequired,
  total: number.isRequired,
  years: arrayOf(shape(yearShape)),
}

const defaultProps = {
  years: [],
}

const AttendanceOverviewSection = ({
  present,
  title,
  total,
  years,
}) => {
  const value = present / total
  return (
    <div>
      <div className="mb1 center">
        <Stats {...{ present, title, total }} />
      </div>
      <Div flex px={2} justify="center">
        <Donut value={value || 0} />
      </Div>
      <Div flex flex-wrap gutter>
        {years.map(year => (
          <Div key={year.id} flex-1>
            <AttendanceYear {...year} />
          </Div>
        ))}
      </Div>
    </div>
  )
}

AttendanceOverviewSection.propTypes = propTypes
AttendanceOverviewSection.defaultProps = defaultProps
export default AttendanceOverviewSection
