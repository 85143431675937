import keyBy from 'lodash/keyBy'
import size from 'lodash/size'
import every from 'lodash/every'
import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'

const INIT = 'comicStrip/INIT'
const MARK = 'comicStrip/MARK'
const RETRY = 'comicStrip/RETRY'
const MOVE = 'comicStrip/MOVE'

const initialState = {
  actualEntities: [],
  availableCardsId: [],
  cards: {},
  completed: null,
  entitiesById: {},
  placeList: [],
}

const createPlacesAndCards = (a, entity) => {
  const id = `place-${size(a.places)}`
  const cardId = a.shuffleEntitiy[size(a.places)].id
  return {
    ...a,
    cards: {
      ...a.cards,
      [cardId]: {
        id: cardId,
        placeId: id,
      },
    },
    places: {
      ...a.places,
      [id]: {
        cardId,
        entityId: entity.id,
        id,
      },
    },
  }
}

const checkPlaceCorrect = (a, placeId) => (
  {
    ...a,
    newPlace: {
      ...a.newPlace,
      [placeId]: {
        ...a.places[placeId],
        correct: a.places[placeId].entityId === a.places[placeId].cardId,
      },
    },
  }
)

const clearPlaceStatus = (a, placeId) => (
  {
    ...a,
    newPlace: {
      ...a.newPlace,
      [placeId]: {
        ...a.places[placeId],
        correct: a.places[placeId].correct ? true : null,
      },
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const { cards, places } = entities.reduce(createPlacesAndCards, {
        shuffleEntitiy: uniqShuffle(entities),
      })
      return {
        ...initialState,
        actualEntities: entities,
        cards,
        entitiesById: keyBy(entities, 'id'),
        placeList: Object.keys(places),
        places,
      }
    }
    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      const { newPlace } = Object.keys(places).reduce(clearPlaceStatus, { places })
      return {
        ...state,
        cards,
        places: newPlace,
      }
    }
    case MARK: {
      const { places } = state
      const { newPlace } = Object.keys(places).reduce(checkPlaceCorrect, { places })
      const completed = every(newPlace, ['correct', true])
      return {
        ...state,
        completed,
        places: newPlace,
      }
    }
    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }
    default: {
      return state
    }
  }
}

const init = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT,
})

const mark = () => ({
  type: MARK,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  mark,
  retry,
  moveCard,
}
export default reducer
