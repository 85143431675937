import React from 'react'
import PhonicBlends from 'src/modules/PhonicBlends/PhonicBlendsGame'

const PhonicBlends1 = props => <PhonicBlends {...props} chunkSize={1} />
const PhonicBlends2 = props => <PhonicBlends {...props} chunkSize={2} />

PhonicBlends1.demoData = {
  entities: [
    'mop',
    'phonics-m',
    'phonics-o',
    'phonics-p',
    'glue',
    'phonics-g',
    'phonics-l',
    'phonics-ue',
    'fry',
    'phonics-f',
    'phonics-r',
    'phonics-ai',
  ],
}

PhonicBlends2.demoData = {
  entities: [
    'mop',
    'phonics-m',
    'phonics-o',
    'phonics-p',
    'glue',
    'phonics-g',
    'phonics-l',
    'phonics-ue',
  ],
}

export {
  PhonicBlends1,
  PhonicBlends2,
}
