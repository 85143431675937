import { t } from 'i18next'
import isFunction from 'lodash/isFunction'
import { successMessage, warningMessage } from 'src/lib/toaster'

const withToast = ({
  success = t('shared.done'),
  error = t('shared.error'),
} = {}) => async (block) => {
  try {
    const result = await block()
    if (success) successMessage({ title: isFunction(success) ? success(result) : success })
  } catch (err) {
    if (error) warningMessage({ title: isFunction(error) ? error(err) : error })
  }
}

export default withToast
