import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import shuffle from 'lodash/shuffle'
import startsWith from 'lodash/startsWith'
import uniqShuffle from 'src/lib/uniqShuffle'
import splitWhile from 'src/lib/splitWhile'
import splitIntoChunks from 'src/lib/splitIntoChunks'

const splitIntoWords = splitIntoChunks((entities) => {
  const [word, ...withoutWord] = entities
  const [phonics, tail] = splitWhile(withoutWord, ({ slug }) => startsWith(slug, 'phonics-'))
  return [[word, ...phonics], tail]
})

const initialState = {
  choices: [],
  choicesOrder: [],
  completed: null,
  phonics: {},
  places: [],
  words: [],
}

const INIT = 'phonicBlends/INIT'
const MOVE = 'phonicBlends/MOVE'
const RETRY = 'phonicBlends/RETRY'
const MARK = 'phonicBlends/MARK'

const init = ({ entities, chunkSize }) => ({ type: INIT, payload: { entities, chunkSize } })
const move = ({ choiceId, placeId }) => ({ type: MOVE, payload: { choiceId, placeId } })
const retry = () => ({ type: RETRY })
const mark = () => ({ type: MARK })

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities, chunkSize } = payload
      const words = uniqShuffle(chunk(splitIntoWords(entities), chunkSize || 1))[0]
      const phonics = keyBy(flatten(
        words.map(([word, ...phonics]) => phonics.map((p, i) => ({
          ...p,
          choiceId: `choice-${word.id}-${p.id}-${i}`,
        }))),
      ), 'choiceId')
      const choices = shuffle(Object.keys(phonics))
      return {
        ...initialState,
        choices,
        choicesOrder: choices,
        phonics,
        places: flatten(words.map(([word, ...phonics]) => phonics.map(({ id }, i) => ({
          word: word.id,
          id: `place-${word.id}-${id}-${i}`,
          correct: id,
          value: null,
          success: null,
          failure: null,
        })))),
        words: words.map(([word]) => word),
      }
    }

    case MOVE: {
      const { choiceId, placeId } = payload
      const { choicesOrder, places, phonics } = state
      const nextPlaces = places.map((p) => {
        if (p.id === placeId) {
          return {
            ...p,
            value: choiceId,
            success: null,
            failure: null,
          }
        }
        if (p.value === choiceId) {
          return {
            ...p,
            // swap values if dragging between places
            value: places.find(({ id }) => id === placeId).value,
            success: null,
            failure: null,
          }
        }
        return p
      })
      const notPicked = Object.keys(phonics).filter(c => !nextPlaces.find(p => p.value === c))
      return {
        ...state,
        choices: choicesOrder.filter(c => notPicked.includes(c)),
        places: nextPlaces,
      }
    }

    case MARK: {
      const { places: prevPlaces, phonics } = state
      const places = prevPlaces.map((place) => {
        const { correct, value } = place
        const isCorrect = !!(value && correct === phonics[value].id)
        return {
          ...place,
          success: isCorrect,
          failure: !isCorrect,
        }
      })
      return {
        ...state,
        completed: !places.find(({ failure }) => failure),
        places,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  mark,
  move,
  retry,
}

export default reducer
