import React from 'react'
import { bool, string, number, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import Progress from 'src/components/Progress'
import Grade from 'src/components/Grade'

const propTypes = {
  isSection: bool,
  resource: shape({
    assignedHomeworkCount: number,
    availableHomeworkCount: number,
    completedHomeworkCount: number,
    homeworkGrade: number,
    homeworkRate: number,
    studentsCount: number,
    title: string,
  }).isRequired,
}

const defaultProps = {
  isSection: false,
}

const HomeworkPageTableRow = ({ linkTo, resource, isSection }) => (
  <tr id={isSection ? `qa-section-${resource.id}` : `qa-year-${resource.id}`}>
    <td className={isSection ? 'bold qa-title' : 'qa-title'}>
      <Link to={linkTo}>
        {resource.title}
      </Link>
    </td>
    <td className="qa-students-count">{resource.studentsCount}</td>
    <td className="qa-grade">
      <Grade grade={resource.homeworkGrade} percentage={resource.homeworkRate} />
    </td>
    <td>
      <Progress
        actual={resource.completedHomeworkCount}
        total={resource.assignedHomeworkCount}
        showRatio
        noTooltip
      />
    </td>
    <td>
      <Progress
        actual={resource.assignedHomeworkCount}
        total={resource.availableHomeworkCount}
        showRatio
        noTooltip
      />
    </td>
  </tr>
)

HomeworkPageTableRow.propTypes = propTypes
HomeworkPageTableRow.defaultProps = defaultProps

export default HomeworkPageTableRow
