import React from 'react'
import sortBy from 'lodash/sortBy'
import { array, shape } from 'prop-types'
import { t } from 'i18next'

import Icon from 'src/components/Icon'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import Divider from 'src/components/Divider'
import ParentRow from 'src/pages/Students/ParentRow'
import StudentRow from 'src/pages/Students/StudentRow'
import Widget from 'src/components/Widget'
import { CHILDREN, PARENTS } from 'src/constants/menu'
import Modal, { OpenModal } from 'src/components/Modal'
import StudentCreate from 'src/pages/Students/StudentCreate'
import { AddStudent } from 'src/pages/Registration/RegistrationForm'

const propTypes = {
  attendanceReport: shape({
    sections: array,
  }),
  parentUser: shape({
    parentsOfChildren: array,
  }).isRequired,
}

const defaultProps = {
  attendanceReport: {
    sections: [],
  },
}

const Year = ({ id, title, students }) => (
  <div>
    <h4><Icon className="blue muted" icon="users" />{title}</h4>
    {sortBy(students, 'name').map((user, i) => (
      <div key={user.id}>
        {!!i && <Divider my1 />}
        <StudentRow key={user.id} {...user} yearId={id} />
      </div>
    ))}
  </div>
)

const Section = ({ title, years }) => (
  <Div pb1>
    <h4 className="muted"><Icon clasName="blue" icon="building" />{title}</h4>
    {sortBy(years, 'title').map((year, i) => (
      <div key={year.id}>
        {!!i && <Divider mxn2 my1 />}
        <Year key={year.id} {...year} />
      </div>
    ))}
  </Div>
)

const MODAL_ID = 'add'

const Students = ({
  attendanceReport: { sections },
  parentUser: { parentsOfChildren },
}) => (
  <Deck>
    <Modal id={MODAL_ID}>
      {({ closeModal }) => <StudentCreate onCompleted={closeModal} />}
    </Modal>
    <Div mb1>
      <Widget token={CHILDREN} title={t(`${CHILDREN}.title`)}>
        {sections &&
          sections.map((section) => <Section key={section.id} {...section} />)}
        <OpenModal id={MODAL_ID}>
          <AddStudent />
        </OpenModal>
      </Widget>
    </Div>
    <Div>
      <Widget token={PARENTS} title={t(`${PARENTS}.title`)}>
        <Div pb1>
          {parentsOfChildren.map((parent) => (
            <ParentRow key={parent.id} parent={parent} />
          ))}
        </Div>
      </Widget>
    </Div>
  </Deck>
)

Students.propTypes = propTypes
Students.defaultProps = defaultProps

export default Students
