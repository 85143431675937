import React from 'react'
import { bool, float, func, shape, string } from 'prop-types'
import classnames from 'classnames'
import { t } from 'i18next'

import gradeSystem from 'src/../config/gradeToColor.json'

import Div from 'src/components/Div'
import Divider from 'src/components/Divider'
import TypeAndSave from 'src/modules/TypeAndSave/TypeAndSave'

const propTypes = {
  chapter: shape({
    id: string.isRequired,
    lastAnswer: shape({
      grade: float,
      id: string,
      isGradable: bool,
      user: shape({
        id: string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  handleGrade: func.isRequired,
}

const GRADES = [0, 1, 1.5, 2, 2.5, 3, 3.5, 4]

const gradingButton = ({ grade, isDisabled, isSelected, onClick }) => (
  <Div
    key={grade}
    align="center"
    bg={gradeSystem[grade].color}
    bold
    className={classnames({
      'button-hover cursor-pointer bold': !isDisabled,
      muted: isDisabled,
      [`qa-assessment-grade-${grade}`]: true,
      'qa-assessment-grade-button': true,
      'qa-assessment-selected-grade': isSelected,
      white: true,
    })}
    col={3}
    flex
    justify="center"
    onClick={onClick}
    style={{
      borderColor: isSelected ? 'black' : 'transparent',
      borderStyle: 'solid',
      borderWidth: '3px',
      margin: '0 1px',
      padding: '5px',
    }}
  >
    {grade}
  </Div>
)

const AssessmentWrapper = ({
  chapter: {
    lastAnswer: {
      id: homeworkAnswerId,
      grade,
      gradedAt,
      isGradable,
      answer,
      props: {
        iterations,
      },
    }, entities },
  handleGrade,
}) => {
  const { entities: entitySlugs = [] } = ((iterations || [])[0] || {})
  const question = entities.find(e => entitySlugs.includes(e.slug)) || {}
  const isDisabled = !isGradable
  const selectedGrade = gradedAt ? grade : null

  return (
    <div>
      <Div className="assessment-wrapper" col={12} p={1} py={3}>
        <TypeAndSave question={question} answer={answer} disabled />
      </Div>
      <Div
        className="no-text-selection"
        flex
        justify="center"
        noPrint
        pb2
        pt4
        relative
        z1
      >
        <Div ap1 bold col={3} flex style={{ margin: '0 1px' }}>
          {t('assessment.grade')}:
        </Div>
        {
          GRADES.map(grade => gradingButton({
            grade,
            isDisabled,
            isSelected: grade === selectedGrade,
            onClick: () => !isDisabled && handleGrade({ grade, homeworkAnswerId }),
          }))
        }
      </Div>

      <Divider className="mb3" />
    </div>
  )
}

AssessmentWrapper.propTypes = propTypes
export default AssessmentWrapper
