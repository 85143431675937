import React from 'react'
import { string } from 'prop-types'
import Icon from 'src/components/Icon'
import { t } from 'i18next'

const propTypes = {
  errorMessage: string,
}

const defaultProps = {
  errorMessage: '',
}

const ErrorMessage = ({ errorMessage, values = {} }) =>
  !!errorMessage && (
    <div className="my2 rounded bg-yellow brown p1">
      <Icon icon="exclamation-triangle" before />
      {t(errorMessage, values)}
    </div>
  )

ErrorMessage.propTypes = propTypes
ErrorMessage.defaultProps = defaultProps
export default ErrorMessage
