import { ApolloProvider } from '@apollo/react-hooks'
import { Provider } from 'react-redux'
import React from 'react'
import ReduxToastr from 'react-redux-toastr'
import { BrowserRouter } from 'react-router-dom'
import { NProgress } from '@tanem/react-nprogress'

import { Bar, Container, Spinner } from 'src/components/LoadingBar'
import { ReactRouterGA } from 'src/components/analytics'
import LocaleProvider from 'src/components/LocaleProvider'
import store from 'src/store'
import 'src/css/index.css'
import 'src/lib/debug'
import Layout from 'src/pages/Layout'
import { bindMobileLandscapeWhenActive } from 'src/lib/window'
import client, { NetworkStatusNotifier } from 'src/lib/apolloClient'
import { TOASTR_OPTIONS } from 'src/config'
import { loadSettings } from 'src/components/TenantSettings/reducer'

store.dispatch(loadSettings(client))
bindMobileLandscapeWhenActive()

const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <NetworkStatusNotifier
          render={({ loading }) => (loading ? (
            <NProgress isAnimating={loading}>
              {({ isFinished, progress, animationDuration }) => (
                <Container
                  isFinished={isFinished}
                  animationDuration={animationDuration}
                >
                  <Bar
                    progress={progress}
                    animationDuration={animationDuration}
                  />
                  <Spinner />
                </Container>
              )}
            </NProgress>
          ) : null)
          }
        />
        <LocaleProvider>
          <BrowserRouter>
            <ReactRouterGA debug>
              <Layout />
            </ReactRouterGA>
          </BrowserRouter>
          <ReduxToastr {...TOASTR_OPTIONS} />
        </LocaleProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
)

export default App
