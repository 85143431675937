import collectionProvider from 'src/lib/collectionProvider'
import WordSpelling from 'src/modules/Word/WordSpelling/WordSpelling'

const WordSpellingContainer = collectionProvider({ collections: ['alphabetEn'] })(WordSpelling)

WordSpellingContainer.demoData = {
  entities: ['family'],
}

export default WordSpellingContainer
