import { formatDayOfWeek, rangeBy } from 'src/lib/dates'

const isWeekend = date => [0, 6].includes(Number(formatDayOfWeek(date)))

const toRanges = events => (
  events.map(({ startDate, endDate }) => rangeBy(startDate, endDate, 'days'))
)

const isAcademicDay = (calendarEvents = []) => {
  const ranges = toRanges(calendarEvents.filter(ev => ev.holiday))
  return (date) => {
    if (isWeekend(date)) return false
    if (ranges.find(r => r.indexOf(date) !== -1)) return false
    return true
  }
}

export {
  isWeekend,
}
export default isAcademicDay
