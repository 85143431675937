import React from 'react'
import Div from 'src/components/Div'
import uniqueId from 'lodash/uniqueId'
import { number } from 'prop-types'
import rateToColor from 'src/lib/rateToColor'
import calculatePercentage from 'src/lib/calculatePercentage'

const propTypes = {
  fixed: number,
  strokeWidth: number,
  value: number,
}

const defaultProps = {
  fixed: 2,
  strokeWidth: 7,
}

const Donut = ({ value, strokeWidth, fixed }) => {
  let total = Number.isNaN(value) ? 0 : calculatePercentage(value, fixed)
  const color = Number.isNaN(value) ? 'red' : rateToColor(value.toFixed(2))
  if (total && total % 1 === 0) total = parseInt(total, 10)
  const id = uniqueId('Donut-circle')
  return (
    <Div flex fx={1} justify="center" align="center" column className="Donut">
      <svg viewBox="0 0 34 34">
        <defs>
          <g id={id}>
            <circle
              id={`${id}-base`}
              cx="17"
              cy="17"
              r="15.91549430918952"
              fill="transparent"
              className={color}
              opacity="0.2"
            />
            <circle
              id={`${id}-segment`}
              cx="17"
              cy="17"
              r="15.91549430918952"
              fill="transparent"
              className={color}
              strokeDasharray={`${total} ${100 - total}`}
              strokeDashoffset="25"
            />
          </g>
          <clipPath id={`${id}-clip`}>
            <use xlinkHref={`#${id}-base`} />
          </clipPath>
        </defs>
        <g>
          <use
            id="qa-Donut"
            xlinkHref={`#${id}`}
            strokeWidth={strokeWidth}
            clipPath={`url(#${id}-clip)`}
          />
          <text
            id="qa-Donut-text"
            x="50%"
            y="50%"
            fontSize={`${total}`.length > 3 ? 7 : 8}
            className={color}
            textAnchor="middle"
            alignmentBaseline="central"
            strokeOpacity={0}
          >
            {total}%
          </text>
        </g>
      </svg>
    </Div>
  )
}

Donut.defaultProps = defaultProps
Donut.propTypes = propTypes

export default Donut
