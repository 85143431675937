import React from 'react'
import { array, bool, func, string } from 'prop-types'
import { t } from 'i18next'
import { ALL } from 'src/constants/shared'
import PrefixedLink from 'src/components/PrefixedLink'
import Div from 'src/components/Div'

const propTypes = {
  gradeSlug: string,
  redirectPath: func.isRequired,
  sections: array,
  showAll: bool,
  subjectSlug: string,
}

const defaultProps = {
  gradeSlug: '',
  sections: [],
  showAll: false,
  subjectSlug: 'all',
}

const GradeFilter = ({
  gradeSlug: selectedGradeSlug,
  redirectPath,
  sections,
  showAll,
  subjectSlug: selectedSubjectSlug,
}) => {
  const sectionAll = { grades: [{ id: '', slug: '', title: t(ALL) }], id: 'all' }
  const filterSections = showAll ? [sectionAll, ...sections] : sections
  return (
    <Div flex wrap print-m0 gutter={1}>
      {filterSections.map(({ id: sectionId, grades }) => (
        <Div key={sectionId} mb={1}>
          <Div flex wrap print-m0 gutter>
            {grades.map(({ slug, title }, index) => (
              <Div key={slug}>
                <Div print-show bold>{selectedGradeSlug === slug && title}</Div>
                <Div print-hide>
                  <PrefixedLink
                    to={redirectPath({ gradeSlug: slug, subjectSlug: selectedSubjectSlug })}
                    className={`
                      bold px2 py1 border inline-block rounded button-hover
                      ${((selectedGradeSlug === slug)
                        || (index === 0 && selectedGradeSlug === 'all'))
                      && 'bg-blue white'}
                    `}
                  >
                    {title}
                  </PrefixedLink>
                </Div>
              </Div>
            ))}
          </Div>
        </Div>
      ))}
    </Div>
  )
}

GradeFilter.defaultProps = defaultProps
GradeFilter.propTypes = propTypes

export default GradeFilter
