import React from 'react'
import { Route, Switch } from 'react-router-dom'
import UnitTest from 'src/pages/UnitTest'

export const path = '/unit-test/:lessonSlug'

const UnitTestRoute = () => (
  <Switch>
    <Route
      path="/unit-test/:lessonSlug"
      component={UnitTest}
    />
  </Switch>
)

export default UnitTestRoute
