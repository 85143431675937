import React, { useContext } from 'react'
import { renderRoutes } from 'react-router-config'

import Div from 'src/components/Div'
import PageTitle from 'src/components/ui/PageTitle'
import SchoolIdleTimer from 'src/components/SchoolIdleTimer'
import GlobalAudioPlayer from 'src/components/GlobalAudioPlayer'
import SoftKeyboard from 'src/components/SoftKeyboard'

import MobileLoading from 'src/pages/Layout/MobileLoading'
import NetworkError from 'src/pages/Layout/NetworkError'
import routes from 'src/routes'
import { LocaleContext } from 'src/lib/contexts'

const Layout = ({ networkStatusConnected }) => {
  const { locale, schoolTitle } = useContext(LocaleContext)
  if (!networkStatusConnected) return <NetworkError />
  return (
    <Div flex column vh100 relative>
      <SoftKeyboard />
      <SchoolIdleTimer />
      <PageTitle>{schoolTitle[locale] || 'Learn21'}</PageTitle>
      <MobileLoading />
      <GlobalAudioPlayer />
      {
        renderRoutes(routes)
      }
    </Div>
  )
}

export default Layout
