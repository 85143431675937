import React from 'react'
import { arrayOf, bool, float, func, shape, string } from 'prop-types'
import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import { t } from 'i18next'
import gqlLoader from 'src/lib/gqlLoader'
import { successMessage } from 'src/lib/toaster'
import lessonQuery from 'src/pages/AssessmentWrapper/lesson.gql'
import chapterQuery from 'src/pages/AssessmentWrapper/chapter.gql'
import mutation from 'src/pages/AssessmentWrapper/gradeHomework.gql'
import AssessmentWrapper from 'src/pages/AssessmentWrapper/AssessmentWrapper'

const propTypes = {
  chapter: shape({
    id: string.isRequired,
    lastAnswer: shape({
      grade: float,
      id: string,
      isGradable: bool,
      user: shape({
        id: string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  lesson: shape({ chapters: arrayOf(shape()).isRequired }).isRequired,
  mutate: func.isRequired,
}

const showSuccessMessage = () => {
  const title = t('assessment.marked')
  const message = t('save.success')
  successMessage({ message, title })
}

export const AssessmentWrapperContainer = ({ chapter, lesson, mutate }) => {
  const updateHomeworkAnswer = async ({ grade, homeworkAnswerId }) => {
    const params = { grade, homeworkAnswerId }
    await mutate({
      refetchQueries: ['ChapterWithLastAnswer'],
      variables: params,
    })
    showSuccessMessage()
  }
  return (
    <AssessmentWrapper
      chapter={chapter}
      handleGrade={updateHomeworkAnswer}
      lesson={lesson}
    />
  )
}

AssessmentWrapperContainer.propTypes = propTypes

export default compose(
  gqlLoader(chapterQuery),
  gqlLoader(lessonQuery),
  graphql(mutation)
)(AssessmentWrapperContainer)
