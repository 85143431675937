import shuffle from 'lodash/shuffle'
import startsWith from 'lodash/startsWith'
import keyBy from 'lodash/keyBy'
import uniqShuffle from 'src/lib/uniqShuffle'
import splitIntoChunks from 'src/lib/splitIntoChunks'
import splitWhile from 'src/lib/splitWhile'

const chunks = splitIntoChunks((entities) => {
  const [word, ...rest] = entities
  const [answers, tail] = splitWhile(rest, ({ slug }) => startsWith(slug, 'phonics-'))
  return [[word, ...answers], tail]
})

const initialState = {
  answers: [],
  entitiesById: {},
  selected: null,
  completed: null,
}

const INIT = 'oddOneOut/INIT'
const SELECT = 'oddOneOut/SELECT'
const RETRY = 'oddOneOut/RETRY'
const MARK = 'oddOneOut/MARK'

const init = ({ entities }) => ({ type: INIT, payload: { entities } })
const select = answerId => ({ type: SELECT, payload: { answerId } })
const retry = () => ({ type: RETRY })
const mark = () => ({ type: MARK })

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const [word, correct, ...incorrect] = uniqShuffle((chunks(entities)))[0]
      return {
        ...initialState,
        entitiesById: keyBy(entities, 'id'),
        word: word.id,
        correct: correct.id,
        answers: shuffle([...incorrect, correct]).map(({ id }) => id),
      }
    }

    case SELECT: {
      const { answerId } = payload
      return {
        ...state,
        selected: answerId,
      }
    }

    case RETRY: {
      return {
        ...state,
        selected: null,
        completed: null,
      }
    }

    case MARK: {
      const { selected, correct } = state
      return {
        ...state,
        selected: null,
        completed: selected === correct,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  select,
  retry,
  mark,
}

export default reducer
