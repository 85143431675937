import React from 'react'
import { GameContext } from 'src/lib/contexts'
import Card from 'src/components/Card/Card'

const CardContainer = props => (
  <GameContext.Consumer>
    {({ isPreview }) => <Card {...props} isPreview={isPreview} />}
  </GameContext.Consumer>
)

export default CardContainer
