import React from 'react'
import { string, shape, arrayOf } from 'prop-types'
import Div from 'src/components/Div'
import { formatRange } from 'src/lib/dates'
import { t } from 'i18next'

const propTypes = {
  events: arrayOf(shape({
    name: string.isRequired,
    startDate: string.isRequired,
    endDate: string.isRequired,
  })).isRequired,
}

const HolidayList = ({ events }) => (
  <Div>
    <Div h5>{t('school.calendar.schoolHolidays')}:</Div>
    {events.map(({ name, startDate, endDate }) => (
      <div className="qa-event" key={name}>
        {formatRange(startDate, endDate)} <b>{name}</b>
      </div>
    ))}
  </Div>
)

HolidayList.propTypes = propTypes

export default HolidayList
