import React from 'react'

const hoverHide = (hoverHiddenFlex, disableHoverOnMobile) => {
  if (hoverHiddenFlex) {
    return disableHoverOnMobile
      ? 'hover-hide-flex-disable-mobile-hover'
      : 'hover-hide-flex'
  }
  return disableHoverOnMobile ? 'hover-hide-disable-mobile-hover' : 'hover-hide'
}
const Hover = ({
  children,
  className,
  hoverHiddenShow,
  hoverHiddenFlex,
  hideClassName,
  hoverStyle,
  disableHoverOnMobile,
  onHover,
  showClassName,
}) => (
  <div className={`hover ${className}`}>
    <div
      className={`${hoverHiddenShow ? 'hover-show-hidden' : 'hover-show'} ${showClassName}`}
    >
      {children}
    </div>
    <div
      className={`
        ${hoverHide(hoverHiddenFlex, disableHoverOnMobile)}
        col-12
        ${hideClassName}
        ${disableHoverOnMobile && 'disable-mobile-hover'}
      `}
      style={hoverStyle}
    >
      {onHover}
    </div>
  </div>
)

export default Hover
