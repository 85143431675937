import React from 'react'
import { dateToday, startOf, add, endOf, subtract } from 'src/lib/dates'

import AttendanceOverview from 'src/pages/DashboardPage/AttendanceOverview'
import HomeworkAssignments from 'src/pages/SchoolDashboardPage/HomeworkAssignments'
import YearCalendar from 'src/components/YearCalendar'
import Div from 'src/components/Div'

const SchoolDashboardPage = () => {
  const startDate = startOf(dateToday(), 'month')
  const endDate = endOf(subtract(add(startDate, 4, 'months'), 1, 'day'), 'month')
  return (
    <Div>
      <Div flex flex-column bg-white mb1>
        <AttendanceOverview />
        <YearCalendar
          date={startDate}
          startDate={startDate}
          endDate={endDate}
        />
      </Div>
      <HomeworkAssignments />
    </Div>

  )
}

export const path = '/school'
export default SchoolDashboardPage
