import every from 'lodash/every'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import shuffle from 'lodash/shuffle'
import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'
import stripPunctuation from 'src/lib/stripPunctuation'

const INIT = 'ListenAndOrderSentences/INIT'
const MOVE = 'ListenAndOrderSentences/MOVE'
const MARK = 'ListenAndOrderSentences/MARK'
const RETRY = 'ListenAndOrderSentences/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  completed: null,
  lines: {},
  places: {},
}

const createTitleArray = word => (
  word.split(' ').filter(Boolean).map(x => stripPunctuation(x).trim())
)

const checkCards = places => (a, card) => {
  const { id } = card
  return {
    ...a,
    [id]: {
      ...card,
      correct: card.placeId && card.title === places[card.placeId].title,
    },
  }
}

const reStateCards = (a, card) => {
  const { id } = card
  return {
    ...a,
    [id]: {
      ...card,
      correct: card.correct ? true : null,
    },
  }
}

const createPlaces = (lineId, randomIds) => (a, title, i) => {
  const id = `p-${lineId}-${i}`
  const randomId = `c-${lineId}-${randomIds[i]}`
  return {
    ...a,
    [id]: {
      cardId: randomId,
      id,
      title,
    },
  }
}

const createCards = (lineId, randomIds) => (a, title, i) => {
  const id = `c-${lineId}-${i}`
  const randomId = `p-${lineId}-${randomIds.indexOf(i)}`
  return {
    ...a,
    [id]: {
      id,
      placeId: randomId,
      title,
    },
  }
}

const createLinesFromEntities = (entity) => {
  const { id, titleEn } = entity
  const words = createTitleArray(titleEn)
  const randomIds = uniqShuffle([...Array(words.length).keys()])
  const places = words.reduce(createPlaces(id, randomIds), {})
  const cards = shuffle(words).reduce(createCards(id, randomIds), {})
  const line = map(places, 'id').map((placeId, i) => ({ placeId, title: words[i] }))

  return {
    cards,
    entity,
    id: entity.id,
    line,
    places,
    title: titleEn,
  }
}

const extractLinesCardsPlaces = (a, eachLine) => {
  const { places, cards, ...line } = eachLine
  return {
    ...a,
    cards: {
      ...a.cards,
      ...cards,
    },
    lines: {
      ...a.lines,
      [line.id]: line,
    },
    places: {
      ...a.places,
      ...places,
    },
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities: allEntities, rows } = payload
      const entities = uniqShuffle(allEntities).slice(0, rows || 1)
      const eachLine = entities.map(createLinesFromEntities)
      const { lines, cards, places } = eachLine.reduce(extractLinesCardsPlaces, {})
      return {
        ...initialState,
        actualEntities: entities,
        cards,
        lines,
        places,
        rows,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { cards, places } = moveCards(state, sourceId, targetId)
      const newCards = reduce(cards, reStateCards, {})
      return {
        ...state,
        cards: newCards,
        places,
      }
    }

    case MARK: {
      const { cards, places } = state
      const newCards = reduce(cards, checkCards(places), {})
      const completed = every(newCards, 'correct')
      return {
        ...state,
        cards: newCards,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, rows, words }) => ({
  payload: {
    entities,
    rows,
    words,
  },
  type: INIT,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const mark = () => ({
  type: MARK,
})

const retry = () => ({
  type: RETRY,
})

export {
  init,
  moveCard,
  mark,
  retry,
}

export default reducer
