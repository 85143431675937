import React from 'react'
import classNames from 'classnames'
import { arrayOf, number, shape, string, func } from 'prop-types'
import { t } from 'i18next'

import Donut from 'src/components/Donut'
import Div from 'src/components/Div'
import ReportItem from 'src/pages/ProgressPage/CurriculumProgress/ReportItem'

const indicatorShape = shape({
  completedHomeworkRate: number.isRequired,
  id: string.isRequired,
  identifier: string.isRequired,
  slug: string,
  title: string.isRequired,
})

const propTypes = {
  indicators: arrayOf(indicatorShape),
  onChange: func.isRequired,
  standards: arrayOf(indicatorShape),
  standardSlug: string.isRequired,
  strands: arrayOf(indicatorShape),
  strandSlug: string.isRequired,
  subject: shape({
    completedHomeworkRate: number.isRequired,
    identifier: string.isRequired,
    title: string.isRequired,
  }).isRequired,
}

const defaultProps = {
  indicators: [],
  standards: [],
  strands: [],
}

const SubjectCoverage = ({
  subject: {
    slug: subjectId,
    title,
    identifier,
    completedHomeworkRate,
  },
  strands,
  standards,
  indicators,
  standardSlug,
  strandSlug,
  onChange,
}) => (
  <Div mb2 p2 column justify-center>
    <Div flex gutter={1}>
      <Div
        col={2}
        mx={1}
        className="center"
        title={t('progress.averageAttainment')}
      >
        <Donut value={completedHomeworkRate} size={2} fixed={4} />
      </Div>
      <Div col={10} mx={1}>
        <div className="h2 bold mb1 qa-subject-title">
          <strong>{identifier}</strong> {title}
        </div>
        <p>
          * {t('progress:curriculumCoverage.hint')}
        </p>
        <Div auto>
          {
            strands.map((strand, index) => {
              const strandSelected = strand.slug === strandSlug
              const strandStandards = standards.filter(s => s.strandId === strand.id)
              return (
                <div key={strand.id}>
                  <ReportItem
                    identifier={strand.identifier}
                    selected={strandSelected}
                    title={strand.title}
                    score={strand.completedHomeworkRate}
                    smallTitle={false}
                    type="strand"
                    index={index}
                    onClick={() => onChange({
                      strandSlug: strand.slug,
                      subjectId,
                    })}
                  />
                  <div
                    className={classNames({
                      mb2: strandSelected,
                      'overflow-hidden': true,
                    })}
                    style={{
                      marginLeft: '-15rem',
                      paddingLeft: '15rem',
                    }}
                  >
                    {
                      strandSelected && (
                        <div className="aml2">
                          {
                            strandStandards.map((standard, index) => {
                              const standardSelected = standard.slug === standardSlug
                              const standardIndicators = indicators.filter(s => s.standardId === standard.id)
                              return (
                                <div key={standard.id}>
                                  <ReportItem
                                    identifier={standard.identifier}
                                    selected={standardSelected}
                                    title={standard.title}
                                    score={standard.completedHomeworkRate}
                                    type="standard"
                                    index={index}
                                    onClick={() => onChange({
                                      standardSlug: standard.slug,
                                      strandSlug: strand.slug,
                                      subjectId,
                                    })}
                                  />
                                  <div
                                    className={classNames({
                                      mb2: standardSelected,
                                      'overflow-hidden': true,
                                    })}
                                    style={{
                                      marginLeft: '-15rem',
                                      paddingLeft: '15rem',
                                    }}
                                  >
                                    {
                                      standardSelected && (
                                        <div className="aml2">
                                          {
                                            standardIndicators.map((indicator, index) => (
                                              <ReportItem
                                                key={indicator.id}
                                                identifier={indicator.identifier}
                                                title={indicator.title}
                                                score={indicator.completedHomeworkRate}
                                                type="indicator"
                                                index={index}
                                              />
                                            ))
                                          }
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            })
          }
        </Div>
      </Div>
    </Div>
  </Div>
)

SubjectCoverage.propTypes = propTypes
SubjectCoverage.defaultProps = defaultProps
export default SubjectCoverage
