import React from 'react'
import { arrayOf, shape } from 'prop-types'
import sortBy from 'lodash/sortBy'
import Slides from 'src/components/Layout/Slides'
import entityShape from 'src/shapes/entity'
import videoShape from 'src/shapes/video'
import Slide from 'src/components/Layout/Slide'
import Video from 'src/modules/Video/Video'
import AspectRatio from 'src/components/AspectRatio'
import Div from 'src/components/Div'

const propTypes = {
  entities: arrayOf(shape(entityShape)),
  letterFormationVideos: arrayOf(shape(videoShape)).isRequired,
}

const defaultProps = {
  entities: [],
}

const LetterFormation = ({ letterFormationVideos, entities }) => {
  const videos = letterFormationVideos.map(v => ({
    ...v,
    letter: v.slug.replace('letter-formation-', ''),
  }))
  const allowedLetters = entities.map(({ slug }) => slug.split('-').pop())
  const displayVideos = allowedLetters.length
    ? allowedLetters.map(allowed => videos.find(({ letter }) => letter === allowed))
    : sortBy(videos, 'letter')
  return (
    <Slides>
      {displayVideos.map(video => (
        <Slide key={video.id} label={video.letter}>
          <Div flex justify="center">
            <Div style={{ width: '90%' }}>
              <AspectRatio><Video video={video} /></AspectRatio>
            </Div>
          </Div>
        </Slide>
      ))}
    </Slides>
  )
}

LetterFormation.propTypes = propTypes
LetterFormation.defaultProps = defaultProps

export default LetterFormation
