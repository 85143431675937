import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { GameContext } from 'src/lib/contexts'
import { clearSoftKeyboardKey } from 'src/components/SoftKeyboard/reducer'
import KeyPress from 'src/components/KeyPress/KeyPress'

const KeyPressContainer = props => (
  <GameContext.Consumer>
    {({ unitTest }) => !unitTest && <KeyPress {...props} />}
  </GameContext.Consumer>
)

const select = state => state.softKeyboard
const actions = dispatch => bindActionCreators({ clearSoftKeyboardKey }, dispatch)

export { KeyPressContainer }

export default connect(select, actions)(KeyPressContainer)
