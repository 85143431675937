import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import entityShape from 'src/shapes/entity'
import Story from 'src/modules/Story/Story'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
  language: string.isRequired,
}

const StoryContainer = ({ entities, language }) => (
  <Story entities={entities} language={language} />
)

export const StoryEnglish = props => <StoryContainer {...props} language="En" />

export const StoryThai = props => <StoryContainer {...props} language="Th" />

StoryContainer.propTypes = propTypes

StoryEnglish.demoData = {
  entities: ['paragraph-all-about-me'],
}

StoryThai.demoData = {
  entities: ['thai-morning'],
}

export default StoryContainer
