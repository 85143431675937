import React from 'react'
import { arrayOf, bool, string, shape } from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { lessonPath, unitTestPath } from 'src/lib/routes'
import LessonsPageLessonsHomeworkLink from 'src/pages/LessonsPage/Lessons/HomeworkLink'
import LessonsPageLessonsToggleReleaseHomework from 'src/pages/LessonsPage/Lessons/ToggleReleaseHomework'
import yearShape from 'src/shapes/year'
import homeworkAssignmentShape from 'src/shapes/homeworkAssignment'

const propTypes = {
  approved: bool.isRequired,
  hasHomework: bool,
  homeworkAssignments: arrayOf(shape(homeworkAssignmentShape)),
  id: string.isRequired,
  identifier: string.isRequired,
  selectedYear: shape(yearShape),
  slug: string.isRequired,
  title: string.isRequired,
  unitTest: bool,
}

const defaultProps = {
  hasHomework: false,
  homeworkAssignments: [],
  selectedYear: {},
  unitTest: false,
}

const LessonsPageLessonsLesson = ({
  approved,
  hasHomework,
  homeworkAssignments,
  id,
  identifier,
  selectedYear,
  slug,
  title,
  unitTest,
}) => {
  const homeworkAssignment = homeworkAssignments.find(a => a.yearId === selectedYear.id)
  const dueOn = homeworkAssignment ? homeworkAssignment.dueOn : undefined
  const hasAnswers = homeworkAssignment ? homeworkAssignment.hasAnswers : false
  return (
    <div className="clearfix">
      <div className="bold h5 left vertical-middle">
        {
          !isEmpty(selectedYear) && (
            <LessonsPageLessonsToggleReleaseHomework
              lessonId={id}
              dueOn={dueOn}
              yearId={selectedYear.id}
              hasAnswers={hasAnswers}
            />
          )
        }
        <Link to={unitTest ? unitTestPath(slug) : lessonPath(slug)}>
          <span className={classnames({ red: !approved })}>
            {identifier} {title}
          </span>
        </Link>
      </div>
      {
        dueOn && hasHomework && <LessonsPageLessonsHomeworkLink lessonSlug={slug} />
      }
    </div>
  )
}

LessonsPageLessonsLesson.propTypes = propTypes
LessonsPageLessonsLesson.defaultProps = defaultProps

export default LessonsPageLessonsLesson
