import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { move } from 'src/modules/PhonicBlends/reducer'
import PhonicBlendsScene from 'src/modules/PhonicBlends/PhonicBlendsScene'

const mapStateToProps = ({ phonicBlends: { words = [], places, choices = [], phonics } }) => ({
  choices: choices.map(id => ({ id, entity: phonics[id] })),
  words: words.map(w => ({
    ...w,
    places: places
      .filter(({ word }) => word === w.id)
      .map(p => ({ ...p, entity: phonics[p.value] })),
  })),
})

export {
  mapStateToProps,
}

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({ onMove: move }, dispatch),
)(props => (props.words ? <PhonicBlendsScene {...props} /> : null))
