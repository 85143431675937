import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/LabelImage/reducer'
import LabelImage from 'src/modules/LabelImage/LabelImage'

const propTypes = {
  ...gamePropTypes,
}

class LabelImageContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  render() {
    const { mark, retry, completed, availableCardsId, entitiesById,
      places, moveCard, cover, direction, cards, identifier, actualEntities } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <LabelImage
          {
            ...{
              availableCardsId,
              cards,
              cover,
              direction,
              entitiesById,
              identifier,
              moveCard,
              places,
            }
          }
        />
      </Game>
    )
  }
}

const select = ({ labelImage }) => labelImage
const actions = dispatch => bindActionCreators(cardActions, dispatch)

LabelImageContainer.propTypes = propTypes

export { LabelImageContainer }
export default connect(select, actions)(LabelImageContainer)
