import React from 'react'
import { array, func, object, shape } from 'prop-types'

import Card from 'src/components/Card'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import map from 'lodash/map'
import pick from 'lodash/pick'

const propTypes = {
  cards: shape().isRequired,
  displayableCardsId: array.isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const renderPlace = ({ cards, entitiesById, moveCard, place }) => {
  const { id } = place
  const entity = entitiesById[id]
  return (
    <Place
      id={id}
      key={id}
      onDrop={moveCard}
      className="justify-end align-center"
    >
      <Div flex wrap col={6} className="justify-center" mbn3 relative z2>
        {
          map(cards).filter(({ placeId }) => placeId === id).map(card => (
            <Div key={card.id}>
              <EntityCard
                key={card.id}
                audio
                autoPlay
                correct={card.correct}
                draggable
                english
                entity={entitiesById[card.id]}
                fit
                id={card.id}
                keyToPlay={card.index}
                size={6}
              />
            </Div>
          ))
        }
      </Div>
      <Div relative flex column col={8} wrap>
        <Div column mbn1 pb3>
          <EntityCard image entity={entity} transparent />
        </Div>
        <Div absolute bottom-0 flex auto column col-12>
          <EntityCard bold fit english entity={entity} size={3} />
        </Div>
      </Div>
    </Place>
  )
}
const SortingImagesHomework = ({
  displayableCardsId,
  entitiesById,
  cards,
  moveCard,
  places,
}) => (
  <Deck>
    <Div flex wrap mbn3 SortingImages>
      { map(pick(entitiesById, displayableCardsId)).map(entity => (
        <Div key={entity.id} column col={3} mbn1>
          <Card draggable id={entity.id} fit transparent>
            <Div flex wrap column col={8} mbn2>
              <EntityCard image entity={entity} transparent minHeight={8} />
            </Div>
            <Div flex wrap column auto>
              <EntityCard
                correct={entity.correct}
                english
                entity={entity}
                fit
                size={3}
              />
            </Div>
          </Card>
        </Div>
      ))}
    </Div>
    <Div flex flex-1>
      {map(places, (place => renderPlace({ cards, entitiesById, moveCard, place })))}
    </Div>
  </Deck>
)
SortingImagesHomework.propTypes = propTypes
export default SortingImagesHomework
