import React from 'react'
import { bool, object, func } from 'prop-types'
import map from 'lodash/map'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Bubble from 'src/components/Bubble'
import KeyHandler from 'src/components/KeyHandler'
import Line from 'src/modules/FillInMissingWords/Line'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  hintImage: bool,
  largeCards: bool,
  line: object.isRequired,
  moveCard: func.isRequired,
  places: object.isRequired,
  playPhonics: func,
  silent: bool,
}

const FillInMissingWord = ({
  cards,
  completed,
  entitiesById,
  hintImage,
  largeCards,
  line,
  moveCard,
  multiplePlaces,
  places,
  placeTarget,
  playPhonics,
  phonics,
  silent,
}) => (
  <Deck className="FillInMissingWord">
    <Div flex auto mb3>
      <Bubble
        label=" "
      >
        <Line
          cards={cards}
          completed={completed}
          entitiesById={entitiesById}
          hintImage={hintImage}
          largeCards={largeCards}
          line={line}
          moveCard={moveCard}
          places={places}
          placeTarget={placeTarget}
          playPhonics={playPhonics}
          phonics={phonics}
          multiplePlaces={multiplePlaces}
          silent={silent}
        />
      </Bubble>
    </Div>
    <Div flex justify="center">
      {map(map(cards).filter(({ placeId }) => !placeId), card => (
        <Div key={card.id} flex column flex-1>
          <AdaptiveCard
            audio
            english
            entity={entitiesById[card.id]}
            minHeight={false}
            silent
            onClick={() => moveCard({ sourceId: card.id, targetId: placeTarget })}
            height={largeCards ? 10 : 6}
          />
          <KeyHandler
            label={card.label}
            onKeyPress={() => moveCard({ sourceId: card.id, targetId: placeTarget })}
          />
        </Div>
      ))
      }
    </Div>
  </Deck>
)

FillInMissingWord.propTypes = propTypes

export default FillInMissingWord
