import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import gamePropTypes from 'src/shapes/Game'
import Game from 'src/components/Game'
import * as cardActions from 'src/modules/ComicStripDragAndDrop/reducer'
import ComicStripDragAndDrop from 'src/modules/ComicStripDragAndDrop/ComicStripDragAndDrop'

const propTypes = {
  ...gamePropTypes,
  cursive: bool,
}

const defaultProps = {
  cursive: null,
  silent: false,
}

class ComicStripDragAndDropContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init = () => {
    const { entities } = this.props
    this.props.init({ entities })
  }

  render() {
    const {
      completed,
      entitiesById,
      cursive,
      mark,
      silent,
      moveCard,
      placeList,
      places,
      retry,
      actualEntities,
    } = this.props
    return (
      <Game
        actualEntities={actualEntities}
        init={this.init}
        completed={completed}
        mark={mark}
        retry={retry}
      >
        <ComicStripDragAndDrop
          {
            ...{
              cursive,
              entitiesById,
              moveCard,
              placeList,
              places,
              silent,
            }
          }
        />
      </Game>
    )
  }
}

const select = ({ comicStripDragAndDrop }) => comicStripDragAndDrop
const actions = dispatch => bindActionCreators(cardActions, dispatch)

export const ComicStripDragAndDropCursiveText = props => (
  <ComicStripDragAndDropContainer cursive {...props} />
)

export const ComicStripDragAndDropCursiveTextContainer =
  connect(select, actions)(ComicStripDragAndDropCursiveText)

ComicStripDragAndDropContainer.defaultProps = defaultProps
ComicStripDragAndDropContainer.propTypes = propTypes

const demoData = {
  entities: [
    'phrase-she-is-washing-the-dishes',
    'phrase-she-is-my-elder-sister',
    'phrase-mother-cooked-from-healthy-menu-book',
    'phrase-last-sunday-i-ate-lettuces',
    'phrase-last-monday-i-ate-mangoes',
    'phrase-he-is-watering-the-plants-in-the-garden',
    'phrase-he-is-my-elder-brother',
    'phrase-it-is-a-mango',
  ],
}
ComicStripDragAndDropContainer.demoData = demoData
ComicStripDragAndDropCursiveTextContainer.demoData = demoData

export { ComicStripDragAndDropContainer }
export default connect(select, actions)(ComicStripDragAndDropContainer)
