import { string } from 'prop-types'
import gqlLoader, { networkOnlyConfig } from 'src/lib/gqlLoader'
import YearHomeworkReport from 'src/pages/HomeworkPage/YearHomeworkReport'
import yearHomeworkReportQuery from 'src/pages/HomeworkPage/yearHomeworkReport.gql'

const YearHomeworkReportContainer =
  gqlLoader(yearHomeworkReportQuery, networkOnlyConfig)(YearHomeworkReport)

YearHomeworkReportContainer.propTypes = {
  subjectId: string,
  yearId: string,
}

export default YearHomeworkReportContainer
