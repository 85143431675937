import React from 'react'
import { t } from 'i18next'
import Icon from 'src/components/Icon'
import Div from 'src/components/Div'
import Markdown from 'src/components/Markdown'
import { Link } from 'react-router-dom'
import RegistrationBanner from 'src/pages/Registration/RegistrationBanner'
import { lessonsPath } from 'src/lib/routes'

const defaultProps = {
  children: t('registration.done'),
  icon: 'thumbs-up',
  spin: false,
}

const RegistrationDone = ({ icon, spin, children }) => (
  <Div flex-1 flex flex-column justify-center p3>
    <Link to={lessonsPath}>
      <RegistrationBanner bg="transparent">
        <Div
          regular
          flex
          rounded
          shadow
          bg-white
          mx-auto
          style={{ maxWidth: '40rem' }}
        >
          <Div bg-blue rounded-left white p3 flex align-center>
            <Icon icon={icon} spin={spin} size={2} />
          </Div>
          <Div flex justify-center align-center pt2 px2 black>
            <Markdown>
              {children}
            </Markdown>
          </Div>
        </Div>
      </RegistrationBanner>
    </Link>
  </Div>
)

RegistrationDone.defaultProps = defaultProps
export default RegistrationDone
