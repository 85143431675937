import React from 'react'
import { bool, node, arrayOf, oneOfType } from 'prop-types'
import Div from 'src/components/Div'

const propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  enabled: bool.isRequired,
}
const defaultProps = {
  children: [],
}

const SelectionActions = ({ enabled, children }) => {
  const keyFor = (child, i) => child.props.key || `selection-action-${i}`
  const isActionAvailable = enabled
    ? () => true
    : action => action.props.visible
  const actions = Array.isArray(children)
    ? children
    : [children]

  return (
    <React.Fragment>
      {
        actions.filter(isActionAvailable)
          .map((child, i) => (<Div key={keyFor(child, i)} mr1>{child}</Div>))
      }
    </React.Fragment>
  )
}
SelectionActions.propTypes = propTypes
SelectionActions.defaultProps = defaultProps

export default SelectionActions
