import React, { Component } from 'react'
import { dateToday, DATE_PICKER_FORMAT } from 'src/lib/dates'
import { bool, func, node, string } from 'prop-types'
import DatePicker from 'src/components/DatePicker'
import { t } from 'i18next'

const propTypes = {
  blockLabels: bool,
  children: node,
  dateFormat: string,
  from: string,
  onChange: func,
  onSubmit: func,
  to: string,
}

const defaultProps = {
  blockLabels: false,
  children: null,
  dateFormat: DATE_PICKER_FORMAT,
  from: dateToday(),
  onChange() {
  },
  onSubmit() {
  },
  to: dateToday(),
}

class DateRangeFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      from: props.from,
      to: props.to,
    }
  }

  handleSubmit = () => this.props.onSubmit(this.state)

  handleChange = field => (date) => {
    this.setState({ [field]: date })
    this.props.onChange(this.state)
  }

  render() {
    const { from, to } = this.state
    const { children, dateFormat, blockLabels } = this.props
    return (
      <React.Fragment>
        <div className="inline-block mr2">
          <label className={blockLabels && 'block'}>
            {t('direction.from')}
          </label>
          <DatePicker
            selected={from}
            name="from"
            dateFormat={dateFormat}
            onChange={this.handleChange('from')}
          />
        </div>
        <div className="inline-block">
          <label className={blockLabels && 'block'}>
            {t('direction.to')}
          </label>
          <DatePicker
            minDate={from}
            name="to"
            selected={to > from ? to : from}
            dateFormat={dateFormat}
            onChange={this.handleChange('to')}
          />
        </div>
        {children}
      </React.Fragment>
    )
  }
}

DateRangeFields.propTypes = propTypes
DateRangeFields.defaultProps = defaultProps

export default DateRangeFields
