import React from 'react'
import { arrayOf, bool, shape } from 'prop-types'
import chunk from 'lodash/chunk'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import entityShape from 'src/shapes/entity'
import SentenceLine from 'src/components/SentenceLine'
import SpeakerBubble from 'src/components/SpeakerBubble'
import Slide from 'src/components/Layout/Slide'
import Slides from 'src/components/Layout/Slides'

const propTypes = {
  entities: arrayOf(
    shape(entityShape).isRequired,
  ),
  thai: bool,
}


const defaultProps = {
  entities: [],
  thai: false,
}

const isOdd = number => number % 2 !== 0
const calculateKeyLabel = number => (number * 2) + 1

const createSlide = ({
  fourOfConversationEntities,
  speakers,
  thai,
}) => (
  <Slide key={fourOfConversationEntities[0].id}>
    <Deck>
      {
        fourOfConversationEntities.map((entity, i) => (
          <Div key={entity.id} flex p1 flex-1>
            <SpeakerBubble
              hasTip
              justify="left"
              mln={isOdd(i) ? null : 2}
              mrn={isOdd(i) ? 2 : null}
              right={isOdd(i)}
              speakerCol={fourOfConversationEntities.length > 3 ? 2 : 3}
              speakerEntity={isOdd(i) ? speakers[1] : speakers[0]}
            >
              <SentenceLine
                align="left"
                keyToPlay={thai ? calculateKeyLabel(i) : i + 1}
                entity={entity}
                hasTip
                thai={thai}
                justify="start"
              />
            </SpeakerBubble>
          </Div>
        ))
      }
    </Deck>
  </Slide>
)

const SpeechBubblesConversation = ({ entities, thai }) => {
  const speakers = entities.slice(0, 2)
  const chunkConversationEntities = chunk(entities.slice(2), 4)
  return (
    <Slides>
      {
        chunkConversationEntities.map((fourOfConversationEntities, i) => createSlide({
          chunkConversationEntities,
          fourOfConversationEntities,
          i,
          speakers,
          thai,
        }))
    }
    </Slides>
  )
}

const demoData = {
  entities: [
    'face-father',
    'face-mother',
    'phrase-what-does-your-father-do',
    'phrase-he-is-a-teacher-he-works-in-a-school',
    'phrase-what-does-your-sister-do',
    'phrase-she-is-a-doctor-she-works-in-a-hospital',
    'phrase-what-does-your-sister-do',
    'phrase-she-is-a-fruitseller-she-works-in-a-marketplace',
  ],
}

export const SpeechBubblesConversationWithThai = props => (
  <SpeechBubblesConversation {...props} thai />
)

SpeechBubblesConversationWithThai.propTypes = propTypes
SpeechBubblesConversationWithThai.defaultProps = defaultProps
SpeechBubblesConversationWithThai.demoData = demoData

SpeechBubblesConversation.propTypes = propTypes
SpeechBubblesConversation.defaultProps = defaultProps
SpeechBubblesConversation.demoData = demoData
export default SpeechBubblesConversation
