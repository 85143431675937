import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'

import BpReportPageContainer from 'src/pages/BpReportPage/BpReportPageContainer'
import ReportLayout from 'src/components/ReportLayout/ReportLayout'
import SubjectAndYearSelector from 'src/selectors/SubjectAndYearSelector'
import usePageParams from 'src/lib/usePageParams'
import usePath from 'src/lib/routes/usePath'
import { Spinner } from 'src/components/LoadingBar'
import { makePath } from 'src/pages/BpReportPage/config'

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
}

const BpReportPageLayout = () => {
  const buildPath = usePath(makePath)
  const history = useHistory()
  const pageParams = usePageParams()
  const [stateParams, setStateParams] = useState({})
  const params = { yearId: null, ...pageParams, ...stateParams }
  const handleParamsChange = update => setStateParams({ ...params, ...update })

  useEffect(() => {
    const path = buildPath(params)
    if (path && (history.location.pathname !== path)) { history.push(path) }
  }, [
    buildPath,
    history,
    params,
  ])

  return (
    <ReportLayout
      {...params}
      onParamsChange={handleParamsChange}
    >
      <SubjectAndYearSelector
        {...params}
        onChange={handleParamsChange}
      >
        {params.yearId ? <BpReportPageContainer /> : <Spinner />}
      </SubjectAndYearSelector>
    </ReportLayout>
  )
}

BpReportPageLayout.propTypes = propTypes
export default BpReportPageLayout
