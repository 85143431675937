import { arrayOf, bool, string, shape, oneOfType } from 'prop-types'
import role from 'src/shapes/role'
import section from 'src/shapes/section'

const userShape = {
  id: string,
  fullName: string,
  lastname: string,
  name: string,
  parents: arrayOf(shape({})),
  present: bool,
  role: oneOfType([shape(role), string]),
  teachingYears: arrayOf(shape(section)),
}

export default userShape
