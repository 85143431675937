import collectionProvider from 'src/lib/collectionProvider'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FillInMissingLettersGame from 'src/modules/FillInMissingLetters/FillInMissingLettersGame'
import { ActionCreators } from 'src/modules/FillInMissingLetters/reducer'

const select = state => state.fillInMissingLetters

const actions = dispatch => bindActionCreators(ActionCreators, dispatch)

const defaultExport = connect(
  select,
  actions,
)(
  collectionProvider({
    collections: ['phonicsAlphabet'],
  })(FillInMissingLettersGame),
)

defaultExport.demoData = FillInMissingLettersGame.demoData
export default defaultExport
