import React from 'react'
import { useHistory } from 'react-router'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'

import yearCreateMutation from 'src/pages/Year/New/yearCreateMutation.gql'
import { successMessage } from 'src/lib/toaster'
import YearNew from 'src/pages/Year/New/Scene'
import { path as yearPath } from 'src/pages/Year/Show/config'
import { linkTo } from 'src/lib/linkTo'
import { SCHOOL_SCOPE } from 'src/constants/routeScopes'
import parseErrors from 'src/lib/form/parseErrors'

const showSuccessMessage = () => {
  const title = t('year.year')
  const message = t('save.success')
  successMessage({ message, title })
}

export const YearNewContainer = () => {
  const history = useHistory()
  return (
    <Mutation
      mutation={yearCreateMutation}
      onCompleted={({ yearCreate: { id } }) => {
        showSuccessMessage()
        history.push(linkTo(yearPath)({
          domain: SCHOOL_SCOPE,
          yearId: id,
        }))
      }}
    >
      {
        (yearCreate, { error }) => (
          <YearNew
            errors={parseErrors(error)}
            onSubmit={(values, setSubmitting) => {
              yearCreate({ variables: values })
              setSubmitting(false)
            }}
          />
        )
      }
    </Mutation>
  )
}

export default YearNewContainer
