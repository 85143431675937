import React from 'react'
import Div from 'src/components/Div'
import { arrayOf, shape } from 'prop-types'
import entityShape from 'src/shapes/entity'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
}

const requiredProps = [
  { name: 'Title', prop: 'title' },
  { name: 'Title in english', prop: 'titleEn' },
  { name: 'Title in thai', prop: 'titleTh' },
  { name: 'Image file', prop: 'image.src' },
  { name: 'Sound in english', prop: 'sound.srcEn' },
  { name: 'Sound in thai', prop: 'sound.srcTh' },
]

const getValue = (prop, entity) => {
  if (prop.indexOf('.') !== -1) {
    return prop.split('.').reduce((obj, path) => (obj ? obj[path] : obj), entity)
  }
  return entity[prop]
}

const checkProp = ({ prop }, entity) => !getValue(prop, entity)

const findMissingProps = lacks => lacks.map(({ name }, i) => (
  <span key={name}>
    <strong>{name}</strong>
    {i !== lacks.length - 1 && (i < lacks.length - 2 ? ', ' : ' and ')}
  </span>
))

class CheckEntities extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
    }
  }

  getIncomplete() {
    const { entities } = this.props
    return entities.reduce((report, entity) => {
      const missingProps = requiredProps.filter(prop => checkProp(prop, entity))
      if (missingProps.length) {
        report.push({
          id: entity.id,
          missingProps,
          slug: entity.slug,
        })
      }
      return report
    }, [])
  }

  hide() {
    this.setState({ show: false })
  }

  render() {
    const { show } = this.state
    const incomplete = this.getIncomplete()
    if (!incomplete.length) return null

    return (
      show && (
        <Div col={12} absolute flex align="center" justify="center" z={2}>
          <Div flex p={2} bg="yellow" rounded shadow>
            <div>
              <h4>Incomplete Entity Report</h4>
              <ul className="px2 overflow-auto" style={{ maxHeight: '29rem' }}>
                {
                  incomplete.map(({ id, slug, missingProps }) => (
                    <li key={id}>
                      <strong><a href={`/admin/entities/${slug}/edit`}>{slug}</a></strong>:
                      lacks {findMissingProps(missingProps)}.
                    </li>
                  ))
                }
              </ul>
            </div>
            <span className="qa-check-entities cursor-pointer" onClick={() => this.hide()}>
              <i className="fa fa-times" />
            </span>
          </Div>
        </Div>
      )
    )
  }
}

CheckEntities.propTypes = propTypes

export default CheckEntities
