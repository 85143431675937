import React from 'react'
import { t } from 'i18next'
import Widget from 'src/components/Widget'
import { SCHOOL_CURRICULUM } from 'src/constants/menu'
import GeneralFilter from 'src/components/GeneralFilter'
import Indicators from 'src/pages/Curriculum/Indicators'
import PrintInstructions from 'src/components/PrintInstructions'

const propTypes = {
}

const Curriculum = ({ curriculumPath, gradeSlug, subjectSlug }) => {
  const title = t(`${SCHOOL_CURRICULUM}.title`)
  return (
    <Widget token={SCHOOL_CURRICULUM} title={title}>
      <GeneralFilter
        redirectPath={curriculumPath}
        gradeSlug={gradeSlug}
        subjectSlug={subjectSlug}
        showAll
      />
      <PrintInstructions />
      <Indicators {...{ gradeSlug, subjectSlug }} />
    </Widget>
  )
}

Curriculum.propTypes = propTypes

export default Curriculum
