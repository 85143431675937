import React from 'react'
import { array, shape } from 'prop-types'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import { t } from 'i18next'
import YearCalendar from 'src/pages/CalendarPage/YearCalendarContainer'
import Div from 'src/components/Div'
import { add, rangeBy, subtract } from 'src/lib/dates'
import { isWeekend } from 'src/pages/CalendarPage/YearSummary/isAcademicDay'
import eventTitleFallBack from 'src/lib/eventTitleFallBack'
import yearShape from 'src/shapes/year'
import HolidayList from 'src/pages/PublicCalendar/HolidayList'
import Legend from 'src/pages/PublicCalendar/Legend'

const addClosedModifiers = events => (
  events.map(ev => ({
    ...ev,
    tooltip: compact([ev.name, t('school.calendar.schoolClosed')]),
    className: 'closed',
  }))
)

const propTypes = {
  calendarEvents: array.isRequired,
  year: shape(yearShape).isRequired,
}

const PublicCalendar = ({
  calendarEvents,
  year: { title, academicTerms },
}) => {
  const holidayEvents = calendarEvents
    .filter(({ holiday }) => holiday)
    .map(({ startDate, endDate, titleEn, titleTh }) => (
      { name: eventTitleFallBack({ titleEn, titleTh }), startDate, endDate }
    ))
  const holidaysBetweenTerms = sortBy(academicTerms, 'startedOn').reduce((acc, next, i, arr) => {
    const prev = arr[i - 1]
    return prev ? [
      ...acc,
      {
        name: `${t('shared.term')} ${i} ${t('school.settings.holidays')}`,
        startDate: add(prev.finishedOn, 1, 'days'),
        endDate: subtract(next.startedOn, 1, 'days'),
      },
    ] : acc
  }, [])
  const [{ startedOn } = {}] = sortBy(academicTerms, 'startedOn')
  const [{ finishedOn } = {}] = sortBy(academicTerms, 'finishedOn').reverse()
  const weekends = (startedOn && finishedOn && compact(
    rangeBy(startedOn, finishedOn, 'day').map(date => isWeekend(date) && {
      name: t('shared.weekend'),
      startDate: date,
      endDate: date,
    }),
  )) || []
  const schoolHolidays = sortBy([...holidayEvents, ...holidaysBetweenTerms], 'startDate')
  return (
    <Div bg-white style={{ minHeight: '100vh' }}>
      <Div flex flex-wrap mx-auto style={{ maxWidth: '1500px' }}>
        <Div mx-auto mt2>
          <Div auto center h4 bold my2 className="qa-year-title">
            {title} {t('shared.academicYear')}
          </Div>
          <YearCalendar
            events={addClosedModifiers([...schoolHolidays, ...weekends])}
            datePath={() => null}
          />
        </Div>
        <Div mx-auto table mt4>
          <Div mb3 mr3>
            <HolidayList events={schoolHolidays} />
          </Div>
          <Legend />
        </Div>
      </Div>
    </Div>
  )
}

PublicCalendar.propTypes = propTypes

export default PublicCalendar
