import React from 'react'
import { shape } from 'prop-types'
import userShape from 'src/shapes/user'
import UserImage from 'src/components/UserImage'
import UserProfileLink from 'src/components/UserProfileLink'

const propTypes = {
  user: shape(userShape).isRequired,
}

const UserImageLink = ({ user }) => (
  <div>
    <UserProfileLink userId={user.slug}>
      <UserImage user={user} />
    </UserProfileLink>
  </div>
)

UserImageLink.propTypes = propTypes

export default UserImageLink
