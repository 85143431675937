import React, { Fragment } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import Div from 'src/components/Div'
import LessonsPageSection from 'src/pages/LessonsPage/Section'
import NoCurrentUser from 'src/pages/CurrentUser/NoCurrentUser'
import RegistrationBanner from 'src/pages/Registration/RegistrationBanner'
import sectionShape from 'src/shapes/section'
import { path } from 'src/routes/registration'

const propTypes = {
  gradeSlug: string,
  sections: arrayOf(shape(sectionShape)).isRequired,
  yearSlug: string,
}

const defaultProps = {
  gradeSlug: undefined,
  yearSlug: undefined,
}

const LessonsPage = ({ sections, gradeSlug, yearSlug }) => (
  <Fragment>
    <NoCurrentUser>
      <a href={`https://online.learn21.school${path}`}>
        <RegistrationBanner />
      </a>
    </NoCurrentUser>
    <Div flex column mbn3 z1 relative>
      {sections.map(
        (section) => section.lessonsCount > 0 && (
          <LessonsPageSection
            key={section.id}
            {...section}
            gradeSlug={gradeSlug}
            yearSlug={yearSlug}
          />
        )
      )}
    </Div>
  </Fragment>
)

LessonsPage.propTypes = propTypes
LessonsPage.defaultProps = defaultProps

export default LessonsPage
