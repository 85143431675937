import React from 'react'
import { number, shape } from 'prop-types'
import Div from 'src/components/Div'
import AdaptiveCard from 'src/components/AdaptiveCard'
import entityShape from 'src/shapes/entity'
import KeyLabel from 'src/components/KeyLabel'

const propTypes = {
  entity: shape(entityShape).isRequired,
  index: number.isRequired,
}

const calculateKeyToPlay = (index, adder) => {
  const keyToPlay = (2 * index) + adder
  const result = keyToPlay > 9 ? '0' : keyToPlay.toString()
  return result
}

const WordBoardLine = ({
  entity,
  index,
}) => (
  <Div auto flex justify="center">
    <Div flex width={2} column justify="center">
      <KeyLabel label={calculateKeyToPlay(index, 1)} />
    </Div>
    <Div auto flex column col={5} justify="center">
      <AdaptiveCard
        audio
        bold
        english
        entity={entity}
        keyToPlay={calculateKeyToPlay(index, 1)}
        minHeight={10}
        withPartOfSpeech
      />
    </Div>
    <Div auto flex column col={5} justify="center">
      <AdaptiveCard
        audio
        bold
        entity={entity}
        keyToPlay={calculateKeyToPlay(index, 2)}
        minHeight={10}
        thai
      />
    </Div>
    <Div flex width={2} column justify="center">
      <KeyLabel label={calculateKeyToPlay(index, 2)} />
    </Div>
  </Div>
)

WordBoardLine.propTypes = propTypes

export default WordBoardLine
