import React from 'react'
import { shape, arrayOf, string, bool, func } from 'prop-types'
import isNil from 'lodash/isNil'
import entityShape from 'src/shapes/entity'
import EntityImage from 'src/components/EntityImage'
import EntityBubble from 'src/components/EntityBubble'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import AdaptiveCard from 'src/components/AdaptiveCard'
import KeyPress from 'src/components/KeyPress'
import Card from 'src/components/Card/Card'
import TextFit from 'src/components/TextFit'
import replaceWord from 'src/lib/replaceWord'
import isEmpty from 'lodash/isEmpty'

const propTypes = {
  answers: arrayOf(shape(entityShape)).isRequired,
  audio: shape(entityShape).isRequired,
  completed: bool,
  correct: shape(entityShape).isRequired,
  onSelectAnswer: func.isRequired,
  phrase: shape(entityShape).isRequired,
  selected: string,
}

const defaultProps = {
  completed: null,
  selected: null,
}

const MultipleChoiceMissingWordsAudioScene = ({
  audio,
  answers,
  completed,
  correct,
  onSelectAnswer,
  phrase,
  selected,
}) => {
  const hasAnswer = !isNil(completed)
  let seq = 0
  const hasImage = audio && !isEmpty(audio.image)
  return (
    <Deck>
      <Div flex gutter={1}>
        <Div auto flex column>
          <Div flex auto><EntityImage entity={audio} /></Div>
          <Div flex={!hasImage} stretch={!hasImage} pb2>
            <EntityBubble
              entity={audio}
              english
              audio
              play
              label=" "
            />
          </Div>
        </Div>
        <Div flex column col={6}>
          <Card className="qa-phrase" key={selected} p1>
            <TextFit>
              <Div wrap px3>
                {/* eslint-disable-next-line no-return-assign */}
                {replaceWord([phrase.titleEn], correct.titleEn, () => (
                  <Div
                    key={`placeholder-${seq += 1}`}
                    p1
                    mtn={1}
                    mx1
                    rounded
                    transition-all
                    /* eslint-disable-next-line no-nested-ternary */
                    bg={hasAnswer ? (completed ? 'green' : 'red') : 'silver'}
                    className="qa-answer"
                    justify-center
                    inline
                  >
                    {selected ? answers.find(({ id }) => id === selected).titleEn : '...'}
                  </Div>
                ))
                }
              </Div>
            </TextFit>
          </Card>
          <Div flex column>{answers.map((answer, i) => {
            const label = i + 1
            const isSelected = answer.id === selected
            const isCorrect = (!hasAnswer || !isSelected) ? null : completed
            return (
              <Div key={label}>
                <AdaptiveCard
                  className="qa-choice"
                  absolutelyPositionedLabel
                  audio
                  english
                  entity={answer}
                  keyToPlay={label}
                  label={label}
                  selected={isSelected}
                  correct={isCorrect}
                  onClick={() => onSelectAnswer(answer.id)}
                />
                <KeyPress keys={{ [label]: () => onSelectAnswer(answer.id) }} />
              </Div>
            )
          })}
          </Div>
        </Div>
      </Div>
    </Deck>
  )
}

MultipleChoiceMissingWordsAudioScene.propTypes = propTypes
MultipleChoiceMissingWordsAudioScene.defaultProps = defaultProps

export default MultipleChoiceMissingWordsAudioScene
