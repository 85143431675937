import React, { Fragment } from 'react'
import { array, string } from 'prop-types'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import { attendanceReportPath } from 'src/lib/routes'
import usePath from 'src/lib/routes/usePath'
import Icon from 'src/components/Icon'
import YearSummary from 'src/components/AttendanceReport/YearSummary'
import SectionTitle from 'src/components/AttendanceReport/SectionTitle'
import StudentReport from 'src/components/AttendanceReport/StudentReport'

const propTypes = {
  sections: array,
  yearId: string,
}

const defaultProps = {
  academicTermId: undefined,
  academicYearId: undefined,
  sections: [],
  yearId: undefined,
}

const TableBody = ({ sections, yearId }) => {
  const path = usePath(attendanceReportPath)
  return (
    <tbody>
      {sections.map((section) => (
        <Fragment key={section.id}>
          <SectionTitle {...section} />
          {sortBy(section.years, 'slug').map((year) => {
            const { id, slug, title, students } = year
            const open = yearId === slug
            return (
              <Fragment key={id}>
                <tr>
                  <td className="bold">
                    <div className={`qa-attendance-report-year-title-${slug}`}>
                      <Link to={path({ yearId: open ? null : slug })}>
                        {open ? <Icon icon="minus" /> : <Icon icon="plus" />}{' '}
                        {title}
                      </Link>
                    </div>
                  </td>
                  <YearSummary {...year} />
                </tr>
                {open &&
                  sortBy(students, 'identifier').map((user) => (
                    <StudentReport key={user.id} {...user} />
                  ))}
              </Fragment>
            )
          })}
        </Fragment>
      ))}
    </tbody>
  )
}

TableBody.propTypes = propTypes
TableBody.defaultProps = defaultProps

export default TableBody
