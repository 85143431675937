import React from 'react'
import { string, bool } from 'prop-types'
import AssessmentMenu from 'src/components/AssessmentMenu'
import Div from 'src/components/Div'
import Student from 'src/pages/TeacherLayout/AssessmentPage/Student'
import Students from 'src/pages/TeacherLayout/AssessmentPage/Students'
import { assessmentStudentPath } from 'src/lib/routes'

const propTypes = {
  chapterId: string,
  marked: bool,
  userId: string,
  yearId: string,
}

const defaultProps = {
  chapterId: null,
  marked: null,
  userId: null,
  yearId: null,
}

const AssessmentList = ({ marked, yearId, userId, chapterId }) => (
  <div className="p">
    <AssessmentMenu
      marked={marked}
      path={params => assessmentStudentPath({ userId, yearId, ...params })}
    />
    <Div flex gutter={1}>
      <Div col={3} px={1} noPrint>
        <Students {...{ marked, yearId, userId }} />
      </Div>
      <Div col={9} px={1}>
        {userId && <Student key={userId} {...{ chapterId, marked, userId }} />}
      </Div>
    </Div>
  </div>
)

AssessmentList.propTypes = propTypes
AssessmentList.defaultProps = defaultProps

export default AssessmentList
