import React from 'react'
import { array, func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  entities: array.isRequired,
  initFixed: func.isRequired,
}

const defaultProps = {
  cardsSize: 3,
  silent: false,
}

const MultipleChoiceFixedContainer = props => (
  <MultipleChoiceCore
    init={() => props.initFixed({ ...props })}
    {...props}
  />
)

MultipleChoiceFixedContainer.demoData = {
  entities: [
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'wiindow',
    'door',
    'dour',
    'doorr',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

MultipleChoiceFixedContainer.demoData = {
  entities: [
    'phrase-choose-the-correct-spelling',
    'window',
    'windoh',
    'wiindow',
    'door',
    'dour',
    'doorr',
  ],
}

MultipleChoiceFixedContainer.propTypes = propTypes
MultipleChoiceFixedContainer.defaultProps = defaultProps

export { MultipleChoiceFixedContainer }
export default connect(select, actions)(MultipleChoiceFixedContainer)
