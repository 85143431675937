import React from 'react'
import { t } from 'i18next'
import classnames from 'classnames'
import Div from 'src/components/Div'

const Entry = ({ className, children }) => (
  <Div className="YearCalendar YearCalendar--disable-hover" flex mb1>
    <Div mr1>
      <Div className={classnames('rc-Day cell', className)}>{12}</Div>
    </Div>
    <div><b>{children}</b></div>
  </Div>
)
const Legend = () => (
  <Div column>
    <Entry className="rc-Day-event">{t('school.calendar.schoolOpen')}</Entry>
    <Entry className="rc-Day-closed">{t('school.calendar.schoolClosed')}</Entry>
    <Entry className="rc-Day-TENANT_EVENT rc-Day-DAY_OFF">
      {t('school.calendar.schoolHolidays')}
    </Entry>
    <Entry className="rc-Day-TENANT_EVENT">{t('school.calendar.schoolEvent')}</Entry>
  </Div>
)

export default Legend
