import React from 'react'
import { arrayOf, number, oneOfType, shape, string, func } from 'prop-types'
import Div from 'src/components/Div'
import Card from 'src/components/Card'

const propTypes = {
  currentId: oneOfType([number, string]),
  items: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
      onClick: func,
      title: oneOfType([number, string]).isRequired,
    }).isRequired,
  ),
}

const defaultProps = {
  currentId: 'none',
  items: [],
}

const SlidesNavigation = ({ items, currentId }) => (
  items.length > 1 &&
  (
    <Div flex gutter={1} wrap justify="center">
      {
        items.map(({ id, title, onClick }) => (
          <Card
            key={id}
            fit
            onClick={onClick}
            selected={id === currentId}
            muted={id !== currentId}
            silent
            size={4}
            bold={false}
            title={title}
          />
        ))
      }
    </Div>
  )
)

SlidesNavigation.propTypes = propTypes
SlidesNavigation.defaultProps = defaultProps

export default SlidesNavigation
