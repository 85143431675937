import icons from 'src/lib/menu/icons'
import * as menu from 'src/constants/menu'

const path = '/:domain/assessment'

const scene = {
  icon: icons[menu.ASSESSMENT],
  id: menu.MY_STUDENTS_ASSESSMENT,
  path,
}

export { path, scene }
