import React from 'react'
import { useHistory } from 'react-router'
import { array, string } from 'prop-types'
import Div from 'src/components/Div'
import StudentList from 'src/components/StudentList'
import AttendanceSections from 'src/pages/AttendancePage/AttendanceToday/AttendanceSections'
import usePageParams from 'src/lib/usePageParams'
import usePath from 'src/lib/routes/usePath'
import userPath from 'src/lib/routes/user'
import { todayPath } from 'src/pages/AttendancePage/paths'
import { linkTo } from 'src/lib/linkTo'

const propTypes = {
  domain: string,
  sections: array,
  yearId: string,
}

const defaultProps = {
  domain: undefined,
  sections: [],
  yearId: null,
}

const Column = ({ children }) => (
  <Div col={4} px={1} mb1 flex-1 style={{ minWidth: '300px' }}>
    {children}
  </Div>
)

const TodayAttendance = ({ sections }) => {
  const { domain, yearId } = usePageParams()
  const history = useHistory()
  const studentPath = usePath(userPath)
  const onYearChange = yearId => history.push(linkTo(todayPath)({ domain, tab: 'today', yearId }))
  return (
    sections ? (
      <Div flex gutter={1} wrap>
        <Column>
          <AttendanceSections sections={sections} selectedYear={yearId} />
        </Column>
        <Column>
          {yearId && (
            <StudentList
              attendance
              studentPath={userId => studentPath({ userId })}
              domain={domain}
              onYearChange={onYearChange}
              {...{ yearId }}
            />
          )}
        </Column>
      </Div>
    ) : null
  )
}

TodayAttendance.propTypes = propTypes
TodayAttendance.defaultProps = defaultProps
export default TodayAttendance
