import React from 'react'
import { func, number, object, oneOfType, string } from 'prop-types'
import Div from 'src/components/Div'
import EntityImage from 'src/components/EntityImage'
import KeyLabel from 'src/components/KeyLabel'

const propTypes = {
  entity: object.isRequired,
  label: oneOfType([string, number]),
  onClick: func,
}

const ComicImage = ({ entity, label, onClick }) => (
  <Div
    auto
    flex
    onClick={onClick}
    pt
    px
    relative
  >
    <Div absolute bg="white" circle>
      <KeyLabel label={label} />
    </Div>
    <EntityImage
      bg="white"
      contain
      entity={entity}
      rounded
    />
  </Div>
)

ComicImage.propTypes = propTypes
export default ComicImage
