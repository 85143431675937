import React from 'react'
import uuid from 'uuid'
import { connect } from 'react-redux'
import { bool, func, node, number, object, string } from 'prop-types'
import isEqual from 'lodash/isEqual'
import series from 'src/components/TextFit/utils/series'
import whilst from 'src/components/TextFit/utils/whilst'
import { innerWidth, innerHeight } from 'src/components/TextFit/utils/innerSize'

const UPDATE_VALUE = 0.1
const MAXIMUM_FONT_SZIE = 10
const MINIMUM_FONT_SIZE = 1
const REDUCE_FONT_SIZE_TO_PREVENT_OVER_CARD = 0.82

const assertElementFitsWidth = (el, width) => el.scrollWidth - 1 <= width

const assertElementFitsHeight = (el, height) => el.scrollHeight - 1 <= height

const noop = () => {}

const propTypes = {
  align: string,
  alignItems: string,
  auto: bool,
  children: node,
  className: string,
  focusOnWidth: bool,
  image: bool,
  justify: string,
  max: number,
  min: number,
  onReady: func,
  reduceFontSize: number,
  style: object,
  text: string,
  textAlign: string,
  wrapperJustify: string,
}

const defaultProps = {
  align: 'center',
  alignItems: 'center',
  auto: null,
  children: null,
  className: undefined,
  focusOnWidth: false,
  image: false,
  justify: 'center',
  max: MAXIMUM_FONT_SZIE,
  min: MINIMUM_FONT_SIZE,
  onReady: noop,
  reduceFontSize: REDUCE_FONT_SIZE_TO_PREVENT_OVER_CARD,
  style: {},
  text: null,
  textAlign: undefined,
  wrapperJustify: 'center',
}

const renderChildPropTypes = {
  children: node,
  image: string,
  ready: bool,
  text: string,
}

const renderChildDefaultProps = {
  children: null,
  image: false,
  ready: false,
  text: null,
}

const renderChild = ({ image, text, children, ready }) => {
  if (image) return children
  if (text && (typeof children === 'function')) {
    if (ready) return children(text)
    return text
  }
  return <div className="col-12">{children}</div>
}

renderChild.propTypes = renderChildPropTypes
renderChild.defaultProps = renderChildDefaultProps

class TextFit extends React.Component {
  state = {
    fontSize: null,
    parentHeight: null,
    ready: false,
  }

  componentDidMount() {
    setTimeout(this.process, 0)
  }

  componentDidUpdate(prevProps) {
    const { ready, parentHeight } = this.state
    const wrapper = this.child
    if (isEqual(this.props, prevProps)) return
    if (!ready) return
    if (!assertElementFitsHeight(wrapper, parentHeight)) {
      this.process()
    }
  }

  componentWillUnmount() {
    // Setting a new pid will cancel all running processes
    this.pid = uuid()
  }

  process = () => {
    const { focusOnWidth, min, max, onReady, reduceFontSize } = this.props
    const { parentHeight } = this.state
    const el = this.parent
    const wrapper = this.child

    const originalWidth = innerWidth(el)
    const originalHeight = parentHeight || innerHeight(el)
    if (originalHeight <= 0) {
      // eslint-disable-next-line
      console.warn('Can not process element without height. Make sure the element is displayed and has a static height.')
      return
    }

    if (originalWidth <= 0) {
      // eslint-disable-next-line
      console.warn('Can not process element without width. Make sure the element is displayed and has a static width.')
      return
    }

    const pid = uuid()
    this.pid = pid

    const shouldCancelProcess = () => pid !== this.pid

    const testPrimary = () => assertElementFitsHeight(wrapper, originalHeight)
    const testSecondary = () => assertElementFitsWidth(wrapper, originalWidth)

    let mid
    let low = min
    let high = max

    this.setState({ ready: false })

    series([
      // Step 1:
      // Binary search to fit the element's height (multi line) / width (single line)
      stepCallback => whilst(
        () => low <= high,
        (whilstCallback) => {
          if (shouldCancelProcess()) return whilstCallback(true)
          mid = ((low + high) / 2)
          this.setState({ fontSize: mid }, () => {
            if (shouldCancelProcess()) return whilstCallback(true)
            if (testPrimary()) low = mid + UPDATE_VALUE
            else high = mid - UPDATE_VALUE
            return whilstCallback()
          })
          return null
        },
        stepCallback,
      ),
      // Step 2:
      // Binary search to fit the element's width (multi line) / height (single line)
      // If mode is single and forceSingleModeWidth is true, skip this step
      // in order to not fit the elements height and decrease the width
      (stepCallback) => {
        if (!focusOnWidth) return stepCallback(false)
        if (testSecondary()) return stepCallback()
        low = min
        high = mid
        return whilst(
          () => low < high,
          (whilstCallback) => {
            if (shouldCancelProcess()) return whilstCallback(true)
            mid = ((low + high) / 2)
            this.setState({ fontSize: mid }, () => {
              if (pid !== this.pid) return whilstCallback(true)
              if (testSecondary()) low = mid + UPDATE_VALUE
              else high = mid - UPDATE_VALUE
              return whilstCallback()
            })
            return null
          },
          stepCallback,
        )
      },
      // Step 3
      // Limits
      (stepCallback) => {
        // We break the previous loop without updating mid for the final time,
        // so we do it here:
        mid = Math.min(low, high)

        // Ensure we hit the user-supplied limits
        mid = Math.max(mid, min)
        mid = Math.min(mid, max)

        // Sanity check:
        mid = Math.max(mid, 0)

        if (shouldCancelProcess()) return stepCallback(true)

        // Prevent over card problem
        mid *= reduceFontSize

        this.setState({ fontSize: mid }, stepCallback)
        return null
      },
    ], (err) => {
      // err will be true, if another process was triggered
      if (err || shouldCancelProcess()) return
      this.setState({ parentHeight: wrapper.scrollHeight, ready: true }, () => onReady(mid))
    })
  }

  render() {
    const {
      align,
      alignItems,
      auto,
      children,
      className,
      image,
      text,
      style,
      justify,
      textAlign,
      wrapperJustify,
    } = this.props
    const { fontSize, ready } = this.state
    const finalStyle = {
      ...style,
      fontSize: `${fontSize}rem`,
    }
    const wrapperClass = ready ? `flex col-12 justify-${wrapperJustify} column ${align} ${auto && 'flex-auto'}` : `flex justify-${wrapperJustify} ${align} ${auto && 'flex-auto'}`
    const wrapperStyle = { alignItems, textAlign }
    const wrapperOutSizeClassName = `flex flex-auto justify-${justify} ${className}`
    return (
      <div ref={(c) => { this.parent = c }} style={finalStyle} className={wrapperOutSizeClassName}>
        <div ref={(c) => { this.child = c }} style={wrapperStyle} className={wrapperClass}>
          {
            renderChild({ children, image, ready, text })
          }
        </div>
      </div>
    )
  }
}

TextFit.propTypes = propTypes
TextFit.defaultProps = defaultProps

const mapStateToProps = state => ({ isShowInstruction: state.layout.isShowInstruction })

export default connect(mapStateToProps, null)(TextFit)
