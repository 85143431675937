import React, { Component } from 'react'
import { array, func, object } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as actions from 'src/modules/FamilyTreeShuffled/reducer'
import FamilyTreeShuffled from 'src/modules/FamilyTreeShuffled/FamilyTreeShuffled'

const propTypes = {
  ...gamePropTypes,
  cards: object.isRequired,
  entitiesById: object.isRequired,
  moveCard: func.isRequired,
  placeLocations: array.isRequired,
  places: object.isRequired,
}

class FamilyTreeShuffledContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    const { init } = this.props
    init(this.props)
  }

  render() {
    const { mark, retry, completed, entities, locale, component, cards,
      entitiesById, moveCard, placeLocations, places, actualEntities } = this.props
    return (
      <Game
        {...{
          actualEntities,
          completed,
          component,
          entities,
          init: () => this.init(),
          locale,
          mark,
          retry,
        }}
      >
        <FamilyTreeShuffled
          cards={cards}
          entitiesById={entitiesById}
          places={places}
          placeLocations={placeLocations}
          moveCard={moveCard}
        />
      </Game>
    )
  }
}

FamilyTreeShuffledContainer.propTypes = propTypes

const selector = state => state.familyTreeShuffled
const boundActions = dispatch => bindActionCreators(actions, dispatch)
export default connect(selector, boundActions)(FamilyTreeShuffledContainer)
