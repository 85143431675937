import React from 'react'
import Div from 'src/components/Div'
import Select from 'src/components/forms/Select'
import { array, oneOf, arrayOf, object, oneOfType, shape } from 'prop-types'
import userShape from 'src/shapes/user'
import {
  PARENT_ROLE,
  STAFF_ROLE,
  STUDENT_ROLE,
  TEACHER_ROLE,
} from 'src/constants/roles'

const defaultProps = {
  errors: [],
  parents: [],
  students: [],
}
const propTypes = {
  errors: oneOfType([array, object]),
  formtype: oneOf([PARENT_ROLE, STAFF_ROLE, STUDENT_ROLE, TEACHER_ROLE])
    .isRequired,
  parents: arrayOf(shape(userShape)),
  students: arrayOf(shape(userShape)),
}

const UserSelector = ({ errors, students, parents, formtype }) => (
  <Div>
    {formtype !== STUDENT_ROLE ? (
      <Select
        isMulti
        isSearchable
        className="mb qa-user-childIds"
        name="childIds"
        isDisabled={!students.length}
        errors={errors.childIds}
        labelToken="user.children"
        getOptionLabel={({ fullName, year }) => `${year.title} ${fullName}`}
        options={students}
      />
    ) : (
      <Select
        isMulti
        isSearchable
        className="mb qa-user-parentIds"
        name="parentIds"
        isDisabled={!parents.length}
        labelToken="user.parents"
        getOptionLabel={({ fullName }) => fullName}
        options={parents}
        errors={errors.parents}
      />
    )}
  </Div>
)
UserSelector.propTypes = propTypes
UserSelector.defaultProps = defaultProps
export default UserSelector
