import chunk from 'lodash/chunk'
import React from 'react'
import { arrayOf, bool, string, shape, func } from 'prop-types'
import ComicImage from 'src/components/ComicImage'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import AdaptiveCard from 'src/components/AdaptiveCard'
import Place from 'src/components/Place'

const propTypes = {
  cursive: bool,
  moveCard: func.isRequired,
  placeList: arrayOf(string).isRequired,
  places: shape({
    id: shape({
      cardId: string,
      entityId: string,
      id: string,
    }),
  }),
  silent: bool,
}

const defaultProps = {
  cursive: false,
  silent: false,
}

const renderPlace = ({ silent, entitiesById, moveCard, place }, i) => (
  <Place
    id={place.id}
    key={place.id}
    mx={false}
    onDrop={moveCard}
  >
    <ComicImage
      entity={entitiesById[place.entityId]}
      label={i + 1}
    />
    <Div>
      <AdaptiveCard
        correct={place.correct}
        draggable
        english
        entity={entitiesById[place.cardId]}
        height={4}
        key={place.cardId}
        keyToPlay={i + 1}
        minHeight={false}
        p={1}
        silent={silent}
      />
    </Div>
  </Place>
)

const ComicStripDragAndDrop = ({
  silent,
  cursive,
  entitiesById,
  moveCard,
  placeList,
  places,
}) => {
  const numberOfEntityPerRow = Math.round(placeList.length / 2)
  const entityChunks = chunk(placeList, numberOfEntityPerRow)
  return (
    <Deck cursive={cursive}>
      <Div auto column flex bg="black" rounded>
        {
        entityChunks.map((entityChunk, i) => (
          <Div flex fx={1} key={entityChunk[0]}>
            {
              entityChunk.map((placeId, j) => (
                renderPlace({
                  entitiesById,
                  moveCard,
                  place: places[placeId],
                  silent,
                }, (i * numberOfEntityPerRow) + j)
              ))
          }
          </Div>
        ))
        }
      </Div>
    </Deck>
  )
}

ComicStripDragAndDrop.propTypes = propTypes
ComicStripDragAndDrop.defaultProps = defaultProps

export default ComicStripDragAndDrop
