import React from 'react'
import { arrayOf, bool, element, oneOfType, string } from 'prop-types'
import classnames from 'classnames'
import Icon from 'src/components/Icon'
import Button from 'src/components/Button'

const propTypes = {
  children: oneOfType([element, arrayOf(element), string]).isRequired,
  className: string,
  completed: bool,
  completedIcon: string,
  icon: string,
  inProgress: bool,
  inProgressIcon: string,
}

const defaultProps = {
  className: null,
  completed: false,
  completedIcon: 'check',
  icon: null,
  inProgress: false,
  inProgressIcon: 'spinner fa-spin',
}

const ActionButton = ({
  children,
  className,
  completed,
  completedIcon,
  inProgressIcon,
  icon,
  inProgress,
  ...rest
}) => (
  <Button
    bg="blue"
    className={className}
    disabled={inProgress}
    {...rest}
  >
    {icon && (
    <Icon
      // eslint-disable-next-line no-nested-ternary
      icon={inProgress ? inProgressIcon : (completed ? completedIcon : icon)}
      className={classnames({ green: completed && !inProgress })}
    />
    )}
    {children}
  </Button>
)

ActionButton.propTypes = propTypes
ActionButton.defaultProps = defaultProps

export default ActionButton
