import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { shape } from 'prop-types'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'

import yearShape from 'src/shapes/year'
import gqlLoader from 'src/lib/gqlLoader'
import parseErrors from 'src/lib/form/parseErrors'
import { linkTo } from 'src/lib/linkTo'
import { successMessage } from 'src/lib/toaster'
import { path as yearPath } from 'src/pages/Year/Show/config'
import YearEdit from 'src/pages/Year/Edit/Scene'
import yearQuery from 'src/pages/Year/shared/year.gql'
import yearUpdateMutation from 'src/pages/Year/Edit/yearUpdateMutation.gql'
import DomainContext from 'src/contexts/DomainContext'

const propTypes = {
  year: shape(yearShape).isRequired,
}

const showSuccessMessage = () => {
  const title = t('year.year')
  const message = t('save.success')
  successMessage({ message, title })
}

export const YearEditContainer = ({ year }) => {
  const history = useHistory()
  const domain = useContext(DomainContext)
  const linkToYearPath = linkTo(yearPath)({
    domain,
    yearId: year.id,
  })
  return (
    <Mutation
      mutation={yearUpdateMutation}
      refetchQueries={() => [{ query: yearQuery, variables: { yearId: year.id } }]}
      awaitRefetchQueries
      onCompleted={() => {
        showSuccessMessage()
        history.push(linkToYearPath)
      }}
    >
      {
        (yearUpdate, { error }) => (
          <YearEdit
            year={year}
            errors={parseErrors(error)}
            cancelPath={linkToYearPath}
            onSubmit={(values, setSubmitting) => {
              yearUpdate({ variables: values })
              setSubmitting(false)
            }}
          />
        )
      }
    </Mutation>
  )
}

YearEditContainer.propTypes = propTypes

export default gqlLoader(yearQuery)(YearEditContainer)
