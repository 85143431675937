import shuffle from 'lodash/shuffle'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import without from 'lodash/without'
import keyBy from 'lodash/keyBy'
import every from 'lodash/every'

import uniqShuffle from 'src/lib/uniqShuffle'
import moveCards from 'src/reducers/lib/moveCards'

const INIT = 'MatchTextWithImage/INIT'
const MARK = 'MatchTextWithImage/MARK'
const MOVE = 'MatchTextWithImage/MOVE'
const RETRY = 'MatchTextWithImage/RETRY'

const initialState = {
  actualEntities: [],
  availableCards: [],
  cards: {},
  completed: null,
  places: {},
}

const checkCompleted = places => every(places, 'correct')

const findAvailableCards = (cards, places) => (
  without(Object.keys(cards), ...map(places, 'cardId'))
)

const setPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.id === place.cardId,
    },
  }
)

const clearPlacesStatus = (a, place) => (
  {
    ...a,
    [place.id]: {
      ...place,
      correct: place.correct ? true : null,
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const entities = uniqShuffle(payload.entities).slice(0, payload.variants || 4)
      const cards = keyBy(shuffle(entities), 'id')
      const places = keyBy(entities, 'id')
      const availableCards = findAvailableCards(cards, places)
      return {
        ...initialState,
        actualEntities: entities,
        availableCards,
        cards,
        places,
      }
    }

    case MOVE: {
      const { sourceId, targetId } = payload
      const { places, cards } = moveCards(state, sourceId, targetId)
      const newPlaces = reduce(places, clearPlacesStatus, {})
      const availableCards = findAvailableCards(cards, places)
      return {
        ...state,
        availableCards,
        cards,
        places: newPlaces,
      }
    }

    case MARK: {
      const { places } = state
      const newPlaces = reduce(places, setPlacesStatus, {})
      const completed = checkCompleted(newPlaces)
      return {
        ...state,
        completed,
        places: newPlaces,
      }
    }

    case RETRY: {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const init = ({ entities, variants }) => ({
  payload: {
    entities,
    variants,
  },
  type: INIT,
})

const moveCard = ({ sourceId, targetId }) => ({
  payload: {
    sourceId,
    targetId,
  },
  type: MOVE,
})

const retry = () => ({
  type: RETRY,
})

const mark = () => ({
  type: MARK,
})

export {
  initialState,
  init,
  mark,
  moveCard,
  retry,
}

export default reducer
