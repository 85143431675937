import React from 'react'
import { Field } from 'formik'
import { t } from 'i18next'
import { arrayOf, func, shape, string, bool } from 'prop-types'
import ReactSelect from 'react-select'
import ValidationErrorMessage from 'src/components/ValidationErrorMessage'
import pluck from 'src/lib/pluck'

const propTypes = {
  className: string,
  getOptionLabel: func,
  getOptionValue: func,
  isMulti: bool,
  isSearchable: bool,
  labelToken: string.isRequired,
  name: string,
  options: arrayOf(
    shape({
      id: string.isRequired,
    })
  ),
}

const defaultProps = {
  className: '',
  getOptionLabel: undefined,
  getOptionValue: undefined,
  isMulti: false,
  isSearchable: false,
  name: undefined,
  options: [],
}

const normalizeValue = ({ value, options }) => {
  if (Array.isArray(value)) {
    return options.filter((option) => value.includes(option.id))
  }
  return options.find(
    ({ id }) => id === value || ({ ...value }.id && id === value.id)
  )
}
const Select = ({
  name,
  className,
  labelToken,
  options,
  isSearchable = false,
  isMulti = false,
  getOptionLabel = ({ title }) => title,
  getOptionValue = ({ id }) => id,
  ...rest,
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const fieldErrors = pluck(form.errors, field.name)
      // const fieldTouched = pluck(form.touched, field.name)
      return (
        <div>
          <label htmlFor={field.name}>{t(labelToken)}</label>
          <div className={className}>
            <ReactSelect
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              name={field.name}
              value={normalizeValue({ options, value: field.value })}
              onChange={(option) => {
                if (option.length) {
                  form.setFieldValue(
                    field.name,
                    option.map((o) => getOptionValue(o))
                  )
                } else {
                  form.setFieldValue(field.name, getOptionValue(option))
                }
              }}
              onBlur={field.onBlur}
              isMulti={isMulti}
              isSearchable={isSearchable}
              {...rest}
            />
          </div>
          <ValidationErrorMessage name={field.name} messages={fieldErrors} />
        </div>
      )
    }}
  </Field>
)

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
