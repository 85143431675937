import React from 'react'
import { useHistory } from 'react-router'
import AttendanceReport from 'src/components/AttendanceReport'
import { attendanceReportPath } from 'src/lib/routes'
import usePath from 'src/lib/routes/usePath'


const AttendanceReportPage = () => {
  const history = useHistory()
  const path = usePath(attendanceReportPath)
  return (
    <AttendanceReport
      onParamsChange={change => history.replace(path(change))}
    />
  )
}

export default AttendanceReportPage
