import React from 'react'
import gqlLoader from 'src/lib/gqlLoader'
import { compose } from 'src/lib/redux'
import * as collectionQueries from 'src/queries/collections'

const ChildrenWithProps = (Component) => (props) => [
  <Component key={Component.name} {...props} />,
]

const collectionProvider = ({ collections }) => (Component) => {
  const queries = collections
    .filter((collection) => collectionQueries[collection])
    .map((collection) =>
      gqlLoader(collectionQueries[collection], { renderWhenLoading: true })
    )

  return compose(...queries)(ChildrenWithProps(Component))
}

export default collectionProvider
