import chunk from 'lodash/chunk'

const INIT = 'SpeechBubblesQuestionAndAnswer/INIT'

const initialState = {
  actualEntities: [],
  chunkedEntities: [],
}


const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities } = payload
      const chunkedEntities = chunk(entities, 2)
      return {
        ...initialState,
        chunkedEntities,
      }
    }

    default: {
      return state
    }
  }
}

const init = entities => ({
  payload: {
    entities,
  },
  type: INIT,
})

export {
  init,
  initialState,
}

export default reducer
