import gqlLoader, { ignoreError, pollInterval } from 'src/lib/gqlLoader'
import StudentsLeaderboard from 'src/pages/DashboardPage/StudentsLeaderboard/StudentsLeaderboard'
import bestStudentsPerClass from 'src/pages/DashboardPage/StudentsLeaderboard/bestStudentsPerClass.gql'

const TEN_MINUTES = 10 * 60

export default gqlLoader(bestStudentsPerClass, {
  ...ignoreError,
  ...pollInterval(TEN_MINUTES),
})(StudentsLeaderboard)
