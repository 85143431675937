import { SKILLS } from 'src/constants/menu'
import icons from 'src/lib/menu/icons'
import { READING, WRITING, LISTENING, SPEAKING } from 'src/constants/skills'

const path = '/:domain/:studentId?/skills/:subject?/:subjectId?'

const portalPath = '/portal/:parentId/skills/:studentId?/:subject?/:subjectId?'

const scene = {
  icon: icons[SKILLS],
  id: SKILLS,
  path,
}

const skillIcon = {
  [LISTENING]: 'headphones',
  [READING]: 'book-reader',
  [SPEAKING]: 'comment',
  [WRITING]: 'edit',
}

export {
  path, portalPath, scene, skillIcon,
}
