import React from 'react'
import { array, func, string } from 'prop-types'
import map from 'lodash/map'
import PrefixedLink from 'src/components/PrefixedLink'
import Div from 'src/components/Div'

const propTypes = {
  gradeSlug: string,
  redirectPath: func.isRequired,
  units: array,
  unitSlug: string,
}

const defaultProps = {
  gradeSlug: '',
  unitSlug: 'all',
  units: [],
}

const UnitFilter = ({ redirectPath, gradeSlug, units, unitSlug }) => (
  <Div flex flex-auto wrap gutter={1}>
    {
      map(units, unit => (
        <Div mb={1} key={unit.id}>
          <PrefixedLink
            to={redirectPath({ gradeSlug, subjectSlug: unit.subject.slug, unitSlug: unit.slug })}
            className={`
              bold px2 py1 border inline-block rounded button-hover
              ${unitSlug === unit.slug && 'bg-blue white'}
            `}
          >
            { unit.identifier }
          </PrefixedLink>
        </Div>
      ))
    }
  </Div>
)

UnitFilter.defaultProps = defaultProps
UnitFilter.propTypes = propTypes

export default UnitFilter
