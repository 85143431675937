import React from 'react'
import { func, string, bool } from 'prop-types'

import Subjects from 'src/components/GeneralFilter/SubjectFilter'
import Grades from 'src/components/GeneralFilter/GradeFilter'
import Units from 'src/components/GeneralFilter/UnitFilter'

const propTypes = {
  gradeSlug: string,
  redirectPath: func,
  showAll: bool,
  showUnits: bool,
  subjectSlug: string,
  unitSlug: string,
}

const defaultProps = {
  gradeSlug: '',
  redirectPath: undefined,
  showAll: false,
  showUnits: false,
  subjectSlug: '',
  unitSlug: 'all',
}

const GeneralFilter = ({
  gradeSlug,
  redirectPath,
  subjectSlug,
  unitSlug,
  showUnits,
  showAll,
}) => (
  <div>
    <Subjects
      showAll={showAll}
      gradeSlug={gradeSlug}
      redirectPath={redirectPath}
      subjectSlug={subjectSlug}
      unitSlug={unitSlug}
    />
    <Grades
      showAll={showAll}
      gradeSlug={gradeSlug}
      redirectPath={redirectPath}
      subjectSlug={subjectSlug}
      unitSlug={unitSlug}
    />
    {
      showUnits && (
        <Units
          gradeSlug={gradeSlug}
          redirectPath={redirectPath}
          subjectSlug={subjectSlug}
          unitSlug={unitSlug}
        />
      )
    }
  </div>
)

GeneralFilter.defaultProps = defaultProps
GeneralFilter.propTypes = propTypes

export default GeneralFilter
