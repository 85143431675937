import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'src/images/learn21-transparent.svg'
import { lessonsPath } from 'src/lib/routes'
import { t } from 'i18next'

export const path = '/welcome'

const Welcome = () => (
  <Link to={lessonsPath()}>
    <div className="flex justify-center align-center center height-100vh bg-blue white px3 mtn2">
      <div>
        <img alt="Learn21" src={logo} />
        <p className="h3 thin">
          {t('welcome.tagline')}
        </p>
      </div>
    </div>
    <style>
      {`
        body { background-color: #0088ee; }
      `}
    </style>
  </Link>
)

export default Welcome
