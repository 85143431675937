/* eslint react/prefer-stateless-function: 1 */
import React from 'react'
import { object, bool, func, string, array } from 'prop-types'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import MicAndImage from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer/MicAndImage'
import Speaker from 'src/modules/SpeechRecognition/SpeechRecognitionQuestionAndAnswer/Speaker'

const propTypes = {
  answerId: string.isRequired,
  asking: bool,
  autoPlay: bool,
  correct: bool,
  entitiesById: object.isRequired,
  hideAnswer: bool,
  keyPressRecognition: func.isRequired,
  loading: bool,
  questionId: string.isRequired,
  selected: bool.isRequired,
  silent: bool.isRequired,
  startRecognition: func.isRequired,
  stopRecognition: func.isRequired,
  transcripts: array.isRequired,
}

const defaultProps = {
  asking: false,
  autoPlay: null,
  correct: null,
  hideAnswer: false,
  loading: null,
}

const SpeechRecognitionQABase = ({
  answerId,
  asking,
  autoPlay,
  correct,
  entitiesById,
  hideAnswer,
  hintTranscripts,
  keyPressRecognition,
  loading,
  questionId,
  selected,
  silent,
  startRecognition,
  stopRecognition,
  transcripts,
}) => {
  const speakerComponent = ({ label, firstEntityId, secondEntityId }) => (
    <Speaker
      {...{
        autoPlay,
        entitiesById,
        firstEntityId,
        key: 'speaker',
        label,
        secondEntityId,
        silent,
      }}
    />
  )
  const mikAndImage = ({ label, entityId }) => (
    <MicAndImage
      {...{
        correct,
        entitiesById,
        entityId,
        hideAnswer,
        hintTranscripts,
        key: 'microphone',
        keyPressRecognition,
        label,
        loading,
        selected,
        silent,
        startRecognition,
        stopRecognition,
        transcripts,
      }}
    />
  )
  return (
    <Deck className="SpeechRecognitionQABase">
      <Div flex auto column>
        {
         asking ? (
           [
             mikAndImage({ entityId: questionId, label: 1 }),
             speakerComponent({ firstEntityId: answerId, label: 2, secondEntityId: answerId }),
           ]
         )
           : (
             [
               speakerComponent({ firstEntityId: questionId, label: 1, secondEntityId: answerId }),
               mikAndImage({ entityId: answerId, label: 2 }),
             ]
           )
       }
      </Div>
    </Deck>
  )
}

SpeechRecognitionQABase.propTypes = propTypes
SpeechRecognitionQABase.defaultProps = defaultProps

export default SpeechRecognitionQABase
