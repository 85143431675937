import React from 'react'
import { any, bool, string, shape } from 'prop-types'

const propTypes = {
  children: any.isRequired,
  className: string,
  disabled: bool,
  style: shape(),
}

const defaultProps = {
  className: '',
  disabled: false,
  style: {},
}

const Dropdown = ({ children, className, style, disabled }) => (
  <div
    className={
      `${disabled ? 'cursor-initial' : 'cursor-pointer button-hover show-on-hover'}
      relative z3 flex flex-column ${className}`
    }
    style={style}
  >
    {children}
  </div>
)

Dropdown.propTypes = propTypes
Dropdown.defaultProps = defaultProps

export default Dropdown
