import React from 'react'
import { UserContext } from 'src/components/SetUser/SetUser'

const WithUser = ({ children }) => {
  const user = React.useContext(UserContext)
  return React.cloneElement(children, { user })
}

const WithUserId = ({ children }) => {
  const user = React.useContext(UserContext)
  return React.cloneElement(children, { userId: user ? user.slug : null })
}

export {
  WithUser,
  WithUserId,
}

export default WithUser
