const considerFix = (check, fix) => item => (check(item) ? fix(item) : item)

const isCardImageMissing = (card) => {
  const entityHasImage = entity => !!(entity.image && entity.image.src)
  const { type, entity } = card
  return type === 'image' && !entityHasImage(entity)
}

const makeCardEnglish = card => ({ ...card, type: 'english' })

const considerCardImageFix = considerFix(isCardImageMissing, makeCardEnglish)

const fallbackToEnglish = cards => Object.keys(cards)
  .reduce((r, e) => ({ ...r, [e]: considerCardImageFix(cards[e]) }), {})

export default fallbackToEnglish
