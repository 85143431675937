import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Mutation } from '@apollo/react-components'
import { graphql } from '@apollo/react-hoc'
import URLSearchParams from '@ungap/url-search-params'
import { Redirect } from 'react-router-dom'
import { t } from 'i18next'
import { loginPath, userHomePagePath } from 'src/lib/routes'
import { successMessage } from 'src/lib/toaster'
import NoCurrentUser from 'src/pages/CurrentUser/NoCurrentUser'
import CurrentUser from 'src/pages/CurrentUser/CurrentUser'
import parseErrors from 'src/lib/form/parseErrors'
import ResetPassword from 'src/pages/ResetPassword/ResetPassword'
import mutation from 'src/pages/ResetPassword/ResetPassword.gql'

const ResetPasswordFormPathRedirection = () => <Redirect to={loginPath()} />

const UserHomePath = (user) => <Redirect to={userHomePagePath(user)} />

const messages = {
  default: t('send.default.success'),
  email: t('send.email.success'),
}

const showSuccessMessage = (type) => {
  const title = t('user.user')
  const message = messages[type]
  successMessage({ message, title })
}

const ResetPasswordContainer = () => {
  const [success, setSuccess] = useState(null)
  const location = useLocation()

  const handleSuccess = ({ success, type }) => {
    showSuccessMessage(type)
    setSuccess(success)
  }

  useEffect(() => {
    const { search } = location
    const params = new URLSearchParams(search)
    if (params.has('success')) {
      handleSuccess({ success: params.get('success'), type: 'email' })
    }
  }, [location])

  if (success) return ResetPasswordFormPathRedirection()

  return (
    <div>
      <NoCurrentUser>
        <Mutation
          mutation={mutation}
          onCompleted={(data) => {
            const { success, type } = data.createResetPasswordToken
            handleSuccess({ success, type })
          }}
        >
          {(resetPassword) => (
            <ResetPassword
              onSubmit={(values, { setSubmitting, setErrors }) => {
                resetPassword({ variables: values }).catch((errors) => {
                  setErrors(parseErrors(errors))
                })
                setSubmitting(false)
              }}
            />
          )}
        </Mutation>
      </NoCurrentUser>
      <CurrentUser>
        <UserHomePath />
      </CurrentUser>
    </div>
  )
}

export default graphql(mutation)(ResetPasswordContainer)
