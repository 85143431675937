import find from 'lodash/find'
import chunk from 'lodash/chunk'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import uniqShuffle from 'src/lib/uniqShuffle'

const INIT_SENTENCE = 'SentenceMarker/INIT_SENTENCE'
const INIT_WORD = 'ToneMarkerWord/INIT_WORD'
const CLICK = 'SentenceMarker/CLICK'
const MARK = 'SentenceMarker/MARK'
const RETRY = 'SentenceMarker/RETRY'

const initialState = {
  actualEntities: [],
  cards: {},
  displayEntityId: '',
  entitiesById: {},
  hearEntityId: '',
}

const createCards = ({ expect }) => (a, phonic) => (
  {
    ...a,
    [phonic.id]: {
      expect: phonic.title.toLowerCase() === expect.toLowerCase(),
      id: phonic.id,
      label: size(a) + 1,
      src: phonic.src,
      title: phonic.title,
    },
  }
)

const createSyllablesCards = (a, syllable) => (
  {
    ...a,
    [syllable.id]: {
      expect: syllable.title.slice(0, 1) === "'",
      id: syllable.id,
      label: size(a) + 1,
      src: syllable.src,
      title: syllable.title.slice(0, 1) === "'" ? syllable.title.slice(1) : syllable.title,
    },
  }
)

const setCardStatus = ({ targetId }) => (a, card) => (
  {
    ...a,
    [card.id]: {
      ...card,
      status: targetId === card.id ? 'selected' : null,
    },
  }
)

const checkCardStatus = (a, card) => (
  {
    ...a,
    [card.id]: {
      ...card,
      status: card.status === 'selected' ? card.expect : null,
    },
  }
)

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case (INIT_SENTENCE): {
      const { entities } = payload
      const [incorrectEntity, correctEntity, wordEntity] = uniqShuffle(chunk(entities, 3))[0]
      const correctEntityId = correctEntity.id
      const cards = incorrectEntity.phonicsEn
        ? incorrectEntity.phonicsEn.reduce(createCards({ expect: wordEntity.titleEn }), {})
        : {}
      return {
        ...initialState,
        actualEntities: [incorrectEntity, correctEntity, wordEntity],
        cards,
        entitiesById: keyBy(entities, 'id'),
        hearEntityId: correctEntityId,
      }
    }

    case (INIT_WORD): {
      const { entities } = payload
      const entity = uniqShuffle(entities)[0]
      const cards = entity.phonicsEn
        ? entity.phonicsEn.reduce(createSyllablesCards, {})
        : {}
      return {
        ...initialState,
        actualEntities: [entity],
        cards,
        entitiesById: keyBy(entities, 'id'),
        hearEntityId: entity.id,
      }
    }

    case (CLICK): {
      const { id } = payload
      const cards = reduce(state.cards, setCardStatus({ targetId: id }), {})
      return {
        ...state,
        cards,
      }
    }

    case (MARK): {
      const cards = reduce(state.cards, checkCardStatus, {})
      return {
        ...state,
        cards,
        completed: Boolean(find(cards, { status: true })),
      }
    }

    case (RETRY): {
      return {
        ...state,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

const initSentence = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT_SENTENCE,
})

const initWord = ({ entities }) => ({
  payload: {
    entities,
  },
  type: INIT_WORD,
})

const mark = () => ({
  type: MARK,
})

const onClick = ({ id }) => ({
  payload: { id },
  type: CLICK,
})

const retry = () => ({
  type: RETRY,
})

export {
  initSentence,
  initWord,
  onClick,
  mark,
  retry,
}

export default reducer
