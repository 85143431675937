import { useState, useEffect } from 'react'

const useTimeout = (delay) => {
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    const onTimeout = () => setExpired(true)
    const id = setTimeout(() => onTimeout(), delay)
    return () => clearTimeout(id)
  })
  return expired
}

export default useTimeout
