import React from 'react'
import { arrayOf, bool, shape } from 'prop-types'
import userShape from 'src/shapes/user'
import academicYearShape from 'src/shapes/academicYear'
import Div from 'src/components/Div'
import UserImage from 'src/components/UserImage'
import UserDetail from 'src/components/UserPassport/UserDetail'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  editable: bool,
  user: shape(userShape).isRequired,
}

const defaultProps = {
  editable: false,
}

const UserPassport = ({ academicYears, editable, user }) => (
  user ? (
    <Div bg-white rounded p1>
      <Div flex gutter={1}>
        <Div auto>
          <UserDetail
            academicYears={academicYears}
            user={user}
            editable={editable}
          />
        </Div>
        <div>
          <UserImage user={user} />
        </div>
      </Div>
    </Div>
  ) : null
)

UserPassport.propTypes = propTypes
UserPassport.defaultProps = defaultProps

export default UserPassport
