import React from 'react'
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { select } from 'src/modules/BlendingSounds/reducer'
import BlendingSoundsScene from 'src/modules/BlendingSounds/BlendingSoundsScene'

export default connect(
  ({ blendingSounds: { entitiesById, phonics, answers, completed, correct, selected } }) => ({
    answers: answers.map(id => entitiesById[id]),
    correct: isNil(completed) ? null : correct,
    phonics: phonics.map(id => entitiesById[id]),
    selected,
  }),
  dispatch => bindActionCreators({ onSelectAnswer: select }, dispatch),
)(props => (props.phonics ? <BlendingSoundsScene {...props} /> : null))
