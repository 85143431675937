import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import collectionProvider from 'src/lib/collectionProvider'
// eslint-disable-next-line import/no-namespace
import * as gameActions from 'src/modules/ListeningWords/reducer'
import ListeningSounds from 'src/modules/ListeningSounds/ListeningSounds'


const select = state => state.listeningWords
const actions = dispatch => bindActionCreators(gameActions, dispatch)

const withPhonicsAndRedux = comp => connect(select, actions)(
  collectionProvider({ collections: ['phonics'] })(comp),
)
const ListeningSounds2Container = withPhonicsAndRedux(props => (
  <ListeningSounds {...{ ...props, size: 2 }} />
))
const ListeningSounds3Container = withPhonicsAndRedux(props => (
  <ListeningSounds {...{ ...props, size: 3 }} />
))

export { ListeningSounds2Container, ListeningSounds3Container }
