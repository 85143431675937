import React, { Fragment } from 'react'
import { t } from 'i18next'
import { number, string, shape } from 'prop-types'
import Div from 'src/components/Div'
import { attendanceWeeklyPath } from 'src/lib/routes'
import {
  add,
  subtract,
  week as formatWeek,
  weekYear as formatWeekYear,
  formatMonthMedium,
  formatDayShortNumeric,
} from 'src/lib/dates'
import PrefixedLink from 'src/components/PrefixedLink'

const propTypes = {
  academicYear: shape({
    title: string.isRequired,
  }),
  beginningOfWeek: string,
  endOfWeek: string,
  week: number.isRequired,
}

const defaultProps = {
  academicYear: undefined,
}

const Header = ({ academicYear, beginningOfWeek, endOfWeek, week }) => {
  const prevWeek = subtract(beginningOfWeek, 1, 'week')
  const nextWeek = add(beginningOfWeek, 1, 'week')
  return (
    <Div flex auto>
      <div>
        <PrefixedLink to={attendanceWeeklyPath({
          week: formatWeek(prevWeek),
          year: formatWeekYear(prevWeek) })}
        >
          ← {t('navigation.previous')}
        </PrefixedLink>
      </div>
      <Div center fx={1}>
        <Div h4 bold>
          {`${formatMonthMedium(beginningOfWeek)} ${formatDayShortNumeric(beginningOfWeek)}`}
          -
          {`${formatMonthMedium(endOfWeek)} ${formatDayShortNumeric(endOfWeek)}`}
        </Div>
        <Div bold>
          {t('shared.week')} {week}
          {academicYear && <Fragment>, {t('shared.academicYear')} {academicYear.title}</Fragment>}
        </Div>
      </Div>
      <div>
        <PrefixedLink to={attendanceWeeklyPath({
          week: formatWeek(nextWeek),
          year: formatWeekYear(nextWeek) })}
        >
          {t('navigation.next')} →
        </PrefixedLink>
      </div>
    </Div>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
