const display = {
  8: '<i class="fas fa-backspace"></i>',
  32: 'space',
  33: '!',
  34: '"',
  35: '#',
  36: '$',
  37: '%',
  38: '&',
  40: '(',
  41: ')',
  42: '*',
  46: '.',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  58: ':',
  59: ';',
  60: '<',
  62: '>',
  63: '?',
  64: '@',
  65: 'a',
  66: 'b',
  67: 'c',
  68: 'd',
  69: 'e',
  70: 'f',
  71: 'g',
  72: 'h',
  73: 'i',
  74: 'j',
  75: 'k',
  76: 'l',
  77: 'm',
  78: 'n',
  79: 'o',
  80: 'p',
  81: 'q',
  82: 'r',
  83: 's',
  84: 't',
  85: 'u',
  86: 'v',
  87: 'w',
  88: 'x',
  89: 'y',
  90: 'z',
  91: '[',
  92: '\\',
  93: ']',
  94: '^',
  95: '_',
  109: '-',
  123: '{',
  124: '|',
  125: '}',
  126: '~',
  177: '+',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  222: '\'',
  301: 'A',
  302: 'B',
  303: 'C',
  304: 'D',
  305: 'E',
  306: 'F',
  307: 'G',
  308: 'H',
  309: 'I',
  310: 'J',
  311: 'K',
  312: 'L',
  313: 'M',
  314: 'N',
  315: 'O',
  316: 'P',
  317: 'Q',
  318: 'R',
  319: 'S',
  320: 'T',
  321: 'U',
  322: 'V',
  323: 'W',
  324: 'X',
  325: 'Y',
  326: 'Z',
  401: '123',
  402: 'ABC',
  403: '<i class="fas fa-arrow-up"></i>',
  404: '<i class="fas fa-arrow-down"></i>',
  405: '#+=',
}

const layout = {
  default: [
    '81 87 69 82 84 89 85 73 79 80',
    '401 65 83 68 70 71 72 74 75 76 8',
    '403 90 88 67 86 66 78 77 32',
  ],
  num: [
    '49 50 51 52 53 54 55 56 57 48',
    '405 189 191 58 59 40 41 36 38 64 34 8',
    '402 190 188 63 33 222 32',
  ],
  shift: [
    '317 323 305 318 320 325 321 309 315 316',
    '401 301 319 304 306 307 308 310 311 312 8',
    '404 326 324 303 322 302 314 313 32',
  ],
  symbol: [
    '91 93 123 125 35 37 94 42 177 187',
    '401 95 92 124 126 60 62 36 38 8',
    '402 190 188 63 33 222 32',
  ],
}

export { display, layout }
