import React from 'react'
import { string, number, oneOf, bool } from 'prop-types'
import KawaiiElementWrapper from 'src/components/Human/KawaiiElementWrapper'
import Face from 'src/components/Human/Face'
import paths from 'src/components/Human/paths'

const Human = props => (
  <KawaiiElementWrapper
    style={{ height: `${props.size}rem`, width: `${props.size}rem` }}
    showTextOnHover={props.showTextOnHover}
    text={props.text}
    width={props.size}
    height={props.size}
    color={props.color}
  >
    <svg
      width={`${props.size}rem`}
      height={`${props.size}rem`}
      viewBox="114 94 151 150"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path d={paths.path1} id="kawaii-planet__path-1" />
        <path d={paths.path2} id="kawaii-planet__path-3" />
      </defs>
      <g
        id="kawaii-planet"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(115.000000, 94.000000)"
      >
        <g
          id="kawaii-planet__body-color"
          transform="translate(8.000000, 8.000000)"
        >
          <mask id="kawaii-planet__mask-2" fill="white">
            <use xlinkHref="#kawaii-planet__path-1" />
          </mask>
          <use
            id="kp-body-color-"
            fill={props.color}
            xlinkHref="#kawaii-planet__path-1"
          />
          <mask id="kawaii-planet__mask-4" fill="white">
            <use xlinkHref="#kawaii-planet__path-3" />
          </mask>
          <use
            id="kp-body-color-darker"
            fill="#111111"
            opacity="0.233908582"
            xlinkHref="#kawaii-planet__path-3"
          />
        </g>
        <Face mood={props.mood} transform="translate(42.000000, 65.000000)" />
      </g>
    </svg>
  </KawaiiElementWrapper>
)

Human.propTypes = {
  /**
    * Size of the width
    */
  color: string,
  mood: oneOf([
    'sad',
    'shocked',
    'happy',
    'blissful',
    'lovestruck',
  ]),
  /**
   * Set the text to show on the speech bubble
   */
  showTextOnHover: bool,
  /**
   * Set as true to show the speech bubble on hover, as false to show text by default
   */
  size: number,
  /**
    * Hex color
    */
  text: string,
}

Human.defaultProps = {
  color: '#83D1FB',
  mood: 'blissful',
  showTextOnHover: true,
  size: 16,
  text: null,
}

export default Human
