import React from 'react'
import SelectTypeContainer from 'src/modules/SelectType'

const TrueOrFalseParagraphContainer = props => (
  <SelectTypeContainer maximumRow={3} {...props} trueOrFalseType="standard" />
)

const TrueOrFalseParagraphs = props => (
  <SelectTypeContainer
    headerHeight={25}
    maximumRow={3}
    {...props}
    trueOrFalseType="standard"
  />
)

const TrueOrFalseAudio = props => <TrueOrFalseParagraphContainer silent audioOnly {...props} />

const TrueOrFalseAudio3 = props => (
  <TrueOrFalseParagraphContainer silent audioOnly maximumRow={3} {...props} />
)

const TrueOrFalseAudio4 = props => (
  <TrueOrFalseParagraphContainer silent audioOnly maximumRow={4} {...props} />
)

const demoData = {
  entities: [
    'paragraph-all-about-me',
    'collection-true',
    'phrase-sara-has-big-blue-eyes',
    'phrase-sam-has-black-hair',
    'collection-false',
    'phrase-sara-has-long-black-curly-hair',
    'phrase-sam-has-big-eyes',
  ],
}

TrueOrFalseParagraphContainer.demoData = demoData
TrueOrFalseAudio.demoData = demoData
TrueOrFalseAudio3.demoData = demoData
TrueOrFalseAudio4.demoData = demoData
TrueOrFalseParagraphs.demoData = {
  ...demoData,
  entities: ['paragraph-all-about-me', ...demoData.entities],
}

export {
  TrueOrFalseAudio,
  TrueOrFalseAudio3,
  TrueOrFalseAudio4,
  TrueOrFalseParagraphs,
}
export default TrueOrFalseParagraphContainer
