import React from 'react'
import { array, func, number } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import MultipleChoiceCore from 'src/modules/MultipleChoice/MultipleChoiceCore'
import * as gameActions from 'src/modules/MultipleChoice/reducer'

const propTypes = {
  cardsSize: number,
  entities: array.isRequired,
  initDefault: func.isRequired,
}

const defaultProps = {
  audioOnly: false,
  cardsSize: 3,
  silent: false,
}

const MultipleChoiceContainer = props => (
  <MultipleChoiceCore
    init={() => props.initDefault({ cardsSize: props.cardsSize, entities: props.entities })}
    {...props}
  />
)

MultipleChoiceContainer.demoData = {
  entities: [
    'phrase-what-is-she-doing',
    'phrase-she-is-cooking-dinner',
    'phrase-what-is-she-doing',
    'phrase-she-is-washing-the-dishes',
  ],
}

const select = state => state.multipleChoice
const actions = dispatch => bindActionCreators(gameActions, dispatch)

MultipleChoiceContainer.propTypes = propTypes
MultipleChoiceContainer.defaultProps = defaultProps

export { MultipleChoiceContainer }
export default connect(select, actions)(MultipleChoiceContainer)
