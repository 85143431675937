import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import { withSelection } from 'src/components/Selection'
import assignYearGQL from 'src/pages/Users/SelectionActions/assignYearMutation.gql'
import AssignYearContainer from 'src/pages/Users/SelectionActions/AssignYearContainer'

const AssignYearContainerContainer = compose(
  graphql(assignYearGQL, { name: 'assignMutation' }),
  graphql(assignYearGQL, { name: 'resignMutation' }),
)(withSelection(AssignYearContainer))

export {
  // eslint-disable-next-line import/prefer-default-export
  AssignYearContainerContainer as AssignYear,
}
