import icons from 'src/lib/menu/icons'
import { CALENDAR } from 'src/constants/menu'

export const path = '/:domain/:userId?/calendar'

export const scene = {
  common: true,
  icon: icons[CALENDAR],
  id: CALENDAR,
  path,
}
