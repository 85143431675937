import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { hideAndClearSoftKeyboardInputs } from 'src/components/SoftKeyboard/reducer'
import { GameContext } from 'src/lib/contexts'
import GameWrapper from 'src/components/GameWrapper/GameWrapper'

const GameWrapperContainer = props => (
  <GameContext.Consumer>
    {({ chapterId, userId }) => <GameWrapper {...props} chapterId={chapterId} userId={userId} />}
  </GameContext.Consumer>
)

const select = state => state.softKeyboard
const actions = dispatch => bindActionCreators({ hideAndClearSoftKeyboardInputs }, dispatch)

export { GameWrapperContainer }

export default connect(select, actions)(GameWrapperContainer)
