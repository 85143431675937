import { shape, string, bool } from 'prop-types'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import entityShape from 'src/shapes/entity'
import renderCard from 'src/components/EntityCard/renderCard'

const propTypes = {
  entity: shape(entityShape).isRequired,
  meaning: bool,
  type: string,
}

const defaultProps = {
  audio: false,
  autoPlay: false,
  autoPlayOnce: false,
  bold: undefined,
  english: false,
  image: false,
  imageSrc: null,
  keyToPlay: null,
  meaning: false,
  onClick: null,
  onKeyPress: null,
  play: false,
  playNext: null,
  selected: false,
  thai: false,
  type: null,
}

const EntityCard = ({
  entity,
  type,
  audio,
  autoPlay,
  autoPlayOnce,
  english,
  thai,
  image,
  bold,
  meaning,
  ...rest
}) => {
  const stringTypes = mapValues(keyBy(`${type}`.split(' ')), () => true)
  const boolTypes = {
    audio,
    autoPlay,
    autoPlayOnce,
    english,
    image,
    thai,
  }
  const {
    audio: theAudio,
    autoPlay: theAutoPlay,
    autoPlayOnce: theAutoPlayOnce,
    english: theEnglish,
    thai: theThai,
    image: theImage, // boolean
    m: unitM,
    cm: unitCM,
    mm: unitMM,
  } = {
    ...boolTypes,
    ...stringTypes,
  }
  const meaningEntity = { ...entity }.meaning
  const meaningSound = { ...meaningEntity }.sound
  const src = (theAudio || theAutoPlay || theAutoPlayOnce)
  && ({ ...entity.sound }.src) // eslint-disable-line no-mixed-operators
  || (
    (theEnglish && meaning) && { ...meaningSound }.srcEn // eslint-disable-line no-mixed-operators
  )
  || (theEnglish && { ...entity.sound }.srcEn) // eslint-disable-line no-mixed-operators
  || (theThai && { ...entity.sound }.srcTh) // eslint-disable-line no-mixed-operators
  || (theImage && { ...entity.image }.src) // eslint-disable-line no-mixed-operators
  return renderCard({
    audio: theAudio,
    autoPlay: theAutoPlay,
    bold,
    cm: unitCM,
    english: theEnglish,
    entity,
    image: theImage,
    m: unitM,
    mm: unitMM,
    src,
    thai: theThai,
    ...rest,
  })
}

EntityCard.propTypes = propTypes
EntityCard.defaultProps = defaultProps

export default EntityCard
