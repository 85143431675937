import React from 'react'
import { object, string } from 'prop-types'
import map from 'lodash/map'
import Answer from 'src/components/Answer'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Bubble from 'src/components/Bubble'
import Card from 'src/components/Card'
import EntityImage from 'src/components/EntityImage'

const propTypes = {
  cards: object.isRequired,
  entitiesById: object.isRequired,
  image: string.isRequired,
}

const defaultProps = {
  entitiesById: [],
}

const FillInMissingWordType = ({
  cards,
  completed,
  entitiesById,
  image,
  answer,
  handleChange,
  isSoftKeyboardShow,
}) => {
  const imageEntity = entitiesById[image]
  const imageObject = { ...imageEntity }.image
  const imageSrc = { ...imageObject }.src
  const soundObject = { ...imageEntity }.sound
  const srcObject = { ...soundObject }.srcEn
  return (
    <Deck>
      <Div flex auto mb3>
        <Bubble
          label=" "
        >
          <Div flex auto>
            <Div flex auto col={8}>
              <Card
                key={image}
                src={srcObject}
                audio
                keyToPlay=" "
              >
                <Div flex column justify-center>
                  <Div flex wrap bold justify="center">
                    {
                      map(cards, (({ id, title, selected, correct }) => (
                        <Div
                          flex
                          key={id}
                        >
                          {
                            completed
                              ? <Card title={title} size={2} fit transparent={completed} />
                              : (
                                <Card
                                  title={title}
                                  size={2}
                                  fit
                                  selected={selected}
                                  correct={correct}
                                />
                              )
                          }
                        </Div>
                      )))
                    }
                  </Div>
                </Div>
              </Card>
            </Div>
            {
              image && imageSrc && (
                <Div flex col={4}>
                  <EntityImage entity={entitiesById[image]} contain />
                </Div>
              )
            }
          </Div>
        </Bubble>
      </Div>
      <Div flex justify="center" relative>
        <Div flex auto justify="center" relative answerSoftKeyBoard={isSoftKeyboardShow}>
          <Answer
            auto
            correct={completed}
            onChange={value => handleChange(value)}
            value={answer}
          />
        </Div>
      </Div>
    </Deck>
  )
}

FillInMissingWordType.propTypes = propTypes
FillInMissingWordType.defaultProps = defaultProps

export default FillInMissingWordType
