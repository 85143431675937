import React from 'react'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import MicrophoneButton from 'src/components/MicrophoneButton'
import Bubble from 'src/components/Bubble'

const selectColor = ({ correct, cardCorrect, silent }) => {
  if (correct === undefined) return null
  if (correct) return { color: 'green' }
  if (silent && cardCorrect === false && correct === false) return null
  return { color: 'red', textDecoration: 'underline' }
}

const MicAndImage = ({
  correct: cardCorrect,
  entitiesById,
  entityId,
  hideAnswer,
  hintTranscripts,
  keyPressRecognition,
  label,
  loading,
  selected,
  silent,
  startRecognition,
  stopRecognition,
  transcripts,
}) => {
  const sound = entitiesById[entityId].sound || {}
  const src = sound.srcEn || ''
  return (
    <Div flex align="center" py={2} px={2}>
      <MicrophoneButton
        loading={loading}
        selected={selected}
        onKeyPress={keyPressRecognition}
        onMouseDown={startRecognition}
        onMouseUp={stopRecognition}
      />
      <Bubble label={silent ? null : label}>
        <Card
          english
          audio
          key={entitiesById[entityId].id}
          keyToPlay={label}
          src={src}
          silent={silent}
          correct={cardCorrect}
        >
          <Div flex p1 wrap justify-center>
            {
              hideAnswer ? (
                hintTranscripts.map(({ titleEn, id }) => (
                  <Div key={id} flex mr1>
                    <Card
                      title={titleEn}
                      minHeight={false}
                      transparent
                      className="remove-border-print"
                    />
                  </Div>
                ))
              )
                : (
                  transcripts.map(({ titleEn, id, correct }) => (
                    <Div key={id} flex mr1>
                      <Card
                        title={titleEn}
                        minHeight={false}
                        transparent
                        style={selectColor({ cardCorrect, correct, silent })}
                        className="remove-border-print"
                      />
                    </Div>
                  ))
                )
            }
          </Div>
        </Card>
      </Bubble>
    </Div>
  )
}

export default MicAndImage
