import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import { ATTENDANCE, SKILLS } from 'src/constants/menu'
import { UserContext } from 'src/components/SetUser/SetUser'
import { linkTo } from 'src/lib/linkTo'
import { portalPath as attendancePath } from 'src/pages/StudentAttendance/config'
import { portalPath as skillsPath } from 'src/pages/StudentSkillsPage/config'
import StudentLogin from 'src/pages/Students/StudentLogin'
import LevelSelector from 'src/components/LevelSelector'
import Fakebutton from 'src/components/Fakebutton'
import Tooltip from 'src/components/Tooltip'

const StudentBar = ({ studentId }) => {
  const { slug: parentId } = useContext(UserContext)
  return (
    <Div key={studentId} flex flex-wrap mxn1 align-center>
      <Div px1 mb1 flex-auto>
        <Tooltip vertical tip={t('student.loginAndDoHomework')}>
          <StudentLogin studentId={studentId} />
        </Tooltip>
      </Div>

      <Div flex>
        <Div px1 mb1>
          <Tooltip vertical tip={t('student.seeSkills')}>
            <Link to={linkTo(skillsPath)({ parentId, studentId })}>
              <Fakebutton>
                <Icon icon="chart-bar" />
                {t(`${SKILLS}.title`)}
              </Fakebutton>
            </Link>
          </Tooltip>
        </Div>

        <Div px1 mb1>
          <Tooltip vertical tip={t('student.seeSkills')}>
            <Link to={linkTo(attendancePath)({ parentId, studentId })}>
              <Fakebutton>
                <Icon icon="chart-pie" />
                {t(`${ATTENDANCE}.title`)}
              </Fakebutton>
            </Link>
          </Tooltip>
        </Div>

        <Div px1 mb1>
          <Tooltip vertical tip={t('student.switchLevel')}>
            <LevelSelector small animated={false} userId={studentId} />
          </Tooltip>
        </Div>
      </Div>

    </Div>
  )
}

export default StudentBar
