import icons from 'src/lib/menu/icons'
import { HOMEWORK } from 'src/constants/menu'

const path = '/:domain/:studentId?/homework'

const portalPath = '/portal/:parentId/homework/:studentId?'

const scene = {
  icon: icons[HOMEWORK],
  id: HOMEWORK,
  path,
}

export {
  path, portalPath, scene,
}
