import buildPath from 'src/lib/routes/buildPath'

const reportPath = ({ domain, academicTermId, academicYearId } = {}) => (
  buildPath([
    domain,
    'reports',
    ...(academicYearId
      ? [academicYearId, academicTermId || 'all']
      : []
    ),
  ])
)

export default reportPath
