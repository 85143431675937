import { useEffect, useState } from 'react'
import { string } from 'prop-types'

const propTypes = {
  children: string.isRequired,
}

const PageTitle = ({ children }) => {
  const [title] = useState(document.title)
  useEffect(() => {
    document.title = children
    return () => { document.title = title }
  })
  return null
}

PageTitle.propTypes = propTypes
export default PageTitle
