import React from 'react'
import { func, element, string } from 'prop-types'
import { t } from 'i18next'
import { DEFAULT_DATE_FORMAT, weekendDaysFromRange, add, subtract, dateToday } from 'src/lib/dates'
import DatePicker from 'src/components/DatePicker'

const propTypes = {
  children: element.isRequired,
  endDate: string.isRequired,
  endDateFieldId: string.isRequired,
  onEndDateChange: func.isRequired,
  onStartDateChange: func.isRequired,
  startDate: string.isRequired,
  startDateFieldId: string.isRequired,
}

const highlightWeekends = (startDate, endDate) => [
  { red: weekendDaysFromRange(startDate, endDate) },
]

const weekendsToHighlight = highlightWeekends(
  subtract(dateToday(), 10, 'years'),
  add(dateToday(), 10, 'years'),
)

const fitsBoundaries = (min, max) => date => date > min && date < max

const AcademicYearTermForm = ({
  children,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startDateFieldId,
  endDateFieldId,
}) => {
  const startMin = subtract(endDate, 100, 'years')
  const startMax = endDate
  const endMin = startDate
  const endMax = add(startDate, 100, 'years')
  const checkStartDate = fitsBoundaries(startMin, startMax)
  const checkEndDate = fitsBoundaries(endMin, endMax)
  return (
    <div className="fields-group">
      <div className="date-box">
        <label htmlFor={startDateFieldId}>
          {t('direction.from')}
        </label>
        <DatePicker
          id={startDateFieldId}
          dateFormat={DEFAULT_DATE_FORMAT}
          selected={startDate}
          onChange={date => checkStartDate(date) && onStartDateChange(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={startMin}
          maxDate={startMax}
          todayButton={t('shared.today')}
          highlightDates={weekendsToHighlight}
        />
      </div>
      <div className="date-box">
        <label htmlFor={endDateFieldId}>
          {t('direction.to')}
        </label>
        <DatePicker
          id={endDateFieldId}
          dateFormat={DEFAULT_DATE_FORMAT}
          selected={endDate}
          onChange={date => checkEndDate(date) && onEndDateChange(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={endMin}
          maxDate={endMax}
          todayButton={t('shared.today')}
          highlightDates={weekendsToHighlight}
        />
      </div>
      {children}
    </div>
  )
}

AcademicYearTermForm.propTypes = propTypes

export default AcademicYearTermForm
