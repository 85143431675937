import React from 'react'
import { arrayOf, shape } from 'prop-types'
import Div from 'src/components/Div'
import LessonsPageLessonsLesson from 'src/pages/LessonsPage/Lessons/Lesson'
import yearShape from 'src/shapes/year'
import subjectShape from 'src/shapes/subject'
import subjectCode from 'src/lib/subjectCodeGenerator'

const propTypes = {
  selectedYear: shape(yearShape),
  subjects: arrayOf(shape(subjectShape)).isRequired,
}

const defaultProps = {
  selectedYear: undefined,
}

const LessonsPageLessons = ({ subjects, selectedYear }) => (
  <div className="qa-lessons">
    {
      subjects.map(subject => (
        <div key={subject.id}>
          <Div h3 mb1 bold key={subject.units[0].id}>
            {subject.title} {subjectCode({ subject, unit: subject.units[0] })}
          </Div>
          <Div ml2>
            {
              subject.units.map(unit => (
                <Div key={unit.id} mb={2} flex column auto>
                  <Div h4 bold mb1>{unit.identifier} {unit.title}</Div>
                  <Div ml={2}>
                    {
                      unit.lessons.map(lesson => (
                        <LessonsPageLessonsLesson
                          key={lesson.id}
                          {...lesson}
                          selectedYear={selectedYear}
                        />
                      ))
                    }
                  </Div>
                </Div>
              ))
            }
          </Div>
        </div>
      ))
      }
  </div>
)

LessonsPageLessons.propTypes = propTypes
LessonsPageLessons.defaultProps = defaultProps

export default LessonsPageLessons
