import React from 'react'
import { number, shape } from 'prop-types'
import Div from 'src/components/Div'
import Progress from 'src/components/Progress'
import Divider from 'src/components/Divider'
import { t } from 'i18next'
import { RELEASED } from 'src/constants/translation'

const propTypes = {
  releasedHomework: shape({
    released: number.isRequired,
    total: number.isRequired,
  }).isRequired,
}

const ReleasedHomeworkProgress = ({ releasedHomework: { released, total } }) => (
  <div>
    <Div flex px={1} py={1} align="center">
      <Div bold px={1}>
        {t('school.homeworkReleased')}
      </Div>
      <Div flex fx={1} px={2}>
        <Progress actual={released} total={total} showRatio type={RELEASED} />
      </Div>
    </Div>
    <Divider className="muted mt0 mb0" />
  </div>
)

ReleasedHomeworkProgress.propTypes = propTypes

export default ReleasedHomeworkProgress
