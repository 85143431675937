import query from 'src/queries/modules/Video.gql'
import gqlLoader from 'src/lib/gqlLoader'
import Video from 'src/modules/Video/Video'

const VideoContainer = gqlLoader(query)(Video)

VideoContainer.demoData = {
  background: 'black',
  video: 'p3f1l1',
}

export default VideoContainer
