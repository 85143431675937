import React from 'react'
import { arrayOf, shape } from 'prop-types'
import startsWith from 'lodash/startsWith'
import Slides from 'src/components/Layout/Slides'
import entityShape from 'src/shapes/entity'
import PhonicsSlide from 'src/modules/PhonicsSounds/PhonicsSlide'
import splitWhile from 'src/lib/splitWhile'
import splitIntoChunks from 'src/lib/splitIntoChunks'

const propTypes = {
  entities: arrayOf(shape(entityShape)).isRequired,
}

const chunks = splitIntoChunks((entities) => {
  const [phonics, ...rest] = entities
  const [sampleWord, tail] = splitWhile(rest, ({ slug }) => !startsWith(slug, 'phonics-'))
  return [[phonics, ...sampleWord], tail]
})

const PhonicsSounds = ({ entities }) => (
  <Slides>
    {chunks(entities).map(([phonics, word]) => (
      <PhonicsSlide
        key={phonics.slug}
        label={phonics.titleEn}
        phonics={phonics}
        word={word}
      />
    ))}
  </Slides>
)

PhonicsSounds.propTypes = propTypes

export default PhonicsSounds
