import React from 'react'
import { bool, string, shape } from 'prop-types'

import entityShape from 'src/shapes/entity'
import pluck from 'src/lib/pluck'

const propTypes = {
  guide: bool,
  size: string,
  unitTest: bool,
  url: string.isRequired,
}

const defaultProps = {
  guide: false,
  size: 'cover',
  unitTest: false,
  noPrint: false,
}

const BodyBackground = ({ guide, url, size, unitTest }) => (
  <style>
    {!unitTest &&
      `body {
        background-image: url("${url}") ${guide ? '' : '!important'};
        background-size: ${size};
      }`}
  </style>
)

const lbPropTypes = {
  entity: shape(entityShape),
  noPrint: bool,
}

const lbDefaultProps = {
  entity: {},
  noPrint: false,
}
//
// in homework spec it received null in props
const LessonBackground = ({ entity, noPrint }) => {
  const src = pluck(entity, 'image.src')
  return src ? (
    <BodyBackground url={src} size="cover" noPrint={noPrint} />
  ) : null
}

BodyBackground.propTypes = propTypes
BodyBackground.defaultProps = defaultProps
LessonBackground.propTypes = lbPropTypes
LessonBackground.defaultProps = lbDefaultProps

export { LessonBackground }
export default BodyBackground
