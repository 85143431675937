import React from 'react'
import { func, object } from 'prop-types'
import { t } from 'i18next'
import { Switch, Route, Redirect } from 'react-router-dom'
import { SCHOOL_SETTINGS } from 'src/constants/menu'
import { schoolSettingsPath } from 'src/lib/routes'
import Div from 'src/components/Div'
import Widget from 'src/components/Widget'
import Tabs from 'src/components/Tabs'
import NavTab from 'src/components/NavTab'
import { initialState } from 'src/components/TenantSettings/reducer'
import SchoolTitle from 'src/pages/SchoolSettings/SchoolTitle'
import AcademicYears from 'src/pages/SchoolSettings/AcademicYears'

const propTypes = {
  schoolTitle: object.isRequired,
  updateSchoolTitle: func.isRequired,
}

const defaultProps = initialState

const SchoolSettings = ({
  schoolTitle,
  updateSchoolTitle,
}) => {
  const title = t(`${SCHOOL_SETTINGS}.title`)
  return (
    <div>
      <Tabs>
        <NavTab to={schoolSettingsPath('school')}>
          {t('school.settings.school')}
        </NavTab>
        <NavTab to={schoolSettingsPath('calendar')}>
          {t('school.settings.calendar')}
        </NavTab>
      </Tabs>
      <Widget token={SCHOOL_SETTINGS} title={title}>
        <Div pb1 className="school-settings-holder">
          <Switch>
            <Route path={schoolSettingsPath('school')}>
              <SchoolTitle title={schoolTitle} updateSchoolTitle={updateSchoolTitle} />
            </Route>
            <Route path={schoolSettingsPath('calendar')}>
              <AcademicYears />
            </Route>
            <Redirect to={schoolSettingsPath('school')} />
          </Switch>
        </Div>
      </Widget>
    </div>
  )
}

SchoolSettings.propTypes = propTypes
SchoolSettings.defaultProps = defaultProps

export default SchoolSettings
