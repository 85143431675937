import React from 'react'
import { arrayOf, shape } from 'prop-types'
import compact from 'lodash/compact'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import Slides from 'src/components/Layout/Slides'
import LetterSlide from 'src/modules/PhonicsSample/LetterSlide'
import entityShape from 'src/shapes/entity'

const propTypes = {
  alphabetEn: arrayOf(shape(entityShape)).isRequired,
  entities: arrayOf(shape(entityShape)),
  phonics: arrayOf(shape(entityShape)).isRequired,
}

const defaultProps = {
  entities: [],
}

const slideKey = slug => slug.replace('phonics-', '').replace('letter-en-', '')

const PhonicsSample = ({ alphabetEn, entities, phonics }) => {
  const alphabet = orderBy(alphabetEn, 'titleEn')
  const phonicsHash = keyBy(phonics, ({ slug }) => slideKey(slug))
  const lettersHash = keyBy(alphabetEn, ({ slug }) => slideKey(slug))
  const slides = uniq(entities.length
    ? compact(entities.map(({ slug }) => slideKey(slug)))
    : orderBy(compact(alphabet.map(({ slug }) => slideKey(slug)))))
  return (
    <Slides>
      {slides.filter(slide => phonicsHash[slide]).map(slide => (
        <LetterSlide
          key={slide}
          letter={lettersHash[slide]}
          phonics={phonicsHash[slide]}
          label={phonicsHash[slide].titleEn}
        />
      ))}
    </Slides>
  )
}

PhonicsSample.propTypes = propTypes
PhonicsSample.defaultProps = defaultProps

export default PhonicsSample
