import React from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/Sorting/reducer'
import SortingWordsLesson from 'src/modules/Sorting/SortingWordsLesson/SortingWordsLesson'

const propTypes = {
  ...gamePropTypes,
}

const defaultProps = {
  completed: null,
}

class SortingWordsLessonContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const { mark, retry, completed, actualEntities,
      displayableCardsId, cards, entitiesById, moveCard, places } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <SortingWordsLesson {...{
          cards,
          displayableCardsId,
          entitiesById,
          moveCard,
          places,
        }}
        />
      </Game>
    )
  }
}

SortingWordsLessonContainer.propTypes = propTypes
SortingWordsLessonContainer.defaultProps = defaultProps
SortingWordsLessonContainer.demoData = {
  entities: [
    'collection-fruits',
    'banana',
    'mango',
    'orange',
    'pear',
    'pineapple',
    'collection-vegetables',
    'apple',
    'cabbage',
    'carrot',
    'lettuce',
    'radish',
  ],
}

const selector = state => state.sorting
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { SortingWordsLessonContainer }
export default connect(selector, actions)(SortingWordsLessonContainer)
