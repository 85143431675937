import React from 'react'
import { useHistory } from 'react-router'
import { Mutation } from '@apollo/react-components'
import { t } from 'i18next'

import userCreateMutation from 'src/pages/User/New/userCreateMutation.gql'
import { successMessage } from 'src/lib/toaster'
import UserNew from 'src/pages/User/New/Scene'
import parseErrors from 'src/lib/form/parseErrors'
import userPath from 'src/lib/routes/user'
import usePath from 'src/lib/routes/usePath'

const showSuccessMessage = () => {
  const title = t('user.user')
  const message = t('save.success')
  successMessage({ message, title })
}

export const UserNewContainer = () => {
  const history = useHistory()
  const path = usePath(userPath)
  return (
    <Mutation
      mutation={userCreateMutation}
      onCompleted={({ userCreate: { slug: userId } }) => {
        showSuccessMessage()
        history.push(path({ userId }))
      }}
    >
      {(userCreate) => (
        <UserNew
          onSubmit={(values, { setSubmitting, setErrors }) => {
            userCreate({ variables: values }).catch((errors) =>
              setErrors(parseErrors(errors))
            )
            setSubmitting(false)
          }}
        />
      )}
    </Mutation>
  )
}

export default UserNewContainer
