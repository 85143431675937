import React from 'react'
import { shape, arrayOf } from 'prop-types'
import { t } from 'i18next'
import Div from 'src/components/Div'
import indicatorShape from 'src/shapes/indicator'
import lessonShape from 'src/shapes/lesson'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import unitShape from 'src/shapes/unit'
import subjectCode from 'src/lib/subjectCodeGenerator'

const propTypes = {
  indicators: arrayOf(shape(indicatorShape)).isRequired,
  lesson: shape(lessonShape).isRequired,
  unit: shape(unitShape).isRequired,
}

const uniqById = array => uniqBy(array, 'id')
const mapUniqById = (array, selector) => uniqBy(map(array, selector), 'id')

const destructure = (lesson) => {
  const indicators = uniqById(lesson.indicators)
  const standards = mapUniqById(indicators, 'standard')
  const strands = mapUniqById(standards, 'strand')
  const subjects = mapUniqById(strands, 'subject')
  return ({
    indicators,
    lesson,
    standards,
    strands,
    subjects,
  })
}

const AcademicCover = ({ lesson, indicators, unit }) => {
  const destructured = destructure(lesson)
  const { lesson: theLesson } = destructured
  const { objectives, subject } = lesson
  const { grade, grade: { section } } = unit
  return (
    <Div
      bg-white
      clearfix
      mb2
      rounded
      mxn1
      style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
    >
      <Div flex row wrap mb2 flex-auto>
        <Div flex row mt2 pr1>
          <Div column>
            <span className="bold">{t('guide.subjectCode')}: </span>
          </Div>
          <Div column px1>
            {subjectCode({ subject, unit })} {subject.title}
          </Div>
        </Div>
        <Div flex row mt2 pr1>
          <Div column>
            <span className="bold">{t('guide.unit')}: </span>
          </Div>
          <Div column px1>
            {unit.identifier} {unit.title}
          </Div>
        </Div>
        <Div flex row mt2 pr1>
          <Div column>
            <span className="bold">{t('guide.lesson')}: </span>
          </Div>
          <Div column px1>
            {theLesson.identifier} {theLesson.title}
          </Div>
        </Div>
        <Div flex row mt2 pr1>
          <Div column>
            <span className="bold">{t('guide.level')}: </span>
          </Div>
          <Div align="left" px1>
            {section.title} {grade.code}
          </Div>
        </Div>
        <Div flex row mt2 pr1>
          <Div column>
            <span className="bold">{t('guide.time')}: </span>
          </Div>
          <Div align="left" px1>
            <span>1</span> {t('guide.hour')}
          </Div>
        </Div>
      </Div>
      <Div flex flex-auto column wrap mb2>
        <Div column>
          <span className="bold">{t('guide.indicators')}: </span>
        </Div>
        <Div col-10 flex wrap justify="start">
          {
            indicators.map(({ id, identifier, title }) => (
              <Div col-12 key={id}>
                <span key={id}><span className="bold">{identifier}</span> {title}</span>
              </Div>
            ))
          }
        </Div>
      </Div>
      <Div flex flex-auto column wrap mb2>
        <Div column>
          <span className="bold">{t('guide.learningObjectives')}: </span>
        </Div>
        <Div col-10 flex wrap justify="start">
          <ul className="pl1">
            {
              objectives.map(({ id, title }) => (
                <li key={id}>{title}</li>
              ))
            }
          </ul>
        </Div>
      </Div>
      <Div column mb2>
        <Div>
          <span className="bold">{t('guide.measurementAndEvaluation.title')} </span>
        </Div>
        <Div>
          <span>
            1. {t('guide.measurementAndEvaluation.info1')}
          </span>
        </Div>
        <Div>
          <span>
            2. {t('guide.measurementAndEvaluation.info2')}
          </span>
        </Div>
        <Div>
          <span>
            3. {t('guide.measurementAndEvaluation.info3')}
          </span>
        </Div>
      </Div>
    </Div>
  )
}

AcademicCover.propTypes = propTypes
export default AcademicCover
