import reject from 'lodash/reject'
import * as roles from 'src/constants/roles'
import { scene as calendarScene } from 'src/pages/CalendarPage/config'
import { scene as lessonsScene } from 'src/pages/LessonsLayout/config'
import { scene as protalScene } from 'src/pages/PortalLayout/config'

const parentItems = [
  protalScene,
  lessonsScene,
  calendarScene,
]

const parentSection = role => (
  (role === roles.PARENT_ROLE) ? parentItems : reject(parentItems, 'common')
)

export default parentSection
