import React from 'react'
import Access from 'src/components/Access'
import Menu from 'src/pages/TheLayout/Menu'
import { TEACHER_SCOPE } from 'src/constants/routeScopes'
import DomainContext from 'src/contexts/DomainContext'
import Div from 'src/components/Div'
import Announcement from 'src/pages/SchoolLayout/Announcement'

const TeacherLayout = ({ children }) => (
  <DomainContext.Provider value={TEACHER_SCOPE}>
    <Access teacher staff redirect>
      <Announcement today />
      <Div flex column auto p1>
        <Menu />
        {children}
      </Div>
    </Access>
  </DomainContext.Provider>
)

export default TeacherLayout
