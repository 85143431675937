const addExtraSpaceBetweenPunctuation = ({
  paragraphWithExtraSpace,
  punctuationList,
  space,
}, letter) => {
  if (space && letter !== ' ') {
    paragraphWithExtraSpace.push(' ')
    paragraphWithExtraSpace.push(letter)
    return {
      paragraphWithExtraSpace,
      punctuationList,
      space: null,
    }
  }
  if (punctuationList.indexOf(letter) > -1) {
    if (space !== false) {
      paragraphWithExtraSpace.push(' ')
    }
    paragraphWithExtraSpace.push(letter)
    return {
      paragraphWithExtraSpace,
      punctuationList,
      space: true,
    }
  }
  if (letter === ' ') {
    return {
      paragraphWithExtraSpace: paragraphWithExtraSpace.concat(letter),
      punctuationList,
      space: false,
    }
  }
  return {
    paragraphWithExtraSpace: paragraphWithExtraSpace.concat(letter),
    punctuationList,
    space: null,
  }
}

export default addExtraSpaceBetweenPunctuation
