import React from 'react'
import { arrayOf, shape, func, string, bool } from 'prop-types'
import Answer from 'src/components/Answer'
import WordBank from 'src/components/WordBank'
import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityBubble from 'src/components/EntityBubble'
import EntityCard from 'src/components/EntityCard'
import entityShape from 'src/shapes/entity'

const propTypes = {
  answer: string.isRequired,
  autoPlay: bool.isRequired,
  completed: bool,
  entities: arrayOf(shape(entityShape)).isRequired,
  handleChange: func.isRequired,
  question: shape(),
}

const defaultProps = {
  entities: [],
  question: {},
}

const ListenAndTypeWords = ({
  entities,
  question,
  handleChange,
  answer,
  completed,
  autoPlay,
  isSoftKeyboardShow,
}) => (
  <Deck className="disable-select">
    <Div key={question.id} flex auto gutter={1}>
      <Div col={6} flex px={1} mb={3}>
        {
          question.id && (
            <EntityBubble
              audio
              entity={question}
              image
              english
              autoPlay={autoPlay}
              label=" "
              keyToPlay=" "
            />
          )
        }
      </Div>
      <Div col={6} px={1} justify="center" flex column>
        <Div>
          {
            question.titleTh && (
              <EntityCard
                audio
                entity={question}
                thai
              />
            )
          }
        </Div>
        <WordBank entities={entities} />
      </Div>
    </Div>
    <Div flex justify="center" relative>
      <Div flex auto justify="center" relative answerSoftKeyBoard={isSoftKeyboardShow}>
        <Answer
          auto
          correct={completed}
          onChange={value => handleChange(value)}
          value={answer}
        />
      </Div>
    </Div>
  </Deck>
)

ListenAndTypeWords.propTypes = propTypes
ListenAndTypeWords.defaultProps = defaultProps

export default ListenAndTypeWords
