import React from 'react'
import EntityImage from 'src/components/EntityImage'

const Image = ({ entities }) => (
  <EntityImage entity={entities[0]} />
)

Image.demoData = {
  entities: ['family'],
}

export default Image
