import query from 'src/queries/lesson/Objectives.gql'
import gqlLoader from 'src/lib/gqlLoader'
import WellDone from 'src/modules/WellDone/WellDone'

const WellDoneContainer = gqlLoader(query)(WellDone)
WellDoneContainer.demoData = {
  lessonSlug: 'p3f1l1',
}

export default WellDoneContainer
