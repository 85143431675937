import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import pull from 'lodash/pull'

const numberOfWords = phrase => phrase.split(' ').length

const createSelectedPhonics = ([variant, ...targets]) => {
  const phonics = variant.phonicsEn || []
  const targetsTitles = targets.map((entity) => {
    const titles = entity.titleEn.toLowerCase().split(' ')
    return { modified: [...titles], original: [...titles] }
  })
  const selectedPhonics = []

  forEach(phonics, (phonic) => {
    const phonicTitle = phonic.title.toLowerCase()
    const targetTitles = find(targetsTitles, titles => includes(titles.modified, phonicTitle))
    if (!targetTitles) return
    pull(targetTitles.modified, phonicTitle)

    selectedPhonics.push({
      phonics: [phonic],
      target: targetTitles.original,
      title: phonicTitle,
    })
  })

  return selectedPhonics
}

const returnResultTargetAndPhonicsMoreThanOne = ({
  selectedPhonic,
  counter,
  newPhonics,
  selectedPhonics,
  target,
  mark,
  phonic,
}) => {
  if (selectedPhonic.phonics.length > 1 && (counter === 0)) {
    selectedPhonic.phonics.forEach((phonicTarget) => {
      newPhonics.push(phonicTarget)
    })
    return {
      counter: (counter + 1),
      mark: selectedPhonic.mark ? mark.concat(...selectedPhonic.target) : mark,
      newPhonics,
      selectedPhonics,
      target,
    }
  }
  if (selectedPhonic.phonics.length === 1 && mark.indexOf(phonic.title.toLowerCase()) === -1) {
    return {
      counter,
      mark: selectedPhonic.mark ? mark.concat(...selectedPhonic.target) : mark,
      newPhonics: newPhonics.concat(selectedPhonic.phonics[0]),
      selectedPhonics,
      target,
    }
  }
  return {
    counter,
    mark: selectedPhonic.mark ? mark.concat(...selectedPhonic.target) : mark,
    newPhonics,
    selectedPhonics,
    target,
  }
}

const returnResultTargetIsOne = ({
  selectedPhonic,
  newPhonics,
  selectedPhonics,
  counter,
  target,
  mark,
}) => {
  if (selectedPhonic.phonics.length > 1) {
    selectedPhonic.phonics.forEach((phonicTarget) => {
      newPhonics.push(phonicTarget)
    })
    return {
      counter,
      mark: selectedPhonic.mark ? mark.concat(...selectedPhonic.target) : mark,
      newPhonics,
      selectedPhonics,
      target,
    }
  }
  return {
    counter,
    mark: selectedPhonic.mark ? mark.concat(...selectedPhonic.target) : mark,
    newPhonics: newPhonics.concat(selectedPhonic.phonics[0]),
    selectedPhonics,
    target,
  }
}

const addPhonicSound = ({ mark, newPhonics, selectedPhonics, counter, target }, phonic) => {
  const selectedPhonic = find(selectedPhonics, { title: phonic.title.toLowerCase() })
  if (selectedPhonic) {
    if (selectedPhonic.target.length > 1) {
      return returnResultTargetAndPhonicsMoreThanOne({
        counter,
        mark,
        newPhonics,
        phonic,
        selectedPhonic,
        selectedPhonics,
        target,
      })
    }
    return returnResultTargetIsOne({
      counter,
      mark,
      newPhonics,
      selectedPhonic,
      selectedPhonics,
      target,
    })
  }
  return {
    counter,
    mark,
    newPhonics: newPhonics.concat(phonic),
    selectedPhonics,
    target,
  }
}

const updateResultTargetMoreThanOne = ({
  selectedPhonic,
  targetArray,
  phonic,
  newSelectedPhonics,
  target,
  src,
  id,
}) => {
  if (selectedPhonic.title.toLowerCase() === targetArray[0].toLowerCase()) {
    if (numberOfWords(phonic.titleEn) > 1) {
      return {
        newSelectedPhonics: newSelectedPhonics.concat({
          ...selectedPhonic,
          mark: true,
          phonics: phonic.phonicsEn,
        }),
        phonic,
        target,
      }
    }
    return {
      newSelectedPhonics: newSelectedPhonics.concat({
        ...selectedPhonic,
        mark: true,
        phonics: [{ ...selectedPhonic.phonics[0], id, src }],
      }),
      phonic,
      target,
    }
  }
  return {
    newSelectedPhonics: newSelectedPhonics.concat({
      ...selectedPhonic,
    }),
    phonic,
    target,
  }
}

const updateResultTargetisOne = ({
  selectedPhonic,
  target,
  phonic,
  newSelectedPhonics,
  src,
  id,
}) => {
  if (selectedPhonic.title.toLowerCase() === target.toLowerCase()) {
    if (numberOfWords(phonic.titleEn) > 1) {
      return {
        newSelectedPhonics: newSelectedPhonics.concat({
          ...selectedPhonic,
          phonics: phonic.phonicsEn,
        }),
        phonic,
        target,
      }
    }
    return {
      newSelectedPhonics: newSelectedPhonics.concat({
        ...selectedPhonic,
        phonics: [{ ...selectedPhonic.phonics[0], id, src }],
      }),
      phonic,
      target,
    }
  }
  return {
    newSelectedPhonics: newSelectedPhonics.concat({
      ...selectedPhonic,
    }),
    phonic,
    target,
  }
}

const updateSelectedPhonics = ({ newSelectedPhonics, phonic, target }, selectedPhonic) => {
  const { sound } = { ...phonic }
  const { srcEn: src, id } = { ...sound }
  const targetArray = target.split(' ')
  if (numberOfWords(target) > 1) {
    return updateResultTargetMoreThanOne({
      id,
      newSelectedPhonics,
      phonic,
      selectedPhonic,
      src,
      target,
      targetArray,
    })
  }
  return updateResultTargetisOne({
    id,
    newSelectedPhonics,
    phonic,
    selectedPhonic,
    src,
    target,
  })
}

export {
  addPhonicSound,
  createSelectedPhonics,
  updateSelectedPhonics,
}
