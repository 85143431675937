import React from 'react'
import { useHistory } from 'react-router'
import { arrayOf, shape } from 'prop-types'
import { t } from 'i18next'

import Breadcrumbs from 'src/components/Breadcrumbs'
import Widget from 'src/components/Widget'
import {
  progressReportPath,
  schoolOverviewReportPath,
} from 'src/lib/routes'

import OverviewChart from 'src/pages/SchoolOverview/shared/OverviewChart'
import academicYearShape from 'src/shapes/academicYear'
import gradeShape from 'src/shapes/grade'
import yearShape from 'src/shapes/year'
import { icon } from 'src/pages/SchoolOverview/config'
import StickyTable, { StickyTableHeader } from 'src/components/StickyTable'
import HomeworkPageTableRow from 'src/pages/SchoolOverview/shared/TableRow'
import usePath from 'src/lib/routes/usePath'
import { ATTAINMENT_TAB } from 'src/constants/progressTabs'

const propTypes = {
  academicYears: arrayOf(shape(academicYearShape)).isRequired,
  grade: shape(gradeShape).isRequired,
  years: arrayOf(shape(yearShape)).isRequired,
}

const defaultProps = {
  dataset: [],
}

const SchoolOverviewGradeScene = ({ academicYears, grade, years }) => {
  const history = useHistory()
  const schoolOverview = usePath(schoolOverviewReportPath)
  const progress = usePath(progressReportPath)
  return (
    <Widget
      icon={icon}
      title={(
        <Breadcrumbs
          crumbs={[
            {
              path: schoolOverview({ gradeId: null, sectionId: null }),
              title: t('school.overview'),
            },
            {
              path: schoolOverview({ gradeId: null, sectionId: grade.section.slug }),
              title: grade.section.title,
            },
          ]}
          currentTitle={grade.title}
        />
      )}
    >
      <OverviewChart
        academicYears={academicYears}
        dataset={years}
        onClick={entity => history.push(progress({ tab: ATTAINMENT_TAB, yearId: entity.slug }))}
      />
      <StickyTable>
        <StickyTableHeader>
          <th>{t('school.grade')}</th>
          <th>{t('homework.students')}</th>
          <th>{t('homework.grade')}</th>
          <th>{t('homework.completedRate')}</th>
          <th>{t('homework.assignedRate')}</th>
        </StickyTableHeader>
        <tbody>
          {
            years.map(year => (
              <HomeworkPageTableRow
                key={year.id}
                resource={year}
                linkTo={progress({ tab: ATTAINMENT_TAB, yearId: year.slug })}
              />
            ))
          }
        </tbody>
      </StickyTable>
    </Widget>
  )
}

SchoolOverviewGradeScene.propTypes = propTypes
SchoolOverviewGradeScene.defaultProps = defaultProps

export default SchoolOverviewGradeScene
