import React from 'react'
import usePageParams from 'src/lib/usePageParams'
import Access from 'src/components/Access'
import AssessmentList from 'src/pages/TeacherLayout/AssessmentPage/AssessmentList'

const AssessmentPage = () => {
  const params = usePageParams()
  const { marked } = params
  return (
    <Access redirect teacher staff>
      <AssessmentList {...{ ...params, marked: marked === 'marked' }} />
    </Access>
  )
}

export default AssessmentPage
