import React from 'react'
import { arrayOf, func, objectOf, shape, string } from 'prop-types'

import Deck from 'src/components/Layout/Deck'
import Div from 'src/components/Div'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import map from 'lodash/map'
import pick from 'lodash/pick'
import entityShape from 'src/shapes/entity'

const propTypes = {
  cards: shape().isRequired,
  displayableCardsId: arrayOf(string).isRequired,
  entitiesById: objectOf(shape(entityShape)).isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const rpPropTypes = {
  cards: shape().isRequired,
  entitiesById: objectOf(shape(entityShape)).isRequired,
  moveCard: func.isRequired,
  place: shape({ id: string }).isRequired,
}
const renderPlace = ({ cards, entitiesById, moveCard, place }) => {
  const { id } = place
  const entity = entitiesById[id]
  return (
    <Place
      id={id}
      key={id}
      onDrop={moveCard}
      className="justify-end align-center"
    >
      <Div flex column col={8} className="justify-center">
        <Div relative flex column auto>
          {map(cards)
            .filter((card) => card.placeId === id)
            .map((card) => (
              <Div key={card.id} flex wrap fadeOutAndDisappear={card.correct}>
                <EntityCard
                  audio
                  autoPlay
                  correct={card.correct}
                  draggable
                  english
                  entity={entitiesById[card.id]}
                  fit
                  id={card.id}
                  keyToPlay={card.index}
                  size={2}
                />
              </Div>
            ))}
          <Div flex auto column mbn1 pb3>
            <EntityCard image entity={entity} transparent />
          </Div>
          <Div absolute bottom-0 flex auto column col-12>
            <EntityCard bold fit english entity={entity} size={3} />
          </Div>
        </Div>
      </Div>
    </Place>
  )
}
renderPlace.propTypes = rpPropTypes

const SortingWordsLesson = ({
  displayableCardsId,
  entitiesById,
  cards,
  moveCard,
  places,
}) => (
  <Deck>
    <Div flex wrap mb={2}>
      {map(pick(entitiesById, displayableCardsId)).map((entity) => (
        <Div key={entity.id} column col={3} gutter-1>
          <EntityCard
            correct={entity.correct}
            draggable
            english
            entity={entity}
            fit
            size={2}
          />
        </Div>
      ))}
    </Div>
    <Div flex flex-1>
      {map(places, (place) =>
        renderPlace({ cards, entitiesById, moveCard, place })
      )}
    </Div>
  </Deck>
)
SortingWordsLesson.propTypes = propTypes
export default SortingWordsLesson
