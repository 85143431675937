import React from 'react'
import { bindActionCreators } from 'redux'
import { array, func } from 'prop-types'
import { connect } from 'react-redux'
import * as cardActions from 'src/modules/BarChart/reducer'
import BarChartContainer from 'src/modules/BarChart/BarChartContainer'

const propTypes = {
  entities: array.isRequired,
  initDefineCountWord: func.isRequired,
}

const BarChartDefineCountWord = props => (
  <BarChartContainer
    init={() => props.initDefineCountWord({ entities: props.entities })}
    {...props}
  />
)

const select = ({ barChart }) => barChart
const actions = dispatch => bindActionCreators(cardActions, dispatch)

BarChartDefineCountWord.propTypes = propTypes
BarChartDefineCountWord.demoData = {
  entities: [
    'paragraph-ice-cream-with-the-family',
    'eat',
    'two',
    'sleep',
    'three',
    'run',
    'five',
  ],
}

export { BarChartDefineCountWord }
export default connect(select, actions)(BarChartDefineCountWord)
