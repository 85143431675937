import React from 'react'
import { node, oneOfType, number, string } from 'prop-types'
import Deck from 'src/components/Layout/Deck'

const propTypes = {
  children: node.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  label: oneOfType([number, string]),
}
const defaultProps = {
  label: null,
}

const Slide = ({ children }) => (
  <Deck>
    {children}
  </Deck>
)

Slide.propTypes = propTypes
Slide.defaultProps = defaultProps

export default Slide
