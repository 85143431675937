import React from 'react'
import { string } from 'prop-types'
import Div from 'src/components/Div'
import Img from 'src/components/Img'

const Description = ({ image, meaning }) => (
  <Div flex auto>
    {
      image && (
        <Div flex col={6}>
          <Div flex auto>
            <Img contain src={image} position="center" />
          </Div>
        </Div>
      )
    }
    <Div flex align-center={image} px2 h2={!image} h3={image} col={image ? 6 : 12}>
      {meaning}
    </Div>
  </Div>
)

Description.propTypes = {
  image: string,
  meaning: string,
}

Description.defaultProps = {
  image: undefined,
  meaning: '',
}

export default Description
