import ComicStripDragAndDrop, {
  ComicStripDragAndDropCursiveTextContainer as ComicStripDragAndDropCursiveText,
} from 'src/modules/ComicStripDragAndDrop/ComicStripDragAndDropContainer'
import reducer from 'src/modules/ComicStripDragAndDrop/reducer'

export {
  reducer,
  ComicStripDragAndDropCursiveText,
}

export default ComicStripDragAndDrop
