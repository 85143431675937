import React from 'react'
import { useParams } from 'react-router'
import { week as weekOfYear } from 'src/lib/dates'
import Div from 'src/components/Div'
import AttendanceWeekly from 'src/pages/AttendancePage/AttendanceWeekly'
import AttendanceToday from 'src/pages/AttendancePage/AttendanceToday'
import Nav from 'src/pages/AttendancePage/Nav'

const withLayout = Component => () => (
  <Div>
    <Nav />
    <Component />
  </Div>
)

const TodayAttendance = withLayout(() => {
  const { domain } = useParams()
  return (
    <AttendanceToday domain={domain} />
  )
})

const WeeklyAttendance = withLayout(() => {
  const { week: weekParam, year: yearParam } = useParams()
  const week = parseInt(weekParam, 10) || weekOfYear(new Date())
  const year = parseInt(yearParam, 10) || (new Date()).getFullYear()
  return <AttendanceWeekly {...{ week, year }} />
})


export {
  TodayAttendance,
  WeeklyAttendance,
}
