import React from 'react'
import Div from 'src/components/Div'
import Icon from 'src/components/Icon'
import learn21Image from 'src/images/learn21-transparent.svg'

const Footer = () => {
  const native = window.Capacitor && window.Capacitor.isNative
  return (
    !native && (
      <Div
        absolute
        auto
        bg="blue"
        bottom-0
        col-12
        flex
        py4
        px2
        style={{ transform: 'translateY(100%)' }}
        white
        noPrint
        safe-area
      >
        <Div flex auto container py2 px2>
          <Div sm-flex auto justify-around mxn2>
            <Div col-6 sm-col-4 px3>
              <img src={learn21Image} />
            </Div>
            <Div flex column sm-col-8 px3>
              80/4 Moo5, Soi Pun Thao Kong
              <br /> Wichit, Mueang Phuket, 83000, Thailand
              <br /> +66-76-600-233
              <br />
              <Div flex mt1>
                <a href="https://www.youtube.com/channel/UCu-z1RPCmwZGEm1MTTL4NWA">
                  <Icon icon="youtube" size={4} className="fab white" />
                </a>
                <a href="https://fb.me/learn21.school">
                  <Icon icon="facebook-f" size={6} className="fab pr1 white" />
                </a>
                <a className="white" href="mailto:info@learn21.school">
                  info@learn21.school
                </a>
              </Div>
              <Div mt1>
                <small className="muted">
                  <a className="mr1 white" href="https://learn21.school/terms">
                    Terms and Conditions
                  </a>{' '}
                  |
                  <a
                    className="ml1 white"
                    href="https://learn21.school/privacy"
                  >
                    Privacy policy
                  </a>
                </small>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    )
  )
}

export default Footer
