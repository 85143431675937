import React from 'react'
import { bool, func, object, string } from 'prop-types'
import map from 'lodash/map'
import Div from 'src/components/Div'
import Deck from 'src/components/Layout/Deck'
import Card from 'src/components/Card'
import EntityBubble from 'src/components/EntityBubble'

const propTypes = {
  autoPlay: bool,
  cards: object,
  entitiesById: object,
  instructionId: string,
  onClick: func.isRequired,
  phraseId: string,
}

const defaultProps = {
  autoPlay: true,
  cards: {},
  entitiesById: {},
  instructionId: '',
  phraseId: '',
}

const PartsOfSpeech = ({ entitiesById, onClick, autoPlay, instructionId, phraseId, cards }) => {
  const phraseEntity = entitiesById[phraseId]
  const soundObject = { ...phraseEntity }.sound
  const imageObject = { ...phraseEntity }.image
  const imageSrc = { ...imageObject }.src
  const srcSound = { ...soundObject }.src
  const title = { ...entitiesById[instructionId] }.titleEn
  return (
    <Deck>
      <Div flex justify-center mb4>
        {
          imageSrc && (
            <Div flex col={3} mr2>
              <EntityBubble
                key={phraseEntity.id}
                english
                entity={phraseEntity}
                label=" "
                image
                audio
                autoPlay={autoPlay}
              />
            </Div>
          )
        }
        <Div flex auto>
          <Card
            audio
            autoPlay={autoPlay}
            title={title}
            label=" "
            src={srcSound}
            keyToPlay=" "
            minHeight={false}
            size={1}
          />
        </Div>
      </Div>
      <Div flex wrap>
        {
          map(cards, ({ id, selected, correct, src, title }) => (
            <Div flex px key={id} column>
              <Card
                english
                silent
                id={id}
                title={title}
                selected={selected}
                correct={correct}
                onClick={() => onClick({ id })}
                src={src}
                fit
                size={0}
              />
            </Div>
          ))
        }
      </Div>
    </Deck>
  )
}

PartsOfSpeech.propTypes = propTypes
PartsOfSpeech.defaultProps = defaultProps

export default PartsOfSpeech
