import React, { Component } from 'react'
import { func } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/PartsOfSpeech/reducer'
import PartsOfSpeech from 'src/modules/PartsOfSpeech/PartsOfSpeech'

const propTypes = {
  ...gamePropTypes,
  onClick: func.isRequired,
}

class PartsOfSpeechContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      completed,
      entitiesById,
      instructionId,
      cards,
      mark,
      onClick,
      retry,
      started,
      phraseId,
    } = this.props
    return (
      <Game {...{
        actualEntities,
        completed,
        init: () => this.init(),
        mark,
        retry,
      }}
      >
        <PartsOfSpeech
          autoPlay={started}
          entitiesById={entitiesById}
          instructionId={instructionId}
          onClick={onClick}
          cards={cards}
          phraseId={phraseId}
        />
      </Game>
    )
  }
}

PartsOfSpeechContainer.propTypes = propTypes
PartsOfSpeechContainer.demoData = {
  entities: [
    'phrase-choose-the-adjective',
    'phrase-last-monday-i-ate-mangoes',
    'monday',
    'mangoes',
    'phrase-last-sunday-i-ate-lettuces',
    'sunday',
    'lettuces',
    'phrase-she-is-a-doctor-she-works-in-a-hospital',
    'doctor',
    'works',
    'hospital',
    'phrase-this-is-my-family',
    'family',
  ],
}

const select = state => state.partsOfSpeech
const actions = dispatch => bindActionCreators(gameActions, dispatch)
export { PartsOfSpeechContainer }
export default connect(select, actions)(PartsOfSpeechContainer)
