import React from 'react'
import { number, func, array, string, oneOfType } from 'prop-types'
import max from 'lodash/max'
import Div from 'src/components/Div'

import Card from 'src/components/Card'
import flatMap from 'lodash/flatMap'
import KeyPress from 'src/components/KeyPress'

const propTypes = {
  keyToPlay: oneOfType([string, number]),
  phonics: array.isRequired,
  player: oneOfType([string, number]),
  playing: oneOfType([string, number]),
  playNext: func.isRequired,
  playPhonics: func.isRequired,
  stopPlay: func.isRequired,
}

const defaultProps = {
  keyToPlay: '2',
  player: null,
  playing: null,
}

const separator = ({ i, title }) => (
  !!i && (
  <div key={`_${i}`} className="h1 muted">
    {title[0] === ' ' ? <span className="pr2" /> : '/'}
  </div>
  ))

const Phonics = ({
  keyToPlay,
  player,
  playing,
  playNext,
  playPhonics,
  phonics,
  stopPlay,
}) => (
  <Div
    flex
    justify="center"
    onClick={playPhonics}
    className="cursor-pointer"
    auto
  >
    <KeyPress keys={{ [keyToPlay]: playPhonics }} />
    {flatMap(phonics, ({ id, title, src }, i) => [
      separator({ i, title }),
      <Div
        key={`${id}-${i}`}
        style={{ flex: `${max(title.length)}` }}
        flex
        justify-center
        auto
      >
        <Card
          auto
          autoPlay={playing === i && player === keyToPlay}
          playNext={() => playNext(player)}
          stopPlay={() => stopPlay()}
          silent
          title={title}
          src={src}
          className="h1"
          disableSound
        />
      </Div>,
    ])}
  </Div>
)

Phonics.propTypes = propTypes
Phonics.defaultProps = defaultProps
export default Phonics
