import React from 'react'

import Div from 'src/components/Div'

const NetworkError = () => (
  <Div flex column vh100 white p2 center bg="blue">
    <Div h3 my2>
      Learn21 app depends on the Internet connection to work.
      <br />
      Please make sure that the Internet is available.
    </Div>
  </Div>
)

export default NetworkError
