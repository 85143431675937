import { shape, string } from 'prop-types'
import lessonShape from 'src/shapes/lesson'
import localizedField from 'src/shapes/localizedField'
import gradeShape from 'src/shapes/grade'

const objectiveShape = {
  grade: shape(gradeShape),
  id: string,
  lesson: shape(lessonShape),
  title: string,
  titleRaw: shape(localizedField),
}

export default objectiveShape
