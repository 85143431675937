import chunk from 'lodash/chunk'
import keyBy from 'lodash/keyBy'
import uniqShuffle from 'src/lib/uniqShuffle'
import normalizeString from 'src/lib/normalizeString'

const initialState = {
  answer: null,
  completed: null,
  correctAnswer: null,
  entitiesById: {},
  phrase: null,
  question: null,
}

const INIT = 'listenAndSpeak/INIT'
const ANSWER = 'listenAndSpeak/ANSWER'
const RETRY = 'listenAndSpeak/RETRY'

const init = ({ entities, fixed }) => ({ type: INIT, payload: { entities, fixed } })
const answer = answer => ({ type: ANSWER, payload: { answer } })
const retry = () => ({ type: RETRY })

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT: {
      const { entities, fixed } = payload
      const [phrase, question, correctAnswer] = fixed
        ? [entities[0], ...uniqShuffle(chunk(entities.slice(1), 2))[0]]
        : uniqShuffle(chunk(entities, 3))[0]
      return {
        ...initialState,
        entitiesById: keyBy(entities, 'id'),
        phrase: phrase.id,
        question: question.id,
        correctAnswer: correctAnswer.id,
      }
    }

    case ANSWER: {
      const { answer } = payload
      const { correctAnswer, entitiesById } = state
      const correct = entitiesById[correctAnswer]
      const completed = !!answer.find(a => normalizeString(a).includes(normalizeString(correct.titleEn)))
      return {
        ...state,
        answer,
        completed,
      }
    }

    case RETRY: {
      return {
        ...state,
        answer: null,
        completed: null,
      }
    }

    default: {
      return state
    }
  }
}

export {
  init,
  answer,
  retry,
}

export default reducer
