import React from 'react'
import { Link } from 'react-router-dom'
import { arrayOf, shape } from 'prop-types'
import Div from 'src/components/Div'
import usePath from 'src/lib/routes/usePath'
import calendarPath from 'src/pages/CalendarPage/paths'
import eventTitleFallBack from 'src/lib/eventTitleFallBack'
import { calendarEventShape } from 'src/shapes/calendar'
import { dateToday, formatDayShortNumeric, formatMonthMedium } from 'src/lib/dates'
import AnnouncementBar from 'src/components/AnnouncementBar'

const propTypes = {
  calendarEvents: arrayOf(shape(calendarEventShape)).isRequired,
}

const Announcement = ({ calendarEvents, ...rest }) => {
  const path = usePath(calendarPath)
  if (!calendarEvents.length) return null
  return (
    <AnnouncementBar {...rest}>
      <Div flex align="center">
        <Div flex auto justify="center" className="AnnouncementItems">
          {calendarEvents.map(({ id, titleEn, titleTh }) => {
            const date = dateToday()
            return (
              <Link className="white" to={path({ date })} key={id}>
                {`${formatDayShortNumeric(date)} ${formatMonthMedium(date)}`}
                {' - '}
                {eventTitleFallBack({ titleEn, titleTh })}
              </Link>
            )
          })}
        </Div>
      </Div>
    </AnnouncementBar>
  )
}

Announcement.propTypes = propTypes

export default Announcement
