import React from 'react'
import query from 'src/queries/lesson/HomeworkWrapUp.gql'
import gqlLoader from 'src/lib/gqlLoader'
import { CurrentUser, NoCurrentUser } from 'src/pages/CurrentUser'
import HomeworkDone from 'src/modules/HomeworkDone/HomeworkDone'

const HomeworkDoneContainer = (props) => {
  const HomeworkDoneComponent = props.homework
    ? gqlLoader(query)(HomeworkDone)
    : HomeworkDone

  return [
    <NoCurrentUser key="user-not-logged-in">
      <HomeworkDone {...props} />
    </NoCurrentUser>,
    <CurrentUser key="user-logged-in">
      <HomeworkDoneComponent {...props} />
    </CurrentUser>,
  ]
}

HomeworkDoneContainer.demoData = {
  lessonSlug: 'p3f1l1',
}

export default HomeworkDoneContainer
