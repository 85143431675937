import React from 'react'
import { any, bool } from 'prop-types'
import classnames from 'classnames'

const propTypes = {
  children: any.isRequired,
  left: bool,
  right: bool,
}

const defaultProps = {
  left: null,
  right: null,
}

const DropdownMenu = ({ children, left, right }) => (
  <div
    className={classnames('hidden absolute', {
      'left-0': left || !right,
      'right-0': !left && right,
    })}
    style={{ marginTop: -4, minWidth: '100%', top: '100%' }}
  >
    {children}
  </div>
)

DropdownMenu.propTypes = propTypes
DropdownMenu.defaultProps = defaultProps

export default DropdownMenu
