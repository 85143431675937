const findGradeWithYears = grade => grade.years.length
const findSectionWithGrades = section => section.grades.find(findGradeWithYears)

export default (sections) => {
  const { grades } = { ...sections.find(findSectionWithGrades) }
  if (grades) {
    return grades.find(findGradeWithYears).years[0].id
  }
  return null
}
