import React from 'react'
import usePageParams from 'src/lib/usePageParams'

import registrationCompleteMutation from 'src/pages/Registration/RegistrationComplete/registrationCompleteMutation.gql'
import RegistrationComplete from 'src/pages/Registration/RegistrationComplete/RegistrationComplete'
import { Mutation } from '@apollo/react-components'
import RedirectToHomePage from 'src/pages/Login/RedirectToHomePage'
import loginByToken from 'src/lib/loginByToken'

class CallMutation extends React.Component {
  componentDidMount() {
    this.props.mutation()
  }

  render() {
    return this.props.children
  }
}

const RegistrationCompleteContainer = () => {
  const { token: urlToken } = usePageParams()
  return (
    <Mutation
      mutation={registrationCompleteMutation}
      variables={{ token: urlToken }}
      onCompleted={({ registrationComplete: { token } }) => {
        loginByToken(token)
      }}
    >
      {
        (mutate, { loading, error }) => (
          <CallMutation mutation={mutate}>
            <RegistrationComplete spin={loading} error={error} />
            <RedirectToHomePage />
          </CallMutation>
        )
      }
    </Mutation>
  )
}

export default RegistrationCompleteContainer
