import React, { Component } from 'react'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as gameActions from 'src/modules/AgeComparison/reducer'
import AgeComparison from 'src/modules/AgeComparison/AgeComparison'

const propTypes = {
  ...gamePropTypes,
}

class AgeComparisonContainer extends Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const { mark, retry, completed, availableCardsId, cards, entitiesById,
      moveCard, places, actualEntities } = this.props
    return (
      <Game
        {...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <AgeComparison
          {...{ availableCardsId, cards, entitiesById, moveCard, places }}
        />
      </Game>
    )
  }
}

AgeComparisonContainer.propTypes = propTypes
AgeComparisonContainer.demoData = {
  entities: [
    'younger-sister',
    'younger-brother',
    'elder-brother',
    'elder-sister',
  ],
}
const select = state => state.ageComparison
const actions = dispatch => bindActionCreators(gameActions, dispatch)

export { AgeComparisonContainer }
export default connect(select, actions)(AgeComparisonContainer)
