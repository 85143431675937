import React from 'react'

import Icon from 'src/components/Icon'

const SubmitButton = ({ withConfirm }) => (
  <button
    onClick={withConfirm}
    className="bg-blue white p2 px3 cursor-pointer button-hover round bold h2"
  >
    <Icon icon="check" before />
    Submit
  </button>
)

export default SubmitButton
