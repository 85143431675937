import React from 'react'
import { GameContext } from 'src/lib/contexts'
import Game from 'src/components/Game/Game'

const GameContainer = props => (
  <GameContext.Consumer>
    {
      ({
        chapterId,
        disableMarkButton,
        enableMarkButton,
        homework,
        instructions,
        moduleId,
        onSubscribe,
        showMarkButton,
        started,
        startGame,
      }) => (
        <Game
          {...{
            ...props,
            chapterId,
            disableMarkButton,
            enableMarkButton,
            homework,
            instructions,
            moduleId,
            onSubscribe,
            showMarkButton,
            startGame,
            started,
          }}
        />
      )
    }
  </GameContext.Consumer>
)

export default GameContainer
