import React from 'react'
import { Link } from 'react-router-dom'
import { any, string } from 'prop-types'
import DomainContext from 'src/contexts/DomainContext'

const propTypes = {
  children: any,
  search: string,
  to: string,
}

const defaultProps = {
  children: undefined,
  search: undefined,
  to: undefined,
}

const PrefixedLink = ({ to, children, search, ...rest }) => (
  <DomainContext.Consumer>
    {
      linkPrefix => (
        linkPrefix ? (
          <Link to={{ pathname: `/${linkPrefix + to}`, search }} {...rest}>
            {children}
          </Link>
        ) : (
          <Link to={{ pathname: to, search }} {...rest}>
            {children}
          </Link>
        ))
    }
  </DomainContext.Consumer>
)

PrefixedLink.propTypes = propTypes
PrefixedLink.defaultProps = defaultProps

export default PrefixedLink
