import React from 'react'
import EntityCard from 'src/components/EntityCard'
import Place from 'src/components/Place'
import Div from 'src/components/Div'

const Line = ({
  id,
  child,
  correct,
  entity,
  moveCard,
}) => (
  <Div flex>
    <Div col={9}>
      <EntityCard
        entity={entity}
        size={2}
        english
        audio
      />
    </Div>
    <Div col={3}>
      <Place
        id={id}
        onDrop={moveCard}
      >
        <EntityCard
          correct={correct}
          draggable
          english
          entity={child}
          image
          key={child.id}
          silent
          size={2}
        />
      </Place>
    </Div>
  </Div>
)

export default Line
