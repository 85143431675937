import query from 'src/queries/lesson/CurriculumMapping.gql'
import gqlLoader from 'src/lib/gqlLoader'
import CurriculumMapping from 'src/modules/CurriculumMapping/CurriculumMapping'

const CurriculumMappingContainer = gqlLoader(query)(CurriculumMapping)

CurriculumMappingContainer.demoData = {
  lessonSlug: 'p3f1l3',
}

export default CurriculumMappingContainer
