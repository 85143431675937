import { useContext } from 'react'
import { useParams, useLocation } from 'react-router'
import qs from 'query-string'
import DomainContext from 'src/contexts/DomainContext'

const usePageParams = () => {
  const params = useParams()
  const location = useLocation()
  const domain = useContext(DomainContext)
  return { domain, ...qs.parse(location.search), ...params }
}

export default usePageParams
