import WebkitSpeechRecognition from 'src/lib/SpeechRecognition/WebkitSpeechRecognition'
import NativeSpeechRecognition from 'src/lib/SpeechRecognition/NativeSpeechRecognition'
import FakeSpeechRecognition from 'src/lib/SpeechRecognition/FakeSpeechRecognition'

const getSpeechRecognition = () => (
  (window.Capacitor && window.Capacitor.isNative) && NativeSpeechRecognition
  || (window.webkitSpeechRecognition || window.SpeechRecognition) && WebkitSpeechRecognition
  || FakeSpeechRecognition
)

export default getSpeechRecognition
