import React, { useContext } from 'react'
import { array, func, object, oneOfType } from 'prop-types'
import Div from 'src/components/Div'
import UserForm from 'src/pages/User/shared/Form'
import { path as usersPath } from 'src/pages/Users/config'
import { linkTo } from 'src/lib/linkTo'
import DomainContext from 'src/contexts/DomainContext'
import { STUDENT_ROLE } from 'src/constants/roles'

const propTypes = {
  errors: oneOfType([array, object]),
  onSubmit: func.isRequired,
}

const defaultProps = {
  errors: [],
}

const UserNew = ({ errors, onSubmit }) => {
  const domain = useContext(DomainContext)
  return (
    <Div py={2} px={3} bg="white" rounded className="qa-new-user-form">
      <UserForm
        errors={errors}
        onSubmit={onSubmit}
        cancelPath={linkTo(usersPath)({ domain })}
        initialValues={{ roleId: STUDENT_ROLE }}
      />
    </Div>
  )
}

UserNew.propTypes = propTypes
UserNew.defaultProps = defaultProps

export default UserNew
