import React from 'react'
import { bool, string, shape } from 'prop-types'
import userShape from 'src/shapes/user'
import Div from 'src/components/Div'
import Bubble from 'src/components/Bubble'
import UserImageLink from 'src/components/UserImageLink'

const propTypes = {
  left: bool,
  user: shape(userShape).isRequired,
  year: string.isRequired,
}

const defaultProps = {
  left: null,
}

const StudentCard = ({
  year,
  left,
  user,
}) => {
  const { identifier, name } = user
  const style = left ? { right: '100%' } : { left: '100%' }
  return (
    <Div absolute z2 style={style}>
      <Bubble>
        <Div column>
          <Div mb2>
            <UserImageLink user={user} />
          </Div>
          <Div column>
            <Div h4 bold>
              {name}
            </Div>
            <Div flex>
              <Div h4 col={6}>
                {identifier}
              </Div>
              <Div h4 col={6}>
                {year}
              </Div>
            </Div>
          </Div>
        </Div>
      </Bubble>
    </Div>
  )
}

StudentCard.propTypes = propTypes
StudentCard.defaultProps = defaultProps

export default StudentCard
