import React from 'react'
import { array, func, object } from 'prop-types'
import { connect, bindActionCreators } from 'src/lib/redux'
import Game from 'src/components/Game'
import gamePropTypes from 'src/shapes/Game'
import * as allActions from 'src/modules/MatchTextWithText/reducer'
import MatchTextWithText from 'src/modules/MatchTextWithText/MatchTextWithText'

const propTypes = {
  ...gamePropTypes,
  chunkPlaces: array.isRequired,
  moveCard: func.isRequired,
  places: object.isRequired,
  types: array,
}

class MatchTextWithTextContainer extends React.Component {
  componentDidMount() {
    this.init()
  }

  init() {
    this.props.init(this.props)
  }

  render() {
    const {
      actualEntities,
      chunkPlaces,
      completed,
      entitiesById,
      mark,
      moveCard,
      places,
      retry,
      types,
    } = this.props
    return (
      <Game {
        ...{
          actualEntities,
          completed,
          init: () => this.init(),
          mark,
          retry,
        }}
      >
        <MatchTextWithText
          chunkPlaces={chunkPlaces}
          entitiesById={entitiesById}
          moveCard={moveCard}
          places={places}
          types={types}
        />
      </Game>
    )
  }
}

MatchTextWithTextContainer.propTypes = propTypes
MatchTextWithTextContainer.demoData = {
  entities: [
    'collection-present-tense',
    'collection-past-tense',
    'collection-present-continuous-tense',
    'walk',
    'walked',
    'walking',
    'run',
    'ran',
    'running',
    'skate',
    'skated',
    'skating',
  ],
}

const selector = state => state.matchTextWithText
const actions = dispatch => bindActionCreators(allActions, dispatch)

export { MatchTextWithTextContainer }
export default connect(selector, actions)(MatchTextWithTextContainer)
