import React from 'react'
import { string, func } from 'prop-types'
import { t } from 'i18next'
import { ATTAINMENT_TAB, CURRICULUM_TAB, STUDENT_TAB } from 'src/constants/progressTabs'
import Icon from 'src/components/Icon'
import Tabs from 'src/components/Tabs'
import Tab from 'src/components/Tab'

const propTypes = {
  tab: string,
  tabPath: func.isRequired,
}

const defaultProps = {
  tab: CURRICULUM_TAB,
}

const Nav = ({
  tab,
  tabPath,
}) => (
  <Tabs mb1>
    <Tab
      className="qa-curriculum-progress"
      selected={tab === CURRICULUM_TAB}
      path={tabPath(CURRICULUM_TAB)}
    >
      <Icon icon="chart-area" />{' '}
      {t('school.curriculumProgress')}
    </Tab>
    <Tab
      className="qa-attainment-progress"
      selected={tab === ATTAINMENT_TAB}
      path={tabPath(ATTAINMENT_TAB)}
    >
      <Icon icon="chart-line" />{' '}
      {t('school.attainmentProgress')}
    </Tab>
    <Tab
      className="qa-student-progress"
      selected={tab === STUDENT_TAB}
      path={tabPath(STUDENT_TAB)}
    >
      <Icon icon="chart-line" />{' '}
      {t('school.studentProgress')}
    </Tab>
  </Tabs>
)

Nav.propTypes = propTypes
Nav.defaultProps = defaultProps

export default Nav
