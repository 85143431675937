import React from 'react'
import { t } from 'i18next'
import RegistrationDone from 'src/pages/Registration/RegistrationDone'
import map from 'lodash/map'

const RegistrationComplete = ({ spin, error }) => {
  const icon = error ? 'exclamation-triangle' : 'spinner'
  const text = error ? `**${t('shared.error')}**\n\n${map(error.graphQLErrors, 'message').join('\n\n')}` : t('registration.complete')
  return (
    <RegistrationDone icon={icon} spin={spin}>
      {text}
    </RegistrationDone>
  )
}

export default RegistrationComplete
