import React from 'react'
import { array, bool, func, object, shape } from 'prop-types'
import EntityCard from 'src/components/EntityCard'
import Div from 'src/components/Div'
import Words from 'src/modules/ListenAndOrderSentences/Words'

const propTypes = {
  autoPlay: bool.isRequired,
  cards: shape().isRequired,
  entity: object.isRequired,
  line: array.isRequired,
  moveCard: func.isRequired,
  places: shape().isRequired,
}

const Line = ({
  autoPlay,
  cards,
  entity,
  line,
  moveCard,
  places,
}) => (
  <Div flex auto column>
    <Div flex flex-1>
      <EntityCard image audio autoPlay={autoPlay} english entity={entity} />
    </Div>
    <Words line={line} moveCard={moveCard} places={places} cards={cards} />
  </Div>
)

Line.propTypes = propTypes

export default Line
