/* eslint-disable max-len */
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import generateEntities from 'src/spec/generateEntities'

import alphabetEn from 'src/spec/demoData/alphabetEn.json'
import alphabetCursiveEn from 'src/spec/demoData/alphabetCursiveEn.json'
import familyFaces from 'src/spec/demoData/familyFaces.json'
import letterFormationVideos from 'src/spec/demoData/letterFormationVideos.json'
import homeworkWrapUp from 'src/spec/demoData/homeworkWrapUp.json'
import objectives from 'src/spec/demoData/objetives.json'
import phonics from 'src/spec/demoData/phonics.json'
import phonicsAlphabet from 'src/spec/demoData/phonicsAlphabet.json'
import curriculumMapping from 'src/spec/demoData/curriculumMapping.json'
import video from 'src/spec/demoData/video.json'
import instructions from 'src/spec/demoData/instructions.json'

import accident from 'src/spec/demoData/entities/accident.json'
import aerobics from 'src/spec/demoData/entities/aerobics.json'
import airFlow from 'src/spec/demoData/entities/air-flow.json'
import am from 'src/spec/demoData/entities/am.json'
import are from 'src/spec/demoData/entities/are.json'
import apple from 'src/spec/demoData/entities/apple.json'
import banana from 'src/spec/demoData/entities/banana.json'
import bathroom from 'src/spec/demoData/entities/bathroom.json'
import bedroom from 'src/spec/demoData/entities/bedroom.json'
import bicycle from 'src/spec/demoData/entities/bicycle.json'
import bird from 'src/spec/demoData/entities/bird.json'
import brother from 'src/spec/demoData/entities/brother.json'
import cabbage from 'src/spec/demoData/entities/cabbage.json'
import calcium from 'src/spec/demoData/entities/calcium.json'
import car from 'src/spec/demoData/entities/car.json'
import carrot from 'src/spec/demoData/entities/carrot.json'
import chapel from 'src/spec/demoData/entities/chapel.json'
import cherry from 'src/spec/demoData/entities/cherry.json'
import chooseAnimal from 'src/spec/demoData/entities/choose-animal.json'
import clap from 'src/spec/demoData/entities/clap.json'
import clock from 'src/spec/demoData/entities/clock.json'
import coldAir from 'src/spec/demoData/entities/cold-air.json'
import collectionCorrect from 'src/spec/demoData/entities/collection-correct.json'
import collectionFalse from 'src/spec/demoData/entities/collection-false.json'
import collectionFruits from 'src/spec/demoData/entities/collection-fruits.json'
import collectionIgh from 'src/spec/demoData/entities/collection-igh.json'
import collectionIncorrect from 'src/spec/demoData/entities/collection-incorrect.json'
import collectionNull from 'src/spec/demoData/entities/collection-null.json'
import collectionPastTense from 'src/spec/demoData/entities/collection-past-tense.json'
import collectionPresentContinuousTense from 'src/spec/demoData/entities/collection-present-continuous-tense.json'
import collectionPresentTense from 'src/spec/demoData/entities/collection-present-tense.json'
import collectionTrue from 'src/spec/demoData/entities/collection-true.json'
import collectionVegetables from 'src/spec/demoData/entities/collection-vegetables.json'
import collectionY from 'src/spec/demoData/entities/collection-y.json'
import coupon from 'src/spec/demoData/entities/coupon.json'
import cry from 'src/spec/demoData/entities/cry.json'
import dad from 'src/spec/demoData/entities/dad.json'
import delicious from 'src/spec/demoData/entities/delicious.json'
import desert from 'src/spec/demoData/entities/desert.json'
import diningRoom from 'src/spec/demoData/entities/dining-room.json'
import does from 'src/spec/demoData/entities/does.json'
import doctor from 'src/spec/demoData/entities/doctor.json'
import dor from 'src/spec/demoData/entities/dor.json'
import door from 'src/spec/demoData/entities/door.json'
import doorr from 'src/spec/demoData/entities/doorr.json'
import dour from 'src/spec/demoData/entities/dour.json'
import eat from 'src/spec/demoData/entities/eat.json'
import eight from 'src/spec/demoData/entities/eight.json'
import elderBrother from 'src/spec/demoData/entities/elder-brother.json'
import elderBrotherThisIsMy from 'src/spec/demoData/entities/elder-brother-this-is-my.json'
import elderSister from 'src/spec/demoData/entities/elder-sister.json'
import eraser from 'src/spec/demoData/entities/eraser.json'
import faceElderBrother from 'src/spec/demoData/entities/face-elder-brother.json'
import faceElderSister from 'src/spec/demoData/entities/face-elder-sister.json'
import faceFather from 'src/spec/demoData/entities/face-father.json'
import faceGrandfatherFather from 'src/spec/demoData/entities/face-grandfather-father.json'
import faceGrandfatherMother from 'src/spec/demoData/entities/face-grandfather-mother.json'
import faceGrandmotherFather from 'src/spec/demoData/entities/face-grandmother-father.json'
import faceGrandmotherMother from 'src/spec/demoData/entities/face-grandmother-mother.json'
import faceMother from 'src/spec/demoData/entities/face-mother.json'
import faceYoungerBrother from 'src/spec/demoData/entities/face-younger-brother.json'
import faceYoungerSister from 'src/spec/demoData/entities/face-younger-sister.json'
import family from 'src/spec/demoData/entities/family.json'
import familyOutside from 'src/spec/demoData/entities/family-outside.json'
import father from 'src/spec/demoData/entities/father.json'
import fatherIsDad from 'src/spec/demoData/entities/father-is-dad.json'
import fight from 'src/spec/demoData/entities/fight.json'
import fright from 'src/spec/demoData/entities/fright.json'
import five from 'src/spec/demoData/entities/five.json'
import flower from 'src/spec/demoData/entities/flower.json'
import flight from 'src/spec/demoData/entities/flight.json'
import fruit from 'src/spec/demoData/entities/fruit.json'
import fry from 'src/spec/demoData/entities/fry.json'
import garage from 'src/spec/demoData/entities/garage.json'
import garden from 'src/spec/demoData/entities/garden.json'
import giraffe from 'src/spec/demoData/entities/giraffe.json'
import glue from 'src/spec/demoData/entities/glue.json'
import grandfather from 'src/spec/demoData/entities/grandfather.json'
import grandfatherIsGrandpa from 'src/spec/demoData/entities/grandfather-is-grandpa.json'
import grandma from 'src/spec/demoData/entities/grandma.json'
import grandmother from 'src/spec/demoData/entities/grandmother.json'
import grandmotherIsGrandma from 'src/spec/demoData/entities/grandmother-is-grandma.json'
import grandpa from 'src/spec/demoData/entities/grandpa.json'
import happy from 'src/spec/demoData/entities/happy.json'
import hospital from 'src/spec/demoData/entities/hospital.json'
import headmasterOffice from 'src/spec/demoData/entities/headmaster-office.json'
import homeWithRooms from 'src/spec/demoData/entities/home-with-rooms.json'
import hotAir from 'src/spec/demoData/entities/hot-air.json'
import iAm from 'src/spec/demoData/entities/i-am.json'
import imageAirCirculation from 'src/spec/demoData/entities/image-air-circulation.json'
import imageFlower from 'src/spec/demoData/entities/image-flower.json'
import imagePlantDiagram from 'src/spec/demoData/entities/image-plant-diagram.json'
import is from 'src/spec/demoData/entities/is.json'
import key from 'src/spec/demoData/entities/key.json'
import kitchen from 'src/spec/demoData/entities/kitchen.json'
import kiwi from 'src/spec/demoData/entities/kiwi.json'
import knife from 'src/spec/demoData/entities/knife.json'
import leaf from 'src/spec/demoData/entities/leaf.json'
import lettuce from 'src/spec/demoData/entities/lettuce.json'
import lettuces from 'src/spec/demoData/entities/lettuces.json'
import livingRoom from 'src/spec/demoData/entities/living-room.json'
import mango from 'src/spec/demoData/entities/mango.json'
import mangoes from 'src/spec/demoData/entities/mangoes.json'
import monday from 'src/spec/demoData/entities/monday.json'
import mop from 'src/spec/demoData/entities/mop.json'
import more from 'src/spec/demoData/entities/more.json'
import mouse from 'src/spec/demoData/entities/mouse.json'
import mother from 'src/spec/demoData/entities/mother.json'
import motherIsMum from 'src/spec/demoData/entities/mother-is-mum.json'
import mum from 'src/spec/demoData/entities/mum.json'
import night from 'src/spec/demoData/entities/night.json'
import orange from 'src/spec/demoData/entities/orange.json'
import paragraphAllAboutMe from 'src/spec/demoData/entities/paragraph-all-about-me.json'
import paragraphIceCreamWithTheFamily from 'src/spec/demoData/entities/paragraph-ice-cream-with-the-family.json'
import paragraphSentencepunctuationmarker from 'src/spec/demoData/entities/paragraph-sentencepunctuationmarker.json'
import paragraphTortoiseAndTheHareRace from 'src/spec/demoData/entities/paragraph-tortoise-and-the-hare-race.json'
import parents from 'src/spec/demoData/entities/parents.json'
import pear from 'src/spec/demoData/entities/pear.json'
import pencil from 'src/spec/demoData/entities/pencil.json'
import phraseChildrenMustCarefulForAccident from 'src/spec/demoData/entities/phrase-children-must-careful-for-accident.json'
import paragraphChristmasTreeStory1 from 'src/spec/demoData/entities/paragraph-christmas-tree-story-1.json'
import paragraphCinderellaPart2 from 'src/spec/demoData/entities/paragraph-cinderella-part-2.json'
import phraseChooseTheCorrectSpelling from 'src/spec/demoData/entities/phrase-choose-the-correct-spelling.json'
import phraseChooseTheAdjective from 'src/spec/demoData/entities/phrase-choose-the-adjective.json'
import phraseFatherBoughtCouponForPoAndPor from 'src/spec/demoData/entities/phrase-father-bought-coupon-for-po-and-por.json'
import phraseHeIs from 'src/spec/demoData/entities/phrase-he-is.json'
import phraseHeIsMyElderBrother from 'src/spec/demoData/entities/phrase-he-is-my-elder-brother.json'
import phraseHeIsTom from 'src/spec/demoData/entities/phrase-he-is-tom.json'
import phraseHeIsWatchingTCInTheLiveingRoom from 'src/spec/demoData/entities/phrase-he-is-watching-tv-in-the-living-room.json'
import phraseHeIsWateringThePlants from 'src/spec/demoData/entities/phrase-he-is-watering-the-plants.json'
import phraseHeIsWateringThePlantsInTheGarden from 'src/spec/demoData/entities/phrase-he-is-watering-the-plants-in-the-garden.json'
import phraseHeIsWorking from 'src/spec/demoData/entities/phrase-he-is-working.json'
import phraseHowOftenDoYouSleep from 'src/spec/demoData/entities/phrase-how-often-do-you-sleep.json'
import phraseHowOftenDoYouExercise from 'src/spec/demoData/entities/phrase-how-often-do-you-exercise.json'
import phraseHeIsATeacherHeWorksInASchool from 'src/spec/demoData/entities/phrase-he-is-a-teacher-he-works-in-a-school'
import phraseHiMyNameIsJohn from 'src/spec/demoData/entities/phrase-hi-my-name-is-john'
import phraseIAm from 'src/spec/demoData/entities/phrase-i-am.json'
import phraseIAmAnna from 'src/spec/demoData/entities/phrase-i-am-anna.json'
import phraseIAmSam from 'src/spec/demoData/entities/phrase-i-am-sam.json'
import phraseIHaveAToothache from 'src/spec/demoData/entities/phrase-i-have-a-toothache.json'
import phraseIThinkItSAboutAParty from 'src/spec/demoData/entities/phrase-i-think-it-s-about-a-party.json'
import phraseIThinkItSAboutARace from 'src/spec/demoData/entities/phrase-i-think-it-s-about-a-race.json'
import phraseIThinkItSAboutATortoiseAndAHare from 'src/spec/demoData/entities/phrase-i-think-it-s-about-a-tortoise-and-a-hare.json'
import phraseIThinkItSAboutAnElephantAndAnAeroplane from 'src/spec/demoData/entities/phrase-i-think-it-s-about-an-elephant-and-an-aeroplane.json'
import phraseIExerciseMoreThanFiveHoursAWeek from 'src/spec/demoData/entities/phrase-i-exercise-more-than-five-hours-a-week.json'
import phraseIExerciseMoreLessFiveHoursAWeek from 'src/spec/demoData/entities/phrase-i-exercise-less-than-five-hours-a-week.json'
import phraseItIsABanana from 'src/spec/demoData/entities/phrase-it-is-a-banana.json'
import phraseItIsAMango from 'src/spec/demoData/entities/phrase-it-is-a-mango.json'
import phraseLastMondayIAteMangoes from 'src/spec/demoData/entities/phrase-last-monday-i-ate-mangoes.json'
import phraseLastSundayIAteLettuces from 'src/spec/demoData/entities/phrase-last-sunday-i-ate-lettuces.json'
import phraseMotherCookedFromHealthyMenuBook from 'src/spec/demoData/entities/phrase-mother-cooked-from-healthy-menu-book.json'
import phrasePorLikeToDoAerobicsDance from 'src/spec/demoData/entities/phrase-por-like-to-do-aerobics-dance.json'
import phrasePutSomeWetTissuesInsideAPlasticBag from 'src/spec/demoData/entities/phrase-put-some-wet-tissues-inside-a-plastic-bag.json'
import phraseSamHasBigEyes from 'src/spec/demoData/entities/phrase-sam-has-big-eyes.json'
import phraseSamHasBlackHair from 'src/spec/demoData/entities/phrase-sam-has-black-hair.json'
import phraseSaraHasBigBlueEyes from 'src/spec/demoData/entities/phrase-sara-has-big-blue-eyes.json'
import phraseSaraHasLongBlackCurlyHair from 'src/spec/demoData/entities/phrase-sara-has-long-black-curly-hair.json'
import phraseSheIs from 'src/spec/demoData/entities/phrase-she-is.json'
import phraseSheIsCookingDinner from 'src/spec/demoData/entities/phrase-she-is-cooking-dinner.json'
import phraseSheIsMary from 'src/spec/demoData/entities/phrase-she-is-mary.json'
import phraseSheIsMyElderSister from 'src/spec/demoData/entities/phrase-she-is-my-elder-sister.json'
import phraseSheIsMyYoungerSister from 'src/spec/demoData/entities/phrase-she-is-my-younger-sister.json'
import phraseSheIsWashingTheDishes from 'src/spec/demoData/entities/phrase-she-is-washing-the-dishes.json'
import phraseSheIsADoctorSheWorksInAHospital from 'src/spec/demoData/entities/phrase-she-is-a-doctor-she-works-in-a-hospital.json'
import phraseSheIsAFruitsellerSheWorksInAMarketplace from 'src/spec/demoData/entities/phrase-she-is-a-fruitseller-she-works-in-a-marketplace.json'
import phraseTakeThesePills from 'src/spec/demoData/entities/phrase-take-these-pills.json'
import phraseTheyAre from 'src/spec/demoData/entities/phrase-they-are.json'
import phraseTheyAreEatingLunch from 'src/spec/demoData/entities/phrase-they-are-eating-lunch.json'
import phraseTheyAreMyParents from 'src/spec/demoData/entities/phrase-they-are-my-parents.json'
import phraseTheyAreReadingBooks from 'src/spec/demoData/entities/phrase-they-are-reading-books.json'
import phraseThisIsMyFamily from 'src/spec/demoData/entities/phrase-this-is-my-family.json'
import phraseThisIsMyFather from 'src/spec/demoData/entities/phrase-this-is-my-father.json'
import phraseThisIsMyMother from 'src/spec/demoData/entities/phrase-this-is-my-mother.json'
import phraseThankYouGoodbyeSara from 'src/spec/demoData/entities/phrase-thank-you-goodbye-sara.json'
import phraseWeAre from 'src/spec/demoData/entities/phrase-we-are.json'
import phraseWeAreFamily from 'src/spec/demoData/entities/phrase-we-are-family.json'
import phraseWeAreParents from 'src/spec/demoData/entities/phrase-we-are-parents.json'
import phraseWhatAreTheyDoing from 'src/spec/demoData/entities/phrase-what-are-they-doing.json'
import phraseWhatIsHeDoing from 'src/spec/demoData/entities/phrase-what-is-he-doing.json'
import phraseWhatIsIt from 'src/spec/demoData/entities/phrase-what-is-it.json'
import phraseWhatIsSheDoing from 'src/spec/demoData/entities/phrase-what-is-she-doing.json'
import phraseWhatIsTheMatter from 'src/spec/demoData/entities/phrase-what-is-the-matter.json'
import phraseWhatDoesYourFatherDo from 'src/spec/demoData/entities/phrase-what-does-your-father-do.json'
import phraseWhatDoesYourSisterDo from 'src/spec/demoData/entities/phrase-what-does-your-sister-do.json'
import phraseWhoAreThey from 'src/spec/demoData/entities/phrase-who-are-they.json'
import phraseWhoIsHe from 'src/spec/demoData/entities/phrase-who-is-he.json'
import phraseWhoIsShe from 'src/spec/demoData/entities/phrase-who-is-she.json'
import phraseYouAre from 'src/spec/demoData/entities/phrase-you-are.json'
import phraseYouAreAGirl from 'src/spec/demoData/entities/phrase-you-are-a-girl.json'
import phraseYouAreTom from 'src/spec/demoData/entities/phrase-you-are-tom.json'
import pineapple from 'src/spec/demoData/entities/pineapple.json'
import plus from 'src/spec/demoData/entities/plus.json'
import play from 'src/spec/demoData/entities/play.json'
import radish from 'src/spec/demoData/entities/radish.json'
import rainForest from 'src/spec/demoData/entities/rain-forest.json'
import ran from 'src/spec/demoData/entities/ran.json'
import rootEntity from 'src/spec/demoData/entities/root.json'
import run from 'src/spec/demoData/entities/run.json'
import running from 'src/spec/demoData/entities/running.json'
import safetyPin from 'src/spec/demoData/entities/safety-pin.json'
import school from 'src/spec/demoData/entities/school.json'
import seed from 'src/spec/demoData/entities/seed.json'
import sister from 'src/spec/demoData/entities/sister.json'
import sleep from 'src/spec/demoData/entities/sleep.json'
import skate from 'src/spec/demoData/entities/skate.json'
import skated from 'src/spec/demoData/entities/skated.json'
import skating from 'src/spec/demoData/entities/skating.json'
import snake from 'src/spec/demoData/entities/snake.json'
import snowHouse from 'src/spec/demoData/entities/snow-house.json'
import son from 'src/spec/demoData/entities/son.json'
import stem from 'src/spec/demoData/entities/stem.json'
import sunday from 'src/spec/demoData/entities/sunday.json'
import thaiMorning from 'src/spec/demoData/entities/thai-morning.json'
import three from 'src/spec/demoData/entities/three.json'
import together from 'src/spec/demoData/entities/together.json'
import toothbrush from 'src/spec/demoData/entities/toothbrush.json'
import Try from 'src/spec/demoData/entities/try.json'
import thankYou from 'src/spec/demoData/entities/thank-you.json'
import two from 'src/spec/demoData/entities/two.json'
import van from 'src/spec/demoData/entities/van.json'
import walk from 'src/spec/demoData/entities/walk.json'
import walked from 'src/spec/demoData/entities/walked.json'
import walking from 'src/spec/demoData/entities/walking.json'
import watermelon from 'src/spec/demoData/entities/watermelon.json'
import wiindow from 'src/spec/demoData/entities/wiindow.json'
import windoh from 'src/spec/demoData/entities/windoh.json'
import windohh from 'src/spec/demoData/entities/windohh.json'
import window from 'src/spec/demoData/entities/window.json'
import why from 'src/spec/demoData/entities/why.json'
import works from 'src/spec/demoData/entities/works.json'
import youngerBrother from 'src/spec/demoData/entities/younger-brother.json'
import youngerSister from 'src/spec/demoData/entities/younger-sister.json'

const stubs = [
  ['collection-fl', 'fl'],
  ['collection-pl', 'pl'],
  ['collection-cl', 'cl'],
  ['phrase-his-name-is-john', 'His name is John'],
  ['jack', 'Jack'],
  ['jane', 'Jane'],
  ['john', 'John'],
  ['phrase-he-is-seven', 'He is seven years old'],
  ['seven', 'seven'],
  ['tree', 'tree'],
  ['train', 'train'],
  ['trap', 'trap'],
  ['bright', 'bright', { sound: { srcEn: 'https://storage.googleapis.com/learn21-assets/sound/bright-p6f4/en-bright-p6f4.986a7ab08e04b1c14612588f6973b4b4.mp3' } }],
]
const generatedStubs = generateEntities(stubs.length)

const demoData = {
  ...instructions.data,
  ...alphabetEn.data,
  ...alphabetCursiveEn.data,
  ...familyFaces.data,
  ...letterFormationVideos.data,
  ...phonics.data,
  ...phonicsAlphabet.data,
  ...video.data,
  ...homeworkWrapUp.data,
  lesson: {
    ...objectives.data.lesson,
    ...curriculumMapping.data.lesson,
  },
  entities: {
    ...keyBy(stubs.map(([slug, title, rest = {}], i) => merge({
      ...generatedStubs[i],
      slug,
      title,
      titleEn: title,
    }, rest)), 'slug'),
    ...keyBy(phonics.data.phonics, 'slug'),
    'air-flow': airFlow.data.entity,
    'choose-animal': chooseAnimal.data.entity,
    'cold-air': coldAir.data.entity,
    'collection-correct': collectionCorrect.data.entity,
    'collection-false': collectionFalse.data.entity,
    'collection-fruits': collectionFruits.data.entity,
    'collection-incorrect': collectionIncorrect.data.entity,
    'collection-igh': collectionIgh.data.entity,
    'collection-null': collectionNull.data.entity,
    'collection-past-tense': collectionPastTense.data.entity,
    'collection-present-continuous-tense': collectionPresentContinuousTense.data.entity,
    'collection-present-tense': collectionPresentTense.data.entity,
    'collection-true': collectionTrue.data.entity,
    'collection-vegetables': collectionVegetables.data.entity,
    'collection-y': collectionY.data.entity,
    'dining-room': diningRoom.data.entity,
    'elder-brother': elderBrother.data.entity,
    'elder-brother-this-is-my': elderBrotherThisIsMy.data.entity,
    'elder-sister': elderSister.data.entity,
    'face-elder-brother': faceElderBrother.data.entity,
    'face-elder-sister': faceElderSister.data.entity,
    'face-father': faceFather.data.entity,
    'face-grandfather-father': faceGrandfatherFather.data.entity,
    'face-grandfather-mother': faceGrandfatherMother.data.entity,
    'face-grandmother-father': faceGrandmotherFather.data.entity,
    'face-grandmother-mother': faceGrandmotherMother.data.entity,
    'face-mother': faceMother.data.entity,
    'face-younger-brother': faceYoungerBrother.data.entity,
    'face-younger-sister': faceYoungerSister.data.entity,
    'family-outside': familyOutside.data.entity,
    'father-is-dad': fatherIsDad.data.entity,
    'grandfather-is-grandpa': grandfatherIsGrandpa.data.entity,
    'grandmother-is-grandma': grandmotherIsGrandma.data.entity,
    'headmaster-office': headmasterOffice.data.entity,
    'home-with-rooms': homeWithRooms.data.entity,
    'hot-air': hotAir.data.entity,
    'i-am': iAm.data.entity,
    'image-air-circulation': imageAirCirculation.data.entity,
    'image-flower': imageFlower.data.entity,
    'image-plant-diagram': imagePlantDiagram.data.entity,
    'living-room': livingRoom.data.entity,
    'mother-is-mum': motherIsMum.data.entity,
    'paragraph-all-about-me': paragraphAllAboutMe.data.entity,
    'paragraph-ice-cream-with-the-family': paragraphIceCreamWithTheFamily.data.entity,
    'paragraph-sentencepunctuationmarker': paragraphSentencepunctuationmarker.data.entity,
    'paragraph-tortoise-and-the-hare-race': paragraphTortoiseAndTheHareRace.data.entity,
    'phrase-children-must-careful-for-accident': phraseChildrenMustCarefulForAccident.data.entity,
    'paragraph-christmas-tree-story-1': paragraphChristmasTreeStory1.data.entity,
    'paragraph-cinderella-part-2': paragraphCinderellaPart2.data.entity,
    'phrase-choose-the-correct-spelling': phraseChooseTheCorrectSpelling.data.entity,
    'phrase-choose-the-adjective': phraseChooseTheAdjective.data.entity,
    'phrase-father-bought-coupon-for-po-and-por': phraseFatherBoughtCouponForPoAndPor.data.entity,
    'phrase-he-is': phraseHeIs.data.entity,
    'phrase-he-is-my-elder-brother': phraseHeIsMyElderBrother.data.entity,
    'phrase-he-is-tom': phraseHeIsTom.data.entity,
    'phrase-he-is-watching-tv-in-the-living-room': phraseHeIsWatchingTCInTheLiveingRoom.data.entity,
    'phrase-he-is-watering-the-plants': phraseHeIsWateringThePlants.data.entity,
    'phrase-he-is-watering-the-plants-in-the-garden': phraseHeIsWateringThePlantsInTheGarden.data.entity,
    'phrase-he-is-working': phraseHeIsWorking.data.entity,
    'phrase-hi-my-name-is-john-i-am-seven': phraseHiMyNameIsJohn.data.entity,
    'phrase-i-am': phraseIAm.data.entity,
    'phrase-i-am-anna': phraseIAmAnna.data.entity,
    'phrase-i-am-sam': phraseIAmSam.data.entity,
    'phrase-i-have-a-toothache': phraseIHaveAToothache.data.entity,
    'phrase-i-think-it-s-about-a-party': phraseIThinkItSAboutAParty.data.entity,
    'phrase-i-think-it-s-about-a-race': phraseIThinkItSAboutARace.data.entity,
    'phrase-i-think-it-s-about-a-tortoise-and-a-hare': phraseIThinkItSAboutATortoiseAndAHare.data.entity,
    'phrase-i-think-it-s-about-an-elephant-and-an-aeroplane': phraseIThinkItSAboutAnElephantAndAnAeroplane.data.entity,
    'phrase-i-exercise-more-than-five-hours-a-week': phraseIExerciseMoreThanFiveHoursAWeek.data.entity,
    'phrase-i-exercise-less-than-five-hours-a-week': phraseIExerciseMoreLessFiveHoursAWeek.data.entity,
    'phrase-it-is-a-banana': phraseItIsABanana.data.entity,
    'phrase-it-is-a-mango': phraseItIsAMango.data.entity,
    'phrase-how-often-do-you-sleep': phraseHowOftenDoYouSleep.data.entity,
    'phrase-how-often-do-you-exercise': phraseHowOftenDoYouExercise.data.entity,
    'phrase-he-is-a-teacher-he-works-in-a-school': phraseHeIsATeacherHeWorksInASchool.data.entity,
    'phrase-last-monday-i-ate-mangoes': phraseLastMondayIAteMangoes.data.entity,
    'phrase-last-sunday-i-ate-lettuces': phraseLastSundayIAteLettuces.data.entity,
    'phrase-mother-cooked-from-healthy-menu-book': phraseMotherCookedFromHealthyMenuBook.data.entity,
    'phrase-por-like-to-do-aerobics-dance': phrasePorLikeToDoAerobicsDance.data.entity,
    'phrase-put-some-wet-tissues-inside-a-plastic-bag': phrasePutSomeWetTissuesInsideAPlasticBag.data.entity,
    'phrase-sam-has-big-eyes': phraseSamHasBigEyes.data.entity,
    'phrase-sam-has-black-hair': phraseSamHasBlackHair.data.entity,
    'phrase-sara-has-big-blue-eyes': phraseSaraHasBigBlueEyes.data.entity,
    'phrase-sara-has-long-black-curly-hair': phraseSaraHasLongBlackCurlyHair.data.entity,
    'phrase-she-is': phraseSheIs.data.entity,
    'phrase-she-is-cooking-dinner': phraseSheIsCookingDinner.data.entity,
    'phrase-she-is-mary': phraseSheIsMary.data.entity,
    'phrase-she-is-my-elder-sister': phraseSheIsMyElderSister.data.entity,
    'phrase-she-is-my-younger-sister': phraseSheIsMyYoungerSister.data.entity,
    'phrase-she-is-washing-the-dishes': phraseSheIsWashingTheDishes.data.entity,
    'phrase-she-is-a-doctor-she-works-in-a-hospital': phraseSheIsADoctorSheWorksInAHospital.data.entity,
    'phrase-she-is-a-fruitseller-she-works-in-a-marketplace': phraseSheIsAFruitsellerSheWorksInAMarketplace.data.entity,
    'phrase-take-these-pills': phraseTakeThesePills.data.entity,
    'phrase-they-are': phraseTheyAre.data.entity,
    'phrase-they-are-eating-lunch': phraseTheyAreEatingLunch.data.entity,
    'phrase-they-are-my-parents': phraseTheyAreMyParents.data.entity,
    'phrase-they-are-reading-books': phraseTheyAreReadingBooks.data.entity,
    'phrase-this-is-my-family': phraseThisIsMyFamily.data.entity,
    'phrase-this-is-my-father': phraseThisIsMyFather.data.entity,
    'phrase-this-is-my-mother': phraseThisIsMyMother.data.entity,
    'phrase-thank-you-goodbye-sara': phraseThankYouGoodbyeSara.data.entity,
    'phrase-we-are': phraseWeAre.data.entity,
    'phrase-we-are-family': phraseWeAreFamily.data.entity,
    'phrase-we-are-parents': phraseWeAreParents.data.entity,
    'phrase-what-is-he-doing': phraseWhatIsHeDoing.data.entity,
    'phrase-what-is-it': phraseWhatIsIt.data.entity,
    'phrase-what-is-she-doing': phraseWhatIsSheDoing.data.entity,
    'phrase-what-is-the-matter': phraseWhatIsTheMatter.data.entity,
    'phrase-who-are-they': phraseWhoAreThey.data.entity,
    'phrase-what-are-they-doing': phraseWhatAreTheyDoing.data.entity,
    'phrase-what-does-your-father-do': phraseWhatDoesYourFatherDo.data.entity,
    'phrase-what-does-your-sister-do': phraseWhatDoesYourSisterDo.data.entity,
    'phrase-who-is-he': phraseWhoIsHe.data.entity,
    'phrase-who-is-she': phraseWhoIsShe.data.entity,
    'phrase-you-are': phraseYouAre.data.entity,
    'phrase-you-are-a-girl': phraseYouAreAGirl.data.entity,
    'phrase-you-are-tom': phraseYouAreTom.data.entity,
    'rain-forest': rainForest.data.entity,
    'safety-pin': safetyPin.data.entity,
    'snow-house': snowHouse.data.entity,
    'thai-morning': thaiMorning.data.entity,
    'thank-you': thankYou.data.entity,
    'younger-brother': youngerBrother.data.entity,
    'younger-sister': youngerSister.data.entity,
    accident: accident.data.entity,
    aerobics: aerobics.data.entity,
    am: am.data.entity,
    are: are.data.entity,
    apple: apple.data.entity,
    banana: banana.data.entity,
    bathroom: bathroom.data.entity,
    bedroom: bedroom.data.entity,
    bicycle: bicycle.data.entity,
    bird: bird.data.entity,
    brother: brother.data.entity,
    cabbage: cabbage.data.entity,
    calcium: calcium.data.entity,
    car: car.data.entity,
    carrot: carrot.data.entity,
    chapel: chapel.data.entity,
    cherry: cherry.data.entity,
    clap: clap.data.entities[0],
    clock: clock.data.entities[0],
    coupon: coupon.data.entity,
    cry: cry.data.entity,
    dad: dad.data.entity,
    delicious: delicious.data.entity,
    desert: desert.data.entity,
    does: does.data.entity,
    doctor: doctor.data.entity,
    dor: dor.data.entity,
    door: door.data.entity,
    doorr: doorr.data.entity,
    dour: dour.data.entity,
    eat: eat.data.entity,
    eight: eight.data.entity,
    eraser: eraser.data.entity,
    family: family.data.entity,
    father: father.data.entity,
    five: five.data.entity,
    fight: fight.data.entity,
    flight: flight.data.entities[0],
    flower: flower.data.entity,
    fright: fright.data.entity,
    fruit: fruit.data.entity,
    fry: fry.data.entity,
    garage: garage.data.entity,
    garden: garden.data.entity,
    giraffe: giraffe.data.entity,
    glue: glue.data.entities[0],
    grandfather: grandfather.data.entity,
    grandma: grandma.data.entity,
    grandmother: grandmother.data.entity,
    grandpa: grandpa.data.entity,
    happy: happy.data.entity,
    hospital: hospital.data.entity,
    is: is.data.entity,
    key: key.data.entity,
    kitchen: kitchen.data.entity,
    kiwi: kiwi.data.entity,
    knife: knife.data.entity,
    leaf: leaf.data.entity,
    lettuce: lettuce.data.entity,
    lettuces: lettuces.data.entity,
    mango: mango.data.entity,
    mangoes: mangoes.data.entity,
    monday: monday.data.entity,
    mop: mop.data.entities[0],
    more: more.data.entity,
    mouse: mouse.data.entity,
    mother: mother.data.entity,
    mum: mum.data.entity,
    night: night.data.entity,
    orange: orange.data.entity,
    parents: parents.data.entity,
    pear: pear.data.entity,
    pencil: pencil.data.entity,
    pineapple: pineapple.data.entity,
    play: play.data.entities[0],
    plus: plus.data.entities[0],
    radish: radish.data.entity,
    root: rootEntity.data.entity,
    run: run.data.entity,
    running: running.data.entity,
    ran: ran.data.entity,
    school: school.data.entity,
    seed: seed.data.entity,
    sister: sister.data.entity,
    sleep: sleep.data.entity,
    skate: skate.data.entity,
    skated: skated.data.entity,
    skating: skating.data.entity,
    snake: snake.data.entity,
    son: son.data.entity,
    stem: stem.data.entity,
    sunday: sunday.data.entity,
    three: three.data.entity,
    together: together.data.entity,
    toothbrush: toothbrush.data.entity,
    try: Try.data.entity,
    two: two.data.entity,
    van: van.data.entity,
    walk: walk.data.entity,
    walked: walked.data.entity,
    walking: walking.data.entity,
    watermelon: watermelon.data.entity,
    wiindow: wiindow.data.entity,
    windoh: windoh.data.entity,
    windohh: windohh.data.entity,
    window: window.data.entity,
    why: why.data.entity,
    works: works.data.entity,
  },
}

export default demoData
