import React, { Component } from 'react'
import * as components from 'src/modules'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'
import {
  previewCapturePath,
  previewModulePath,
  schoolPreviewModulePath,
  teacherPreviewModulePath,
} from 'src/lib/routes'
import Div from 'src/components/Div'
import Card from 'src/components/Card'
import { homeworkIteration } from 'src/lib/homework.js'

const propTypes = {
  path: string.isRequired,
}

const styles = {
  background: 'yellow',
  color: 'black',
  display: 'block',
  fontWeight: 'bold',
}
const moduleList = Object.keys(components).sort()

const moduleListWithIterations = moduleList.map(
  module => `${module} x${homeworkIteration({ module })}`,
)

const filterComponentName = (componentName, inputValue) => (
  componentName.toLowerCase().includes(inputValue.replace(/\s/g, '').toLowerCase())
)

class ModuleScreenShots extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredModuleList: moduleListWithIterations,
    }
  }

  linkToPath = (path, img) => {
    switch (path) {
      case '/preview/captures':
        return previewCapturePath(img)
      case '/preview/modules':
        return previewModulePath(img)
      case '/school/preview/modules':
        return schoolPreviewModulePath({ moduleId: img })
      case '/teacher/preview/modules':
        return teacherPreviewModulePath({ moduleId: img })
      default: return previewModulePath(img)
    }
  }

  handleOnChange({ target: { value } }) {
    this.setState({
      filteredModuleList: moduleListWithIterations.filter(
        name => filterComponentName(name, value),
      ),
    })
  }

  render() {
    const { filteredModuleList } = this.state
    const { path } = this.props

    return (
      <Div flex flex-auto column bg="gray">
        <Div
          flex
          p1
          sticky
          top-0
          col-12
          bg-body
          shadow-bottom
        >
          <Div flex column auto mr2>
            <input
              className="h3 field px1"
              placeholder="Filter modules"
              onChange={e => this.handleOnChange(e)}
            />
          </Div>
          <Card
            fit
            m={0}
            size={3}
            title={filteredModuleList.length}
          />
        </Div>
        <Div wrap align="center" justify="center" flex>
          {filteredModuleList.map((img) => {
            const imgName = img.split(' ')[0]
            return (
              <Div
                align="center"
                auto
                center
                col={3}
                flex
                justify="center"
                key={imgName}
                m={1}
                shadow
              >
                <Link to={this.linkToPath(path, imgName)}>
                  <img
                    alt={imgName}
                    className="flex-1"
                    src={`/preview/${imgName}.png`}
                    style={styles}
                  />
                </Link>
              </Div>
            )
          })}
          {filteredModuleList.length === 0 &&
            <p className="h3">No modules found</p>
          }
        </Div>
      </Div>
    )
  }
}

ModuleScreenShots.propTypes = propTypes

export default ModuleScreenShots
