import React from 'react'
import { Link } from 'react-router-dom'
import { bool, string, number, shape } from 'prop-types'

import Progress from 'src/components/Progress'
import { homeworkReportPath } from 'src/lib/routes'
import Grade from 'src/components/Grade'
import usePath from 'src/lib/routes/usePath'


const propTypes = {
  isSection: bool,
  resource: shape({
    assignedHomeworkCount: number,
    availableHomeworkCount: number,
    completedHomeworkCount: number,
    homeworkGrade: number,
    homeworkRate: number,
    studentsCount: number,
    title: string,
  }).isRequired,
}

const defaultProps = {
  isSection: false,
}

const HomeworkPageTableRow = ({
  resource: {
    id,
    slug,
    title,
    studentsCount,
    homeworkRate,
    homeworkGrade,
    completedHomeworkCount,
    assignedHomeworkCount,
    availableHomeworkCount,
  },
  isSection,
}) => {
  const path = usePath(homeworkReportPath)
  return (
    <tr id={isSection ? `qa-section-${id}` : `qa-year-${id}`}>
      <td className={isSection ? 'bold qa-title' : 'qa-title'}>
        {!isSection ? (
          <Link to={path({ yearId: slug })}>{title}</Link>
        ) : title}
      </td>
      <td className="qa-students-count">{studentsCount}</td>
      <td className="qa-grade">
        <Grade grade={homeworkGrade} percentage={homeworkRate} />
      </td>
      <td>
        <Progress
          actual={completedHomeworkCount}
          total={assignedHomeworkCount}
          showRatio
          noTooltip
        />
      </td>
      <td>
        <Progress
          actual={assignedHomeworkCount}
          total={availableHomeworkCount}
          showRatio
          noTooltip
        />
      </td>
    </tr>
  )
}

HomeworkPageTableRow.propTypes = propTypes
HomeworkPageTableRow.defaultProps = defaultProps

export default HomeworkPageTableRow
