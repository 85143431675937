import { graphql } from '@apollo/react-hoc'
import { compose } from 'src/lib/redux'
import query from 'src/queries/academicYearsQuery.gql'
import createAcademicTerm from 'src/mutations/AcademicTerm/createAcademicTerm.gql'
import updateAcademicTerm from 'src/mutations/AcademicTerm/updateAcademicTerm.gql'
import deleteAcademicTerm from 'src/mutations/AcademicTerm/deleteAcademicTerm.gql'
import AcademicTerms from 'src/pages/SchoolSettings/AcademicYears/AcademicTerms/AcademicTerms'

const options = {
  refetchQueries: [{ query }],
}
export default compose(
  graphql(createAcademicTerm, { name: 'createTerm', options }),
  graphql(updateAcademicTerm, { name: 'updateTerm', options }),
  graphql(deleteAcademicTerm, { name: 'deleteTerm', options }),
)(AcademicTerms)
