import React from 'react'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import EntityImage from 'src/components/EntityImage'
import EntityCard from 'src/components/EntityCard'
import Bubble from 'src/components/Bubble'

const propTypes = {
  audio: bool,
  audioRepeat: number,
  autoPlay: bool,
  bold: bool,
  bounce: bool,
  className: string,
  col: number,
  correct: bool,
  english: bool,
  entity: object.isRequired,
  gutter: number,
  image: bool,
  justify: string,
  label: oneOfType([string, number]),
  mbn: number,
  meaning: bool,
  minHeight: oneOfType([string, number, bool]),
  mln: number,
  mrn: number,
  onClick: func,
  onKeyPress: func,
  play: bool,
  playNext: func,
  selected: bool,
  silent: bool,
  size: number,
  style: object,
  thai: bool,
  tip: string,
}

const defaultProps = {
  audio: false,
  audioRepeat: 1,
  autoPlay: false,
  bold: undefined,
  bounce: null,
  className: undefined,
  col: null,
  correct: null,
  english: false,
  entity: {},
  gutter: null,
  image: null,
  justify: 'center',
  label: null,
  mbn: null,
  meaning: false,
  minHeight: null,
  mln: null,
  mrn: null,
  onClick: null,
  onKeyPress: null,
  play: null,
  playNext: null,
  selected: null,
  silent: false,
  size: 1,
  thai: false,
  tip: null,
  style: undefined,
}

const EntityBubble = ({
  audio,
  audioRepeat,
  autoPlay,
  bold,
  bounce,
  className,
  col,
  correct,
  english,
  entity,
  gutter,
  image,
  justify,
  label,
  mbn,
  meaning,
  minHeight,
  mln,
  mrn,
  onClick,
  onKeyPress,
  play,
  playNext,
  selected,
  silent,
  size,
  thai,
  tip,
  style,
}) => (
  <Bubble
    bounce={bounce}
    col={col}
    gutter={gutter}
    justify={justify}
    label={label}
    mbn={mbn}
    mln={mln}
    mrn={mrn}
    onKeyPress={onKeyPress}
    tip={tip}
  >
    {
      image && !(thai || english) && (
        <EntityImage entity={entity} />
      )
    }
    {
      (thai || english) && (
      <EntityCard
        audio={audio}
        audioRepeat={audioRepeat}
        bold={bold}
        className={className}
        correct={correct}
        english={english}
        entity={entity}
        image={image}
        keyToPlay={label}
        meaning={meaning}
        onClick={onClick}
        play={play}
        playNext={playNext}
        selected={selected}
        silent={silent}
        thai={thai}
        autoPlay={autoPlay}
        minHeight={minHeight}
        size={size}
        style={style}
      />
      )
    }
  </Bubble>
)

EntityBubble.defaultProps = defaultProps
EntityBubble.propTypes = propTypes

export default EntityBubble
