import React from 'react'
import ListenAndSpeakGame from 'src/modules/ListenAndSpeak/ListenAndSpeakGame'

const ListenAndSpeak = props => <ListenAndSpeakGame {...props} />
const ListenAndSpeakFixed = props => <ListenAndSpeakGame {...props} fixed />

ListenAndSpeak.demoData = {
  entities: [
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-his-name-is-john',
    'john',
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-he-is-seven',
    'seven',
  ],
}

ListenAndSpeakFixed.demoData = {
  entities: [
    'phrase-hi-my-name-is-john-i-am-seven',
    'phrase-his-name-is-john',
    'john',
    'phrase-he-is-seven',
    'seven',
  ],
}

export {
  ListenAndSpeak,
  ListenAndSpeakFixed,
}
