import React from 'react'
import { useParams } from 'react-router'
import { dateToday, endOf, startOf, add, subtract } from 'src/lib/dates'

import Div from 'src/components/Div'
import YearCalendar from 'src/components/YearCalendar'
import AttendanceOverview from 'src/pages/DashboardPage/AttendanceOverview'
import Announcement from 'src/pages/SchoolLayout/Announcement'

export const path = '/dashboard'

const DashboardPage = () => {
  const { domain } = useParams()
  const startDate = startOf(dateToday(), 'month')
  const endDate = endOf(subtract(add(startDate, 4, 'months'), 1, 'day'), 'month')
  return (
    <Div>
      {!domain && <Announcement today />}
      <Div className="flex flex-column bg-white">
        <AttendanceOverview domain={domain} />
        <YearCalendar
          date={startDate}
          startDate={startDate}
          endDate={endDate}
        />
      </Div>
    </Div>
  )
}

export default DashboardPage
