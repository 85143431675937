import React from 'react'
import { useParams } from 'react-router'
import UserEditContainer from 'src/pages/User/Edit/Container'

const UserEdit = () => {
  const { studentId, userId } = useParams()
  return (
    <UserEditContainer userId={userId || studentId} />
  )
}

export default UserEdit
